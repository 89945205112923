import { GRAPH_SCALES } from 'modules/liveLook/constants';
import {
  marginBottom,
  marginBottomPdf,
  marginBottomDashboard,
  MONTH_BAR_WIDTH,
  MIN_GRAPH_WIDTH,
  ADDITIONAL_WIDTH,
} from './components/GraphBody/graphBody.constants';

const VALUES_TO_FILTER = {
  nominalCapacity: 'nominalCapacity',
  capacity: 'capacity',
};

const valuesToFilterArray = Object.values(VALUES_TO_FILTER);

const filterConfig = (isSwitcherOn, configArray) => {
  return {
    newConfigArray: isSwitcherOn
      ? configArray
      : configArray.filter(({ valueKey }) => !valuesToFilterArray.includes(valueKey)),
  };
};

export default filterConfig;

export const getMarginForScale = (scale, isDashboard, isPdf) => {
  if (isDashboard) return marginBottomDashboard[scale];
  if (isPdf) return marginBottomPdf[scale];
  return marginBottom[scale];
};

export const getWidth = (firstBarLength, dataLabelsLength, barWidth, scale, isDashboard) => {
  const width = dataLabelsLength * barWidth;
  if (isDashboard) return `${width}px`;

  if (scale === GRAPH_SCALES.halfAnHour) {
    if (firstBarLength < 18) return MIN_GRAPH_WIDTH;
  }

  if (scale === GRAPH_SCALES.hour) {
    if (firstBarLength < 9) {
      return MIN_GRAPH_WIDTH;
    }
    return `${width * 2}px`;
  }

  if (scale === GRAPH_SCALES.month || scale === GRAPH_SCALES.week || scale === GRAPH_SCALES.day) {
    if (firstBarLength > 7) return `${firstBarLength * MONTH_BAR_WIDTH + ADDITIONAL_WIDTH}px`;
    return MIN_GRAPH_WIDTH;
  }

  return `${width}px`;
};
