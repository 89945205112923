import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import URLS from 'pages/constants/urls';
import { getLastOpenedReportId } from 'modules/spaceLook/selectors';
import { RESET_REPORT_ERROR } from 'modules/shared/actions';
import { RESET_REPORT_DATA } from 'modules/spaceLook/actions';
import { HistoryReportListNode, HistoryReportListTitle, HistoryReportPopup } from 'components';

import './HistoryReportListItem.scss';

const HistoryReportListItem = ({ date, place, data, id }) => {
  const dispatch = useDispatch();
  const { lastOpenedReportId } = useSelector(getLastOpenedReportId);

  const onReportLinkClick = () => {
    if (id !== lastOpenedReportId) {
      dispatch(RESET_REPORT_DATA(id));
      dispatch(RESET_REPORT_ERROR());
    }
  };

  return (
    <section className="HistoryReportListItem">
      <div className="history-report-list-item">
        <HistoryReportListTitle date={date} />
        <div className="nodes">
          {data.map(({ title, label, percent }, idx) => (
            <HistoryReportListNode key={idx} title={title} label={label} percent={percent} />
          ))}
        </div>
      </div>
      <HistoryReportPopup buildings={place} />
      <Link className="report-link" to={URLS.spaceLook.surveyDetails.replace(':id', id)} onClick={onReportLinkClick} />
    </section>
  );
};

HistoryReportListItem.propTypes = {
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  place: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      percent: PropTypes.string,
    }),
  ).isRequired,
};

export default HistoryReportListItem;
