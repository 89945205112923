import React from 'react';
import PropTypes from 'prop-types';

import './ErrorNotification.scss';

const ErrorNotification = ({ infoText, ctaText, children }) => {
  const ctaContent = children || <p className="cta-text">{ctaText}</p>;

  return (
    <div className="ErrorNotification">
      <div className="error-notification">
        <p className="info-text">{infoText}</p>
        {ctaContent}
      </div>
    </div>
  );
};

ErrorNotification.defaultProps = {
  infoText: 'Unfortunately, something went wrong',
  ctaText: 'Please, check list of surveys',
};

ErrorNotification.propTypes = {
  infoText: PropTypes.string,
  ctaText: PropTypes.string,
};

export default ErrorNotification;
