/* eslint-disable no-unused-expressions */
import React, { useReducer, useEffect } from 'react';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';

import { useAuthSelector } from 'modules/auth/hooks';
import { showModal } from 'modules/modalsSystem/actions';
import { API, createAsyncAction } from 'modules/shared/utils';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { ENDPOINTS, requestStatuses } from 'modules/shared/constants';

import { TextPlaceholder } from 'modules/shared/components';

// eslint-disable-next-line import/no-cycle
import BasketSummaryTable from './BasketSummaryTable';

const FETCH_BASKET_SUMMARY = createAsyncAction(createAction('FETCH_BASKET_SUMMARY'));

const initialState = {
  requestStatus: requestStatuses.default,
  basketSummary: [],
  errorCode: null,
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_BASKET_SUMMARY.pending().type:
      return {
        ...initialState,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_BASKET_SUMMARY.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        basketSummary: payload,
      };
    case FETCH_BASKET_SUMMARY.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
        errorCode: payload,
      };
    default:
      throw new Error();
  }
}

function BasketSummaryTableContainer() {
  const reduxDispatch = useDispatch();

  const [companyId] = useAuthSelector(['currentCompany']);
  const [assets, basketBuilding, categories] = useAssetLookSelector([
    'assets.basketAssets',
    'assets.basketBuilding',
    'common.categories',
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let isCanceled = false;

    dispatch(FETCH_BASKET_SUMMARY.pending());

    const url = ENDPOINTS.assetLook.basketSummary
      .replace(':companyId', companyId)
      .replace(':buildingId', basketBuilding);

    assets.length &&
      API.post(url, { assets })
        .then(({ data }) => {
          if (isCanceled) return;
          dispatch(FETCH_BASKET_SUMMARY.success(data));
        })
        .catch(({ response }) => {
          if (isCanceled) return;
          dispatch(FETCH_BASKET_SUMMARY.failure(response.status));
        });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [assets, basketBuilding, companyId]);

  // eslint-disable-next-line camelcase
  const building = categories.by_building.find(({ building_id }) => building_id === Number(basketBuilding));

  return (
    <Choose>
      <When condition={!assets.length}>
        <TextPlaceholder text="The basket is empty" absolute />
      </When>
      <Otherwise>
        <BasketSummaryTable
          buildingName={get(building, 'building__name', undefined)}
          onRequestClick={() => reduxDispatch(showModal(modalCodes.AL_REQUEST_ORDER))}
          {...state}
        />
      </Otherwise>
    </Choose>
  );
}

export default BasketSummaryTableContainer;
