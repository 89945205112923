const DesksUsageBandingMore80 = ({ graphData, usageBandingType }) => {
  const totalDesks = Object.values(graphData).reduce((a, b) => a + b, 0);
  const more80Quantity = graphData[90] + graphData[100];
  const more80Percent = Math.round((more80Quantity / totalDesks) * 100);

  const occupiedOrUtilised = usageBandingType === 'OCCUPIED' ? 'occupied' : 'utilised';

  return `Only ${Math.floor(more80Quantity)} desks (${Math.floor(more80Percent)}%)
     were ${occupiedOrUtilised} ≥80% of the time during the survey.`;
};

export default DesksUsageBandingMore80;
