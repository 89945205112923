/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import { noop } from 'lodash';

import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// TODO: Remove when the "notistack" is fully implemented
const CustomSnackbar = ({ open, onClose, autoHideDuration, anchorOrigin, severity, variant, label }) => (
  <Snackbar
    open={open}
    onClose={onClose}
    autoHideDuration={autoHideDuration}
    anchorOrigin={{
      vertical: anchorOrigin.vertical,
      horizontal: anchorOrigin.horizontal,
    }}
  >
    <Alert severity={severity} variant={variant}>
      {label}
    </Alert>
  </Snackbar>
);

CustomSnackbar.defaultProps = {
  open: false,
  onClose: noop,
  autoHideDuration: 6000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  severity: 'success',
  variant: 'filled',
  label: 'Success',
};

export default CustomSnackbar;
