import React from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import { PAGE_TYPES, REPORT_TYPES } from 'modules/assetLook/constants';

import { TextPlaceholder } from 'modules/shared/components';
import { ReportContentPage } from 'pages/_privatePages/shared';

import ProductList from '../ProductList';
import ProductSummary from '../ProductSummary';
import AssetList from '../AssetList';

const titlesMap = {
  [REPORT_TYPES.productList]: PAGE_TYPES.productList,
  [REPORT_TYPES.productSummary]: PAGE_TYPES.productSummary,
  [REPORT_TYPES.assetList]: PAGE_TYPES.assetList,
};

const contentPagesMap = {
  [REPORT_TYPES.productList]: ProductList,
  [REPORT_TYPES.productSummary]: ProductSummary,
  [REPORT_TYPES.assetList]: AssetList,
};

const ContentPage = ({ products, buildingId, type, activeFilters, theme }) => {
  const ContentPageComponent = contentPagesMap[type];

  return (
    <Choose>
      <When condition={products}>
        <Choose>
          <When condition={isEmpty(products)}>
            <div className={cn(theme.pdfPage, theme.hasPageCounter)}>
              <ReportContentPage title={titlesMap[type]} activeFilters={activeFilters}>
                {() => <TextPlaceholder noData />}
              </ReportContentPage>
            </div>
          </When>

          <Otherwise>
            <Choose>
              <When condition={type === REPORT_TYPES.productList}>
                <ContentPageComponent products={products} buildingId={buildingId} />
              </When>
              <Otherwise>
                {products.map((product, i) => (
                  <ContentPageComponent key={i} product={product} buildingId={buildingId} />
                ))}
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </When>
      <Otherwise>{null}</Otherwise>
    </Choose>
  );
};

export default ContentPage;
