/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import React, { useState, useCallback, useRef, useMemo } from 'react';
import axios from 'axios';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import QRCode from 'qrcode.react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isLogged } from 'utils';
import { ONLY_NUMBERS, REQUESTS } from '_constants';
import { onLoginRedirect } from 'modules/auth/utils';
import { SET_TWO_FACTOR_REQUIRED } from 'modules/auth/actions';

import { FormButton, ModalWindow } from 'components';
// eslint-disable-next-line import/no-cycle
import { FormikInput, ErrorNotification, PasswordNotification } from 'modules/auth/components';
import StaticTokensList from './StaticTokensList';

import FormSchema from './FormSchema';

import './QrConfirmForm.scss';
import { getUser } from '../../selectors';

const { base, version, otpLogin } = REQUESTS;

const QrConfirmForm = ({
  qrUrl,
  successButtonText,
  errorButtonText,
  successInfoText,
  errorInfoText,
  errorCtaText,
  redirectButtonText,
  redirectInfoText,
  redirectCtaText,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(getUser);

  const storageUser = user;

  const [pending, setPending] = useState(false);
  const [isCodeError, setIsCodeError] = useState(false);
  const [isCodeSuccess, setIsCodeSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCanRedirect, setIsCanRedirect] = useState(false);
  const [staticTokens, setStaticTokens] = useState(null);

  const inputForCopyTokens = useRef(null);
  const inputForCopyTokensValue = useMemo(() => {
    return staticTokens ? staticTokens.join(', ') : '';
  }, [staticTokens]);

  const copyDisposableTokens = useCallback(() => {
    inputForCopyTokens.current.select();
    document.execCommand('copy');
    setIsCanRedirect(true);
  }, []);

  const onModalClose = useCallback(() => setIsModalOpen(false), []);
  const modalRedirect = useCallback(() => onLoginRedirect({ user: storageUser, history }), [history, storageUser]);

  const modalCallBack = isCanRedirect
    ? modalRedirect
    : isCodeSuccess
    ? copyDisposableTokens
    : isCodeError
    ? onModalClose
    : null;

  const buttonText = isCanRedirect ? redirectButtonText : isCodeSuccess ? successButtonText : errorButtonText || '';

  return (
    <div className="QrConfirmForm">
      <ModalWindow onClose={modalCallBack} isOpen={isModalOpen} buttonText={buttonText} withToggleButton={false}>
        {isCodeError && <ErrorNotification infoText={errorInfoText} ctaText={errorCtaText} />}
        {isCodeSuccess && !isCanRedirect && (
          <ErrorNotification infoText={successInfoText}>
            {staticTokens && <StaticTokensList tokensList={staticTokens} />}{' '}
          </ErrorNotification>
        )}
        {isCodeSuccess && isCanRedirect && <PasswordNotification title={redirectInfoText} text={redirectCtaText} />}
      </ModalWindow>
      {qrUrl && <QRCode renderAs="svg" height={150} width={150} value={qrUrl} />}
      <Formik
        initialValues={{
          code: '',
        }}
        validate={(values) => {
          const errors = {};
          const { code } = values;

          if (code.length) {
            const isOnlyNumbers = ONLY_NUMBERS.test(code);
            if (!isOnlyNumbers) {
              errors.code = 'Code must contain ONLY numbers';
            } else if (code.length !== 6) {
              errors.code = 'Code must contain 6 numbers';
            }
          }

          return errors;
        }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          if (isCodeSuccess) return;
          const { code } = values;
          const token = isLogged();
          setPending(true);
          axios({
            method: 'post',
            url: `${base}${version}${otpLogin}`,
            headers: { Authorization: `Token ${token}` },
            data: { token: code },
          })
            .then((response) => {
              const { key, static_tokens } = response.data;
              localStorage.setItem('userToken', key);
              setIsCodeError(false);
              setIsCodeSuccess(true);
              setPending(false);
              setIsModalOpen(true);
              static_tokens ? setStaticTokens(static_tokens) : setIsCanRedirect(true);
              dispatch(SET_TWO_FACTOR_REQUIRED(true));
            })
            .catch((err) => {
              if (err.message.indexOf(400) !== -1) {
                setIsCodeError(true);
                setIsCodeSuccess(false);
                setPending(false);
                setIsModalOpen(true);
              }
            });
        }}
      >
        {({ errors, touched }) => {
          const error = !isEmpty(touched) && errors.code;

          const classes = cn('qr-confirm-form', {
            disabled: error || pending,
          });

          return (
            <Form className={classes}>
              <input defaultValue={inputForCopyTokensValue} className="inputForCopyTokens" ref={inputForCopyTokens} />

              <FormikInput name="code" placeholder="Code" type="text" />
              {error && <div className="field-error">{error}</div>}
              <FormButton text="Send code" />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

QrConfirmForm.defaultProps = {
  qrUrl: '',
  successButtonText: 'Copy',
  errorButtonText: 'Please, try again',
  successInfoText:
    'Save this static tokens. You can use them instead of your code from authenticator if you lost your device',
  successCtaText: 'Static tokens must be here',
  errorInfoText: 'Something went wrong',
  errorCtaText: 'Your code may be wrong or you have been used it earlier',
  redirectButtonText: 'Go to homepage',
  // eslint-disable-next-line react/default-props-match-prop-types
  redirectInfoText: 'You successfully logged in!',
  // eslint-disable-next-line react/default-props-match-prop-types
  redirectCtaText: 'You have access to the system',
};

export default QrConfirmForm;
