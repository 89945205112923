/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { Autocomplete } from '@material-ui/lab';
import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  Collapse,
  Button,
  withStyles,
  TextField,
} from '@material-ui/core';

import { PAGE_TYPES, REPORT_TYPES } from 'modules/assetLook/constants';
import { CustomButtonContained } from 'modules/shared/components';
import { MultiSelect, FilterListPdf } from 'modules/assetLook/components';
import { getFiltersList, onSelectFilter, useInitFilters } from './utils';
import theme from './PdfReportsNew.module.scss';

const reportTypeLabels = {
  [REPORT_TYPES.productList]: PAGE_TYPES.productList,
  [REPORT_TYPES.productSummary]: PAGE_TYPES.productSummary,
  [REPORT_TYPES.assetList]: PAGE_TYPES.assetList,
};

const PdfReportsNew = ({ formik, buildingsMenuItems, categoriesMenuItems, summaryMenuItems, classes }) => {
  const resetFilters = () => {
    formik.setFieldValue('buildings', formik.initialValues.buildings);
    formik.setFieldValue('categories', formik.initialValues.categories);
    formik.setFieldValue('reportType', formik.initialValues.reportType);
  };
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedInputValue, setSelectedInputValue] = useState('');

  const { requestStatus, initialFilters } = useInitFilters(formik);

  useEffect(() => {
    if (isEmpty(selectedFilter)) return;

    onSelectFilter(selectedFilter, formik, initialFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={theme.container}>
          <div className={theme.search}>
            <Autocomplete
              id="filters List"
              options={getFiltersList(initialFilters)}
              disabled={requestStatus !== 'SUCCESS'}
              style={{
                width: '300px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              inputValue={selectedInputValue}
              onInputChange={(_event, value) => {
                if (selectedInputValue.length + 1 >= value.length) {
                  return setSelectedInputValue(value.trimStart());
                }
                return setSelectedInputValue(value.trim());
              }}
              value={selectedFilter}
              onChange={(_event, newFilter) => setSelectedFilter(newFilter)}
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" placeholder="Search" />}
            />
          </div>
          <FilterListPdf formik={formik} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <FormControl style={{ width: '250px', maxWidth: '250px' }}>
          <InputLabel children="Report Type" />
          <Select
            id="reportType"
            name="reportType"
            value={formik.values.reportType}
            onChange={formik.handleChange}
            error={formik.touched.reportType && Boolean(formik.errors.reportType)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {Object.values(REPORT_TYPES).map((value, i) => (
              <MenuItem key={i} value={value}>
                {reportTypeLabels[value]}
              </MenuItem>
            ))}
            <FormHelperText children={formik.touched.reportType && formik.errors.reportType} />
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <MultiSelect
          label="Buildings"
          value={formik.values.buildings}
          menuItems={buildingsMenuItems}
          onChange={(e) => {
            if (!isEmpty(e.target.value)) {
              formik.setFieldValue('categories', formik.initialValues.categories);
              formik.setFieldValue('buildings', e.target.value);
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelect
          label="Categories"
          value={formik.values.categories}
          menuItems={categoriesMenuItems}
          onChange={(e) => {
            if (!isEmpty(e.target.value)) {
              formik.setFieldValue('categories', e.target.value);
            }
          }}
        />
      </Grid>
      <Collapse
        in={formik.values.reportType === REPORT_TYPES.productSummary}
        className="MuiGrid-root MuiGrid-grid-xs-12"
        classes={{ wrapper: classes.collapseWrapper }}
      >
        <MultiSelect
          label="Summary Fields"
          disabled={isEmpty(summaryMenuItems)}
          value={formik.values.summaryFields}
          menuItems={summaryMenuItems}
          onChange={(e) => !isEmpty(e.target.value) && formik.setFieldValue('summaryFields', e.target.value)}
        />
      </Collapse>
      <Grid item xs={12}>
        <Button children="Reset" variant="contained" style={{ marginRight: '16px' }} onClick={() => resetFilters()} />
        <CustomButtonContained children="Create" onClick={() => formik.submitForm()} />
      </Grid>
    </Grid>
  );
};

export default withStyles(() => ({
  collapseWrapper: { padding: '12px' },
}))(PdfReportsNew);
