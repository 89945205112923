import { get } from 'lodash';

import { ASC } from 'modules/shared/constants';
import { ASSET_FIELDS, PRODUCT_FIELDS } from 'modules/assetLook/constants';

const sortItems = (data, orderBy, direction) => {
  const path =
    Object.values(PRODUCT_FIELDS).includes(orderBy) || Object.values(ASSET_FIELDS).includes(orderBy)
      ? [orderBy]
      : ['list_data', orderBy];

  return data
    .map((item) => item)
    .sort((a, b) => {
      if (get(a, path) < get(b, path)) {
        return direction === ASC ? 1 : -1;
      }

      if (get(a, path) > get(b, path)) {
        return direction === ASC ? -1 : 1;
      }

      return 0;
    });
};

export default sortItems;
