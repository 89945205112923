const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
  },
  tooltips: {
    callbacks: {
      title: () => '',
      label: ({ index: i }, data) => `${data.labels[i]}: ${data.datasets[0].data[i]}`,
    },
  },
};

export default options;
