/* eslint-disable camelcase */
import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/shared/components';
import { ArchiveItem, LiveCounter } from 'modules/liveLook/components';
import IFrameModal from 'modules/liveLook/components/iFrame';

const ArchivePage = ({ archives, companyId }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Archives" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
          <Grid item>
            <LiveCounter counter={3} />
          </Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        {archives.map(({ date_from, date_to, id }) => (
          <ArchiveItem companyId={companyId} startDate={date_from} endDate={date_to} id={id} />
        ))}
      </Grid>
    </Grid>
  );
};

export default ArchivePage;
