import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import URLS from 'pages/constants/urls';

import ReportItem from './ReportItem';

const ReportItemContainer = ({ reportId, ...props }) => {
  const history = useHistory();

  const titleMatch = useRouteMatch(URLS.workLook.title);
  const sectionMatch = useRouteMatch(URLS.workLook.section);
  const pdfReportsMatch = useRouteMatch(URLS.workLook.pdfReports);

  const { typeId } = useParams();
  const activeReportId = get(titleMatch, 'params.reportId', false);
  const activeSectionId = get(sectionMatch, 'params.sectionId', undefined);

  const isActiveReport = reportId === Number(activeReportId);

  const [expand, setExpand] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isActiveReport ? setExpand(true) : setExpand(false);
  }, [activeReportId, isActiveReport]);

  const _onReportItemClick = () => {
    setExpand(!expand);

    if (!isActiveReport)
      history.push({
        pathname: URLS.workLook.title.replace(':typeId', typeId).replace(':reportId', reportId),
      });
  };

  return (
    <ReportItem
      typeId={typeId}
      reportId={reportId}
      sectionId={Number(activeSectionId)}
      isActiveReport={isActiveReport}
      isAccordionOpen={expand && isActiveReport}
      _onReportItemClick={_onReportItemClick}
      reportTitleMatch={titleMatch && titleMatch.isExact}
      pdfReportsMatch={pdfReportsMatch}
      {...props}
    />
  );
};

export default ReportItemContainer;
