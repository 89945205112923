import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import { useStyles } from '../styles';
import './dataWidget.scss';

const DataWidget = ({ description, data, subTitle, title, isLoading }) => {
  const classes = useStyles();

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && isEmpty(data)}>
        <Paper className="widgetContainer" elevation={2}>
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <div className="widgetContainer">
          <div className="title">
            <Typography variant="h6" component="span" classes={{ root: classes.title }}>
              {title}
            </Typography>
          </div>
          <div className="subtitle">
            <Typography variant="body1" component="span" classes={{ root: classes.subTitle }}>
              {subTitle}
            </Typography>
          </div>
          <div className="description">
            <Typography variant="body2" component="span" classes={{ root: classes.description }}>
              {description}
            </Typography>
            <div>
              {Object.keys(data).map((key, i) => (
                <div key={i}>
                  <Typography variant="body1" component="span" classes={{ root: classes.values }}>
                    {`${key}: `}
                  </Typography>
                  <Typography variant="body1" component="span" classes={{ root: classes.values }}>
                    {data[key]}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default DataWidget;
