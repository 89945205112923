/* eslint-disable camelcase */
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { IconButton, Typography, withStyles } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { useSharedSelector } from 'modules/shared/hooks';
import { useSelector } from 'react-redux';
import { getUser } from 'modules/auth/selectors';

import Menu from './Menu';
import WorkLookItem from './WorkLookItem';

import config from './config';
import theme from './NavBar.module.scss';

const WhiteTextTypography = withStyles({
  root: {
    color: '#F79540',
  },
})(Typography);

const NavBar = () => {
  const { location } = window;
  const { user } = useSelector(getUser);
  const anotherUser = window.localStorage.getItem('anotherUser');
  const { email } = user;
  const [portalSections] = useSharedSelector(['portalSection']);

  const portalSectionsName = portalSections.map(({ name }) => name);
  const configNames = config.map(({ name }) => name);

  const result = config.filter((section) => portalSectionsName.includes(section.name));
  const workLookTypes = portalSections.filter((section) => !configNames.includes(section.name));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <nav className={theme.container}>
      <div>
        <If condition={anotherUser}>
          <WhiteTextTypography variant="body1">Logged In as {email}</WhiteTextTypography>
        </If>
      </div>
      <div>
        {result.map(({ pathname, label, linkedPages, state }) => {
          // TODO: Use the "useRouteMatch" hook, after the "Location" and "Space Look" routing refactoring
          const isActive = linkedPages && linkedPages.some((link) => location.pathname.indexOf(link) !== -1);

          return (
            <Link
              key={label}
              to={{
                pathname,
                state,
              }}
              className={cn(theme.navItem, isActive && theme.active)}
            >
              {label}
            </Link>
          );
        })}
        {workLookTypes.map((type) => (
          <WorkLookItem key={type.id} theme={theme} {...type} />
        ))}
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          style={{ marginLeft: '26px' }}
          size="small"
          onClick={handleClick}
        >
          <AccountCircleIcon style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
        </IconButton>

        <Menu anchorEl={anchorEl} handleClose={handleClose} />
      </div>
    </nav>
  );
};

export default NavBar;
