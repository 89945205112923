/* eslint-disable no-console */
import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';
import { useDispatch } from 'react-redux';
import {
  SET_GRAPH_ID,
  SET_MODIFIERS_DATA,
  SET_GRAPH_SERIES_LIST,
  SET_MODIFIERS_FIELDS,
  SET_MODIFIERS_GROUP_FIELDS,
  SET_GRAPH_GROUP_TYPE,
  SET_MODIFIERS_LEGENDS,
  SET_GRAPH_TYPE,
} from '../actions';
import getModifierFields from './getModifierFields';
import getModifierGroupFields from './getModifierGroupFields';
import getModifierLegends from './getModifierLegends';

const useModifierGraph = (isModal, graphId, graphData, type, typeOfGroup) => {
  if (isModal) {
    return { onClick: null };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  const url = ENDPOINTS.workLook.modifiersList.replace(':graphId', graphId);
  const seriesList = ENDPOINTS.workLook.seriesList.replace(':graphId', graphId);

  const onClick = () => {
    const modifierFields = getModifierFields(graphData, type);
    const modifierGroupFields = getModifierGroupFields(graphData, type);
    const modifierLegends = getModifierLegends(graphData, type);

    API.get(url)
      .then(({ data }) => {
        dispatch(SET_MODIFIERS_DATA(data));
        dispatch(SET_GRAPH_TYPE(type));
        dispatch(SET_MODIFIERS_LEGENDS(modifierLegends));
        dispatch(SET_GRAPH_ID(graphId));
        dispatch(SET_MODIFIERS_FIELDS(modifierFields));
        dispatch(SET_GRAPH_GROUP_TYPE(typeOfGroup));
        dispatch(SET_MODIFIERS_GROUP_FIELDS(modifierGroupFields));
        dispatch(showModal(modalCodes.WL_MODIFIERS_GRAPH_MODAL));
      })
      .catch((error) => {
        console.log(error);
      });
    API.get(seriesList)
      .then(({ data }) => {
        dispatch(SET_GRAPH_SERIES_LIST(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { onModifierClick: onClick };
};

export default useModifierGraph;
