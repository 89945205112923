import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_MEETS_PEAK_AND_AVG_UTIL_LIVE = createAsyncAction(createAction('FETCH_MEETS_PEAK_AND_AVG_UTIL_LIV'));

const fetchMeetsPeakAndAvgUtilLive = (
  dispatch,
  isCanceled,
  { companyId, dateAfter, dateBefore, workingHours, query, archive },
) => {
  dispatch(FETCH_MEETS_PEAK_AND_AVG_UTIL_LIVE.pending());

  const url = ENDPOINTS.liveLook.meetingBySubtype
    .replace(':companyId', companyId)
    .concat(`?date_after=${dateAfter}`)
    .concat(`&date_before=${dateBefore}`)
    .concat(workingHours ? `&work_hours=True` : '')
    .concat(workingHours ? `&exclude_weekend=True` : '')
    .concat(query ? `&${query}` : '')
    .concat(archive ? `&archive_id=${archive}` : '');

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_MEETS_PEAK_AND_AVG_UTIL_LIVE.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_MEETS_PEAK_AND_AVG_UTIL_LIVE.failure());
    });
};

export default fetchMeetsPeakAndAvgUtilLive;
