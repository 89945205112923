import GRAPH_RANGE from 'modules/liveLook/constants/graphRange';
import { format } from 'date-fns';

const formatDate = (date) => {
  const [month, day, year] = format(new Date(date), 'P').split('/');
  return `${year}-${month}-${day}`;
};

const getLastWeekDates = (range) => {
  const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
  const beforeOneWeek2 = new Date(beforeOneWeek);
  const day = beforeOneWeek.getDay();
  const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);

  const lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
  const lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

  const mondayFourWeeks = new Date(beforeOneWeek.setDate(diffToMonday - 21));
  const lastDayPreviousMonth = new Date(new Date().setDate(0));
  const firstDayOfMonth = new Date(new Date().setDate(1));
  const firstDayFourMonth = new Date(firstDayOfMonth.setMonth(firstDayOfMonth.getMonth() - 4));

  const customMonday = formatDate(lastMonday);
  const customFourMonday = formatDate(mondayFourWeeks);
  const customSunday = formatDate(lastSunday);
  const customLastDayPreviousMonth = formatDate(lastDayPreviousMonth);
  const customFirstDayFourMonth = formatDate(firstDayFourMonth);

  if (range === GRAPH_RANGE.FOUR_WEEKS) return { customMonday: customFourMonday, customSunday };
  if (range === GRAPH_RANGE.FOUR_MONTHS)
    return { customMonday: customFirstDayFourMonth, customSunday: customLastDayPreviousMonth };
  return { customMonday, customSunday };
};

export default getLastWeekDates;
