/* eslint-disable camelcase */
import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { ALL_BUILDINGS, ALL_PRODUCTS } from 'modules/assetLook/constants';

const onSubmit = (formValues, categories, companyId, tags) => {
  const buildings = categories.by_building
    .filter(({ building_id }) => formValues.buildings.includes(building_id))
    .map((building) => ({
      ...building,
      categories: building.categories.filter(({ category_id }) => formValues.categories.includes(category_id)),
    }));

  if (formValues.buildings.includes(ALL_BUILDINGS.id)) {
    buildings.unshift({
      building__name: ALL_BUILDINGS.label,
      building__order: 0,
      building_id: ALL_BUILDINGS.id,
      categories: categories.all.filter(({ category_id }) => formValues.categories.includes(category_id)),
    });
  }

  if (formValues.categories.includes(ALL_PRODUCTS.id)) {
    buildings.forEach((building) =>
      building.categories.unshift({
        category__name: ALL_PRODUCTS.label,
        category__order: 0,
        category_id: ALL_BUILDINGS.id,
      }),
    );
  }

  const url = ENDPOINTS.assetLook.pdf.replace(':companyId', companyId);
  const data = {
    pdf_data: JSON.stringify({
      buildings,
      activeFilters: formValues.activeFilters.filter((filter) => filter.values.length),
      summaryFields: formValues.summaryFields,
      tags,
    }),
    type: formValues.reportType,
  };

  return API.post(url, data);
};

export default onSubmit;
