import { cloneDeep } from 'lodash';
import { roundToFloat } from 'utils';

import { isPieValueToOmit } from 'modules/spaceLook/utils';

const PC_IN_USE = 'PC In Use';
const LAPTOP_IN_USE = 'Laptop In Use';

const getMostCommonActivities = (data) => {
  let dataCopy = cloneDeep(data)
    .filter((item) => !isPieValueToOmit(item.result))
    .map((item) => {
      const label = item.result.match(/In Use \((.*)\)/i);

      return {
        label: (label && label[1]) || item.result,
        value: item.percent_without_vacant_and_sol || item.percent_without_vacant,
      };
    });

  const PCData = dataCopy.find((item) => item.label === PC_IN_USE);
  const LaptopData = dataCopy.find((item) => item.label === LAPTOP_IN_USE);

  if (PCData && LaptopData) {
    dataCopy = dataCopy.filter((item) => ![PC_IN_USE, LAPTOP_IN_USE].includes(item.label));

    const computerUsage = {
      label: 'Computer Usage',
      value: roundToFloat(PCData.value + LaptopData.value),
    };
    dataCopy.push(computerUsage);
  }

  const dataSorted = dataCopy.sort((a, b) => b.value - a.value);
  if (dataSorted.length === 1) return [dataSorted, null];

  const theMostCommonList = dataCopy.filter((item) => item.value === dataSorted[0].value);
  if (theMostCommonList.length >= 2) return [theMostCommonList, null];

  const secondTheMostCommonList = dataCopy.filter((item) => item.value === dataSorted[1].value);
  return [theMostCommonList, secondTheMostCommonList];
};

export default getMostCommonActivities;
