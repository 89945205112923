/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { getDeskTypesQuantity } from 'modules/spaceLook/selectors';

import theme from './ReportNavItem.module.scss';

const ReportNavItem = ({ label, icon, items, activeReportId, pathname: currentPath }) => {
  const [open, setOpen] = useState(true);
  const deskTypesQuantity = useSelector(getDeskTypesQuantity);

  return (
    <>
      <ListItem onClick={() => setOpen(!open)} className={theme.listItem}>
        <ListItemIcon className={theme.icon}>
          <img src={icon} alt="Report navigation icon." />
        </ListItemIcon>
        <ListItemText primary={label} primaryTypographyProps={{ className: theme.text }} />
        {open ? <ArrowDropUpIcon className={theme.arrow} /> : <ArrowDropDownIcon className={theme.arrow} />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {items.map(({ text, path }) => {
            if (text === 'By Type' && deskTypesQuantity < 2) return;

            // eslint-disable-next-line consistent-return
            return (
              <Link to={path.replace(':id', activeReportId)} key={path}>
                <ListItem className={theme.listSubItem}>
                  <ListItemText
                    primary={text}
                    inset
                    className={theme.textContainer}
                    primaryTypographyProps={{
                      className: cn(theme.text, {
                        [`${theme.active}`]: path.replace(':id', activeReportId) === currentPath,
                      }),
                    }}
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default ReportNavItem;
