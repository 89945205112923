import { requestStatuses } from 'modules/shared/constants';
import { FETCH_ARCHIVES } from 'modules/liveLook/actions';

export const initialState = {
  requestStatus: requestStatuses.default,
  data: [],
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_ARCHIVES.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_ARCHIVES.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload,
      };
    case FETCH_ARCHIVES.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    default:
      throw new Error();
  }
}

export default reducer;
