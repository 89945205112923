import React from 'react';

import { Grid } from '@material-ui/core';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { useCustomPdfList } from 'modules/liveLook/hooks';

import { DownloadsList } from 'modules/shared/components';
import { useSelector } from 'react-redux';
import { getActiveCompany } from '../../../../../../modules/shared/selectors';

const History = () => {
  const [customPdfList, privatePdfList, standardPdfList, setCustomPdfList, setPrivatePdfList, setStandardPdfList] =
    useCustomPdfList();
  const { id: companyId } = useSelector(getActiveCompany);
  const getUrl = ENDPOINTS.liveLook.pdfList.replace(':companyId', companyId);
  const getPrivateUrl = ENDPOINTS.liveLook.privatePdfList.replace(':companyId', companyId);
  const getStandardUrl = ENDPOINTS.liveLook.standardPdfList.replace(':companyId', companyId);

  const dropPdfLists = () => {
    setPrivatePdfList(null);
    setCustomPdfList(null);
    setStandardPdfList(null);
  };

  const mutatePdfList = () => {
    API.get(getUrl)
      .then(({ data }) => setCustomPdfList(data))
      .then(() => API.get(getPrivateUrl))
      .then(({ data }) => setPrivatePdfList(data))
      .then(() => API.get(getStandardUrl))
      .then(({ data }) => setStandardPdfList(data))
      .catch((err) => err);
  };

  const _onItemDelete = (pdfReportId) => {
    const deleteUrl = ENDPOINTS.liveLook.pdfData.replace(':pdfId', pdfReportId);
    dropPdfLists();

    API.delete(`${deleteUrl}/`)
      .then(() => mutatePdfList())
      .catch((err) => err);
  };

  const _onPinReportClick = (reportId, isPinned) => {
    const getPinnedUrl = ENDPOINTS.liveLook.pinnedPdf.replace(':pdfId', reportId);
    dropPdfLists();

    if (isPinned) {
      API.delete(`${getPinnedUrl}`)
        .then(() => mutatePdfList())
        .catch((err) => err);
    } else {
      API.post(`${getPinnedUrl}`)
        .then(() => mutatePdfList())
        .catch((err) => err);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DownloadsList
          title="Standard Report"
          emptyListPlaceholder="No standard report yet"
          items={standardPdfList}
          skeletonItems={3}
          _onItemDelete={_onItemDelete}
          deleteTitle="Delete standard report?"
          deleteText="This operation can't be undone"
        />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <DownloadsList
            _onPinReportClick={_onPinReportClick}
            isLiveLook
            title="Public Reports"
            items={customPdfList}
            emptyListPlaceholder="No public reports yet"
            skeletonItems={5}
            _onItemDelete={_onItemDelete}
            deleteTitle="Delete public report?"
            deleteText="This operation can't be undone"
          />
        </Grid>
        <Grid item xs={6}>
          <DownloadsList
            _onPinReportClick={_onPinReportClick}
            isLiveLook
            isPrivate
            title="Private Reports"
            items={privatePdfList}
            emptyListPlaceholder="No private reports yet"
            skeletonItems={4}
            _onItemDelete={_onItemDelete}
            deleteTitle="Delete private report?"
            deleteText="This operation can't be undone"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default History;
