import React from 'react';
import { CircleMarker, Popup } from 'react-leaflet';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import renderPopupData from '../utils';

const gradient = { 0.1: 'green', 0.8: 'orange', 1.0: 'red' };

function HeatComponent({ data, maxIntensity, dataValue }) {
  return (
    <>
      {data.map(({ id, coordinates, ...rest }) => {
        return (
          <CircleMarker key={id} center={coordinates} color="transparent" radius={30}>
            <Popup>{renderPopupData(rest)}</Popup>
          </CircleMarker>
        );
      })}
      <HeatmapLayer
        fitBoundsOnLoad
        fitBoundsOnUpdate
        gradient={gradient}
        points={data}
        longitudeExtractor={(b) => b.coordinates[1]}
        latitudeExtractor={(b) => b.coordinates[0]}
        intensityExtractor={(b) => {
          return parseFloat(b[dataValue]);
        }}
        minOpacity={0.2}
        max={maxIntensity}
      />
    </>
  );
}

export default HeatComponent;
