import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { URLS } from 'pages/constants';
import { Box, Button, IconButton, ThemeProvider, Typography, Modal, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { btnsTheme } from 'modules/modalsSystem/components/modalsWL/options';
import { API } from 'modules/shared/utils';
import { useDispatch } from 'react-redux';
import { LOG_OUT, SET_AS_ANOTHER_USER } from 'modules/auth/actions';
import { ENDPOINTS } from 'modules/shared/constants';
import theme from './resetPasswordModal.module.scss';

const validationSchema = Yup.object({
  old_password: Yup.string().required('Current password is required'),
  new_password: Yup.string()
    .required('New password is required')
    .required('New password is required')
    .matches(/^(?=.*[a-z].*[a-z].*[a-z])/, 'Your password must contain at least 3 lowercase characters')
    .matches(/^(?=.*[A-Z].*[A-Z])/, 'Your password must contain at least 2 uppercase characters')
    .matches(/^(?=.*\d.*\d)/, 'Your password must contain at least 2 numbers')
    .min(8, 'Password must be at least 8 characters long')
    .test(
      'submitError',
      'Failed to change password. Please try again.',
      (value) => value !== 'Failed to change password. Please try again.',
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const ResetPasswordModal = ({ onClose }) => {
  const [submitError, setSubmitError] = useState('');
  const [isSuccessful, setIsSuccessful] = useState('');
  const getToken = window.localStorage.getItem('userToken');
  const anotherUser = window.localStorage.getItem('anotherUser');
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };

  const handleSubmit = (values) => {
    const url = ENDPOINTS.auth.resetPassword;
    const data = new FormData();
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    Object.entries(values).map(([key, value]) => {
      return data.append(key, value);
    });
    API.patch(url, data, config)
      .then((res) => {
        setSubmitError('');
        setIsSuccessful(true);
      })
      .catch((error) => {
        setSubmitError(error.response.data.old_password[0]);
      });
  };

  const clearData = () => {
    window.localStorage.removeItem('userToken');
    history.push(URLS.auth.login);
    dispatch(LOG_OUT());
  };

  const clearAnotherUser = () => {
    dispatch(SET_AS_ANOTHER_USER(false));
    window.localStorage.removeItem('asUserToken');
    window.localStorage.removeItem('anotherUser');
  };

  const signOut = () => {
    if (!isEmpty(getToken) && anotherUser) {
      clearAnotherUser();
      window.location.reload(false);
    }
    if (isEmpty(getToken) && anotherUser) {
      clearAnotherUser();
      clearData();
    }
    if (!isEmpty(getToken) && !anotherUser) {
      clearData();
    }
    onClose();
    // if (isEmpty(getToken) && !anotherUser) {
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 10,
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };
  return (
    <Modal open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Box sx={style}>
        <div className={theme.header}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            RESET PASSWORD
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <If condition={!isSuccessful}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ errors, touched }) => (
              <Form>
                <div>
                  <Field
                    as={TextField}
                    type="password"
                    name="old_password"
                    label="Old Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!(touched.old_password && errors.old_password) || submitError}
                    helperText={(touched.old_password && errors.old_password) || submitError}
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    type="password"
                    name="new_password"
                    label="New Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!(touched.new_password && errors.new_password)}
                    helperText={touched.new_password && errors.new_password}
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    type="password"
                    name="confirm_password"
                    label="Confirm Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!(touched.confirm_password && errors.confirm_password)}
                    helperText={touched.confirm_password && errors.confirm_password}
                  />
                </div>

                <div className={theme.btnWrapper}>
                  <Button type="submit" color="primary" variant="outlined">
                    Reset
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </If>
        <If condition={isSuccessful}>
          <div className={theme.succesWrpper}>
            <Typography variant="h6" component="h3">
              Your password has been successfully changed
            </Typography>
          </div>
          <div className={theme.btnWrapper}>
            <Button onClick={signOut} color="primary" variant="outlined">
              Okay
            </Button>
          </div>
        </If>
      </Box>
    </Modal>
  );
};

export default ResetPasswordModal;
