import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './StatsTableCell.scss';

const StatsTableCell = ({ label, pdfMode }) => {
  return <span className={cn('StatsTableCell', { pdf: pdfMode })}>{label}</span>;
};

StatsTableCell.defaultProps = {
  label: 'N/A',
};

StatsTableCell.propTypes = {
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default StatsTableCell;
