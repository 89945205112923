import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { fetchBenchmarkingOptions } from 'modules/spaceLook/actions';
import {
  getBenchmarkingOptions,
  getBenchmarkingSelectedFilters,
  getBenchmarkingAppliedFilters,
} from 'modules/spaceLook/selectors';

import BenchmarkingFilter from './BenchmarkingFilter';

const BenchmarkingFilterContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { options } = useSelector(getBenchmarkingOptions);
  const { selectedFilters } = useSelector(getBenchmarkingSelectedFilters);
  const { benchmarkingAppliedFilters } = useSelector(getBenchmarkingAppliedFilters);

  useEffect(() => {
    if (!options) fetchBenchmarkingOptions(dispatch, id);
    // eslint-disable-next-line
  }, []);

  return (
    <BenchmarkingFilter
      options={options}
      selectedFilters={selectedFilters}
      isApplyDisabled={isEqual(benchmarkingAppliedFilters, selectedFilters)}
      isResetDisabled={
        options &&
        selectedFilters.length === options.self_historic.length &&
        // eslint-disable-next-line no-shadow
        options.self_historic.every(({ id }) => selectedFilters.includes(id))
      }
    />
  );
};

export default BenchmarkingFilterContainer;
