import React from 'react';
import defaultLogo from 'assets/images/icons/logo-with-text.svg';

import './StaticHeaderBanner.scss';

const StaticHeaderBanner = ({ logoUrl = defaultLogo }) => {
  const styles = {
    backgroundImage: `url(${logoUrl})`,
  };

  return (
    <section className="StaticHeaderBanner">
      <div style={styles} className="static-header-banner" />
    </section>
  );
};

export default StaticHeaderBanner;
