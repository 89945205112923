import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import Chart from 'chart.js';
// ChartDataLabels is required in order to use the "label plugin" in charts doorsDailyConfig

import Pages from 'pages';
import theme from 'assets/theme';

import 'assets/variables.scss';
import 'assets/reset.scss';
import 'assets/base.scss';
import 'assets/typography.scss';
import 'assets/animations.scss';
import 'assets/classes.scss';

import store from './store';

Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  display: false,
});

export default () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <Pages />
      </Router>
    </ThemeProvider>
  </Provider>
);
