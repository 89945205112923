import React from 'react';
import { Typography } from '@material-ui/core';

const Paragraph = ({ children, pdfMode }) => (
  <Typography variant={pdfMode ? 'body2' : 'body1'} paragraph={!pdfMode}>
    {children}
  </Typography>
);

export default Paragraph;
