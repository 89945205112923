/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import cn from 'classnames';
import { Typography, Box } from '@material-ui/core';

import theme from './PieCustomLegend.module.scss';

const PieCustomLegend = ({ pieData, lineThrough, onItemClick, pdfMode }) => {
  const legendData = pieData.labels.map((label, i) => ({
    label,
    color: pieData.datasets[0].backgroundColor[i],
  }));

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      {legendData.map(({ label, color }, i) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div onClick={() => onItemClick(i)} key={label + color} className={theme.legendItem}>
          <div className={theme.icon} style={{ backgroundColor: `${color}` }} />
          <Typography variant={pdfMode ? 'body2' : 'body1'} className={theme.label}>
            <Box
              component="span"
              fontWeight="fontWeightBold"
              className={cn({ [`${theme.lineThrough}`]: lineThrough.includes(i) })}
            >
              {label}
            </Box>
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default PieCustomLegend;
