import React from 'react';
import { Collapse } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';

import { SidebarListItem } from 'modules/shared/components';
import { CategoryList } from 'modules/assetLook/components/Sidebar/components';

const AllBuildings = ({ isAccordionOpen, _onItemClick, ...props }) => {
  return (
    <>
      <SidebarListItem
        Icon={BusinessIcon}
        label="All Buildings"
        isAccordionOpen={isAccordionOpen}
        _onItemClick={_onItemClick}
      />

      <Collapse in={isAccordionOpen} timeout="auto" unmountOnExit>
        <CategoryList {...props} />
      </Collapse>
    </>
  );
};

export default AllBuildings;
