import React from 'react';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { PageHeader, ReportDataCards } from 'modules/spaceLook/components';

import { reportPageTypes } from 'modules/spaceLook/constants';

import theme from './Heatmap.module.scss';

const Heatmap = ({ pdfMode, heatmap, databoxes }) =>
  heatmap.map(({ id, image, name }) => (
    <div key={id} className={cn(theme.container, { pdfPage: pdfMode, hasPageCounter: pdfMode })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={12}>
          <PageHeader title="Heatmaps" text={name} />
        </Grid>

        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.desks} />
        </Grid>

        <Grid item xs={12} align="center">
          <img className={theme.image} src={image} alt="Space usage heatmap." />
        </Grid>
      </Grid>
    </div>
  ));

export default Heatmap;
