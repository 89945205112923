import { requestStatuses } from 'modules/shared/constants';
import { GRAPH_SCALES, GRAPH_VALUES, GRAPH_SWITCHER } from 'modules/liveLook/constants';

import {
  FETCH_DESK_OVER_TIME,
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
  SET_GRAPH_SWITCHER,
  FETCH_DESK_DATABOXES,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
  FETCH_DESK_VARIABLES,
} from 'modules/liveLook/actions';

export const initialState = {
  requestStatus: requestStatuses.default,
  databoxRequestStatus: requestStatuses.default,
  textReportRequestStatus: requestStatuses.default,
  deskVariableRequestStatus: requestStatuses.default,
  data: [],
  scale: GRAPH_SCALES.halfAnHour,
  value: GRAPH_VALUES.max,
  returnPercentageSwitcher: GRAPH_SWITCHER.percentages,
  deskDataboxes: [],
  editableTextReport: '',
  textVariables: {
    deskVariables: {},
  },
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_DESK_OVER_TIME.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_OVER_TIME.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload,
      };
    case FETCH_DESK_OVER_TIME.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    case FETCH_DESK_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        deskDataboxes: payload,
      };
    case FETCH_DESK_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_DESK_VARIABLES.pending().type:
      return {
        ...state,
        deskVariableRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_VARIABLES.success().type:
      return {
        ...state,
        deskVariableRequestStatus: requestStatuses.success,
        textVariables: { ...state.textVariables, ...{ deskVariables: payload } },
      };
    case FETCH_DESK_VARIABLES.failure().type:
      return {
        ...state,
        deskVariableRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SET_GRAPH_SCALE().type:
      return {
        ...state,
        scale: payload,
      };
    case SET_GRAPH_VALUE().type:
      return {
        ...state,
        value: payload,
      };
    case SET_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcher: payload,
      };
    default:
      throw new Error();
  }
}

export default reducer;
