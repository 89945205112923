/* eslint-disable camelcase */
import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';

import RequestComplete from './RequestComplete';

const RequestCompleteContainer = ({ onClose, orderId, target_location, reloadOrderList }) => {
  const [errorCode, setErrorCode] = useState(null);

  const validationSchema = yup.object({
    location: yup.number('Select the location').required('Location is required'),
    status: yup.string('Select the status').required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      location: target_location || 0,
      status: target_location ? 'None' : 'rm',
      tagsFlag: true,
      tags: [],
    },
    validationSchema,
    onSubmit: ({ location, status, tagsFlag, tags }, formikHelpers) => {
      setErrorCode(null);

      const url = ENDPOINTS.assetLook.orderEdit.replace(':orderId', orderId);
      const data = {
        action: 'complete',
        resulting_status: status,
      };

      if (location) data.location_id = location;
      if (!tagsFlag) data.tags = tags;

      API.patch(url, data)
        .then(() => {
          formikHelpers.setSubmitting(false);
          onClose();
          reloadOrderList();
        })
        .catch(({ response }) => {
          formikHelpers.setSubmitting(false);
          setErrorCode(response.status);
        });
    },
  });

  return <RequestComplete formik={formik} onClose={onClose} errorCode={errorCode} />;
};

export default RequestCompleteContainer;
