import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { GraphHeader } from 'components';
import { setOneBarGraphYMaxValue } from 'utils';
import { getPdfMode } from 'modules/spaceLook/selectors';

import options from './options';
import config from './config';

import theme from './DesksUsageBandingGraph.module.scss';

const DesksUsageBandingGraph = ({ graphData, label, barColor }) => {
  const pdfMode = useSelector(getPdfMode);

  const data = {
    labels: config.graphLabels,
    datasets: [
      {
        label: 'Quantity',
        backgroundColor: barColor,
        data: Object.values(graphData),
      },
    ],
  };

  options.scales.yAxes[0].ticks.max = setOneBarGraphYMaxValue(Object.values(graphData));

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      <GraphHeader label={label} />
      <div className={theme.graphWrapper}>
        <div className={theme.graph}>
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default DesksUsageBandingGraph;
