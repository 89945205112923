import { createReducer } from '@reduxjs/toolkit';

import { SET_PROPERTY_NAMES_MAP, FETCH_PORTAL_SECTION } from 'modules/shared/actions';

import { requestStatuses } from 'modules/shared/constants';

export const initState = {
  propertyNamesRequestStatus: requestStatuses.default,
  propertyNamesMap: [],
  portalSectionRequestStatus: requestStatuses.default,
  portalSection: [],
};

const sharedReducer = createReducer(initState, {
  [SET_PROPERTY_NAMES_MAP.pending]: (state) => ({
    ...state,
    propertyNamesRequestStatus: requestStatuses.pending,
  }),
  [SET_PROPERTY_NAMES_MAP.success]: (state, { payload }) => ({
    ...state,
    propertyNamesRequestStatus: requestStatuses.success,
    propertyNamesMap: payload,
  }),
  [SET_PROPERTY_NAMES_MAP.failure]: (state) => ({
    ...state,
    propertyNamesRequestStatus: requestStatuses.failure,
  }),

  [FETCH_PORTAL_SECTION.pending]: (state) => ({
    ...state,
    portalSectionRequestStatus: requestStatuses.pending,
  }),
  [FETCH_PORTAL_SECTION.success]: (state, { payload }) => ({
    ...state,
    portalSectionRequestStatus: requestStatuses.success,
    portalSection: payload,
  }),
  [FETCH_PORTAL_SECTION.failure]: (state) => ({
    ...state,
    portalSectionRequestStatus: requestStatuses.failure,
  }),
});

export default sharedReducer;
