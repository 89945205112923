/* eslint-disable camelcase */
import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import cn from 'classnames';

import { PageHeader, DetailsDataCards } from 'modules/spaceLook/components';
import { BuildingsMap } from 'modules/shared/components';

import config from './config';
import theme from './SurveyDetails.module.scss';

const SurveyDetails = ({ detailsData, pdfMode, propertyNamesMap }) => {
  const {
    property_data,
    survey_stats: { timeslots_per_day_desks, total_observations },
    partner_company_name,
    company_name,
    dates_data: { total_days },
  } = detailsData;

  const buildingsData = property_data.map(({ property_id, location_data: { latitude, longitude } }) => {
    const propertyInList = propertyNamesMap.find((item) => item.property_id === property_id);
    const propertyName = (propertyInList && propertyInList.property_name) || property_id;

    return {
      coords: [latitude, longitude],
      building: propertyName,
      info: [
        <li key="Building" className="buildings-map-tooltip-content-item">
          <span className="name">Building: </span>
          <span className="value">{propertyName}</span>
        </li>,
      ],
    };
  });

  const locationList = property_data
    .map(
      ({ location_data: { address, city } }, idx) =>
        `${address}, ${city}${property_data.length - 1 === idx ? '' : '; '}`,
    )
    .join('');

  return (
    <div className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={12}>
          <PageHeader title="Survey Details" text="Executive Summary" />
        </Grid>
        <Grid item xs={4}>
          <BuildingsMap buildingsData={buildingsData} />
        </Grid>
        <Grid item xs={8}>
          <DetailsDataCards detailsData={detailsData} />

          <div className={cn(theme.textBlock, { [`${theme.pdfMode}`]: pdfMode })}>
            <Typography variant={`${pdfMode ? 'body2' : 'body1'}`} paragraph>
              {`${partner_company_name || 'Baker Stuart'} were commissioned by ${company_name} to complete
             a ${total_days} day(s). SpaceLOOK utilisation survey of their office(s) at ${locationList}.
             Areas in scope were:`}
            </Typography>

            <ul>
              {config.areas.map((text) => (
                <li key={text}>
                  <Typography variant={`${pdfMode ? 'body2' : 'body1'}`}>
                    <Box fontWeight="fontWeightBold" component="span">
                      {text}
                    </Box>
                  </Typography>
                </li>
              ))}
            </ul>

            <Typography variant={`${pdfMode ? 'body2' : 'body1'}`} paragraph>
              {`Our Surveyors completed ${timeslots_per_day_desks} walkthroughs per day, making over 
             ${total_observations} observations, during which the Surveyors recorded the use of the various spaces.`}
            </Typography>
            <Typography variant={`${pdfMode ? 'body2' : 'body1'}`} paragraph>
              Use of the desk work spaces was classified as follows:
            </Typography>

            <ul>
              {config.usage.map((text) => (
                <li key={text}>
                  <Typography variant={`${pdfMode ? 'body2' : 'body1'}`}>
                    <Box fontWeight="fontWeightBold" component="span">
                      {text}
                    </Box>
                  </Typography>
                </li>
              ))}
            </ul>

            <Typography variant={`${pdfMode ? 'body2' : 'body1'}`} paragraph>
              Activities were recorded at all occupied desks, these include:
            </Typography>

            <ul>
              {config.activities.map((text) => (
                <li key={text}>
                  <Typography variant={`${pdfMode ? 'body2' : 'body1'}`}>
                    <Box fontWeight="fontWeightBold" component="span">
                      {text}
                    </Box>
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SurveyDetails;
