import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isDefault } from 'modules/shared/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  getBenchmarkingData,
  getBenchmarkingAppliedFilters,
  getBenchmarkingSelectedFilters,
  getPdfMode,
} from 'modules/spaceLook/selectors';
import {
  fetchBenchmarkingDataboxes,
  fetchBenchmarkingTextData,
  fetchBenchmarkingHistoricData,
} from 'modules/spaceLook/actions';
import { LoadingCondition } from 'modules/spaceLook/components';

import DesksBenchmarking from './DesksBenchmarking';

const DesksBenchmarkingContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fetchStart, setFetchStart] = useState(false);
  const pdfMode = useSelector(getPdfMode);
  const { dataRequestStatuses, data, shouldRefetchData } = useSelector(getBenchmarkingData);
  const { benchmarkingAppliedFiltersRequestStatus } = useSelector(getBenchmarkingAppliedFilters);
  const { selectedFiltersUrlParams } = useSelector(getBenchmarkingSelectedFilters);

  const isNoData = Object.values(data).some((dataItem) => !dataItem);
  const isLoading = Object.values(dataRequestStatuses).some((status) => status === requestStatuses.pending);
  const isFiltersHistoryLoading = [requestStatuses.default, requestStatuses.pending].includes(
    benchmarkingAppliedFiltersRequestStatus,
  );

  useEffect(() => {
    if (isFiltersHistoryLoading) return;

    if (isDefault(dataRequestStatuses.databoxes) || shouldRefetchData.databoxes) {
      fetchBenchmarkingDataboxes(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.textData) || shouldRefetchData.textData) {
      fetchBenchmarkingTextData(dispatch, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.historicData) || shouldRefetchData.historicData) {
      fetchBenchmarkingHistoricData(dispatch, id, selectedFiltersUrlParams);
    }
    setFetchStart(true);
  }, [
    benchmarkingAppliedFiltersRequestStatus,
    shouldRefetchData.databoxes,
    shouldRefetchData.textData,
    shouldRefetchData.historicData,
    isFiltersHistoryLoading,
    dataRequestStatuses.databoxes,
    dataRequestStatuses.textData,
    dataRequestStatuses.historicData,
    dispatch,
    id,
    selectedFiltersUrlParams,
  ]);

  return (
    <LoadingCondition isLoading={isLoading} isNoData={isNoData} fetchStart={fetchStart}>
      <DesksBenchmarking isLoading={isLoading} pdfMode={pdfMode} {...data} />
    </LoadingCondition>
  );
};

export default DesksBenchmarkingContainer;
