import { createReducer } from '@reduxjs/toolkit';

import { SET_CONTROL_CARD_DATA } from 'modules/workLook/actions';

const initState = {
  controlCardData: {},
};

export default createReducer(initState, {
  [SET_CONTROL_CARD_DATA]: (state, { payload }) => ({
    ...state,
    controlCardData: payload,
  }),
});
