import { isEmpty } from 'lodash';
import { format, utcToZonedTime } from 'date-fns-tz';

const INTEGER_FIELDS = ['desk_in_use', 'desk_used', 'desk_never_used'];
const PERCENTAGE_FIELDS = ['percentage_desk_in_use', 'percentage_desk_used', 'percentage_desk_never_used'];
const GRAPH_FIELDS = ['deskInUse', 'deskUsed', 'deskNeverUsed'];
const BACKGROUNDS = ['#58BFC7', '#F6A000', '#f1f1f1'];

export const getPeakValue = (deskGraphData) => {
  if (!deskGraphData?.data) return 0;
  const [element] = deskGraphData.data;
  const peakIntgerScale = element?.desk_in_use + element?.desk_used + element?.desk_never_used;

  const peakPercentagesScale =
    element?.percentage_desk_in_use + element?.percentage_desk_used + element?.percentage_desk_never_used;
  return { peakIntgerScale, peakPercentagesScale };
};

const getArrayOfProps = (arrayOfFields, data) => {
  return arrayOfFields.reduce((acc, item, index) => {
    return acc.concat([
      {
        type: 'bar',
        data: data.map((elementData) => elementData[item]),
        label: GRAPH_FIELDS[index],
        stack: 'standing costs',
        backgroundColor: BACKGROUNDS[index],
      },
    ]);
  }, []);
};

const setDataForGraph = (deskGraphData, isSwitcherInteger) => {
  if (isEmpty(deskGraphData)) return false;

  const { data } = deskGraphData;
  const itemDateLondon = utcToZonedTime(new Date(deskGraphData.peak_date).toISOString(), 'Europe/London');
  const title = `Peak day : ${format(itemDateLondon, 'dd MMMM yyyy', { timeZone: 'Europe/London' })}`;

  const labels = data.map(({ date }) => {
    // eslint-disable-next-line no-shadow
    const itemDateLondon = utcToZonedTime(new Date(date).toISOString(), 'Europe/London');
    return format(itemDateLondon, 'HH:mm', { timeZone: 'Europe/London' });
  });

  const datasets = isSwitcherInteger ? getArrayOfProps(INTEGER_FIELDS, data) : getArrayOfProps(PERCENTAGE_FIELDS, data);
  return { title, labels, datasets };
};

export default setDataForGraph;
