/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './RoundArrowButton.scss';

import arrow from '../../../assets/images/icons/to-left-grey-arrow.svg';

const RoundArrowButton = ({ onClick, additionalClass }) => {
  const classes = cn('RoundArrowButton', additionalClass);

  return (
    <div onClick={onClick} className={classes}>
      <button className="round-arrow-button" type="button">
        <img className="grey-arrow" src={arrow} alt="close" />
      </button>
    </div>
  );
};

RoundArrowButton.defaultProps = {
  // eslint-disable-next-line no-console
  onClick: () => console.log('onClick function'),
  additionalClass: '',
};

RoundArrowButton.propTypes = {
  onClick: PropTypes.func,
  additionalClass: PropTypes.string,
};

export default RoundArrowButton;
