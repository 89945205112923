import { createReducer } from '@reduxjs/toolkit';

import { SET_DETAIL_DATA } from 'modules/workLook/actions';

const initState = {
  widget: {},
};

export default createReducer(initState, {
  [SET_DETAIL_DATA]: (state, { payload }) => {
    return {
      ...state,
      widgetModal: payload,
      recordId: payload.index,
    };
  },
});
