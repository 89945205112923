import React from 'react';
import { Button } from '@material-ui/core';
import theme from './ConfirmModal.module.scss';

const ConfirmModal = (props) => {
  const { onSave, onToggle } = props;

  return (
    <div className={theme.container}>
      <div className={theme.textArea}>
        Are you sure you want to save changes for all the users?
        <br />
        <div className={theme.buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onToggle}
            style={{
              backgroundColor: '#004352',
              paddingTop: '10px',
              marginRight: '50px',
              minWidth: '88px',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSave}
            style={{
              backgroundColor: '#00aad2',
              paddingTop: '10px',
              minWidth: '88px',
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
