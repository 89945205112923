/* eslint-disable camelcase */
import React from 'react';
import { get, isEmpty } from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';

import URLS from 'pages/constants/urls';
import { isPositiveInteger } from 'modules/shared/utils';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID, PRODUCT_ID, ASSET_ID } from 'modules/assetLook/constants';

function RedirectHandler({ children }) {
  const location = useLocation();
  const [categories] = useAssetLookSelector(['common.categories']);
  const { buildingId, categoryId, productId, assetId } = useLocationSearchParams([
    BUILDING_ID,
    CATEGORY_ID,
    PRODUCT_ID,
    ASSET_ID,
  ]);

  const building = buildingId && categories.by_building.find(({ building_id }) => building_id === Number(buildingId));
  const category = categoryId && categories.all.find(({ category_id }) => category_id === Number(categoryId));
  const categoryWithinBuilding =
    building && building.categories.find(({ category_id }) => category_id === Number(categoryId));

  const firstBuildingFirstCategory = {
    pathname: URLS.assetLook.productList,
    search: `?${BUILDING_ID}=${categories.by_building[0].building_id}`.concat(
      isEmpty(categories.by_building[0].categories)
        ? ''
        : `&${CATEGORY_ID}=${categories.by_building[0].categories[0].category_id}`,
    ),
    state: { isBuilding: true },
  };

  return (
    <Choose>
      <When
        condition={
          get(location, 'state.fromNavBar', false) ||
          (buildingId && !isPositiveInteger(buildingId)) ||
          (categoryId && !isPositiveInteger(categoryId)) ||
          (productId && !isPositiveInteger(productId)) ||
          (assetId && !isPositiveInteger(assetId))
        }
      >
        <Redirect to={firstBuildingFirstCategory} />
      </When>
      <When
        condition={
          (buildingId && categoryId && categoryWithinBuilding) ||
          (buildingId && !categoryId && building) ||
          (!buildingId && categoryId && category && categories.by_building.length > 1) ||
          (!buildingId && !categoryId && categories.by_building.length > 1)
        }
      >
        {children}
      </When>
      <Otherwise>
        <Redirect to={firstBuildingFirstCategory} />
      </Otherwise>
    </Choose>
  );
}

export default RedirectHandler;
