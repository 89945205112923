import React from 'react';

const ValueComponent = ({ contentState, entityKey }) => {
  const {
    mention: { value },
  } = contentState.getEntity(entityKey).getData();

  return <span>{value}</span>;
};

export default ValueComponent;
