import { isEmpty } from 'lodash';

const getModifierLegends = (data, type) => {
  if (isEmpty(data)) {
    return [];
  }
  if (type === '18') {
    const legends = Object.keys(data[0]);
    const filteredLegends = legends.filter((key) => key !== 'date');
    return filteredLegends;
  }

  return [];
};

export default getModifierLegends;
