import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { UPDATE_TEXT_SUMMARY_CONTENT } from 'modules/workLook/actions';

const useOnTextEditorSave = (graphId) => {
  const dispatch = useDispatch();
  const { reportId, sectionId, pageId } = useParams();

  return (contentRaw) => {
    const url = ENDPOINTS.workLook.graphText.replace(':graphId', graphId);
    const data = { description: JSON.stringify(contentRaw) };

    return API.put(url, data).then(() => {
      dispatch(
        UPDATE_TEXT_SUMMARY_CONTENT({
          reportId,
          sectionId,
          pageId,
          graphId,
          description: data.description,
        }),
      );
    });
  };
};

export default useOnTextEditorSave;
