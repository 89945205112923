import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/auth/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_USER = createAsyncAction(createAction('FETCH_USER'));

const fetchUser = (dispatch) => {
  dispatch(FETCH_USER.pending());

  API.get(ENDPOINTS.auth.user)
    .then(({ data }) => dispatch(FETCH_USER.success(data)))
    .catch(() => dispatch(FETCH_USER.failure()));
};

export default fetchUser;
