/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import './externalLink.scss';
import { URL } from './constants';

const ExternalLink = ({ graphId, onClick, onModifierClick, isAdminRole, display = true }) => {
  const getTargetUrl = URL.replace(':graphId', graphId);

  return (
    <div className="iconsContainer">
      <If condition={display}>
        <div onClick={onModifierClick}>
          <SettingsSuggestIcon color="action" fontSize="medium" />
        </div>
      </If>
      <div onClick={onClick}>
        <BorderColorIcon color="action" fontSize="small" />
      </div>
      <a href={getTargetUrl} target="_blank" rel="noopener noreferrer">
        <If condition={isAdminRole}>
          <OpenInNewIcon color="action" fontSize="small" />
        </If>
      </a>
    </div>
  );
};

export default ExternalLink;
