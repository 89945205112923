import React from 'react';

import { Sidebar } from 'modules/assetLook/components';
import { NavBar } from 'modules/shared/components';

import theme from './AssetLookLayout.module.scss';

const AssetLookLayout = ({ children }) => (
  <div className={theme.root}>
    <div className={theme.header}>
      <NavBar />
    </div>

    <div className={theme.sidebar}>
      <Sidebar />
    </div>

    <div className={theme.content}>{children}</div>
  </div>
);

export default AssetLookLayout;
