import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Typography } from '@material-ui/core';
import { getBlankReport } from 'modules/workLook/selectors';
import { ENDPOINTS } from 'modules/shared/constants';
import { isLogged } from 'utils';
import style from './matchFields.module.scss';
import TableMatch from './components/TableMatch';

const useStyles = makeStyles(() => ({
  error: {
    marginTop: '10px',
    color: 'red',
  },
}));

const MatchFields = ({ onNext }) => {
  const classes = useStyles();
  const { blankReportData, reportFiles, additionalFile, mergedFields } = useSelector(getBlankReport);
  const [mergedTable, setMergedTable] = useState({ displayTable: false, infoData: [] });
  const newData = mergedFields.map((item) => ({ ...item }));
  const [errorResponse, setErrorResponse] = useState({ state: false, msg: '' });
  const getBaseRows = newData.filter((row) => {
    return row.table_alias === reportFiles[0].id;
  });
  const getBaseSelectors = getBaseRows.map(({ name, id }) => ({ name, id }));

  const getAdditionalRows = newData.filter((row) => {
    return row.table_alias === additionalFile?.additionalFile[0]?.id;
  });

  const groupBuyTableAlias = (arr) => {
    const sortedTableFields = arr?.sort((a, b) => a.table_alias - b.table_alias);
    const grouped = {};
    sortedTableFields.forEach((obj) => {
      const tableAlias = obj.table_alias;
      if (!grouped[tableAlias]) {
        grouped[tableAlias] = [];
      }
      grouped[tableAlias].push(obj);
    });
    return grouped;
  };

  const getAdditionalSelectors = getAdditionalRows.map(({ name, id }) => ({ name, id }));

  const [activeBaseField, setActiveBaseField] = useState();
  const [activeAdditionField, setActiveAdditionField] = useState({});
  const [tableKeys, setTableKeys] = useState({});
  const [lastItemSubmitter, setLastItemSubmitter] = useState(false);

  const handleBaseChange = (event) => {
    setActiveBaseField(event.target.value);
  };

  const handleAdditionChange = (event, tableAlias, isTableKey = false) => {
    const { value } = event.target;
    isTableKey
      ? setTableKeys((prev) => ({ ...prev, [tableAlias]: value }))
      : setActiveAdditionField((prev) => ({ ...prev, [tableAlias]: value }));
    if (isLastElement(tableAlias)) {
      setLastItemSubmitter((prev) => true);
    }
  };

  const isLastElement = (tableAlias) => {
    const tablesArr = additionalFile?.additionalFile.map((table) => table?.id);
    const lastTableAlias = tablesArr.pop();
    return +tableAlias === +lastTableAlias;
  };

  const mergeTables = (payload) => {
    setErrorResponse({ state: false, msg: '' });
    setMergedTable({ displayTable: false, infoData: [] });
    const url = ENDPOINTS.workLook.matchFields.replace(':reportId', blankReportData.id);
    const config = {
      method: 'put',
      url,
      headers: {
        Authorization: `Token ${isLogged()}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    };
    axios(config)
      .then(({ data }) => {
        setMergedTable({ displayTable: true, infoData: data });
      })
      .catch(({ response }) => {
        setErrorResponse({ state: true, msg: response.data.name || 'Please select another fields' });
      });
  };

  useEffect(() => {
    const additionalTables = additionalFile?.additionalFile;
    const result = [
      {
        id: reportFiles[0].id,
        name: 'Base table',
        is_base_table: true,
        report: blankReportData.id,
        relates_to: additionalTables[0]?.id,
        table_key: activeBaseField,
        related_table_key: activeAdditionField[additionalTables[0]?.id],
      },
    ];
    const additionalTablesPayload = [];
    for (let index = 0; index < additionalTables.length - 1; index++) {
      const relate = {
        id: additionalTables[index]?.id,
        name: additionalTables[index]?.name,
        is_base_table: false,
        report: blankReportData.id,
        relates_to: additionalTables[index + 1]?.id,
        table_key: tableKeys[additionalTables[index]?.id],
        related_table_key: activeAdditionField[additionalTables[index + 1]?.id],
      };
      additionalTablesPayload.push(relate);
    }
    if (lastItemSubmitter) {
      mergeTables([...result, ...additionalTablesPayload]);
      // setLastItemSubmitter(prev=>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAdditionField, tableKeys, lastItemSubmitter]);

  const onSubmitBtn = () => {
    onNext();
  };

  const HEADER_NAME = {
    name: 'Name',
    csv_name: 'Header in CSV',
    type: 'Type',
    is_filter: 'Use as filter',
  };

  return (
    <div style={{ height: '100%' }}>
      <If condition={errorResponse.state}>
        <Typography variant="body2" className={classes.error}>
          {errorResponse.msg}
        </Typography>
      </If>
      <div className={style.container}>
        <div className={style.tableContainer}>
          <TableMatch
            headerName={HEADER_NAME}
            rows={getBaseRows}
            selectors={getBaseSelectors}
            label="Base"
            value={activeBaseField}
            handleChange={handleBaseChange}
            selectBottom
          />
          {newData?.length &&
            additionalFile?.additionalFile?.map((tableObject, index) => {
              const table = groupBuyTableAlias(newData)[tableObject?.id];
              const additionalTables = additionalFile?.additionalFile;
              return (
                <TableMatch
                  key={`table_${index}`}
                  headerName={HEADER_NAME}
                  rows={table}
                  selectors={table.map(({ name, id }) => ({ name, id }))}
                  label={`${tableObject?.name}`}
                  value={activeAdditionField[tableObject?.id]}
                  handleChange={handleAdditionChange}
                  tableKeyValue={tableKeys[tableObject?.id]}
                  withTableKey={!isLastElement(tableObject?.id)}
                />
              );
            })}
        </div>
        <If condition={mergedTable.displayTable}>
          <div className={style.mergedContainer}>
            <TableMatch
              headerName={HEADER_NAME}
              rows={mergedTable.infoData}
              displaySelector={false}
              selectors={getAdditionalSelectors}
              label="Additional Table Field"
              value={activeAdditionField}
              handleChange={() => ''}
            />
          </div>
        </If>
      </div>
      <div className={style.btnWrapper}>
        <Button
          disabled={errorResponse.state}
          onClick={() => onSubmitBtn()}
          color="primary"
          variant="outlined"
          type="submit"
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>Submit</div>
        </Button>
      </div>
    </div>
  );
};

export default MatchFields;
