import { roundToFloat } from 'utils';
import { reportPageTypes } from 'modules/spaceLook/constants';
import { isEmpty } from 'lodash';

const SingleSpaceUsage = ({ locationsData, graphData, repeatN, pageType }) => {
  if (isEmpty(graphData)) return null;
  const dataToUse = pageType === reportPageTypes.desks ? graphData[repeatN] : locationsData[repeatN];

  const spaceName = dataToUse.category1 || dataToUse.location_id__type;
  const maxPercent = dataToUse.percent_max_utilization || dataToUse.percent_max;
  const max = dataToUse.max_utilization || dataToUse.max;
  const avgPercent = dataToUse.percent_avg_utilization || dataToUse.percent_avg;
  const avg = dataToUse.avg_utilization || dataToUse.avg;

  const roomsOrSeats = pageType === reportPageTypes.desks ? 'seats' : 'rooms';

  return `For ${spaceName} the maximum observed use was ${maxPercent}% (${roundToFloat(max)} ${roomsOrSeats} occupied)
     with an average use of ${avgPercent}% (${roundToFloat(avg)} ${roomsOrSeats} occupied).`;
};

export default SingleSpaceUsage;
