const config = {
  legend: [
    {
      iconType: 'round',
      label: 'Occupied',
      color: '#58BFC7',
    },
    {
      iconType: 'round',
      label: 'Unoccupied ',
      color: '#f1f1f1',
    },
    {
      iconType: 'round',
      label: 'Occupied (seats)',
      color: '#a2559d',
    },
  ],
};

export default config;
