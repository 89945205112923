import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';

// eslint-disable-next-line import/no-cycle
import ModalBody from './ModalBody';

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: '350px',
    maxWidth: '1400px',
  },
}));

const ModalBase = ({ open, isEmptyData, onClose, DialogProps, PaperProps, onSubmit, ...props }) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      onClose={onClose}
      PaperProps={{
        classes: { root: classes.paper },
        ...PaperProps,
      }}
      {...DialogProps}
    >
      <Choose>
        <When condition={isEmptyData}>
          <ModalBody {...props} onClose={onClose} isEmptyData={isEmptyData} />
        </When>
        <When condition={onSubmit}>
          <form onSubmit={onSubmit}>
            <ModalBody {...props} onSubmit={onSubmit} onClose={onClose} />
          </form>
        </When>
        <Otherwise>
          <ModalBody {...props} onClose={onClose} />
        </Otherwise>
      </Choose>
    </Dialog>
  );
};

export default ModalBase;
