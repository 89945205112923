const config = {
  legend: [
    {
      iconType: 'round',
      label: 'Average Occupancy',
      color: '#34BBDB',
    },
    {
      iconType: 'round',
      label: 'Peak Occupancy',
      color: '#0D6F85',
    },
    {
      iconType: 'round',
      label: 'Average Utilisation',
      color: '#f6a000',
    },
    {
      iconType: 'round',
      label: 'Peak Utilisation',
      color: '#b4cc04',
    },
  ],
};

export default config;
