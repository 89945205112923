/* eslint-disable camelcase */
import { Typography } from '@material-ui/core';
import React from 'react';
import theme from './InnerHeader.module.scss';

const InnerHeader = ({ redTitle, greenTitle, smallStyles, showHeader }) => {
  const getVariant = smallStyles ? 'body2' : 'subtitle2';

  return (
    <If condition={showHeader}>
      <div className={theme.container}>
        <div className={theme.colorsContainer}>
          <Typography variant={getVariant} className={theme.redText}>
            Red
          </Typography>
          <Typography variant={getVariant} className={theme.greenText}>
            Green
          </Typography>
        </div>
        <div className={theme.titlesWrapper}>
          <Typography variant={getVariant} className={theme.leftTitle}>
            {redTitle}
          </Typography>
          <Typography variant={getVariant} className={theme.rightTitle}>
            {greenTitle}
          </Typography>
        </div>
      </div>
    </If>
  );
};

export default InnerHeader;
