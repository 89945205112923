import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isDefault } from 'modules/shared/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  getAppliedFilters,
  getBreakoutByBuildingAndFloorData,
  getSelectedFilters,
  getPdfMode,
} from 'modules/spaceLook/selectors';
import {
  fetchBreakoutDataboxes,
  fetchBreakoutFloorUtilGraphData,
  fetchBreakoutBuildingUtilGraphData,
} from 'modules/spaceLook/actions';
import { LoadingCondition } from 'modules/spaceLook/components';

import BreakoutByBuildingAndFloor from './BreakoutByBuildingAndFloor';

const BreakoutByBuildingAndFloorContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fetchStart, setFetchStart] = useState(false);
  const pdfMode = useSelector(getPdfMode);
  const { dataRequestStatuses, data, shouldRefetchData } = useSelector(getBreakoutByBuildingAndFloorData);
  const { selectedFiltersUrlParams } = useSelector((store) => getSelectedFilters(store, 'breakout'));
  const { appliedFiltersRequestStatus } = useSelector(getAppliedFilters);

  const isNoData = Object.values(data).some((dataItem) => !dataItem);
  const isLoading = Object.values(dataRequestStatuses).some((status) => status === requestStatuses.pending);
  const isFiltersHistoryLoading = [requestStatuses.default, requestStatuses.pending].includes(
    appliedFiltersRequestStatus,
  );

  useEffect(() => {
    if (isFiltersHistoryLoading) return;

    if (isDefault(dataRequestStatuses.databoxes) || shouldRefetchData.databoxes) {
      fetchBreakoutDataboxes(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.buildingUtilizationData) || shouldRefetchData.buildingUtilizationData) {
      fetchBreakoutBuildingUtilGraphData(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.floorUtilizationData) || shouldRefetchData.floorUtilizationData) {
      fetchBreakoutFloorUtilGraphData(dispatch, id, selectedFiltersUrlParams);
    }
    setFetchStart(true);
  }, [
    appliedFiltersRequestStatus,
    shouldRefetchData.databoxes,
    shouldRefetchData.buildingUtilizationData,
    shouldRefetchData.floorUtilizationData,
    isFiltersHistoryLoading,
    dataRequestStatuses.databoxes,
    dataRequestStatuses.buildingUtilizationData,
    dataRequestStatuses.floorUtilizationData,
    dispatch,
    id,
    selectedFiltersUrlParams,
  ]);

  return (
    <LoadingCondition isLoading={isLoading} isNoData={isNoData} fetchStart={fetchStart}>
      <BreakoutByBuildingAndFloor isLoading={isLoading} pdfMode={pdfMode} {...data} />
    </LoadingCondition>
  );
};

export default BreakoutByBuildingAndFloorContainer;
