import React from 'react';

import { useAssetLookSelector } from 'modules/assetLook/hooks';

import { MultiSelect } from 'modules/assetLook/components';
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel } from '@material-ui/core';

const TagsMultiSelect = ({ formik }) => {
  const [tags] = useAssetLookSelector(['common.tags']);

  return (
    <FormGroup>
      <FormControl component="fieldset">
        <InputLabel
          shrink
          // eslint-disable-next-line react/no-children-prop
          children="Tags"
          style={{ position: 'unset' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              id="tagsFlag"
              name="tagsFlag"
              checked={formik.values.tagsFlag}
              onChange={(e) => {
                const newValue = e.target.checked;
                formik.setFieldValue('tagsFlag', newValue);
                if (newValue) formik.setFieldValue('tags', formik.initialValues.tags);
              }}
              color="primary"
            />
          }
          label="Keep current"
        />
      </FormControl>
      <MultiSelect
        width="100%"
        menuWidth="auto"
        disabled={formik.isSubmitting || formik.values.tagsFlag}
        value={formik.values.tags}
        menuItems={tags.map(({ id, name }) => ({ id, label: name }))}
        onChange={(e) => formik.setFieldValue('tags', e.target.value)}
      />
    </FormGroup>
  );
};

export default TagsMultiSelect;
