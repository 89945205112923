import { createReducer } from '@reduxjs/toolkit';

import { requestStatuses } from 'modules/shared/constants';
import { FETCH_HEATMAP_DATA, RESET_REPORT_DATA } from 'modules/spaceLook/actions';

const initState = {
  requestStatus: requestStatuses.default,
  heatmapData: null,
};

const heatmapReducer = createReducer(initState, {
  [FETCH_HEATMAP_DATA.pending]: (state) => ({ ...state, requestStatus: requestStatuses.pending }),
  [FETCH_HEATMAP_DATA.success]: (state, { payload }) => ({
    ...state,
    requestStatus: requestStatuses.success,
    heatmapData: payload.sort((a, b) => a.id - b.id),
  }),
  [FETCH_HEATMAP_DATA.failure]: (state) => ({ ...state, requestStatus: requestStatuses.failure }),
  [RESET_REPORT_DATA]: () => ({ ...initState }),
});

export default heatmapReducer;
