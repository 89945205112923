/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography, Grid } from '@material-ui/core';
import { getTemplates } from 'modules/workLook/selectors';
import CloseIcon from '@material-ui/icons/Close';
import theme from './EditTemplatesContainer.module.scss';
import TemplateItem from './components';

const EditTemplatesContainer = React.forwardRef(({ onClose }) => {
  const { templatesList } = useSelector(getTemplates);

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Grid item>
            <Typography variant="h5" className={theme.title}>
              List of Templates
            </Typography>
          </Grid>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={theme.wrapper}>
        {templatesList.map(({ id, name, type }, index) => (
          <TemplateItem id={id} name={name} type={type} index={index} />
        ))}
      </div>
    </div>
  );
});

export default EditTemplatesContainer;
