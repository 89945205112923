import { createReducer } from '@reduxjs/toolkit';

import { HIDE_SECOND_MODAL, SHOW_SECOND_MODAL } from 'modules/modalsSystem/actions';

const initState = {
  secondModalCode: null,
  secondModalProps: {},
};

const secondModalsReducer = createReducer(initState, {
  [SHOW_SECOND_MODAL]: (state, { payload: { secondModalCode, secondModalProps } }) => ({
    ...state,
    secondModalCode,
    secondModalProps,
  }),
  [HIDE_SECOND_MODAL]: () => ({ ...initState }),
});

export default secondModalsReducer;
