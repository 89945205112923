import { flow, uniq, flattenDeep } from 'lodash';

import { SERIES_ACTION_GROUP } from 'modules/workLook/constants';

const toTableData = (data, groupByFieldName) => {
  if (!data || !groupByFieldName) return { headers: null, rows: null };

  const filteredData = data.filter((series) => SERIES_ACTION_GROUP.singleValue.includes(series.action));

  // 0.Create headers where the first item is always a "group by" field name
  const headers = [groupByFieldName];

  // 1. Get all unique row names. Data keys are not consistent and may differ between series
  const rowNames = flow([flattenDeep, (res) => res.map(({ field }) => field), uniq])(
    filteredData.map((series) => series.data),
  );

  // 2. Create an object where KEY is a unique row name and VALUE is an empty array
  const rows = Object.fromEntries(rowNames.map((name) => [name, []]));

  // 3. Fill headers with series name. Each series is a single column
  // 4. Fill empty arrays inside the rows object with series data. If there is no value in series, put '-'
  filteredData.forEach((series) => {
    headers.push(series.name);

    Object.keys(rows).forEach((row) => {
      const dataItem = series.data.find(({ field }) => field === row);
      rows[row].push(dataItem ? dataItem.value : '-');
    });
  });

  return { headers, rows };
};

export default toTableData;
