/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
const toObjectStructure = (report) => ({
  ...report,
  activeFilters: report.initial_filters.map(({ field }) => ({ field, values: [] })),
  sections: report.sections.reduce((result, section) => {
    result[section.id] = {
      ...section,
      pages: section.pages.reduce((result, page) => {
        result[page.id] = {
          ...page,
          activeFilters: report.initial_filters.map(({ field }) => ({ field, values: [] })),
          graphs: page.graphs.reduce((result, graph) => {
            result[graph.id] = graph;
            return result;
          }, {}),
        };
        return result;
      }, {}),
    };
    return result;
  }, {}),
});

export default toObjectStructure;
