/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation, useParams, useHistory } from 'react-router-dom';

import URLS from 'pages/constants/urls';
import { getReports } from 'modules/workLook/selectors';
import { useSharedSelector } from 'modules/shared/hooks';
import { getActiveCompany } from 'modules/shared/selectors';
import { fetchReports, RESET_STATE } from 'modules/workLook/actions';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import config from 'modules/shared/components/NavBar/config';
import { TextPlaceholder } from 'modules/shared/components';
// eslint-disable-next-line import/no-cycle
import { ReportTitleSkeleton } from 'modules/workLook/components';

function FetchReportsHandler({ children }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { typeId } = useParams();
  const { id: companyId } = useSelector(getActiveCompany);

  const [portalSections] = useSharedSelector(['portalSection']);
  const configNames = config.map(({ name }) => name);
  const workLookTypes = portalSections?.filter((section) => !configNames.includes(section.name));

  const { reportData, requestStatus } = useSelector(getReports);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    dispatch(RESET_STATE());
    history.push({
      pathname: URLS.workLook.base.replace(':typeId', typeId),
      state: { isInit: true },
    });
  }, [companyId, dispatch, history, typeId]);

  useEffect(() => {
    let isCanceled = false;

    workLookTypes.some((type) => type.id === Number(typeId))
      ? fetchReports(dispatch, { companyId, typeId }, isCanceled)
      : history.push(URLS.locationLook.locationSummary);

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId, companyId, dispatch, history]);

  return (
    <Choose>
      <When condition={isDefault(requestStatus) || isLoading(requestStatus)}>
        <ReportTitleSkeleton />
      </When>
      <When condition={isSuccess(requestStatus)}>
        <Choose>
          <When condition={isEmpty(reportData)}>
            <TextPlaceholder noData />
          </When>
          <When condition={get(location, 'state.isInit', false)}>
            <Redirect
              to={URLS.workLook.title.replace(':typeId', typeId).replace(':reportId', Object.values(reportData)[0].id)}
            />
          </When>
          <Otherwise>{children}</Otherwise>
        </Choose>
      </When>
      <When condition={isFailure(requestStatus)}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default FetchReportsHandler;
