import { SERIES_ACTION_GROUP } from 'modules/workLook/constants';

// Because of the "draft-js-mention-plugin", we need to return an empty array in case of an error
const toTextSummaryData = (data, groupType) => {
  if (!data || !data.length) return [];

  switch (groupType) {
    case null:
      return data.filter((series) => SERIES_ACTION_GROUP.singleValue.includes(series.action));
    default:
      return [];
  }
};

export default toTextSummaryData;
