import { pick } from 'lodash';

const keysToPick = ['databoxes', 'peakAndAvgUtilData', 'observedSizesData', 'comparisonTextData'];

const getMeetingsCompAndSizesData = ({ report: { meetings } }) => ({
  dataRequestStatuses: pick(meetings.requestStatuses, keysToPick),
  data: pick(meetings.data, keysToPick),
  shouldRefetchData: pick(meetings.shouldRefetchData, keysToPick),
});

export default getMeetingsCompAndSizesData;
