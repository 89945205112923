const options = (isSwitcherOn) => {
  return {
    layout: {
      padding: {
        left: -10,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
          ticks: {
            minRotation: 90,
          },
        },
      ],
      yAxes: [
        {
          ticks: { min: 0, max: 100, display: false },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: '#FFF',
      titleFontColor: '#000',
      titleFontSize: 11,
      bodyFontColor: '#000',
      bodyFontSize: 11,
      displayColors: false,
      callbacks: {
        title(context) {
          const { labels } = this._data;
          const [firstElement] = context;
          return labels[firstElement.index];
        },
        label(context) {
          const [desks, breakouts] = this._data.datasets;
          const { index } = context;
          return [
            `${breakouts.label} : ${breakouts.data[index]}${isSwitcherOn ? '' : '%'}`,
            `${desks.label} : ${desks.data[index]}${isSwitcherOn ? '' : '%'}`,
          ];
        },
      },
    },
  };
};

export default options;
