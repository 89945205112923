/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';
import { Grid } from '@material-ui/core';
import { GraphLegend } from 'modules/spaceLook/components';

import { NoDataAvailable } from 'modules/liveLook/utils';
import GraphHeader from './components/GraphHeader';
import GraphBody from './components/GraphBody';
import theme from './UsageOverTimeGraph.module.scss';

const UsageOverTimeGraph = ({
  isCombinedDashboard,
  isCombinedOccupancy,
  isDashboardPageWeek,
  isDashboardPage,
  daysDiffernce,
  title,
  scale,
  onScaleChange,
  value,
  onValueChange,
  graphData,
  linesData,
  scaleValues,
  config,
  onSwitcherChange,
  isSwitcherOn,
  customHeader,
  pdfCustomHeader,
  shouldRenderScale,
  label,
  isLiveLook,
  isDashboard,
  range,
  onRangeChange,
}) => {
  const getDashboardPage = isDashboard || isDashboardPage || isDashboardPageWeek;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GraphHeader
          daysDiffernce={daysDiffernce}
          title={title}
          scale={scale}
          onScaleChange={onScaleChange}
          value={value}
          isSwitcherOn={isSwitcherOn}
          onValueChange={onValueChange}
          onSwitcherChange={onSwitcherChange}
          customHeader={customHeader}
          label={label}
          shouldRenderScale={shouldRenderScale}
          pdfCustomHeader={pdfCustomHeader}
          isDashboard={isDashboard}
          range={range}
          onRangeChange={onRangeChange}
          graphData={graphData}
        />
      </Grid>
      <Grid className={pdfCustomHeader ? theme.pdfBody : theme.body} xs={pdfCustomHeader ? null : 12}>
        {graphData ? (
          <GraphBody
            pdfCustomHeader={pdfCustomHeader}
            isCombinedDashboard={isCombinedDashboard}
            isDashboardPageWeek={isDashboardPageWeek}
            isDashboardPage={isDashboardPage}
            graphData={graphData}
            linesData={linesData}
            scaleValues={scaleValues}
            config={config}
            scale={scale}
            isSwitcherOn={isSwitcherOn}
            isCombinedOccupancy={isCombinedOccupancy}
          />
        ) : (
          <NoDataAvailable theme={pdfCustomHeader ? theme.pdfGraphInner : theme.graphInner} />
        )}
      </Grid>
      <Grid item xs={12}>
        <GraphLegend
          pdfMode={pdfCustomHeader}
          isLiveLook={isLiveLook}
          config={config}
          isSwitcherOn={isSwitcherOn}
          isDashboard={getDashboardPage}
          marginTop={0}
          sm={6}
          md={3}
          lg={2}
        />
      </Grid>
    </Grid>
  );
};

export default UsageOverTimeGraph;
