/* eslint-disable react/no-children-prop */
import React from 'react';
import { get } from 'lodash';

import { Grid, Typography, makeStyles } from '@material-ui/core';

import defaultImage from 'assets/images/report-title-default.png';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    background: ({ image }) => (image ? `center / cover no-repeat url(${image})` : ''),
  },
  contentWrapper: {
    margin: '0',
    backgroundColor: ({ image, backgroundColor }) =>
      image ? 'unset' : get(theme, `palette.${backgroundColor}`, 'unset'),
    flexGrow: '1',
    padding: '67px 42px 32px',
    color: ({ textColor }) => textColor || 'white',
  },
  defaultImage: {
    minWidth: '275px',
    flexBasis: '275px',
    background: ({ image }) => (image ? '' : `center / cover no-repeat url(${defaultImage})`),
  },
  middleTextWrapper: {
    margin: 'auto 0',
  },
  childrenWrapper: {
    marginTop: ({ middleText }) => middleText && 'auto',
  },
  text: {
    color: 'inherit',
  },
}));

const ReportTitlePage = ({
  title,
  subTitle,
  middleText,
  image,
  children,
  textColor,
  backgroundColor = 'primary.dark',
}) => {
  const classes = useStyles({
    image,
    middleText,
    textColor,
    backgroundColor,
  });

  return (
    <div className={classes.container}>
      <Grid classes={{ root: classes.contentWrapper }} container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h2" children={title} classes={{ root: classes.text }} />
        </Grid>
        <Grid item>
          <Typography variant="h4" children={subTitle} classes={{ root: classes.text }} />
        </Grid>
        <If condition={middleText}>
          <Grid item classes={{ root: classes.middleTextWrapper }}>
            <Typography variant="h5" children={middleText} classes={{ root: classes.text }} />
          </Grid>
        </If>
        <If condition={Boolean(children)}>
          <Grid item classes={{ root: classes.childrenWrapper }}>
            {children}
          </Grid>
        </If>
      </Grid>
      <div className={classes.defaultImage} />
    </div>
  );
};

export default ReportTitlePage;
