/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { URLS } from 'pages/constants';
import { getActiveCompany } from 'modules/shared/selectors';
import { getTemplates } from 'modules/workLook/selectors';
import { ENDPOINTS, requestStatuses } from 'modules/shared/constants';
import { API, isDefault, isLoading as isLoadingStatus } from 'modules/shared/utils';
import { ADD_NEW_REPORT, fetchTemplates } from 'modules/workLook/actions';

import CreateReportModal from './CreateReportModal';

const CreateReportModalContainer = ({ _handleClose, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { typeId } = useParams();

  // eslint-disable-next-line camelcase
  const { id: company_id } = useSelector(getActiveCompany);
  const [submitStatus, setSubmitStatus] = useState(requestStatuses.default);
  const { templatesRequestStatus, templatesList } = useSelector(getTemplates);
  const isLoading = isDefault(templatesRequestStatus) || isLoadingStatus(templatesRequestStatus);

  const validationSchema = yup.object({
    name: yup
      .string('Enter the report name')
      .trim()
      .max(20, 'Report name should be of maximum 20 characters length')
      .required('Report name is required'),
    template: yup.string('Select the appropriate template').required('Template is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      template: templatesList.length ? templatesList[0].id : 'none',
    },
    validationSchema,
    onSubmit: ({ name, template }) => {
      setSubmitStatus(requestStatuses.pending);

      const url = ENDPOINTS.workLook.createReport.replace(':templateId', template);

      API.post(url, { name: name.trim(), company_id })
        .then(({ data }) => {
          dispatch(ADD_NEW_REPORT(data));
          setSubmitStatus(requestStatuses.success);
          formik.resetForm();
          history.push({
            pathname: URLS.workLook.title.replace(':typeId', typeId).replace(':reportId', data.id),
          });
          _handleClose();
        })
        .catch((err) => {
          setSubmitStatus(requestStatuses.failure);
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
  });

  useEffect(() => {
    if (templatesList.length) formik.setFieldValue('template', templatesList[0].id, false);
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    let isCanceled = false;

    // eslint-disable-next-line no-unused-expressions
    isDefault(templatesRequestStatus) && fetchTemplates(dispatch, typeId, isCanceled);

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
    // eslint-disable-next-line
  }, []);

  return (
    <CreateReportModal
      typeId={typeId}
      formik={formik}
      isLoading={isLoading}
      templatesList={templatesList}
      isSubmitPending={submitStatus === requestStatuses.pending}
      isSubmitFailure={submitStatus === requestStatuses.failure}
      _handleClose={_handleClose}
      {...props}
    />
  );
};

export default CreateReportModalContainer;
