import { requestStatuses } from 'modules/shared/constants';

const actionsCreator = (action, dataKey) => ({
  [action.pending]: (state) => ({
    ...state,
    requestStatuses: {
      ...state.requestStatuses,
      [dataKey]: requestStatuses.pending,
    },
    shouldRefetchData: {
      ...state.shouldRefetchData,
      [dataKey]: false,
    },
  }),
  [action.success]: (state, { payload }) => ({
    ...state,
    requestStatuses: {
      ...state.requestStatuses,
      [dataKey]: requestStatuses.success,
    },
    data: {
      ...state.data,
      [dataKey]: payload,
    },
  }),
  [action.failure]: (state) => ({
    ...state,
    requestStatuses: {
      ...state.requestStatuses,
      [dataKey]: requestStatuses.failure,
    },
  }),
});

export default actionsCreator;
