import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const SidebarSkeleton = () => (
  <>
    <Skeleton width={274} height={32} />
    {[1, 2, 3, 4, 5].map((key) => (
      <Skeleton key={key} width={234} height={32} style={{ marginLeft: '40px' }} />
    ))}
    <Skeleton width={274} height={32} />
    <Skeleton width={274} height={32} />
  </>
);

export default SidebarSkeleton;
