import { useState, useEffect } from 'react';
import { EditorState } from 'draft-js';

import decorators from 'modules/shared/components/TextEditor/decorators';

const usePDFTextMention = (editorState, setEditorState, data, key, setKey) => {
  const [suggestions, setSuggestions] = useState(null);
  // eslint-disable-next-line no-shadow
  const convertedData = data.map(({ name, data }) => ({
    name,
    value: data,
    isActive: true,
  }));

  useEffect(() => {
    setSuggestions(convertedData);
    setKey(key + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(convertedData)]);

  useEffect(() => {
    const decorator = editorState.getDecorator();

    const numOfDecorators =
      decorator &&
      (decorator.decorators ? decorator.decorators._tail.array[0]._decorators.length : decorator._decorators.length);

    if (numOfDecorators > 1) {
      const newState = EditorState.set(editorState, { decorator: decorators.toValue });
      setEditorState(newState);
    }
  });

  return {
    suggestions,
  };
};

export default usePDFTextMention;
