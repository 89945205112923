import React from 'react';
import ModalInnerHeader from 'components/Modals/components/ModalInnerHeader';
import theme from './modalInnerBody.module.scss';

const ModalInnerBody = ({ title, children }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <div className={theme.container}>
        <div className={theme.wrapper}>
          <ModalInnerHeader title={title} />
          <div className={theme.bodyContainer}>{children}</div>
        </div>
      </div>
      {/* <div className={theme.formContainer}>
      // Display additional right section 
            <If condition={openUserForm}>
              <UserForm userId={activeUser} onCancel={onCancel} />
            </If>
          </div> */}
    </div>
  );
};

export default ModalInnerBody;
