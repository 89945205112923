import { requestStatuses } from 'modules/shared/constants';
import {
  FETCH_DESK_DATABOXES,
  SET_SECOND_GRAPH_SWITCHER,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
  FETCH_DESK_BY_DIVISION_SUMMARY,
  FETCH_DESK_VARIABLES,
} from 'modules/liveLook/actions';
import { GRAPH_SWITCHER } from 'modules/liveLook/constants';

export const initialState = {
  databoxRequestStatus: requestStatuses.default,
  deskGraphRequestStatus: requestStatuses.default,
  deskVariableRequestStatus: requestStatuses.default,
  deskGraphData: [],
  returnPercentageSwitcherDeskGraph: GRAPH_SWITCHER.integers,
  textReportRequestStatus: requestStatuses.default,
  maxNum: 0,
  deskDataboxes: [],
  returnPercentageSwitcher: GRAPH_SWITCHER.percentages,
  editableTextReport: '',
  textVariables: {
    deskVariables: {},
  },
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_DESK_BY_DIVISION_SUMMARY.pending().type:
      return {
        ...state,
        deskGraphRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_BY_DIVISION_SUMMARY.success().type:
      return {
        ...state,
        deskGraphRequestStatus: requestStatuses.success,
        deskGraphData: payload,
      };
    case FETCH_DESK_BY_DIVISION_SUMMARY.failure().type:
      return {
        ...state,
        deskGraphRequestStatus: requestStatuses.failure,
      };
    case FETCH_DESK_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        deskDataboxes: payload,
      };
    case FETCH_DESK_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_DESK_VARIABLES.pending().type:
      return {
        ...state,
        deskVariableRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_VARIABLES.success().type:
      return {
        ...state,
        deskVariableRequestStatus: requestStatuses.success,
        textVariables: { ...state.textVariables, ...{ deskVariables: payload } },
      };
    case FETCH_DESK_VARIABLES.failure().type:
      return {
        ...state,
        deskVariableRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SET_SECOND_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcherDeskGraph: payload,
      };
    default:
      throw new Error();
  }
}

export default reducer;
