import { API, createAsyncAction } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import createAction from 'modules/shared/actions/_createAction';

export const FETCH_PORTAL_SECTION = createAsyncAction(createAction('FETCH_PORTAL_SECTION'));

const setPropertyNamesMap = (dispatch, companyId) => {
  dispatch(FETCH_PORTAL_SECTION.pending());
  if (!companyId) return null;

  const url = ENDPOINTS.shared.portalSections.replace(':getSections', companyId);

  return API.get(url)
    .then(({ data }) => dispatch(FETCH_PORTAL_SECTION.success(data)))
    .catch(() => dispatch(FETCH_PORTAL_SECTION.failure()));
};

export default setPropertyNamesMap;
