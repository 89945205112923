import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TOGGLE_FILTER } from 'modules/spaceLook/actions';
import { countSelectedFiltersQuantity } from 'modules/spaceLook/utils';
import { getBenchmarkingSelectedFilters } from 'modules/spaceLook/selectors';

import BenchmarkingFilterHandler from './BenchmarkingFilterHandler';

const BenchmarkingFilterHandlerContainer = ({ ...props }) => {
  const dispatch = useDispatch();
  const showFilter = useSelector((state) => state.report.services.showFilter);

  const { selectedFilters } = useSelector(getBenchmarkingSelectedFilters);

  const toggleFilter = () => dispatch(TOGGLE_FILTER());

  return (
    <BenchmarkingFilterHandler
      showFilter={showFilter}
      toggleFilter={toggleFilter}
      selectedFiltersQuantity={countSelectedFiltersQuantity({ id: selectedFilters })}
      {...props}
    />
  );
};

export default BenchmarkingFilterHandlerContainer;
