import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/spaceLook/components';
import {
  FilterList,
  LiveCounter,
  ReportDataCards,
  VerticalBarGraph,
  DeskUsageDuringTheDay,
} from 'modules/liveLook/components';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';

import WarningDialog from 'modules/shared/components/WarningDialog';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import { TextEditor } from 'modules/shared/components';
import IFrameModal from 'modules/liveLook/components/iFrame';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import graphDeskConfig from './graphDeskConfig';
import deskUsageConfig from './config';
import { MAX_SELECTED_WEEKS } from '../constants/liveLook.constants';

const SummaryKeyFindings = ({
  databoxes,
  databoxesSet,
  doorsGraphData,
  doorsGraphOnSwitcherChange,
  doorsGraphScaleValues,
  editableTextReport,
  saveGraphsText,
  returnPercentageSwitcher,
  deskPeakOccupancyDayUsageData,
  deskGraphSwitcher,
  deskGraphScaleValues,
  onDeskSwitcherChange,
  textVariables,
  archive,
  onScaleChange,
  scale,
}) => {
  const { labels } = doorsGraphData;
  const [warningDialog, setWarningDialog] = useState(false);

  const openWarningDialog = () => {
    setWarningDialog(true);
  };

  const showWarning = labels?.length > MAX_SELECTED_WEEKS;
  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Executive Summary - Key Findings" />
          </Grid>
          <Grid item xs={12}>
            <FilterList doorSensorData pageType="OCCUPANCY" subtypes={false} />
          </Grid>
          <Grid item xs={12}>
            <ReportDataCards databoxes={databoxes} pageType={pageTypes.keyFindings} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Grid
          container
          alignItems="flex-end"
          direction="column"
          // spacing={1}
          style={{ justifyContent: 'flex-end' }}
        >
          <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>

        <Grid>
          <AddToPDFCard
            openWarningDialog={openWarningDialog}
            showWarning={showWarning}
            type="Key Findings"
            sectionType="Executive Summary"
            category={pdfCases.keyFindings}
            sectionOrder={sectionsOrder.first}
            typeOrder={sectionItemOrder.first}
            chartName={liveLookPagesNames.summaryKeyFindings}
            activeFilters={filtersDataContainer('OCCUPANCY', false).initActiveFilters}
            data={{
              databoxes,
              databoxesSet,
              doorsGraphData,
              doorsGraphOnSwitcherChange,
              doorsGraphScaleValues,
              editableTextReport,
              returnPercentageSwitcher,
              deskPeakOccupancyDayUsageData,
              deskGraphSwitcher,
              deskGraphScaleValues,
              onDeskSwitcherChange,
              textVariables,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <TextEditor
          chartName={liveLookPagesNames.summaryKeyFindings}
          data={databoxesToEditorData(databoxesSet, textVariables)}
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
          graphType={GRAPH_TYPES.KEY_FINDINGS}
        />
      </Grid>
      <Grid item xs={6}>
        <VerticalBarGraph
          title="Occupancy by week"
          data={doorsGraphData}
          returnPercentageSwitcher={returnPercentageSwitcher}
          scaleValues={doorsGraphScaleValues}
          onSwitcherChange={doorsGraphOnSwitcherChange}
          config={graphDeskConfig}
          customHeader
          isWeekGraph
          shouldRenderScale
          scale={scale}
          onScaleChange={onScaleChange}
        />
        <DeskUsageDuringTheDay
          title="Desk Usage During the Day"
          graphData={deskPeakOccupancyDayUsageData}
          returnPercentageSwitcher={deskGraphSwitcher}
          scaleValues={deskGraphScaleValues}
          onSwitcherChange={onDeskSwitcherChange}
          legendConfig={deskUsageConfig}
          customHeader
        />
      </Grid>
      <If condition={openWarningDialog}>
        <WarningDialog
          open={warningDialog}
          _onClose={() => setWarningDialog(false)}
          title="Warning"
          text="The maximum number of weeks for the PDF reports is 9"
        />
      </If>
    </Grid>
  );
};

export default SummaryKeyFindings;
