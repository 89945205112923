/* eslint-disable prefer-spread */
import React, { useEffect, useReducer, useCallback } from 'react';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, toByBuildingGraphData, filtersToQuery } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import {
  fetchOccupancyDataboxes,
  SET_GRAPH_SWITCHER,
  fetchDoorsByBuilding,
  fetchGraphsText,
  saveGraphsText,
} from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import DoorsByBuilding from './DoorsByBuilding';
import { setScaleValues } from '../utils';
import reducer, { initialState } from './_reducer';
import { useActiveBuildings } from '../hooks';

function DoorsByBuildingContainer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    building,
    occupancyDataboxes,
    databoxRequestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
  } = state;

  const [companyId] = useAuthSelector(['currentCompany']);
  const [startDate, endDate, workingHours, buildings, archive, activeFilters] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'common.buildings',
    'common.archive',
    'filters.activeFilters',
  ]);

  const query = filtersToQuery(activeFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;

    fetchDoorsByBuilding(dispatch, isCanceled, {
      companyId,
      buildingId: state.building,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchOccupancyDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'oc_bld_flr',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, state.building, dateAfter, dateBefore, workingHours, query]);

  const databoxesSet = {
    occupancyDataboxes,
  };

  const peakOccupancy = Math.max.apply(
    Math,
    data?.map((key) => key[returnPercentageSwitcher ? 'max' : 'percentage_max']),
  );

  const { scaleValues } = setScaleValues(peakOccupancy);

  const { activeBuilding, onChange: onBuildingChange } = useActiveBuildings(buildings, dispatch);

  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  return (
    <Choose>
      <When
        condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].some(
          (item) => isDefault(item) || isLoading(item),
        )}
      >
        <Loader />
      </When>
      <When condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].every((item) => isSuccess(item))}>
        <DoorsByBuilding
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'oc_bld_flr', text });
          }}
          editableTextReport={editableTextReport}
          data={toByBuildingGraphData(data, returnPercentageSwitcher)}
          scaleValues={scaleValues}
          building={building}
          onSwitcherChange={onSwitcherChange}
          onBuildingChange={onBuildingChange}
          databoxes={occupancyDataboxes}
          switcher={returnPercentageSwitcher}
          returnPercentageSwitcher={returnPercentageSwitcher}
          databoxesSet={databoxesSet}
          activeBuilding={activeBuilding}
          archive={archive}
        />
      </When>
      <When condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default DoorsByBuildingContainer;
