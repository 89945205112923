import React, { useState, useEffect } from 'react';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';

import './Copyright.scss';
import axios from 'axios';
import { isEmpty } from 'lodash';

const Copyright = ({ style }) => {
  const currentYear = new Date().getFullYear();
  const [customData, setCustomData] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const asUserToken = queryParameters.get('token');

  useEffect(() => {
    let canceled = false;

    if (!isEmpty(asUserToken)) {
      const conf = {
        headers: {
          Authorization: `Token ${asUserToken}`,
        },
      };
      axios
        .get(ENDPOINTS.shared.version, conf)
        .then(({ data }) => {
          if (canceled) return;
          setCustomData(data);
        })
        .catch((err) => {
          if (canceled) return;
          // eslint-disable-next-line no-console
          console.error(err);
        });
    } else {
      API.get(ENDPOINTS.shared.version)
        .then(({ data }) => {
          if (canceled) return;
          setCustomData(data);
        })
        .catch((err) => {
          if (canceled) return;
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
  }, [asUserToken]);

  return (
    <section className="Copyright" style={style}>
      <div className="copyright">
        <span className="item">Powered by BakerStuart WorkLOOK</span>
        <span className="item">{`©BakerStuart Limited ${currentYear}`}</span>
        <span className="item">{customData && customData.version}</span>
      </div>
    </section>
  );
};

export default Copyright;
