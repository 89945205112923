import React from 'react';
import PropTypes from 'prop-types';

import './StackedPercentNav.scss';

const StackedPercentNav = ({ percents }) => {
  const percentItems = percents.map((percent, idx) => (
    <span key={idx} className="stacked-percent-item">
      {percent}
    </span>
  ));

  return (
    <div className="StackedPercentNav">
      <div className="stacked-percent-nav">{percentItems}</div>
    </div>
  );
};

StackedPercentNav.defaultProps = {
  percents: ['0%', '25%', '50%', '75%', '100%'],
};

StackedPercentNav.propTypes = {
  percents: PropTypes.arrayOf(PropTypes.string),
};

export default StackedPercentNav;
