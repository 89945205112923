const config = {
  legend: [
    {
      iconType: 'round',
      label: 'Average in Use %',
      color: '#00aad2',
    },
    {
      iconType: 'round',
      label: 'Peak in Use %',
      color: '#0d6f85',
    },
  ],
};

export default config;
