/* eslint-disable func-names */
import { Chart } from 'react-chartjs-2';

const options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  events: [],
  animation: {
    onComplete() {
      const chartInstance = this.chart;
      const { ctx } = chartInstance;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.verticalAlign = 'center';
      ctx.font = '12px 500, Roboto';

      const { datasets } = this.data;

      Chart.helpers.each(
        datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);

          Chart.helpers.each(
            meta.data.forEach(function (bar, index) {
              const data = dataset.data[index];
              if (data < 10) return;
              const color = '#fff';
              ctx.fillStyle = color;
              ctx.fillText(`${data}%`, bar._model.x, bar._model.y + 10);
            }),
            this,
          );
        }),
        this,
      );
    },
  },
  dataset: [{ categoryPercentage: 1, barPercentage: 0.9, categoryThickness: 31 }],
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#4D4D4D',
          fontSize: 12,
          fontStyle: 'bold',
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: { min: 0, max: 100, display: false },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export default options;
