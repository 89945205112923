/* eslint-disable camelcase */
import React from 'react';
import theme from './GradientBar.module.scss';

const GradientBar = ({ scales, maxAxis, value, minAxis, smallStyles }) => {
  const getPosition =
    value < 0
      ? ((value + Math.abs(minAxis)) / (Math.abs(minAxis) + maxAxis)) * 100
      : ((value - minAxis) / (maxAxis - minAxis)) * 100;

  return (
    <div className={theme.container}>
      <div className={theme.scaleContainer}>
        <div className={theme.scaleWrapper}>
          {scales.map((scale, id) => (
            <div key={id} className={theme.aligner}>
              <div className={theme.textScale}>{scale}</div>
              <div
                className={theme.scale}
                style={{ height: smallStyles ? 60 : 90, alignSelf: id === 0 ? 'start' : id === 5 ? 'end' : 'center' }}
              />
            </div>
          ))}
        </div>
        <div
          className={theme.progressBarContainer}
          style={{ height: smallStyles ? 40 : 60, marginTop: smallStyles ? '5%' : '6%' }}
        >
          <div className={theme.popoverWrapper} style={{ marginLeft: `${getPosition}%` }}>
            <div className={theme.valueWrapper} style={{ height: smallStyles ? 50 : 70 }} />
            <div className={theme.popoverContent}>
              <p className={theme.popoverMessage}>Value: {value}</p>
            </div>
          </div>
          <div className={theme.progress} />
        </div>
      </div>
    </div>
  );
};

export default GradientBar;
