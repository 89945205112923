/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CHECK_ASSET, fetchAssets, fetchAllAssets } from 'modules/assetLook/actions';
import { toRequestBody } from 'modules/assetLook/utils';
import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { BUILDING_ID, CATEGORY_ID, PRODUCT_ID, ASSET_FIELDS } from 'modules/assetLook/constants';

import { FixedDataTable, TextPlaceholder } from 'modules/shared/components';
import {
  AutoSizer,
  CellSkeleton,
  AssetListCellHeader as CellHeader,
  AssetListCellBody as CellBody,
} from 'modules/assetLook/components';

const columnWidthMap = {
  0: 53,
  1: 150,
};

function trim(el) {
  const result = el
    .trim()
    .replace(/[ ]{2,}/gi, ' ')
    .replace(/\n +/, '\n');
  return result;
}

function TargetAssetListTable({ isTargetChecked, isAllAssets }) {
  const dispatch = useDispatch();

  const { id: companyId } = useSelector(getActiveCompany);
  const { buildingId, categoryId, productId } = useLocationSearchParams([BUILDING_ID, CATEGORY_ID, PRODUCT_ID]);

  const [assetsRequestStatus, assets, activeFilters, search, activeTargetFilters, tags, basketAssets] =
    useAssetLookSelector([
      'assets.requestStatus',
      'assets.assets',
      'filters.activeFilters',
      'filters.search',
      'filters.activeTargetFilters',
      'common.tags',
      'assets.basketAssets',
    ]);

  const allActiveFilters = [...activeTargetFilters, ...activeFilters];

  const filters = toRequestBody(allActiveFilters, tags);

  useEffect(() => {
    let isCanceled = false;
    if (isAllAssets) {
      fetchAllAssets(dispatch, isCanceled, {
        companyId,
        buildingId,
        productId,
        filters,
        isTargetChecked,
      });
    } else {
      fetchAssets(dispatch, isCanceled, {
        companyId,
        buildingId,
        productId,
        filters,
        isTargetChecked,
      });
    }
    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
    // eslint-disable-next-line
  }, [buildingId, categoryId, isTargetChecked, productId, JSON.stringify(filters || {})]);

  const onRowClick = (e, i) => {
    const assetId = assets.data[i][ASSET_FIELDS.id];
    dispatch(CHECK_ASSET(assetId));
  };

  const columnWidth = (columnKey, i) => columnWidthMap[i] || 200;

  const data =
    !isEmpty(assets.data) &&
    assets.data
      .filter(
        ({ _id }) =>
          _id.toString().includes(trim(search.toLowerCase())) || trim(search.toLowerCase()).includes(_id.toString()),
      )
      .map((item) => {
        const newItem = JSON.parse(JSON.stringify(item));
        newItem.list_data.Zone = '-';
        if (newItem.list_data.Floor) {
          newItem.list_data.Floor = '-';
        }
        if (newItem.list_data.Building) {
          newItem.list_data.Building = '-';
        }
        return newItem;
      });

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Choose>
          <When condition={isDefault(assetsRequestStatus) || isLoading(assetsRequestStatus)}>
            <FixedDataTable
              width={width}
              height={height}
              rows={[1, 2, 3]}
              columns={[1, 2, 3, 4, 5]}
              columnWidth={columnWidth}
              ColumnHeader={CellSkeleton}
              ColumnCell={CellSkeleton}
            />
          </When>

          <When condition={isFailure(assetsRequestStatus)}>
            <TextPlaceholder error />
          </When>

          <When condition={isSuccess(assetsRequestStatus)}>
            <Choose>
              <When condition={!isEmpty(data)}>
                <FixedDataTable
                  width={width}
                  height={height}
                  rows={data}
                  onRowClick={onRowClick}
                  columns={[
                    ASSET_FIELDS.checkbox,
                    ASSET_FIELDS.id,
                    ...Object.keys(data[0].list_data),
                    ASSET_FIELDS.status,
                    ASSET_FIELDS.tags,
                  ]}
                  columnWidth={columnWidth}
                  ColumnHeader={CellHeader}
                  ColumnCell={(props) => (
                    <CellBody
                      {...props}
                      buildingId={buildingId}
                      categoryId={categoryId}
                      productId={productId}
                      basketAssets={basketAssets}
                    />
                  )}
                  columnFixed={(key, i) => i < 2}
                  columnFlexGrow={(key, i) => (i < 2 ? 0 : 1)}
                />
              </When>
              <Otherwise>
                <TextPlaceholder noData />
              </Otherwise>
            </Choose>
          </When>
        </Choose>
      )}
    </AutoSizer>
  );
}

export default TargetAssetListTable;
