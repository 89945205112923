import React, { useState, useCallback } from 'react';
import axios from 'axios';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isLogged } from 'utils';
import { REQUESTS, ONLY_NUMBERS_AND_LOWERCASE_LETTERS } from '_constants';
import { onLoginRedirect } from 'modules/auth/utils';

import { FormButton, ModalWindow } from 'components';
// eslint-disable-next-line import/no-cycle
import { FormikInput, ErrorNotification, PasswordNotification } from 'modules/auth/components';

import getUser from 'modules/auth/selectors/getUser';
import FormSchema from './FormSchema';

import './StaticTokenForm.scss';

const { base, version, otpStaticLogin } = REQUESTS;

const StaticTokenForm = ({
  successButtonText,
  errorButtonText,
  successCtaText,
  successInfoText,
  errorInfoText,
  errorCtaText,
}) => {
  const history = useHistory();
  const { user } = useSelector(getUser);

  const storageUser = user;
  const [pending, setPending] = useState(false);
  const [isCodeError, setIsCodeError] = useState(false);
  const [isCodeSuccess, setIsCodeSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalRedirect = useCallback(
    // eslint-disable-next-line object-shorthand
    () => onLoginRedirect({ user: storageUser, history: history }),
    [history, storageUser],
  );
  const onModalClose = useCallback(() => setIsModalOpen(false), []);

  const modalCallBack = isCodeSuccess ? modalRedirect : isCodeError ? onModalClose : null;

  const buttonText = isCodeSuccess ? successButtonText : isCodeError ? errorButtonText : '';

  return (
    <div className="StaticTokenForm">
      <ModalWindow onClose={modalCallBack} isOpen={isModalOpen} buttonText={buttonText} withToggleButton={false}>
        {isCodeError && <ErrorNotification infoText={errorInfoText} ctaText={errorCtaText} />}
        {isCodeSuccess && <PasswordNotification title={successInfoText} text={successCtaText} />}
      </ModalWindow>

      <Formik
        initialValues={{
          staticToken: '',
        }}
        validate={(values) => {
          const errors = {};
          const { staticToken } = values;

          if (staticToken.length) {
            const isOnlyNumbersAndLetters = ONLY_NUMBERS_AND_LOWERCASE_LETTERS.test(staticToken);
            if (!isOnlyNumbersAndLetters) {
              errors.staticToken = (
                <>
                  Code must contain ONLY numbers
                  <br />
                  and lowercase letters
                </>
              );
            } else if (staticToken.length && staticToken.length !== 8) {
              errors.staticToken = 'Code must contain 8 symbols';
            }
          }

          return errors;
        }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          if (isCodeSuccess) return;
          const { staticToken } = values;
          const token = isLogged();
          setPending(true);
          axios({
            method: 'post',
            url: `${base}${version}${otpStaticLogin}`,
            headers: { Authorization: `Token ${token}` },
            data: { token: staticToken },
          })
            .then((response) => {
              const { key } = response.data;
              localStorage.setItem('userToken', key);
              setIsCodeError(false);
              setIsCodeSuccess(true);
              setPending(false);
              setIsModalOpen(true);
            })
            .catch((err) => {
              if (err.message.indexOf(400) !== -1) {
                setIsCodeError(true);
                setIsCodeSuccess(false);
                setPending(false);
                setIsModalOpen(true);
              }
            });
        }}
      >
        {({ errors, touched }) => {
          const error = !isEmpty(touched) && errors.staticToken;

          const classes = cn('static-token-form', {
            disabled: error || pending,
          });

          return (
            <Form className={classes}>
              <FormikInput name="staticToken" placeholder="Static Token" type="text" />
              {error && <div className="field-error">{error}</div>}
              <FormButton text="Send token" />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

StaticTokenForm.defaultProps = {
  successButtonText: 'Go to homepage',
  errorButtonText: 'Please, try again',
  successInfoText: 'You successfully logged in!',
  successCtaText: 'Remember(!) that token you have already used is expired now',
  errorInfoText: 'Something went wrong',
  errorCtaText: 'Your token may be wrong or already expired',
  redirectButtonText: 'Go to homepage',
};

export default StaticTokenForm;
