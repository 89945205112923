import React, { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { withUser } from 'hocs';
import { getData } from 'services';
import { REQUESTS } from '_constants';
import { URLS } from 'pages/constants';
import changeProperty from 'actions/changeProperty';
import { requestStatuses } from 'modules/shared/constants';
import { getPropertyNamesMap } from 'modules/shared/selectors';
import setDetailsPageData, { SET_DETAILS_PAGE_DATA } from 'actions/setDetailsPageData';

import {
  isLogged,
  findActiveCompany,
  getUserCompanies,
  defineCompanyNameAndLogo,
  withoutSecondFactorAuthLogOut,
} from 'utils';

import { PageHeader, StaticSidebar, RoundArrowButton, Loader, BuildingsMap, PropertyInfo } from 'components';
import { NavBar } from 'modules/shared/components';
import useDetailsPageReducer from './hooks/useDetailsPageReducer';

import CardLines from './CardLines';
import Graphs from './Graphs';

import './DetailsPage.scss';

const {
  base,
  version,
  details: { buildings, properties },
} = REQUESTS;

// TODO: Rewrite from scratch!
const DetailsPage = ({ currentCompany, user, history }) => {
  const [state, dispatch] = useDetailsPageReducer();
  const [closedMap, setClosedMap] = useState(false);
  const { propertyNamesMap } = useSelector(getPropertyNamesMap);

  const activeCompany = useMemo(() => currentCompany || findActiveCompany(user), [currentCompany, user]);
  const companies = useMemo(() => getUserCompanies(user), [user]);
  const [companyName, logoUrl] = useMemo(
    () => defineCompanyNameAndLogo(companies, user, activeCompany),
    [activeCompany, companies, user],
  );

  useEffect(() => {
    dispatch({ type: SET_DETAILS_PAGE_DATA.pending });

    const token = isLogged();

    const buildingsUrl = `${base}${version}/company/${activeCompany}${buildings}`;
    const propertiesUrl = `${base}${version}/company/${activeCompany}${properties}`;

    axios
      .all([getData(buildingsUrl, token), getData(propertiesUrl, token)])
      .then((data) => dispatch(setDetailsPageData(...data, propertyNamesMap)))
      .catch((err) => {
        withoutSecondFactorAuthLogOut(err, history);
        dispatch({ type: SET_DETAILS_PAGE_DATA.failure });
      });
  }, [activeCompany, dispatch, history, propertyNamesMap]);

  return (
    <Choose>
      <When condition={state.requestStatus !== requestStatuses.success}>
        <Loader />
      </When>
      <When condition={!state.activeProperty}>
        <Redirect to={URLS.locationLook.locationSummary} />
      </When>
      <Otherwise>
        <div className={cn('DetailsPage', { 'no-map': closedMap })}>
          <div className="details-page">
            <div className="details-nav">
              <StaticSidebar
                items={state.propertiesList}
                onItemClick={(propertyId) => dispatch(changeProperty(propertyId))}
                currentItem={state.activeProperty.id}
                clientName={companyName}
                togglerTitle="Change property"
                logoUrl={logoUrl}
              />
            </div>
            <div className="details-content-scroll">
              <div className="details-content">
                <RoundArrowButton
                  additionalClass={cn({ 'invert-arrow': closedMap })}
                  onClick={() => setClosedMap(!closedMap)}
                />
                <div className="nav-menu">
                  <NavBar />
                </div>
                <div className="map-container">
                  <PageHeader title="Property Details" />
                  <BuildingsMap buildingsData={state.activeBuilding} />
                </div>
                <div className="content-container">
                  <div className="content-container-inner">
                    <PageHeader title="Property Details" />
                    <PropertyInfo activePropertyData={state.activeProperty} />
                    <CardLines databoxes={state.activeProperty.databoxes} />
                    <Graphs
                      graphDataType={state.graphDataType}
                      graphsData={state.activeProperty.graphsData}
                      sortOrder={state.sortOrder}
                      dispatch={dispatch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default withUser(DetailsPage);
