const setValueParams = (key, label, isPercent = false) => ({ key, label, isPercent });

const config = [
  {
    title: 'Size, sq m',
    firstValue: setValueParams('size', ''),
    color: '#a2559d',
  },

  {
    title: 'Desks',
    firstValue: setValueParams('desks', ''),
    color: '#f6a000',
  },

  {
    title: 'Cur. Allocation',
    firstValue: setValueParams('current_allocation', ''),
    color: '#00aad2',
  },

  {
    title: 'Capacity',
    firstValue: setValueParams('capacity', ''),
    color: '#b4cc04',
  },

  {
    title: 'Spare Capacity',
    firstValue: setValueParams('percent_spare_capacity', null, true),
    secondValue: setValueParams('spare_capacity', 'desks'),
    color: '#c4043f',
  },

  {
    title: 'Peak Utilisation',
    firstValue: setValueParams('percent_max_utilization', null, true),
    secondValue: setValueParams('max_utilization', 'desks'),
    color: '#0d6f85',
  },

  {
    title: 'Peak Occupancy',
    firstValue: setValueParams('percent_max_occupancy', null, true),
    secondValue: setValueParams('max_occupancy', 'desks'),
    color: '#333333',
  },
];

export default config;
