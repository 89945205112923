/* eslint-disable no-return-assign */
const DesksUsageBandingLess50 = ({ graphData, usageBandingType }) => {
  const totalDesks = Object.values(graphData).reduce((a, b) => a + b, 0);
  const valueToSum = [0, 10, 20, 30, 40, 50];
  // eslint-disable-next-line no-param-reassign
  const less50Quantity = valueToSum.reduce((sum, current) => (sum += graphData[current]), 0);
  const less50Percent = Math.round((less50Quantity / totalDesks) * 100);

  const occupiedOrUtilised = usageBandingType === 'OCCUPIED' ? 'occupied' : 'utilised';

  return `In total ${Math.floor(less50Quantity)} (${Math.floor(less50Percent)}%) desks
     were ${occupiedOrUtilised} ≤50% of the time during the survey.`;
};

export default DesksUsageBandingLess50;
