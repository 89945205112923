import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Grid, TextField, Typography, FormControlLabel, Checkbox, withStyles } from '@material-ui/core';

import { isEmpty } from 'lodash';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { SET_START_DATE, SET_END_DATE, TOGGLE_WORKING_HOURS } from 'modules/liveLook/actions';
import ErrorMessage from './ErrorMessage';

function DatePicker({ classes, disable }) {
  const dispatch = useDispatch();
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);

  const setStartDate = useCallback(
    (e) => {
      if (e.target.value < initDate || e.target.value > endDate) {
        setStartError(true);
      } else {
        setStartError(false);
        dispatch(SET_START_DATE(e.target.value));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );
  const setEndDate = useCallback(
    (e) => {
      if (e.target.value > endDate || isEmpty(e.target.value)) {
        setEndError(true);
      } else {
        setEndError(false);
        dispatch(SET_END_DATE(e.target.value));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const [initDate, startDate, endDate, hasWorkingHours, workingHours, archive, arhiveDateEnd] = useLiveLookSelector([
    'common.initDate',
    'common.startDate',
    'common.endDate',
    'common.hasWorkingHours',
    'common.workingHours',
    'common.archive',
    'common.arhiveDateEnd',
  ]);
  const setInitialDate = new Date(endDate);
  const fourMonthsInitDate = new Date(setInitialDate.setMonth(setInitialDate.getMonth() - 4))
    .toISOString()
    .substring(0, 10);

  const startDateError = `Value must be ${initDate} or later`;
  const endDateError = `Value must be ${new Date().toISOString().substring(0, 10)} or earlier`;

  return (
    <Grid container spacing={1} classes={{ root: classes.container }}>
      <Grid item xs={3} classes={{ root: classes.text }}>
        <Typography>From:</Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField
          fullWidth
          id="dateFrom"
          type="date"
          defaultValue={startDate}
          value={startDate}
          inputProps={{
            min: fourMonthsInitDate,
            max: endDate,
          }}
          onChange={setStartDate}
          error={startError}
          disabled={disable}
        />
      </Grid>
      {startError && <ErrorMessage error={startDateError} />}
      <Grid item xs={3} classes={{ root: classes.text }}>
        <Typography>To:</Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField
          fullWidth
          id="dateTo"
          type="date"
          defaultValue={endDate}
          inputProps={{
            min: startDate,
            max: archive ? arhiveDateEnd : endDate,
          }}
          value={endDate}
          onChange={setEndDate}
          error={endError}
          disabled={disable}
        />
      </Grid>
      {endError && <ErrorMessage error={endDateError} />}
      <Grid item xs={3} />
      <Grid item xs={9}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!hasWorkingHours}
              checked={workingHours}
              onChange={(e) => dispatch(TOGGLE_WORKING_HOURS(e.target.checked))}
              name="workingHours"
              color="primary"
            />
          }
          label="Working hours"
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(() => ({
  container: {
    paddingRight: '16px',
    paddingBottom: '16px',
  },
  text: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}))(DatePicker);
