import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';

import theme from './WarningDialog.module.scss';

const WarningDialog = ({
  open,
  _onClose,
  DialogProps,

  title,
  text,

  firstActionLabel = 'Close',
  FirstActionProps,
  _onFirstActionClick,
}) => (
  <Dialog
    open={open}
    onClose={_onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    {...DialogProps}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
    </DialogContent>
    <DialogActions className={theme.actions}>
      <Button
        autoFocus
        color="primary"
        // eslint-disable-next-line react/no-children-prop
        children={firstActionLabel}
        onClick={_onFirstActionClick || _onClose}
        {...FirstActionProps}
      />
    </DialogActions>
  </Dialog>
);

export default WarningDialog;
