import React, { useEffect, useReducer, useState } from 'react';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { createAction } from '@reduxjs/toolkit';

import { useAuthSelector } from 'modules/auth/hooks';
import { ORDER_STATUSES } from 'modules/assetLook/constants';
import { OrderListContext } from 'modules/assetLook/context';
import { ENDPOINTS, requestStatuses } from 'modules/shared/constants';
import { API, createAsyncAction, isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';

import { TextPlaceholder } from 'modules/shared/components';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTheme } from '@material-ui/core';
import OrderListSkeleton from './OrderListSkeleton';
import OrderList from './OrderList';

const FETCH_ORDER_LIST = createAsyncAction(createAction('FETCH_ORDER_LIST'));

const initialState = {
  requestStatus: requestStatuses.default,
  orderList: [],
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_ORDER_LIST.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_ORDER_LIST.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        orderList: payload,
      };
    case FETCH_ORDER_LIST.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    default:
      throw new Error();
  }
}

function OrderListContainer({ statuses, ...props }) {
  const theme = useTheme();

  const [companyId] = useAuthSelector(['currentCompany']);
  const [{ orderList, requestStatus }, dispatch] = useReducer(reducer, initialState);

  const [reloadTrigger, setReloadTrigger] = useState(null);
  const [orderType, setOrderType] = useState('all'); // New state for query parameter
  const [orderTypeTabValue, setOrderTypeTabValue] = React.useState('all');

  useEffect(() => {
    let isMounted = true;

    dispatch(FETCH_ORDER_LIST.pending());

    let url = ENDPOINTS.assetLook.orderList
      .replace(':companyId', companyId)
      .concat(`?${statuses.map((i) => `status=${i}`).join('&')}`);

    if (orderType !== 'all') {
      url = url.concat(`&order_type=${orderType}`);
    }

    console.log(theme);

    API.get(url)
      .then(({ data }) => {
        if (isMounted) {
          dispatch(FETCH_ORDER_LIST.success(data));
        }
      })
      .catch(() => {
        if (isMounted) {
          dispatch(FETCH_ORDER_LIST.failure());
        }
      });

    return () => {
      isMounted = false;
    };
  }, [companyId, reloadTrigger, statuses, orderType, theme]); // Add queryParam to the dependency array

  const handleChange = (event, newValue) => {
    setOrderType(newValue); // Update the query parameter
    setOrderTypeTabValue(newValue);
    setReloadTrigger(uuidv4()); // Trigger a reload
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={orderTypeTabValue}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        indicatorColor="primary" // Use predefined color
        textColor="primary" // Use predefined color
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main,
          },
          // '& .MuiTab-root': {
          //   color: 'inherit',
          // },
          '& .Mui-selected': {
            color: `${theme.palette.primary.main} !important`,
            // Customize selected tab color
          },
        }}
      >
        <Tab label="All" value="all" sx={{ minWidth: 'fit-content', flex: 1 }} />
        <Tab label="Arrivals" value="arrival" sx={{ minWidth: 'fit-content', flex: 1 }} />
        <Tab label="Relocations" value="relocation" sx={{ minWidth: 'fit-content', flex: 1 }} />
        <Tab label="Dispatches" value="dispatch" sx={{ minWidth: 'fit-content', flex: 1 }} />
      </Tabs>
      <Choose>
        <When condition={isDefault(requestStatus) || isLoading(requestStatus)}>
          <OrderListSkeleton hasActions={statuses.includes(ORDER_STATUSES.PD)} />
        </When>
        <When condition={isSuccess(requestStatus)}>
          <Choose>
            <When condition={isEmpty(orderList)}>
              <TextPlaceholder text="No orders available" />
            </When>
            <Otherwise>
              <OrderListContext.Provider
                value={{
                  reloadOrderList: () => setReloadTrigger(uuidv4()),
                }}
              >
                <OrderList orders={orderList} {...props} />
              </OrderListContext.Provider>
            </Otherwise>
          </Choose>
        </When>
        <When condition={isFailure(requestStatus)}>
          <TextPlaceholder error />
        </When>
      </Choose>
    </Box>
  );
}

export default OrderListContainer;
