import React from 'react';

import { IconButton } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';

import { ALLOWED_FILE_TYPES } from 'modules/shared/constants';

const ImageButton = React.forwardRef(({ _onIconClick, _onFileSelect }, ref) => (
  <>
    <IconButton size="small" onClick={_onIconClick}>
      <ImageIcon />
    </IconButton>

    <input
      ref={ref}
      type="file"
      style={{ display: 'none' }}
      accept={ALLOWED_FILE_TYPES.join(', ')}
      multiple={false}
      onChange={_onFileSelect}
    />
  </>
));

export default ImageButton;
