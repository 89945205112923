import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';

// function getDateOfISOWeek(w, y) {
//   const simple = new Date(y, 0, 1 + (w - 1) * 7);
//   const dow = simple.getDay();
//   const ISOweekStart = simple;
//   if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 2);
//   else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
//   return ISOweekStart;
// }

const formatTime = (time) => DateTime.fromISO(time).toFormat('dd LLL yyyy');

const toOccupancyOverTimeData = (data, returnPercentageSwitcher) => {
  if (isEmpty(data)) return false;
  const initialData = {
    labels: [],
    avg: [],
    max: [],
  };
  const results = data.reduce((acc, item) => {
    const getItem = (isAvg) => {
      const fieldName = `${returnPercentageSwitcher ? '' : 'percentage_'}${isAvg ? 'avg' : 'max'}`;
      return parseFloat(item[fieldName]?.toFixed(1));
    };
    return {
      labels: acc.labels.concat(formatTime(item.date)),
      avg: acc.avg.concat(getItem(true)),
      max: acc.max.concat(getItem(false)),
    };
  }, initialData);
  return {
    labels: results.labels,
    datasets: [
      {
        label: 'Avg Occupancy',
        backgroundColor: '#34BBDB',
        data: results.avg,
      },
      {
        label: 'Max Occupancy',
        backgroundColor: '#0D6F85',
        data: results.max,
      },
    ],
  };
};
export default toOccupancyOverTimeData;
