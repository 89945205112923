/* eslint-disable no-param-reassign */
const splitOneGraph = (graphs, chunckSize = 5) => {
  return Object.keys(graphs).reduce((result, currentName) => {
    const current = graphs[currentName];
    const objectsQuantity = Math.ceil(current.length / chunckSize);
    for (let i = 0; i < objectsQuantity; i++) {
      const from = i * chunckSize;
      const to = from + chunckSize;
      const array = current.slice(from, to);
      if (result[i] === undefined) result[i] = {};

      result[i][currentName] = array;
    }
    return result;
  }, []);
};

export default splitOneGraph;
