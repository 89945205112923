import React from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

import { PageHeader } from 'modules/shared/components';
import { VerticalBarGraph, ReportDataCards } from 'modules/liveLook/components';
import { isEmpty } from 'lodash';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';

import { GRAPH_TYPES, pageTypes } from 'modules/liveLook/constants';
import renderFilters from '../../utils/activeFiltersPdf';
import styles from '../../LLPrintablePdfPage.module.scss';

import graphDeskConfig from '../SummaryKeyFindings/graphDeskConfig';

const DesksAmberState = ({
  databoxes,
  activeFilters,
  deskGraphData,
  deskGraphScaleValues,
  returnPercentageSwitcherDeskGraph,
  deskGraphOnSwitcherChange,
  editableTextReport,
}) => {
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));

  return (
    <div>
      <div className={cn(styles.pdfPage)}>
        <Grid style={{ paddingTop: '20px', paddingLeft: '30px' }} container spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader pdfMode title="Desk Amber State" />
            </Grid>
          </Grid>
          {!shouldRenderFilters && renderFilters(activeFilters)}
          <div className={styles.blockContainer}>
            <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.desks} />
          </div>
          <Grid style={{ paddingTop: '7px' }} xs={12}>
            <VerticalBarGraph
              title="Amber usage by team zones"
              data={deskGraphData}
              returnPercentageSwitcher={returnPercentageSwitcherDeskGraph}
              scaleValues={deskGraphScaleValues}
              onSwitcherChange={deskGraphOnSwitcherChange}
              config={graphDeskConfig}
              customHeader
              pdfCustomHeader
              isLiveLook
            />
          </Grid>
          <PDFTextEditorContainer
            graphType={GRAPH_TYPES.VERTICAL}
            hasFilters={shouldRenderFilters}
            data={[]}
            onSave={() => {}}
            text={editableTextReport}
          />
        </Grid>
      </div>
    </div>
  );
};

export default DesksAmberState;
