import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader, TextEditor } from 'modules/shared/components';
import { FilterList, LiveCounter, ReportDataCards, UsageOverTimeGraph } from 'modules/liveLook/components';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import IFrameModal from 'modules/liveLook/components/iFrame';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';

import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import config from './config';

const DoorsOverTime = ({
  data,
  niceMax,
  onScaleChange,
  value,
  onValueChange,
  graphData,
  linesData,
  scaleValues,
  databoxes,
  daysDiffernce,
  scale,
  onSwitcherChange,
  customHeader,
  databoxesSet,
  editableTextReport,
  saveGraphsText,
  returnPercentageSwitcher,
  archive,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Occupancy vs Capacity" />
          </Grid>
          <Grid item xs={12}>
            <FilterList doorSensorData pageType="OCCUPANCY" subtypes={false} />
          </Grid>
          <Grid item xs={12}>
            <ReportDataCards databoxes={databoxes} pageType={pageTypes.occupancy} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
          <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>
        <Grid>
          <AddToPDFCard
            type="Occupancy Over Time"
            sectionType="Occupancy"
            category={pdfCases.occupancyOverTime}
            sectionOrder={sectionsOrder.second}
            typeOrder={sectionItemOrder.first}
            chartName={liveLookPagesNames.doorsOverTime}
            activeFilters={filtersDataContainer('OCCUPANCY', false).initActiveFilters}
            data={{
              value,
              graphData,
              linesData,
              scaleValues,
              databoxes,
              data,
              niceMax,
              scale,
              customHeader,
              editableTextReport,
              returnPercentageSwitcher,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <UsageOverTimeGraph
          daysDiffernce={daysDiffernce}
          scale={scale}
          onScaleChange={onScaleChange}
          onSwitcherChange={onSwitcherChange}
          value={value}
          isSwitcherOn={returnPercentageSwitcher}
          onValueChange={onValueChange}
          graphData={graphData}
          linesData={linesData}
          scaleValues={scaleValues}
          title="Occupancy Over Time"
          config={config}
          customHeader={customHeader}
        />
      </Grid>

      <Grid item xs={12}>
        <TextEditor
          chartName={liveLookPagesNames.doorsOverTime}
          data={databoxesToEditorData(databoxesSet)}
          // eslint-disable-next-line no-shadow
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
          graphType={GRAPH_TYPES.OVERTIME}
        />
      </Grid>
    </Grid>
  );
};

export default DoorsOverTime;
