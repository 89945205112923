import { byBuildingAndFloorTypes } from '../constants';

const toPropertyNamesMap = (byBuildingAndFloorData, propertyNamesMap) => {
  const { data, type } = byBuildingAndFloorData;
  const updatedData =
    type === byBuildingAndFloorTypes.building
      ? data.map((item) => {
          const propertyInList = propertyNamesMap.find((property) => property.property_id === item.name);
          return {
            ...item,
            name: (propertyInList && propertyInList.property_name) || item.name,
          };
        })
      : data;

  return updatedData;
};

export default toPropertyNamesMap;
