const config = [
  {
    key: 'name',
    label: 'Building Name',
  },
  {
    key: 'address',
    label: 'Building Address',
  },
  {
    key: 'region',
    label: 'Region',
  },
  {
    key: 'type',
    label: 'Property Type',
  },
  {
    key: 'floors',
    label: 'Floors',
  },
  {
    key: 'area',
    label: 'Net Internal Area',
    suffix: ' (Sq m)',
  },
  {
    key: 'totalOccupancyCost',
    label: 'Total Occupancy Cost',
    prefix: '£',
  },
];

export default config;
