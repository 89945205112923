const splitGraphs = (graphRows) => {
  const graphRowsKeys = Object.keys(graphRows);

  return graphRowsKeys.reduce((result, currentName, idx) => {
    const current = graphRows[currentName];

    if (idx === 0 || idx % 5 === 0) {
      const obj = {};
      obj[currentName] = current;
      result.push(obj);
    } else {
      // eslint-disable-next-line no-param-reassign
      result[result.length - 1][currentName] = current;
    }
    return result;
  }, []);
};

export default splitGraphs;
