import React from 'react';
import { isEmpty } from 'lodash';
import { List } from '@material-ui/core';

import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { isDefault, isLoading, isSuccess } from 'modules/shared/utils';

import { SidebarSkeleton } from 'modules/shared/components';
import { AllBuildings, BuildingItem } from 'modules/assetLook/components/Sidebar/components';

const BuildingList = () => {
  const [categoriesRequestStatus, categories] = useAssetLookSelector([
    'common.categoriesRequestStatus',
    'common.categories',
  ]);

  return (
    <List>
      <Choose>
        <When condition={isDefault(categoriesRequestStatus) || isLoading(categoriesRequestStatus)}>
          <SidebarSkeleton />
        </When>
        <When condition={isSuccess(categoriesRequestStatus) && !isEmpty(categories.by_building)}>
          <If condition={categories.by_building.length > 1}>
            <AllBuildings categories={categories.all} />
          </If>

          {categories.by_building.map((building) => (
            <BuildingItem key={building.building_id} {...building} />
          ))}
        </When>
        <Otherwise>{null}</Otherwise>
      </Choose>
    </List>
  );
};

export default BuildingList;
