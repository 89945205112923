import React from 'react';
import { Card, Grid, makeStyles } from '@material-ui/core';
import ModalImage from 'react-modal-image';

import { CardContent, NavTitle } from 'modules/assetLook/components/ItemDetailsCard/components';

import noImage from 'assets/images/icons/no-image.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    maxHeight: '100%',
    padding: '16px',
  },
  image: {
    height: '100%',
    maxHeight: '187.50px',
    width: '250px',
    minWidth: '250px',
    backgroundSize: 'contain',
    backgroundColor: ({ image }) => (image ? theme.palette.common.white : theme.palette.grey[200]),
  },
  titleContainer: {
    paddingLeft: ({ navDisabled }) => (navDisabled ? '16px' : '8px'),
  },
  dataContainer: {
    marginTop: '16px',
    flexGrow: '1',
    overflow: 'auto',
  },
}));

function ItemDetailsCard({
  image,
  title,
  backUrl,
  forwardUrl,
  contentData,
  elevation = 4,
  navDisabled,
  image_medium = null,
}) {
  const classes = useStyles({ image, navDisabled });
  return (
    <Card classes={{ root: classes.container }} elevation={elevation}>
      <div style={{ width: 300, height: 300 }}>
        <ModalImage
          small={image || noImage}
          medium={image_medium || image || noImage}
          large={image_medium || image || noImage}
          alt={title}
          hideDownload
          hideZoom
        />
      </div>
      {/* <CardMedia classes={{ root: classes.image }} image={image || noImage} /> */}
      <Grid container direction="column" wrap="nowrap" spacing={0}>
        <Grid item classes={{ root: classes.titleContainer }}>
          <NavTitle title={title} backUrl={backUrl} forwardUrl={forwardUrl} navDisabled={navDisabled} />
        </Grid>
        <Grid item classes={{ root: classes.dataContainer }}>
          <CardContent data={contentData} />
        </Grid>
      </Grid>
    </Card>
  );
}

export default ItemDetailsCard;
