import React from 'react';
import { Bar } from 'react-chartjs-2';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { reportPageTypes } from 'modules/spaceLook/constants';
import { cutLabels, setupStackedGraphRowsRooms, splitOneGraph, splitSizesGraphLabels } from 'utils';

import {
  PageHeader,
  AgilityDataCards,
  FilterHandler,
  ReportText,
  ReportFilter,
  GraphHeader,
} from 'modules/spaceLook/components';
import { useHasScroll } from 'modules/shared/hooks';

import config from './config';

import theme from './DesksAgility.module.scss';

const DesksAgility = ({ pdfMode, isLoading, agilityDataboxes, agilityGraphData }) => {
  const labels = pdfMode
    ? splitSizesGraphLabels(agilityGraphData, 5)
    : splitSizesGraphLabels(agilityGraphData, Object.keys(agilityGraphData).length);

  const scroll = useHasScroll();

  const graphRows = setupStackedGraphRowsRooms(agilityGraphData);
  const rowsGraphs = pdfMode ? splitOneGraph(graphRows, 5) : [graphRows];

  const sizesDatasets = rowsGraphs.map((graph, idx) => {
    const datasets = Object.keys(graph).map((rowName) => {
      return {
        label: rowName.charAt(0).toUpperCase() + rowName.slice(1),
        data: graph[rowName],
        stack: 'standing costs',
        backgroundColor: config.graphColors[rowName],
      };
    });

    return {
      labels: labels[idx].map(cutLabels(33)),
      datasets,
    };
  });

  return sizesDatasets.map((dataset, idx) => (
    <div key={idx} ref={scroll.ref} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Agility" text="Desk Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.desks}>
              <ReportFilter pageType={reportPageTypes.desks} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={12}>
          <AgilityDataCards databoxes={agilityDataboxes} />
        </Grid>

        <Grid item xs={12}>
          <div className={cn(theme.graphContainer, { [`${theme.pdfMode}`]: pdfMode })}>
            <GraphHeader label="Agility by Team" />
            <div className={theme.graphWrapper}>
              <div style={{ width: `${dataset.labels.length * 208}px` }} className={theme.graphInner}>
                <Bar key={dataset.labels.length} data={dataset} options={config.graphOptions} />
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <ReportText
            databoxes={agilityDataboxes}
            getParagraphs={['agilityFixed', 'agilityFlexible', 'agilityMobile', 'agilityNomadic']}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default DesksAgility;
