import { requestStatuses } from 'modules/shared/constants';
import { GRAPH_SCALES, GRAPH_VALUES, GRAPH_SWITCHER, GRAPH_RANGE } from 'modules/liveLook/constants';

import {
  SET_GRAPH_SCALE_WEEK,
  SET_BUILDING,
  FETCH_MEETS_OVER_TIME,
  SET_GRAPH_SCALE,
  SET_GRAPH_RANGE,
  FETCH_COMBINED_TEAM_ZONE_OCCUPANCY,
  FETCH_COMBINED_OCCUPANCY_DATABOXES,
  SET_GRAPH_VALUE,
  FETCH_MEETS_DATABOXES,
  SET_GRAPH_SWITCHER,
  FETCH_BREAK_DATABOXES,
  FETCH_BREAKOUT_OVER_TIME,
  FETCH_OCCUPANCY_DATABOXES,
  FETCH_DOORS_OVER_TIME,
  FETCH_MEETS_OVER_TIME_WEEK,
  FETCH_MEETS_DATABOXES_WEEK,
  FETCH_DOORS_OVER_TIME_WEEK,
  FETCH_OCCUPANCY_DATABOXES_WEEK,
  FETCH_BREAKOUT_OVER_TIME_WEEK,
  FETCH_BREAK_DATABOXES_WEEK,
  FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK,
  FETCH_COMBINED_OCCUPANCY_DATABOXES_WEEK,
  FETCH_DESK_DATABOXES,
  SET_SECOND_GRAPH_SWITCHER,
} from 'modules/liveLook/actions';

export const initialState = {
  // Occupancy
  dataOcc: [],
  requestStatusOcc: requestStatuses.default,
  databoxRequestStatusOcc: requestStatuses.default,
  capacity: null,
  nominalCapacity: null,
  occupancyDataboxesOcc: [],
  // Occupancy Week
  dataOccWeek: [],
  requestStatusOccWeek: requestStatuses.default,
  databoxRequestStatusOccWeek: requestStatuses.default,
  capacityWeek: null,
  nominalCapacityWeek: null,
  occupancyDataboxesOccWeek: [],
  // Combined
  dataCombined: null,
  requestStatusCombined: requestStatuses.default,
  databoxRequestStatusCombined: requestStatuses.default,
  occupancyDataboxes: [],
  // Combined week
  dataCombinedWeek: null,
  requestStatusCombinedWeek: requestStatuses.default,
  databoxRequestStatusCombinedWeek: requestStatuses.default,
  occupancyDataboxesWeek: [],
  // Meeting
  data: [],
  requestStatus: requestStatuses.default,
  databoxRequestStatus: requestStatuses.default,
  meetsDataboxes: [],
  // Meeting week
  dataMeetWeek: [],
  requestStatusMeetWeek: requestStatuses.default,
  databoxRequestStatusMeetWeek: requestStatuses.default,
  meetsDataboxesWeek: [],
  // Breakout
  dataBreak: [],
  requestStatusBreak: requestStatuses.default,
  databoxRequestStatusBreak: requestStatuses.default,
  breakDataboxes: [],
  // Breakout week
  dataBreakWeek: [],
  requestStatusBreakWeek: requestStatuses.default,
  databoxRequestStatusBreakWeek: requestStatuses.default,
  breakDataboxesWeek: [],
  // Others
  range: GRAPH_RANGE.LAST_WEEK,
  deskDataboxRequestStatus: requestStatuses.default,
  deskDataboxes: [],
  building: 0,
  scale: GRAPH_SCALES.halfAnHour,
  scaleWeek: GRAPH_SCALES.day,
  value: GRAPH_VALUES.max,
  returnPercentageSwitcher: GRAPH_SWITCHER.percentages,
  returnPercentageSwitcherWeek: GRAPH_SWITCHER.percentages,
  editableTextReport: '',
};

function reducer(state, { type, payload }) {
  switch (type) {
    // Occupancy
    case FETCH_DOORS_OVER_TIME.pending().type:
      return {
        ...state,
        requestStatusOcc: requestStatuses.pending,
      };
    case FETCH_DOORS_OVER_TIME.success().type:
      return {
        ...state,
        requestStatusOcc: requestStatuses.success,
        dataOcc: payload.data,
        capacity: payload.capacity,
        nominalCapacity: payload.nominal_capacity,
      };
    case FETCH_DOORS_OVER_TIME.failure().type:
      return {
        ...state,
        requestStatusOcc: requestStatuses.failure,
      };
    case FETCH_OCCUPANCY_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatusOcc: requestStatuses.pending,
      };
    case FETCH_OCCUPANCY_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatusOcc: requestStatuses.success,
        occupancyDataboxesOcc: payload,
      };
    case FETCH_OCCUPANCY_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatusOcc: requestStatuses.failure,
      };
    // Occupancy Week
    case FETCH_DOORS_OVER_TIME_WEEK.pending().type:
      return {
        ...state,
        requestStatusOccWeek: requestStatuses.pending,
      };
    case FETCH_DOORS_OVER_TIME_WEEK.success().type:
      return {
        ...state,
        requestStatusOccWeek: requestStatuses.success,
        dataOccWeek: payload.data,
        capacityWeek: payload.capacity,
        nominalCapacityWeek: payload.nominal_capacity,
      };
    case FETCH_DOORS_OVER_TIME_WEEK.failure().type:
      return {
        ...state,
        requestStatusOccWeek: requestStatuses.failure,
      };
    case FETCH_OCCUPANCY_DATABOXES_WEEK.pending().type:
      return {
        ...state,
        databoxRequestStatusOccWeek: requestStatuses.pending,
      };
    case FETCH_OCCUPANCY_DATABOXES_WEEK.success().type:
      return {
        ...state,
        databoxRequestStatusOccWeek: requestStatuses.success,
        occupancyDataboxesOccWeek: payload,
      };
    case FETCH_OCCUPANCY_DATABOXES_WEEK.failure().type:
      return {
        ...state,
        databoxRequestStatusOccWeek: requestStatuses.failure,
      };
    // Combined Occupancy
    case FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.pending().type:
      return {
        ...state,
        requestStatusCombined: requestStatuses.pending,
      };
    case FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.success().type:
      return {
        ...state,
        requestStatusCombined: requestStatuses.success,
        dataCombined: payload,
      };
    case FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.failure().type:
      return {
        ...state,
        requestStatusCombined: requestStatuses.failure,
      };
    case FETCH_COMBINED_OCCUPANCY_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatusCombined: requestStatuses.pending,
      };
    case FETCH_COMBINED_OCCUPANCY_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatusCombined: requestStatuses.success,
        occupancyDataboxes: payload,
      };
    case FETCH_COMBINED_OCCUPANCY_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatusCombined: requestStatuses.failure,
      };
    // Combined Occupancy Week
    case FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK.pending().type:
      return {
        ...state,
        requestStatusCombinedWeek: requestStatuses.pending,
      };
    case FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK.success().type:
      return {
        ...state,
        requestStatusCombinedWeek: requestStatuses.success,
        dataCombinedWeek: payload,
      };
    case FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK.failure().type:
      return {
        ...state,
        requestStatusCombinedWeek: requestStatuses.failure,
      };
    case FETCH_COMBINED_OCCUPANCY_DATABOXES_WEEK.pending().type:
      return {
        ...state,
        databoxRequestStatusCombinedWeek: requestStatuses.pending,
      };
    case FETCH_COMBINED_OCCUPANCY_DATABOXES_WEEK.success().type:
      return {
        ...state,
        databoxRequestStatusCombinedWeek: requestStatuses.success,
        occupancyDataboxesWeek: payload,
      };
    case FETCH_COMBINED_OCCUPANCY_DATABOXES_WEEK.failure().type:
      return {
        ...state,
        databoxRequestStatusCombinedWeek: requestStatuses.failure,
      };
    // Breakout
    case FETCH_BREAKOUT_OVER_TIME.pending().type:
      return {
        ...state,
        requestStatusBreak: requestStatuses.pending,
      };
    case FETCH_BREAKOUT_OVER_TIME.success().type:
      return {
        ...state,
        requestStatusBreak: requestStatuses.success,
        dataBreak: payload,
      };
    case FETCH_BREAKOUT_OVER_TIME.failure().type:
      return {
        ...state,
        requestStatusBreak: requestStatuses.failure,
      };
    case FETCH_BREAK_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatusBreak: requestStatuses.pending,
      };
    case FETCH_BREAK_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatusBreak: requestStatuses.success,
        breakDataboxes: payload,
      };
    case FETCH_BREAK_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatusBreak: requestStatuses.failure,
      };
    // Breakout
    case FETCH_BREAKOUT_OVER_TIME_WEEK.pending().type:
      return {
        ...state,
        requestStatusBreakWeek: requestStatuses.pending,
      };
    case FETCH_BREAKOUT_OVER_TIME_WEEK.success().type:
      return {
        ...state,
        requestStatusBreakWeek: requestStatuses.success,
        dataBreakWeek: payload,
      };
    case FETCH_BREAKOUT_OVER_TIME_WEEK.failure().type:
      return {
        ...state,
        requestStatusBreakWeek: requestStatuses.failure,
      };
    case FETCH_BREAK_DATABOXES_WEEK.pending().type:
      return {
        ...state,
        databoxRequestStatusBreakWeek: requestStatuses.pending,
      };
    case FETCH_BREAK_DATABOXES_WEEK.success().type:
      return {
        ...state,
        databoxRequestStatusBreakWeek: requestStatuses.success,
        breakDataboxesWeek: payload,
      };
    case FETCH_BREAK_DATABOXES_WEEK.failure().type:
      return {
        ...state,
        databoxRequestStatusBreakWeek: requestStatuses.failure,
      };
    // Meetings
    case FETCH_MEETS_OVER_TIME.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_MEETS_OVER_TIME.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload,
      };
    case FETCH_MEETS_OVER_TIME.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    case FETCH_MEETS_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_MEETS_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        meetsDataboxes: payload,
      };
    case FETCH_MEETS_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    // Meeting Week
    case FETCH_MEETS_OVER_TIME_WEEK.pending().type:
      return {
        ...state,
        requestStatusMeetWeek: requestStatuses.pending,
      };
    case FETCH_MEETS_OVER_TIME_WEEK.success().type:
      return {
        ...state,
        requestStatusMeetWeek: requestStatuses.success,
        dataMeetWeek: payload,
      };
    case FETCH_MEETS_OVER_TIME_WEEK.failure().type:
      return {
        ...state,
        requestStatusMeetWeek: requestStatuses.failure,
      };
    case FETCH_MEETS_DATABOXES_WEEK.pending().type:
      return {
        ...state,
        databoxRequestStatusMeetWeek: requestStatuses.pending,
      };
    case FETCH_MEETS_DATABOXES_WEEK.success().type:
      return {
        ...state,
        databoxRequestStatusMeetWeek: requestStatuses.success,
        meetsDataboxesWeek: payload,
      };
    case FETCH_MEETS_DATABOXES_WEEK.failure().type:
      return {
        ...state,
        databoxRequestStatusMeetWeek: requestStatuses.failure,
      };
    // Other
    case FETCH_DESK_DATABOXES.pending().type:
      return {
        ...state,
        deskDataboxRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_DATABOXES.success().type:
      return {
        ...state,
        deskDataboxRequestStatus: requestStatuses.success,
        deskDataboxes: payload,
      };
    case FETCH_DESK_DATABOXES.failure().type:
      return {
        ...state,
        deskDataboxRequestStatus: requestStatuses.failure,
      };
    case SET_BUILDING().type:
      return {
        ...state,
        building: payload,
      };
    case SET_GRAPH_SCALE().type:
      return {
        ...state,
        scale: payload,
      };
    case SET_GRAPH_SCALE_WEEK().type:
      return {
        ...state,
        scaleWeek: payload,
      };
    case SET_GRAPH_VALUE().type:
      return {
        ...state,
        value: payload,
      };
    case SET_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcher: payload,
      };
    case SET_SECOND_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcherWeek: payload,
      };
    case SET_GRAPH_RANGE().type:
      return {
        ...state,
        range: payload,
      };
    default:
      throw new Error();
  }
}

export default reducer;
