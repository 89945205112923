const toHourlyGraphMeetsData = (graphData) => {
  const dayLabels = [];

  const data = {
    labels: [],
    datasets: [
      {
        label: 'Occupied (seats)',
        type: 'line',
        data: [],
        fill: false,
        borderColor: '#a2559d',
        backgroundColor: '#a2559d',
      },
      {
        type: 'bar',
        label: 'Occupied',
        stack: 'Stack 0',
        data: [],
        fill: false,
        backgroundColor: '#58bfc7',
      },
      {
        type: 'bar',
        label: 'Unoccupied',
        data: [],
        stack: 'Stack 0',
        fill: false,
        backgroundColor: '#f1f1f1',
      },
    ],
  };

  graphData.forEach((dayData) => {
    dayLabels.push(dayData[0]);

    dayData[1].forEach((hourData) => {
      const roomOccupancy = hourData.percent_room_occupancy > 100 ? 100 : hourData.percent_room_occupancy;
      const seatOccupancy = hourData.percent_seat_occupancy > 100 ? 100 : hourData.percent_seat_occupancy;

      data.labels.push(hourData.hour);
      data.datasets[0].data.push(seatOccupancy);
      data.datasets[1].data.push(roomOccupancy);
      data.datasets[2].data.push(Number((100 - roomOccupancy).toFixed(1)));
    });
  });

  return { data, dayLabels };
};

export default toHourlyGraphMeetsData;
