import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './Loader.scss';

const Loader = ({ fixed, id }) => {
  const classes = cn('Loader', { fixed });

  return (
    <div id={id} className={classes}>
      <div className="loader">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

Loader.defaultProps = {
  id: null,
  fixed: false,
};

Loader.propTypes = {
  id: PropTypes.string,
  fixed: PropTypes.bool,
};

export default Loader;
