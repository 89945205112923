/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo } from 'react';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { usePdfContext } from 'modules/assetLook/hooks';
import { toRequestBody } from 'modules/assetLook/utils';

import ContentPage from './ContentPage';

function ContentPageContainer({ buildingId, categoryId }) {
  const {
    data: { pdf_data, type, company },
    theme,
  } = usePdfContext();

  const [products, setProducts] = useState(null);

  const filters = useMemo(
    () => toRequestBody(pdf_data.activeFilters, pdf_data.tags),
    [pdf_data.activeFilters, pdf_data.tags],
  );

  useEffect(() => {
    let isCanceled = false;

    const url = ENDPOINTS.assetLook.products.replace(':companyId', company.id);

    const data = {
      building_id: Number(buildingId),
      category_id: Number(categoryId),
      filters,
    };

    API.post(url, data)
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        if (isCanceled) return;
        setProducts(data.data);
      })
      .catch((err) => {
        if (isCanceled) return;
        // eslint-disable-next-line no-console
        console.error(err);
      });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [buildingId, categoryId, company.id, filters]);

  return (
    <ContentPage
      products={products}
      buildingId={buildingId}
      type={type}
      activeFilters={pdf_data.activeFilters}
      theme={theme}
    />
  );
}

export default ContentPageContainer;
