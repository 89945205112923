import { isEmpty } from 'lodash';

// eslint-disable-next-line import/extensions
import config from '../pages/_privatePages/liveLook/BreakoutSpaceUsageBySize/configObserved.js';

const setDataset = (data, keysList) => {
  if (isEmpty(data.All)) return null;

  const dataObject = {};
  const keysListOfProp = Object.keys(data.All).sort();

  const setPropsValue = (index) => {
    const arr = [];
    for (let j = 0; j < keysList.length; j += 1) {
      const nameOfProp = keysList[j];
      arr.push(data[nameOfProp][index]);
    }
    return arr;
  };

  for (let index = 0; index < keysListOfProp.length; index += 1) {
    dataObject[`${keysListOfProp[index]} ${index === 0 ? 'person' : 'people'}`] = setPropsValue(keysListOfProp[index]);
  }
  return dataObject;
};

const setGraphInfo = (data) => {
  if (isEmpty(data)) return false;
  const keysList = Object.keys(data).sort();

  const graphDataObject = {
    labels: keysList,
    datasets: Object.keys(setDataset(data, keysList)).map((rowName) => ({
      label: rowName,
      data: setDataset(data, keysList)[rowName],
      stack: 'standing costs',
      backgroundColor: config.observedSizesColors[rowName],
    })),
  };

  const graphData = {
    labels: graphDataObject.labels,
    datasets: graphDataObject.datasets,
  };

  return graphData;
};

export default setGraphInfo;
