const config = [
  {
    legendLabel: 'Desks',
    iconType: 'round',
    color: '#58BFC7',
  },
  {
    legendLabel: 'Breakouts',
    iconType: 'round',
    color: '#F6A000',
  },
  {
    legendLabel: 'Unoccupied ',
    iconType: 'round',
    color: '#f1f1f1',
  },
  {
    legendLabel: 'Peak occupancy',
    lineLabel: 'Peak occupancy',
    iconType: 'line',
    color: '#0d6f85',
    valueKey: 'peakOccupancy',
  },
  {
    legendLabel: 'Avg occupancy',
    lineLabel: 'Avg occupancy',
    color: '#f6a000',
    iconType: 'line',
    valueKey: 'avgOccupancy',
  },
];

export default config;
