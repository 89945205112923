import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { GraphLegend } from 'modules/spaceLook/components';
import { NoDataAvailable } from 'modules/liveLook/utils';
import GraphBody from './components/GraphBody';
import theme from './StackedBarGraph.module.scss';

const StackedBarGraph = ({ title, data, config, pdfCustomHeader, isLiveLook, shortSize }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={pdfCustomHeader ? 'subtitle2' : 'h5'}>
          <Box fontWeight="fontWeightBold" component="span">
            {title}
          </Box>
        </Typography>
      </Grid>
      <Grid className={pdfCustomHeader ? theme.pdfBody : theme.body} xs={12}>
        {
          // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
          data ? (
            <GraphBody data={data} pdfMode={pdfCustomHeader} />
          ) : (
            <NoDataAvailable theme={pdfCustomHeader ? theme.pdfGraphInner : theme.graphInner} />
          )
        }
      </Grid>

      <Grid item xs={12}>
        <GraphLegend
          isLiveLook={isLiveLook}
          shortSize={shortSize}
          pdfMode={pdfCustomHeader}
          config={config}
          marginTop={0}
          sm={6}
          md={3}
          lg={3}
        />
      </Grid>
    </Grid>
  );
};

export default StackedBarGraph;
