import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';
import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

export const FETCH_DOWNLOADS_AVAILABLE_FILTERS = createAsyncAction(createAction('FETCH_DOWNLOADS_AVAILABLE_FILTERS'));

const fetchDownloadsAvailableFilters = (dispatch, id, combinedParams, desksParams) => {
  dispatch(FETCH_DOWNLOADS_AVAILABLE_FILTERS.pending());

  const token = isLogged();
  const combinedUrl = ENDPOINTS.combinedInitFilters.replace(':id', id);
  const desksUrl = ENDPOINTS.desksInitFilters.replace(':id', id);
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  Promise.all([axios.get(`${combinedUrl}?${combinedParams}`, config), axios.get(`${desksUrl}?${desksParams}`, config)])
    .then(([{ data: combined }, { data: desks }]) => {
      dispatch(FETCH_DOWNLOADS_AVAILABLE_FILTERS.success({ combined, desks }));
    })
    .catch(() => dispatch(FETCH_DOWNLOADS_AVAILABLE_FILTERS.failure()));
};

export default fetchDownloadsAvailableFilters;
