import React, { useState } from 'react';
import cn from 'classnames';

import { Paper, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import theme from './Card.module.scss';

// eslint-disable-next-line no-shadow
const useStyles = makeStyles((theme) => ({
  paper: {
    '&:hover $icon': {
      fill: theme.palette.primary.main,
    },
  },
  title: {
    color: theme.palette.primary.dark,
  },
  icon: {
    fill: ({ iconColor }) => iconColor || '',
    width: '34px',
    height: '34px',
  },
}));

const Card = ({ title, subTitle, IconComponent, iconColor, data, subData, subDataColor, color, _onCardClick }) => {
  const classes = useStyles({ iconColor });

  const [elevation, setElevation] = useState(2);
  const setOnClickElevation = _onCardClick && {
    onMouseEnter: () => setElevation(4),
    onMouseLeave: () => setElevation(2),
    onMouseDown: () => setElevation(2),
    onMouseUp: () => setElevation(4),
  };

  return (
    <Paper
      className={theme.cardBody}
      classes={{ root: classes.paper }}
      style={{
        backgroundColor: `${color}`,
        cursor: _onCardClick ? 'pointer' : false,
      }}
      elevation={elevation}
      onClick={_onCardClick}
      {...setOnClickElevation}
    >
      <div className={cn(theme.title, classes.title)}>{title}</div>
      <div className={theme.subTitle}>{subTitle}</div>
      <div className={theme.content}>
        <Choose>
          <When condition={IconComponent}>
            <div className={theme.icon}>
              <IconComponent className={classes.icon} />
            </div>
          </When>
          <When condition={data || data === 0}>{data}</When>
          <Otherwise>
            <Skeleton />
          </Otherwise>
        </Choose>
      </div>
      <div className={theme.subContent} style={{ color: subDataColor }}>
        <Choose>
          <When condition={subData}>{subData}</When>
          <Otherwise>
            <Skeleton />
          </Otherwise>
        </Choose>
      </div>
    </Paper>
  );
};

export default Card;
