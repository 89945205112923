/* eslint-disable react/no-children-prop */
import React from 'react';
import { Link } from 'react-router-dom';

import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(() => ({
  actionButtons: {
    padding: '4px',
  },
}));

function NavTitle({ backUrl, forwardUrl, title, navDisabled }) {
  const classes = useStyles();

  const LinkComponent = backUrl && forwardUrl ? Link : 'a';

  return (
    <Grid container spacing={1}>
      <If condition={!navDisabled}>
        <Grid item>
          <LinkComponent to={backUrl || undefined}>
            <IconButton disabled={!backUrl} children={<ChevronLeftIcon />} classes={{ root: classes.actionButtons }} />
          </LinkComponent>
          <LinkComponent to={forwardUrl || undefined}>
            <IconButton
              disabled={!forwardUrl}
              children={<ChevronRightIcon />}
              classes={{ root: classes.actionButtons }}
            />
          </LinkComponent>
        </Grid>
      </If>
      <Grid item>
        <Typography variant="h5" component="span" children={title} />
      </Grid>
    </Grid>
  );
}

export default NavTitle;
