import { cloneDeep } from 'lodash';

const constructOptions = (peaks, options, withLabels = true) => {
  const peak = peaks[0];
  const oneBarOptions = cloneDeep(options);
  if (!withLabels) oneBarOptions.animation.onComplete = null;
  oneBarOptions.scales.xAxes[0].ticks.max = peak;
  return oneBarOptions;
};

export default constructOptions;
