const pdfCases = {
  keyFindings: 'Key Findings',
  keyFindingsDesks: 'Key Findings - Desks',
  keyFindingsOccupancy: 'Key Findings - Occupancy',
  keyFindingsBreakout: 'Key Findings - Breakout',
  keyFindingsMeeting: 'Key Findings - Meeting',
  keyFindingsFloor: 'Executive Summary - Floor Plan',
  overallOcuppancy: 'Summary Overall Occupancy',
  occupancyOverTime: 'Occupancy - Usage Over Time',
  occupancyDaily: 'Occupancy - Daily Comparisons',
  occupancyByBuilding: 'Occupancy - By Building and Floor',
  combinedOccupancy: 'Occupancy - Combined Occupancy',
  deskOverTime: 'Desk - Usage Over Time',
  deskSpaceComparison: 'Desk - Space Comparison',
  deskByBuilding: 'Desk - By Building and Floor',
  deskByDivision: 'Desk - By Division',
  desksAmberState: 'Desk - Amber State',
  meetingOverTime: 'Meeting - Usage Over Time',
  meetingSpaceComparison: 'Meeting - Space Comparison',
  meetingSpaceUsage: 'Meeting - Space Usage By Size',
  breakoutOverTime: 'Breakout - Usage Over Time',
  breakoutSpaceComparison: 'Breakout - Space Comparison',
  breakoutSpaceUsage: 'Breakout - Space Usage By Size',
};

export default pdfCases;
