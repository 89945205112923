import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/assetLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_TARGET_FILTERS = createAsyncAction(createAction('FETCH_TARGET_FILTERS'));

const fetchTargetFilters = (dispatch, isCanceled, { companyId, buildingId, categoryId }) => {
  dispatch(FETCH_TARGET_FILTERS.pending());

  const url = ENDPOINTS.assetLook.targetFilters.replace(':companyId', companyId);

  API.post(url, { building_id: Number(buildingId), category_id: Number(categoryId) })
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_TARGET_FILTERS.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_TARGET_FILTERS.failure());
    });
};

export default fetchTargetFilters;
