import React, { useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';

import { isFailure } from 'modules/shared/utils';
import { useAuthSelector } from 'modules/auth/hooks';
import { filtersToQuery } from 'modules/liveLook/utils';
import { requestStatuses } from 'modules/shared/constants';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { FETCH_FLOORS_LIVE_OCC, fetchFloorsLiveOcc } from 'modules/liveLook/actions';

import LiveCounter from './LiveCounter';

const initialState = {
  requestStatus: requestStatuses.default,
  counter: null,
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_FLOORS_LIVE_OCC.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_FLOORS_LIVE_OCC.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        counter: payload.count,
      };
    case FETCH_FLOORS_LIVE_OCC.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    default:
      throw new Error();
  }
}

function LiveCounterContainer() {
  const [{ requestStatus, counter }, dispatch] = useReducer(reducer, initialState);

  const { enqueueSnackbar } = useSnackbar();

  const [companyId] = useAuthSelector(['currentCompany']);
  const [activeFilters] = useLiveLookSelector(['filters.activeFilters']);

  const query = filtersToQuery(activeFilters);

  useEffect(() => {
    const abortController = new AbortController();

    fetchFloorsLiveOcc(dispatch, abortController, { companyId, query });

    return () => abortController.abort();
  }, [companyId, query]);

  useEffect(() => {
    if (isFailure(requestStatus)) enqueueSnackbar("Can't fetch the live data", { variant: 'error' });
  }, [enqueueSnackbar, requestStatus]);

  return <LiveCounter counter={counter} />;
}

export default LiveCounterContainer;
