const config = {
  day_of_week: {
    title: 'Day',
    weekDays: {
      1: 'Sunday',
      2: 'Monday',
      3: 'Tuesday',
      4: 'Wednesday',
      5: 'Thursday',
      6: 'Friday',
      7: 'Saturday',
    },
  },
  building: {
    title: 'Property',
  },
  floor: {
    title: 'Floor',
  },
  space_type: {
    title: 'Space Type',
  },
  division: {
    title: 'Division',
  },
  department: {
    title: 'Department',
  },
  self_historic: {
    title: 'Your Survey(s)',
  },
  other_historic: {
    title: 'Other Surveys, by sector',
  },
};

export default config;
