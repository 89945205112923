import { allDesks, findPercent, setStackedGraphColumnsData, setupBandingData, zeroOrValue } from 'utils';

const DesksTwentyPercent = ({ bandingOccupiedData }) => {
  const graphData = setupBandingData(Object.values(bandingOccupiedData));
  const graphBendingOccupiedClear = graphData;
  const graphBendingOccupied = setStackedGraphColumnsData(graphData);

  const { quantity } = graphBendingOccupied;
  const totalDesks = allDesks(quantity);

  const zeroPercentQuantity = zeroOrValue(graphBendingOccupiedClear['0%'].quantity);

  const less20Quantity =
    zeroPercentQuantity +
    zeroOrValue(graphBendingOccupiedClear['1%-10%'].quantity) +
    zeroOrValue(graphBendingOccupiedClear['11%-20%'].quantity);
  const less20Percent = findPercent(less20Quantity, totalDesks);

  return `If you consider the occupancy of each individual desk for the entirety of the study period 
     then ${less20Quantity} (${less20Percent}%) of the ${totalDesks} desks were occupied ≤20% of the time,
     and of these ${zeroPercentQuantity} were never seen as occupied.`;
};

export default DesksTwentyPercent;
