import React from 'react';
import { Grid } from '@material-ui/core';

import config from 'modules/liveLook/components/PdfReportsPageSelector/config';

import { PageHeader } from 'modules/shared/components';
import { PdfReportsPageSelector } from 'modules/liveLook/components';

import { Current, History } from './components';

const PdfReportsPage = ({ pageType }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Print Report" />
          </Grid>
          <Grid item xs={12}>
            <PdfReportsPageSelector />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Choose>
          <When condition={pageType === config.current.pageType}>
            <Current />
          </When>
          <When condition={pageType === config.history.pageType}>
            <History />
          </When>
        </Choose>
      </Grid>
    </Grid>
  );
};

export default PdfReportsPage;
