import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import DataCard from 'modules/liveLook/components/DataCard';

import config from './config';
import theme from './ReportDataCards.module.scss';

const ReportDataCards = ({ databoxes, pageType, pdf, cropped }) => {
  return (
    <Grid
      className={cn(`${theme.dataCardsContainer}`, { pdf })}
      container
      spacing={pdf ? 1 : 2}
      style={cropped ? { maxWidth: '485px' } : null}
    >
      {config.map(({ title, firstValue, secondValue, dateValue, color }) => {
        return (
          <If condition={title[pageType]}>
            <DataCard
              key={title[pageType]}
              pdfMode={pdf}
              title={title[pageType]}
              firstValue={databoxes[firstValue.key]}
              firstLabel={firstValue.isPercent ? '%' : ` ${firstValue.label[pageType]}`}
              secondValue={databoxes[secondValue?.key]}
              secondLabel={(secondValue?.isPercent ? '%' : ` ${secondValue?.label[pageType]}`) || ''}
              noValue={!databoxes[dateValue]}
              color={color}
            />
          </If>
        );
      })}
    </Grid>
  );
};

ReportDataCards.propTypes = {
  databoxes: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
};

export default ReportDataCards;
