/* eslint-disable react/no-children-prop */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import GraphYScale from 'modules/spaceLook/components/Graphs/GraphYScale';
import HourlyGraphLines from 'modules/spaceLook/components/Graphs/HourlyGraph/HourlyGraphLines';
import GraphLegend from 'modules/spaceLook/components/Graphs/GraphLegend';

import { getPdfMode } from 'modules/spaceLook/selectors';

import options from './options';
import theme from './HourlyGraph.module.scss';

const scaleValues = {
  pdf: ['100%', '80%', '60%', '40%', '20%', '0%'],
  web: ['100%', '90%', '80%', '70%', '60%', '50%', '40%', '30%', '20%', '10%', '0%'],
};

const HourlyGraph = ({ title, linesData, graphData, linesConfig, legendConfig, pdfModeLegendItemSize }) => {
  const pdfMode = useSelector(getPdfMode);

  const yScaleMarginBottom = graphData.length > 5 ? '100px' : pdfMode ? '75px' : '89px';
  return (
    <div>
      <div className={theme.title}>
        <Typography variant="h5">
          <Box fontWeight="fontWeightBold" component="span" children={title} />
        </Typography>
      </div>

      <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
        <div className={theme.graphContainer}>
          <GraphYScale values={pdfMode ? scaleValues.pdf : scaleValues.web} marginBottom={yScaleMarginBottom} />

          <HourlyGraphLines marginBottom={parseInt(yScaleMarginBottom)} linesData={linesData} config={linesConfig} />

          <div className={theme.barSection}>
            {graphData.map((row, i) => (
              <div key={i} className={theme.oneDayContainer}>
                <div className={theme.oneDayGraph}>
                  <Bar data={row} options={options} />
                </div>
                <div className={theme.oneDayLabel}>{row.title}</div>
              </div>
            ))}
          </div>
        </div>
        <GraphLegend
          config={legendConfig}
          pdfMode={pdfMode}
          sm={pdfMode ? pdfModeLegendItemSize : undefined}
          md={pdfMode ? pdfModeLegendItemSize : undefined}
          lg
        />
      </div>
    </div>
  );
};

export default HourlyGraph;
