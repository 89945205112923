import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isDefault } from 'modules/shared/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  getAppliedFilters,
  getKeyFindingsDesksData,
  getPdfMode,
  getSelectedFilters,
} from 'modules/spaceLook/selectors';
import {
  fetchDesksDataboxes,
  fetchDesksPieData,
  fetchDesksTextData,
  fetchDesksDailyHourlyGraphData,
  fetchDesksWeeklyHourlyGraphData,
  fetchDesksUsageBandingOccGraphData,
} from 'modules/spaceLook/actions';

import { LoadingCondition } from 'modules/spaceLook/components';

import KeyFindingsDesks from './KeyFindingsDesks';

const KeyFindingDesksContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fetchStart, setFetchStart] = useState(false);
  const pdfMode = useSelector(getPdfMode);
  const { dataRequestStatuses, data, shouldRefetchData } = useSelector(getKeyFindingsDesksData);
  const { selectedFiltersUrlParams } = useSelector((store) => getSelectedFilters(store, 'desks'));
  const { appliedFiltersRequestStatus } = useSelector(getAppliedFilters);
  const isNoData = Object.values(data).some((dataItem) => !dataItem);
  const isLoading = Object.values(dataRequestStatuses).some((status) => status === requestStatuses.pending);
  const isFiltersHistoryLoading = [requestStatuses.default, requestStatuses.pending].includes(
    appliedFiltersRequestStatus,
  );

  useEffect(() => {
    if (isFiltersHistoryLoading) return;

    if (isDefault(dataRequestStatuses.databoxes) || shouldRefetchData.databoxes) {
      fetchDesksDataboxes(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.pieData) || shouldRefetchData.pieData) {
      fetchDesksPieData(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.textData) || shouldRefetchData.textData) {
      fetchDesksTextData(dispatch, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.dailyHourlyGraphData) || shouldRefetchData.dailyHourlyGraphData) {
      fetchDesksDailyHourlyGraphData(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.weeklyHourlyGraphData) || shouldRefetchData.weeklyHourlyGraphData) {
      fetchDesksWeeklyHourlyGraphData(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.usageBandingOccGraphData) || shouldRefetchData.usageBandingOccGraphData) {
      fetchDesksUsageBandingOccGraphData(dispatch, id, selectedFiltersUrlParams);
    }
    setFetchStart(true);
  }, [
    appliedFiltersRequestStatus,
    shouldRefetchData.databoxes,
    shouldRefetchData.pieData,
    shouldRefetchData.textData,
    shouldRefetchData.dailyHourlyGraphData,
    shouldRefetchData.weeklyHourlyGraphData,
    shouldRefetchData.usageBandingOccGraphData,
    isFiltersHistoryLoading,
    dataRequestStatuses.databoxes,
    dataRequestStatuses.pieData,
    dataRequestStatuses.textData,
    dataRequestStatuses.dailyHourlyGraphData,
    dataRequestStatuses.weeklyHourlyGraphData,
    dataRequestStatuses.usageBandingOccGraphData,
    dispatch,
    id,
    selectedFiltersUrlParams,
  ]);

  return (
    <LoadingCondition isLoading={isLoading} isNoData={isNoData} fetchStart={fetchStart}>
      <KeyFindingsDesks isLoading={isLoading} pdfMode={pdfMode} {...data} />
    </LoadingCondition>
  );
};

export default KeyFindingDesksContainer;
