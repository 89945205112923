import { createReducer } from '@reduxjs/toolkit';
import { MAP_TYPES } from 'modules/modalsSystem/components/modalsWL/MapModal/MapContainer/constants';

import { SET_MAP_TYPE, SET_MAP_DATA } from 'modules/workLook/actions';

const initState = {
  mapType: MAP_TYPES.STANDARD,
  mapData: [],
  maxIntensity: 0,
};

export default createReducer(initState, {
  [SET_MAP_TYPE]: (state, { payload }) => ({
    ...state,
    mapType: payload,
  }),
  [SET_MAP_DATA]: (state, { payload }) => ({
    ...state,
    mapData: payload.buildings,
    maxIntensity: payload['max_intensity '],
    mapDataValue: payload.value,
  }),
});
