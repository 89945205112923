import CompanyInfoForm from '../components/CompanyInfo';
import PersonalInfoForm from '../components/PersonalInfo/PersonalInfo';
import UserListInfo from '../components/UserListInfo';

export const SETTINGS_TABS = {
  // admin: ['personal_info', 'company_info', 'all_users', 'user_groups'],
  admin: ['personal_info', 'all_users'],
  // partner: ['personal_info', 'all_users', 'company_info'],
  partner: ['personal_info'],
  client: ['personal_info'],
  partner_admin: ['personal_info', 'all_users'],
  company_admin: ['personal_info', 'all_users'],
};

export const TAB_NAMES = {
  personal_info: 'Personal Info',
  all_users: 'Company Users',
  user_groups: 'User Groups',
  company_info: 'Company Info',
};

export const TABS_FORMS = {
  personal_info: PersonalInfoForm,
  all_users: UserListInfo,
  user_groups: PersonalInfoForm,
  company_info: CompanyInfoForm,
};
