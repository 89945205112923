import React from 'react';

import { SidebarHeader, Copyright } from 'modules/shared/components';

import ReportList from './ReportList';
import CreateReportButton from './CreateReportButton';

import theme from './Sidebar.module.scss';

const Sidebar = ({ isPermitted }) => (
  <div className={theme.container}>
    <SidebarHeader />

    <If condition={isPermitted}>
      <CreateReportButton />
    </If>

    <ReportList />

    <Copyright style={{ marginTop: 'auto', marginBottom: '16px' }} />
  </div>
);

export default Sidebar;
