const options = {
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#4D4D4D',
          fontSize: 15,
          fontStyle: 'bold',
        },
      },
    ],
    yAxes: [
      {
        ticks: { min: 0, max: 100, display: true, stepSize: 20 },
        gridLines: {
          color: '#E4E4E4',
          zeroLineColor: '#e4e4e4',
        },
      },
    ],
  },
  legend: {
    display: true,
    position: 'bottom',
    align: 'start',
    labels: {
      fontColor: '#000',
      fontFamily: 'Roboto',
      fontWeight: 500,
      borderWidth: 'none',
      fontStyle: 'bold',
      usePointStyle: true,
      padding: 30,
    },
  },
};

export default options;
