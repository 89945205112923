import { GRAPH_COLORS } from 'modules/workLook/constants';

const getRandomColor = () => {
  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  if (GRAPH_COLORS.includes(randomColor)) {
    return getRandomColor();
  }
  return randomColor;
};

export default getRandomColor;
