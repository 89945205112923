/* eslint-disable prefer-spread */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useReducer, useMemo } from 'react';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, filtersToQuery } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import {
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
  SET_SECOND_GRAPH_SWITCHER,
  SET_GRAPH_SWITCHER,
  fetchDeskPeakOccupancyDayUsage,
  fetchOccupancyDataboxes,
  fetchDoorsOverTime,
  fetchDeskDataboxes,
  fetchBreakDataboxes,
  fetchMeetsDataboxes,
  fetchDeskVariables,
  fetchCombinedOccupancyVariables,
  fetchGraphsText,
  saveGraphsText,
} from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import toOccupancyOverTimeData from 'modules/liveLook/utils/toOccupancyOverTimeData';
import SummaryKeyFindings from './SummaryKeyFindings';
import { setScaleValues, setDataForGraph, getPeakValue } from '../utils';
import reducer, { initialState } from './_reducer';

function SummaryKeyFindingsContainer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    data,
    scale,
    occupancyDataboxes,
    deskDataboxes,
    databoxRequestStatus,
    breakDataboxRequestStatus,
    breakDataboxes,
    meetDataboxRequestStatus,
    meetsDataboxes,
    requestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
    deskGraphRequestStatus,
    deskGraphSwitcher,
    deskGraphData,
    deskVariableRequestStatus,
    combinedOccVariablesRequestStatus,
    textVariables,
  } = state;

  const deskPeakOccupancyDayUsageData = useMemo(
    () => setDataForGraph(deskGraphData, deskGraphSwitcher),
    [deskGraphData, deskGraphSwitcher],
  );

  const requestStatuses = [
    databoxRequestStatus,
    meetDataboxRequestStatus,
    breakDataboxRequestStatus,
    deskGraphRequestStatus,
    requestStatus,
    textReportRequestStatus,
    deskVariableRequestStatus,
    combinedOccVariablesRequestStatus,
  ];

  const [companyId] = useAuthSelector(['currentCompany']);
  const [startDate, endDate, workingHours, activeDoorSensorFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'doorSensorFilters.activeDoorSensorFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeDoorSensorFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;
    // if (archive) {
    //   dispatcher(SET_START_DATE(initDate));
    //   dispatcher(SET_END_DATE(arhiveDateEnd));
    // }

    fetchDoorsOverTime(dispatch, isCanceled, {
      companyId,
      scaleOption: scale,
      workingHours,
      query,
      dateAfter,
      dateBefore,
      archive,
    });

    fetchOccupancyDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
      query,
      archive,
    });

    fetchDeskDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      query,
      dateAfter,
      dateBefore,
      archive,
    });

    fetchBreakDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      query,
      dateAfter,
      dateBefore,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'sum_key_data',
    });

    fetchMeetsDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      query,
      dateAfter,
      dateBefore,
      archive,
    });

    fetchDeskPeakOccupancyDayUsage(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchDeskVariables(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchCombinedOccupancyVariables(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, dateAfter, dateBefore, workingHours, query, state.building, scale]);

  const databoxes = {
    capacity: occupancyDataboxes.capacity,
    capacity_percentage: occupancyDataboxes.capacity_percentage,
    max_occupancy_percentage: occupancyDataboxes.max_occupancy_percentage,
    max_desk_usage: deskDataboxes.max_desk_usage,
    max_desk_occupancy: deskDataboxes.max_desk_occupancy,
    max_occupancy: occupancyDataboxes.max_occupancy,
    nominal_capacity_percentage: occupancyDataboxes.nominal_capacity_percentage,
    desks: deskDataboxes.desks,
    percentage_max_desk_usage: deskDataboxes.percentage_max_desk_usage,
    percentage_max_desk_occupancy: deskDataboxes.percentage_max_desk_occupancy,
    max_rooms_usage: meetsDataboxes.max_rooms_usage,
    percentage_max_rooms_usage: meetsDataboxes.percentage_max_rooms_usage,
    max_rooms_usage_breakout: breakDataboxes.max_rooms_usage,
    percentage_max_rooms_usage_breakout: breakDataboxes.percentage_max_rooms_usage,
    nominal_capacity: occupancyDataboxes.nominal_capacity,
    max_occupancy_date: occupancyDataboxes.max_occupancy_date,
    max_desk_occupancy_date: deskDataboxes.max_desk_occupancy_date,
    max_rooms_date_breakout: breakDataboxes.max_rooms_date,
    max_rooms_date: meetsDataboxes.max_rooms_date,
    max_desk_usage_date: deskDataboxes.max_desk_usage_date,
  };

  const doorsGraphOnSwitcherChange = useCallback(
    () => dispatch(SET_SECOND_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  const onDeskSwitcherChange = useCallback(() => dispatch(SET_GRAPH_SWITCHER(!deskGraphSwitcher)), [deskGraphSwitcher]);

  const onScaleChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_SCALE(value)), []);
  const onValueChange = useCallback((e) => dispatch(SET_GRAPH_VALUE(e.target.value)), []);

  const { peakIntgerScale, peakPercentagesScale } = getPeakValue(deskGraphData);

  const peakValue = deskGraphSwitcher ? peakIntgerScale : peakPercentagesScale;

  const databoxesSet = {
    occupancyDataboxes,
    deskDataboxes,
    breakDataboxes,
    meetsDataboxes,
  };

  // eslint-disable-next-line no-shadow
  const mappedData = (data) => {
    const arrData = [];
    for (const key in data) {
      arrData.push(Object.assign(data[key], { name: key }));
    }
    return arrData;
  };

  const doorsGraphPeakOccupancy = Math.max.apply(
    Math,
    mappedData(data).map((key) => key[returnPercentageSwitcher ? 'max' : 'percentage_max']),
  );

  const doorsGraph = setScaleValues(doorsGraphPeakOccupancy);
  const deskGraph = setScaleValues(peakValue, true);

  return (
    <Choose>
      <When condition={requestStatuses.some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={requestStatuses.every((item) => isSuccess(item))}>
        <SummaryKeyFindings
          {...state}
          doorsGraphData={toOccupancyOverTimeData(data.slice(0, 10), returnPercentageSwitcher)}
          doorsGraphScaleValues={doorsGraph.scaleValues}
          deskGraphScaleValues={deskGraph.scaleValues}
          doorsGraphOnSwitcherChange={doorsGraphOnSwitcherChange}
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'sum_key_data', text });
          }}
          deskPeakOccupancyDayUsageData={deskPeakOccupancyDayUsageData}
          editableTextReport={editableTextReport}
          activeFilters={activeDoorSensorFilters}
          scale={scale}
          onScaleChange={onScaleChange}
          onValueChange={onValueChange}
          databoxes={databoxes}
          databoxesSet={databoxesSet}
          returnPercentageSwitcher={returnPercentageSwitcher}
          deskGraphSwitcher={deskGraphSwitcher}
          onDeskSwitcherChange={onDeskSwitcherChange}
          textVariables={textVariables}
          customHeader
          archive={archive}
        />
      </When>
      <When condition={requestStatuses.some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default SummaryKeyFindingsContainer;
