const config = {
  Monday: {
    color: '#00AAD2',
    data: {
      hours: [],
      values: [],
    },
  },
  Tuesday: {
    color: '#B4CC04',
    data: {
      hours: [],
      values: [],
    },
  },
  Wednesday: {
    color: '#C4043F',
    data: {
      hours: [],
      values: [],
    },
  },
  Thursday: {
    color: '#A2559D',
    data: {
      hours: [],
      values: [],
    },
  },
  Friday: {
    color: '#F6A000',
    data: {
      hours: [],
      values: [],
    },
  },
  Saturday: {
    color: '#e7e7e7',
    data: {
      hours: [],
      values: [],
    },
  },
  Sunday: {
    color: '#e7e7e7',
    data: {
      hours: [],
      values: [],
    },
  },
};

export default config;
