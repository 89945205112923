import React from 'react';
import PropTypes from 'prop-types';

import './FormHeader.scss';

const FormHeader = ({ title }) => {
  return <h2 className="FormHeader">{title}</h2>;
};

FormHeader.defaultProps = {
  title: 'Some title',
};

FormHeader.propTypes = {
  title: PropTypes.string,
};

export default FormHeader;
