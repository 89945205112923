/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';
import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { useDispatch } from 'react-redux';
import { SET_REPORT_ID, SET_SECTION_DATA, SET_SECTION_ID } from 'modules/workLook/actions';

const PageHeader = ({ title, hasViewRights, pdfMode, section, isAdmin, typeId, report, isPublished }) => {
  const dispatch = useDispatch();
  const url = ENDPOINTS.workLook.crudSection.replace(':sectionId', section);

  const onEditClick = () => {
    API.get(url).then(({ data }) => {
      dispatch(SET_SECTION_ID(section));
      dispatch(SET_REPORT_ID(report));
      dispatch(SET_SECTION_DATA({ data, action: 'edit', typeId }));
      dispatch(showModal(modalCodes.WL_CREATE_SECTION_MODAL));
    });
  };

  return (
    <Grid container alignItems="center">
      <Typography variant={pdfMode ? 'h5' : 'h4'}>
        <Box fontWeight="fontWeightMedium" component="span">
          {title}
        </Box>
      </Typography>
      <If condition={(isAdmin && !isPublished) || hasViewRights}>
        <div onClick={onEditClick}>
          <BorderColorIcon sx={{ ml: 2 }} color="action" fontSize="small" />
        </div>
      </If>
    </Grid>
  );
};

export default PageHeader;
