import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import SummaryGraphSwitcher from './SummaryGraphSwitcher';
import SummaryPageGraphs from './SummaryPageGraphs';

import config from './config';

const Graphs = ({ state: { graphType, graphDataType, sortOrder, data: graphsData }, dispatch }) => (
  <Grid container spacing={4}>
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} lg={4}>
          <Typography variant="h6">{config.graphsHeader[graphType][graphDataType]}</Typography>
        </Grid>
        <Grid item xs={12} lg={8} align="right">
          <SummaryGraphSwitcher
            graphType={graphType}
            graphDataType={graphDataType}
            sortOrder={sortOrder}
            dispatch={dispatch}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <SummaryPageGraphs graphType={graphType} graphDataType={graphDataType} sortOrder={sortOrder} data={graphsData} />
    </Grid>
  </Grid>
);

export default Graphs;
