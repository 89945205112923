import React, { useState } from 'react';
import { useFormik } from 'formik';
import { SET_ADDITIONAL_FILES, SET_CSV_DATA } from 'modules/workLook/actions';
import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { findEmpty } from 'modules/workLook/components/UploadCsvModal/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getBlankReport } from 'modules/workLook/selectors';
import UploadCSV from '../UploadCSV';

const UploadCsvContainer = ({ onNext }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { reportFiles, blankReportData } = useSelector(getBlankReport);
  const [isLoading, setIsLoading] = useState(false);
  const even = (element) => element.id === '1';
  const [errors, setErrors] = useState([]);

  const postFiles = async (files) => {
    const url = ENDPOINTS.workLook.blankCsv.replace(':reportId', blankReportData.id);
    const data = new FormData();
    files.map(({ id, file }, index) => {
      return data.append(id.toString(), file);
    });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { data: csvData } = await API.post(url, data, config);
      dispatch(SET_CSV_DATA(csvData));
      onNext();
      setIsLoading(false);
    } catch ({ response }) {
      setErrors(response.data.errors);
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      files: [],
    },
    onSubmit: async (values) => {
      setErrors([]);
      setIsLoading(true);

      const filterdValues = findEmpty(values.files, reportFiles);
      if (filterdValues.length) {
        return filterdValues.forEach(({ id }) => {
          setIsLoading(false);
          formik.setFieldError(id.toString(), 'Please select a file');
        });
      }
      const filesWithId = await Promise.all(
        await values.files.map(async (file, index) => {
          if (index > 0) {
            const urlTable = ENDPOINTS.workLook.reportTableAlias.replace(':reportId', blankReportData.id);
            const tableInfo = await API.post(urlTable, { name: `Aditional table ${index}` }).then(({ data }) => {
              return data;
            });
            const fileName = file?.file?.name.split('.')[0];
            return { ...file, id: tableInfo?.id, name: fileName };
          }
          return file;
        }),
      );
      const additionalFiles = filesWithId.slice(1).map((table, index) => ({ name: table.name, id: table.id }));
      const isAdditionalStepsShown = !(filesWithId.length === 1);
      dispatch(
        SET_ADDITIONAL_FILES({
          additionalFile: additionalFiles,
          displayNewStep: isAdditionalStepsShown,
        }),
      );

      return postFiles(filesWithId);
    },
  });

  return (
    <UploadCSV open={open} setOpen={setOpen} isLoading={isLoading} errors={errors} formik={formik} data={reportFiles} />
  );
};

export default UploadCsvContainer;
