import { setDataGroupedByDays } from 'pages/_privatePages/liveLook/utils/setDataForOverTime';
import { splitGraphData } from './splitGraphData';

const getHourLength = (graphData) => {
  if (!graphData) return { hoursLength: false };

  return {
    hoursLength: graphData.dayLabels.length <= 1 ? graphData.dayLabels[0]?.length : graphData.dayLabels[1]?.length,
  };
};

const getSplittedGraphData = (data, scale, toLondonTimeZoneFunc, graphData) => {
  const dataGroupedByDays = setDataGroupedByDays(data, scale, toLondonTimeZoneFunc);
  const { hoursLength } = getHourLength(graphData);
  const splittedGraphData = splitGraphData(hoursLength, dataGroupedByDays, scale);

  return { hoursLength, splittedGraphData };
};

export default getSplittedGraphData;
