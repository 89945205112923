import React from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

import { reportPageTypes } from 'modules/spaceLook/constants';

import { PageHeader, ReportText, ReportDataCards, ReportFilter, FilterHandler } from 'modules/spaceLook/components';
import { useHasScroll } from 'modules/shared/hooks';

const KeyFindingsBreakout = ({ isLoading, pdfMode, databoxes, pieData, textData }) => {
  const scroll = useHasScroll();
  return (
    <div ref={scroll.ref} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Key Findings - Breakout" text="Executive Summary" />
        </Grid>
        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.breakout}>
              <ReportFilter pageType={reportPageTypes.breakout} />
            </FilterHandler>
          </Grid>
        </If>
        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.breakout} />
        </Grid>
        <Grid item xs={12}>
          <ReportText
            getParagraphs={[
              'meetsAndBreakOccupancy',
              'meetsAndBreakOccupancySeats',
              'meetsAndBreakPeakUsage',
              'meetsAndBreakAvgOccupancy',
              'meetsAndBreakSoloUse',
              'meetsAndBreakOneToFourUse',
              'meetsAndBreakLargeUse',
              'mostCommonActivities',
            ]}
            pageType={reportPageTypes.breakout}
            databoxes={databoxes}
            pieData={pieData}
            textData={textData}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default KeyFindingsBreakout;
