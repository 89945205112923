/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { useHasScroll } from 'modules/shared/hooks';
import { getPdfMode } from 'modules/spaceLook/selectors';
// eslint-disable-next-line import/no-cycle
import { GraphHeader, GraphYScale, GraphLegend } from 'modules/spaceLook/components';
import { NoDataAvailable } from 'modules/liveLook/utils';

import options from './options';
import config from './config';
import theme from './OccupationUtilizationGraph.module.scss';

const OccupationUtilizationGraph = ({ data }) => {
  const pdfMode = useSelector(getPdfMode);
  const dataLength = data.labels.length;

  const { ref, hasHorScroll } = useHasScroll();

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      <GraphHeader label="Peak and Average by Day" />

      {dataLength ? (
        <div className={theme.graphWrapper}>
          <GraphYScale marginBottom={hasHorScroll ? '43px' : '30px'} />
          <div className={theme.graphInner} ref={ref}>
            <div style={{ width: `${dataLength * 192}px` }} className={theme.graph}>
              <Bar key={dataLength} data={data} options={options} />
            </div>
          </div>
        </div>
      ) : (
        <NoDataAvailable theme={theme.emptyGraphInner} />
      )}

      <GraphLegend config={config.legend} pdfMode={pdfMode} sm={pdfMode ? 3 : undefined} xl={3} />
    </div>
  );
};

export default OccupationUtilizationGraph;
