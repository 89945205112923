/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import React, { useCallback, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { List } from '@material-ui/core';

import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { isDefault, isLoading, isSuccess } from 'modules/shared/utils';
import { RESET_FILTERS } from 'modules/liveLook/actions';
import { SidebarHeader, Copyright, SidebarSkeleton } from 'modules/shared/components';
import { useDispatch } from 'react-redux';
import DatePicker from './components/DatePicker';
import ReportNavItem from './components/ReportNavItem';
import config, { ARCHIVE, DASHBOARD } from './config';
import theme from './Sidebar.module.scss';
import ArchiveExit from './components/ArchiveExit';

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleOnClick = useCallback(() => {
    history.push(DASHBOARD.path);
    dispatch(RESET_FILTERS());
  }, [dispatch, history]);

  const handleOnClickArchives = useCallback(() => {
    history.push(ARCHIVE.path);
  }, [history]);

  const { pathname } = useLocation();
  const [requestStatus, buildings, titles, archive] = useLiveLookSelector([
    'common.requestStatus',
    'common.buildings',
    'common.titles',
    'common.archive',
  ]);
  const isDashboardPage = DASHBOARD.path === history.location.pathname;
  const clientTitles = (obj) => {
    return obj ? Object.values(obj) : null;
  };

  const clientLabels = clientTitles(titles)?.map((label) => label.charAt(0).toUpperCase() + label.slice(1));

  const configLabelsMap = (config, titlesArr) => {
    const mutatedArr = titlesArr
      ? titlesArr.unshift('Executive Summary') &&
        titlesArr.push('PDF Reports') &&
        config?.forEach((item, i) => (item.label = titlesArr[i]))
      : null;
    const returnedArr = mutatedArr || config;

    return returnedArr;
  };

  const [openList, setOpenList] = useState(
    get(
      config.find((list) => list.items.some(({ path }) => path === pathname)),
      'label',
      '',
    ),
  );

  return (
    <div className={theme.container}>
      <SidebarHeader />
      {archive && <ArchiveExit archive={archive} />}
      <Choose>
        <When condition={isDefault(requestStatus) || isLoading(requestStatus)}>
          <SidebarSkeleton />
          {archive && <ArchiveExit archive={archive} />}
        </When>
        <When condition={isSuccess(requestStatus) && !isEmpty(buildings)}>
          <DatePicker disable={isDashboardPage} />
          <ReportNavItem
            key={DASHBOARD.label}
            pathname={pathname}
            isOpen={openList === DASHBOARD.label}
            dashboard
            onClick={handleOnClick}
            {...DASHBOARD}
            hasArchive={archive}
          />
          <List>
            {configLabelsMap(config, clientLabels).map((item) => (
              <ReportNavItem
                key={item.label}
                pathname={pathname}
                isOpen={openList === item.label}
                onClick={() => {
                  openList === item.label ? setOpenList('') : setOpenList(item.label);
                }}
                {...item}
              />
            ))}
          </List>
          <ReportNavItem
            key={ARCHIVE.label}
            pathname={pathname}
            isOpen={openList === ARCHIVE.label}
            archive
            onClick={handleOnClickArchives}
            {...ARCHIVE}
          />
        </When>
        <Otherwise>{null}</Otherwise>
      </Choose>
      <Copyright style={{ marginTop: 'auto', marginBottom: '16px' }} />
    </div>
  );
};

export default Sidebar;
