/* eslint-disable camelcase */
const DesksOccupancy = ({
  databoxes: { total_desks, percent_avg_occupancy, avg_occupancy, percent_max_occupancy, max_occupancy },
}) =>
  `Of the ${total_desks} desks, the average percentage
   of physically occupied desks was ${percent_avg_occupancy}% (${avg_occupancy} desks)
   with a peak of ${percent_max_occupancy}% (${max_occupancy} of the ${total_desks} desks occupied,
   leaving ${total_desks - max_occupancy} desks either vacant or exhibiting signs of life).`;

export default DesksOccupancy;
