import React, { Children, cloneElement, useState, useCallback } from 'react';

import './Tabs.scss';

const Tabs = ({ children }) => {
  const [activeIdx, setActiveIdx] = useState(0);

  const onClick = useCallback((idx) => {
    setActiveIdx(idx);
  }, []);

  const renderedTabs = children && Children.map(children, (child) => cloneElement(child, { onClick, activeIdx }));

  return (
    <article className="Tabs">
      {renderedTabs || <p className="Tabs-message">Error: TabsNav and TabsContent components must be here</p>}
    </article>
  );
};

export default Tabs;
