import React from 'react';
import { useDispatch } from 'react-redux';
import { TableCell, TableSortLabel, Checkbox } from '@material-ui/core';

import { ASSET_FIELDS } from 'modules/assetLook/constants';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { CHECK_ALL_ASSETS, SORT_ASSETS } from 'modules/assetLook/actions';

const labelsMap = {
  [ASSET_FIELDS.id]: 'ID',
  [ASSET_FIELDS.status]: 'Status',
  [ASSET_FIELDS.tags]: 'Tags',
};

function AssetListCellHeader({ columnKey, height, width, data }) {
  const dispatch = useDispatch();

  const label = labelsMap[columnKey] || columnKey;
  const isCheckbox = columnKey === ASSET_FIELDS.checkbox;

  const [assetsSortParams] = useAssetLookSelector(['assets.sortParams']);
  const isActiveSort = assetsSortParams.orderBy === columnKey;

  return (
    <TableCell
      size="small"
      component="div"
      variant="head"
      title={isCheckbox ? null : label}
      onClick={() => (isCheckbox ? dispatch(CHECK_ALL_ASSETS()) : dispatch(SORT_ASSETS(columnKey)))}
      style={{
        height,
        width,
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <Choose>
        <When condition={isCheckbox}>
          <Checkbox
            checked={data.every((asset) => asset[ASSET_FIELDS.checkbox])}
            size="small"
            color="primary"
            disableRipple
            style={{ padding: 0 }}
          />
        </When>
        <Otherwise>
          <TableSortLabel active={isActiveSort} direction={assetsSortParams.direction} hideSortIcon>
            {label}
          </TableSortLabel>
        </Otherwise>
      </Choose>
    </TableCell>
  );
}

export default AssetListCellHeader;
