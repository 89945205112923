/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Card, CardHeader, CardContent, Typography, Grid, CardActions, withStyles } from '@material-ui/core';
import { ORDER_STATUSES, ORDER_STATUS_LABELS } from 'modules/assetLook/constants';

// eslint-disable-next-line import/no-cycle
import { OrderInfo, AccordionAssets, AccordionHistory, OrderActions } from './components';

const PANELS = {
  ASSETS: 'Asset list',
  HISTORY: 'Status history',
};

const OrderItem = ({ classes, id, date_created, date_updated, status, ...otherProps }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Card elevation={2}>
      <CardHeader
        title={
          <Grid container justify="space-between" spacing={0}>
            <Grid item>
              <Typography variant="h6">{`№ ${id}`}</Typography>
            </Grid>
            <Grid>
              <Typography variant="h6">{ORDER_STATUS_LABELS[status]}</Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <OrderInfo {...otherProps} />
        <AccordionAssets
          expanded={expanded === PANELS.ASSETS}
          onChange={handleChange(PANELS.ASSETS)}
          summary={PANELS.ASSETS}
          {...otherProps}
        />
        <AccordionHistory
          expanded={expanded === PANELS.HISTORY}
          onChange={handleChange(PANELS.HISTORY)}
          summary={PANELS.HISTORY}
          {...otherProps}
        />
      </CardContent>
      <If condition={[ORDER_STATUSES.PD, ORDER_STATUSES.AP].includes(status)}>
        <CardActions classes={{ root: classes.cardActions }}>
          <OrderActions orderStatus={status} orderId={id} {...otherProps} />
        </CardActions>
      </If>
    </Card>
  );
};

export default withStyles({
  subheader: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  cardContent: {
    paddingTop: '0',
  },
  cardActions: {
    justifyContent: 'flex-end',
    padding: '0 12px 12px',
  },
})(OrderItem);
