import React from 'react';
import PropTypes from 'prop-types';

import './PasswordNotification.scss';

const PasswordNotification = ({ title, text }) => {
  return (
    <div className="PasswordNotification">
      <div className="password-notification">
        <h2 className="password-title">{title}</h2>
        <p className="password-message">{text}</p>
      </div>
    </div>
  );
};

PasswordNotification.defaultProps = {
  title: 'Your email has been sent!',
  text: 'Please, check out your post',
};

PasswordNotification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default PasswordNotification;
