const config = {
  tableHeaders: [
    {
      label: 'Industry',
      key: 'industry',
    },
    {
      label: 'Total Desks',
      key: 'totalDesks',
    },
    {
      label: 'Max Utilisation',
      key: 'maxUtilisation',
    },
    {
      label: 'Avg Utilisation',
      key: 'avgUtilisation',
    },
    {
      label: 'Max Occupancy',
      key: 'maxOccupancy',
    },
    {
      label: 'Avg Occupancy',
      key: 'avgOccupancy',
    },
  ],
};

export default config;
