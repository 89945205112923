/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';
import { toBarChartData } from 'modules/workLook/utils';

// eslint-disable-next-line import/no-cycle
import BarChart from './BarChart';

const BarChartContainer = ({
  id,
  type_of_group,
  activeFilters,
  type,
  isModal,
  graphData,
  sub_title,
  isPdf,
  title,
  isPublished,
  ...props
}) => {
  // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
  const { isLoading, data } = useGraphData(id, activeFilters, graphData, false, false, type, type_of_group, title);

  return (
    <BarChart
      isPublished={isPublished}
      title={title}
      isPdf={isPdf}
      graphId={id}
      isLoading={isLoading}
      data={toBarChartData(data, type_of_group, isModal)}
      groupType={type_of_group}
      isHorizontalBar={type === '3'}
      modalData={!isModal && { ...props, id, type_of_group, activeFilters, type, graphData: data }}
      isModal={isModal}
      {...props}
      subtitle={sub_title}
      type={type}
    />
  );
};

export default BarChartContainer;
