const options = {
  maintainAspectRatio: false,
  usePointStyle: true,
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
    },
  },
};

export default options;
