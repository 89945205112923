import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { URLS } from 'pages/constants';
import { API } from 'modules/shared/utils';
import { getUser } from 'modules/auth/selectors';
import { ENDPOINTS } from 'modules/shared/constants';
import { LOG_OUT, SET_AS_ANOTHER_USER, SET_TWO_FACTOR_REQUIRED } from 'modules/auth/actions';
import useUserSettingsModal from 'components/Modals/UserSettingsModal/useUserModifierSettingsModal';
import useCompanySettingsModal from 'components/Modals/CompanySettingsModal/useCompanySettingsModal';

// eslint-disable-next-line import/no-cycle
import { SnackbarError } from 'modules/shared/components';
import { isEmpty } from 'lodash';

import Menu from './Menu';

const MenuContainer = (props) => {
  const activeCompany = localStorage.getItem('lastCompany');
  const dispatch = useDispatch();
  const history = useHistory();
  const anotherUser = window.localStorage.getItem('anotherUser');
  const [showError, setShowError] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOtpRequired, user } = useSelector(getUser);
  const getToken = window.localStorage.getItem('userToken');
  const isAdmin = user.role === 'admin';

  const clearData = () => {
    window.localStorage.removeItem('userToken');
    history.push(URLS.auth.login);
    dispatch(LOG_OUT());
  };

  const clearAnotherUser = () => {
    dispatch(SET_AS_ANOTHER_USER(false));
    window.localStorage.removeItem('asUserToken');
    window.localStorage.removeItem('anotherUser');
  };

  const _on2FAClick = () => {
    if (isOtpRequired) {
      setIsDeleting(true);
      API.delete(ENDPOINTS.auth.otpDisable)
        .then(({ data }) => {
          setIsDeleting(false);
          localStorage.setItem('userToken', data.key);
          dispatch(SET_TWO_FACTOR_REQUIRED(false));
        })
        .catch(() => {
          setIsDeleting(false);
          setShowError(true);
        });
    } else {
      history.push(URLS.auth.twoFASetup);
    }
  };

  const _onSignOutClick = () => {
    if (!isEmpty(getToken) && anotherUser) {
      clearAnotherUser();
      window.location.reload(false);
    }
    if (isEmpty(getToken) && anotherUser) {
      clearAnotherUser();
      clearData();
    }
    if (!isEmpty(getToken) && !anotherUser) {
      clearData();
    }
    // if (isEmpty(getToken) && !anotherUser) {
  };

  const { onUserSettingsModalClick } = useUserSettingsModal(false, activeCompany);
  const { onCompanySettingsModalClick } = useCompanySettingsModal(false, activeCompany);

  return (
    <>
      <Menu
        isOtpRequired={isOtpRequired}
        isDeleting={isDeleting}
        showError={showError}
        setShowError={setShowError}
        _on2FAClick={_on2FAClick}
        anotherUser={anotherUser}
        onUserSettings={onUserSettingsModalClick}
        onCompanySettings={onCompanySettingsModalClick}
        token={getToken}
        _onSignOutClick={_onSignOutClick}
        isAdmin={isAdmin}
        {...props}
      />
      {/* TODO: Replace with the "notistack" */}
      <SnackbarError open={showError} _handleClose={() => setShowError(false)} />
    </>
  );
};

export default MenuContainer;
