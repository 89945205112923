import React from 'react';

import { NavBar } from 'modules/shared/components';
import { Sidebar } from 'modules/liveLook/components';

import theme from './LiveLookLayout.module.scss';

const LiveLookLayout = ({ children }) => (
  <div className={theme.root}>
    <div className={theme.header}>
      <NavBar />
    </div>

    <div className={theme.sidebar}>
      <Sidebar />
    </div>

    <div className={theme.content}>{children}</div>
  </div>
);

export default LiveLookLayout;
