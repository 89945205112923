/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { setHeaders } from 'modules/workLook/components/EditableTable/config';
import { Box, Checkbox } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '95%',
    height: '70%',
    minHeight: '200px',
    marginTop: '15px',
    overflowX: 'auto',
    overflowY: 'auto',
  },
  table: {
    minWidth: 300,
    minHeight: 200,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
}));

const CustomTableCell = ({ row, name }) => {
  const classes = useStyles();
  const { isEditMode } = row;

  return (
    <TableCell align="left" className={classes.tableCell}>
      <If condition={!isEditMode && name === 'is_filter'}>
        <Checkbox disabled checked={row[name]} color="primary" style={{ padding: '0' }} />
      </If>
      <If condition={!isEditMode}>{row[name]}</If>
    </TableCell>
  );
};

const TableMatch = ({
  headerName,
  rows,
  selectors,
  handleChange,
  tableKeyValue,
  label,
  value,
  displaySelector = true,
  withTableKey = false,
  selectBottom = false,
}) => {
  const classes = useStyles();
  const headers = setHeaders(rows);
  const tableAlias = rows[0]?.table_alias;

  const filteredHeaders = headers.filter((value) => {
    return value !== 'id' && value !== 'report' && value !== 'table_alias';
  });

  return (
    <>
      <If condition={displaySelector && !selectBottom}>
        <Box sx={{ mt: 2, width: '50%' }}>
          <FormControl fullWidth>
            <InputLabel id="selectors">{label} Table Related Field</InputLabel>
            <Select
              labelId="selectors"
              id="selectors"
              value={value}
              label={`${label} Table Related Filed`}
              onChange={(event) => handleChange(event, tableAlias)}
            >
              {selectors.map(({ name, id }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </If>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow>
              {filteredHeaders.map((attribute) => (
                <TableCell key={attribute} align="left">
                  {headerName[attribute] || attribute}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                {filteredHeaders.map((header, index) => (
                  <CustomTableCell key={index} {...{ row, name: `${header}` }} />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <If condition={displaySelector}>
        <If condition={withTableKey || selectBottom}>
          <Box sx={{ mt: 4, width: '50%' }}>
            <FormControl fullWidth>
              <InputLabel id="selectors1">{label} Table Field</InputLabel>
              <Select
                labelId="selectors1"
                id="selectors1"
                value={tableKeyValue}
                label={`${label} Table Field`}
                onChange={(event) => handleChange(event, tableAlias, withTableKey)}
              >
                {selectors.map(({ name, id }) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </If>
      </If>
    </>
  );
};

export default TableMatch;
