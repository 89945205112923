import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/spaceLook/components';
import { FilterList, LiveCounter, ReportDataCards, VerticalBarGraph } from 'modules/liveLook/components';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import { TextEditor } from 'modules/shared/components';
import IFrameModal from 'modules/liveLook/components/iFrame';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import graphDeskConfig from './graphDeskConfig';

const SummaryKeyFindingsOccupancy = ({
  databoxes,
  databoxesSet,
  editableTextReport,
  saveGraphsText,
  data,
  scaleValues,
  onSwitcherChange,
  deskAndBreakGraphData,
  deskAndBreakScaleValues,
  deskAndBreakOnSwitcherChange,
  returnPercentageSwitcher,
  returnPercentageSwitcherDeskAndBreak,
  textVariables,
  archive,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Executive Summary - Key Findings - Occupancy" />
          </Grid>
          <Grid item xs={12}>
            <FilterList doorSensorData pageType="OCCUPANCY" subtypes={false} />
          </Grid>
          <Grid item xs={12}>
            <ReportDataCards databoxes={databoxes} pageType={pageTypes.occupancy} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Grid
          container
          alignItems="flex-end"
          direction="column"
          // spacing={1}
          style={{ justifyContent: 'flex-end' }}
        >
          <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>
        <Grid>
          <AddToPDFCard
            type="Key Findings - Occupancy"
            sectionType="Executive Summary"
            category={pdfCases.keyFindingsOccupancy}
            sectionOrder={sectionsOrder.first}
            typeOrder={sectionItemOrder.second}
            chartName={liveLookPagesNames.summaryKeyFindingsOccupancy}
            activeFilters={filtersDataContainer('OCCUPANCY', false).initActiveFilters}
            data={{
              databoxes,
              databoxesSet,
              editableTextReport,
              data,
              scaleValues,
              deskAndBreakGraphData,
              deskAndBreakScaleValues,
              returnPercentageSwitcher,
              returnPercentageSwitcherDeskAndBreak,
              textVariables,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <TextEditor
          chartName={liveLookPagesNames.summaryKeyFindingsOccupancy}
          data={databoxesToEditorData(databoxesSet, textVariables)}
          // eslint-disable-next-line no-shadow
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
          graphType={GRAPH_TYPES.KEY_FINDINGS}
        />
      </Grid>

      <Grid item xs={6}>
        <VerticalBarGraph
          customHeader
          onSwitcherChange={onSwitcherChange}
          title="Daily Comparisons"
          data={data}
          scaleValues={scaleValues}
          returnPercentageSwitcher={returnPercentageSwitcher}
          config={graphDeskConfig}
        />

        <VerticalBarGraph
          title="Desk and breakout seat usage by team zones"
          data={deskAndBreakGraphData}
          returnPercentageSwitcher={returnPercentageSwitcherDeskAndBreak}
          scaleValues={deskAndBreakScaleValues}
          onSwitcherChange={deskAndBreakOnSwitcherChange}
          config={graphDeskConfig}
          customHeader
        />
      </Grid>
    </Grid>
  );
};

export default SummaryKeyFindingsOccupancy;
