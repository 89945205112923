export const SET_AVAILABLE_FILTERS = {
  pending: 'SET_AVAILABLE_FILTERS_PENDING',
  success: 'SET_AVAILABLE_FILTERS_SUCCESS',
  failure: 'SET_AVAILABLE_FILTERS_FAILURE',
};

export const setAvailableFilters = (filtersData) => {
  // eslint-disable-next-line no-param-reassign
  delete filtersData.category1;

  return {
    type: SET_AVAILABLE_FILTERS.success,
    payload: filtersData,
  };
};
