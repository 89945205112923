import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

export const FETCH_BREAKOUT_SPACE_COMPARISON = createAsyncAction(createAction('FETCH_BREAKOUT_SPACE_COMPARISON'));

const fetchBreakoutSpaceComparison = () =>
  // dispatch,
  // isCanceled,
  // { companyId, dateAfter, dateBefore, workingHours, query },
  {
    // dispatch(FETCH_BREAKOUT_SPACE_COMPARISON.pending());
    //
    // const url = ENDPOINTS.liveLook.doorsOverTime
    //   .replace(':companyId', companyId)
    //   .concat(`?date_after=${dateAfter}`)
    //   .concat(`&date_before=${dateBefore}`)
    //   .concat(workingHours ? `&work_hours=True` : '')
    //   .concat(query ? `&${query}` : '');
    //
    // API.get(url)
    //   .then(({data}) => {
    //     if (isCanceled) return;
    //     dispatch(FETCH_BREAKOUT_SPACE_COMPARISON.success(data));
    //   })
    //   .catch(() => {
    //     if (isCanceled) return;
    //     dispatch(FETCH_BREAKOUT_SPACE_COMPARISON.failure());
    //   })
  };

export default fetchBreakoutSpaceComparison;
