import { byBuildingAndFloorTypes } from 'modules/spaceLook/constants';

const DesksPropertyOrFloorLowestOcc = ({ buildingAndFloorData: { data, type } }) => {
  const lowest = data.reduce(
    (min, current) => (min.percent_max_occupancy > current.percent_max_occupancy ? current : min),
    data[0],
  );

  const buildingOrFloor = type === byBuildingAndFloorTypes.building ? 'building' : 'floor';

  return `The lowest peak occupancy was ${buildingOrFloor} ${lowest.name} with a peak of ${lowest.percent_max_occupancy}%
     and an average occupancy of ${lowest.percent_avg_occupancy}%.`;
};

export default DesksPropertyOrFloorLowestOcc;
