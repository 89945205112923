import { createReducer } from '@reduxjs/toolkit';

import { FILTERS, filtersPageTypes, weekday } from 'modules/liveLook/constants';
import { makeDefaultActiveFilters } from 'modules/shared/utils';
import { APPLY_FILTERS, FETCH_COMMON_DATA, RESET_FILTERS, RESET_STATE } from 'modules/liveLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  initCombinedFilters: null,
  activeCombinedFilters: null,
};

export default createReducer(initState, {
  [FETCH_COMMON_DATA.success]: (state, { payload }) => {
    if (!payload) {
      // eslint-disable-next-line no-console
      console.error('FETCH_COMMON_DATA_TMP::No payload', payload);
      return;
    }

    const { buildings, filters } = payload;
    const deskFilt = filters[filtersPageTypes.desks];
    const breakFilt = filters[filtersPageTypes.breakout];
    const combinedFilters = {
      floor: Array.from(new Set(deskFilt.floor.concat(breakFilt.floor))),
      subtype: Array.from(new Set(deskFilt.subtype.concat(breakFilt.subtype))),
      division: Array.from(new Set(deskFilt.division.concat(breakFilt.division))),
      neighbourhood: Array.from(new Set(deskFilt.neighbourhood.concat(breakFilt.neighbourhood))),
    };

    const initCombinedFilters = [weekday];

    initCombinedFilters.push({
      field: FILTERS.floor,
      values: combinedFilters.floor,
    });

    initCombinedFilters.push({
      field: FILTERS.subtype,
      values: combinedFilters.subtype,
    });

    initCombinedFilters.push({
      field: FILTERS.division,
      values: combinedFilters.division,
    });

    initCombinedFilters.push({
      field: FILTERS.neighbourhood,
      values: combinedFilters.neighbourhood,
    });

    initCombinedFilters.push({
      field: FILTERS.building_id,
      // eslint-disable-next-line camelcase
      values: buildings.map(({ building__property_name, building_id }) => ({
        label: building__property_name,
        value: building_id,
      })),
    });

    // eslint-disable-next-line consistent-return
    return {
      ...state,
      initCombinedFilters,
      activeCombinedFilters: makeDefaultActiveFilters(initCombinedFilters),
    };
  },
  [FETCH_COMMON_DATA.failure]: (state) => {
    return state;
  },
  [APPLY_FILTERS]: (state, { payload: { field, values } }) => ({
    ...state,
    activeCombinedFilters: state.activeCombinedFilters.map((filter) =>
      filter.field === field ? { field, values } : { ...filter },
    ),
  }),
  [RESET_FILTERS]: (state) => ({
    ...state,
    activeCombinedFilters: makeDefaultActiveFilters(state.initCombinedFilters),
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
