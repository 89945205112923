/* eslint-disable camelcase */
import { findPeakAndLowestDayByOccupancy, roundToFloat } from 'utils';

const DesksPeakDaysPlus = ({
  dailyUsagePattern,
  databoxes: {
    textblock_data: { max_peaktime_avg_occup_percent },
  },
}) => {
  const { lowestOcc, highestOcc } = findPeakAndLowestDayByOccupancy(dailyUsagePattern);
  const highestLowestDiff = roundToFloat(highestOcc.avgOccupancy - lowestOcc.avgOccupancy);

  return `
    However, throughout the core business hours of the week, occupation levels of the desks were generally observed 
    at ${max_peaktime_avg_occup_percent}%. The busiest day (by average occupancy)
    was ${highestOcc.day}. ${lowestOcc.day} was the least busy day, when average occupation was ${highestLowestDiff}% lower.
  `;
};

export default DesksPeakDaysPlus;
