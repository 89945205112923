import { WEEKDAYS } from 'modules/shared/constants';
import { isEmpty } from 'lodash';

const toDailyGraphData = (data, returnPercentageSwitcher) => {
  if (isEmpty(data)) return false;
  const initialData = {
    labels: [],
    avg: [],
    max: [],
  };
  const results = data
    .sort((a, b) => a.weekday - b.weekday)
    .reduce((acc, item) => {
      const getItem = (isAvg) => {
        const fieldName = `${returnPercentageSwitcher ? '' : 'percentage_'}${isAvg ? 'avg' : 'max'}`;
        return parseFloat(item[fieldName].toFixed(1));
      };
      return {
        labels: acc.labels.concat(WEEKDAYS[item.weekday]),
        avg: acc.avg.concat(getItem(true)),
        max: acc.max.concat(getItem(false)),
      };
    }, initialData);
  return {
    labels: results.labels,
    datasets: [
      {
        label: 'Avg Occupancy',
        backgroundColor: '#34BBDB',
        data: results.avg,
      },
      {
        label: 'Max Occupancy',
        backgroundColor: '#0D6F85',
        data: results.max,
      },
    ],
  };
};
export default toDailyGraphData;
