import AgilityFixed from './AgilityFixed';
import AgilityFlexible from './AgilityFlexible';
import AgilityMobile from './AgilityMobile';
import AgilityNomadic from './AgilityNomadic';
import DesksOccupancy from './DesksOccupancy';
import DesksCoreHoursOccupation from './DesksCoreHoursOccupation';
import DesksUtilization from './DesksUtilization';
import DesksPeakDays from './DesksPeakDays';
import DesksPeakDaysPlus from './DesksPeakDaysPlus';
import DesksSignsOfLife from './DesksSignsOfLife';
import DesksBusiestDay from './DesksBusiestDay';
import DesksLeastBusy from './DesksLeastBusy';
import DesksHighestAndLowest from './DesksHighestAndLowest';
import MostCommonActivities from './MostCommonActivities';
import DesksOccupancyPlusSigns from './DesksOccupancyPlusSigns';
import DesksOccupancyPlusAgile from './DesksOccupancyPlusAgile';
import DesksTwentyPercent from './DesksTwentyPercent';
import DesksPeakOccupancy from './DesksPeakOccupancy';
import DesksPeakUtilization from './DesksPeakUtilization';
import DesksLeastBusyOccupancy from './DesksLeastBusyOccupancy';
import DesksBusiestPeakTimeAvg from './DesksBusiestPeakTimeAvg';
import DesksPropertyOrFloorHighestOcc from './DesksPropertyOrFloorHighestOcc';
import DesksPropertyOrFloorHighestUtil from './DesksPropertyOrFloorHighestUtil';
import DesksPropertyOrFloorLowestOcc from './DesksPropertyOrFloorLowestOcc';
import DesksDivHighestOcc from './DesksDivHighestOcc';
import DesksDivHighestUtil from './DesksDivHighestUtil';
import DesksDivLowestOcc from './DesksDivLowestOcc';
import DesksUsageBandingZero from './DesksUsageBandingZero';
import DesksUsageBandingLess20 from './DesksUsageBandingLess20';
import DesksUsageBandingLess50 from './DesksUsageBandingLess50';
import DesksUsageBandingMore80 from './DesksUsageBandingMore80';
import MeetsAndBreakOccupancy from './MeetsAndBreakOccupancy';
import MeetsAndBreakOccupancySeats from './MeetsAndBreakOccupancySeats';
import MeetsAndBreakPeakUsage from './MeetsAndBreakPeakUsage';
import MeetsAndBreakPeakUsagePlus from './MeetsAndBreakPeakUsagePlus';
import MeetsAndBreakAvgOccupancy from './MeetsAndBreakAvgOccupancy';
import MeetsAndBreakSoloUse from './MeetsAndBreakSoloUse';
import MeetsAndBreakOneToFourUse from './MeetsAndBreakOneToFourUse';
import MeetsAndBreakOneToFourWithSolo from './MeetsAndBreakOneToFourWithSolo';
import SingleSpaceUsage from './SingleSpaceUsage';
import MeetsAndBreakLargeUse from './MeetsAndBreakLargeUse';
import CombinedAvgAndPeak from './CombinedAvgAndPeak';
import CombinedCoreHours from './CombinedCoreHours';
import CombinedBuildingOccupancy from './CombinedBuildingOccupancy';
import BuildingAndFloorHighestUsage from './BuildingAndFloorHighestUsage';
import BuildingAndFloorLowestUsage from './BuildingAndFloorLowestUsage';
import BuildingAndFloorEfficiency from './BuildingAndFloorEfficiency';
import BenchOccPreAgile from './BenchOccPreAgile';
import BenchOccPostAgile from './BenchOccPostAgile';
import BenchUtilization from './BenchUtilization';

const config = {
  agilityFixed: [AgilityFixed],
  agilityFlexible: [AgilityFlexible],
  agilityMobile: [AgilityMobile],
  agilityNomadic: [AgilityNomadic],

  desksOccupancy: [DesksOccupancy],
  desksOccupancyPlusSigns: [DesksOccupancyPlusSigns],
  desksOccupancyPlusAgile: [DesksOccupancyPlusAgile],
  desksCoreHoursOccupation: [DesksCoreHoursOccupation],
  desksUtilization: [DesksUtilization],
  desksPeakDays: [DesksPeakDays],
  desksPeakDaysPlus: [DesksPeakDays, DesksPeakDaysPlus],
  desksSignsOfLife: [DesksSignsOfLife],
  desksTwentyPercent: [DesksTwentyPercent],
  desksBusiestDay: [DesksBusiestDay],
  desksLeastBusy: [DesksLeastBusy],
  desksHighestAndLowest: [DesksHighestAndLowest],
  desksPeakOccupancy: [DesksPeakOccupancy],
  desksPeakUtilization: [DesksPeakUtilization],
  desksLeastBusyOccupancy: [DesksLeastBusyOccupancy],
  desksBusiestPeakTimeAvg: [DesksBusiestPeakTimeAvg],
  desksPropertyOrFloorHighestOcc: [DesksPropertyOrFloorHighestOcc],
  desksPropertyOrFloorHighestUtil: [DesksPropertyOrFloorHighestUtil],
  desksPropertyOrFloorLowestOcc: [DesksPropertyOrFloorLowestOcc],
  desksDivHighestOcc: [DesksDivHighestOcc],
  desksDivHighestUtil: [DesksDivHighestUtil],
  desksDivLowestOcc: [DesksDivLowestOcc],
  desksUsageBandingZero: [DesksUsageBandingZero],
  desksUsageBandingLess20: [DesksUsageBandingLess20],
  desksUsageBandingLess50: [DesksUsageBandingLess50],
  desksUsageBandingMore80: [DesksUsageBandingMore80],

  meetsAndBreakOccupancy: [MeetsAndBreakOccupancy],
  meetsAndBreakOccupancySeats: [MeetsAndBreakOccupancySeats],
  meetsAndBreakPeakUsage: [MeetsAndBreakPeakUsage],
  meetsAndBreakPeakUsagePlus: [MeetsAndBreakPeakUsagePlus],
  meetsAndBreakAvgOccupancy: [MeetsAndBreakAvgOccupancy],
  meetsAndBreakSoloUse: [MeetsAndBreakSoloUse],
  meetsAndBreakOneToFourUse: [MeetsAndBreakOneToFourUse],
  meetsAndBreakOneToFourWithSolo: [MeetsAndBreakOneToFourWithSolo],
  meetsAndBreakLargeUse: [MeetsAndBreakLargeUse],

  buildingAndFloorEfficiency: [BuildingAndFloorEfficiency],
  buildingAndFloorLowestUsage: [BuildingAndFloorLowestUsage],
  buildingAndFloorHighestUsage: [BuildingAndFloorHighestUsage],

  combinedAvgAndPeak: [CombinedAvgAndPeak],
  combinedCoreHours: [CombinedCoreHours],
  combinedBuildingOccupancy: [CombinedBuildingOccupancy],

  benchOccPreAgile: [BenchOccPreAgile],
  benchOccPostAgile: [BenchOccPostAgile],
  benchUtilization: [BenchUtilization],

  singleSpaceUsage: [SingleSpaceUsage],
  mostCommonActivities: [MostCommonActivities],
};

export default config;
