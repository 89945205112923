const transformDataDivisionPercent = (data, key = 'avg_util_percent') => {
  return data.map((division) => {
    const deskCount = division[key];
    if (deskCount === null) {
      return {
        property_name: division.division,
        divisions: [],
      };
    }
    return {
      property_name: division.division,
      divisions: [
        {
          division: 'Utilization',
          desk_count: deskCount,
        },
        {
          division: 'Free Space',
          desk_count: 100 - deskCount,
        },
      ],
    };
  });
};

export default transformDataDivisionPercent;
