import { getBuildingOrFloorData } from 'modules/spaceLook/utils';
import { reportPageTypes } from 'modules/spaceLook/constants';

const BuildingAndFloorLowestUsage = ({ graphData, pageType }) => {
  const { lowestValue, buildingOrFloor, nameKey } = getBuildingOrFloorData(graphData);
  const roomOrSpace = pageType === reportPageTypes.meetings ? 'room' : 'breakout space';

  return `The lowest peak ${roomOrSpace} usage was ${buildingOrFloor} ${lowestValue[nameKey]}
     with a peak of ${lowestValue.percent_max}% 
     and an average occupancy of ${lowestValue.percent_avg}%.`;
};

export default BuildingAndFloorLowestUsage;
