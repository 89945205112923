const toOccUtilGraphData = (graphData, labelKey) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Avg Occupancy',
        backgroundColor: '#34BBDB',
        data: [],
      },
      {
        label: 'Max Occupancy',
        backgroundColor: '#0D6F85',
        data: [],
      },
      {
        label: 'Avg Utilization',
        backgroundColor: '#F6A000',
        data: [],
      },
      {
        label: 'Max Utilization',
        backgroundColor: '#B4CC04',
        data: [],
      },
    ],
  };

  graphData.forEach((item) => {
    const label = item[labelKey].length > 19 ? `${item[labelKey].slice(0, 16)}...` : item[labelKey];

    data.labels.push(label);
    data.datasets[0].data.push(item.percent_avg_occupancy);
    data.datasets[1].data.push(item.percent_max_occupancy);
    data.datasets[2].data.push(item.percent_avg_utilization);
    data.datasets[3].data.push(item.percent_max_utilization);
  });

  return data;
};

export default toOccUtilGraphData;
