const GRAPH_TYPES = {
  dataCards: '1',
  donutChart: '2',
  barChart: '3',
  stackedSingleChart: '4',
  stackedMultipleChart: '5',
  radarChart: '6',
  table: '7',
  text: '8',
  columnChart: '9',
  gaugeChart: '10',
};

export default GRAPH_TYPES;
