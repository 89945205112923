import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, cloneDeep } from 'lodash';

import { reportPageTypes } from 'modules/spaceLook/constants';
import {
  fetchInitFilters,
  fetchCombinedAvailableFilters,
  fetchDesksAvailableFilters,
  fetchMeetingsAvailableFilters,
  fetchBreakoutAvailableFilters,
} from 'modules/spaceLook/actions';

import {
  getMeetAndBreakInitFilters,
  getCombinedInitFilters,
  getSelectedFilters,
  getAppliedFilters,
  getAvailableFilters,
} from 'modules/spaceLook/selectors';

import ReportFilter from './ReportFilter';

const copySortInitFilters = (initFilters) => {
  if (!initFilters) return initFilters;
  const copy = cloneDeep(initFilters);
  copy.day_of_week.sort((a, b) => a - b);
  return copy;
};

const ReportFilterContainer = ({ pageType }) => {
  const isFirstRun = useRef(true);

  const { id } = useParams();
  const dispatch = useDispatch();

  let initFiltersSelector;
  let dataKey;
  let fetchAvailableFilters;
  // eslint-disable-next-line default-case
  switch (pageType) {
    case reportPageTypes.combined:
      initFiltersSelector = getCombinedInitFilters;
      dataKey = 'combined';
      fetchAvailableFilters = fetchCombinedAvailableFilters;
      break;
    case reportPageTypes.desks:
      initFiltersSelector = getCombinedInitFilters;
      dataKey = 'desks';
      fetchAvailableFilters = fetchDesksAvailableFilters;
      break;
    case reportPageTypes.meetings:
      initFiltersSelector = getMeetAndBreakInitFilters;
      dataKey = 'meetings';
      fetchAvailableFilters = fetchMeetingsAvailableFilters;
      break;
    case reportPageTypes.breakout:
      initFiltersSelector = getMeetAndBreakInitFilters;
      dataKey = 'breakout';
      fetchAvailableFilters = fetchBreakoutAvailableFilters;
      break;
  }

  const { initFilters, requestStatus } = useSelector(initFiltersSelector);
  const { availableFilters } = useSelector((store) => getAvailableFilters(store, dataKey));
  const { selectedFilters, selectedFiltersUrlParams } = useSelector((store) => getSelectedFilters(store, dataKey));
  const { appliedFilters } = useSelector((store) => getAppliedFilters(store, dataKey));

  useEffect(() => {
    if (!initFilters) {
      fetchInitFilters(dispatch, id);
    } else {
      fetchAvailableFilters(dispatch, id, selectedFiltersUrlParams);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    fetchAvailableFilters(dispatch, id, selectedFiltersUrlParams);
    // eslint-disable-next-line
  }, [selectedFiltersUrlParams]);

  return (
    <ReportFilter
      requestStatus={requestStatus}
      initFilters={copySortInitFilters(initFilters)}
      availableFilters={availableFilters}
      selectedFilters={selectedFilters}
      isApplyDisabled={isEqual(appliedFilters, selectedFilters)}
      isResetDisabled={!selectedFiltersUrlParams}
    />
  );
};

export default ReportFilterContainer;
