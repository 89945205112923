const useGetWords = (words, isLoading) => {
  if (isLoading || !words?.length) return { listOfWords: [] };
  const maxValue = Math.max(...words.map((object) => object.value));

  const listOfWords = words.map(({ field: text, value }) => ({
    text,
    value: (value / maxValue) * 100,
  }));
  return { listOfWords };
};

export default useGetWords;
