/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import theme from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      pathname: this.props.location.pathname,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.pathname !== nextProps.location.pathname) {
      return {
        hasError: false,
        pathname: nextProps.location.pathname,
      };
    }
    return prevState;
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={theme.errorContainer}>
          <ErrorOutlineIcon fontSize="inherit" className={theme.errorIcon} />
          <Typography variant="h5" className={theme.errorText}>
            Something went wrong, please try to reload the page or contact a manager.
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
