import React from 'react';
import PropTypes from 'prop-types';

import './ResetPasswordMailNotification.scss';

const ResetPasswordMailNotification = ({ infoText, ctaText }) => {
  return (
    <div className="ResetPasswordMailNotification">
      <div className="reset-password-mail-notification">
        <p className="info-text">{infoText}</p>
        <p className="cta-text">{ctaText}</p>
      </div>
    </div>
  );
};

ResetPasswordMailNotification.defaultProps = {
  infoText: 'Your email has been sent!',
  ctaText: 'Please, check out your post',
};

ResetPasswordMailNotification.propTypes = {
  infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ctaText: PropTypes.string,
};

export default ResetPasswordMailNotification;
