import { useSelector } from 'react-redux';
import { get } from 'lodash';

const useLiveLookSelector = (paths) => {
  return useSelector(({ liveLook }) => {
    const res = [];

    paths.forEach((path) => res.push(get(liveLook, path)));

    return res;
  });
};

export default useLiveLookSelector;
