import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const FilterListSkeleton = ({ length = 5 }) => (
  <Grid container spacing={2}>
    {new Array(length).fill(null).map((_, i) => (
      <Grid item key={i}>
        <Skeleton variant="rect" width={96} height={32} style={{ borderRadius: '16px' }} />
      </Grid>
    ))}
  </Grid>
);

export default FilterListSkeleton;
