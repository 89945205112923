import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader, TextEditor } from 'modules/shared/components';
import { FilterList, ReportDataCards, StackedBarGraph, LiveCounter } from 'modules/liveLook/components';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import IFrameModal from 'modules/liveLook/components/iFrame';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import config from './config';

const BreakoutSpaceUsageBySize = ({ data, databoxes, editableTextReport, saveGraphsText, databoxesSet, archive }) => (
  <Grid container spacing={4}>
    <Grid item xs={8}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title="Breakout" />
        </Grid>
        <Grid item xs={12}>
          <FilterList subtypes pageType="BREAKOUT" />
        </Grid>
        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={pageTypes.meetings} />
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={4}>
      <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
        <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
        <Grid item>
          <IFrameModal />
        </Grid>
      </Grid>
      <Grid>
        <AddToPDFCard
          type="Space Usage By Size"
          sectionType="Breakout"
          category={pdfCases.breakoutSpaceUsage}
          sectionOrder={sectionsOrder.fifth}
          typeOrder={sectionItemOrder.fourth}
          chartName={liveLookPagesNames.breakoutSpaceUsageBySize}
          activeFilters={filtersDataContainer('BREAKOUT', false).initActiveFilters}
          data={{
            data,
            databoxes,
            editableTextReport,
          }}
        />
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <StackedBarGraph title="Group Sizes by Space Type" data={data} config={config} databoxes={databoxes} />
    </Grid>

    <Grid item xs={12}>
      <TextEditor
        chartName={liveLookPagesNames.breakoutSpaceUsageBySize}
        data={databoxesToEditorData(databoxesSet)}
        // eslint-disable-next-line no-shadow
        onSave={async (data) => {
          saveGraphsText(JSON.stringify(data));
        }}
        text={editableTextReport}
        graphType={GRAPH_TYPES.STACKED}
      />
    </Grid>
  </Grid>
);

export default BreakoutSpaceUsageBySize;
