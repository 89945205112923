import React from 'react';
import { TableCell } from '@material-ui/core';

function ProductSummaryCellBody({ data, rowIndex, columnKey, height, width }) {
  const label = data[rowIndex][columnKey];

  return (
    <TableCell
      size="small"
      component="div"
      variant="body"
      style={{
        height,
        width,
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
      }}
    >
      <span title={label}>{label}</span>
    </TableCell>
  );
}

export default ProductSummaryCellBody;
