/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
// import { useParams } from 'react-router-dom';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { TextPlaceholder } from 'modules/shared/components';
import { API } from 'modules/shared/utils';
import './ImageGraph.scss';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import theme from 'assets/theme';
import { ENDPOINTS } from 'modules/shared/constants';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// eslint-disable-next-line no-shadow
const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
  },
  dropzonesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneName: {
    justifySelf: 'center',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    marginTop: '5px',
    alignItems: 'center',
  },
  inputfile: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    Zindex: '-1',
  },
  label: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

const ImageGraph = ({
  data,
  typeOfGroup,
  subtitle,
  title,
  isLoading,
  graphId,
  type,
  isPdf,
  isPublished,
  showTitle,
  isModal,
}) => {
  const classes = useStyles();
  const checkOnEmptyImage = isEmpty(data);
  const [inputPhoto, setInputPhoto] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  const handleChange = (event) => {
    setInputPhoto(event.target.files[0]);
  };

  const onSubmit = () => {
    const url = ENDPOINTS.workLook.uploadImage.replace(':graphId', graphId);
    const dataImage = new FormData();
    dataImage.append('image', inputPhoto);
    setIsUploading(true);
    return API.post(url, dataImage, config).then(() => {
      window.location.reload(false);
      setIsUploading(true);
    });
  };

  const onDeleteImage = () => {
    const url = ENDPOINTS.workLook.uploadImage.replace(':graphId', graphId);
    return API.delete(url).then(() => {
      window.location.reload(false);
      setIsUploading(true);
    });
  };

  return (
    <Choose>
      <When condition={isLoading || isUploading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={isPdf && isEmpty(data)}>
        <Paper className="emptyContainer" elevation={2}>
          <GraphHeader
            display={false}
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={subtitle}
            isPdf={isPdf}
            isPublished={isPublished}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper className="container" elevation={2}>
          <Grid item>
            <GraphHeader
              showTitle={showTitle}
              display={false}
              title={title}
              subTitle={subtitle}
              graphId={graphId}
              isPublished={isPublished}
              isPdf={isPdf}
              onClick={onEditClick}
              onModifierClick={onModifierClick}
            />
          </Grid>
          <If condition={checkOnEmptyImage}>
            <div className={classes.form}>
              <form className={classes.dropzonesContainer} encType="multipart/form-data">
                <div className={classes.dropzone}>
                  <input
                    className={classes.inputfile}
                    id="photo"
                    type="file"
                    name="photo"
                    accept="image/*"
                    onChange={handleChange}
                  />
                  <label className={classes.label} htmlFor="photo">
                    Choose an Image
                  </label>
                  <h1 className={classes.dropzoneName}>{inputPhoto?.name}</h1>
                </div>
                <Button
                  disabled={isEmpty(inputPhoto?.name)}
                  style={{ marginTop: '50px' }}
                  type="submit"
                  color="primary"
                  component="span"
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </form>
            </div>
          </If>
          <If condition={!checkOnEmptyImage}>
            <div
              style={{
                backgroundImage: `url(${data})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: '5px',
                width: '100%',
                height: '90%',
              }}
            />
            <If condition={!isPdf}>
              <Button
                variant="contained"
                style={{ marginTop: '-40px', display: 'flex', marginLeft: 'auto' }}
                onClick={onDeleteImage}
              >
                Delete
              </Button>
            </If>
          </If>
        </Paper>
      </Otherwise>
    </Choose>
  );
};

export default ImageGraph;
