import React from 'react';

import filterIcon from 'assets/images/icons/black-filter.svg';

const FiltersHeader = () => (
  <div className="header">
    <img src={filterIcon} className="icon" alt="icon" />
    <h3 className="title">Filter by region, building name, directorate</h3>
  </div>
);

export default FiltersHeader;
