import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveCompany } from 'modules/shared/selectors';
import { fetchInitFilters, fetchTargetFilters, RESET_ACTIVE_FILTERS } from 'modules/assetLook/actions';

import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { showModal } from 'modules/modalsSystem/actions/showModal';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { BUILDING_ID, CATEGORY_ID } from 'modules/assetLook/constants';
import { isDefault, isLoading, isSuccess } from 'modules/shared/utils';

// eslint-disable-next-line import/no-cycle
import { FilterList } from 'modules/assetLook/components';
import { FilterListSkeleton } from 'modules/shared/components';

const FilterListCategory = ({ disabled, searchPlaceholder, isTargetChecked }) => {
  const dispatch = useDispatch();

  const { id: companyId } = useSelector(getActiveCompany);
  const { buildingId, categoryId } = useLocationSearchParams([BUILDING_ID, CATEGORY_ID]);
  const [
    initFiltersRequestStatus,
    initFilters,
    activeFilters,
    activeTargetFilters,
    targetFilters,
    targetFiltersRequestStatus,
    modifiedFilters,
  ] = useAssetLookSelector([
    'filters.initFiltersRequestStatus',
    'filters.initFilters',
    'filters.activeFilters',
    'filters.activeTargetFilters',
    'filters.targetFilters',
    'filters.targetFiltersRequestStatus',
    'filters.modifiedFilters',
  ]);

  useEffect(() => {
    let isCanceled = false;

    // eslint-disable-next-line no-unused-expressions
    isDefault(initFiltersRequestStatus) &&
      fetchInitFilters(dispatch, isCanceled, {
        companyId,
        buildingId,
        categoryId,
      });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, buildingId, categoryId, initFiltersRequestStatus, dispatch]);

  useEffect(() => {
    let isCanceled = false;

    // eslint-disable-next-line no-unused-expressions
    isDefault(targetFiltersRequestStatus) &&
      fetchTargetFilters(dispatch, isCanceled, {
        companyId,
        buildingId,
        categoryId,
      });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, buildingId, categoryId, targetFiltersRequestStatus, dispatch]);

  const initialFilters = isTargetChecked ? [...initFilters, ...targetFilters] : initFilters;
  const isModifiedFilters = isEmpty(modifiedFilters) ? initialFilters : modifiedFilters;

  const activeFinalFilters = isTargetChecked ? [...activeFilters, ...activeTargetFilters] : activeFilters;

  const onFilterButtonClick = (filterField) =>
    dispatch(
      showModal(modalCodes.AL_FILTER_MODAL, { filterField, initialFilters: isModifiedFilters, activeFinalFilters }),
    );

  const onResetClick = () => dispatch(RESET_ACTIVE_FILTERS());

  return (
    <Choose>
      <When
        condition={
          isDefault(initFiltersRequestStatus) ||
          isDefault(targetFiltersRequestStatus) ||
          isLoading(initFiltersRequestStatus) ||
          isLoading(targetFiltersRequestStatus)
        }
      >
        <FilterListSkeleton />
      </When>
      <When
        condition={
          isSuccess(initFiltersRequestStatus) &&
          !isEmpty(initialFilters) &&
          isSuccess(targetFiltersRequestStatus) &&
          !isEmpty(targetFiltersRequestStatus)
        }
      >
        <FilterList
          disabled={disabled}
          initFilters={isModifiedFilters}
          activeFilters={activeFinalFilters}
          onFilterButtonClick={onFilterButtonClick}
          onResetClick={onResetClick}
          searchPlaceholder={searchPlaceholder}
        />
      </When>
      <Otherwise>{null}</Otherwise>
    </Choose>
  );
};

export default FilterListCategory;
