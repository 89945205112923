import React from 'react';

import { Modal } from '@material-ui/core';
import GraphModalDetailContainer from './GraphModalDetailContainer/GraphModalDetailContainer';

const GraphModalDetail = ({ onClose }) => {
  return (
    <Modal open onClose={onClose}>
      <GraphModalDetailContainer onClose={onClose} />
    </Modal>
  );
};

export default GraphModalDetail;
