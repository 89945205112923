/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty, cloneDeep } from 'lodash';

import { Grid, Button, Checkbox, CircularProgress, Snackbar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import Alert from '@material-ui/lab/Alert';

import { API } from 'modules/shared/utils';
import { getReports } from 'modules/workLook/selectors';
import { ENDPOINTS, requestStatuses } from 'modules/shared/constants';
import config from 'modules/workLook/components/PdfReportsPageSelector/config';
import { BASKET_TOGGLE_CHECK_ALL, BASKET_DELETE_CHECKED } from 'modules/workLook/actions';

import { TableOfContents } from 'modules/workLook/components';
import { TextPlaceholder } from 'modules/shared/components';

import theme from './Current.module.scss';

const Current = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { typeId, reportId } = useParams();
  const { reportData } = useSelector(getReports);
  const [createReportStatus, setCreateReportStatus] = useState(requestStatuses.default);

  const { basket } = reportData[reportId];
  const empty = isEmpty(basket);

  const isSomeChecked = !empty && Object.values(basket).some((section) => section.pages.some((page) => page.checked));
  const isAllChecked = !empty && Object.values(basket).every((section) => section.pages.every((page) => page.checked));

  const _onCheckAllClick = () =>
    dispatch(
      BASKET_TOGGLE_CHECK_ALL({
        reportId,
        newCheckboxState: !isAllChecked,
      }),
    );

  const _onDeleteClick = () => dispatch(BASKET_DELETE_CHECKED(reportId));

  const _onCreatePdfClick = () => {
    let canceled = false;
    setCreateReportStatus(requestStatuses.pending);

    const basketUrl = ENDPOINTS.workLook.basket.replace(':reportId', reportId);
    const customPdfUrl = ENDPOINTS.workLook.customPdf.replace(':reportId', reportId);
    const reportsHistoryUrl = config.history.path.replace(':typeId', typeId).replace(':reportId', reportId);

    const deepReport = cloneDeep(reportData[reportId]);
    const basketData = {
      ...deepReport,
      sections: deepReport.basket,
    };

    API.post(basketUrl, { basket_data: JSON.stringify(basketData) })
      .then(() => {
        if (canceled) return;
        // eslint-disable-next-line consistent-return
        return API.post(customPdfUrl);
      })
      .then(() => {
        if (canceled) return;
        history.push(reportsHistoryUrl);
      })
      .catch(() => {
        if (canceled) return;
        setCreateReportStatus(requestStatuses.failure);
      });

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={theme.header}>
        <Button
          className={theme.deleteBtn}
          disabled={empty || !isSomeChecked}
          startIcon={<DeleteIcon />}
          children="Delete"
          onClick={_onDeleteClick}
        />
        <Button
          disabled={empty || createReportStatus === requestStatuses.pending}
          // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
          startIcon={createReportStatus === requestStatuses.pending ? <CircularProgress size={14} /> : <GetAppIcon />}
          children="Create PDF"
          onClick={_onCreatePdfClick}
        />
        <Checkbox
          checked={isAllChecked}
          className={theme.selectAll}
          color="primary"
          disabled={empty}
          disableRipple
          style={{ padding: 0 }}
          onChange={_onCheckAllClick}
        />
      </Grid>

      <Grid item xs={12}>
        <Choose>
          <When condition={!empty}>
            <TableOfContents />
          </When>
          <Otherwise>
            <TextPlaceholder text="The basket is empty" absolute />
          </Otherwise>
        </Choose>
      </Grid>
      {/* TODO: Replace with the "notistack" */}
      <Snackbar
        open={createReportStatus === requestStatuses.failure}
        onClose={() => setCreateReportStatus(requestStatuses.default)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity="error" variant="filled">
          Something went wrong. Please try again later.
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Current;
