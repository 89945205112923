import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_GRAPHS_TEXT = createAsyncAction(createAction('FETCH_GRAPHS_TEXT'));

const fetchGraphsText = (dispatch, isCanceled, { companyId, page }) => {
  dispatch(FETCH_GRAPHS_TEXT.pending());

  const url = ENDPOINTS.liveLook.graphsText.replace(':companyId', companyId).concat(`?page=${page}`);

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_GRAPHS_TEXT.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_GRAPHS_TEXT.failure());
    });
};

export default fetchGraphsText;
