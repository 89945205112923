/* eslint-disable camelcase */
import React from 'react';
import { Typography, TextField, Button, Checkbox, FormControlLabel, Box } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Loader } from 'components';
import theme from './generalSettings.module.scss';
import { DASHBOARD_POSITIONS, ERROR_TYPES, GRAPHS_TYPE_LIST, GRAPH_POSITIONS } from './generalSettings.constants';

const GeneralSettings = ({
  isLoading,
  formik,
  isEdit,
  isDashboard,
  getError,
  getGraphDataType,
  isEmptySeries,
  errors,
}) => {
  const getPositions = isDashboard ? DASHBOARD_POSITIONS : GRAPH_POSITIONS;

  return (
    <Choose>
      <When condition={isLoading}>
        <Loader />
      </When>
      <When condition={!isLoading}>
        <div className={theme.container}>
          <Typography variant="h6" className={theme.title}>
            General Settings
          </Typography>
          <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
            {errors?.isError &&
              errors?.errorMsg?.map((msg) => (
                <Typography variant="body2" className={theme.error}>
                  {msg}
                </Typography>
              ))}
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Title"
                placeholder="Enter graph title"
                value={formik.values.title}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Box>
            <Typography variant="body2" className={theme.error}>
              {formik.errors.title}
            </Typography>
            <Box fullWidth sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                id="title_alias"
                name="title_alias"
                label="Title Alias"
                placeholder="Enter graph title alias"
                value={formik.values.title_alias}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  id="is_display_title"
                  name="is_display_title"
                  color="primary"
                  checked={formik.values.is_display_title}
                  value={formik.values.is_display_title}
                  onChange={formik.handleChange}
                />
              }
              label="Display title"
            />
            <Box sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                id="sub_title"
                name="sub_title"
                label="Subtitle"
                placeholder="Enter graph subtitle"
                value={formik.values.sub_title}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Box>
            <FormControl fullWidth>
              <InputLabel id="type">Type</InputLabel>
              <Select id="type" name="type" value={formik.values.type} label="Type" onChange={formik.handleChange}>
                {GRAPHS_TYPE_LIST.map(({ value, name, dataType }) => (
                  <MenuItem key={value} value={value}>
                    <Typography
                      variant="body1"
                      style={{
                        color: isEdit && !isEmptySeries ? (getGraphDataType === dataType ? 'black' : 'red') : 'balck',
                      }}
                    >
                      {name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" className={theme.error}>
                {formik.errors.type}
              </Typography>
              {isEdit && !isEmptySeries && getError && (
                <Typography variant="body2" className={theme.error}>
                  {ERROR_TYPES}
                </Typography>
              )}
            </FormControl>
            <Box sx={{ mt: 2, mb: 2 }}>
              <TextField
                multiline
                fullWidth
                maxRows={4}
                id="description"
                name="description"
                label="Description"
                placeholder="Enter graph description"
                value={formik.values.description}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Box>
            <FormControl fullWidth>
              <InputLabel id="position">Position</InputLabel>
              <Select
                id="position"
                name="position"
                value={formik.values.position}
                label="Position"
                onChange={formik.handleChange}
              >
                {getPositions.map(({ value, name }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" className={theme.error}>
                {formik.errors.position}
              </Typography>
            </FormControl>
            {isDashboard && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                  className={theme.input}
                  fullWidth
                  type="number"
                  id="order"
                  name="order"
                  label="Order"
                  placeholder="Enter an order"
                  value={formik.values.order}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.order}
                </Typography>
              </Box>
            )}
          </form>
          <Button
            onClick={() => formik.handleSubmit()}
            className={theme.sbmtBtn}
            color="primary"
            variant="outlined"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </When>
    </Choose>
  );
};

export default GeneralSettings;
