import React from 'react';
import { isEmpty } from 'lodash';

import { Table, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import { useNodeSize } from 'modules/shared/hooks';

const useStyles = makeStyles(() => ({
  table: {
    maxHeight: '100%',
    '& tr': {
      backgroundColor: 'unset',
    },
    '& th': {
      fontWeight: '700',
      width: '180px',
      maxWidth: '180px',
      verticalAlign: 'top',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& th, td': {
      borderBottom: 'none',
    },
  },
}));

function CardContent({ data }) {
  const classes = useStyles();

  const { ref, width } = useNodeSize();

  return (
    <Table size="small" classes={{ root: classes.table }} ref={ref}>
      <TableBody>
        <Choose>
          <When condition={width >= 787}>
            {data &&
              Object.entries(data).map(
                (row, i, array) =>
                  i % 2 === 0 && (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {row[0]}
                      </TableCell>
                      <TableCell>{row[1]}</TableCell>
                      <If condition={!isEmpty(array[i + 1])}>
                        <TableCell component="th" scope="row">
                          {array[i + 1][0]}
                        </TableCell>
                        <TableCell>{array[i + 1][1]}</TableCell>
                      </If>
                    </TableRow>
                  ),
              )}
          </When>
          <Otherwise>
            {data &&
              Object.entries(data).map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {row[0]}
                  </TableCell>
                  <TableCell>{row[1]}</TableCell>
                </TableRow>
              ))}
          </Otherwise>
        </Choose>
      </TableBody>
    </Table>
  );
}

export default CardContent;
