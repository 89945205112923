import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/spaceLook/components';
import { FilterList, LiveCounter, ReportDataCards, VerticalBarGraph } from 'modules/liveLook/components';
import WarningDialog from 'modules/shared/components/WarningDialog';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import { TextEditor } from 'modules/shared/components';
import IFrameModal from 'modules/liveLook/components/iFrame';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import graphDeskConfig from './graphDeskConfig';
import { MAX_SELECTED_WEEKS } from '../constants/liveLook.constants';

const SummaryKeyFindingsDesks = ({
  databoxes,
  databoxesSet,
  editableTextReport,
  saveGraphsText,
  deskGraphData,
  deskGraphScaleValues,
  onSwitcherChange,
  deskPeakAndAvgGraphData,
  deskPeakAndAvgScaleValues,
  deskPeakAndAvgOnSwitcherChange,
  returnPercentageSwitcherDeskPeakAndAvg,
  returnPercentageSwitcher,
  textVariables,
  archive,
  onScaleChange,
  scale,
}) => {
  const { labels } = deskGraphData;
  const [warningDialog, setWarningDialog] = useState(false);

  const openWarningDialog = () => {
    setWarningDialog(true);
  };

  const showWarning = labels?.length > MAX_SELECTED_WEEKS;
  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Executive Summary - Key Findings - Desks" />
          </Grid>
          <Grid item xs={12}>
            <FilterList pageType="DESKS" subtypes />
          </Grid>
          <Grid item xs={12}>
            <ReportDataCards databoxes={databoxes} pageType={pageTypes.desks} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Grid
          container
          alignItems="flex-end"
          direction="column"
          // spacing={1}
          style={{ justifyContent: 'flex-end' }}
        >
          <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>
        <Grid>
          <AddToPDFCard
            openWarningDialog={openWarningDialog}
            showWarning={showWarning}
            type="Key Findings - Desks"
            sectionType="Executive Summary"
            category={pdfCases.keyFindingsDesks}
            sectionOrder={sectionsOrder.first}
            typeOrder={sectionItemOrder.third}
            chartName={liveLookPagesNames.summaryKeyFindingsDesks}
            activeFilters={filtersDataContainer('DESKS', false).initActiveFilters}
            data={{
              databoxes,
              databoxesSet,
              editableTextReport,
              deskGraphData,
              deskGraphScaleValues,
              deskPeakAndAvgGraphData,
              deskPeakAndAvgScaleValues,
              returnPercentageSwitcherDeskPeakAndAvg,
              returnPercentageSwitcher,
              textVariables,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <TextEditor
          chartName={liveLookPagesNames.summaryKeyFindingsDesks}
          data={databoxesToEditorData(databoxesSet, textVariables)}
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
          graphType={GRAPH_TYPES.KEY_FINDINGS}
        />
      </Grid>

      <Grid item xs={6}>
        <VerticalBarGraph
          title="Occupancy By Week"
          data={deskGraphData}
          returnPercentageSwitcher={returnPercentageSwitcher}
          customHeader
          scaleValues={deskGraphScaleValues}
          onSwitcherChange={onSwitcherChange}
          config={graphDeskConfig}
          isWeekGraph
          shouldRenderScale
          scale={scale}
          onScaleChange={onScaleChange}
        />

        <VerticalBarGraph
          title="Peak and Average by Type"
          label="Integers"
          data={deskPeakAndAvgGraphData}
          returnPercentageSwitcher={returnPercentageSwitcherDeskPeakAndAvg}
          config={graphDeskConfig}
          onSwitcherChange={deskPeakAndAvgOnSwitcherChange}
          scaleValues={deskPeakAndAvgScaleValues}
          customHeader
        />
      </Grid>
      <If condition={openWarningDialog}>
        <WarningDialog
          open={warningDialog}
          _onClose={() => setWarningDialog(false)}
          title="Warning"
          text="The maximum number of weeks for the PDF reports is 9"
        />
      </If>
    </Grid>
  );
};

export default SummaryKeyFindingsDesks;
