/* eslint-disable react/no-children-prop */
import React from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import config from './config';

const useStyles = makeStyles((theme) => ({
  item: {
    color: theme.palette.grey[900],
    opacity: '0.54',
    textDecoration: '',
    cursor: 'default',

    '&:hover, &$active': {
      color: theme.palette.primary.main,
      opacity: '1',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  active: {},
}));

const PdfReportsPageSelector = () => {
  const { pathname } = useLocation();

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {Object.values(config).map(({ name, path }) => {
        const pagePath = path;
        const match = pagePath === pathname;

        return (
          <Grid item key={name}>
            <Link to={pagePath}>
              <Typography variant="body1" className={cn(classes.item, { [classes.active]: match })} children={name} />
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PdfReportsPageSelector;
