import { requestStatuses } from 'modules/shared/constants';
import {
  FETCH_BREAKOUT_USAGE_BY_SIZE,
  FETCH_BREAK_DATABOXES,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
} from 'modules/liveLook/actions';

export const initialState = {
  requestStatus: requestStatuses.success,
  databoxRequestStatus: requestStatuses.default,
  textReportRequestStatus: requestStatuses.default,
  data: null,
  breakDataboxes: [],
  editableTextReport: '',
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_BREAKOUT_USAGE_BY_SIZE.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_BREAKOUT_USAGE_BY_SIZE.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload,
      };
    case FETCH_BREAKOUT_USAGE_BY_SIZE.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    case FETCH_BREAK_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_BREAK_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        breakDataboxes: payload,
      };
    case FETCH_BREAK_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    default:
      throw new Error();
  }
}

export default reducer;
