import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import cn from 'classnames';
import axios from 'axios';

import { REQUESTS } from '_constants';
import { VALIDATION_RULES } from 'modules/auth/constants';

import { FormButton, ModalWindow } from 'components';
// eslint-disable-next-line import/no-cycle
import { FormikInput, ErrorNotification, FormHeader, ResetPasswordMailNotification } from 'modules/auth/components';
import BackButton from './BackButton';

import './RestoreForm.scss';

const { base, version, reset } = REQUESTS;

const RestoreForm = ({ title, backButtonText, _onBackBtnClick }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const [resetSendSuccess, setResetSendSuccess] = useState(false);

  const isModalOpen = resetSendSuccess && modalOpen;

  const errorModal = error && (
    <ModalWindow
      onClose={() => setErrorModalOpen(false)}
      isOpen={errorModalOpen}
      buttonText="Close"
      withToggleButton={false}
    >
      <ErrorNotification ctaText="Please, try again later" />
    </ModalWindow>
  );

  return (
    <div className="RestoreForm">
      {errorModal}
      <ModalWindow
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        buttonText="Got it"
        withToggleButton={false}
      >
        <ResetPasswordMailNotification />
      </ModalWindow>

      <BackButton onClick={_onBackBtnClick} text={backButtonText} />
      <FormHeader title={title} />
      <Formik
        initialValues={{
          email: '',
        }}
        validate={(values) => {
          const errors = {};
          const { email } = values;
          const isValidMail = VALIDATION_RULES.email.test(String(email).toLowerCase());

          if (!email.length) {
            errors.email = '*Required';
          } else if (!isValidMail) {
            errors.email = '*Incorrect email';
          }
          return errors;
        }}
        onSubmit={(values) => {
          if (pending) return;
          const { email } = values;
          setPending(true);
          axios
            .post(`${base}${version}${reset}`, { email })
            .then(() => {
              setResetSendSuccess(true);
              setPending(false);
              setModalOpen(true);
            })
            .catch(() => {
              setError(true);
              setErrorModalOpen(true);
              setPending(false);
            });
        }}
      >
        {({ errors, touched }) => {
          const isEmailErrors = Boolean(errors.email && touched.email);
          const buttonErrorClass = isEmailErrors || pending ? 'disabled' : '';

          return (
            <Form className={cn('restore-form', buttonErrorClass)}>
              <FormikInput name="email" placeholder="Email" type="email" />
              {isEmailErrors && <div className="field-error">{errors.email}</div>}
              <FormButton text="Restore" />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

RestoreForm.defaultProps = {
  backButtonText: 'Back',
  title: 'Please, enter your email to restore the password',
  // eslint-disable-next-line no-console
  closeForm: () => console.log('func for closein form'),
};

export default RestoreForm;
