import { graphTypes, graphDataTypes } from 'components/Pages/SummaryPage/constants';

const config = {
  graphsHeader: {
    [graphTypes.property]: {
      [graphDataTypes.deskCount]: 'Desk count by Property',
      [graphDataTypes.deskUtilization]: 'Utilisation by Property',
    },
    [graphTypes.division]: {
      [graphDataTypes.deskCount]: 'Desk count by Division',
      [graphDataTypes.deskUtilization]: 'Utilisation by Division',
    },
  },
  graphsLegend: {
    utilization: [
      {
        label: 'Average Utilization',
        color: 'sea-blue',
      },
      {
        label: 'Average Vacant',
        color: 'almost-white',
      },
    ],
  },
};

export default config;
