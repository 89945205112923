/* eslint-disable no-console */
import { createAction } from '@reduxjs/toolkit';

const namespaces = [];

const createNamespacedAction = (namespace) => {
  const namespacedActions = [];
  if (namespaces.includes(namespace)) {
    console.error(`Not unique namespace name: ${namespace}`);
  }

  namespaces.push(namespace);

  return (action) => {
    const namespacedAction = `${namespace}_${action}`;
    if (namespacedActions.includes(namespacedAction)) {
      console.error(`Not unique namespaced action name: ${namespacedAction}`);
    }

    namespacedActions.push(namespacedAction);
    return createAction(namespacedAction);
  };
};

export default createNamespacedAction;
