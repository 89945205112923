/* eslint-disable camelcase */
import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_DESK_BY_DIVISION_SUMMARY = createAsyncAction(createAction('FETCH_DESK_BY_DIVISION_SUMMARY'));

const fetchDeskByDivisionSummary = (
  dispatch,
  isCanceled,
  { companyId, buildingId, workingHours, query, fixed_date_range, dateAfter, dateBefore, archive },
) => {
  dispatch(FETCH_DESK_BY_DIVISION_SUMMARY.pending());

  const url = ENDPOINTS.liveLook.desksByDivisionSummary
    .replace(':companyId', companyId)
    .replace(':buildingId/', buildingId ? `${buildingId}/` : '')
    .concat(workingHours ? `?work_hours=True` : '')
    .concat(workingHours ? `&exclude_weekend=True` : '')
    .concat(dateAfter ? (workingHours ? `&date_after=${dateAfter}` : `?date_after=${dateAfter}`) : '')
    .concat(dateBefore ? `&date_before=${dateBefore}` : '')
    .concat(
      fixed_date_range
        ? workingHours
          ? `&fixed_date_range=${fixed_date_range}`
          : `?fixed_date_range=${fixed_date_range}`
        : '',
    )
    .concat(query ? `&${query}` : '')
    .concat(archive ? `&archive_id=${archive}` : '');

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_DESK_BY_DIVISION_SUMMARY.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_DESK_BY_DIVISION_SUMMARY.failure());
    });
};

export default fetchDeskByDivisionSummary;
