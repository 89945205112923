import React from 'react';
import { Typography, TableCell, makeStyles } from '@material-ui/core';
import { PRODUCT_FIELDS } from 'modules/assetLook/constants';

import noImage from 'assets/images/icons/no-image.svg';

const useStyles = makeStyles((theme) => ({
  cell: {
    display: 'block',
    cursor: 'pointer',
    textAlign: ({ src }) => typeof src === 'string' && 'center',
  },
  label: {
    maxHeight: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  image: {
    height: '107px',
    maxWidth: '142.67px',
    backgroundSize: 'contain',
    backgroundColor: ({ src }) => (src ? theme.palette.common.white : theme.palette.grey[200]),
  },
}));

function ProductListCellBody({ data, rowIndex, columnKey, height, width }) {
  let label;
  let src;

  switch (columnKey) {
    case PRODUCT_FIELDS.thumbnail:
      src = data[rowIndex][columnKey];
      break;
    case PRODUCT_FIELDS.name:
      label = data[rowIndex][columnKey];
      break;
    case PRODUCT_FIELDS.assets:
      label = data[rowIndex][columnKey];
      break;
    default:
      label = data[rowIndex].list_data[columnKey];
  }

  const classes = useStyles({ src });

  return (
    <TableCell size="small" component="div" variant="body" style={{ height, width }} classes={{ root: classes.cell }}>
      <Choose>
        <When condition={columnKey === PRODUCT_FIELDS.thumbnail}>
          <img
            className={classes.image}
            src={src || noImage}
            alt={`${data[rowIndex][PRODUCT_FIELDS.name]} thumbnail`}
          />
        </When>
        <Otherwise>
          <Typography color="" variant="body1" title={label} classes={{ root: classes.label }}>
            {label}
          </Typography>
        </Otherwise>
      </Choose>
    </TableCell>
  );
}

export default ProductListCellBody;
