/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Checkbox.scss';

const Checkbox = ({ label, onClick, isChecked, isDisabled, isStrict, className }) => {
  const classes = cn('Checkbox', className, { 'is-strict': isStrict });

  return (
    <div className={classes}>
      <label className="checkbox">
        <input disabled={isDisabled} onChange={onClick} checked={isChecked} type="checkbox" />
        <span>{label}</span>
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  label: 'Option',
  isStrict: false,
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStrict: PropTypes.bool,
};

export default Checkbox;
