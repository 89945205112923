import React from 'react';

const renderPopupData = (rest) => {
  const popupData = Object.entries(rest);
  return popupData.map(([fieldName, fieldValue]) =>
    // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
    !fieldValue ? '' : <p key={fieldName}>{`${fieldName.replace(/_/g, ' ')}: ${fieldValue}`}</p>,
  );
};

export default renderPopupData;
