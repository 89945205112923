import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TableCell, TableSortLabel, Typography, makeStyles } from '@material-ui/core';

import { SORT_PRODUCTS } from 'modules/assetLook/actions';
import { PRODUCT_FIELDS } from 'modules/assetLook/constants';
import { useAssetLookSelector } from 'modules/assetLook/hooks';

const labelsMap = {
  [PRODUCT_FIELDS.thumbnail]: 'Thumbnail',
  [PRODUCT_FIELDS.name]: 'Product Name',
  [PRODUCT_FIELDS.assets]: 'Assets',
};

const useStyles = makeStyles(() => ({
  cell: {
    display: 'block',
    textAlign: ({ isImage }) => isImage && 'center',
  },
  sortLabel: {
    maxWidth: '100%',
    cursor: ({ isImage }) => isImage && 'default',
    '&:focus': {
      color: 'inherit',
    },
  },
  label: {
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

function ProductListCellHeader({ columnKey, height, width }) {
  const dispatch = useDispatch();
  const isImage = columnKey === PRODUCT_FIELDS.thumbnail;
  const label = labelsMap[columnKey] || columnKey;

  const classes = useStyles({ isImage });

  const [productsSortParams] = useAssetLookSelector(['products.sortParams']);
  const isActiveSort = productsSortParams.orderBy === columnKey;

  const sortHandler = useCallback(() => {
    if (!isImage) dispatch(SORT_PRODUCTS(columnKey));
  }, [columnKey, dispatch, isImage]);

  return (
    <TableCell
      size="small"
      component="div"
      variant="head"
      onClick={sortHandler}
      style={{ height, width }}
      classes={{ root: classes.cell }}
    >
      <TableSortLabel
        active={isActiveSort}
        direction={productsSortParams.direction}
        hideSortIcon
        classes={{ root: classes.sortLabel }}
      >
        <Typography variant="body1" title={label} classes={{ root: classes.label }}>
          {label}
        </Typography>
      </TableSortLabel>
    </TableCell>
  );
}

export default ProductListCellHeader;
