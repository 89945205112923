import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { URLS } from 'pages/constants';
import { getReports } from 'modules/workLook/selectors';

function Base() {
  const { typeId } = useParams();
  const { reportData } = useSelector(getReports);

  return (
    <Redirect
      to={URLS.workLook.title.replace(':typeId', typeId).replace(':reportId', Object.values(reportData)[0].id)}
    />
  );
}

export default Base;
