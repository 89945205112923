import { createReducer } from '@reduxjs/toolkit';

import { actionsCreator } from 'modules/spaceLook/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  FETCH_BENCHMARKING_DATABOXES,
  FETCH_BENCHMARKING_TEXT_DATA,
  FETCH_BENCHMARKING_HISTORIC_DATA,
  APPLY_BENCHMARKING_FILTERS,
  RESET_BENCHMARKING_FILTERS,
  RESET_REPORT_DATA,
} from 'modules/spaceLook/actions';

const initState = {
  requestStatuses: {
    databoxes: requestStatuses.default,
    textData: requestStatuses.default,
    historicData: requestStatuses.default,
  },
  data: {
    databoxes: null,
    textData: null,
    historicData: null,
  },
  shouldRefetchData: {
    databoxes: false,
    textData: false,
    historicData: false,
  },
};

const benchmarkingReducer = createReducer(initState, {
  ...actionsCreator(FETCH_BENCHMARKING_DATABOXES, 'databoxes'),
  ...actionsCreator(FETCH_BENCHMARKING_TEXT_DATA, 'textData'),
  ...actionsCreator(FETCH_BENCHMARKING_HISTORIC_DATA, 'historicData'),

  [APPLY_BENCHMARKING_FILTERS]: (state) => ({
    ...state,
    shouldRefetchData: {
      databoxes: true,
      textData: true,
      historicData: true,
    },
  }),
  [RESET_BENCHMARKING_FILTERS]: (state) => ({
    ...state,
    shouldRefetchData: {
      databoxes: true,
      textData: true,
      historicData: true,
    },
  }),

  [RESET_REPORT_DATA]: () => ({ ...initState }),
});

export default benchmarkingReducer;
