const transormDataPropertyPercent = (data) => {
  return data.map((division) => {
    const avgUtilPercent = division.desk_util.avg_util_percent;
    if (avgUtilPercent === null) {
      return {
        property_name: division.property_name,
        divisions: [],
      };
    }
    return {
      property_name: division.property_name,
      divisions: [
        {
          division: 'Utilization',
          desk_count: avgUtilPercent,
        },
        {
          division: 'Free Space',
          desk_count: 100 - avgUtilPercent,
        },
      ],
    };
  });
};

export default transormDataPropertyPercent;
