import React from 'react';
import PropTypes from 'prop-types';

import './RestorePage.scss';

import { Copyright } from 'modules/shared/components';
import { NewPasswordForm, SwitchButton } from 'modules/auth/components';

import buildings from 'assets/images/buildings.png';
import buildingsBig from 'assets/images/buildings1-5x.png';

const RestorePage = ({ copyrightContent }) => {
  const imageSrc = window.devicePixelRatio === 2 ? buildings : buildingsBig;

  const backgroundImage = {
    backgroundImage: `url(${imageSrc})`,
  };

  return (
    <article className="RestorePage">
      <div className="restore-page">
        <div className="restore-form-container">
          <section className="logo">
            <SwitchButton />
          </section>
          <section className="restore-form">
            <NewPasswordForm />
          </section>
          <Copyright copyrightContent={copyrightContent} />
        </div>
        <div style={backgroundImage} className="restore-image-container">
          <div className="filter" />
        </div>
      </div>
    </article>
  );
};

RestorePage.defaultProps = {
  copyrightContent: ['Powered by Baker Stuart Worklook', '©BakerStuart Limited 2019'],
};

RestorePage.propTypes = {
  copyrightContent: PropTypes.arrayOf(PropTypes.string),
};

export default RestorePage;
