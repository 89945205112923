/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';

import { isEmpty } from 'lodash';
import EditableTable from './EditableTable';
import EmptyGraph from '../EmptyGraph';

const EditableTableContainer = ({ mapType, id, type_of_group, activeFilters, type, isPublished, isPdf, ...props }) => {
  const { isLoading, data } = useGraphData(id, activeFilters);
  if (isEmpty(data))
    return (
      <EmptyGraph
        display={false}
        title={props.title}
        isPublished={isPublished}
        typeOfGroup={type_of_group}
        graphId={id}
        showTitle={props.is_display_title}
        subtitle={props.sub_title}
        type={type}
        {...props}
      />
    );

  return (
    <EditableTable
      graphId={id}
      typeOfGroup={type_of_group}
      isPublished={isPublished}
      isLoading={isLoading}
      data={data[0].data}
      fieldTypes={data[0].field_types}
      {...props}
      type={type}
      isPdf={isPdf}
    />
  );
};

export default EditableTableContainer;
