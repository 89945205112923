import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { showSecondModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { getUser } from 'modules/auth/selectors';
import UserRow from '../UserRow';

import theme from './userTableRow.module.scss';

const UserTableRow = ({ users }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(getUser);

  const onDeleteUser = (userId, firstName, lastName) => {
    dispatch(showSecondModal(modalCodes.CONFIRM_DELETION, { userId, firstName, lastName }));
  };

  return (
    <div className={theme.container}>
      {users
        .filter((_user) => _user.id !== user.id)
        .map((user, index) => {
          return (
            <div key={index} className={theme.row}>
              <UserRow user={user} />
              <div className={theme.iconWrapper}>
                <DeleteIcon
                  onClick={() => onDeleteUser(user.id, user.first_name, user.last_name)}
                  color="error"
                  fontSize="small"
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default UserTableRow;
