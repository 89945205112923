import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getReports } from 'modules/workLook/selectors';

import { TextPlaceholder } from 'modules/shared/components';

import PdfReportsPage from './PdfReportsPage';

const PdfReportsPageContainer = () => {
  const { pageType, reportId } = useParams();
  const { reportData } = useSelector(getReports);
  const activeReport = reportData[reportId];

  return (
    <Choose>
      <When condition={!activeReport}>
        <TextPlaceholder noData />
      </When>
      <Otherwise>
        <PdfReportsPage pageType={pageType} />
      </Otherwise>
    </Choose>
  );
};

export default PdfReportsPageContainer;
