import { GRAPH_SCALES, GRAPH_VALUES } from 'modules/liveLook/constants';
import { isEmpty } from 'lodash';

const valueTypesMap = {
  [GRAPH_VALUES.max]: 'max',
  [GRAPH_VALUES.avg]: 'avg',
};

const percentageValueTypesMap = {
  [GRAPH_VALUES.max]: 'percentage_max',
  [GRAPH_VALUES.avg]: 'percentage_avg',
};

const getLabel = (hourData, scale) => {
  switch (scale) {
    case GRAPH_SCALES.day: {
      return hourData.day;
    }

    case GRAPH_SCALES.week: {
      return hourData.day;
    }

    case GRAPH_SCALES.month: {
      return hourData.day.slice(3);
    }

    default: {
      return hourData.time;
    }
  }
};

const toUsageOverTimeGraphData = ({ sourceData, valueType, scaleMaxValue, scale, returnPercentageSwitcher }) => {
  if (isEmpty(sourceData)) return false;
  const dayLabels = [];

  const graphData = {
    labels: [],
    datasets: [
      {
        type: 'bar',
        label: 'Occupied',
        stack: 'Stack 0',
        data: [],
        fill: false,
        backgroundColor: '#58bfc7',
      },
      {
        type: 'bar',
        label: 'Unoccupied',
        data: [],
        stack: 'Stack 0',
        fill: false,
        backgroundColor: '#f1f1f1',
      },
    ],
  };

  sourceData.forEach(([dayLabel, dayData]) => {
    dayLabels.push({
      label: dayLabel,
      length: dayData.length,
    });

    dayData.forEach((hourData) => {
      const key = returnPercentageSwitcher ? valueTypesMap[valueType] : percentageValueTypesMap[valueType];

      const occupied = hourData[key] >= 0 ? hourData[key] : 0;
      const unoccupied = scaleMaxValue ? Number(scaleMaxValue - occupied) : 100 - occupied;

      const label = getLabel(hourData, scale);

      graphData.labels.push(label);
      graphData.datasets[0].data.push(occupied);
      graphData.datasets[1].data.push(parseFloat(unoccupied.toFixed(2)));
    });
  });

  return { data: graphData, dayLabels };
};

export default toUsageOverTimeGraphData;
