const setFormikFields = (filesObject, formik, id) => {
  const filesList = formik.values.files;
  Array.from(filesObject)
    .filter((file) => {
      if (file.type !== 'text/csv') formik.setFieldError(id.toString(), 'File type must be .csv');
      return file.type === 'text/csv';
    })
    .forEach((file) => {
      if (filesList.every((el) => Number(el.id) !== id)) {
        formik.setFieldValue('files', filesList.concat({ id: id.toString(), file }));
      } else {
        formik.setFieldValue(
          'files',
          filesList.map((el) => (Number(el.id) === id ? { id: id.toString(), file } : el)),
        );
      }
    });
};

export const onDrop = (e, formik, id) => {
  e.preventDefault();
  setFormikFields(e.dataTransfer.files, formik, id);
};

export const onInput = (e, formik, id) => {
  setFormikFields(e.target.files, formik, id);
};

export const findEmpty = (chosenValues, allValues) => {
  const chosenList = chosenValues.map(({ id }) => id);
  const emptyList = allValues.filter(({ id }) => !chosenList.includes(id.toString()));
  return emptyList;
};
