import React from 'react';
import { Tooltip, Typography, Box, makeStyles } from '@material-ui/core';

import theme from './HourlyGraphLines.module.scss';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: '8px',
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  arrow: {
    color: 'white',
  },
}));

const HourlyGraphLines = ({ linesData, config, marginBottom, maxScaleValue, switcher }) => {
  const classes = useStyles();

  return config.map(({ label, valueKey, percentKey, color }) => {
    const TooltipContent = () => (
      <>
        <Typography variant="body1">
          <Box fontWeight="fontWeightMedium" component="span">
            {label}
          </Box>
        </Typography>
        <Typography variant="body1">{` ${linesData[valueKey]} (${linesData[percentKey]}%)`}</Typography>
      </>
    );

    const position = !switcher
      ? (linesData[percentKey] / maxScaleValue) * 100
      : (linesData[valueKey] / maxScaleValue) * 100;

    return (
      <Tooltip
        arrow
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        title={<TooltipContent />}
        key={label}
      >
        <div
          className={theme.graphLine}
          style={{
            bottom: `calc((100% - ${marginBottom + 5}px) / 100 * ${position} + ${marginBottom}px)`,
            backgroundColor: color,
          }}
        />
      </Tooltip>
    );
  });
};

export default HourlyGraphLines;
