/* eslint-disable react/no-children-prop */
import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

import { ASSET_STATUSES, ASSET_STATUSES_LABEL } from 'modules/assetLook/constants';

const StatusSelect = ({ formik }) => (
  <FormControl style={{ width: '100%' }}>
    <InputLabel children="Status" />
    <Select
      id="status"
      name="status"
      disabled
      value={formik.values.status}
      onChange={formik.handleChange}
      error={formik.touched.status && Boolean(formik.errors.status)}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      <MenuItem value={ASSET_STATUSES.NONE}>{ASSET_STATUSES_LABEL[ASSET_STATUSES.NONE]}</MenuItem>
      <MenuItem value={ASSET_STATUSES.RM}>{ASSET_STATUSES_LABEL[ASSET_STATUSES.RM]}</MenuItem>
      <FormHelperText children={formik.touched.reportType && formik.errors.reportType} />
    </Select>
  </FormControl>
);

export default StatusSelect;
