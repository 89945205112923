import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';

const useStandardPdf = (reportId, deps = []) => {
  const [standardPdf, setStandardPdf] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(null);

  useEffect(() => {
    let canceled = false;
    const url = ENDPOINTS.workLook.basePdf.replace(':reportId', reportId);

    API.get(url)
      .then(({ data }) => {
        if (canceled) return;
        setStandardPdf(data);
      })
      .catch((err) => {
        if (canceled) return;
        // eslint-disable-next-line no-console
        console.error(err);
      });

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, reloadTrigger, ...deps]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (standardPdf && standardPdf.status === 'In progress') setReloadTrigger(uuidv4());
    }, 5000);

    return () => clearInterval(interval);
  });

  return standardPdf;
};

export default useStandardPdf;
