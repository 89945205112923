import React from 'react';
import { cloneDeep } from 'lodash';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

import { reportPageTypes } from 'modules/spaceLook/constants';

import { PageHeader, ReportText, ReportDataCards, FilterHandler, ReportFilter } from 'modules/spaceLook/components';
import { useHasScroll } from 'modules/shared/hooks';

const KeyFindingsDesks = ({
  isLoading,
  pdfMode,
  databoxes,
  textData,
  pieData,
  dailyHourlyGraphData,
  weeklyHourlyGraphData,
  usageBandingOccGraphData,
}) => {
  const scroll = useHasScroll();
  return (
    <div ref={scroll.ref} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Key Findings - Desks" text="Executive Summary" />
        </Grid>
        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.desks}>
              <ReportFilter pageType={reportPageTypes.desks} />
            </FilterHandler>
          </Grid>
        </If>
        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.desks} />
        </Grid>
        <Grid item xs={12}>
          <ReportText
            getParagraphs={[
              'desksOccupancyPlusSigns',
              'desksPeakDaysPlus',
              'desksOccupancyPlusAgile',
              'desksTwentyPercent',
              'mostCommonActivities',
            ]}
            databoxes={databoxes}
            textData={textData}
            pieData={pieData}
            graphData={cloneDeep(dailyHourlyGraphData)}
            dailyUsagePattern={weeklyHourlyGraphData}
            bandingOccupiedData={usageBandingOccGraphData}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default KeyFindingsDesks;
