/* eslint-disable camelcase */
import React from 'react';

import { Grid } from '@material-ui/core';
import { List, ListItem, ListItemText } from '../CustomList';

const OrderInfo = ({
  building_name,
  user_first_name: firstName,
  user_last_name: lastName,
  user_email: email,
  target_location,
  target_building_name,
  location_floor,
  location_zone,
  request_date: deliveryDate,
  comment,
}) => (
  <List>
    <Grid container spacing={1}>
      <Grid item sm={6} md={3}>
        <ListItem>
          <ListItemText primary="Source building" secondary={building_name} />
        </ListItem>
      </Grid>
      <Grid item sm={6} md={3}>
        <ListItem>
          <ListItemText primary="Requester" secondary={`${firstName} ${lastName} (${email})`} />
        </ListItem>
      </Grid>
      <Grid item sm={6} md={3}>
        <ListItem>
          <ListItemText
            primary="Delivery location"
            secondary={target_location ? `${target_building_name} (${location_floor}, ${location_zone})` : 'Pickup'}
          />
        </ListItem>
      </Grid>
      <Grid item sm={6} md={3}>
        <ListItem>
          <ListItemText
            primary="Delivery date"
            secondary={new Date(deliveryDate).toLocaleString('en-GB', {
              weekday: 'short',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          />
        </ListItem>
      </Grid>
      <Grid item xs={12} md={6}>
        <ListItem>
          <ListItemText primary="Comment" secondary={comment || '—'} />
        </ListItem>
      </Grid>
    </Grid>
  </List>
);

export default OrderInfo;
