import React, { useCallback, useRef, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { useAssetLookSelector } from 'modules/assetLook/hooks';

import ArrivalOrderUpload from './ArrivalOrderUpload';
import { getActiveCompany } from '../../../../shared/selectors';

function ArrivalOrderUploadContainer({ onClose }) {
  const inputRef = useRef();

  const onButtonClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const [errorCode, setErrorCode] = useState(null);

  const [basketBuilding] = useAssetLookSelector(['assets.basketBuilding']);

  const { id: companyId } = useSelector(getActiveCompany);

  const validationSchema = yup.object({
    location: yup.number('Select the location').required('Location is required'),
    date: yup.string('Pick the date').required('Date is required'),
    comment: yup.string('Provide a comment'),
    csvFile: yup.mixed().required('A CSV file is required'),
  });

  const formik = useFormik({
    initialValues: {
      location: '',
      date: new Date().toISOString().substring(0, 10),
      comment: '',
      csvFile: '',
    },
    validationSchema,
    onSubmit: ({ location, date, comment, csvFile }, formikHelpers) => {
      setErrorCode(null);

      const formData = new FormData();
      formData.append('building', Number(basketBuilding));
      formData.append('request_date', date);
      if (location) formData.append('target_location', location);
      if (comment) formData.append('comment', comment);
      if (csvFile) formData.append('csvFile', csvFile);

      API.post(ENDPOINTS.assetLook.upcomingDelivery.replace(':companyId', companyId), formData, {
        headers: {
          // Omit the Content-Type header, so the browser sets it automatically with the correct boundary
          'Content-Type': undefined,
        },
      })
        .then(() => {
          onClose();
          formikHelpers.setSubmitting(false);
        })
        .catch(({ response }) => {
          formikHelpers.setSubmitting(false);
          setErrorCode(response.status);
        });
    },
  });
  console.log('formik', formik);

  return <ArrivalOrderUpload formik={formik} onClose={onClose} errorCode={errorCode} onButtonClick={onButtonClick} />;
}

export default ArrivalOrderUploadContainer;
