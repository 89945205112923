import React from 'react';

import { Modal } from '@material-ui/core';
import GraphModalContainer from './GraphModalContainer/GraphModalContainer';

const GraphModal = ({ onClose }) => {
  return (
    <Modal open onClose={onClose}>
      <GraphModalContainer onClose={onClose} />
    </Modal>
  );
};

export default GraphModal;
