import React from 'react';
import PropTypes from 'prop-types';

import { HistoryReportListItem } from 'components';

import './HistoryReportList.scss';

const HistoryReportList = ({ reports }) => (
  <article className="HistoryReportList">
    <div className="history-report-list">
      {reports.map(({ date, place, data, id }) => (
        <HistoryReportListItem key={id} id={id} date={date} place={place} data={data} />
      ))}
    </div>
  </article>
);

HistoryReportList.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      }),
      place: PropTypes.arrayOf(PropTypes.string),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          percent: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default HistoryReportList;
