import React from 'react';
import { TableCell } from '@material-ui/core';
import { CONDITIONS } from 'modules/assetLook/constants';

function BasketCellBody({ data, rowIndex, columnKey, height, width }) {
  let label;

  switch (columnKey) {
    case 'condition':
      label = CONDITIONS[data[rowIndex][columnKey]];
      break;
    default:
      label = data[rowIndex][columnKey];
  }

  return (
    <TableCell
      size="small"
      component="div"
      variant="body"
      style={{
        height,
        width,
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <span title={label}>{label}</span>
    </TableCell>
  );
}

export default BasketCellBody;
