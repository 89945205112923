import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';

// eslint-disable-next-line import/no-cycle
import { CustomButtonContained } from 'modules/shared/components';

const useStyles = makeStyles(() => ({
  actions: {
    padding: '16px 24px',
  },
}));

/**
 * ModalBody should be either a separated component or embedded to the ModalBase directly
 * (not a function inside a functional component).
 * Because otherwise it breaks dropzone on the "CSV upload" modal.
 */
const ModalBody = ({
  title,
  children,
  firstActionOnClick,
  onClose,
  isEmptyData,
  firstActionLabel = 'Cancel',
  firstActionProps,
  onSubmit,
  secondActionOnClick,
  secondActionLabel,
  secondActionProps,
  createTemplate = true,
}) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button
          color="primary"
          onClick={firstActionOnClick || onClose}
          // eslint-disable-next-line react/no-children-prop
          children={firstActionLabel}
          {...firstActionProps}
        />
        <If condition={!isEmptyData}>
          <If condition={createTemplate}>
            <CustomButtonContained
              label={secondActionLabel}
              type={onSubmit ? 'submit' : ''}
              onClick={secondActionOnClick}
              {...secondActionProps}
            />
          </If>
        </If>
      </DialogActions>
    </>
  );
};

export default ModalBody;
