import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import { GRAPH_RANGES } from 'modules/liveLook/components/UsageOverTimeGraph/components/GraphHeader/graphHeader.constants';

const DashboardSwitcher = ({ isSwitcherOn, onSwitcherChange, range, onRangeChange }) => {
  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid>
        <FormGroup>
          <Grid component="label" container alignItems="center" spacing={2}>
            <Grid item>Percentages</Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={isSwitcherOn} onChange={onSwitcherChange} />}
                label="Integers"
              />
            </Grid>
          </Grid>
        </FormGroup>
      </Grid>

      <Grid>
        <FormControl style={{ minWidth: '150px' }}>
          <InputLabel id="range">Range</InputLabel>
          <Select
            labelId="range"
            id="range"
            value={range}
            onChange={onRangeChange}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {GRAPH_RANGES.map(({ period, value }) => (
              <MenuItem value={value} key={period}>
                {period}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DashboardSwitcher;
