/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FormButton, ModalWindow } from 'components';
// eslint-disable-next-line import/no-cycle
import { FormikInput, PasswordNotification, FormHeader } from 'modules/auth/components';

import { API } from 'modules/shared/utils';
import { LOG_IN } from 'modules/auth/actions';
import { ENDPOINTS } from 'modules/shared/constants';
import { onLoginRedirect } from 'modules/auth/utils';
// import { useSharedSelector } from 'modules/shared/hooks';
// import { fetchWorkLookTypes } from 'modules/shared/actions';
import axios from 'axios';
import { URLS } from 'pages/constants';

import { isEmpty } from 'lodash';
import config from './config';
import theme from './LoginForm.module.scss';
import { getUser } from '../../selectors';

const LoginForm = ({ _onRestoreClick }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(getUser);
  // const [portalSections] = useSharedSelector(['portalSection']);
  const storageUser = user;
  const displayForm = window.localStorage.getItem('anotherUser');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const asUserToken = queryParameters.get('token');

  useEffect(() => {
    if (!isEmpty(asUserToken)) {
      window.localStorage.setItem('anotherUser', true);
      window.localStorage.setItem('asUserToken', asUserToken);
      const conf = {
        headers: {
          Authorization: `Token ${asUserToken}`,
        },
      };
      axios
        .get(ENDPOINTS.auth.user, conf)
        .then(({ data }) => {
          dispatch(LOG_IN({ user: data }));
          onLoginRedirect({ user: data, history });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setErrorModalOpen(true);
        });
    }
  }, [asUserToken, dispatch, history]);

  const onHomeRedirect = () => {
    history.push(URLS.auth.empty);
  };

  return (
    <>
      <FormHeader title="Welcome to the Workplace Portal!" />
      <Formik
        initialValues={config.initialValues}
        validate={config.validate}
        validationSchema={config.validationSchema}
        onSubmit={(values) => {
          API.post(ENDPOINTS.auth.login, values)
            // eslint-disable-next-line camelcase
            .then(({ data: { user, key, otp_login_required } }) => {
              window.localStorage.setItem('userToken', key);
              dispatch(LOG_IN({ user, isOtpRequired: otp_login_required }));
              onLoginRedirect({ user: user || storageUser, history, isOtpRequired: otp_login_required });
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
              setErrorModalOpen(true);
            });
        }}
      >
        {({ errors, touched }) => {
          const isEmailErrors = Boolean(errors.email && touched.email);
          const isPasswordErrors = Boolean(errors.password && touched.password);

          return (
            <>
              <If condition={!displayForm}>
                <Form className={cn(theme.loginForm, { [`${theme.disabled}`]: isEmailErrors || isPasswordErrors })}>
                  <FormikInput name="email" placeholder="Email" type="email" />
                  {isEmailErrors && <div className={theme.fieldError}>{errors.email}</div>}

                  <FormikInput name="password" placeholder="Password" type="password" />
                  {isPasswordErrors && <div className={theme.fieldError}>{errors.password}</div>}
                  <FormButton />
                  <div className={theme.restoreBlock}>
                    <span className={theme.text}>Forgot your password?</span>
                    <button type="button" onClick={_onRestoreClick} className={theme.button}>
                      Restore
                    </button>
                  </div>
                </Form>
              </If>
              <If condition={displayForm}>
                <Form className={cn(theme.loginForm)}>
                  <div className={theme.restoreBlock}>
                    <span className={theme.text}>
                      You are logged in as another user. To work from your admin account you should
                      <button type="button" onClick={onHomeRedirect} className={theme.button}>
                        &nbsp;return to the home&nbsp;
                      </button>
                      page and return to admin account
                    </span>
                  </div>
                </Form>
              </If>
            </>
          );
        }}
      </Formik>

      <ModalWindow
        isOpen={errorModalOpen}
        withToggleButton={false}
        onClose={() => setErrorModalOpen(false)}
        onOpen={() => setErrorModalOpen(true)}
        // eslint-disable-next-line react/no-children-prop
        children={<PasswordNotification title="Invalid login or password" text="Please, check it and try again" />}
      />
    </>
  );
};

export default LoginForm;
