import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { GraphLegend } from 'modules/spaceLook/components';
import GraphBody from './components/GraphBody';
import theme from './VerticalBarGraph.module.scss';
import { GRAPHSCALES } from './constants';

const VerticalBarGraph = ({
  title,
  CustomHeader,
  secondSwitcher,
  scaleValues,
  data,
  config,
  customHeader,
  onSwitcherChange,
  returnPercentageSwitcher,
  onWeekSwitcherChange,
  label,
  pdfCustomHeader,
  isLiveLook,
  returnWeekSwitcher,
  isWeekGraph,
  shortSize,
  isDailyComparisons,
  isSummaryPdf,
  scale,
  onScaleChange,
  shouldRenderScale,
}) => {
  return (
    <Grid container spacing={2}>
      <Choose>
        <When condition={CustomHeader}>
          <CustomHeader />
        </When>

        <Otherwise>
          <Grid item style={{ marginRight: 'auto' }}>
            <Typography variant={pdfCustomHeader ? 'subtitle2' : 'h5'}>
              <Box fontWeight="fontWeightBold" component="span">
                {title}
              </Box>
            </Typography>
          </Grid>

          {!pdfCustomHeader && data && (
            <Grid>
              <Grid container style={{ display: 'flex', paddingLeft: '7px' }} alignItems="center">
                {customHeader && !pdfCustomHeader && (
                  <FormGroup>
                    <Grid component="label" container alignItems="center" spacing={2}>
                      <Grid item>Percentages</Grid>
                      <Grid item>
                        <FormControlLabel
                          control={<Switch checked={returnPercentageSwitcher} onChange={onSwitcherChange} />}
                          label="Integers"
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                )}
                {shouldRenderScale && (
                  <Grid item>
                    <FormControl style={{ minWidth: '150px' }}>
                      <InputLabel id="scale">Scale</InputLabel>
                      <Select
                        labelId="scale"
                        id="scale"
                        value={scale}
                        onChange={onScaleChange}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {GRAPHSCALES.map(({ period }) => (
                          <MenuItem value={period} key={period}>
                            {period}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {secondSwitcher && (
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel control={<Switch onChange={onWeekSwitcherChange} />} label={label || 'Week'} />
                    </FormGroup>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Otherwise>
      </Choose>

      <Grid className={pdfCustomHeader ? theme.pdfBody : theme.body} xs={12}>
        <GraphBody
          returnWeekSwitcher={returnWeekSwitcher}
          isDailyComparisons={isDailyComparisons}
          isWeekGraph={isWeekGraph}
          scaleValues={scaleValues}
          pdfCustomHeader={pdfCustomHeader}
          data={data}
          isSummaryPdf={isSummaryPdf}
          isSwitcherOn={returnPercentageSwitcher}
        />
      </Grid>
      <Grid item xs={12}>
        <GraphLegend shortSize={shortSize} pdfMode={pdfCustomHeader} isLiveLook={isLiveLook} config={config} />
      </Grid>
    </Grid>
  );
};

export default VerticalBarGraph;
