import React from 'react';
import { Grid } from '@material-ui/core';
import { PageHeader } from 'modules/shared/components';
import { ReportDataCards } from 'modules/liveLook/components';

import { pageTypes } from 'modules/liveLook/constants';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';
import styles from '../../LLPrintablePdfPage.module.scss';

const databoxesToEditorData = (databoxesSet) => {
  let order = 0;
  const output = [];

  const pushEditorData = (data, prefix) => {
    const keys = Object.keys(data);
    const values = Object.values(data);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = values[i];

      output.push({
        name: prefix + key,
        action: 'min',
        color: '#FFC0CB',
        sub_title: null,
        type_of_group: null,
        group_by_name: null,
        order: ++order,
        is_display_legend: true,
        data: value,
        output_type: 'single_value',
      });
    }
  };

  if (typeof databoxesSet?.occupancyDataboxes === 'object') {
    pushEditorData(databoxesSet.occupancyDataboxes, 'occ_');
  }
  if (typeof databoxesSet?.deskDataboxes === 'object') {
    pushEditorData(databoxesSet.deskDataboxes, 'desk_');
  }
  if (typeof databoxesSet?.breakDataboxes === 'object') {
    pushEditorData(databoxesSet.breakDataboxes, 'break_');
  }
  if (typeof databoxesSet?.meetsDataboxes === 'object') {
    pushEditorData(databoxesSet.meetsDataboxes, 'meet_');
  }

  return output;
};

const SummaryFloorPlan = ({ databoxes, databoxesSet, editableTextReport }) => {
  return (
    <div className={styles.pdfPage}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader pdfMode title="Executive Summary - Floor Plan" />
        </Grid>
      </Grid>
      <div className={styles.blockContainer}>
        <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.summary} />
      </div>

      <Grid item xs={12}>
        <PDFTextEditorContainer
          data={databoxesToEditorData(databoxesSet)}
          onSave={() => {}}
          text={editableTextReport}
        />
      </Grid>
    </div>
  );
};

export default SummaryFloorPlan;
