/* eslint-disable camelcase */
import { useReducer } from 'react';

import { requestStatuses } from 'modules/shared/constants';
import { SWITCH_GRAPH_DATA_TYPE } from 'actions/switchGraphDataType';
import { SET_DETAILS_PAGE_DATA } from 'actions/setDetailsPageData';
import { CHANGE_PROPERTY } from 'actions/changeProperty';
import { CHANGE_SORT_ORDER } from 'actions';
import { graphDataTypes, graphSortOptions } from 'components/Pages/SummaryPage/constants';

const initState = {
  requestStatus: requestStatuses.default,
  buildingsData: [],
  propertiesData: [],
  propertiesList: [],

  activeProperty: {},
  activeBuilding: [],

  graphDataType: graphDataTypes.deskCount,
  sortOrder: graphSortOptions.desc,
};

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SWITCH_GRAPH_DATA_TYPE:
      return {
        ...state,
        graphDataType: payload,
      };
    case CHANGE_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };

    case SET_DETAILS_PAGE_DATA.pending:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case SET_DETAILS_PAGE_DATA.success:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        buildingsData: payload.buildingsData,
        propertiesData: payload.propertiesData,
        propertiesList: payload.propertiesList,
        activeProperty: payload.propertiesData[0],
        activeBuilding: payload.buildingsData.filter(({ property_id }) => property_id === payload.propertiesData[0].id),
      };
    case SET_DETAILS_PAGE_DATA.failure:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };

    case CHANGE_PROPERTY:
      return {
        ...state,
        activeProperty: state.propertiesData.find((property) => property.id === payload),
        activeBuilding: state.buildingsData.filter(({ property_id }) => property_id === payload),
      };
    default:
      return state;
  }
};

const useDetailsPageReducer = () => useReducer(reducer, initState);

export default useDetailsPageReducer;
