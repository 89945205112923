/* eslint-disable camelcase */
import { createReducer } from '@reduxjs/toolkit';

import { FILTERS, weekday } from 'modules/liveLook/constants';
import { makeDefaultActiveFilters } from 'modules/shared/utils';
import { APPLY_FILTERS, FETCH_COMMON_DATA, RESET_FILTERS, RESET_STATE } from 'modules/liveLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  initFilters: null,
  activeFilters: null,
};

export default createReducer(initState, {
  [FETCH_COMMON_DATA.success]: (state, { payload: { buildings, floors, subtype, division, neighbourhood } }) => {
    const initFilters = [weekday];

    initFilters.push({
      field: FILTERS.building_id,
      values: buildings.map(({ building__property_name, building_id }) => ({
        label: building__property_name,
        value: building_id,
      })),
    });

    initFilters.push({
      field: FILTERS.floor,
      values: floors,
    });

    initFilters.push({
      field: FILTERS.subtype,
      values: subtype,
    });

    initFilters.push({
      field: FILTERS.division,
      values: division,
    });

    initFilters.push({
      field: FILTERS.neighbourhood,
      values: neighbourhood,
    });

    return {
      ...state,
      initFilters,
      activeFilters: makeDefaultActiveFilters(initFilters),
    };
  },
  [APPLY_FILTERS]: (state, { payload: { field, values } }) => ({
    ...state,
    activeFilters: state.activeFilters.map((filter) => (filter.field === field ? { field, values } : { ...filter })),
  }),
  [RESET_FILTERS]: (state) => ({
    ...state,
    activeFilters: makeDefaultActiveFilters(state.initFilters),
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
