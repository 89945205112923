/* eslint-disable func-names */
import { Chart } from 'react-chartjs-2';

const options = (setBarPosition) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    events: [],
    dataset: [{ categoryThickness: 120, barPercentage: 1 }],
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            minRotation: 0,
            fontSize: 12,
            fontStyle: 'bold',
            color: '#4D4D4D',
          },
        },
      ],
      yAxes: [
        {
          ticks: { min: 0, max: 100, display: false },
          gridLines: {
            display: false,
          },
        },
      ],
    },

    animation: {
      duration: 100,
      onComplete() {
        const chartInstance = this.chart;
        const { ctx } = chartInstance;
        ctx.textAlign = 'center';
        ctx.font = '9px Roboto';
        ctx.fillStyle = '#fff';

        Chart.helpers.each(
          this.data.datasets.forEach(function (dataset, i) {
            const meta = chartInstance.controller.getDatasetMeta(i);
            setBarPosition(meta?.data[0]?._model?.base);
            Chart.helpers.each(
              meta.data.forEach(function (bar, index) {
                const data = dataset.data[index];
                const yCoef = -5;
                ctx.textBaseline = 'middle';
                ctx.fillStyle = '#000';
                ctx.font = 'bold 10px Roboto';
                ctx.fillText(`${data}`, bar._model.x, bar._model.y + yCoef);
              }),
              this,
            );
          }),
          this,
        );
      },
    },
  };
};

export default options;
