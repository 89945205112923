/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography, Grid } from '@material-ui/core';
import { getEditGraphId } from 'modules/workLook/selectors';
import CloseIcon from '@material-ui/icons/Close';
import theme from './EditFieldsContainer.module.scss';
import EditCsvData from '../../CreateBlankReport/EditCsvData';

const EditFieldsContainer = React.forwardRef(({ onClose }) => {
  const { reportId } = useSelector(getEditGraphId);

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Grid item>
            <Typography variant="h5" className={theme.title}>
              Edit Report Fields
            </Typography>
          </Grid>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={theme.wrapper}>
        <EditCsvData isEditFields report={reportId} />
      </div>
    </div>
  );
});

export default EditFieldsContainer;
