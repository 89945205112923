import React from 'react';
import { Tooltip, Typography, Box, withStyles } from '@material-ui/core';

import filterConfig from '../../usageOverTime.utils';

const LINE_HEIGHT = 4;

const GraphLines = ({ classes, linesData, config, marginBottom, scaleMaxValue, isSwitcherOn }) => {
  const { newConfigArray } = filterConfig(isSwitcherOn, config);

  return newConfigArray.map(({ lineLabel, valueKey, color }) => {
    const TooltipContent = () => (
      <>
        <Typography variant="body1">
          <Box fontWeight="fontWeightMedium" component="span">
            {lineLabel}
          </Box>
        </Typography>
        <Typography variant="body1">{isSwitcherOn ? linesData[valueKey] : `${linesData[valueKey]}%`}</Typography>
      </>
    );

    // Relative line position to scale, in percents
    const position = (linesData[valueKey] / scaleMaxValue) * 100;

    return (
      <Tooltip
        key={lineLabel}
        arrow
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        title={<TooltipContent />}
      >
        <div
          className={classes.line}
          style={{
            bottom: `calc((100% - ${marginBottom + LINE_HEIGHT}px) / 100 * ${position} + ${marginBottom}px)`,
            backgroundColor: color,
          }}
        />
      </Tooltip>
    );
  });
};

export default withStyles((theme) => ({
  tooltip: {
    padding: '8px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  arrow: {
    color: `${theme.palette.grey[200]}`,
  },
  line: {
    height: `${LINE_HEIGHT}px`,
    width: '100%',
    position: 'absolute',
    left: '0',
    cursor: 'pointer',
    zIndex: '9',
    opacity: '0.7',
  },
}))(GraphLines);
