import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader, TextEditor } from 'modules/shared/components';
import {
  VerticalBarGraph,
  ReportDataCards,
  ByBuildingGraphHeader,
  LiveCounter,
  FilterList,
} from 'modules/liveLook/components';

import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import IFrameModal from 'modules/liveLook/components/iFrame';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';

import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import config from './config';

const DoorsByBuilding = ({
  building,
  onBuildingChange,
  data,
  scaleValues,
  databoxes,
  onSwitcherChange,
  switcher,
  editableTextReport,
  saveGraphsText,
  databoxesSet,
  returnPercentageSwitcher,
  activeBuilding,
  archive,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Occupancy" />
          </Grid>
          <Grid item xs={12}>
            <FilterList doorSensorData pageType="OCCUPANCY" subtypes={false} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={pageTypes.occupancy} />
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
          <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>
        <Grid>
          <AddToPDFCard
            type="By Building and Floor"
            sectionType="Occupancy"
            category={pdfCases.occupancyByBuilding}
            sectionOrder={sectionsOrder.second}
            typeOrder={sectionItemOrder.third}
            chartName={liveLookPagesNames.doorsByBuilding}
            data={{
              scaleValues,
              building,
              data,
              databoxes,
              editableTextReport,
              returnPercentageSwitcher,
              activeBuilding,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <VerticalBarGraph
          CustomHeader={() => (
            <ByBuildingGraphHeader
              title="Comparison by Building and Floor"
              building={building}
              onBuildingChange={onBuildingChange}
              customHeader
              switcher={switcher}
              onSwitcherChange={onSwitcherChange}
              isGraphScaleExist={!!scaleValues?.length}
            />
          )}
          returnPercentageSwitcher={returnPercentageSwitcher}
          data={data}
          scaleValues={scaleValues}
          config={config}
        />
      </Grid>

      <Grid item xs={12}>
        <TextEditor
          chartName={liveLookPagesNames.doorsByBuilding}
          data={databoxesToEditorData(databoxesSet)}
          // eslint-disable-next-line no-shadow
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
          graphType={GRAPH_TYPES.VERTICAL}
        />
      </Grid>
    </Grid>
  );
};

export default DoorsByBuilding;
