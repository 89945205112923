import { useSelector } from 'react-redux';
import { get } from 'lodash';

const useAuthSelector = (paths) => {
  return useSelector(({ auth }) => {
    const res = [];

    paths.forEach((path) => res.push(get(auth, path)));

    return res;
  });
};

export default useAuthSelector;
