import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/auth/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_PARTNER_COMPANIES = createAsyncAction(createAction('FETCH_PARTNER_COMPANIES'));

const fetchPartnerCompanies = (dispatch) => {
  dispatch(FETCH_PARTNER_COMPANIES.pending());

  API.get(ENDPOINTS.auth.partnerCompanyList)
    .then(({ data }) => dispatch(FETCH_PARTNER_COMPANIES.success(data)))
    .catch(() => dispatch(FETCH_PARTNER_COMPANIES.failure()));
};

export default fetchPartnerCompanies;
