/* eslint-disable func-names */
import { Chart } from 'react-chartjs-2';

const optionsPdf = (isAllDataZeros) => {
  return {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    events: [],
    animation: {
      onComplete() {
        const chartInstance = this.chart;
        const { ctx } = chartInstance;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.verticalAlign = 'center';
        ctx.font = '10px normal, Roboto';
        const { datasets } = this.data;

        Chart.helpers.each(
          datasets.forEach(function (dataset, i) {
            const meta = chartInstance.controller.getDatasetMeta(i);
            Chart.helpers.each(
              meta.data.forEach(function (bar, index) {
                const data = dataset.data[index];
                if (isAllDataZeros) {
                  ctx.fillStyle = '#000';
                  ctx.fillText(`${data}`, bar._model.x, bar._model.y - 10);
                }
                if (data < 4) return;
                ctx.fillStyle = '#fff';
                ctx.fillText(`${data}%`, bar._model.x, bar._model.y + 10);
              }),
              this,
            );
          }),
          this,
        );
      },
    },
    dataset: [{ categoryThickness: 31, categoryPercentage: 1, barPercentage: 0.9 }],
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#4D4D4D',
            fontSize: 11,
            fontStyle: 'normal',
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: { min: 0, max: 100, display: false },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
};

export default optionsPdf;
