import { createReducer } from '@reduxjs/toolkit';

import { SHOW_MODAL, HIDE_MODAL } from 'modules/modalsSystem/actions';

const initState = {
  modalCode: null,
  modalProps: {},
};

const modalsReducer = createReducer(initState, {
  [SHOW_MODAL]: (state, { payload: { modalCode, modalProps } }) => ({
    ...state,
    modalCode,
    modalProps,
  }),
  [HIDE_MODAL]: () => ({ ...initState }),
});

export default modalsReducer;
