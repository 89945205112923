/* eslint-disable camelcase */
import { reportPageTypes } from 'modules/spaceLook/constants';

const MeetsAndBreakSoloUse = ({ textData: { percent_total_1_people_meet, percent_count_1_people_meet }, pageType }) => {
  const isMeet = pageType === reportPageTypes.meetings;

  return `${isMeet ? percent_total_1_people_meet : percent_count_1_people_meet}% of all 
     ${isMeet ? 'meeting rooms' : 'breakout space'} use was solo use.`;
};

export default MeetsAndBreakSoloUse;
