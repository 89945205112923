/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable array-callback-return */
import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { pdfCases } from 'modules/liveLook/constants';
import { makeStyles } from '@material-ui/core';
import { sortPdfReports } from 'modules/liveLook/utils';
import DoorsOverTime from './components/DoorsOverTime';
import DoorsDaily from './components/DoorsDaily';
import DoorsByBuilding from './components/DoorsByBuilding';
import DesksOverTime from './components/DesksOverTime';
import DesksSpaceComparison from './components/DesksSpaceComparison';
import DesksByBuilding from './components/DesksByBuilding';
import DesksByDivision from './components/DesksByDivision';
import DesksAmberState from './components/DesksAmberState';
import MeetsOverTime from './components/MeetsOverTime';
import MeetsSpaceComparison from './components/MeetsSpaceComparison';
import MeetsSpaceUsageBySize from './components/MeetsSpaceUsageBySize';
import BreakoutOverTime from './components/BreakoutOverTime';
import BreakoutSpaceComparison from './components/BreakoutSpaceComparison';
import BreakoutSpaceUsageBySize from './components/BreakoutSpaceUsageBySize';
import SummaryKeyFindings from './components/SummaryKeyFindings';
import SummaryOverallOccupancy from './components/SummaryOverallOccupancy';
import SummaryFloorPlan from './components/SummaryFloorPlan';
import SummaryKeyFindingsMeet from './components/SummaryKeyFindingsMeet/SummaryKeyFindingsMeet';
import SummaryKeyFindingsBreak from './components/SummaryKeyFindingsBreak/SummaryKeyFindingsBreak';
import SummaryKeyFindingsDesks from './components/SummaryKeyFindingsDesks/SummaryKeyFindingsDesks';
import SummaryKeyFindingsOccupancy from './components/SummaryKeyFindingsOccupancy/SummaryKeyFindingsOccupancy';
import CombinedTeamZoneOccupancy from './components/CombinedTeamZoneOccupancy/CombinedTeamZoneOccupancy';

import styles from './LLPrintablePdfPage.module.scss';
import { ReportSubTitlePage, ReportTitlePage } from '../../shared';
import { PAGE_HEIGHT_IN_PIXELS } from './constants/pdfPageSizes';

const useStyles = makeStyles(() => ({
  image: {
    height: '26px',
    backgroundSize: 'cover',
  },
}));
const LLPrintablePdfPage = ({ pdfData, companyName, companyLogo, reportName }) => {
  const containerRef = useRef(null);
  const [numbersOfPages, setNumbersOfPages] = useState([]);
  const [mainContainerHeight, setMainContainerHeight] = useState(0);
  const { data } = pdfData;

  const sortedData = sortPdfReports(data);
  const onContainerResize = useCallback((newState) => setMainContainerHeight(newState), []);

  useEffect(() => {
    if (!containerRef?.current) return;
    const amountOfPages = Math.round(containerRef.current.clientHeight / PAGE_HEIGHT_IN_PIXELS);
    const arr = [];
    for (let i = 1; i <= amountOfPages; i += 1) {
      arr.push(i * PAGE_HEIGHT_IN_PIXELS - 35 + i / 15);
    }
    setNumbersOfPages(arr);
  }, [containerRef, mainContainerHeight]);
  const classes = useStyles({ companyLogo });
  return (
    <div className={styles.container} ref={containerRef}>
      <div>
        <div className={styles.servicePage}>
          <ReportTitlePage
            subTitle={reportName}
            title={companyName}
            textColor={undefined}
            backgroundColor={undefined}
          />
        </div>
        {numbersOfPages.map((elementPosition, index) => {
          return (
            <>
              <div className={styles.footerLogo} style={{ top: `${elementPosition}px` }}>
                <img className={classes.image} src={companyLogo} alt="company logo" />
              </div>
              <div key={elementPosition} className={styles.footer} style={{ top: `${elementPosition + 5}px` }}>
                {index + 1}
              </div>
            </>
          );
        })}
        {sortedData.map((item, index) => {
          const payload = item?.data;

          const textData = payload?.editableTextReport;
          const structured = textData ? JSON.parse(textData) : null;

          switch (item.category) {
            case pdfCases.occupancyOverTime: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <div className={styles.occupancyOverTime}>
                    <DoorsOverTime workingHours={item.workingHours} {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.occupancyDaily: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <DoorsDaily {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.occupancyByBuilding: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <DoorsByBuilding {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.deskOverTime: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="primary.dark" />
                    </div>
                  </If>
                  <div className={styles.occupancyOverTime}>
                    <DesksOverTime {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.deskSpaceComparison: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="primary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <DesksSpaceComparison {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.deskByBuilding: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="primary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <DesksByBuilding {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.deskByDivision: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="primary.dark" />
                    </div>
                  </If>
                  <DesksByDivision {...payload} />
                </Fragment>
              );
            }
            case pdfCases.desksAmberState: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="primary.dark" />
                    </div>
                  </If>
                  <DesksAmberState {...payload} />
                </Fragment>
              );
            }
            case pdfCases.meetingOverTime: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <div className={styles.occupancyOverTime}>
                    <MeetsOverTime {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.meetingSpaceComparison: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <MeetsSpaceComparison {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.meetingSpaceUsage: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <MeetsSpaceUsageBySize {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.breakoutOverTime: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="primary.dark" />
                    </div>
                  </If>
                  <div className={styles.occupancyOverTime}>
                    <BreakoutOverTime {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.breakoutSpaceComparison: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="primary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <BreakoutSpaceComparison {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.breakoutSpaceUsage: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="primary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <BreakoutSpaceUsageBySize {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.keyFindings: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <SummaryKeyFindings onContainerResize={onContainerResize} {...payload} />
                </Fragment>
              );
            }
            case pdfCases.overallOcuppancy: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <SummaryOverallOccupancy {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.keyFindingsFloor: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <div className={styles.pdfPage}>
                    <SummaryFloorPlan {...payload} />
                  </div>
                </Fragment>
              );
            }
            case pdfCases.keyFindingsMeeting: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <SummaryKeyFindingsMeet onContainerResize={onContainerResize} {...payload} />
                </Fragment>
              );
            }
            case pdfCases.keyFindingsBreakout: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <SummaryKeyFindingsBreak onContainerResize={onContainerResize} {...payload} />
                </Fragment>
              );
            }
            case pdfCases.keyFindingsDesks: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <SummaryKeyFindingsDesks onContainerResize={onContainerResize} {...payload} />
                </Fragment>
              );
            }
            case pdfCases.keyFindingsOccupancy: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <SummaryKeyFindingsOccupancy onContainerResize={onContainerResize} {...payload} />
                </Fragment>
              );
            }
            case pdfCases.combinedOccupancy: {
              return (
                <Fragment key={item.id}>
                  <If condition={item.shouldRenderSection}>
                    <div className={styles.servicePage}>
                      <ReportSubTitlePage title={item.sectionType} backgroundColor="secondary.dark" />
                    </div>
                  </If>
                  <CombinedTeamZoneOccupancy type={item.type} structured={structured} {...payload} />
                </Fragment>
              );
            }
          }
        })}
      </div>
    </div>
  );
};

export default LLPrintablePdfPage;
