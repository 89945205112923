/* eslint-disable react/no-children-prop */
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import theme from './ReportTitleSkeleton.module.scss';

const ReportTitleSkeleton = () => (
  <div className={theme.container}>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={2} className={theme.header}>
          <Grid item xs={12}>
            <Skeleton variant="rect" width={150}>
              <Typography variant="h3" children="Header" />
            </Skeleton>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width={400}>
              <Typography variant="h5" children="Subheader" />
            </Skeleton>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width={400}>
              <Typography variant="h5" children="PublicationDate" />
            </Skeleton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Skeleton variant="rect" width={150}>
          <Typography variant="h6" children="Filters title" />
        </Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={7}>
            <Skeleton variant="rect" width={600} height={32} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width={800}>
              <Typography variant="body1" children="Filters text" />
            </Skeleton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className={theme.btns} spacing={1}>
          <Grid item>
            <Skeleton variant="rect" width={83} height={24} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={157} height={24} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={127} height={24} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={91} height={24} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={100} height={24} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export default ReportTitleSkeleton;
