const DesksUsageBandingZero = ({ graphData, usageBandingType }) => {
  const totalDesks = Object.values(graphData).reduce((a, b) => a + b, 0);
  const zeroQuantity = graphData[0];
  const zeroPercent = Math.round((zeroQuantity / totalDesks) * 100);

  const occupiedOrUtilised = usageBandingType === 'OCCUPIED' ? 'occupied' : 'utilised';

  return ` Of the ${Math.floor(totalDesks)} desks, ${Math.floor(zeroQuantity)} (${Math.floor(zeroPercent)}%) 
      were never observed as being ${occupiedOrUtilised} during the survey.`;
};

export default DesksUsageBandingZero;
