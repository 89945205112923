/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Input,
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  FormControl,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 4;
const INPUT_WIDTH = 250;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: INPUT_WIDTH,
    maxWidth: INPUT_WIDTH,
    marginLeft: '16px',
  },
  menuPaper: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: INPUT_WIDTH,
  },
  menuItem: {
    maxHeight: `${ITEM_HEIGHT}px`,
  },
}));

const FieldsSelect = ({ summaryFields, disabled, value, onSelectChange, onSelectClose }) => {
  const classes = useStyles();

  return (
    <FormControl
      disabled={disabled}
      classes={{
        root: classes.formControl,
      }}
    >
      <InputLabel children="Summary fields" />
      <Select
        multiple
        value={value}
        onClose={onSelectClose}
        onChange={onSelectChange}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={{
          PaperProps: {
            className: classes.menuPaper,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          variant: 'menu',
          getContentAnchorEl: null,
        }}
      >
        {summaryFields.map((field) => (
          <MenuItem key={field} value={field} dense classes={{ root: classes.menuItem }}>
            <Checkbox checked={value.indexOf(field) > -1} color="primary" />
            <ListItemText primary={field} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FieldsSelect;
