import { useMemo } from 'react';

import toCombinedOccTimeGraphData from '../LLPrintablePdfPage/components/CombinedTeamZoneOccupancy/data.utils.pdf';
import { setDataGroupedByDays } from '../utils/setDataForOverTime';
import { setScaleValues, toLondonTimeZone } from '../utils';
import { isInPercentages } from '../utils/common';

const useGetCombinedData = ({ data, scale, returnPercentageSwitcher, occupancyDataboxes, value }) => {
  const dataGroupedByDaysCombined = setDataGroupedByDays(data, scale, toLondonTimeZone);

  const peakCombinedOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        occupancyDataboxes,
        occupancyDataboxes?.max_combined_occupancy,
        occupancyDataboxes?.percentage_max_combined_occupancy,
      ),
    [returnPercentageSwitcher, occupancyDataboxes],
  );

  const avgCombinedOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        occupancyDataboxes,
        occupancyDataboxes?.avg_combined_occupancy,
        occupancyDataboxes?.percentage_avg_combined_occupancy,
      ),
    [returnPercentageSwitcher, occupancyDataboxes],
  );

  const { niceMax, scaleValues } = setScaleValues(peakCombinedOccupancy);

  const linesDataCombined = {
    peakOccupancy: peakCombinedOccupancy,
    avgOccupancy: avgCombinedOccupancy,
  };

  const graphDataCombined = toCombinedOccTimeGraphData(
    {
      sourceData: dataGroupedByDaysCombined,
      valueType: value,
      scale,
      returnPercentageSwitcher,
      niceMax,
    },
    false,
  );
  return { scale: scaleValues, linesDataCombined, graphDataCombined };
};

export default useGetCombinedData;
