import { createReducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { requestStatuses } from 'modules/shared/constants';
import { setFilterOption, toUrlParams } from 'modules/spaceLook/utils';

import {
  FETCH_APPLIED_FILTERS,
  SET_START_DATE,
  SET_END_DATE,
  RESET_REPORT_DATA,
  SET_FILTER_OPTION,
  APPLY_FILTERS,
  RESET_FILTERS,
  RESET_SHOULD_UPLOAD,
} from 'modules/spaceLook/actions';

const sharedFilters = {
  from_date: '',
  to_date: '',
  day_of_week: [],
  floor: [],
  space_type: [],
  building: [],
};

const initState = {
  requestStatus: requestStatuses.default,
  urlParams: {
    combined: '',
    desks: '',
    meetings: '',
    breakout: '',
  },
  selected: {
    combined: { ...sharedFilters, division: [], department: [] },
    desks: { ...sharedFilters, division: [], department: [] },
    meetings: { ...sharedFilters },
    breakout: { ...sharedFilters },
  },
  applied: {
    combined: { ...sharedFilters, division: [], department: [] },
    desks: { ...sharedFilters, division: [], department: [] },
    meetings: { ...sharedFilters },
    breakout: { ...sharedFilters },
  },
  shouldUpload: false,
};

const createDateRangeAction = (actionName, dataKey) => ({
  [actionName]: (state, { payload, initFilters }) => {
    const isInit = (initFilters.combined && initFilters.combined[dataKey] === payload) || !initFilters.combined;

    const combined = { ...state.selected.combined, [dataKey]: isInit ? '' : payload };
    const desks = { ...state.selected.desks, [dataKey]: isInit ? '' : payload };
    const meetings = { ...state.selected.meetings, [dataKey]: isInit ? '' : payload };
    const breakout = { ...state.selected.breakout, [dataKey]: isInit ? '' : payload };

    return {
      ...state,
      selected: {
        ...state.selected,
        combined,
        desks,
        meetings,
        breakout,
      },
      urlParams: {
        ...state.urlParams,
        combined: toUrlParams(combined),
        desks: toUrlParams(desks),
        meetings: toUrlParams(meetings),
        breakout: toUrlParams(breakout),
      },
    };
  },
});

const activeFiltersReducer = createReducer(initState, {
  /** SELECTED */
  ...createDateRangeAction(SET_START_DATE, 'from_date'),
  ...createDateRangeAction(SET_END_DATE, 'to_date'),
  [SET_FILTER_OPTION]: (state, { payload: { value, filterCategory }, availableFilters }) => {
    const combined = setFilterOption(
      availableFilters.combined,
      cloneDeep(state.selected.combined),
      value,
      filterCategory,
    );
    const desks = setFilterOption(availableFilters.desks, cloneDeep(state.selected.desks), value, filterCategory);
    const meetings = setFilterOption(
      availableFilters.meetings,
      cloneDeep(state.selected.meetings),
      value,
      filterCategory,
    );
    const breakout = setFilterOption(
      availableFilters.breakout,
      cloneDeep(state.selected.breakout),
      value,
      filterCategory,
    );

    return {
      ...state,
      selected: {
        ...state.selected,
        combined,
        desks,
        meetings,
        breakout,
      },
      urlParams: {
        ...state.urlParams,
        combined: toUrlParams(combined),
        desks: toUrlParams(desks),
        meetings: toUrlParams(meetings),
        breakout: toUrlParams(breakout),
      },
    };
  },
  [RESET_FILTERS]: (state) => ({
    ...state,
    urlParams: initState.urlParams,
    selected: initState.selected,
    applied: initState.applied,
    shouldUpload: true,
  }),

  /** APPLIED */
  [FETCH_APPLIED_FILTERS.pending]: (state) => ({
    ...state,
    requestStatus: requestStatuses.pending,
  }),
  [FETCH_APPLIED_FILTERS.success]: (state, { payload }) => {
    if (!payload) {
      return {
        ...initState,
        requestStatus: requestStatuses.success,
      };
    }

    const filterHistory = JSON.parse(payload);

    return {
      ...state,
      requestStatus: requestStatuses.success,
      selected: filterHistory,
      applied: filterHistory,
      urlParams: {
        combined: toUrlParams(filterHistory.combined),
        desks: toUrlParams(filterHistory.desks),
        meetings: toUrlParams(filterHistory.meetings),
        breakout: toUrlParams(filterHistory.breakout),
      },
    };
  },
  [FETCH_APPLIED_FILTERS.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
  }),
  [APPLY_FILTERS]: (state) => ({
    ...state,
    applied: state.selected,
    shouldUpload: true,
  }),
  [RESET_SHOULD_UPLOAD]: (state) => ({
    ...state,
    shouldUpload: false,
  }),

  [RESET_REPORT_DATA]: () => ({ ...initState }),
});

export default activeFiltersReducer;
