/* eslint-disable camelcase */
import { piePalette } from 'modules/spaceLook/constants';
import isPieValueToOmit from './isPieValueToOmit';

const getRandomColor = () => {
  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  if (piePalette.includes(randomColor)) {
    return getRandomColor();
  }
  return randomColor;
};

export const toPieChartData = (data) => {
  const pieChartData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  };

  const pieData = Array.isArray(data) ? data.flat() : data;

  pieData.forEach(({ result, percent_without_vacant, percent_without_vacant_and_sol }, i) => {
    if (isPieValueToOmit(result)) return;

    const label = result.match(/In Use \((.*)\)/i);

    pieChartData.labels.push((label && label[1]) || result);
    pieChartData.datasets[0].data.push(percent_without_vacant || percent_without_vacant_and_sol);
    pieChartData.datasets[0].backgroundColor.push(piePalette[i] || getRandomColor());
  });

  return pieChartData;
};

export default toPieChartData;
