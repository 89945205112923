import axios from 'axios';

import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_APPLIED_FILTERS = createAsyncAction(createAction('FETCH_APPLIED_FILTERS'));

const fetchAppliedFilters = (dispatch, id) => {
  dispatch(FETCH_APPLIED_FILTERS.pending());

  const token = isLogged();
  const data = { url: id };
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  axios
    .post(ENDPOINTS.appliedFilters, data, config)
    // eslint-disable-next-line no-shadow
    .then(({ data }) => dispatch(FETCH_APPLIED_FILTERS.success(data.parameters)))
    .catch(() => dispatch(FETCH_APPLIED_FILTERS.failure()));
};

export default fetchAppliedFilters;
