import React from 'react';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import {
  PageHeader,
  FilterHandler,
  SpaceUsageByTypeGraph,
  ReportText,
  ReportDataCards,
  ReportFilter,
} from 'modules/spaceLook/components';

import { reportPageTypes } from 'modules/spaceLook/constants';
import { setStackedGraphColumnsData, setupBuildingFloorData, splitOneGraph } from 'utils';
import { useHasScroll } from 'modules/shared/hooks';

const BreakoutSpaceComparison = ({ isLoading, pdfMode, databoxes, peakAndAvgUtilData }) => {
  const usageByTypeData = setStackedGraphColumnsData(
    setupBuildingFloorData(peakAndAvgUtilData, null, 'location_id__type'),
  );
  const splitUsageByTypeData = pdfMode ? splitOneGraph(usageByTypeData, 5) : [usageByTypeData];
  const usageByTypeDatasets = splitUsageByTypeData.map((graph) => ({
    labels: graph.labels,
    datasets: [
      {
        label: 'Avg in Use, %',
        backgroundColor: '#00aad2',
        data: graph.occ_percent_avg,
      },
      {
        label: 'Max in Use, %',
        backgroundColor: '#0D6F85',
        data: graph.occ_percent_max,
      },
    ],
  }));
  const scroll = useHasScroll();

  return usageByTypeDatasets.map((data, i) => (
    <div ref={scroll.ref} key={i} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Breakout Space Comparison" text="Breakout Space Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.breakout}>
              <ReportFilter pageType={reportPageTypes.breakout} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.breakout} cropped={!pdfMode} />
        </Grid>

        <Grid item xs={12}>
          <SpaceUsageByTypeGraph data={data} title="Peak and Average by Type" />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={['singleSpaceUsage']}
            repeat={{
              singleSpaceUsage: peakAndAvgUtilData.length,
            }}
            databoxes={databoxes}
            locationsData={peakAndAvgUtilData}
            pageType={reportPageTypes.breakout}
            scrollRef={scroll}
            isBreakout
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default BreakoutSpaceComparison;
