import { requestStatuses } from 'modules/shared/constants';
import {
  FETCH_DOORS_DAILY_COMPARISONS,
  FETCH_OCCUPANCY_DATABOXES,
  SET_GRAPH_SWITCHER,
  SET_GRAPH_WEEK_SWITCHER,
  FETCH_DOORS_OVER_TIME,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
} from 'modules/liveLook/actions';
import { GRAPH_SWITCHER, WEEK_GRAPH_SWITCHER } from 'modules/liveLook/constants';

export const initialState = {
  requestStatus: requestStatuses.default,
  weeklyRequestStatus: requestStatuses.default,
  databoxRequestStatus: requestStatuses.default,
  textReportRequestStatus: requestStatuses.default,
  data: null,
  capacity: null,
  weeklyData: [],
  weeklyCapacity: null,
  weeklyNominalCapacity: null,
  occupancyDataboxes: [],
  returnPercentageSwitcher: GRAPH_SWITCHER.integers,
  returnWeekSwitcher: WEEK_GRAPH_SWITCHER.week,
  editableTextReport: '',
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_DOORS_DAILY_COMPARISONS.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_DOORS_DAILY_COMPARISONS.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload.data,
        capacity: payload.capacity,
      };
    case FETCH_DOORS_DAILY_COMPARISONS.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    case FETCH_DOORS_OVER_TIME.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_DOORS_OVER_TIME.success().type:
      return {
        ...state,
        weeklyRequestStatus: requestStatuses.success,
        weeklyData: payload.data,
        weeklyCapacity: payload.capacity,
        weeklyNominalCapacity: payload.nominal_capacity,
      };
    case FETCH_DOORS_OVER_TIME.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    case FETCH_OCCUPANCY_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_OCCUPANCY_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        occupancyDataboxes: payload,
      };
    case FETCH_OCCUPANCY_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SET_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcher: payload,
      };
    case SET_GRAPH_WEEK_SWITCHER().type:
      return {
        ...state,
        returnWeekSwitcher: payload,
      };
    default:
      throw new Error();
  }
}

export default reducer;
