import { RESET_REPORT_ERROR } from 'modules/shared/actions';

const initState = {
  reportError: null,
};

const errorReducer = (state = initState, { type, payload }) => {
  if (type === RESET_REPORT_ERROR().type) return initState;

  const reportError = payload && payload.reportError;

  return reportError && !state.reportError ? { ...state, reportError } : state;
};

export default errorReducer;
