import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isDefault } from 'modules/shared/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  getAppliedFilters,
  getDesksByDivAndDepData,
  getSelectedFilters,
  getPdfMode,
} from 'modules/spaceLook/selectors';
import {
  fetchDesksDataboxes,
  fetchDesksDivisionGraphData,
  fetchDesksDepartmentGraphData,
} from 'modules/spaceLook/actions';
import { LoadingCondition } from 'modules/spaceLook/components';

import DesksByDivAndDep from './DesksByDivAndDep';

const DesksByDivAndDepContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fetchStart, setFetchStart] = useState(false);
  const pdfMode = useSelector(getPdfMode);
  const { dataRequestStatuses, data, shouldRefetchData } = useSelector(getDesksByDivAndDepData);
  const { selectedFiltersUrlParams } = useSelector((store) => getSelectedFilters(store, 'desks'));
  const { appliedFiltersRequestStatus } = useSelector(getAppliedFilters);

  const isNoData = Object.values(data).some((dataItem) => !dataItem);
  const isLoading = Object.values(dataRequestStatuses).some((status) => status === requestStatuses.pending);
  const isFiltersHistoryLoading = [requestStatuses.default, requestStatuses.pending].includes(
    appliedFiltersRequestStatus,
  );

  useEffect(() => {
    if (isFiltersHistoryLoading) return;

    if (isDefault(dataRequestStatuses.databoxes) || shouldRefetchData.databoxes) {
      fetchDesksDataboxes(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.divisionData) || shouldRefetchData.divisionData) {
      fetchDesksDivisionGraphData(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.departmentData) || shouldRefetchData.departmentData) {
      fetchDesksDepartmentGraphData(dispatch, id, selectedFiltersUrlParams);
    }
    setFetchStart(true);
  }, [
    appliedFiltersRequestStatus,
    shouldRefetchData.databoxes,
    shouldRefetchData.divisionData,
    shouldRefetchData.departmentData,
    isFiltersHistoryLoading,
    dataRequestStatuses.databoxes,
    dataRequestStatuses.divisionData,
    dataRequestStatuses.departmentData,
    dispatch,
    id,
    selectedFiltersUrlParams,
  ]);

  return (
    <LoadingCondition isLoading={isLoading} isNoData={isNoData} fetchStart={fetchStart}>
      <DesksByDivAndDep isLoading={isLoading} pdfMode={pdfMode} {...data} />
    </LoadingCondition>
  );
};

export default DesksByDivAndDepContainer;
