const MOCKED_DATA = {
  mapLabels: {
    country: 'Country',
    region: 'Region',
    city: 'City',
    address: 'Address',
    postal_code: 'Postal code',
  },
};

export default MOCKED_DATA;
