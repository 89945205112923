import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_COMMON_DATA = createAsyncAction(createAction('FETCH_COMMON_DATA'));

const fetchCategories = (dispatch, companyId, isCanceled, archive) => {
  dispatch(FETCH_COMMON_DATA.pending());

  const url = ENDPOINTS.liveLook.commonData
    .replace(':companyId', companyId)
    .concat(archive ? `?archive_id=${archive}` : '');

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_COMMON_DATA.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_COMMON_DATA.failure());
    });
};

export default fetchCategories;
