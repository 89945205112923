import { createReducer } from '@reduxjs/toolkit';

import { requestStatuses } from 'modules/shared/constants';
import { FETCH_DETAILS_DATA, RESET_REPORT_DATA } from 'modules/spaceLook/actions';

const initState = {
  requestStatus: requestStatuses.default,
  detailsData: null,
};

const summaryReducer = createReducer(initState, {
  [FETCH_DETAILS_DATA.pending]: (state) => ({ ...state, requestStatus: requestStatuses.pending }),
  [FETCH_DETAILS_DATA.success]: (state, { payload }) => ({
    ...state,
    requestStatus: requestStatuses.success,
    detailsData: payload,
  }),
  [FETCH_DETAILS_DATA.failure]: (state) => ({ ...state, requestStatus: requestStatuses.failure }),

  [RESET_REPORT_DATA]: () => ({
    ...initState,
  }),
});

export default summaryReducer;
