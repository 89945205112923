/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-children-prop */
import React from 'react';

import { Typography, makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import { SET_DETAIL_DATA } from 'modules/workLook/actions';
import { useDispatch } from 'react-redux';
import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';
import './largeDataCard.scss';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { formatDateTime } from '../EditableTable/formatDateTime';

const getDataCard = (key, card) => {
  if (['date', 'time'].includes(key)) return formatDateTime(key, card);
  return card;
};

const useStyles = makeStyles((theme) => ({
  buildingType: {
    color: '#9e9e9e',
    marginBottom: 0,
    fontSize: '12px',
  },
  buildingName: {
    color: theme.palette.primary.dark,
    marginBottom: 0,
  },
  additionalInfo: {
    textAlign: 'center',
    marginBottom: 0,
    color: '#9e9e9e',
  },
}));

const getCardsInfo = (data) => {
  if (isEmpty(data)) return [];
  return Object.keys(data[0]);
};

const LargeDataCard = ({
  data,
  showTitle,
  widgets,
  isLoading,
  position,
  isPdf,
  typeOfGroup,
  title,
  isPublished,
  subtitle,
  graphId,
  isModal,
  type,
}) => {
  const cardsKeys = getCardsInfo(data);
  const classes = useStyles();
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  const dispatch = useDispatch();
  const checkPosition = ['7', '8', '9'].includes(position);
  const checkDashboardPosition = ['10', '11'].includes(position);
  const getGraphHeight = isPdf
    ? checkPosition
      ? '660px'
      : '340px'
    : checkPosition
    ? '720px'
    : checkDashboardPosition
    ? '500px'
    : '360px';

  const onClick = ({ detail }, id) => {
    if (detail !== 2 || isEmpty(widgets)) {
      return;
    }

    dispatch(showModal(modalCodes.WL_MODAL_DETAILS));
    dispatch(
      SET_DETAIL_DATA({
        widgetModal: widgets,
        recordId: id,
      }),
    );
  };

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && isEmpty(data)}>
        <Paper className="container" elevation={2}>
          <GraphHeader
            display={false}
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={subtitle}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper elevation={2} className="cardLargeContainer" style={{ maxHeight: getGraphHeight }}>
          <GraphHeader
            display={false}
            showTitle={showTitle}
            graphId={graphId}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          {data.map((cardData) => {
            return (
              <div
                key={cardData.id}
                role="button"
                tabIndex={0}
                className={isEmpty(widgets) ? 'largeCard' : 'largeCardWidgets'}
                onClick={(e) => onClick(e, cardData.id)}
              >
                <div className="largeCardInfoContainer">
                  {/* <Typography variant="body1" component="span" classes={{ root: classes.buildingType }}>
                    {isEmpty(data) ? '' : `id: ${cardData.id}`}
                  </Typography> */}

                  <Typography variant="h5" component="span" classes={{ root: classes.buildingName }}>
                    {isEmpty(data) ? '' : getDataCard(cardsKeys[1], cardData[cardsKeys[1]])}
                  </Typography>

                  {cardsKeys.slice(2).map((key) => {
                    return (
                      <div key={key}>
                        <Typography variant="body1" component="span" classes={{ root: classes.additionalInfo }}>
                          {isEmpty(data)
                            ? ''
                            : `${key.replace('_', ' ').toLowerCase()}: ${getDataCard(key, cardData[key])}`}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Paper>
      </Otherwise>
    </Choose>
  );
};

export default LargeDataCard;
