const PRODUCT_FIELDS = {
  id: '_id',
  name: '_name',
  thumbnail: '_thumbnail',
  assets: '_asset_count',
  image: '_image',
};

const ASSET_FIELDS = {
  id: '_id',
  thumbnail: '_thumbnail',
  status: '_status',
  tags: '_tags',
  checkbox: 'isChecked',
};

export { PRODUCT_FIELDS, ASSET_FIELDS };
