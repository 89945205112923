/* eslint-disable react/no-children-prop */
/* eslint-disable react/button-has-type */
import React from 'react';
import cn from 'classnames';
import { Grid, Typography, Button } from '@material-ui/core';

import crossIcon from 'assets/images/icons/white-cross.svg';

import theme from './DownloadsSnackbar.module.scss';

const DownloadsSnackbar = ({ open, onClose }) => (
  <div className={cn(theme.container, { [`${theme.isOpen}`]: open })}>
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Typography variant="body1" style={{ color: 'white' }}>
          Please wait, your report will be ready in 5 minutes. Then reload the page.
        </Typography>
      </Grid>
      <Grid item xs={2} align="right">
        <button
          onClick={onClose}
          className={theme.closeBtn}
          children={<img className={theme.closeIcon} src={crossIcon} alt="close" />}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => window.location.reload()}
          children="Reload"
          size="small"
          variant="contained"
          style={{ backgroundColor: 'white' }}
        />
      </Grid>
    </Grid>
  </div>
);

export default DownloadsSnackbar;
