const FILTERS_AL = {
  Zone: 'Zone',
  Floor: 'Floor',
  Building: 'Building',
  Manufacturer: 'Manufacturer',
  Category: 'Category',
  'Product Size': 'Product Size',
  Condition: 'Condition',
  'Colour / Finish': 'Colour / Finish',
  Comments: 'Comments',
  Location: 'Location',
  Status: 'Status',
  Tags: 'Tags',
};
export default FILTERS_AL;
