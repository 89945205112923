import React from 'react';
import { Grid } from '@material-ui/core';

import config from 'modules/workLook/components/PdfReportsPageSelector/config';

import { PageHeader } from 'modules/shared/components';
import { PdfReportsPageSelector } from 'modules/workLook/components';

import { Current, History } from './components';

const PdfReportsPage = ({ pageType }) => (
  <Grid container spacing={4}>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title="Print Report" />
        </Grid>
        <Grid item xs={12}>
          <PdfReportsPageSelector />
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <Choose>
        <When condition={pageType === config.current.pageType}>
          <Current />
        </When>
        <When condition={pageType === config.history.pageType}>
          <History />
        </When>
      </Choose>
    </Grid>
  </Grid>
);

export default PdfReportsPage;
