const GRAPH_COLORS = [
  '#80D4E8',
  '#7FA0A8',
  '#004352',
  '#00AAD2',
  '#B2E9F5',
  '#542965',
  '#9A50B7',
  '#CE93D8',
  '#C4043F',
  '#F9C5D6',
  '#F6A000',
  '#FFE2AD',
];

export default GRAPH_COLORS;
