import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader, TextEditor } from 'modules/shared/components';
import { FilterList, VerticalBarGraph, ReportDataCards, LiveCounter } from 'modules/liveLook/components';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';
import IFrameModal from 'modules/liveLook/components/iFrame';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import graphDeskConfig from '../SummaryKeyFindings/graphDeskConfig';

const DesksAmberState = ({
  databoxes,
  editableTextReport,
  saveGraphsText,
  databoxesSet,
  deskGraphData,
  returnPercentageSwitcherDeskGraph,
  deskGraphScaleValues,
  deskGraphOnSwitcherChange,
  textVariables,
  archive,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Desk Amber State" />
          </Grid>
          <Grid item xs={12}>
            <FilterList pageType="DESKS" subtypes />
          </Grid>
          <Grid item xs={12}>
            <ReportDataCards databoxes={databoxes} pageType={pageTypes.desks} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
          <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>
        <Grid>
          <AddToPDFCard
            type="Amber State"
            sectionType="Desk"
            category={pdfCases.desksAmberState}
            sectionOrder={sectionsOrder.third}
            typeOrder={sectionItemOrder.fifth}
            chartName={liveLookPagesNames.desksAmberState}
            activeFilters={filtersDataContainer('DESKS', false).initActiveFilters}
            data={{
              databoxes,
              editableTextReport,
              deskGraphData,
              deskGraphScaleValues,
              returnPercentageSwitcherDeskGraph,
              deskGraphOnSwitcherChange,
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <VerticalBarGraph
          title="Amber usage by team zones"
          data={deskGraphData}
          returnPercentageSwitcher={returnPercentageSwitcherDeskGraph}
          scaleValues={deskGraphScaleValues}
          onSwitcherChange={deskGraphOnSwitcherChange}
          config={graphDeskConfig}
          customHeader
        />
      </Grid>
      <Grid item xs={12}>
        <TextEditor
          chartName={liveLookPagesNames.desksAmberState}
          data={databoxesToEditorData(databoxesSet, textVariables)}
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
          graphType={GRAPH_TYPES.VERTICAL}
        />
      </Grid>
    </Grid>
  );
};

export default DesksAmberState;
