// eslint-disable-next-line import/no-cycle
import { getRandomColor } from 'modules/workLook/utils';
import { GRAPH_COLORS, GROUP_TYPES, SERIES_ACTION_GROUP } from 'modules/workLook/constants';

const LABEL_LENGTH = 24;

const setGraphDataLegendsColors = (graphData, name, data, color, isModal, itemCounter) => {
  const label = name.length > LABEL_LENGTH && !isModal ? `${name.slice(0, LABEL_LENGTH - 3)}...` : name;
  const backgroundColor = color || GRAPH_COLORS[itemCounter];

  graphData.labels.push(label);
  graphData.datasets[0].data.push(data);
  graphData.datasets[0].backgroundColor.push(backgroundColor || getRandomColor());
};

const handleGroupNoneDataStructure = (data, isModal) => {
  const graphData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  };

  let itemCounter = 0;

  // eslint-disable-next-line no-shadow
  data.forEach(({ action, data, name, color }) => {
    if (SERIES_ACTION_GROUP.singleValue.includes(action)) {
      const chunkColor = color === '#ffffff' ? false : color;
      setGraphDataLegendsColors(graphData, name, data, chunkColor, isModal, itemCounter);
      itemCounter += 1;
    }

    if (SERIES_ACTION_GROUP.multipleValue.includes(action)) {
      data.forEach(({ field, value, _color }) => {
        setGraphDataLegendsColors(graphData, field, value, _color, isModal, itemCounter);
        itemCounter += 1;
      });
    }

    if (SERIES_ACTION_GROUP.counter.includes(action)) {
      data.forEach(({ field, value, _percentage, _color }) => {
        setGraphDataLegendsColors(graphData, field, _percentage || value, _color, isModal, itemCounter);
        itemCounter += 1;
      });
    }
  });

  return graphData.datasets[0].data.length ? graphData : null;
};

const handleGroupByFieldDataStructure = (data, isModal) => {
  const graphData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  };

  const firstSeries = data[0];

  if (SERIES_ACTION_GROUP.singleValue.includes(firstSeries.action)) {
    firstSeries.data.forEach(({ field, value, _color }, i) => {
      setGraphDataLegendsColors(graphData, field, value, _color, isModal, i);
    });
  }

  return graphData.datasets[0].data.length ? graphData : null;
};

const toDonutChartData = (data, groupType, isModal) => {
  if (!data || !data.length) return null;

  switch (groupType) {
    case null:
      return handleGroupNoneDataStructure(data, isModal);
    case GROUP_TYPES.byField:
      return handleGroupByFieldDataStructure(data, isModal);
    default:
      return null;
  }
};

export default toDonutChartData;
