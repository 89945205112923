/* eslint-disable camelcase */
import React from 'react';
import { useWidgetData } from 'modules/workLook/hooks';
import { isEmpty } from 'lodash';
import TableGraph from './TableGraph';

const TableGraphContainer = ({ description, id, order, sub_title, title, recordId }) => {
  const { isLoading, dataFromRecord } = useWidgetData(id, recordId);

  if (isEmpty(dataFromRecord)) return null;
  return (
    <TableGraph
      data={dataFromRecord?.data}
      isLoading={isLoading}
      order={order}
      description={description}
      subTitle={sub_title}
      title={title}
    />
  );
};

export default TableGraphContainer;
