export const PAGE_TYPES = [
  {
    value: '1',
    name: 'Report page',
  },
  {
    value: '2',
    name: 'Summary page',
  },
  {
    value: '3',
    name: 'Guage page',
  },
  {
    value: '4',
    name: 'Dashboard page',
  },
];
