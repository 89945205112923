import React from 'react';
import PropTypes from 'prop-types';

import './GroupedBarLegend.scss';

import { GroupedBarLegendItem } from '../..';

const GroupedBarLegend = ({ items }) => {
  const renderedItems = items.map(({ label, color }, idx) => (
    <GroupedBarLegendItem key={idx} label={label} color={color} />
  ));

  return (
    <div className="GroupedBarLegend">
      <div className="grouped-bar-legend">{renderedItems}</div>
    </div>
  );
};

GroupedBarLegend.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
};

GroupedBarLegend.defaultProps = {
  items: [
    {
      label: 'Peak Utilisation',
      color: 'sea-blue',
    },
    {
      label: 'Avg Utilisation',
      color: 'bright-turquoise',
    },
    {
      label: 'Peak Occupancy',
      color: 'crimson',
    },
    {
      label: 'Avg Occupancy',
      color: 'light-green',
    },
  ],
};

export default GroupedBarLegend;
