/* eslint-disable camelcase */
import { reportPageTypes } from 'modules/spaceLook/constants';
import { roundToFloat } from 'utils';

const MeetsAndBreakOneToFourUse = ({
  textData: { percent_total_1_to_4_people_meet, percent_count_1_to_4_people_meet, percent_count_1_people_meet },
  pageType,
}) => {
  const isMeet = pageType === reportPageTypes.meetings;

  return isMeet
    ? `Including solo use ${percent_total_1_to_4_people_meet}% of all rooms use was for four people or less.`
    : `${roundToFloat(percent_count_1_to_4_people_meet - percent_count_1_people_meet)}%
         of all space use was for groups sizes of two to four people.`;
};

export default MeetsAndBreakOneToFourUse;
