import { isEmpty } from 'lodash';
import { GRAPH_SCALES, GRAPH_VALUES } from 'modules/liveLook/constants';

const valueTypesMap = {
  [GRAPH_VALUES.max]: 'percentage_seat_occupancy',
  [GRAPH_VALUES.avg]: 'percentage_avg_seat_occupancy',
};

const percentageSeatsMap = {
  [GRAPH_VALUES.max]: 'seat_occupancy',
  [GRAPH_VALUES.avg]: 'avg_seat_occupancy',
};

const valueSpaceTypesMap = {
  [GRAPH_VALUES.max]: 'percentage_space_occupancy',
  [GRAPH_VALUES.avg]: 'percentage_avg_space_occupancy',
};

const percentageValueTypesMap = {
  [GRAPH_VALUES.max]: 'space_occupancy',
  [GRAPH_VALUES.avg]: 'avg_space_occupancy',
};

const getLabel = (timeSlot, scale) => {
  switch (scale) {
    case GRAPH_SCALES.day: {
      return timeSlot.day;
    }

    case GRAPH_SCALES.week: {
      return timeSlot.day;
    }

    case GRAPH_SCALES.month: {
      return timeSlot.day.slice(3);
    }

    default: {
      return timeSlot.time;
    }
  }
};

const toSeatsUsageOverTimeGraphData = (
  { sourceData, valueType, scale, returnPercentageSwitcher, niceMax },
  showSeats,
) => {
  if (isEmpty(sourceData)) return false;
  const dayLabels = [];

  const graphData = {
    labels: [],
    datasets: [
      {
        label: 'Occupied (seats)',
        type: 'line',
        data: [],
        fill: false,
        borderColor: '#a2559d',
        backgroundColor: '#a2559d',
      },
      {
        type: 'bar',
        label: 'Occupied',
        stack: 'Stack 0',
        data: [],
        fill: false,
        backgroundColor: '#58bfc7',
      },
      {
        type: 'bar',
        label: 'Unoccupied',
        data: [],
        stack: 'Stack 0',
        fill: false,
        backgroundColor: '#f1f1f1',
      },
    ],
  };

  sourceData.forEach(([dayLabel, dayData]) => {
    dayLabels.push({
      label: dayLabel,
      length: dayData.length,
    });

    dayData.forEach((timeSlot) => {
      const key = returnPercentageSwitcher ? percentageSeatsMap[valueType] : valueTypesMap[valueType];

      const occupied =
        timeSlot[returnPercentageSwitcher ? percentageValueTypesMap[valueType] : valueSpaceTypesMap[valueType]];
      const unoccupied = niceMax - occupied;

      const label = getLabel(timeSlot, scale);

      graphData.labels.push(label);
      graphData.datasets[0].data.push(showSeats ? timeSlot[key] : null);
      graphData.datasets[1].data.push(occupied);
      graphData.datasets[2].data.push(parseFloat(unoccupied.toFixed(1)));
    });
  });

  return { data: graphData, dayLabels };
};

export default toSeatsUsageOverTimeGraphData;
