import { isEmpty } from 'lodash';

const JWTPayload = (token) => {
  try {
    // eslint-disable-next-line react/destructuring-assignment
    const tokenPayload = token.split('.')[1];
    return JSON.parse(atob(tokenPayload));
  } catch (err) {
    return null;
  }
};

const isLogged = () => {
  const milliseconds = '000';

  const token = window.localStorage.getItem('userToken');
  const asUserToken = window.localStorage.getItem('asUserToken');
  const userTokenExist = isEmpty(asUserToken);

  if (!userTokenExist) {
    return window.localStorage.getItem('asUserToken');
  }

  if (!token) return;

  const tokenPayloadObj = JWTPayload(token);
  if (!tokenPayloadObj) return;

  const exp = parseInt(tokenPayloadObj.exp.toString() + milliseconds);

  const isTokenExpired = Date.now() - exp > 0;

  // eslint-disable-next-line no-unused-expressions
  isTokenExpired && localStorage.removeItem('userToken');

  // eslint-disable-next-line consistent-return
  return window.localStorage.getItem('userToken');
};

export default isLogged;
