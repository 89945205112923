import React from 'react';

import DescriptionIcon from '@material-ui/icons/Description';
import { Button, CircularProgress } from '@material-ui/core';

const StandardReportBtn = ({ href, inProgress }) => (
  <Button
    // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
    startIcon={inProgress ? <CircularProgress size={14} style={{ color: 'inherit' }} /> : <DescriptionIcon />}
    // eslint-disable-next-line react/no-children-prop
    children="STANDARD REPORT"
    href={href}
    disabled={!href || inProgress}
    target="_blank"
    rel="noopener noreferrer"
  />
);

export default StandardReportBtn;
