import { get } from 'lodash';

import common from './commonReducer';
import products from './productsReducer';
import assets from './assetsReducer';
import filters from './filtersReducer';
import summary from './summaryReducer';

export default (state = {}, action) => {
  const tags = get(state, 'common.tags', null);

  return {
    common: common(state.common, action),
    products: products(state.products, action),
    assets: assets(state.assets, action),
    filters: filters(state.filters, { ...action, tags }),
    summary: summary(state.summary, action),
  };
};
