import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { useDispatch } from 'react-redux';
import { SET_TYPE_ID } from '../actions';

const useCreateBlankReport = (isModal, _handleClose, typeId) => {
  if (isModal) {
    return { onClick: null };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const onClick = () => {
    _handleClose();
    dispatch(SET_TYPE_ID(typeId));
    dispatch(showModal(modalCodes.WL_CREATE_BLANK_REPORT));
  };

  return { onCreateBlankReport: onClick };
};

export default useCreateBlankReport;
