/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';

import { isEmpty } from 'lodash';
import AutotextCard from './AutotextCard';
import EmptyGraph from '../EmptyGraph';

const AutotextCardContainer = ({ id, type_of_group, activeFilters, type, isPdf, isPublished, sub_title, ...props }) => {
  const { isLoading, data } = useGraphData(id, activeFilters);

  if (isEmpty(data))
    return (
      <EmptyGraph
        display={false}
        type={type}
        title={props.title}
        isPublished={isPublished}
        isPdf={isPdf}
        graphId={id}
        typeOfGroup={type_of_group}
        showTitle={props.is_display_title}
        subtitle={sub_title}
        {...props}
      />
    );

  return (
    <AutotextCard
      graphId={id}
      type={type}
      typeOfGroup={type_of_group}
      isPublished={isPublished}
      subtitle={sub_title}
      data={data[0].data}
      isLoading={isLoading}
      {...props}
      isPdf={isPdf}
    />
  );
};

export default AutotextCardContainer;
