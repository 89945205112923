import { isEmpty } from 'lodash';
import { GRAPH_SCALES, GRAPH_VALUES } from 'modules/liveLook/constants';

const percentageValueTypesMap = {
  [GRAPH_VALUES.max]: 'occupancy',
  [GRAPH_VALUES.avg]: 'avg_occupancy',
};

const valueTypesMap = {
  [GRAPH_VALUES.max]: 'percentage_occupancy',
  [GRAPH_VALUES.avg]: 'percentage_avg_occupancy',
};

const percentageValueTypesMapBreak = {
  [GRAPH_VALUES.max]: 'seat_occupancy',
  [GRAPH_VALUES.avg]: 'avg_seat_occupancy',
};

const valueTypesMapBreak = {
  [GRAPH_VALUES.max]: 'percentage_seat_occupancy',
  [GRAPH_VALUES.avg]: 'percentage_avg_seat_occupancy',
};

const getLabel = (timeSlot, scale) => {
  switch (scale) {
    case GRAPH_SCALES.day: {
      return timeSlot.day;
    }

    case GRAPH_SCALES.week: {
      return timeSlot.day;
    }

    case GRAPH_SCALES.month: {
      return timeSlot.day.slice(3);
    }

    default: {
      return timeSlot.time;
    }
  }
};

// eslint-disable-next-line no-unused-vars
const toCombinedOccTimeGraphData = ({ sourceData, valueType, scale, returnPercentageSwitcher, niceMax }, showSeats) => {
  if (isEmpty(sourceData)) return false;
  const dayLabels = [];
  const graphData = {
    // title: rowName,
    labels: [],
    datasets: [
      {
        label: 'Desks',
        data: [],
        stack: 'standing costs',
        backgroundColor: '#58BFC7',
      },
      {
        label: 'Breakouts',
        data: [],
        stack: 'standing costs',
        backgroundColor: '#F6A000',
      },
      {
        label: 'Unoccupied',
        data: [],
        stack: 'standing costs',
        backgroundColor: '#f1f1f1',
      },
    ],
  };
  sourceData.forEach(([dayLabel, dayData]) => {
    dayLabels.push({
      label: dayLabel,
      length: dayData.length,
    });
    dayData.forEach((timeSlot) => {
      const desksData = returnPercentageSwitcher ? percentageValueTypesMap[valueType] : valueTypesMap[valueType];
      const breakoutData = returnPercentageSwitcher
        ? percentageValueTypesMapBreak[valueType]
        : valueTypesMapBreak[valueType];
      const desks = timeSlot.desks[desksData];
      const breakouts = timeSlot.breakouts[breakoutData];

      const unoccupied = niceMax - desks - breakouts;

      const label = getLabel(timeSlot, scale);

      graphData.labels.push(label);
      graphData.datasets[0].data.push(desks);
      graphData.datasets[1].data.push(breakouts);
      graphData.datasets[2].data.push(parseFloat(unoccupied.toFixed(1)));
    });
  });

  return { data: graphData, dayLabels };
};

export default toCombinedOccTimeGraphData;
