import React, { useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { getPdfMode } from 'modules/spaceLook/selectors';

import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { dateToQuery, filtersToQuery } from 'modules/liveLook/utils';
import {
  SET_GRAPH_RANGE,
  fetchMeetsOverTime,
  fetchCombinedTeamZoneOccupancy,
  fetchCombinedOccupancyDataboxes,
  fetchOccupancyDataboxes,
  fetchDoorsOverTime,
  fetchMeetsDataboxes,
  SET_GRAPH_SWITCHER,
  fetchBreakoutOverTime,
  fetchDeskDataboxes,
  fetchBreakDataboxes,
} from 'modules/liveLook/actions';
import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import { GET_SCALE_RANGE } from './constants';
import SummaryDashboard from './SummaryDashboard';
import reducer, { initialState } from './_reducer';
import useGetMeetData from './useGetMeetData';
import useGetBreakData from './useGetBreakData';
import useGetOccupancyData from './useGetOccupancyData';
import getLastWeekDates from './getLastWeekDates';
import useGetCombinedData from './useGetCombinedData';

function SummaryDashboardContainer() {
  const [companyId] = useAuthSelector(['currentCompany']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    // Occupancy
    dataOcc,
    occupancyDataboxesOcc,
    requestStatusOcc,
    databoxRequestStatusOcc,
    capacity,
    nominalCapacity,
    // Occupancy Week
    dataOccWeek,
    occupancyDataboxesOccWeek,
    requestStatusOccWeek,
    databoxRequestStatusOccWeek,
    capacityWeek,
    nominalCapacityWeek,
    // Combined
    dataCombined,
    occupancyDataboxes,
    requestStatusCombined,
    databoxRequestStatusCombined,
    // Combined week
    dataCombinedWeek,
    occupancyDataboxesWeek,
    requestStatusCombinedWeek,
    databoxRequestStatusCombinedWeek,
    // Meeting
    data,
    meetsDataboxes,
    requestStatus,
    databoxRequestStatus,
    // Meeting week
    dataMeetWeek,
    meetsDataboxesWeek,
    requestStatusMeetWeek,
    databoxRequestStatusMeetWeek,
    // Breakout
    dataBreak,
    breakDataboxes,
    databoxRequestStatusBreak,
    requestStatusBreak,
    // Breakout week
    dataBreakWeek,
    requestStatusBreakWeek,
    databoxRequestStatusBreakWeek,
    breakDataboxesWeek,
    // Other
    range,
    scaleWeek,
    scale,
    value,
    returnPercentageSwitcher,
    deskDataboxes,
  } = state;
  const statuses = [
    requestStatusBreakWeek,
    databoxRequestStatusBreakWeek,
    requestStatusCombinedWeek,
    databoxRequestStatusCombinedWeek,
    requestStatusOccWeek,
    databoxRequestStatusOccWeek,
    requestStatusMeetWeek,
    databoxRequestStatusMeetWeek,
    requestStatusOcc,
    databoxRequestStatusOcc,
    requestStatusBreak,
    databoxRequestStatusBreak,
    databoxRequestStatusCombined,
    requestStatusCombined,
    databoxRequestStatus,
    requestStatus,
  ];

  const pdfMode = useSelector(getPdfMode);
  const [startDate, workingHours, activeFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.workingHours',
    'filters.activeFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeFilters);
  const currentDay = new Date().toJSON().slice(0, 10);
  const dateAfter = dateToQuery(currentDay, [0, 0, 0]);
  const dateAfterWeek = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(currentDay, [23, 59, 59]);
  const dayChoosen = dateAfter.slice(8, 10);

  const { customMonday, customSunday } = getLastWeekDates(range);
  const dateMonday = dateToQuery(customMonday, [0, 0, 0]);
  const dateSunday = dateToQuery(customSunday, [23, 59, 59]);

  const getScaleForWeek = GET_SCALE_RANGE[range];

  useEffect(() => {
    let isCanceled = false;
    // Meetings
    fetchMeetsOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      scale,
      query,
      archive,
    });

    fetchMeetsDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      scale,
      query,
      archive,
    });
    // Combined
    fetchCombinedTeamZoneOccupancy(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchCombinedOccupancyDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
      query,
      archive,
    });
    // Combined week
    fetchCombinedTeamZoneOccupancy(dispatch, isCanceled, {
      companyId,
      dateAfter: dateMonday,
      dateBefore: dateSunday,
      workingHours,
      week: true,
      scaleOption: getScaleForWeek,
      query,
      archive,
    });

    fetchCombinedOccupancyDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter: dateMonday,
      dateBefore: dateSunday,
      week: true,
      scaleOption: getScaleForWeek,
      query,
      archive,
    });
    // Breakout
    fetchBreakoutOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      scale,
      query,
      archive,
    });

    fetchBreakDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      scale,
      query,
      archive,
    });
    // Breakout week
    fetchBreakoutOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter: dateMonday,
      dateBefore: dateSunday,
      workingHours,
      scaleOption: getScaleForWeek,
      query,
      week: true,
      archive,
    });

    fetchBreakDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter: dateMonday,
      dateBefore: dateSunday,
      workingHours,
      scaleOption: getScaleForWeek,
      query,
      week: true,
      archive,
    });
    // Occupancy
    fetchDoorsOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      scale,
      workingHours,
      query,
      archive,
    });

    fetchOccupancyDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
      query,
      archive,
    });
    // Occupancy Week
    fetchDoorsOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter: dateMonday,
      dateBefore: dateSunday,
      scaleOption: getScaleForWeek,
      workingHours,
      query,
      week: true,
      archive,
    });

    fetchOccupancyDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter: dateMonday,
      dateBefore: dateSunday,
      query,
      scaleOption: getScaleForWeek,
      week: true,
      archive,
    });
    // Meeting week
    fetchMeetsOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter: dateMonday,
      dateBefore: dateSunday,
      workingHours,
      scaleOption: getScaleForWeek,
      query,
      week: true,
      archive,
    });
    fetchMeetsDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter: dateMonday,
      dateBefore: dateSunday,
      workingHours,
      scaleOption: getScaleForWeek,
      query,
      week: true,
      archive,
    });
    // Desk databoxes
    fetchDeskDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      scale,
      query,
      dateAfter,
      dateBefore,
      archive,
    });
    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [
    companyId,
    archive,
    dateAfter,
    dateAfterWeek,
    dateBefore,
    dateMonday,
    dateSunday,
    getScaleForWeek,
    query,
    scale,
    scaleWeek,
    workingHours,
  ]);

  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  // eslint-disable-next-line no-shadow
  const onRangeChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_RANGE(value)), []);

  const allDataboxes = {
    capacity: occupancyDataboxesOcc.capacity,
    capacity_percentage: occupancyDataboxesOcc.capacity_percentage,
    max_occupancy_percentage: occupancyDataboxesOcc.max_occupancy_percentage,
    max_desk_usage: deskDataboxes.max_desk_usage,
    max_desk_occupancy: deskDataboxes.max_desk_occupancy,
    max_occupancy: occupancyDataboxesOcc.max_occupancy,
    nominal_capacity_percentage: occupancyDataboxesOcc.nominal_capacity_percentage,
    desks: deskDataboxes.desks,
    percentage_max_desk_usage: deskDataboxes.percentage_max_desk_usage,
    percentage_max_desk_occupancy: deskDataboxes.percentage_max_desk_occupancy,
    max_rooms_usage: meetsDataboxes.max_rooms_usage,
    percentage_max_rooms_usage: meetsDataboxes.percentage_max_rooms_usage,
    max_rooms_usage_breakout: breakDataboxes.max_rooms_usage,
    percentage_max_rooms_usage_breakout: breakDataboxes.percentage_max_rooms_usage,
    nominal_capacity: occupancyDataboxesOcc.nominal_capacity,
    max_occupancy_date: occupancyDataboxesOcc.max_occupancy_date,
    max_desk_occupancy_date: deskDataboxes.max_desk_occupancy_date,
    max_rooms_date_breakout: breakDataboxes.max_rooms_date,
    max_rooms_date: meetsDataboxes.max_rooms_date,
    max_desk_usage_date: deskDataboxes.max_desk_usage_date,
  };
  // Meetings
  const {
    scale: scaleValues,
    linesData,
    graphData,
  } = useGetMeetData({
    data,
    scale,
    returnPercentageSwitcher,
    meetsDataboxes,
    value,
  });
  // Meetings Week
  const {
    scale: scaleValuesMeetWeek,
    linesData: linesDataMeetWeek,
    graphData: graphDataMeetWeek,
  } = useGetMeetData({
    data: dataMeetWeek,
    scale: getScaleForWeek,
    returnPercentageSwitcher,
    meetsDataboxes: meetsDataboxesWeek,
    value,
  });
  // Breakout
  const {
    scale: scaleValuesBreak,
    linesDataBreak,
    graphDataBreak,
  } = useGetBreakData({
    data: dataBreak,
    scale,
    returnPercentageSwitcher,
    breakDataboxes,
    value,
  });
  // BreakoutWeek
  const {
    scale: scaleValuesBreakWeek,
    linesDataBreak: linesDataBreakWeek,
    graphDataBreak: graphDataBreakWeek,
  } = useGetBreakData({
    data: dataBreakWeek,
    scale: getScaleForWeek,
    returnPercentageSwitcher,
    breakDataboxes: breakDataboxesWeek,
    value,
  });
  // Occupancy
  const {
    scaleValues: scaleValuesOcc,
    linesDataOcc,
    graphDataOcc,
  } = useGetOccupancyData({
    dataOcc,
    scale,
    dayChoosen,
    occupancyDataboxesOcc,
    returnPercentageSwitcher,
    capacity,
    nominalCapacity,
    value,
  });
  // Occupancy Week
  const {
    scaleValues: scaleValuesOccWeek,
    linesDataOcc: linesDataOccWeek,
    graphDataOcc: graphDataOccWeek,
  } = useGetOccupancyData({
    dataOcc: dataOccWeek,
    scale: getScaleForWeek,
    dayChoosen,
    occupancyDataboxesOcc: occupancyDataboxesOccWeek,
    returnPercentageSwitcher,
    capacity: capacityWeek,
    nominalCapacity: nominalCapacityWeek,
    value,
  });
  // Combined
  const {
    scale: scaleValuesCombined,
    linesDataCombined,
    graphDataCombined,
  } = useGetCombinedData({
    data: dataCombined,
    scale,
    occupancyDataboxes,
    returnPercentageSwitcher,
    value,
  });
  // Combined week
  const {
    scale: scaleValuesCombinedWeek,
    linesDataCombined: linesDataCombinedWeek,
    graphDataCombined: graphDataCombinedWeek,
  } = useGetCombinedData({
    data: dataCombinedWeek,
    scale: getScaleForWeek,
    occupancyDataboxes: occupancyDataboxesWeek,
    returnPercentageSwitcher,
    value,
  });

  return (
    <Choose>
      <When condition={statuses.some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={statuses.every((item) => isSuccess(item))}>
        <SummaryDashboard
          {...state}
          // Meetings
          linesDataMeet={linesData}
          graphDataMeet={graphData}
          scaleValuesMeet={scaleValues}
          databoxesMeet={meetsDataboxes}
          // Meetings Week
          linesDataMeetWeek={linesDataMeetWeek}
          graphDataMeetWeek={graphDataMeetWeek}
          scaleValuesMeetWeek={scaleValuesMeetWeek}
          databoxesMeetWeek={meetsDataboxesWeek}
          // Combined
          databoxesCombined={occupancyDataboxes}
          pdfMode={pdfMode}
          scaleValuesCombined={scaleValuesCombined}
          dataCombined={graphDataCombined}
          linesDataCombined={linesDataCombined}
          // Combined week
          databoxesCombinedWeek={occupancyDataboxesWeek}
          pdfModeWeek={pdfMode}
          scaleValuesCombinedWeek={scaleValuesCombinedWeek}
          dataCombinedWeek={graphDataCombinedWeek}
          linesDataCombinedWeek={linesDataCombinedWeek}
          // Breakout
          linesDataBreak={linesDataBreak}
          graphDataBreak={graphDataBreak}
          scaleValuesBreak={scaleValuesBreak}
          // Breakout week
          linesDataBreakWeek={linesDataBreakWeek}
          graphDataBreakWeek={graphDataBreakWeek}
          scaleValuesBreakWeek={scaleValuesBreakWeek}
          databoxesBreakWeek={breakDataboxesWeek}
          // Occupancy
          onSwitcherChangeOcc={onSwitcherChange}
          linesDataOcc={linesDataOcc}
          graphDataOcc={graphDataOcc}
          scaleValuesOcc={scaleValuesOcc}
          databoxesOcc={occupancyDataboxesOcc}
          returnPercentageSwitcherOcc={returnPercentageSwitcher}
          // Occupancy Week
          linesDataOccWeek={linesDataOccWeek}
          graphDataOccWeek={graphDataOccWeek}
          scaleValuesOccWeek={scaleValuesOccWeek}
          databoxesOccWeek={occupancyDataboxesOccWeek}
          returnPercentageSwitcherOccWeek={returnPercentageSwitcher}
          // Other
          range={range}
          allDataboxes={allDataboxes}
          scale={scale}
          scaleWeek={getScaleForWeek}
          isDashboardPage
          value={value}
          onRangeChange={onRangeChange}
        />
      </When>
      <When condition={statuses.some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default SummaryDashboardContainer;
