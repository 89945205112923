import React, { useState } from 'react';
import { Button, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import theme from './IFrame.module.scss';

const IFrameModal = () => {
  const [isPopUpOpen, setPopup] = useState(false);

  const handlePopup = () => {
    setPopup(!isPopUpOpen);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handlePopup}
        style={{ marginTop: '40px', width: '150px', marginBottom: '20px', backgroundColor: '#004352' }}
      >
        View Live Map
      </Button>

      <Modal open={isPopUpOpen} onClose={handlePopup} style={{ borderRadius: '10px' }}>
        <div className={theme.wrapper}>
          <div className={theme.wrapper2}>
            <IconButton className={theme.button} aria-label="close" onClick={handlePopup}>
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe width="100%" height="100%" src="https://app.metrikus.io/" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IFrameModal;
