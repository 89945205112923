/* eslint-disable camelcase */
import React from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

import {
  PageHeader,
  CombinedDataCards,
  FilterHandler,
  ReportFilter,
  HourlyGraph,
  ReportText,
  UsagePie,
} from 'modules/spaceLook/components';

import { reportPageTypes } from 'modules/spaceLook/constants';
import { setStackedGraphRowsData, splitGraphs } from 'utils';
import { databoxesToPieChartData } from 'modules/spaceLook/utils';

import { useHasScroll } from 'modules/shared/hooks';
import config from './config';
import theme from './CombinedOccupancy.module.scss';

const CombinedOccupancy = ({
  isLoading,
  pdfMode,
  databoxes,
  dailyHourlyGraphData: { graph_data, lines_data },
  textData,
}) => {
  const scroll = useHasScroll();
  const graphRows = setStackedGraphRowsData(graph_data, 100);
  const graphs = pdfMode ? splitGraphs(graphRows) : [graphRows];
  const graphsData = graphs.map((graph) => {
    return Object.keys(graph).map((rowName) => {
      const dateObj = graphRows[rowName];
      return {
        title: rowName,
        labels: dateObj.labels,
        datasets: [
          {
            label: 'Desks',
            data: dateObj.percent_desk_occupancy,
            stack: 'standing costs',
            backgroundColor: config.palette.desks,
          },
          {
            label: 'Meets',
            data: dateObj.percent_meet_occupancy,
            stack: 'standing costs',
            backgroundColor: config.palette.meets,
          },
          {
            label: 'Breakout',
            data: dateObj.percent_breakout_occupancy,
            stack: 'standing costs',
            backgroundColor: config.palette.break,
          },
          {
            label: 'Unoccupied',
            data: dateObj.rest,
            stack: 'standing costs',
            backgroundColor: config.palette.unoccupied,
          },
        ],
      };
    });
  });

  return graphsData.map((graphData, i) => (
    <div
      key={i}
      className={cn(theme.container, {
        pdfPage: pdfMode,
        hasPageCounter: pdfMode,
        blured: isLoading,
      })}
      ref={scroll.ref}
    >
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={6}>
          <PageHeader title="Combined Occupancy" text="Executive Summary" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={6} align="right">
            <FilterHandler pageType={reportPageTypes.combined}>
              <ReportFilter pageType={reportPageTypes.combined} />
            </FilterHandler>
          </Grid>
        </If>

        <If condition={pdfMode}>
          <Grid item xs={6}>
            <div className={theme.totalCapacityBox}>{`Total seat capacity: ${databoxes.seat_capacity}`}</div>
          </Grid>
        </If>

        <Grid item xs={pdfMode ? 6 : 12} lg={6}>
          <If condition={!pdfMode}>
            <div className={theme.totalCapacityBox}>{`Total seat capacity: ${databoxes.seat_capacity}`}</div>
          </If>
          <CombinedDataCards databoxes={databoxes} />
        </Grid>

        <Grid item xs={pdfMode ? 6 : 12} lg={6}>
          <UsagePie pieData={databoxesToPieChartData(databoxes)} label="Seats Capacity" />
        </Grid>

        <Grid item xs={12}>
          <HourlyGraph
            linesData={lines_data}
            graphData={graphData}
            linesConfig={config.horizontalBars}
            legendConfig={config.legend.concat(config.horizontalBars)}
            pdfModeLegendItemSize={2}
          />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={['combinedAvgAndPeak', 'combinedCoreHours', 'combinedBuildingOccupancy']}
            repeat={{
              combinedBuildingOccupancy: Object.keys(textData.buildings).length,
            }}
            textData={textData}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default CombinedOccupancy;
