/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Typography, Button, TextField, Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import { Skeleton } from '@material-ui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ENDPOINTS } from 'modules/shared/constants';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isLogged } from 'utils';
import axios from 'axios';
import { isEmpty } from 'lodash';
import theme from '../forms.module.scss';

const warning = 'Please fill all tags';
const checkonEmpties = (element) => isEmpty(element?.tags);

const ColorForm = ({
  modifierType,
  seriesList,
  modifierId,
  modifierAction,
  onClose,
  graphId,
  isModifierLoading,
  modifierData,
  modifierFields,
  onCancelModifier,
}) => {
  const { name, graph, is_active, modifier_settings, series, order, action } = modifierData || {};
  const isEditMode = modifierType === 'edit';
  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });
  const [isEmptyTag, setEmptyTag] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Name required'),
    order: Yup.string().required('*Order required'),
    series: Yup.array().min(1, '*Series required'),
  });
  const [inputTags, setInputTags] = useState(isEditMode ? modifier_settings : [{ tags: [], color: '#000000' }]);

  const initialCreateValues = {
    graph: graphId,
    name: '',
    order: '',
    action: modifierAction,
    is_active: true,
    series: [],
  };

  const editInitialValues = {
    graph,
    name,
    order,
    action,
    is_active,
    series,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode ? editInitialValues : initialCreateValues,
    validationSchema,
    onSubmit: (values) => {
      if (inputTags.some(checkonEmpties)) {
        setEmptyTag(true);
      } else {
        const getValues = Object.assign(values, { modifier_settings: inputTags });
        const result = JSON.stringify(getValues, (k, val) => (val === '' ? null : val));
        const urlEditModifier = ENDPOINTS.workLook.crudModifier.replace(':modifierId', modifierId);
        const url = ENDPOINTS.workLook.modifiersList.replace(':graphId', graphId);
        const config = {
          method: isEditMode ? 'put' : 'post',
          url: isEditMode ? urlEditModifier : url,
          headers: {
            Authorization: `Token ${isLogged()}`,
            'Content-Type': 'application/json',
          },
          data: result,
        };
        axios(config)
          .then(() => {
            onClose();
            window.location.reload(false);
          })
          .catch((error) => {
            setErrors({ isError: true, errorMsg: error?.response?.data?.non_field_errors });
          });
      }
    },
  });

  const handleFormChange = (index, event) => {
    const data = [...inputTags];
    data[index][event.target.name] = event.target.value;
    setInputTags(data);
  };

  const addFields = () => {
    const newTag = { tags: [], color: '#000000' };
    setInputTags([...inputTags, newTag]);
  };

  const removeFields = (index) => {
    const data = [...inputTags];
    data.splice(index, 1);
    setInputTags(data);
  };

  return (
    <Choose>
      <When condition={isModifierLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>
      <Otherwise>
        <div className={theme.container}>
          <div className={theme.header}>
            <Typography variant="h5" className={theme.title}>
              {isEditMode ? 'Edit Color Modifier' : 'Create Color Modifier'}
            </Typography>
          </div>
          <div className={theme.formWrapper}>
            <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 2, mb: 2 }}>
                <If condition={errors.isError}>
                  {errors.errorMsg.map((msg) => (
                    <Typography variant="body2" className={theme.error}>
                      {msg}
                    </Typography>
                  ))}
                </If>
              </Box>
              <div>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  placeholder="Enter series name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.name}
                </Typography>
              </div>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="is_active"
                      name="is_active"
                      color="primary"
                      checked={formik.values.is_active}
                      value={formik.values.is_active}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Is Active Modifier"
                />
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="series">Series</InputLabel>
                  <Select
                    labelId="series"
                    id="series"
                    name="series"
                    label="Series"
                    multiple
                    value={formik.values.series}
                    onChange={formik.handleChange}
                  >
                    {seriesList.map(({ id, name: seriesName }) => (
                      <MenuItem key={id} value={id}>
                        {seriesName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="body2" className={theme.error}>
                  {formik.errors?.series}
                </Typography>
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                {inputTags.map(({ tags, color }, index) => {
                  return (
                    <div key={index}>
                      <div className={theme.removeTag}>
                        <Typography variant="body1" className={theme.tags}>
                          {index + 1} Tag
                        </Typography>
                        <If condition={inputTags.length !== 1}>
                          <Button
                            onClick={() => removeFields(index)}
                            size="small"
                            className={theme.removeBtn}
                            variant="outlined"
                          >
                            Remove
                          </Button>
                        </If>
                      </div>
                      <Box sx={{ mt: 2, mb: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel id="tags">Tag</InputLabel>
                          <Select
                            labelId="tags"
                            id="tags"
                            name="tags"
                            label="Tags"
                            multiple
                            value={tags}
                            onChange={(event) => handleFormChange(index, event)}
                          >
                            {modifierFields.map(({ label }) => (
                              <MenuItem key={label} value={label}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ mt: 2, mb: 2, alignSelf: 'center' }}>
                        <ChromePicker
                          fullWidth
                          id="color"
                          name="color"
                          color={color}
                          onChange={(value) => handleFormChange(index, { target: { value: value.hex, name: 'color' } })}
                        />
                      </Box>
                    </div>
                  );
                })}
                <Box sx={{ mt: 2, mb: 2 }}>
                  <If condition={isEmptyTag}>
                    <Typography variant="body2" className={theme.error}>
                      {warning}
                    </Typography>
                  </If>
                </Box>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Button className={theme.sbmtBtn} color="primary" size="small" variant="outlined" onClick={addFields}>
                    Add color
                  </Button>
                </Box>
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  className={theme.input}
                  type="number"
                  id="order"
                  name="order"
                  label="Order"
                  placeholder="Enter an order"
                  value={formik.values.order}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.order}
                </Typography>
              </Box>
              <div className={theme.btnContainer}>
                <Button onClick={() => onCancelModifier()} className={theme.cancelBtn} variant="outlined">
                  Cancel
                </Button>
                <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default ColorForm;
