import { findPeakAndLowestDayByOccupancy, sortObjects } from 'utils';

const DesksHighestAndLowest = ({ graphData }) => {
  const { highestOcc, lowestOcc } = findPeakAndLowestDayByOccupancy(graphData);

  const graphDataSorted = graphData.sort(sortObjects('percent_occupancy'));
  const graphDataSortedReverse = [...graphDataSorted].reverse();

  const lowestHourOfLowestDay = graphDataSorted.find((dayObj) => dayObj.week_day === lowestOcc.day);
  const peakHourOfPeakDay = graphDataSortedReverse.find((dayObj) => dayObj.week_day === highestOcc.day);

  const highestMorningOrAfternoon = parseInt(peakHourOfPeakDay.hour.split(':')[0]) < 13 ? 'morning' : 'afternoon';

  const lowestMorningOrAfternoon = parseInt(lowestHourOfLowestDay.hour.split(':')[0]) < 13 ? 'morning' : 'afternoon';

  return `By half day ${highestOcc.day} ${highestMorningOrAfternoon} was the highest
     and ${lowestOcc.day} ${lowestMorningOrAfternoon} was the lowest.`;
};

export default DesksHighestAndLowest;
