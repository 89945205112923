/* eslint-disable consistent-return */
import { ENDPOINTS } from 'modules/shared/constants';
import { API, createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/workLook/actions/_createAction';

export const FETCH_TEMPLATES = createAsyncAction(createAction('FETCH_TEMPLATES'));

const fetchTemplates = (dispatch, typeId, isCanceled) => {
  dispatch(FETCH_TEMPLATES.pending());

  const url = ENDPOINTS.workLook.templates.replace(':typeId', typeId);

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return dispatch(FETCH_TEMPLATES.default());
      dispatch(FETCH_TEMPLATES.success(data));
    })
    .catch(() => {
      if (isCanceled) return dispatch(FETCH_TEMPLATES.default());
      dispatch(FETCH_TEMPLATES.failure());
    });
};

export default fetchTemplates;
