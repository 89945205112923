import { createReducer } from '@reduxjs/toolkit';

import { actionsCreator } from 'modules/spaceLook/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  FETCH_DESKS_DATABOXES,
  FETCH_DESKS_PIE_DATA,
  FETCH_DESKS_TEXT_DATA,
  FETCH_DESKS_DAILY_HOURLY_GRAPH_DATA,
  FETCH_DESKS_WEEKLY_HOURLY_GRAPH_DATA,
  FETCH_DESKS_USAGE_BANDING_OCC_GRAPH_DATA,
  FETCH_DESKS_USAGE_BANDING_UTIL_GRAPH_DATA,
  FETCH_DESKS_WEEKLY_PEAK_AND_AVG_GRAPH_DATA,
  FETCH_DESKS_BY_BUILDING_AND_FLOOR_DATA,
  FETCH_DESKS_DIVISION_GRAPH_DATA,
  FETCH_DESKS_DEPARTMENT_GRAPH_DATA,
  FETCH_DESKS_TYPE_GRAPH_DATA,
  FETCH_DESKS_AGILITY_GRAPH_DATA,
  FETCH_DESKS_AGILITY_DATABOXES,
  RESET_REPORT_DATA,
  APPLY_FILTERS,
  RESET_FILTERS,
} from 'modules/spaceLook/actions';

const initState = {
  requestStatuses: {
    databoxes: requestStatuses.default,
    pieData: requestStatuses.default,
    textData: requestStatuses.default,
    dailyHourlyGraphData: requestStatuses.default,
    weeklyHourlyGraphData: requestStatuses.default,
    weeklyPeakAndAvgGraphData: requestStatuses.default,
    byBuildingAndFloorData: requestStatuses.default,
    divisionData: requestStatuses.default,
    departmentData: requestStatuses.default,
    desksTypeGraphData: requestStatuses.default,
    usageBandingOccGraphData: requestStatuses.default,
    usageBandingUtilGraphData: requestStatuses.default,
    agilityGraphData: requestStatuses.default,
    agilityDataboxes: requestStatuses.default,
  },
  data: {
    databoxes: null,
    pieData: null,
    textData: null,
    dailyHourlyGraphData: null,
    weeklyHourlyGraphData: null,
    weeklyPeakAndAvgGraphData: null,
    byBuildingAndFloorData: null,
    divisionData: null,
    departmentData: null,
    desksTypeGraphData: null,
    usageBandingOccGraphData: null,
    usageBandingUtilGraphData: null,
    agilityGraphData: null,
    agilityDataboxes: null,
  },
  shouldRefetchData: {
    databoxes: false,
    pieData: false,
    textData: false,
    dailyHourlyGraphData: false,
    weeklyHourlyGraphData: false,
    weeklyPeakAndAvgGraphData: false,
    byBuildingAndFloorData: false,
    divisionData: false,
    departmentData: false,
    desksTypeGraphData: false,
    usageBandingOccGraphData: false,
    usageBandingUtilGraphData: false,
    agilityGraphData: false,
    agilityDataboxes: false,
  },
};

const desksReducer = createReducer(initState, {
  ...actionsCreator(FETCH_DESKS_DATABOXES, 'databoxes'),
  ...actionsCreator(FETCH_DESKS_PIE_DATA, 'pieData'),
  ...actionsCreator(FETCH_DESKS_TEXT_DATA, 'textData'),
  ...actionsCreator(FETCH_DESKS_DAILY_HOURLY_GRAPH_DATA, 'dailyHourlyGraphData'),
  ...actionsCreator(FETCH_DESKS_WEEKLY_HOURLY_GRAPH_DATA, 'weeklyHourlyGraphData'),
  ...actionsCreator(FETCH_DESKS_USAGE_BANDING_OCC_GRAPH_DATA, 'usageBandingOccGraphData'),
  ...actionsCreator(FETCH_DESKS_WEEKLY_PEAK_AND_AVG_GRAPH_DATA, 'weeklyPeakAndAvgGraphData'),
  ...actionsCreator(FETCH_DESKS_BY_BUILDING_AND_FLOOR_DATA, 'byBuildingAndFloorData'),
  ...actionsCreator(FETCH_DESKS_DIVISION_GRAPH_DATA, 'divisionData'),
  ...actionsCreator(FETCH_DESKS_DEPARTMENT_GRAPH_DATA, 'departmentData'),
  ...actionsCreator(FETCH_DESKS_TYPE_GRAPH_DATA, 'desksTypeGraphData'),
  ...actionsCreator(FETCH_DESKS_USAGE_BANDING_UTIL_GRAPH_DATA, 'usageBandingUtilGraphData'),
  ...actionsCreator(FETCH_DESKS_AGILITY_GRAPH_DATA, 'agilityGraphData'),
  ...actionsCreator(FETCH_DESKS_AGILITY_DATABOXES, 'agilityDataboxes'),
  [APPLY_FILTERS]: (state) => ({
    ...state,
    shouldRefetchData: {
      databoxes: true,
      pieData: true,
      textData: true,
      dailyHourlyGraphData: true,
      weeklyHourlyGraphData: true,
      weeklyPeakAndAvgGraphData: true,
      byBuildingAndFloorData: true,
      divisionData: true,
      departmentData: true,
      desksTypeGraphData: true,
      usageBandingOccGraphData: true,
      usageBandingUtilGraphData: true,
      agilityGraphData: true,
      agilityDataboxes: true,
    },
  }),
  [RESET_FILTERS]: (state) => ({
    ...state,
    shouldRefetchData: {
      databoxes: true,
      pieData: true,
      textData: true,
      dailyHourlyGraphData: true,
      weeklyHourlyGraphData: true,
      weeklyPeakAndAvgGraphData: true,
      byBuildingAndFloorData: true,
      divisionData: true,
      departmentData: true,
      desksTypeGraphData: true,
      usageBandingOccGraphData: true,
      usageBandingUtilGraphData: true,
      agilityGraphData: true,
      agilityDataboxes: true,
    },
  }),

  [RESET_REPORT_DATA]: () => ({ ...initState }),
});

export default desksReducer;
