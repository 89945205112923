import React from 'react';
import cn from 'classnames';
import { Grid } from '@material-ui/core';
import { DateTime } from 'luxon';

import { isEmpty, isUndefined } from 'lodash';
import theme from './DataCard.module.scss';

const DataCard = ({ title, color, noValue, firstValue, firstLabel, secondValue, secondLabel, pdfMode }) => {
  const formatDate = (value) => {
    const result = typeof value === 'string' ? DateTime.fromISO(value).toFormat('dd LLLL yyyy') : value;
    return result;
  };

  const cardLabel = (value) => {
    const label = !isEmpty(value) && value !== ' undefined' ? value : '';
    return label;
  };

  return (
    <Grid item>
      <div className={cn(theme.dataCard, { [`${theme.pdfMode}`]: pdfMode })} style={{ backgroundColor: color }}>
        <h4 className={theme.cardTitle}>{title}</h4>

        <Choose>
          <When condition={noValue}>
            <p className={theme.noValue}> N/A </p>
          </When>

          <When condition={!isUndefined(firstValue)}>
            <p className={theme.firstLine}>{`${formatDate(firstValue)}${cardLabel(firstLabel)}`}</p>
            <If condition={!isUndefined(secondValue)}>
              <p className={theme.secondLine}>{`${secondValue}${secondLabel || ''}`}</p>
            </If>
          </When>

          <Otherwise>
            <p className={theme.noValue}> N/A </p>
          </Otherwise>
        </Choose>
      </div>
    </Grid>
  );
};

export default DataCard;
