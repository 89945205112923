/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

// eslint-disable-next-line import/no-cycle
import { DownloadsListItem, DownloadsListItemSkeleton, ConfirmationDialog } from 'modules/shared/components';

import theme from './DownloadsList.module.scss';

const deleteDialogInitState = { open: false, id: null };

const DownloadsList = ({
  title,
  items,
  emptyListPlaceholder,
  skeletonItems,
  deleteTitle,
  deleteText,
  _onItemDelete,
  customTooltip,
  isPrivate,
  isLiveLook,
  _onPinReportClick,
}) => {
  const [deleteDialog, setDeleteDialog] = React.useState(deleteDialogInitState);

  const _onDeleteClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialog({ open: true, id });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          <Box fontWeight="fontWeightMedium" component="span">
            {title}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={theme.list}>
          <Choose>
            <When condition={!items}>
              {[...Array(skeletonItems)].map((_, i) => (
                <DownloadsListItemSkeleton key={i} />
              ))}
            </When>
            <When condition={!items.length}>
              <p className={theme.noReports}>{emptyListPlaceholder}</p>
            </When>
            <Otherwise>
              {items.map((item, i) => (
                <DownloadsListItem
                  isLiveLook={isLiveLook}
                  isPrivate={isPrivate}
                  key={i}
                  customTooltip={customTooltip}
                  _onPinReportClick={_onPinReportClick}
                  _onDeleteClick={_onItemDelete && _onDeleteClick}
                  {...item}
                />
              ))}
            </Otherwise>
          </Choose>
        </div>
      </Grid>
      <If condition={_onItemDelete}>
        <ConfirmationDialog
          open={deleteDialog.open}
          _onClose={() => setDeleteDialog(deleteDialogInitState)}
          title={deleteTitle}
          text={deleteText}
          _onSecondActionClick={() => {
            _onItemDelete(deleteDialog.id);
            setDeleteDialog(deleteDialogInitState);
          }}
        />
      </If>
    </Grid>
  );
};

DownloadsList.defaultProps = {
  title: 'Downloads',
  emptyListPlaceholder: 'No items to download yet',
  skeletonItems: 5,
};

export default DownloadsList;
