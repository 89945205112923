/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Typography, Button, TextField, Box, Checkbox, FormControlLabel, Chip } from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ENDPOINTS } from 'modules/shared/constants';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isLogged } from 'utils';
import axios from 'axios';
import theme from '../forms.module.scss';

const MergeForm = ({
  modifierType,
  seriesList,
  modifierId,
  modifierAction,
  onClose,
  graphId,
  isModifierLoading,
  modifierData,
  onCancelModifier,
}) => {
  const { name, graph, is_active, modifier_settings, series, order, action } = modifierData || {};
  const isEditMode = modifierType === 'edit';

  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Name required'),
    order: Yup.string().required('*Order required'),
    series: Yup.array().min(1, '*Series required'),
    values: Yup.array().min(1, '*Enter merge values'),
  });

  const initialCreateValues = {
    graph: graphId,
    name: '',
    order: '',
    action: modifierAction,
    values: [],
    is_active: true,
    series: [],
  };

  const editInitialValues = {
    graph,
    name,
    order,
    values: modifier_settings?.values,
    action,
    is_active,
    series,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode ? editInitialValues : initialCreateValues,
    validationSchema,
    onSubmit: (values) => {
      const getValues = Object.assign(values, {
        modifier_settings: { values: values.values },
      });
      delete getValues.values;
      const result = JSON.stringify(values, (k, val) => (val === '' ? null : val));
      const urlEditModifier = ENDPOINTS.workLook.crudModifier.replace(':modifierId', modifierId);
      const url = ENDPOINTS.workLook.modifiersList.replace(':graphId', graphId);
      const config = {
        method: isEditMode ? 'put' : 'post',
        url: isEditMode ? urlEditModifier : url,
        headers: {
          Authorization: `Token ${isLogged()}`,
          'Content-Type': 'application/json',
        },
        data: result,
      };
      axios(config)
        .then(() => {
          onClose();
          window.location.reload(false);
        })
        .catch((error) => {
          setErrors({ isError: true, errorMsg: error?.response?.data });
        });
    },
  });

  return (
    <Choose>
      <When condition={isModifierLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>
      <Otherwise>
        <div className={theme.container}>
          <div className={theme.header}>
            <Typography variant="h5" className={theme.title}>
              {isEditMode ? 'Edit Merge Modifier' : 'Create Merge Modifier'}
            </Typography>
          </div>
          <div className={theme.formWrapper}>
            <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 2, mb: 2 }}>
                <If condition={errors.isError}>
                  {errors.errorMsg.map((msg) => (
                    <Typography variant="body2" className={theme.error}>
                      {msg}
                    </Typography>
                  ))}
                </If>
              </Box>
              <div>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  placeholder="Enter series name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.name}
                </Typography>
              </div>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="is_active"
                      name="is_active"
                      color="primary"
                      checked={formik.values.is_active}
                      value={formik.values.is_active}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Is Active Modifier"
                />
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="series">Series</InputLabel>
                  <Select
                    labelId="series"
                    id="series"
                    name="series"
                    label="Series"
                    multiple
                    value={formik.values.series}
                    onChange={formik.handleChange}
                  >
                    {seriesList.map(({ id, name: seriesName }) => (
                      <MenuItem key={id} value={id}>
                        {seriesName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="body2" className={theme.error}>
                  {formik.errors?.series}
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="values"
                  freeSolo
                  value={formik.values.values}
                  options={[]}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue('values', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Values to merge"
                      variant="outlined"
                      placeholder="Enter values to merge"
                    />
                  )}
                  sx={{ width: '500px' }}
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors?.values}
                </Typography>
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  className={theme.input}
                  type="number"
                  id="order"
                  name="order"
                  label="Order"
                  placeholder="Enter an order"
                  value={formik.values.order}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.order}
                </Typography>
              </Box>
              <div className={theme.btnContainer}>
                <Button onClick={() => onCancelModifier()} className={theme.cancelBtn} variant="outlined">
                  Cancel
                </Button>
                <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default MergeForm;
