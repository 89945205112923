import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import { useStyles } from '../styles';
import './tableGraph.scss';

const TableGraph = ({ description, data, subTitle, title, isLoading }) => {
  const classes = useStyles();
  const secondColumnStart = Math.ceil(Object.keys(data).length / 2);
  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && isEmpty(data)}>
        <Paper className="widgetContainer" elevation={2}>
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <div className="widgetContainer">
          <div className="title">
            <Typography variant="h6" component="span" classes={{ root: classes.title }}>
              {title}
            </Typography>
          </div>
          <div className="subtitle">
            <Typography variant="body1" component="span" classes={{ root: classes.subTitle }}>
              {subTitle}
            </Typography>
          </div>
          <div className="description">
            <Typography variant="body2" component="span" classes={{ root: classes.description }}>
              {description}
            </Typography>
            <div className="dataWrapper">
              <div className="column">
                {Object.keys(data)
                  .slice(0, secondColumnStart)
                  .map((key, i) => (
                    <div key={i}>
                      <Typography variant="body1" component="span" classes={{ root: classes.title }}>
                        {`${key}: `}
                      </Typography>
                      <Typography variant="body1" component="span" classes={{ root: classes.values }}>
                        {data[key]}
                      </Typography>
                    </div>
                  ))}
              </div>
              <div className="column2">
                {Object.keys(data)
                  .slice(secondColumnStart)
                  .map((key, i) => (
                    <div key={i}>
                      <Typography variant="body1" component="span" classes={{ root: classes.title }}>
                        {`${key}: `}
                      </Typography>
                      <Typography variant="body1" component="span" classes={{ root: classes.values }}>
                        {data[key]}
                      </Typography>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default TableGraph;
