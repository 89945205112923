import React from 'react';
import cn from 'classnames';

import { PAGE_TYPES, PRODUCT_FIELDS } from 'modules/assetLook/constants';

import { FixedDataTable } from 'modules/shared/components';
import {
  ItemDetailsCard,
  ProductSummaryCellBody as CellBody,
  ProductSummaryCellHeader as CellHeader,
} from 'modules/assetLook/components';

import { ReportContentPage } from 'pages/_privatePages/shared';

import config from '../config';

const ProductSummary = ({ product, data, activeFilters, parentCompanyLogo, companyLogo, theme }) => (
  <div className={cn(theme.pdfPage, theme.hasPageCounter)}>
    <ReportContentPage
      title={PAGE_TYPES.productSummary}
      activeFilters={activeFilters}
      firstLogo={parentCompanyLogo}
      secondLogo={companyLogo}
    >
      {({ height, width }) => (
        <>
          <If condition={product}>
            <div
              style={{
                height: `${config.productDetailsHeight}px`,
                marginBottom: `${config.productDetailsMargin}px`,
              }}
            >
              <ItemDetailsCard
                image={product[PRODUCT_FIELDS.thumbnail]}
                image_medium={product[PRODUCT_FIELDS.image]}
                title={product[PRODUCT_FIELDS.name]}
                contentData={product.details_data}
                elevation={0}
                navDisabled
                product={product[PRODUCT_FIELDS.thumbnail]}
              />
            </div>
          </If>
          <FixedDataTable
            width={width}
            height={product ? height - config.productDetailsHeight - config.productDetailsMargin : height}
            rows={data}
            columns={Object.keys(data[0])}
            ColumnHeader={CellHeader}
            ColumnCell={CellBody}
            columnFixed={() => false}
            columnWidth={(columnKey, i, columns) => {
              return i >= columns.length - config.staticColumnsNum ? 95 : 135;
            }}
            columnFlexGrow={(columnKey, i, columns) => {
              return i >= columns.length - config.staticColumnsNum ? 0 : 1;
            }}
            showScrollbarX={false}
            showScrollbarY={false}
          />
        </>
      )}
    </ReportContentPage>
  </div>
);

export default ProductSummary;
