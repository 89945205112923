import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '@material-ui/core';

import { REPORT_FILTER_APPLY, SECTION_FILTER_APPLY } from 'modules/workLook/actions';

// eslint-disable-next-line import/no-cycle
import { FilterModalBase } from 'modules/modalsSystem/components';
import { isEmpty, includes, filter } from 'lodash';

const FilterModal = ({
  filterField,
  initFilters,
  activeFilters,
  reportId,
  sectionId,
  onClose,
  modifiedFilters,
  isPeopleLook,
}) => {
  const dispatch = useDispatch();
  const initValues = initFilters?.find(({ field }) => field === filterField).values;
  const modifiedValues = modifiedFilters?.find(({ field }) => field === filterField).values;
  const activeValues = activeFilters?.find(({ field }) => field === filterField).values;

  const [selectedValues, setSelectedValues] = useState(activeValues);
  const getFilters = activeFilters.some((filters) => !isEmpty(filters.values)) ? modifiedValues : initValues;

  const result = Object.keys(getFilters).map((key) => ({ key, value: getFilters[key] }));
  const isAllSelected = result.every(({ key }) => {
    return includes(selectedValues, key);
  });

  const onSelectAllClick = () => {
    return setSelectedValues(isAllSelected ? [] : Object.keys(initValues));
  };

  const onValueClick = (value) =>
    setSelectedValues(
      includes(selectedValues, value) ? filter(selectedValues, (item) => item !== value) : [...selectedValues, value],
    );

  const onApplyClick = () => {
    dispatch(
      sectionId
        ? SECTION_FILTER_APPLY({
            field: filterField,
            values: selectedValues,
            reportId,
            sectionId,
          })
        : REPORT_FILTER_APPLY({
            field: filterField,
            values: selectedValues,
            reportId,
          }),
    );
    onClose();
  };

  return (
    <Modal open onClose={onClose}>
      <FilterModalBase
        isPeopleLook={isPeopleLook}
        isAllSelected={isAllSelected}
        onSelectAllClick={onSelectAllClick}
        title={filterField.charAt(0).toUpperCase() + filterField.slice(1)}
        onClose={onClose}
        initValues={result.map((item) => item).sort((a, b) => a.key.localeCompare(b.key))}
        isValueChecked={(value) => selectedValues.includes(value)}
        onValueClick={onValueClick}
        onApplyClick={onApplyClick}
        isAssetLook
      />
    </Modal>
  );
};

export default FilterModal;
