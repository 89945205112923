/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';
import Modal from 'react-awesome-modal';

import './ModalWindow.scss';

import { FormButton } from '../..';

const ModalWindow = ({
  isOpen,
  children,
  buttonText,
  withToggleButton,
  withCloseButton,
  CloseButton,
  modalStyles,
  OpenButton,
  onClose,
  onOpen,
  isCloseOnOutClick,
}) => {
  const toggleButton = withToggleButton ? (
    OpenButton ? (
      <OpenButton onClick={onOpen} />
    ) : (
      <button type="button" onClick={onOpen}>
        open
      </button>
    )
  ) : null;

  const closeOnOutClick = isCloseOnOutClick ? onClose : () => {};

  return (
    <div className="ModalWindow">
      {toggleButton}
      <Modal className="my-modal" visible={isOpen} effect="fadeInUp" onClickAway={() => closeOnOutClick()}>
        <div style={modalStyles} className="modal-window">
          {children}
          <div onClick={onClose}>{withCloseButton && <CloseButton text={buttonText} />}</div>
        </div>
      </Modal>
    </div>
  );
};

ModalWindow.defaultProps = {
  defaultState: false,
  buttonText: 'Got it',
  withToggleButton: true,
  CloseButton: FormButton,
  withCloseButton: true,
  // eslint-disable-next-line react/default-props-match-prop-types
  modalStyles: {},
  isCloseOnOutClick: false,
  onClose: () => console.log('onClose func'),
  onOpen: () => console.log('onOpen func'),
};

export default ModalWindow;
