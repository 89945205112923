import fixed from 'assets/images/icons/agility/fixed.svg';
import flexible from 'assets/images/icons/agility/flexible.svg';
import mobile from 'assets/images/icons/agility/mobile.svg';
import nomadic from 'assets/images/icons/agility/nomadic.svg';

const config = [
  {
    key: 'percent_fixed',
    label: 'Fixed',
    color: 'dark-pink',
    icon: fixed,
  },
  {
    key: 'percent_flexible',
    label: 'Flexible',
    color: 'turquoise',
    icon: flexible,
  },
  {
    key: 'percent_mobile',
    label: 'Mobile',
    color: 'light-green',
    icon: mobile,
  },
  {
    key: 'percent_nomadic',
    label: 'Nomadic',
    color: 'dark-gold',
    icon: nomadic,
  },
];

export default config;
