/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { isLogged } from 'utils';
import { ENDPOINTS, requestStatuses } from 'modules/shared/constants';
import { getReportInfo } from 'modules/spaceLook/selectors';

import { Loader } from 'components';

import Downloads from './Downloads';

const DownloadsContainer = () => {
  const [baseReport, setBaseReport] = useState(null);
  const [customReports, setCustomReports] = useState(null);
  const [generateStatus, setGenerateStatus] = useState(requestStatuses.default);
  const [showAlert, setShowAlert] = useState(false);
  const { reportInfoRequestStatus, ...reportInfo } = useSelector(getReportInfo);

  const { id } = useParams();
  const token = isLogged();
  const basePdfReportUrl = ENDPOINTS.basePdfReport.replace(':id', id);
  const customPdfReportUrl = ENDPOINTS.customPdfReport.replace(':id', id);
  const config = { headers: { Authorization: `Token ${token}` } };

  useEffect(() => {
    axios
      .get(basePdfReportUrl, config)
      // eslint-disable-next-line camelcase
      .then(({ data: { report_pdf_file } }) => setBaseReport(report_pdf_file))
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(customPdfReportUrl, config)
      .then(({ data: customReports }) => setCustomReports(customReports))
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReportGeneration = () => {
    setGenerateStatus(requestStatuses.pending);

    axios
      .post(customPdfReportUrl, {}, config)
      .then(() => axios.get(customPdfReportUrl, config))
      .then(({ data: customReports }) => {
        setCustomReports(customReports);
        setGenerateStatus(requestStatuses.success);
        setShowAlert(true);
      })
      .catch(() => setGenerateStatus(requestStatuses.failure));
  };

  return (
    <Choose>
      <When condition={[requestStatuses.default, requestStatuses.pending].includes(reportInfoRequestStatus)}>
        <Loader />
      </When>
      <Otherwise>
        <Downloads
          baseReport={baseReport}
          reportInfo={reportInfo}
          customReports={customReports}
          handleReportGeneration={handleReportGeneration}
          generateStatus={generateStatus}
          showAlert={showAlert}
          handleAlertClose={() => setShowAlert(false)}
        />
      </Otherwise>
    </Choose>
  );
};

export default DownloadsContainer;
