import React from 'react';
import { cloneDeep, omit } from 'lodash';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { reportPageTypes } from 'modules/spaceLook/constants';
import { setStackedGraphColumnsData, splitOneGraph } from 'utils';

import {
  PageHeader,
  ReportText,
  ReportDataCards,
  ReportFilter,
  FilterHandler,
  OccupationUtilizationGraph,
} from 'modules/spaceLook/components';

import { useHasScroll } from 'modules/shared/hooks';
import config from './config';

const DesksDailyComparison = ({ isLoading, pdfMode, databoxes, weeklyPeakAndAvgGraphData }) => {
  const graphData = weeklyPeakAndAvgGraphData.reduce((result, current) => {
    // eslint-disable-next-line no-param-reassign
    result[current.day_of_week] = omit(current, 'day_of_week');
    return result;
  }, {});
  const scroll = useHasScroll();
  const graphColumns = setStackedGraphColumnsData(graphData, config.graph);
  const graphs = pdfMode ? splitOneGraph(graphColumns) : [graphColumns];

  const datas = graphs.map((graph) => {
    return {
      labels: graph.labels,
      datasets: [
        {
          label: 'Avg Occupancy',
          backgroundColor: '#34BBDB',
          data: graph.percent_avg_occupancy,
        },
        {
          label: 'Max Occupancy',
          backgroundColor: '#0D6F85',
          data: graph.percent_max_occupancy,
        },
        {
          label: 'Avg Utilization',
          backgroundColor: '#F6A000',
          data: graph.percent_avg_utilization,
        },
        {
          label: 'Max Utilization',
          backgroundColor: '#B4CC04',
          data: graph.percent_max_utilization,
        },
      ],
    };
  });

  return datas.map((data, idx) => (
    <div ref={scroll.ref} key={idx} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Daily Comparisons" text="Desk Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.desks}>
              <ReportFilter pageType={reportPageTypes.desks} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={12}>
          <ReportDataCards
            databoxes={databoxes}
            pageType={reportPageTypes.desks}
            marginBottom="40px"
            cropped={!pdfMode}
          />
        </Grid>

        <Grid item xs={12}>
          <OccupationUtilizationGraph data={data} />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={[
              'desksPeakOccupancy',
              'desksPeakUtilization',
              'desksLeastBusyOccupancy',
              'desksBusiestPeakTimeAvg',
            ]}
            graphData={cloneDeep(weeklyPeakAndAvgGraphData)}
            databoxes={databoxes}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default DesksDailyComparison;
