import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkingHours } from 'modules/liveLook/selectors/common.selectors';
import { getReports } from 'modules/liveLook/selectors';
import { ADD_TO_BASKET } from 'modules/liveLook/actions';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import * as TextFieldStorageManager from 'modules/shared/utils/TextFieldStorageManager';

// eslint-disable-next-line import/no-cycle
import { AddToPDF as SharedAddToPDF } from 'modules/liveLook/components';

const removeAllMetaFields = (data) => {
  if (!data || typeof data !== 'object') return;

  // eslint-disable-next-line no-param-reassign
  delete data?._meta;

  const values = Array.isArray(data) ? data : Object.values(data);

  for (let i = 0; i < values.length; i++) {
    const item = values[i];

    if (typeof item === 'object') {
      removeAllMetaFields(item);
    }
  }
};

const AddToPDFCard = ({
  type,
  sectionType,
  sectionOrder,
  data,
  chartName,
  activeFilters,
  category,
  typeOrder,
  showWarning,
  openWarningDialog,
}) => {
  const dispatch = useDispatch();
  const { reportData } = useSelector(getReports);
  const workingHours = useSelector(getWorkingHours);

  const onClick = useCallback(() => {
    if (showWarning) {
      openWarningDialog();
    } else {
      const textValue = TextFieldStorageManager.getItem(chartName) || data.editableTextReport;
      const newData = _.cloneDeep(data);
      newData.editableTextReport = textValue;
      newData.activeFilters = activeFilters;

      removeAllMetaFields(newData);

      dispatch(
        ADD_TO_BASKET({
          id: uuidv4(),
          type,
          sectionType,
          sectionOrder,
          typeOrder,
          category,
          data: newData,
          workingHours,
        }),
      );
    }
  }, [
    showWarning,
    openWarningDialog,
    chartName,
    data,
    activeFilters,
    dispatch,
    type,
    sectionType,
    sectionOrder,
    typeOrder,
    category,
    workingHours,
  ]);

  return <SharedAddToPDF warningDialog counter={reportData.length || 0} onClick={onClick} />;
};

export default AddToPDFCard;
