export const WRAPPER_WIDTH = 635;

export const marginBottom = {
  '30 minutes': 96,
  '1 hour': 96,
  Day: 88,
  Week: 88,
  Month: 71,
};

export const marginBottomDashboard = {
  '30 minutes': 96,
  '1 hour': 96,
  Day: 88,
  Week: 75,
  Month: 68,
};

export const marginBottomPdf = {
  '30 minutes': 72,
  '1 hour': 72,
  Day: 88,
  Week: 88,
  Month: 72,
};

export const MIN_GRAPH_WIDTH = '540px';
export const BAR_WIDTH = 15;
export const MONTH_BAR_WIDTH = 55;
export const ADDITIONAL_WIDTH = 120;
