import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import { API } from 'modules/shared/utils';
import { useAuthSelector } from 'modules/auth/hooks';
import { showModal } from 'modules/modalsSystem/actions';
import { OrderListContext } from 'modules/assetLook/context';
import { ENDPOINTS, ERROR_TEXT } from 'modules/shared/constants';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';

import OrderActions from './OrderActions';

function OrderActionsContainer(props) {
  const dispatch = useDispatch();

  const { reloadOrderList } = useContext(OrderListContext);

  const { enqueueSnackbar } = useSnackbar();

  const [user] = useAuthSelector(['user']);
  const isManager = user.is_manage_orders_permission;

  const errorHandler = ({ response }) =>
    enqueueSnackbar(response.status === 400 ? 'The status of this order has been already changed' : ERROR_TEXT, {
      variant: 'error',
    });

  const editRequest = (action) => {
    const url = ENDPOINTS.assetLook.orderEdit.replace(':orderId', props.orderId);
    API.patch(url, { action })
      .then(() => reloadOrderList())
      .catch(errorHandler);
  };

  const cancelRequest = () => {
    const url = ENDPOINTS.assetLook.orderCancel.replace(':orderId', props.orderId);
    API.delete(url)
      .then(() => reloadOrderList())
      .catch(errorHandler);
  };

  return (
    <OrderActions
      {...props}
      isManager={isManager}
      onRejectClick={() => editRequest('reject')}
      onApproveClick={() => editRequest('approve')}
      onCancelClick={isManager ? () => editRequest('cancel') : cancelRequest}
      onCompleteClick={() =>
        dispatch(
          showModal(modalCodes.AL_REQUEST_COMPLETE, {
            ...props,
            reloadOrderList,
          }),
        )
      }
    />
  );
}

export default OrderActionsContainer;
