/* eslint-disable camelcase */
import { roundToFloat } from 'utils';

const MeetsAndBreakOneToFourWithSolo = ({
  textData: { percent_total_1_to_4_people_meet, percent_total_1_people_meet },
}) => {
  return `${roundToFloat(percent_total_1_to_4_people_meet)}% of the meetings were for 4 people or less,
     of these ${roundToFloat(percent_total_1_people_meet)}% were solo usage.`;
};

export default MeetsAndBreakOneToFourWithSolo;
