import { createReducer } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { requestStatuses } from 'modules/shared/constants';
import { makeDefaultActiveFilters } from 'modules/shared/utils';
import { ASSET_STATUSES_FILTER_AVAILABLE, FILTERS } from 'modules/assetLook/constants';

import {
  APPLY_FILTERS,
  FETCH_INIT_FILTERS,
  FETCH_MODIFIED_FILTERS,
  FETCH_TARGET_FILTERS,
  RESET_ACTIVE_FILTERS,
  RESET_FILTERS_DATA,
  RESET_STATE,
  SET_SEARCH,
} from 'modules/assetLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  initFiltersRequestStatus: requestStatuses.default,
  modifiedFiltersRequestStatus: requestStatuses.default,
  targetFiltersRequestStatus: requestStatuses.default,
  initFilters: [],
  modifiedFilters: [],
  targetFilters: [],
  activeFilters: [],
  activeTargetFilters: [],
  search: '',
};

export default createReducer(initState, {
  [FETCH_INIT_FILTERS.pending]: () => ({
    ...initState,
    initFiltersRequestStatus: requestStatuses.pending,
  }),
  [FETCH_INIT_FILTERS.success]: (state, { payload, tags }) => {
    const initFilters = [
      ...payload,
      {
        field: FILTERS.status,
        values: ASSET_STATUSES_FILTER_AVAILABLE,
      },
    ];

    if (!isEmpty(tags))
      initFilters.push({
        field: FILTERS.tags,
        values: ['None', ...tags.map(({ name }) => name)],
      });

    return {
      ...state,
      initFiltersRequestStatus: requestStatuses.success,
      initFilters,
      activeFilters: makeDefaultActiveFilters(initFilters),
    };
  },
  [FETCH_INIT_FILTERS.failure]: (state) => ({
    ...state,
    initFiltersRequestStatus: requestStatuses.failure,
  }),
  [FETCH_MODIFIED_FILTERS.pending]: () => ({
    ...initState,
    modifiedFiltersRequestStatus: requestStatuses.pending,
  }),
  [FETCH_MODIFIED_FILTERS.success]: (state, { payload, tags }) => {
    const modifiedFilters = [
      ...payload,
      {
        field: FILTERS.status,
        values: ASSET_STATUSES_FILTER_AVAILABLE,
      },
    ];
    if (!isEmpty(tags))
      modifiedFilters.push({
        field: FILTERS.tags,
        values: ['None', ...tags.map(({ name }) => name)],
      });
    return {
      ...state,
      modifiedFiltersRequestStatus: requestStatuses.success,
      modifiedFilters,
    };
  },
  [FETCH_MODIFIED_FILTERS.failure]: (state) => ({
    ...state,
    modifiedFiltersRequestStatus: requestStatuses.failure,
  }),
  [FETCH_TARGET_FILTERS.pending]: () => ({
    ...initState,
    targetFiltersRequestStatus: requestStatuses.pending,
  }),
  [FETCH_TARGET_FILTERS.success]: (state, { payload, tags }) => {
    const targetFilters = [
      ...payload,
      {
        field: FILTERS.status,
        values: ASSET_STATUSES_FILTER_AVAILABLE,
      },
    ];

    if (!isEmpty(tags))
      targetFilters.push({
        field: FILTERS.tags,
        values: ['None', ...tags.map(({ name }) => name)],
      });

    return {
      ...state,
      targetFiltersRequestStatus: requestStatuses.success,
      targetFilters,
      activeTargetFilters: makeDefaultActiveFilters(targetFilters),
    };
  },
  [FETCH_TARGET_FILTERS.failure]: (state) => ({
    ...state,
    targetFiltersRequestStatus: requestStatuses.failure,
  }),

  [APPLY_FILTERS]: (state, { payload: { field, values } }) => ({
    ...state,
    activeFilters: state.activeFilters.map((filter) => (filter.field === field ? { field, values } : { ...filter })),
    activeTargetFilters: state.activeTargetFilters.map((filter) =>
      filter.field === field ? { field, values } : { ...filter },
    ),
  }),

  [SET_SEARCH]: (state, { payload }) => {
    const result = {
      ...state,
      search: payload,
    };
    return result;
  },

  [RESET_ACTIVE_FILTERS]: (state) => ({
    ...state,
    activeFilters: makeDefaultActiveFilters(state.initFilters),
    activeTargetFilters: makeDefaultActiveFilters(state.targetFilters),
  }),

  [RESET_FILTERS_DATA]: () => ({
    ...initState,
  }),
  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
