/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { cloneDeep, filter, omitBy, keyBy, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { SORT_ORDER, MAP_ORDER, SECTION_PAGE_TYPES } from 'modules/workLook/constants';
import { toObjectStructure } from 'modules/workLook/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  FETCH_REPORTS,
  UPDATE_TEXT_SUMMARY_CONTENT,
  SECTION_FILTER_APPLY,
  REPORT_FILTER_APPLY,
  SECTION_FILTER_RESET,
  REPORT_FILTER_RESET,
  ADD_TO_BASKET,
  BASKET_TOGGLE_CHECK_ALL,
  BASKET_TOGGLE_CHECK_SINGLE,
  BASKET_DELETE_CHECKED,
  BASKET_ADD_ALL,
  ADD_NEW_REPORT,
  DELETE_REPORT,
  SWITCH_IS_PUBLISHED,
  RESET_STATE,
} from 'modules/workLook/actions';

const initState = {
  requestStatus: requestStatuses.default,
  data: {},
};

export default createReducer(initState, {
  [FETCH_REPORTS.pending]: (state) => ({ ...state, requestStatus: requestStatuses.pending }),
  [FETCH_REPORTS.success]: (state, { payload }) => {
    const data = payload.reduce((result, report) => {
      result[report.id] = toObjectStructure(report);
      return result;
    }, {});

    return {
      ...state,
      requestStatus: requestStatuses.success,
      data,
    };
  },
  [FETCH_REPORTS.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
    data: initState.data,
  }),

  [ADD_NEW_REPORT]: (state, { payload: newReport }) => ({
    ...state,
    data: {
      ...state.data,
      [newReport.id]: toObjectStructure(newReport),
    },
  }),
  [DELETE_REPORT]: (state, { payload: reportId }) => {
    const deep = cloneDeep(state.data);

    delete deep[reportId];

    return {
      ...state,
      data: deep,
    };
  },
  [SWITCH_IS_PUBLISHED]: (state, { payload: { reportId, is_published, date_published } }) => ({
    ...state,
    data: {
      ...state.data,
      [reportId]: {
        ...state.data[reportId],
        is_published,
        date_published,
      },
    },
  }),

  [REPORT_FILTER_APPLY]: (state, { payload }) => {
    const { reportId, field, values } = payload;
    const deep = cloneDeep(state.data);

    deep[reportId].activeFilters.forEach((filter) => {
      if (filter.field === field) filter.values = values;
    });
    Object.values(deep[reportId].sections).forEach((section) => {
      Object.values(section.pages).forEach((page) => {
        page.activeFilters.forEach((filter) => {
          if (filter.field === field) filter.values = values;
        });
      });
    });

    return {
      ...state,
      data: deep,
    };
  },
  [SECTION_FILTER_APPLY]: (state, { payload }) => {
    const { reportId, sectionId, field, values } = payload;
    const deep = cloneDeep(state.data);

    Object.values(deep[reportId].sections[sectionId].pages).forEach((page) => {
      page.activeFilters.forEach((filter) => {
        if (filter.field === field) filter.values = values;
      });
    });

    return {
      ...state,
      data: deep,
    };
  },
  [REPORT_FILTER_RESET]: (state, { payload }) => {
    const { reportId } = payload;
    const deep = cloneDeep(state.data);

    deep[reportId].activeFilters.forEach((filter) => (filter.values = []));
    Object.values(deep[reportId].sections).forEach((section) => {
      Object.values(section.pages).forEach((page) => {
        page.activeFilters.forEach((filter) => (filter.values = []));
      });
    });

    return {
      ...state,
      data: deep,
    };
  },
  [SECTION_FILTER_RESET]: (state, { payload }) => {
    const { reportId, sectionId } = payload;
    const deep = cloneDeep(state.data);

    Object.values(deep[reportId].sections[sectionId].pages).forEach((page) => {
      page.activeFilters.forEach((filter) => (filter.values = []));
    });

    return {
      ...state,
      data: deep,
    };
  },

  [UPDATE_TEXT_SUMMARY_CONTENT]: (state, { payload }) => {
    const { reportId, sectionId, pageId, graphId, description } = payload;
    const deep = cloneDeep(state.data);

    deep[reportId].sections[sectionId].pages[pageId].graphs[graphId].description = description;

    return {
      ...state,
      data: deep,
    };
  },

  [ADD_TO_BASKET]: (state, { payload }) => {
    const {
      reportId,
      sectionId,
      pageId,
      mapType,
      dataCard,
      widgetsData,
      controlCardData,
      intSwitcher,
      timeScale,
      valueMethod,
    } = payload;
    const deepReport = cloneDeep(state.data[reportId]);

    const deepAddedPage = cloneDeep(deepReport.sections[sectionId].pages[pageId]);
    const addedPageInternalUUID = uuidv4();
    const basketItem = {
      ...deepAddedPage,
      internalId: addedPageInternalUUID,
      checked: false,
      widgetsData: widgetsData || null,
      controlCardData: controlCardData || null,
    };

    if (mapType) {
      basketItem.mapType = mapType;
      basketItem.mapOrder = MAP_ORDER[mapType];
    }
    if (intSwitcher) {
      basketItem.intSwitcher = intSwitcher;
    }
    if (timeScale) {
      basketItem.timeScale = timeScale;
    }
    if (valueMethod) {
      basketItem.valueMethod = valueMethod;
    }
    if (dataCard) {
      basketItem.dataCard = dataCard;
    }
    const hasSection = deepReport.basket && deepReport.basket[sectionId];
    if (hasSection) {
      deepReport.basket[sectionId].pages.push(basketItem);
    } else {
      deepReport.basket = {
        ...deepReport.basket,
        [sectionId]: {
          ...cloneDeep(deepReport.sections[sectionId]),
          pages: [basketItem],
        },
      };
    }

    deepReport.basket[sectionId].pages
      .sort(SORT_ORDER.byOrder)
      .sort(SORT_ORDER.byOrderAndId)
      .sort(SORT_ORDER.byOrderAndIdAndFilters)
      .sort(SORT_ORDER.byMapTypeOrder)
      .sort(SORT_ORDER.byControlCardData);

    return {
      ...state,
      data: {
        ...state.data,
        [reportId]: deepReport,
      },
    };
  },
  [BASKET_ADD_ALL]: (state, { payload: reportId }) => {
    const deepReport = cloneDeep(state.data[reportId]);

    deepReport.basket = cloneDeep(deepReport.sections);

    Object.values(deepReport.basket).forEach((section) => {
      const clonedPages = filter(
        cloneDeep(deepReport.sections[section.id].pages),
        ({ type }) => type !== SECTION_PAGE_TYPES.dashboard,
      );
      section.pages = Object.values(clonedPages)
        .map((page) => {
          return {
            ...page,
            internalId: uuidv4(),
            checked: false,
            mapType: 'STANDARD',
            intSwitcher: false,
            timeScale: '1 hour',
            valueMethod: 'average',
          };
        })
        .sort(SORT_ORDER.byOrder)
        .sort(SORT_ORDER.byOrderAndId)
        .sort(SORT_ORDER.byOrderAndIdAndFilters);
    });

    deepReport.basket = keyBy(
      filter(deepReport.basket, (el) => !isEmpty(el.pages)),
      ({ id }) => id,
    );

    return {
      ...state,
      data: {
        ...state.data,
        [reportId]: deepReport,
      },
    };
  },
  [BASKET_TOGGLE_CHECK_ALL]: (state, { payload }) => {
    const { reportId, newCheckboxState } = payload;
    const deepReport = cloneDeep(state.data[reportId]);

    Object.values(deepReport.basket).forEach((section) => {
      section.pages.forEach((page) => (page.checked = newCheckboxState));
    });

    return {
      ...state,
      data: {
        ...state.data,
        [reportId]: deepReport,
      },
    };
  },
  [BASKET_TOGGLE_CHECK_SINGLE]: (state, { payload }) => {
    const { reportId, sectionId, internalId } = payload;
    const deepReport = cloneDeep(state.data[reportId]);

    deepReport.basket[sectionId].pages.forEach((page) => {
      if (page.internalId === internalId) page.checked = !page.checked;
    });

    return {
      ...state,
      data: {
        ...state.data,
        [reportId]: deepReport,
      },
    };
  },
  [BASKET_DELETE_CHECKED]: (state, { payload: reportId }) => {
    const deepReport = cloneDeep(state.data[reportId]);

    Object.values(deepReport.basket).forEach((section) => {
      section.pages = section.pages.filter((page) => !page.checked);
    });

    deepReport.basket = omitBy(deepReport.basket, (section) => !section.pages.length);

    return {
      ...state,
      data: {
        ...state.data,
        [reportId]: deepReport,
      },
    };
  },

  [RESET_STATE]: () => ({
    ...initState,
  }),
});
