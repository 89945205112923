import React from 'react';
import PropTypes from 'prop-types';

import './HistoryReportListTitle.scss';

const transformDate = (date) => (date ? new Date(date).toDateString().slice(4) : 'no date');

const HistoryReportListTitle = ({ date: { start, end } }) => {
  const startDate = transformDate(start);
  const endDate = transformDate(end);

  return (
    <div className="HistoryReportListTitle">
      <div className="history-report-list-title">
        <div className="date">
          <span className="date-start">{startDate}</span>
          <span className="date-separator">&nbsp;-&nbsp;</span>
          <span className="date-end">{endDate}</span>
        </div>
      </div>
    </div>
  );
};

HistoryReportListTitle.defaultProps = {
  date: {
    start: 'Start date',
    end: 'End date',
  },
};

HistoryReportListTitle.propTypes = {
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

export default HistoryReportListTitle;
