/* eslint-disable consistent-return */
import { createReducer } from '@reduxjs/toolkit';

import { FILTERS, filtersPageTypes, weekday } from 'modules/liveLook/constants';
import { makeDefaultActiveFilters } from 'modules/shared/utils';
import { APPLY_FILTERS, FETCH_COMMON_DATA, RESET_FILTERS, RESET_STATE } from 'modules/liveLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  initMeetFilters: null,
  activeMeetFilters: null,
};

export default createReducer(initState, {
  [FETCH_COMMON_DATA.success]: (state, { payload }) => {
    if (!payload) {
      // eslint-disable-next-line no-console
      console.error('FETCH_COMMON_DATA::No payload', payload);
      return;
    }

    const { buildings, filters } = payload;
    const meetFilters = filters[filtersPageTypes.meetings];

    const initMeetFilters = [weekday];

    initMeetFilters.push({
      field: FILTERS.floor,
      values: meetFilters.floor,
    });

    initMeetFilters.push({
      field: FILTERS.subtype,
      values: meetFilters.subtype,
    });

    initMeetFilters.push({
      field: FILTERS.division,
      values: meetFilters.division,
    });

    initMeetFilters.push({
      field: FILTERS.neighbourhood,
      values: meetFilters.neighbourhood,
    });

    initMeetFilters.push({
      field: FILTERS.building_id,
      // eslint-disable-next-line camelcase
      values: buildings.map(({ building__property_name, building_id }) => ({
        label: building__property_name,
        value: building_id,
      })),
    });

    return {
      ...state,
      initMeetFilters,
      activeMeetFilters: makeDefaultActiveFilters(initMeetFilters),
    };
  },
  [FETCH_COMMON_DATA.failure]: (state) => {
    return state;
  },
  [APPLY_FILTERS]: (state, { payload: { field, values } }) => ({
    ...state,
    activeMeetFilters: state.activeMeetFilters.map((filter) =>
      filter.field === field ? { field, values } : { ...filter },
    ),
  }),
  [RESET_FILTERS]: (state) => ({
    ...state,
    activeMeetFilters: makeDefaultActiveFilters(state.initMeetFilters),
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
