/* eslint-disable no-shadow */
import { useEffect, useReducer } from 'react';
import { isEmpty } from 'lodash';

import { useSelector } from 'react-redux';
import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';

import { makeDefaultActiveFilters, API } from 'modules/shared/utils';

import { ALL_BUILDINGS, ALL_PRODUCTS, ASSET_STATUSES_FILTER_AVAILABLE, FILTERS } from 'modules/assetLook/constants';
import { ENDPOINTS } from 'modules/shared/constants';
import { reducer, FETCH_INIT_FILTERS, initialState } from './_reducer';

export const getFiltersList = (array) => {
  if (!array.length) return [];
  return array.flatMap(({ values }) => {
    if (values instanceof Array) {
      return values;
    }
    return Object.keys(values);
  });
};

export const getFiltersObject = (array) => {
  if (!array.length) return [];
  return array.flatMap(({ field, values }) => {
    if (values instanceof Array) {
      return { field, filtersList: values, isArray: true };
    }
    return { field, filtersList: Object.keys(values), isArray: false };
  });
};

export const onSelectFilter = (value, formik, initialFilters) => {
  const { field, filtersList, isArray } = getFiltersObject(initialFilters).find(({ filtersList }) =>
    filtersList.includes(value),
  );

  formik.setFieldValue(
    'activeFilters',
    formik.values.activeFilters.map((filter) =>
      filter.field === field
        ? { field, values: filter.values.concat(isArray ? `${filtersList.indexOf(value)}` : value) }
        : { ...filter },
    ),
  );
};

export const useInitFilters = (formik) => {
  const { id: companyId } = useSelector(getActiveCompany);

  const [tags] = useAssetLookSelector(['common.tags']);

  const [{ requestStatus, initialFilters }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let isCanceled = false;

    dispatch(FETCH_INIT_FILTERS.pending());

    const url = ENDPOINTS.assetLook.initFilters.replace(':companyId', companyId);
    const data = {
      building_id: formik.values.buildings.includes(ALL_BUILDINGS.id) ? null : formik.values.buildings,
      category_id: formik.values.categories.includes(ALL_PRODUCTS.id) ? null : formik.values.categories,
    };

    API.post(url, data)
      .then(({ data }) => {
        if (isCanceled) return;

        const initialFilters = [
          ...data,
          {
            field: FILTERS.status,
            values: ASSET_STATUSES_FILTER_AVAILABLE,
          },
        ];

        if (!isEmpty(tags))
          initialFilters.push({
            field: FILTERS.tags,
            values: ['None', ...tags.map(({ name }) => name)],
          });

        formik.setFieldValue('activeFilters', makeDefaultActiveFilters(initialFilters));
        dispatch(FETCH_INIT_FILTERS.success(initialFilters));
      })
      .catch(() => {
        if (isCanceled) return;
        dispatch(FETCH_INIT_FILTERS.failure());
      });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
    // eslint-disable-next-line
  }, [JSON.stringify(formik.values.buildings), JSON.stringify(formik.values.categories)]);

  return { requestStatus, initialFilters };
};
