/* eslint-disable camelcase */
import React from 'react';
import { isEmpty } from 'lodash';
import DataWidget from '../../DataWidget/DataWidget';

const GraphDataWidgetContainer = ({ isLoading, description, order, sub_title, title, data }) => {
  if (isEmpty(data)) return null;
  return (
    <DataWidget
      data={data}
      isLoading={isLoading}
      order={order}
      description={description}
      subTitle={sub_title}
      title={title}
    />
  );
};

export default GraphDataWidgetContainer;
