import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isDefault } from 'modules/shared/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  getAppliedFilters,
  getSelectedFilters,
  getKeyFindingsBreakoutData,
  getPdfMode,
} from 'modules/spaceLook/selectors';
import { fetchBreakoutDataboxes, fetchBreakoutPieData, fetchBreakoutTextData } from 'modules/spaceLook/actions';
import { LoadingCondition } from 'modules/spaceLook/components';

import KeyFindingsBreakout from './KeyFindingsBreakout';

const KeyFindingsBreakoutContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fetchStart, setFetchStart] = useState(false);
  const pdfMode = useSelector(getPdfMode);
  const { dataRequestStatuses, data, shouldRefetchData } = useSelector(getKeyFindingsBreakoutData);
  const { selectedFiltersUrlParams } = useSelector((store) => getSelectedFilters(store, 'breakout'));
  const { appliedFiltersRequestStatus } = useSelector(getAppliedFilters);

  const isNoData = Object.values(data).some((dataItem) => !dataItem);
  const isLoading = Object.values(dataRequestStatuses).some((status) => status === requestStatuses.pending);
  const isFiltersHistoryLoading = [requestStatuses.default, requestStatuses.pending].includes(
    appliedFiltersRequestStatus,
  );

  useEffect(() => {
    if (isFiltersHistoryLoading) return;

    if (isDefault(dataRequestStatuses.databoxes) || shouldRefetchData.databoxes) {
      fetchBreakoutDataboxes(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.pieData) || shouldRefetchData.pieData) {
      fetchBreakoutPieData(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.textData) || shouldRefetchData.textData) {
      fetchBreakoutTextData(dispatch, id, selectedFiltersUrlParams);
    }

    setFetchStart(true);
  }, [
    appliedFiltersRequestStatus,
    dataRequestStatuses.databoxes,
    dataRequestStatuses.pieData,
    dataRequestStatuses.textData,
    dispatch,
    id,
    isFiltersHistoryLoading,
    selectedFiltersUrlParams,
    shouldRefetchData.databoxes,
    shouldRefetchData.pieData,
    shouldRefetchData.textData,
  ]);

  return (
    <LoadingCondition isLoading={isLoading} isNoData={isNoData} fetchStart={fetchStart}>
      <KeyFindingsBreakout isLoading={isLoading} pdfMode={pdfMode} {...data} />
    </LoadingCondition>
  );
};

export default KeyFindingsBreakoutContainer;
