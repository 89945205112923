import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';
import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

export const FETCH_INIT_FILTERS = createAsyncAction(createAction('FETCH_INIT_FILTERS'));

const fetchInitFilters = (dispatch, id) => {
  dispatch(FETCH_INIT_FILTERS.pending());

  const token = isLogged();
  const desksUrl = ENDPOINTS.desksInitFilters.replace(':id', id);
  const meetsUrl = ENDPOINTS.meetingsInitFilters.replace(':id', id);
  const breakUrl = ENDPOINTS.breakoutInitFilters.replace(':id', id);
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  Promise.all([axios.get(desksUrl, config), axios.get(meetsUrl, config), axios.get(breakUrl, config)])
    .then((initAll) => {
      dispatch(
        FETCH_INIT_FILTERS.success({
          desks: initAll[0].data,
          meetings: initAll[1].data,
          breakout: initAll[2].data,
        }),
      );
    })
    .catch(() => dispatch(FETCH_INIT_FILTERS.failure()));
};

export default fetchInitFilters;
