import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';

// eslint-disable-next-line import/no-cycle
import { CustomButtonContained } from 'modules/shared/components';

import theme from './ConfirmationDialog.module.scss';

const ConfirmationDialog = ({
  open,
  _onClose,
  DialogProps,

  title,
  text,

  firstActionLabel = 'Cancel',
  FirstActionProps,
  _onFirstActionClick,
  secondActionLabel = 'Delete',
  SecondActionProps,
  _onSecondActionClick,
}) => (
  <Dialog
    open={open}
    onClose={_onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    {...DialogProps}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
    </DialogContent>
    <DialogActions className={theme.actions}>
      <Button
        autoFocus
        color="primary"
        // eslint-disable-next-line react/no-children-prop
        children={firstActionLabel}
        onClick={_onFirstActionClick || _onClose}
        {...FirstActionProps}
      />
      <CustomButtonContained label={secondActionLabel} onClick={_onSecondActionClick} {...SecondActionProps} />
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
