import { byDivAndDepGraphTypes } from 'modules/spaceLook/constants';

const DesksDivLowestOcc = ({ divOrDepData, type }) => {
  if (divOrDepData.length < 1) return null;

  const lowest = divOrDepData.reduce(
    (min, current) => (min.percent_max_occupancy > current.percent_max_occupancy ? current : min),
    divOrDepData[0],
  );

  const divOrDep = type === byDivAndDepGraphTypes.div ? 'division' : 'department';

  return `The lowest peak occupancy was ${lowest.name} ${divOrDep} with a peak of ${lowest.percent_max_occupancy}%
     and an average occupancy of ${lowest.percent_avg_occupancy}%.`;
};

export default DesksDivLowestOcc;
