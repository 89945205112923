/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';
import { toRadarChartData } from 'modules/workLook/utils';

// eslint-disable-next-line import/no-cycle
import RadarChart from './RadarChart';

const RadarChartContainer = ({
  id,
  type_of_group,
  activeFilters,
  type,
  isModal,
  graphData,
  isPdf,
  isPublished,
  ...props
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters, graphData);

  return (
    <RadarChart
      isPdf={isPdf}
      graphId={id}
      type={type}
      isPublished={isPublished}
      isLoading={isLoading}
      data={toRadarChartData(data, type_of_group, isModal)}
      groupType={type_of_group}
      isModal={isModal}
      modalData={!isModal && { ...props, id, type_of_group, activeFilters, type, graphData: data }}
      {...props}
    />
  );
};

export default RadarChartContainer;
