import { useMapStartZoom } from 'modules/shared/utils';
import React from 'react';
import { Popup, LayerGroup, CircleMarker } from 'react-leaflet';
import renderPopupData from '../utils';

function SizeComponent({ data, maxValue, dataValue }) {
  useMapStartZoom(data);
  const getRadius = (size) => {
    return (size / maxValue) * 30;
  };

  return (
    <LayerGroup>
      {data.map(({ id, coordinates, ...rest }) => {
        return (
          <CircleMarker key={id} id={id} center={coordinates} color="#e8869a" radius={getRadius(rest[dataValue])}>
            <Popup>{renderPopupData({ ...rest })}</Popup>
          </CircleMarker>
        );
      })}
    </LayerGroup>
  );
}

export default SizeComponent;
