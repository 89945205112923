import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Typography, Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { ERROR_TEXT } from 'modules/shared/constants';

import theme from './TextPlaceholder.module.scss';

const TextPlaceholder = ({ text, absolute, column, error, noData, notFound }) => (
  <div
    className={cn(theme.container, {
      absolute,
      column,
    })}
  >
    <InfoIcon
      style={{
        marginRight: column ? 'unset' : '16px',
        marginBottom: column ? '16px' : 'unset',
      }}
    />
    <Typography variant="h5" style={{ textAlign: column ? 'center' : 'unset' }}>
      <Box fontWeight="fontWeightMedium" component="span">
        <Choose>
          <When condition={error}>{ERROR_TEXT}</When>
          <When condition={noData}>No data available</When>
          <When condition={notFound}>404: Not found</When>
          <Otherwise>{text}</Otherwise>
        </Choose>
      </Box>
    </Typography>
  </div>
);

TextPlaceholder.propTypes = {
  text: PropTypes.string,
  absolute: PropTypes.bool,
  error: PropTypes.bool,
  noData: PropTypes.bool,
  notFound: PropTypes.bool,
};

TextPlaceholder.defaultProps = {
  text: 'No data available',
  absolute: false,
  error: false,
  noData: false,
  notFound: false,
};

export default TextPlaceholder;
