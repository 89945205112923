import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getUser } from 'modules/auth/selectors';
import { isAdmin, isClient, isPartner, isViewOnly } from 'modules/shared/utils';
import theme from './GraphHeader.module.scss';
import ExternalLink from '../ExternalLink';

const GraphHeader = ({
  title,
  subTitle,
  graphId,
  isPdf,
  onClick,
  showTitle,
  isPublished,
  onModifierClick,
  display,
}) => {
  // TODO: Add useEllipsis hook;
  const { user } = useSelector(getUser);
  // eslint-disable-next-line camelcase
  const { people_look_editing_rights, role } = user;
  const hasViewRights = isViewOnly(people_look_editing_rights);
  const isAdminRole = isAdmin(role) || isPartner(role);
  const isExectAdmin = isAdmin(role);
  const isClientRole = isClient(role);
  const clientRights = isClientRole && !hasViewRights && !isPdf && !isPublished;
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <If condition={showTitle}>
            <Typography variant="h6" className={theme.title}>
              {title}
            </Typography>
          </If>
          <If condition={subTitle}>
            <Box className={theme.subTitle}>{subTitle}</Box>
          </If>
        </div>
        <If condition={clientRights || (isAdminRole && !isPdf && !isPublished)}>
          <ExternalLink
            display={display}
            isAdminRole={isExectAdmin}
            graphId={graphId}
            onClick={onClick}
            onModifierClick={onModifierClick}
          />
        </If>
      </div>
    </>
  );
};

export default GraphHeader;
