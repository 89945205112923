import React, { useState } from 'react';
import { Table, Column, Plugins } from 'fixed-data-table-2';

import { makeStyles } from '@material-ui/core';

import './fixed-data-table.css';

const HEADER_DEFAULT_HEIGHT = 36;
const ROW_DEFAULT_HEIGHT = 34;

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '4px',
    outline: 'none',
  },
  row: {
    '&:hover .public_fixedDataTableCell_main': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

/**
 * Table sharable component with a fixed header and configurable fixed columns.
 *
 * @param {int} width - Table width
 * @param {int} height - Table height
 * @param {int} [headerHeight] - Header height (optional)
 * @param {int} [rowHeight] - Row height (optional)
 * @param {array} rows - Data to fill rows
 * @param {function} [onRowClick] - On row click callback (optional)
 * @param {boolean} [showScrollbarX] - Hide/show the scrollbar. By default is true.
 * @param {boolean} [showScrollbarY] - Hide/show the scrollbar. By default is true.
 *
 * @param {array} columns - Array of column keys.
 * @param {function} [columnWidth] - Callback that calculates the column width (optional)
 * @param {function} [columnFixed] - Callback that calculates if column is fixed (optional). By default first column is fixed.
 * @param {function} [columnFlexGrow] - Callback that calculates column's flex grow value. By default all columns have flexGrow = 1.
 * @param {ReactElement} ColumnHeader - Component that renders header cells
 * @param {ReactElement} ColumnCell - Component that renders body cells
 * @returns {JSX.Element}
 */

function FixedDataTable({
  width,
  height,
  headerHeight = HEADER_DEFAULT_HEIGHT,
  rowHeight = ROW_DEFAULT_HEIGHT,
  rows,
  onRowClick,
  showScrollbarX,
  showScrollbarY,

  columns,
  columnWidth = () => 200,
  columnFixed = (columnKey, i) => i === 0,
  columnFlexGrow = () => 1,
  ColumnHeader,
  ColumnCell,
}) {
  const classes = useStyles();
  const [columnWidths, setColumnWidths] = useState({});
  const _onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
    setColumnWidths((prevColumnWidths) => ({
      ...prevColumnWidths,
      [columnKey]: newColumnWidth,
    }));
  };

  return (
    <Table
      width={width}
      height={height}
      headerHeight={headerHeight}
      rowHeight={rowHeight}
      className={classes.container}
      rowsCount={rows.length}
      rowClassNameGetter={() => classes.row}
      onRowClick={onRowClick}
      touchScrollEnabled
      keyboardScrollEnabled
      showScrollbarX={showScrollbarX}
      showScrollbarY={showScrollbarY}
    >
      {columns.map((columnKey, i) => {
        // eslint-disable-next-line no-shadow
        const width = columnWidth && columnWidth(columnKey, i, columns);
        const fixed = columnFixed && columnFixed(columnKey, i, columns);
        const flexGrow = columnFlexGrow && columnFlexGrow(columnKey, i, columns);

        return (
          <Column
            key={i}
            columnKey={columnKey}
            header={
              <Plugins.ResizeCell
                onColumnResizeEnd={(newColumnWidth) => _onColumnResizeEndCallback(newColumnWidth, columnKey)}
              >
                <ColumnHeader data={rows} />
              </Plugins.ResizeCell>
            }
            cell={<ColumnCell data={rows} />}
            fixed={fixed}
            width={columnWidths[columnKey] || width}
            flexGrow={flexGrow}
            pureRendering
          />
        );
      })}
    </Table>
  );
}

export default FixedDataTable;
