import React from 'react';
import { useDispatch } from 'react-redux';

import { HIDE_SECOND_MODAL } from 'modules/modalsSystem/actions';
import { Modal } from '@material-ui/core';
import ConfirmDeletionContainer from './ConfirmDeletionContainer';

const ConfirmDeletionModal = () => {
  const dispatch = useDispatch();

  const onClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(HIDE_SECOND_MODAL());
    }
  };

  return (
    <Modal open onClose={onClose}>
      <ConfirmDeletionContainer onClose={onClose} />
    </Modal>
  );
};

export default ConfirmDeletionModal;
