import React from 'react';
import cn from 'classnames';
import { Grid, Typography, Box } from '@material-ui/core';

import filterConfig from 'modules/liveLook/components/UsageOverTimeGraph/usageOverTime.utils';
import theme from './GraphLegend.module.scss';

const GraphLegend = ({ config, pdfMode, marginTop, isSwitcherOn }) => {
  const { newConfigArray } = filterConfig(isSwitcherOn, config);

  return (
    <div
      className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}
      style={{
        marginTop,
      }}
    >
      <Grid container spacing={2}>
        {newConfigArray.map(({ iconType, label, legendLabel, color }, i) => (
          <Grid key={i} className={theme.legendItem}>
            <div
              className={cn(theme.icon, {
                [`${theme.round}`]: iconType === 'round',
                [`${theme.line}`]: iconType === 'line',
              })}
              style={{ backgroundColor: color }}
            />
            <Typography variant={pdfMode ? 'body2' : 'body1'}>
              <Box fontWeight={pdfMode ? 'normal' : 'fontWeightMedium'} component="span">
                {legendLabel || label}
              </Box>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

GraphLegend.defaultProps = {
  xs: 12,
  sm: 6,
  md: 3,
  lg: 3,
  xl: 2,
  marginTop: '16px',
};

export default GraphLegend;
