import GroupsIcon from '@mui/icons-material/Groups';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DeskIcon from '@mui/icons-material/Desk';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import DvrIcon from '@mui/icons-material/Dvr';
import CallIcon from '@mui/icons-material/Call';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TrainIcon from '@mui/icons-material/Train';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import ForestIcon from '@mui/icons-material/Forest';
import GrassIcon from '@mui/icons-material/Grass';
import Co2Icon from '@mui/icons-material/Co2';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import BuildIcon from '@mui/icons-material/Build';
import Groups2Icon from '@mui/icons-material/Groups2';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DescriptionIcon from '@mui/icons-material/Description';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import EuroIcon from '@mui/icons-material/Euro';
import PlaceIcon from '@mui/icons-material/Place';
import ConstructionIcon from '@mui/icons-material/Construction';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MapIcon from '@mui/icons-material/Map';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';

export const HEADER_ICONS = {
  PEOPLE: GroupsIcon,
  BUILDINGS: ApartmentIcon,
  DESKS: DeskIcon,
  ROOMS: MeetingRoomIcon,
  CHAIRS: EventSeatIcon,
  COMPUTER_SCREENS: DvrIcon,
  TELEPHONES: CallIcon,
  LARGE_TV: LiveTvIcon,
  TEAMS: Diversity3Icon,
  TRUCK: LocalShippingIcon,
  CAR: DirectionsCarIcon,
  PERSON_WALKING: DirectionsWalkIcon,
  BUS: DirectionsBusIcon,
  TRAIN: TrainIcon,
  JOURNEYS: AddRoadIcon,
  TREES: ForestIcon,
  PLANTS: GrassIcon,
  TONNES_CO2: Co2Icon,
  FILING_CABINETS: CorporateFareIcon,
  MECHANICAL_ITEMS: HeatPumpIcon,
  COGS: BuildIcon,
  MEETING: Groups2Icon,
  DOOR: DoorBackIcon,
  OTHER_GENERAL_ITEMS: AttachFileIcon,
  DOCUMENT: DescriptionIcon,
  UP_ARROW: FileUploadIcon,
  DOWN_ARROW: DownloadIcon,
  LOCATION: MyLocationIcon,
  DOLLAR: AttachMoneyIcon,
  POUND: CurrencyPoundIcon,
  EURO: EuroIcon,
  PIN: PlaceIcon,
  PROJECT: ConstructionIcon,
  ASSETS: LocalOfferIcon,
  MAP: MapIcon,
  PROCESS: MiscellaneousServicesIcon,
  IMAGES: PhotoCameraIcon,
  HAPPY: SentimentSatisfiedAltIcon,
  NEUTRAL: SentimentNeutralIcon,
  SAD: SentimentVeryDissatisfiedIcon,
  WORK: WorkIcon,
  HOME: HomeIcon,
};
