import React from 'react';
import ReactTable from 'react-table';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { getPdfMode } from 'modules/spaceLook/selectors';
import { StatsTableCell, StatsTableHeadCell } from 'components';

import './StatsTable.scss';
import 'react-table/react-table.css';

const StatsTable = ({ rows, headerLabels }) => {
  const pdfMode = useSelector(getPdfMode);

  const headerItems = headerLabels.map(({ label, key }) => {
    return {
      Header: () => <StatsTableHeadCell label={label} pdfMode={pdfMode} />,
      accessor: key,
      // eslint-disable-next-line react/destructuring-assignment
      Cell: (props) => <StatsTableCell label={props.value} pdfMode={pdfMode} />,
      width: 175,
    };
  });

  return (
    <div className={cn('StatsTable', { pdf: pdfMode })}>
      <div className="stats-table">
        <ReactTable columns={headerItems} data={rows} minRows="auto" showPagination={false} defaultPageSize={100} />
      </div>
    </div>
  );
};

export default StatsTable;
