import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_ARCHIVES = createAsyncAction(createAction('FETCH_ARCHIVES'));

const fetchArchive = (dispatch, isCanceled, { companyId }) => {
  dispatch(FETCH_ARCHIVES.pending());

  const url = ENDPOINTS.liveLook.archives.replace(':companyId', companyId);

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_ARCHIVES.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_ARCHIVES.failure());
    });
};

export default fetchArchive;
