import React from 'react';
import { Tooltip, Typography, Box, makeStyles } from '@material-ui/core';

import theme from './HourlyGraphLines.module.scss';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: '8px',
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  arrow: {
    color: 'white',
  },
}));

const HourlyGraphLines = ({ linesData, config, marginBottom }) => {
  const classes = useStyles();

  return config.map(({ label, valueKey, percentKey, color }) => {
    const TooltipContent = () => (
      <>
        <Typography variant="body1">
          <Box fontWeight="fontWeightMedium" component="span">
            {label}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box fontWeight="fontWeightMedium" component="span">
            In use slots:
          </Box>
          {` ${linesData[valueKey]} (${linesData[percentKey]}%)`}
        </Typography>
      </>
    );

    return (
      <Tooltip
        arrow
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        title={<TooltipContent />}
        key={label}
      >
        <div
          className={theme.graphLine}
          style={{
            bottom: `calc((100% - ${marginBottom + 5}px) * (${linesData[percentKey]} / 100) + ${marginBottom}px)`,
            backgroundColor: color,
          }}
        />
      </Tooltip>
    );
  });
};

export default HourlyGraphLines;
