import { sortItems } from 'modules/assetLook/utils';
import { ASC, DESC } from 'modules/shared/constants';

function sortAction(state, payload, sortParamsKey, dataKey) {
  const { orderBy, direction } = state[sortParamsKey];
  const newDirection = orderBy === payload && direction === ASC ? DESC : ASC;

  return {
    ...state,
    [`${dataKey}`]: {
      ...state[dataKey],
      data: sortItems(state[dataKey].data, payload, newDirection),
    },
    [`${sortParamsKey}`]: {
      ...state[sortParamsKey],
      orderBy: payload,
      direction: newDirection,
    },
  };
}

export default sortAction;
