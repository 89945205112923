/* eslint-disable camelcase */
import React from 'react';
import { HorizontalBar, Bar } from 'react-chartjs-2';
import { Grid, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { GROUP_TYPES } from 'modules/workLook/constants';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { TextPlaceholder } from 'modules/shared/components';
import { useEditGraphClick, useGraphClick, useModifierGraph } from 'modules/workLook/hooks';
import options from './options';
import theme from './BarChart.module.scss';

const BarChart = ({
  title,
  sub_title,
  isLoading,
  data,
  groupType,
  is_display_legend: showLegend,
  is_display_title: showTitle,
  isHorizontalBar,
  axis_min_limit: min,
  axis_max_limit: max,
  modalData,
  isModal,
  subtitle,
  graphId,
  isPublished,
  isPdf,
  type,
}) => {
  const { onClick } = useGraphClick(isModal, modalData);
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, groupType);

  const ChartComponent = isHorizontalBar ? HorizontalBar : Bar;

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && !data}>
        <Paper elevation={2} className={theme.container}>
          <GraphHeader
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={subtitle}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper elevation={2} className={isModal ? theme.container : theme.containerModal} onClick={onClick}>
          <Grid container spacing={2} direction="column" className={theme.gridContainer}>
            <Grid item>
              <GraphHeader
                showTitle={showTitle}
                graphId={graphId}
                title={title}
                subTitle={sub_title}
                isPdf={isPdf}
                onClick={onEditClick}
                onModifierClick={onModifierClick}
                isPublished={isPublished}
              />
            </Grid>
            <Grid item className={theme.innerContainer}>
              <ChartComponent
                data={data}
                options={{
                  ...options,
                  legend: {
                    ...options.legend,
                    display: groupType === GROUP_TYPES.byName && showLegend,
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: Number.isFinite(min) && !isHorizontalBar ? min : undefined,
                          max: Number.isFinite(max) && !isHorizontalBar ? max : undefined,
                        },
                      },
                    ],
                    xAxes: [
                      {
                        ticks: {
                          min: Number.isFinite(min) && isHorizontalBar ? min : undefined,
                          max: Number.isFinite(max) && isHorizontalBar ? max : undefined,
                        },
                      },
                    ],
                  },
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Otherwise>
    </Choose>
  );
};

export default BarChart;
