/* eslint-disable func-names */
/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './MainButton.scss';

const MainButton = ({ text, func, link, customClass }) => {
  const classes = cn('MainButton', customClass);
  return (
    <div className={classes}>{link ? <LinkButton text={text} link={link} /> : <Button text={text} func={func} />}</div>
  );
};

const LinkButton = function ({ text, link }) {
  return (
    <a href={link} rel="noopener noreferrer" target="_blank" download>
      <div className="main-button">{text}</div>
    </a>
  );
};

const Button = function ({ text, func }) {
  return (
    <button onClick={func} type="button" className="main-button">
      {text}
    </button>
  );
};

MainButton.defaultProps = {
  text: 'Button text',
  // eslint-disable-next-line no-console
  func: () => console.log('test func'),
  customClass: '',
  // eslint-disable-next-line react/default-props-match-prop-types
  link: null,
};

MainButton.propTypes = {
  text: PropTypes.string,
  func: PropTypes.func,
  customClass: PropTypes.string,
};

export default MainButton;
