import React from 'react';
import { Grid, Typography } from '@material-ui/core';

function ErrorMessage({ error }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={3} />
      <Grid item xs={9}>
        <Typography color="error">{error}</Typography>
      </Grid>
    </Grid>
  );
}

export default ErrorMessage;
