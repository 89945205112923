import React from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

import { reportPageTypes } from 'modules/spaceLook/constants';

import {
  PageHeader,
  ReportText,
  FilterHandler,
  ReportDataCards,
  ReportFilter,
  DesksUsageBandingGraph,
} from 'modules/spaceLook/components';
import { useHasScroll } from 'modules/shared/hooks';

const DesksUsageBandingOccupied = ({ isLoading, pdfMode, databoxes, usageBandingOccGraphData }) => {
  const scroll = useHasScroll();
  return (
    <div ref={scroll.ref} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Desk Usage Banding - Occupied" text="Desk Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.desks}>
              <ReportFilter pageType={reportPageTypes.desks} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.desks} cropped={!pdfMode} />
        </Grid>

        <Grid item xs={12}>
          <DesksUsageBandingGraph
            label="Occupancy of Individual Desks During Study Period"
            graphData={usageBandingOccGraphData}
            barColor="#00aad2"
          />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={[
              'desksUsageBandingZero',
              'desksUsageBandingLess20',
              'desksUsageBandingLess50',
              'desksUsageBandingMore80',
            ]}
            graphData={usageBandingOccGraphData}
            usageBandingType="OCCUPIED"
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DesksUsageBandingOccupied;
