const ONLY_NUMBERS = /^[0-9]*$/;
const ONLY_NUMBERS_AND_LOWERCASE_LETTERS = /^[a-z0-9]+$/;
/*
  The password length must be greater than or equal to 8
  The password must contain 3 or more lowercase characters
  The password must contain 2 or more uppercase characters
  The password must contain 2 or more numeric values
*/
const STRONG_PASSWORD = /^(?=.*\d.*\d)(?=.*[a-z].*[a-z].*[a-z])(?=.*[A-Z].*[A-Z]).{8,}$/;

// REQUESTS
const baseUrl = process.env.REACT_APP_API_URL || 'https://api.dev.bakerstuart.wesoftyou.club';

const REQUESTS = {
  base: baseUrl,
  version: '/v1',
  reAuth: '/auth/user/',
  otpCreate: '/auth/otp/create/',
  otpLogin: '/auth/otp/login/',
  otpStaticLogin: '/auth/otp/static-login/',
  historyReport: '/survey/list/',
  reset: '/auth/password/reset/',
  resetConfirm: '/auth/password/reset/confirm/',
  filterHistory: '/filter-history/',
  summary: {
    databoxes: '/properties/locationlook-overall-stats/',
    buildings: '/properties/locationlook-property-list/',
    filter: '/properties/',
    filterSubmit: '/properties/filter-submit/',
    graphs: {
      deskUtilizationDivision: '/locationlook-graph-capacity-data-division/',
      deskUtilizationProperty: '/locationlook-graph-capacity-data-property/',
      deskCountProperty: '/locationlook-graph-desk-data-property/',
      deskCountDivision: '/location-look-graph-desk-data-division/',
      property: '/properties/locationlook-graph-data-property/',
      division: '/properties/locationlook-graph-data-division/',
      propertyPercent: '/properties/locationlook-graph-capacity-data-property/',
      divisionPercent: '/properties/locationlook-graph-capacity-data-division/',
    },
    table: '/locationlook-property-list/',
  },
  details: {
    buildings: '/properties/locationlook-property-list/',
    properties: '/properties/list/',
  },
};

const BASE_REPORTS_URL = '/main';

const REDIRECT_URLS = {
  login: '/login',
  reports: '/report-history',
  summary: '/summary',
  details: '/details',
  twoFactorAuth: '/two-factor-auth',
  downloads: `${BASE_REPORTS_URL}/:id/downloads`,
};

export { REDIRECT_URLS, REQUESTS, BASE_REPORTS_URL, ONLY_NUMBERS, ONLY_NUMBERS_AND_LOWERCASE_LETTERS, STRONG_PASSWORD };
