/* eslint-disable camelcase */
import { roundToFloat } from 'utils';

const BenchOccPreAgile = ({ databoxes: { percent_max_occupancy }, textData: { percent_peak_occupancy_non_agile } }) => {
  const occDiff = roundToFloat(percent_peak_occupancy_non_agile - percent_max_occupancy);
  const higherOrLower = occDiff > 0 ? 'lower' : 'higher';

  return `Peak occupancy measured was ${percent_max_occupancy}% which is ${Math.abs(occDiff)}%
     ${higherOrLower} than our pre agile working benchmark of ${percent_peak_occupancy_non_agile}%.`;
};

export default BenchOccPreAgile;
