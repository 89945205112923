import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { SidebarHeader, Copyright } from 'modules/shared/components';

import HeatmapItem from './HeatmapItem';
import ReportNavItem from './ReportNavItem';

import config from './config';
import theme from './Sidebar.module.scss';

const Sidebar = ({ heatmapData, activeReportId, activeHeatmapId, pathname }) => (
  <div className={theme.container}>
    <SidebarHeader />

    <List>
      <Link to={config.reportHistory.path}>
        <ListItem className={theme.listItem}>
          <ListItemIcon className={theme.icon}>
            <img src={config.reportHistory.icon} alt="File icon" />
          </ListItemIcon>
          <ListItemText primary={config.reportHistory.label} primaryTypographyProps={{ className: theme.text }} />
        </ListItem>
      </Link>

      {config.reportNav.map((item) => (
        <ReportNavItem key={item.label} activeReportId={activeReportId} pathname={pathname} {...item} />
      ))}

      <If condition={heatmapData && heatmapData.length}>
        <HeatmapItem
          items={heatmapData}
          activeReportId={activeReportId}
          activeHeatmapId={activeHeatmapId}
          {...config.heatmaps}
        />
      </If>

      <Link to={config.downloads.path.replace(':id', activeReportId)}>
        <ListItem className={theme.listItem}>
          <ListItemIcon className={theme.icon}>
            <img src={config.downloads.icon} alt="Downloads icon" />
          </ListItemIcon>
          <ListItemText primary={config.downloads.label} primaryTypographyProps={{ className: theme.text }} />
        </ListItem>
      </Link>
    </List>

    <Copyright style={{ marginTop: 'auto', marginBottom: '16px' }} />
  </div>
);

export default Sidebar;
