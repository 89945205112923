import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { getPdfMode } from 'modules/spaceLook/selectors';

import { lowerFontSize } from 'modules/shared/utils';
import Paragraph from './components/_Paragraph';
import config from './components/config';
import theme from './ReportText.module.scss';

/**
 * @param getParagraphs - array of keys that we get from doorsDailyConfig;
 * @param repeat -
 *    key = doorsDailyConfig key;
 *    value = number of times to repeat;
 * @param props - databoxes, textData etc... that text component require;
 * @returns the text component by doorsDailyConfig wrapped in Typography;
 */

const ReportText = ({ getParagraphs, repeat, scrollRef, ...props }) => {
  const pdfMode = useSelector(getPdfMode);
  const ref = useRef();
  useMemo(() => {
    if (ref.current && scrollRef.ref.current) {
      lowerFontSize(scrollRef.hasVertScroll, ref.current, pdfMode, scrollRef.ref.current, true);
    }
  }, [scrollRef, pdfMode, ref]);

  return (
    <div ref={ref}>
      <div className={cn(theme.reportTextContainer, { [`${theme.pdfMode}`]: pdfMode })}>
        {getParagraphs.map((key) => {
          if (repeat[key]) {
            const repeatedList = [];

            for (let repeatNum = 0; repeatNum < repeat[key]; repeatNum++) {
              repeatedList.push(
                <Paragraph key={key + repeatNum} pdfMode={pdfMode}>
                  {config[key].map((Component, i) => (
                    <Component key={i} repeatN={repeatNum} {...props} />
                  ))}
                </Paragraph>,
              );
            }

            return repeatedList;
          }

          return (
            <Paragraph key={key} pdfMode={pdfMode}>
              {config[key].map((Component, i) => (
                <Component key={i} {...props} />
              ))}
            </Paragraph>
          );
        })}
      </div>
      {pdfMode && <div style={{ height: '15px', width: '100%' }} />}
    </div>
  );
};

ReportText.propTypes = {
  getParagraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
  repeat: PropTypes.objectOf(PropTypes.number),
  scrollRef: PropTypes.object,
};

ReportText.defaultProps = {
  repeat: {},
  scrollRef: {},
};

export default ReportText;
