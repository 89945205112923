import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getReports } from 'modules/workLook/selectors';

const useActiveReport = () => {
  const { reportId } = useParams();
  const { reportData } = useSelector(getReports);

  return [reportData[reportId], reportId];
};

export default useActiveReport;
