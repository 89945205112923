import { byBuildingAndFloorTypes } from 'modules/spaceLook/constants';

const DesksPropertyOrFloorHighestOcc = ({ buildingAndFloorData: { data, type } }) => {
  const highest = data.reduce(
    (max, current) => (max.percent_max_occupancy < current.percent_max_occupancy ? current : max),
    data[0],
  );

  const buildingOrFloor = type === byBuildingAndFloorTypes.building ? 'Building' : 'Floor';

  return `${buildingOrFloor} ${highest.name} had the highest occupancy with a peak of ${highest.percent_max_occupancy}%
     and an average occupancy of ${highest.percent_avg_occupancy}%.`;
};

export default DesksPropertyOrFloorHighestOcc;
