const MOCKED_DATA = {
  table: {
    headers: [
      {
        label: 'Property name',
        key: 'propertyName',
      },
      {
        label: 'Regions',
        key: 'region',
      },
      {
        label: 'City',
        key: 'city',
      },
      {
        label: 'Property Type',
        key: 'propertyType',
      },
      {
        label: 'Size(NIA sq m)',
        key: 'size',
      },
      {
        label: 'Floors',
        key: 'floors',
      },
      {
        label: 'Desks',
        key: 'desks',
      },
      {
        label: 'Limiting Capacity',
        key: 'limitingCapacity',
      },
      {
        label: 'Nominal Capacity',
        key: 'nominalCapacity',
      },
      {
        label: 'Current Allocation',
        key: 'currentAllocation',
      },
      {
        label: 'Current Spare Capacity',
        key: 'currentSpareCapacity',
      },
      {
        label: 'Current Sharing Ratio',
        key: 'currentSharingRatio',
      },
      {
        label: 'Peak Occupancy',
        key: 'peakOccupancy',
      },
      {
        label: 'Peak Utilisation',
        key: 'peakUtilisation',
      },
      {
        label: 'Space per Desk',
        key: 'spacePerDesk',
      },
      {
        label: 'Planned Space per Head',
        key: 'plannedSpacePerHead',
      },
      {
        label: 'Actual Space per Head',
        key: 'actualSpacePerHead',
      },
      {
        label: 'Potential Capacity',
        key: 'potentialCapacity',
      },
      {
        label: 'Potential Spare Capacity',
        key: 'potentialSpareCapacity',
      },
    ],
  },
  mapLabels: {
    country: 'Country',
    region: 'Region',
    city: 'City',
    address: 'Address',
    postal_code: 'Postal code',
  },
};

export default MOCKED_DATA;
