const transformPropertyDivisionPercent = (data) => {
  const dataKeys = Object.keys(data);
  return dataKeys.map((division) => {
    const deskCount = data[division].percent_avg_utilization;
    if (deskCount === null) {
      return {
        property_name: division,
        divisions: [],
      };
    }
    return {
      property_name: division,
      divisions: [
        {
          division: 'Utilization',
          desk_count: deskCount,
        },
        {
          division: 'Free Space',
          desk_count: 100 - deskCount,
        },
      ],
    };
  });
};

export default transformPropertyDivisionPercent;
