const splitSizesGraphLabels = (graph, chunk) => {
  const allKeys = Object.keys(graph);
  const chunks = [];
  for (let i = 0, j = allKeys.length; i < j; i += chunk) {
    chunks.push(allKeys.slice(i, i + chunk));
  }
  return chunks;
};

export default splitSizesGraphLabels;
