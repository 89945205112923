import { createReducer } from '@reduxjs/toolkit';

import { PRODUCT_FIELDS } from 'modules/assetLook/constants';
import { sortItems, sortAction } from 'modules/assetLook/utils';
import { DESC, requestStatuses } from 'modules/shared/constants';

import { FETCH_PRODUCTS, RESET_STATE, SORT_PRODUCTS } from 'modules/assetLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  requestStatus: requestStatuses.default,
  products: {},
  sortParams: {
    orderBy: PRODUCT_FIELDS.name,
    direction: DESC,
  },
};

export default createReducer(initState, {
  [FETCH_PRODUCTS.pending]: () => ({
    ...initState,
    requestStatus: requestStatuses.pending,
  }),
  [FETCH_PRODUCTS.success]: (state, { payload }) => {
    const { orderBy, direction } = state.sortParams;

    return {
      ...state,
      requestStatus: requestStatuses.success,
      products: {
        ...payload,
        data: sortItems(payload.data, orderBy, direction),
      },
    };
  },
  [FETCH_PRODUCTS.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
  }),

  [SORT_PRODUCTS]: (state, { payload }) => sortAction(state, payload, 'sortParams', 'products'),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
