import { findPeakAndLowestDayByOccupancy } from 'utils';

const DesksLeastBusy = ({ graphData }) => {
  const {
    highestOcc: { avgOccupancy: highestAvgOccupancy },
    lowestOcc: { day, avgOccupancy: lowestAvgOccupancy },
  } = findPeakAndLowestDayByOccupancy(graphData);

  const diff = Math.round((highestAvgOccupancy - lowestAvgOccupancy + Number.EPSILON) * 100) / 100;

  return `${day} was the least busy day, when average occupation was ${diff}% lower.`;
};

export default DesksLeastBusy;
