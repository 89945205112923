import { isEmpty } from 'lodash';

const excludeKeys = ['title', 'survey_total_desks'];

const setStackedGraphColumnsData = (data, mocked) => {
  if (isEmpty(data))
    return {
      labels: [],
    };

  const reMocked =
    mocked ||
    Object.keys(data).map((key) => {
      return { key, title: data[key].title };
    });

  const categoryKeys = Object.keys(data[Object.keys(data)[0]]);
  categoryKeys.forEach((category, idx) => {
    if (excludeKeys.includes(category)) categoryKeys.splice(idx, 1);
  });

  return reMocked.reduce(
    (result, industry) => {
      if (!data[industry.key]) return result;
      result.labels.push(industry.title);

      categoryKeys.forEach((category) => {
        if (result[category]) {
          result[category].push(data[industry.key][category]);
        } else {
          // eslint-disable-next-line no-param-reassign
          result[category] = [data[industry.key][category]];
        }
      });

      return result;
    },
    { labels: [] },
  );
};

export default setStackedGraphColumnsData;
