import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import isLogged from 'utils/isLogged';
import { toQuery } from 'modules/workLook/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { getReports } from 'modules/workLook/selectors';

import { Counter as SharedCounter } from 'modules/shared/components';

import { HEADER_ICONS } from './icons.constants';

const Counter = ({ icon }) => {
  const { reportId, sectionId, pageId } = useParams();
  const { reportData } = useSelector(getReports);

  const { activeFilters } = reportData[reportId].sections[sectionId].pages[pageId];

  const query = toQuery(activeFilters);

  const initState = { count: null, total: null };
  const [data, setData] = useState(initState);

  useEffect(() => {
    let canceled = false;

    setData(initState);

    const url = ENDPOINTS.workLook.counter.replace(':reportId', reportId);
    // const filterUrl = ENDPOINTS.workLook.initFilters.replace(':reportId', reportId);
    const config = {
      headers: { Authorization: `Token ${isLogged()}` },
    };

    axios
      .get(`${url}${query}`, config)
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        if (canceled) return;
        setData(data);
      })
      .catch((err) => {
        if (canceled) return;
        // eslint-disable-next-line no-console
        console.error(err);
      });

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
    // eslint-disable-next-line
  }, [sectionId, query]);

  return <SharedCounter Icon={HEADER_ICONS?.[icon]} total={data.total} counter={data.count} />;
};

export default Counter;
