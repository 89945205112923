const config = [
  {
    legendLabel: 'In use',
    iconType: 'round',
    color: '#58BFC7',
  },
  {
    legendLabel: 'Was used (and not in use right now)',
    iconType: 'round',
    color: '#F6A000',
  },
  {
    legendLabel: 'Vacant / Never Used',
    iconType: 'round',
    color: '#f1f1f1',
  },
];

export default config;
