import React from 'react';
import { Typography } from '@material-ui/core';

import { FixedDataTable } from 'modules/shared/components';
// eslint-disable-next-line import/no-cycle
import {
  AutoSizer,
  StatusHistoryCellHeader as CellHeader,
  StatusHistoryCellBody as CellBody,
} from 'modules/assetLook/components';
import { Accordion, AccordionDetails, AccordionSummary } from '../CustomAccordion';

const HEADER_HEIGHT = 36;
const ROW_HEIGHT = 34;
const BORDER_HEIGHT = 4;

const COLUMNS = ['date_created', 'status', 'user_email'];

// eslint-disable-next-line camelcase
function AccordionHistory({ expanded, onChange, summary, order_history }) {
  const tableHeight = HEADER_HEIGHT + BORDER_HEIGHT + order_history.length * ROW_HEIGHT;

  return (
    <Accordion square expanded={expanded} onChange={onChange}>
      <AccordionSummary aria-controls={`${summary}-content`} id={`${summary}-header`}>
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AutoSizer>
          {({ width }) => (
            <FixedDataTable
              width={width}
              height={tableHeight < 500 ? tableHeight : 500}
              rows={order_history.map((i) => i).sort((a, b) => new Date(a.date_created) - new Date(b.date_created))}
              columns={COLUMNS}
              ColumnHeader={CellHeader}
              ColumnCell={CellBody}
              columnFixed={() => false}
              columnFlexGrow={(k, i) => (i < 2 ? 0 : 1)}
            />
          )}
        </AutoSizer>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionHistory;
