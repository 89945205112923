import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: '0',
  },
  listItemText: {
    paddingLeft: '40px',
  },
  listItemTextPrimary: {
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: (props) =>
      props.isUnpublished
        ? theme.palette.secondary.main
        : props.isActive
        ? theme.palette.primary.main
        : 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      color: (props) => (props.isUnpublished ? theme.palette.secondary.main : theme.palette.primary.main),
    },
  },
}));

const SidebarListSubItem = ({ link, label, isActive, isUnpublished }) => {
  const classes = useStyles({ isActive, isUnpublished });

  return (
    <Link to={link}>
      <ListItem classes={{ root: classes.listItem }}>
        <ListItemText
          primary={label}
          inset
          classes={{
            root: classes.listItemText,
            primary: classes.listItemTextPrimary,
          }}
        />
      </ListItem>
    </Link>
  );
};

export default SidebarListSubItem;
