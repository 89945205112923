import React from 'react';
import PropTypes from 'prop-types';

import './DayTimeGraphYNav.scss';

import { DayTimeGraphYNavItem } from '../..';

const DayTimeGraphYNav = ({ items, initValue }) => {
  const renderedItems = items.map((item, key) => <DayTimeGraphYNavItem item={item} key={key} />);

  return (
    <div className="DayTimeGraphYNav">
      <span className="init-value">{initValue}</span>
      <div className="day-time-graph-y-nav">{renderedItems}</div>
    </div>
  );
};

DayTimeGraphYNav.defaultProps = {
  items: ['100%', '80%', '60%', '40%', '20%'],
  initValue: '0%',
};

DayTimeGraphYNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  initValue: PropTypes.string,
};

export default DayTimeGraphYNav;
