import React from 'react';
import { useDispatch } from 'react-redux';

import {
  APPLY_FILTERS,
  APPLY_BENCHMARKING_FILTERS,
  RESET_FILTERS,
  RESET_BENCHMARKING_FILTERS,
} from 'modules/spaceLook/actions';

import FiltersHeader from './FiltersHeader';

const FiltersHeaderContainer = ({ type, ...props }) => {
  const dispatch = useDispatch();

  let applyAction;
  let resetAction;
  switch (type) {
    case 'benchmarking':
      applyAction = APPLY_BENCHMARKING_FILTERS;
      resetAction = RESET_BENCHMARKING_FILTERS;
      break;
    default:
      applyAction = APPLY_FILTERS;
      resetAction = RESET_FILTERS;
  }

  const handleFiltersApply = () => dispatch(applyAction());
  const handleFiltersReset = () => dispatch(resetAction());

  return <FiltersHeader handleFiltersApply={handleFiltersApply} handleFiltersReset={handleFiltersReset} {...props} />;
};

export default FiltersHeaderContainer;
