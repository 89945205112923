/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

import './BackButton.scss';

import arrow from 'assets/images/icons/to-left-arrow.svg';

const BackButton = ({ text, icon, onClick }) => {
  return (
    <div className="BackButton">
      <img src={icon} className="arrow" alt="arrow" />
      <button onClick={() => onClick()} className="back-button">
        {text}
      </button>
    </div>
  );
};

BackButton.defaultProps = {
  text: 'Back',
  icon: arrow,
  // eslint-disable-next-line no-console
  onClick: () => console.log('click on back button'),
};

BackButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default BackButton;
