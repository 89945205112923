import React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';

import { requestStatuses } from 'modules/shared/constants';
import { reportPageTypes } from 'modules/spaceLook/constants';

import { DownloadsList } from 'modules/shared/components';
import { PageHeader, ReportFilter, DownloadsSnackbar, BenchmarkingFilter } from 'modules/spaceLook/components';

import theme from './Downloads.module.scss';

const useStyles = makeStyles(() => ({
  label: {
    color: 'white',
  },
  root: {
    margin: '0 auto',
  },
}));

const Downloads = ({
  baseReport,
  reportInfo,
  customReports,

  handleReportGeneration,
  generateStatus,

  showAlert,
  handleAlertClose,
}) => {
  const classes = useStyles();

  return (
    <div className={theme.container}>
      <DownloadsSnackbar open={showAlert} onClose={handleAlertClose} />

      <div className={theme.reportsWrapper}>
        <PageHeader title="Downloads" text="Base and custom reports" />

        <div className={theme.reportListContainer}>
          {
            // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
            !baseReport ? (
              <DownloadsList
                emptyListPlaceholder="No standard report yet"
                title="Standard Report"
                items={[]}
                skeletonItems={1}
              />
            ) : (
              <DownloadsList
                title="Standard Report"
                items={baseReport ? [{ ...reportInfo, name: reportInfo.surveyName, file: baseReport }] : null}
                skeletonItems={1}
              />
            )
          }
        </div>

        <div className={theme.reportListContainer}>
          <DownloadsList
            title="Custom Reports"
            items={customReports}
            emptyListPlaceholder="No custom reports yet"
            skeletonItems={4}
          />

          <Button
            classes={{ label: classes.label, root: classes.root }}
            variant="contained"
            size="large"
            // eslint-disable-next-line react/no-children-prop
            children="Generate new report"
            color="primary"
            onClick={handleReportGeneration}
            startIcon={
              generateStatus === requestStatuses.pending && <CircularProgress size={14} style={{ color: 'white' }} />
            }
          />
        </div>
      </div>

      <div className={theme.filtersWrapper}>
        <div className={theme.filterContainer}>
          <ReportFilter pageType={reportPageTypes.combined} />
        </div>
        <div className={theme.filterContainer}>
          <BenchmarkingFilter />
        </div>
      </div>
    </div>
  );
};

export default Downloads;
