import React from 'react';
import { chunk } from 'lodash';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { StatsTable } from 'modules/shared/components';
import {
  BenchmarkingFilterHandler,
  BenchmarkingFilter,
  BenchmarkingGraph,
  ReportText,
  PageHeader,
} from 'modules/spaceLook/components';

import { toBenchmarkingTableData, toBenchmarkingGraphData } from 'modules/spaceLook/utils';

import { useHasScroll } from 'modules/shared/hooks';
import config from './config';

const DesksBenchmarking = ({ isLoading, pdfMode, databoxes, textData, historicData }) => {
  const tableData = toBenchmarkingTableData(historicData);
  const scroll = useHasScroll();
  const graphData = pdfMode
    ? chunk(historicData, 5).map((data) => toBenchmarkingGraphData(data))
    : [toBenchmarkingGraphData(historicData)];

  return graphData.map((data, idx) => (
    <div ref={scroll.ref} key={idx} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Desk Benchmarking" text="Desk Utilisation" />
        </Grid>
        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <BenchmarkingFilterHandler>
              <BenchmarkingFilter />
            </BenchmarkingFilterHandler>
          </Grid>
        </If>

        <Grid item xs={12}>
          <BenchmarkingGraph data={data} />
        </Grid>

        <Grid item xs={12}>
          <StatsTable rows={tableData} headerLabels={config.tableHeaders} />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={['benchOccPreAgile', 'benchOccPostAgile', 'benchUtilization']}
            databoxes={databoxes}
            textData={textData}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default DesksBenchmarking;
