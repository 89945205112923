import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import cn from 'classnames';

import { getPdfMode } from 'modules/spaceLook/selectors';
import PieCustomLegend from 'modules/spaceLook/components/Graphs/UsagePie/PieCustomLegend';

import options from './options';
import theme from './UsagePie.module.scss';

const UsagePie = ({ pieData, label, inPercents }) => {
  const pdfMode = useSelector(getPdfMode);

  const [lineThrough, setLineThrough] = useState([]);

  const filteredPieData = {
    labels: pieData.labels.filter((item, i) => !lineThrough.includes(i)),
    datasets: [
      {
        data: pieData.datasets[0].data.filter((item, i) => !lineThrough.includes(i)),
        backgroundColor: pieData.datasets[0].backgroundColor.filter((item, i) => !lineThrough.includes(i)),
        borderWidth: 0,
      },
    ],
  };

  const onItemClick = (itemIdx) => {
    if (lineThrough.includes(itemIdx)) {
      setLineThrough(lineThrough.filter((i) => i !== itemIdx));
    } else {
      if (pieData.labels.length - lineThrough.length === 1) return;
      setLineThrough([...lineThrough, itemIdx]);
    }
  };

  options.tooltips.callbacks.label = (tooltipItem, data) =>
    `${data.datasets[0].data[tooltipItem.index]}${inPercents ? '%' : ''}`;

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      <Typography variant="h6" className={theme.header}>
        {label}
      </Typography>
      <div className={theme.pieContainer}>
        <div className={theme.pieWrapper}>
          <Pie data={filteredPieData} options={options} />
        </div>
        <PieCustomLegend pdfMode={pdfMode} pieData={pieData} lineThrough={lineThrough} onItemClick={onItemClick} />
      </div>
    </div>
  );
};

export default UsagePie;
