import { setupMapData, setupPropertiesDetails, updatePropertyIdByMap } from 'utils';
import { detailsPage as MOCKED_DATA } from 'services';

export const SET_DETAILS_PAGE_DATA = {
  pending: 'SET_DETAILS_PAGE_DATA_PENDING',
  success: 'SET_DETAILS_PAGE_DATA_SUCCESS',
  failure: 'SET_DETAILS_PAGE_DATA_FAILURE',
};

const setDetailsPageData = (buildings, properties, propertyNamesMap) => {
  const updatedBuildings = updatePropertyIdByMap(buildings, propertyNamesMap);
  const updatedProperties = updatePropertyIdByMap(properties, propertyNamesMap);

  return {
    type: SET_DETAILS_PAGE_DATA.success,
    payload: {
      buildingsData: setupMapData(updatedBuildings, MOCKED_DATA.mapLabels),
      propertiesData: setupPropertiesDetails(updatedProperties),
      propertiesList: updatedProperties.map((item) => ({
        name: item.property_name,
        id: item.property_id,
      })),
    },
  };
};

export default setDetailsPageData;
