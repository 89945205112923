import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import './PicturesGraph.scss';
import { Grid, Typography } from '@material-ui/core';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';

const positions = {
  0: 'topLeft',
  1: 'topRight',
  2: 'bottomLeft',
  3: 'bottomRight',
};

const PicturesGraph = ({
  data,
  isPublished,
  subtitle,
  title,
  isLoading,
  graphId,
  isPdf,
  typeOfGroup,
  showTitle,
  pictures,
  isModal,
  type,
}) => {
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={(!isLoading && isEmpty(data)) || isEmpty(pictures)}>
        <Paper className="container" elevation={2}>
          <GraphHeader
            display={false}
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={subtitle}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper className="container" elevation={2}>
          <Grid item>
            <GraphHeader
              display={false}
              showTitle={showTitle}
              title={title}
              subTitle={subtitle}
              graphId={graphId}
              isPdf={isPdf}
              onClick={onEditClick}
              onModifierClick={onModifierClick}
              isPublished={isPublished}
            />
          </Grid>
          <Grid item className="innerContainer">
            {pictures.map(({ name, image }, index) => (
              <div key={index} className={positions[index]}>
                <Typography variant="body1" component="span">
                  {name}
                </Typography>
                <div className="imgContainer" elevation={2}>
                  <div className="barContainer">
                    <div style={{ height: '100%' }}>
                      <div className="emptyBar">
                        <div
                          style={{
                            width: '100%',
                            maxHeight: '100%',
                            height: `${!data[index] ? '0' : data[index]}%`,
                            borderRadius: '10px',
                            backgroundColor: 'green',
                            display: 'inline-block',
                            justifyContent: 'center',
                            alignSelf: 'flex-end',
                          }}
                        />
                      </div>
                    </div>
                    <Typography variant="body1" component="span">
                      {!data[index] ? '0' : data[index]}%
                    </Typography>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${image})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              </div>
            ))}
          </Grid>
        </Paper>
      </Otherwise>
    </Choose>
  );
};

export default PicturesGraph;
