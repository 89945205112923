import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

import { PageHeader } from 'modules/shared/components';
import { ReportDataCards, UsageOverTimeGraph } from 'modules/liveLook/components';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';

import { GRAPH_TYPES, pageTypes } from 'modules/liveLook/constants';

import { toDeskUsageOverTimeGraphData } from 'modules/liveLook/utils';
import { toLondonTimeZone } from 'pages/_privatePages/liveLook/utils';
import { isEmpty } from 'lodash';
import renderFilters from '../../utils/activeFiltersPdf';
import config from './config';
import styles from '../../LLPrintablePdfPage.module.scss';
import getSplittedGraphData from '../../utils/getSplittedGraphData';

const DesksOverTime = ({
  scale,
  data,
  value,
  niceMax,
  graphData,
  linesData,
  databoxes,
  scaleValues,
  returnPercentageSwitcher,
  activeFilters,
  editableTextReport,
}) => {
  const { hoursLength, splittedGraphData } = useMemo(
    () => getSplittedGraphData(data, scale, toLondonTimeZone, graphData),
    [data, scale, graphData],
  );

  const graphDataToBuild = useMemo(() => {
    if (!splittedGraphData) return [false];
    return splittedGraphData.map((graph) => {
      return toDeskUsageOverTimeGraphData(
        {
          sourceData: graph,
          valueType: value,
          scale,
          returnPercentageSwitcher,
          niceMax,
        },
        false,
      );
    });
  }, [niceMax, returnPercentageSwitcher, scale, splittedGraphData, value]);
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));
  const getGraphLength = graphDataToBuild.length;

  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles.pdfPage)}>
        <Grid style={{ paddingTop: '20px', paddingLeft: '30px' }} container spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader pdfMode title="Desks Over Time" />
            </Grid>
          </Grid>
          {!shouldRenderFilters && renderFilters(activeFilters)}
          <div className={styles.blockContainer}>
            <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.desks} />
          </div>

          <UsageOverTimeGraph
            title={getGraphLength > 1 ? `1 of ${getGraphLength} Usage over time` : 'Usage over time'}
            label="Integers"
            scale={scale}
            onScaleChange={() => {}}
            onSwitcherChange={() => {}}
            value={value}
            onValueChange={() => {}}
            graphData={graphDataToBuild[0]}
            config={config}
            linesData={linesData}
            scaleValues={scaleValues}
            isSwitcherOn={returnPercentageSwitcher}
            pdfCustomHeader
            isLiveLook
          />
          <PDFTextEditorContainer
            graphType={GRAPH_TYPES.OVERTIME}
            hasFilters={shouldRenderFilters}
            data={[]}
            onSave={() => {}}
            text={editableTextReport}
          />
        </Grid>
      </div>
      {hoursLength &&
        graphDataToBuild.slice(1).map((infoData, id) => (
          <div key={id} className={cn(styles.pdfPage)}>
            <Grid style={{ paddingTop: '20px', paddingLeft: '30px' }} container spacing={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PageHeader pdfMode title="Desks Over Time" />
                </Grid>
              </Grid>
              {!shouldRenderFilters && renderFilters(activeFilters)}
              <div className={styles.blockContainer}>
                <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.desks} />
              </div>
              <UsageOverTimeGraph
                title={`${id + 2} of ${getGraphLength} Usage over time`}
                label="Integers"
                scale={scale}
                onScaleChange={() => {}}
                onSwitcherChange={() => {}}
                value={value}
                onValueChange={() => {}}
                graphData={infoData}
                config={config}
                linesData={linesData}
                scaleValues={scaleValues}
                isSwitcherOn={returnPercentageSwitcher}
                pdfCustomHeader
                isLiveLook
              />
              <PDFTextEditorContainer
                graphType={GRAPH_TYPES.OVERTIME}
                hasFilters={shouldRenderFilters}
                data={[]}
                onSave={() => {}}
                text={editableTextReport}
              />
            </Grid>
          </div>
        ))}
    </div>
  );
};

export default DesksOverTime;
