/* eslint-disable react/no-children-prop */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import cn from 'classnames';
import { Grid, Typography, Box, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import GraphYScale from 'modules/liveLook/components/GraphYScale';
import GraphLegend from 'modules/liveLook/components/GraphLegend';

import { NoDataAvailable } from 'modules/liveLook/utils';
import options from './options';
import theme from './DeskUsageDuringTheDay.module.scss';

const DeskUsageDuringTheDay = ({
  title,
  graphData,
  legendConfig,
  returnPercentageSwitcher,
  scaleValues,
  onSwitcherChange,
  customHeader,
  pdfCustomHeader,
  isLiveLook,
}) => {
  const yScaleMarginBottom = graphData.length > 5 ? '100px' : pdfCustomHeader ? '64.5px' : '90px';

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfCustomHeader })}>
      <div className={theme.header}>
        <Grid
          xs={12}
          item
          style={
            pdfCustomHeader
              ? { paddingBottom: '8px' }
              : { paddingBottom: '15px', display: 'flex', justifyContent: 'space-between' }
          }
        >
          <Typography variant={pdfCustomHeader ? 'subtitle2' : 'h5'}>
            <Box fontWeight="fontWeightBold" component="span" children={title} />
          </Typography>
          {customHeader && graphData && (
            <div>
              <FormGroup>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ justifyContent: 'flex-end' }}
                >
                  <Grid item>Percentages</Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Switch checked={returnPercentageSwitcher} onChange={onSwitcherChange} />}
                      label="Integers"
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </div>
          )}
        </Grid>
      </div>

      {
        // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
        graphData ? (
          <>
            <div className={theme.graphContainer}>
              <GraphYScale
                isSwitcherOn={returnPercentageSwitcher}
                values={scaleValues}
                marginBottom={yScaleMarginBottom}
                pdf={pdfCustomHeader}
              />

              <div className={theme.barSection}>
                <div className={theme.oneDayContainer}>
                  <div className={theme.oneDayGraph}>
                    <Bar
                      data={graphData}
                      options={options(scaleValues[0], returnPercentageSwitcher, pdfCustomHeader)}
                    />
                  </div>
                  <div className={theme.labelWrapper}>
                    <div className={theme.oneDayLabel}>{graphData.title}</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <NoDataAvailable theme={theme.graphSection} />
        )
      }
      <GraphLegend config={legendConfig} isLiveLook={isLiveLook} pdfMode={pdfCustomHeader} lg />
    </div>
  );
};

export default DeskUsageDuringTheDay;
