import GRAPH_RANGE from 'modules/liveLook/constants/graphRange';

export const GRAPHSCALES = [
  {
    period: '30 minutes',
    maxDays: 31,
  },
  {
    period: '1 hour',
    maxDays: 61,
  },
  {
    period: 'Day',
    maxDays: 365,
  },
  {
    period: 'Week',
    maxDays: 365,
  },
  {
    period: 'Month',
    maxDays: 365,
  },
];

export const GRAPH_RANGES = [
  {
    period: 'Last week',
    value: GRAPH_RANGE.LAST_WEEK,
  },
  {
    period: 'Last 4 weeks',
    value: GRAPH_RANGE.FOUR_WEEKS,
  },
  {
    period: 'Last 4 months',
    value: GRAPH_RANGE.FOUR_MONTHS,
  },
];
