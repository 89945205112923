/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';

const useWidgetData = (id, recordId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataFromRecord, setData] = useState(null);

  useEffect(() => {
    let canceled = false;

    setIsLoading(true);

    const url = ENDPOINTS.workLook.widgetData.replace(':wigetId', id).replace(':recordId', recordId);
    const config = {
      headers: { Authorization: `Token ${isLogged()}` },
    };

    axios
      .get(`${url}`, config)
      .then(({ data }) => {
        if (canceled) return;
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (canceled) return;
        console.error(err);
      });
    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
  }, [id, recordId]);

  return { isLoading, dataFromRecord };
};

export default useWidgetData;
