/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Button, TableCell, Typography } from '@material-ui/core';
import { ENDPOINTS } from 'modules/shared/constants';
import Input from '@material-ui/core/Input';
import { API } from 'modules/shared/utils';
import isLogged from 'utils/isLogged';
import { isEmpty } from 'lodash';
import { fieldValidations, dateTypes, getValue, isDateTime, cutString } from './config';
import { formatDateTime } from './formatDateTime';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const CustomTableCell = ({ row, name, fieldType, onChange, errors, setErrors, classes, editable, reportId }) => {
  const { isEditMode } = row;
  const dateTime = isDateTime(fieldType);
  const [inputFile, setInputFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const checkOnEmpties = isEmpty(getValue(fieldType, row[name]));
  const handleChange = (event) => {
    setInputFile(event.target.files[0]);
  };

  const onSubmit = () => {
    const url = ENDPOINTS.workLook.uploadFileTable.replace(':reportId', reportId).replace(':dataRecordId', row.id);
    const dataFile = new FormData();
    dataFile.append('file', inputFile);
    dataFile.append('field', name);
    setIsUploading(true);
    return API.post(url, dataFile, config).then(() => {
      window.location.reload(false);
      setIsUploading(false);
    });
  };

  const onDeleteFile = () => {
    const urlDelete = ENDPOINTS.workLook.uploadFileTable
      .replace(':reportId', reportId)
      .replace(':dataRecordId', row.id);
    const dataFile = new FormData();
    dataFile.append('field', name);

    const configDelete = {
      headers: {
        Authorization: `Token ${isLogged()}`,
      },
      data: dataFile,
    };

    setIsUploading(true);
    return API.delete(urlDelete, configDelete).then(() => {
      window.location.reload(false);
      setIsUploading(false);
    });
  };

  const onChangeRow = (e) => {
    const { value } = e.target;
    if (!isEmpty(value)) {
      setErrors((state) => ({ ...state, [name]: '' }));
    }
    fieldValidations[fieldType](setErrors, name, value, fieldType);
    if (isEmpty(value)) setErrors((state) => ({ ...state, [name]: '* Required' }));

    onChange(e, row, dateTime);
  };

  return (
    <TableCell style={{ minWidth: `${dateTime && '130px'}` }} align="left" className={classes.tableCell}>
      <If condition={isEditMode}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <If condition={fieldType === 'file'}>
            <div className={classes.dropzone}>
              <If condition={checkOnEmpties}>
                <input className={classes.inputfile} id="file" type="file" name="file" onChange={handleChange} />
                <label className={classes.label} htmlFor="file">
                  Browse File
                </label>
                <h1 className={classes.dropzoneName}>{inputFile?.name}</h1>
              </If>
              <If condition={checkOnEmpties}>
                <Button
                  disabled={isEmpty(inputFile?.name)}
                  type="submit"
                  color="primary"
                  component="span"
                  onClick={onSubmit}
                >
                  Upload
                </Button>
              </If>
              <If condition={!checkOnEmpties}>
                <a href={getValue(fieldType, row[name])}>{cutString(getValue(fieldType, row[name]))}</a>
                <Button type="submit" color="secondary" component="span" onClick={onDeleteFile}>
                  Delete
                </Button>
              </If>
            </div>
          </If>
          <If condition={fieldType !== 'file'}>
            <Input
              style={{ width: '100%' }}
              required
              error={!!errors[name]}
              type={dateTypes[fieldType]}
              value={getValue(fieldType, row[name])}
              placeholder={name.replace('_', ' ')}
              name={name}
              inputProps={{ step: '1' }}
              onChange={(e) => onChangeRow(e)}
              className={classes.input}
            />
            {!!errors[name] && (
              <Typography variant="body2" color="error" component="span">
                {errors[name]}
              </Typography>
            )}
          </If>
        </div>
        <Else />
        {formatDateTime(editable ? fieldType : name, row[name])}
      </If>
    </TableCell>
  );
};

export default CustomTableCell;
