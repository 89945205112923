import { createReducer } from '@reduxjs/toolkit';

import { SET_WIDGETS_DATA } from 'modules/workLook/actions';

const initState = {
  widgetsData: [],
  record: 0,
};

export default createReducer(initState, {
  [SET_WIDGETS_DATA]: (state, { payload }) => ({
    ...state,
    widgetsData: payload.widgetsData,
    record: payload.record,
    isLoading: payload.isLoading,
  }),
});
