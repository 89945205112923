const config = [
  {
    label: 'Peak Occupancy',
    valueKey: 'max_occupancy',
    percentKey: 'percent_max_occupancy',
    color: '#0d6f85',
    iconType: 'line',
  },
  {
    label: 'Average Occupancy',
    valueKey: 'avg_occupancy',
    percentKey: 'percent_avg_occupancy',
    color: '#00aad2',
    iconType: 'line',
  },
  {
    label: 'Peak Utilisation',
    valueKey: 'max_utilization',
    percentKey: 'percent_max_utilization',
    color: '#a2559d',
    iconType: 'line',
  },
  {
    label: 'Average Utilisation',
    valueKey: 'avg_utilization',
    percentKey: 'percent_avg_utilization',
    color: '#c577c0',
    iconType: 'line',
  },
];

export default config;
