import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import createAction from './_createAction';

export const SHOW_MODAL = createAction('SHOW_MODAL');

export const showModal = (modalCode, modalProps) =>
  SHOW_MODAL({
    modalCode,
    modalProps,
  });

export const showDialog = (modalProps) =>
  SHOW_MODAL({
    modalCode: modalCodes.DIALOG,
    modalProps,
  });

export const showError = (modalProps) =>
  SHOW_MODAL({
    modalCode: modalCodes.ERROR,
    modalProps,
  });
