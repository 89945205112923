import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { ReportHistoryPage, SummaryPage, DetailsPage } from 'components';

import URLS from 'pages/constants/urls';
import { LANDING_START_PAGE, LANDING_PATH } from 'pages/constants';

import { ModalPlace, SecondModalPlace } from 'modules/modalsSystem/components';
import { getUser } from 'modules/auth/selectors';

import config from 'modules/shared/components/NavBar/config';
import { LoginPage, RestorePage, TwoFAPage } from './_authPages';
import PrivateRoute from './PrivateRoute';

import SLPages from './_privatePages/spaceLook';
import SLPdfPage from './_privatePages/spaceLook/SLPdfPage';

import ALPages from './_privatePages/assetLook';
import ALPrintablePdf from './_privatePages/assetLook/PrintablePdfPage';

import WLPages from './_privatePages/workLook';
import WLPrintablePdf from './_privatePages/workLook/PrintablePdfPage';
import LLPrintablePdf from './_privatePages/liveLook/LLPrintablePdfPage';

import LLPages from './_privatePages/liveLook';

export default () => {
  const { user } = useSelector(getUser);
  const location = useLocation();

  const configNames = config.map(({ name }) => name);
  const getWorkLookPage = configNames.includes(getLandingPage);

  const getLandingPage = user?.landing_start_page?.name;
  const getLandingPageId = user?.landing_start_page?.id;

  const landingPage = getLandingPage?.split(' ')[0].toLowerCase();

  const portalUrl = landingPage
    ? URLS[LANDING_START_PAGE[landingPage]]?.[LANDING_PATH[landingPage]]
    : URLS.locationLook.locationSummary;
  const redirectUrl = getWorkLookPage ? portalUrl : URLS.workLook.base.replace(':typeId', getLandingPageId);

  const history = useHistory();
  const [shouldBeRedirected, updateSBR] = useState(true);
  const [initialPath, updateIP] = useState(null);

  useEffect(() => {
    if (user && shouldBeRedirected && initialPath === '/') {
      history.push(redirectUrl.replace(':typeId', user.company?.work_look_report_types?.[0]?.id || '1'));
      updateSBR(false);
    }
  }, [history, initialPath, redirectUrl, shouldBeRedirected, user]);

  useEffect(() => {
    updateIP(location.pathname);
  }, [location.pathname]);

  return (
    <div className="App">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
          integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
          crossOrigin=""
        />
        <script
          src="https://unpkg.com/leaflet@1.6.0/dist/leaflet.js"
          integrity="sha512-gZwIG9x3wUXg2hdXF6+rVkLF/0Vi9U8D2Ntg4Ga5I5BZpVkVxlJWbSQtXPSiUTtC0TjtGOmxa1AJPuV0CPthew=="
          crossOrigin=""
        />
      </Helmet>
      <div className="app">
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <ModalPlace />
          <SecondModalPlace />
          <Switch>
            <Route path={URLS.auth.login} component={LoginPage} />
            <Route path={URLS.auth.restorePassword} component={RestorePage} />
            <Route exact path={URLS.auth.twoFACode} component={TwoFAPage} />

            <PrivateRoute exact path={URLS.auth.twoFASetup} component={TwoFAPage} />

            <PrivateRoute path={URLS.locationLook.locationSummary} component={SummaryPage} />
            <PrivateRoute path={URLS.locationLook.locationDetails} component={DetailsPage} />

            <PrivateRoute exact path={URLS.spaceLook.reportHistory} component={ReportHistoryPage} />
            <PrivateRoute path={URLS.spaceLook.baseReport} component={SLPages} />
            <PrivateRoute exact path={URLS.spaceLook.pdf} component={SLPdfPage} />

            <PrivateRoute exact path={URLS.assetLook.pdf} component={ALPrintablePdf} />
            <PrivateRoute path={URLS.assetLook.base} component={ALPages} />

            <PrivateRoute exact path={URLS.workLook.pdfBase} component={WLPrintablePdf} isBase />
            <PrivateRoute exact path={URLS.workLook.pdfCustom} component={WLPrintablePdf} />
            <PrivateRoute path={URLS.workLook.base} component={WLPages} />

            <PrivateRoute exact path={URLS.liveLook.pdf} component={LLPrintablePdf} />
            <PrivateRoute path={URLS.liveLook.base} component={LLPages} />

            <Redirect to={URLS.locationLook.locationSummary} />
          </Switch>
        </SnackbarProvider>
      </div>
    </div>
  );
};
