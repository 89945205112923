/* eslint-disable camelcase */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';
import { btnsTheme } from 'modules/modalsSystem/components/modalsWL/options';
import { showSecondModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import theme from './confirmDeletionContainer.module.scss';

const ConfirmDeletionContainer = React.forwardRef(({ onClose }) => {
  const dispatch = useDispatch();
  const activeCompany = localStorage.getItem('lastCompany');
  const { secondModalProps } = useSelector(({ secondModal }) => ({ ...secondModal }));
  const { userId, firstName, lastName } = secondModalProps;
  const url = ENDPOINTS.auth.deleteUser.replace(':userId', userId);
  const onDeleteClick = () => {
    const queryParams = {
      company_id: activeCompany,
    };
    API.delete(url, { params: queryParams })
      .then(() => {
        window.location.reload(false);
      })
      .catch((error) => {
        onClose();
        dispatch(
          showSecondModal(modalCodes.ERROR, {
            title: 'Error',
            text: error.response.data.detail,
          }),
        );
      });
  };

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.header}>
        <Typography variant="h5" className={theme.title}>
          Confirm Deletion
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={theme.modalBody}>
        <Typography>
          Are you sure you want to remove {firstName} {lastName} from the list?
        </Typography>
        <div className={theme.btnWrapper}>
          <ThemeProvider theme={btnsTheme}>
            <Button color="secondary" variant="outlined" onClick={onDeleteClick}>
              Delete
            </Button>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
});

export default ConfirmDeletionContainer;
