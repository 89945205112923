import { cloneDeep } from 'lodash';

const setDayTimeGraphData = (data, mocked) => {
  const mockedCopy = cloneDeep(mocked);

  data.forEach((dayTimeObj) => {
    // eslint-disable-next-line camelcase
    const { week_day, hour, percent_occupancy } = dayTimeObj;

    mockedCopy[week_day].data.hours.push(hour);
    mockedCopy[week_day].data.values.push(percent_occupancy);
  });

  Object.keys(mockedCopy).forEach((day) => {
    if (mockedCopy[day].data.hours.length === 0) delete mockedCopy[day];
  });

  return mockedCopy;
};

export default setDayTimeGraphData;
