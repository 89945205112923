import { findPeakDays, sortObjects } from 'utils';

const MeetsAndBreakPeakUsagePlus = ({ graphData }) => {
  const maxValue = graphData.sort(sortObjects('percent_room_occupancy'))[graphData.length - 1].percent_room_occupancy;
  const peakDays = findPeakDays(graphData, maxValue, 'percent_room_occupancy');

  return `The peak usage was reached on ${peakDays.split(', ').length} occasion(s), on ${peakDays}.`;
};

export default MeetsAndBreakPeakUsagePlus;
