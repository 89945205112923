import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  withStyles,
} from '@material-ui/core';

const List = withStyles({
  root: {
    paddingTop: 0,
  },
})(MuiList);

const ListItem = withStyles({
  root: {
    padding: 0,
  },
})(MuiListItem);

const ListItemText = withStyles({
  primary: {
    fontWeight: '500',
  },
})(MuiListItemText);

export { List, ListItem, ListItemText };
