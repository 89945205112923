import { getNiceNumbers } from 'modules/shared/utils';

const setMaxPoint = (peakValue, withoutIncrement) => {
  if (!peakValue) return 0;
  if (withoutIncrement) return Number(peakValue.toFixed(1));
  return Number(peakValue.toFixed(1)) * 1.001;
};

const setScaleValues = (peakValue, withoutIncrement = false, isTimeGraph = false, maxTicks) => {
  const { niceMax, niceMin, tickSpacing } = getNiceNumbers(
    0,
    setMaxPoint(peakValue, withoutIncrement),
    maxTicks,
    isTimeGraph,
  );

  const scaleValues = [];
  for (let i = niceMax; i >= niceMin; i -= tickSpacing) {
    scaleValues.push(Number(i.toFixed(2)));
  }

  return {
    niceMax,
    scaleValues,
  };
};

export default setScaleValues;
