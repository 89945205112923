import React from 'react';

const SuggestionComponent = ({ mention, theme, searchValue, isFocused, ...parentProps }) => (
  <div {...parentProps}>
    <div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>

    <div className={theme.mentionSuggestionsEntryTitle}>{mention.value}</div>
  </div>
);

export default SuggestionComponent;
