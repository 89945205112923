const desksColor = '#00aad2';
const meetsColor = '#f6a000';
const breakColor = '#a2559d';
const unoccupiedColor = '#f1f1f1';

const config = {
  palette: {
    desks: desksColor,
    meets: meetsColor,
    break: breakColor,
    unoccupied: unoccupiedColor,
  },
  horizontalBars: [
    {
      label: 'Peak Occupancy',
      valueKey: 'peak_total_occupancy',
      percentKey: 'peak_total_percentage',
      color: '#0d6f85',
      iconType: 'line',
    },
    {
      label: 'Avg Occupancy',
      valueKey: 'avg_total_occupancy',
      percentKey: 'avg_total_percentage',
      color: '#cee2e7',
      iconType: 'line',
    },
  ],
  legend: [
    {
      iconType: 'round',
      label: 'Desk seats',
      color: desksColor,
    },
    {
      iconType: 'round',
      label: 'Meeting seats',
      color: meetsColor,
    },
    {
      iconType: 'round',
      label: 'Breakout seats',
      color: breakColor,
    },
  ],
};

export default config;
