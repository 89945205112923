import React from 'react';
import { Loader } from 'components';
import usePdfData from 'modules/liveLook/hooks/usePdfData';
import { requestStatuses } from 'modules/shared/constants';
import LLPrintablePdfPage from './LLPrintablePdfPage';

const LLPrintablePdfPageContainer = () => {
  const { requestStatus, pdfData, reportName } = usePdfData();
  const companyLogo = 'http://api.dev.bakerstuart.wesoftyou.club/media/Logo_V7.jpg';
  const companyName = 'Sky';
  return (
    <Choose>
      <When condition={requestStatus === requestStatuses.success}>
        <LLPrintablePdfPage
          reportName={reportName}
          pdfData={pdfData}
          companyName={companyName}
          companyLogo={companyLogo}
        />
      </When>
      <Otherwise>
        <Loader />
      </Otherwise>
    </Choose>
  );
};

export default LLPrintablePdfPageContainer;
