import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Loader } from 'components';

import { isDefault } from 'modules/shared/utils';
import { fetchDetailsData } from 'modules/spaceLook/actions';
import { requestStatuses } from 'modules/shared/constants';
import { getPropertyNamesMap } from 'modules/shared/selectors';
import { getDetailsData, getPdfMode } from 'modules/spaceLook/selectors';

import SurveyDetails from './SurveyDetails';

const SurveyDetailsContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const pdfMode = useSelector(getPdfMode);
  const { detailsData, requestStatus } = useSelector(getDetailsData);
  const { propertyNamesMap } = useSelector(getPropertyNamesMap);

  useEffect(() => {
    if (isDefault(requestStatus)) fetchDetailsData(dispatch, id);
  }, [dispatch, id, requestStatus]);

  return (
    <Choose>
      <When condition={requestStatus !== requestStatuses.success}>
        <Loader />
      </When>
      <Otherwise>
        <SurveyDetails detailsData={detailsData} pdfMode={pdfMode} propertyNamesMap={propertyNamesMap} />
      </Otherwise>
    </Choose>
  );
};

export default SurveyDetailsContainer;
