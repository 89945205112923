/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, IconButton, makeStyles, ThemeProvider, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import { SET_ADDITIONAL_FILES } from 'modules/workLook/actions';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CloseIcon from '@material-ui/icons/Close';
import URLS from 'pages/constants/urls';
import { getActiveCompany } from 'modules/shared/selectors';
import { editTheme } from 'modules/modalsSystem/components/modalsWL/options';
import { getBlankReport } from 'modules/workLook/selectors';
import theme from './createBlankReport.module.scss';
import CreateReportForm from '../CreateReportForm';
import EditCsvData from '../EditCsvData';
import UploadCsvContainer from '../UploadCSV/UploadCsvContainer';
import MatchFields from '../MatchFields';

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 16px',
    textTransform: 'capitalize',
    borderRadius: '20px',
  },
}));

const steps = ['Fill Report Information', 'Upload CSV File', 'Edit Report Fields'];
const additionalSteps = ['Fill Report Information', 'Upload CSV File', 'Edit Report Fields', 'Match fields'];

const CreateBlankReportContainer = React.forwardRef(({ onClose }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(getActiveCompany);
  const { blankReportData, typeId, additionalFile } = useSelector(getBlankReport);
  const { displayNewStep } = additionalFile || {};

  const getSteps = displayNewStep ? additionalSteps : steps;

  useEffect(() => {
    dispatch(
      SET_ADDITIONAL_FILES({
        additionalFile: [],
        displayNewStep: false,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const finishStep = () => {
    onClose();
    history.push({
      pathname: URLS.workLook.title.replace(':typeId', typeId).replace(':reportId', blankReportData.id),
    });
    window.location.reload(false);
  };

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Typography variant="h5" className={theme.title}>
            Create Blank Report
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Box sx={{ width: '100%', height: 'calc(100% - 78px)' }}>
          <Stepper sx={{ mb: 2 }} activeStep={activeStep}>
            {getSteps?.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <If condition={activeStep === getSteps.length}>
            <div className={theme.finishWrapper}>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - {blankReportData.name} report has been created
              </Typography>
              <Box sx={{ mt: 2, mb: 2 }}>
                <ThemeProvider theme={editTheme}>
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.root }}
                    onClick={finishStep}
                    // eslint-disable-next-line react/no-children-prop
                    children="Finish"
                  />
                </ThemeProvider>
              </Box>
            </div>
          </If>
          <If condition={activeStep !== getSteps.length}>
            <div style={{ height: 'calc(100% - 24px)', position: 'relative' }}>
              <If condition={activeStep === 0}>
                <CreateReportForm onNext={handleNext} companyId={companyId} />
              </If>
              <If condition={activeStep === 1}>
                <UploadCsvContainer onNext={handleNext} onBack={handleBack} />
              </If>
              <If condition={activeStep === 2}>
                <EditCsvData onBack={handleBack} onNext={handleNext} companyId={companyId} />
              </If>
              <If condition={activeStep === 3}>
                <MatchFields onBack={handleBack} onNext={handleNext} companyId={companyId} />
              </If>
            </div>
          </If>
        </Box>
      </div>
    </div>
  );
});

export default CreateBlankReportContainer;
