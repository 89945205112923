import React, { useEffect, useReducer } from 'react';
import { useAuthSelector } from 'modules/auth/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { TextPlaceholder } from 'modules/shared/components';
import { Loader } from 'components';

import { fetchArchive } from 'modules/liveLook/actions';
import reducer, { initialState } from './_reducer';

import ArchivePage from './ArchivePage';

function ArchivePageContainer() {
  const [companyId] = useAuthSelector(['currentCompany']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { requestStatus, data } = state;

  useEffect(() => {
    let isCanceled = false;
    fetchArchive(dispatch, isCanceled, { companyId });
    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId]);

  return (
    <Choose>
      <When condition={[requestStatus].some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={[requestStatus].every((item) => isSuccess(item))}>
        <ArchivePage companyId={companyId} archives={data} />
      </When>
      <When condition={[requestStatus].some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default ArchivePageContainer;
