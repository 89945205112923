const pageTypes = {
  occupancy: 'OCCUPANCY',
  desks: 'DESKS',
  meetings: 'MEETINGS',
  breakout: 'BREAKOUT',
  summary: 'SUMMARY',
  combinedOccupancy: 'COMBINED OCCUPANCY',
  keyFindings: 'KEYFINDINGS',
};

export default pageTypes;
