/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Grid,
  DialogContentText,
  TextField,
  MenuItem,
  CircularProgress,
  makeStyles,
  Button,
  ThemeProvider,
  Box,
} from '@material-ui/core';
import { editTheme } from 'modules/modalsSystem/components/modalsWL/options';

import { ModalBase } from 'modules/shared/components';
import { useCreateBlankReport } from 'modules/workLook/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { getUser } from 'modules/auth/selectors';
import { isAdmin, isCreateEditReportTempalte, isPartner } from 'modules/shared/utils';

const useStyles = makeStyles((theme) => ({
  error: {
    margin: 0,
    marginTop: '24px',
    color: theme.palette.error.main,
  },
  root: {
    padding: '4px 16px',
    width: '200px',
    height: '120px',
    textTransform: 'capitalize',
    borderRadius: '12px',
  },
}));

const CreateReportModal = ({
  formik,
  open,
  typeId,
  isLoading,
  templatesList,
  isSubmitPending,
  isSubmitFailure,
  _handleClose,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { onCreateBlankReport } = useCreateBlankReport(false, _handleClose, typeId);
  const [createTemplate, setCreateTemplate] = useState(false);
  const { user } = useSelector(getUser);
  const { people_look_editing_rights } = user;

  const isAdminRole = isAdmin(user?.role) || isPartner(user?.role);

  const hasEditRightsTemplate = isAdminRole || (!isAdminRole && isCreateEditReportTempalte(people_look_editing_rights));

  const onEditTemplates = () => {
    dispatch(showModal(modalCodes.WL_EDIT_TEMPLATES));
  };

  return (
    <ModalBase
      open={open}
      onClose={_handleClose}
      DialogProps={{
        disableBackdropClick: isSubmitPending,
        disableEscapeKeyDown: isSubmitFailure,
      }}
      createTemplate={createTemplate}
      onSubmit={formik.handleSubmit}
      title={createTemplate ? 'Create Report From Template' : 'Create Report'}
      firstActionProps={{
        disabled: isSubmitPending,
      }}
      secondActionLabel="Create"
      secondActionProps={{
        disabled: isLoading || isSubmitPending || !templatesList.length,
        startIcon: isSubmitPending && <CircularProgress size={14} style={{ color: 'white' }} />,
      }}
    >
      <If condition={!createTemplate}>
        <ThemeProvider theme={editTheme}>
          <Grid container spacing={2}>
            <Box sx={{ mr: 2, mb: 4 }}>
              <Button
                variant="contained"
                color="primary"
                classes={{ root: classes.root }}
                onClick={onCreateBlankReport}
                // eslint-disable-next-line react/no-children-prop
                children="Create Blank Report"
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                classes={{ root: classes.root }}
                onClick={() => setCreateTemplate(true)}
                // eslint-disable-next-line react/no-children-prop
                children="Create Report From Template"
              />
            </Box>
          </Grid>
        </ThemeProvider>
      </If>
      <If condition={createTemplate}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogContentText>
              In order to create a new report, please enter the report name and select an appropriate template.
            </DialogContentText>

            <TextField
              type="text"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              id="name"
              name="name"
              label="Report name *"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              disabled={isLoading || !templatesList.length}
              InputLabelProps={{
                shrink: true,
              }}
              id="template"
              name="template"
              label="Template *"
              value={formik.values.template}
              onChange={formik.handleChange}
              error={formik.touched.template && Boolean(formik.errors.template)}
              helperText={formik.touched.template && formik.errors.template}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              <Choose>
                <When condition={isLoading}>
                  <MenuItem value="none">
                    <CircularProgress size={14} />
                  </MenuItem>
                </When>
                <When condition={!templatesList.length}>
                  <MenuItem value="none" disabled>
                    No templates are available
                  </MenuItem>
                </When>
                <Otherwise>
                  {templatesList.map(({ id, name }) => (
                    <MenuItem key={id} value={`${id}`}>
                      {name}
                    </MenuItem>
                  ))}
                </Otherwise>
              </Choose>
            </TextField>
          </Grid>
          <If condition={hasEditRightsTemplate}>
            <Grid item xs={12}>
              <Button color="primary" variant="outlined" onClick={() => onEditTemplates()}>
                Edit Templates
              </Button>
            </Grid>
          </If>
        </Grid>
        <If condition={isSubmitFailure}>
          <DialogContentText classes={{ root: classes.error }}>
            Something went wrong! Please, try again later.
          </DialogContentText>
        </If>
      </If>
    </ModalBase>
  );
};

export default CreateReportModal;
