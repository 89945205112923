import { isEmpty } from 'lodash';

const toSpaceComparisonGraphData = (data, returnPercentageSwitcher) => {
  if (isEmpty(data)) return false;
  const res = {
    labels: [],
    datasets: [
      {
        label: 'Avg Occupancy',
        backgroundColor: '#34BBDB',
        data: [],
      },
      {
        label: 'Max Occupancy',
        backgroundColor: '#0D6F85',
        data: [],
      },
      {
        label: 'Avg Occupancy (seats)',
        backgroundColor: '#c577c0',
        data: [],
      },
      {
        label: 'Max Occupancy (seats)',
        backgroundColor: '#a2559d',
        data: [],
      },
    ],
  };

  data.forEach((item) => {
    res.labels.push(item.name);

    res.datasets[0].data.push(
      parseFloat(returnPercentageSwitcher ? item.avg_space_occupancy : item.percentage_avg_space_occupancy.toFixed(1)),
    );
    res.datasets[1].data.push(
      parseFloat(returnPercentageSwitcher ? item.max_space_occupancy : item.percentage_max_space_occupancy.toFixed(1)),
    );
    res.datasets[2].data.push(
      parseFloat(returnPercentageSwitcher ? item.avg_seat_occupancy : item.percentage_avg_seat_occupancy.toFixed(1)),
    );
    res.datasets[3].data.push(
      parseFloat(returnPercentageSwitcher ? item.max_seat_occupancy : item.percentage_max_seat_occupancy.toFixed(1)),
    );
  });

  return res;
};

export default toSpaceComparisonGraphData;
