import { createReducer } from '@reduxjs/toolkit';

import {
  SET_BLANK_REPORT_DATA,
  SET_REPORT_FILES,
  SET_CSV_DATA,
  SET_TYPE_ID,
  SET_ADDITIONAL_FILES,
  SET_MERGED_FIELDS,
} from 'modules/workLook/actions';

const initState = {
  blankReportData: {},
  reportFiles: [],
  csvData: [],
  typeId: null,
  mergedFields: [],
  additionalFile: {
    additionalFile: null,
    displayNewStep: false,
  },
};

export default createReducer(initState, {
  [SET_BLANK_REPORT_DATA]: (state, { payload }) => {
    return {
      ...state,
      blankReportData: payload,
    };
  },
  [SET_REPORT_FILES]: (state, { payload }) => {
    return {
      ...state,
      reportFiles: payload,
    };
  },
  [SET_ADDITIONAL_FILES]: (state, { payload }) => {
    return {
      ...state,
      additionalFile: payload,
    };
  },
  [SET_MERGED_FIELDS]: (state, { payload }) => {
    return {
      ...state,
      mergedFields: payload,
    };
  },
  [SET_CSV_DATA]: (state, { payload }) => {
    return {
      ...state,
      csvData: payload,
    };
  },
  [SET_TYPE_ID]: (state, { payload }) => {
    return {
      ...state,
      typeId: payload,
    };
  },
});
