/* eslint-disable no-console */
import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';
import { useDispatch } from 'react-redux';
import { SET_GRAPH_EDIT_DATA, SET_GRAPH_ID, SET_GRAPH_SERIES_LIST, SET_MODAL_TYPE } from '../actions';

const useEditGraphClick = (isModal, graphId) => {
  if (isModal) {
    return { onClick: null };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  const url = ENDPOINTS.workLook.editGraphData.replace(':graphId', graphId);
  const urlSeries = ENDPOINTS.workLook.seriesList.replace(':graphId', graphId);

  const onClick = () => {
    dispatch(SET_MODAL_TYPE({ type: 'edit' }));
    API.get(url)
      .then(({ data }) => {
        dispatch(SET_GRAPH_EDIT_DATA(data));
        dispatch(SET_GRAPH_ID(graphId));
        dispatch(showModal(modalCodes.WL_EDIT_GRAPH_MODAL));
      })
      .catch((error) => {
        console.log(error);
      });
    API.get(urlSeries)
      .then(({ data }) => {
        dispatch(SET_GRAPH_SERIES_LIST(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { onEditClick: onClick };
};

export default useEditGraphClick;
