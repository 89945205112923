import { sortObjects } from 'utils';

const getBuildingOrFloorData = (graphData) => {
  const buildOrFloorSorted = graphData.sort(sortObjects('percent_max'));

  const isFloor = !buildOrFloorSorted[0].location_id__building;
  const buildingOrFloor = isFloor ? 'floor' : 'building';
  const nameKey = isFloor ? 'location_id__floor' : 'location_id__building';

  const highestValue = buildOrFloorSorted[buildOrFloorSorted.length - 1];
  const lowestValue = buildOrFloorSorted[0];

  return { highestValue, lowestValue, buildingOrFloor, nameKey };
};

export default getBuildingOrFloorData;
