/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import sortGraphObjects from './sortGraphObjects';

const colors = [
  '#004352',
  '#34BBDB',
  '#F6A000',
  '#B4CC04',
  '#A2559D',
  '#0D6F85',
  '#C4043F',
  '#C477BF',
  '#00A1D4',
  '#9e4200',
  '#364b7c',
  '#367c41',
  '#5C5C5C',
  '#f5e900',
  '#bfeded',
  '#5600f5',
  '#e11469',
  '#5ece27',
  '#9e8714',
  '#F0F3BD',
  '#66B3BA',
  '#71F79F',
  '#FCAA67',
  '#F0F3BD',
  '#6A0136',
  '#735CDD',
  '#80CED7',
  '#F06449',
  '#38369A',
  '#D36582',
  '#7F675B',
  '#FFEAEE',
  '#717744',
  '#8BBD8B',
  '#DBD56E',
  '#DDCECD',
  '#F56476',
  '#79B791',
  '#E08E45',
  '#05668D',
  '#CC2936',
  '#EBBAB9',
  '#6B2B06',
  '#878E88',
  '#909CC2',
  '#7FB069',
  '#FFFBBD',
  '#02C39A',
  '#ABE188',
  '#60AFFF',
  '#EF6461',
  '#4392F1',
  '#F6F5AE',
  '#E9D758',
  '#92BFB1',
  '#E5F77D',
  '#DB3069',
  '#B8B8D1',
  '#FE5F55',
  '#D9DD92',
  '#21295C',
  '#FE9920',
  '#B9A44C',
  '#D0B8AC',
  '#6290C8',
  '#88CCF1',
  '#1BE7FF',
  '#6EEB83',
  '#E4FF1A',
  '#FFEAEE',
  '#FFB8D1',
  '#E55934',
];

const makeAllPeaksMax = (peaks) => {
  const maxValue = Math.max(...peaks);
  return peaks.map(() => maxValue);
};

const setupStackedHorizontalBar = (graphPropertyData, isWithoutFreeSpace = false, isOneMaxValue = false) => {
  const freeSpaceColor = isWithoutFreeSpace ? '#FFFFFF' : '#F1F1F1';
  const colorsMap = {
    'Free Space': freeSpaceColor,
  };
  const peaks = [];
  const graphs = graphPropertyData.map((graphObj) => {
    let peak = 0;
    const data = graphObj.divisions || [];
    const datasets = data.map((division, idx) => {
      const isHasColor = colorsMap[division.division];
      const color = isHasColor || colors[Object.keys(colorsMap).length - 1];
      if (!isHasColor) colorsMap[division.division] = color;
      peak += division.desk_count;
      idx === graphObj.divisions.length - 1 && peaks.push(peak);
      return {
        label: [division.division],
        data: [division.desk_count],
        backgroundColor: color || 'grey',
        hoverBackgroundColor: color,
      };
    });
    return {
      labels: [graphObj.property_name],
      datasets,
    };
  });
  if (isOneMaxValue) {
    graphs.sort((a, b) => {
      const aSum = a.datasets.reduce((sum, obj) => {
        sum += obj.data[0];
        return sum;
      }, 0);
      const bSum = b.datasets.reduce((sum, obj) => {
        sum += obj.data[0];
        return sum;
      }, 0);
      return bSum - aSum;
    });
  }

  return {
    graphs: isOneMaxValue ? sortGraphObjects(graphs) : graphs,
    legend: colorsMap,
    peaks: isOneMaxValue ? makeAllPeaksMax(peaks) : peaks,
  };
};

export default setupStackedHorizontalBar;
