import React from 'react';
import { isEmpty } from 'lodash';
import { useRouteMatch } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { URLS } from 'pages/constants';
import { makeSearch } from 'modules/assetLook/utils';
import { PageSelectorItem } from 'modules/shared/components';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import {
  BUILDING_ID,
  CATEGORY_ID,
  PRODUCT_ID,
  PRODUCT_FIELDS,
  ASSET_FIELDS,
  PAGE_TYPES,
} from 'modules/assetLook/constants';

const PageSelectorBar = () => {
  const [products, assets] = useAssetLookSelector(['products.products', 'assets.assets']);
  const { buildingId, categoryId, productId } = useLocationSearchParams([BUILDING_ID, CATEGORY_ID, PRODUCT_ID]);

  const matchProductList = useRouteMatch(URLS.assetLook.productList);
  const matchAllAssets = useRouteMatch(URLS.assetLook.allAssets);
  const matchProductSummary = useRouteMatch(URLS.assetLook.productSummary);
  const matchAssetList = useRouteMatch(URLS.assetLook.assetList);
  const matchAssetDetails = useRouteMatch(URLS.assetLook.assetDetails);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <PageSelectorItem
          link={{
            pathname: URLS.assetLook.productList,
            search: makeSearch({ buildingId, categoryId }),
            state: { isBuilding: true },
          }}
          label={PAGE_TYPES.productList}
          active={Boolean(matchProductList)}
        />
      </Grid>
      <Grid item>
        <PageSelectorItem
          link={
            !isEmpty(products.data) && {
              pathname: URLS.assetLook.productSummary,
              search: makeSearch({
                buildingId,
                categoryId,
                productId: productId || products.data[0][PRODUCT_FIELDS.id],
              }),
              state: { isBuilding: true },
            }
          }
          label={PAGE_TYPES.productSummary}
          active={Boolean(matchProductSummary)}
        />
      </Grid>
      <Grid item>
        <PageSelectorItem
          link={
            !isEmpty(products.data) && {
              pathname: URLS.assetLook.assetList,
              search: makeSearch({
                buildingId,
                categoryId,
                productId: productId || products.data[0][PRODUCT_FIELDS.id],
              }),
              state: { isBuilding: true },
            }
          }
          label={PAGE_TYPES.assetList}
          active={Boolean(matchAssetList)}
        />
      </Grid>
      <Grid item>
        <PageSelectorItem
          link={
            !isEmpty(products.data) && {
              pathname: URLS.assetLook.allAssets,
              search: makeSearch({
                buildingId,
                categoryId,
                productId: productId || products.data[0][PRODUCT_FIELDS.id],
              }),
              state: { isBuilding: true },
            }
          }
          label={PAGE_TYPES.allAssets}
          active={Boolean(matchAllAssets)}
        />
      </Grid>
      <Grid item>
        <PageSelectorItem
          link={
            !isEmpty(assets.data) && {
              pathname: URLS.assetLook.assetDetails,
              search: makeSearch({
                buildingId,
                categoryId,
                productId,
                assetId: assets.data[0][ASSET_FIELDS.id],
              }),
              state: { isBuilding: true },
            }
          }
          label={PAGE_TYPES.assetDetails}
          active={Boolean(matchAssetDetails)}
        />
      </Grid>
    </Grid>
  );
};

export default PageSelectorBar;
