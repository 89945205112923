import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_BREAKOUT_OVER_TIME = createAsyncAction(createAction('FETCH_BREAKOUT_OVER_TIME'));
export const FETCH_BREAKOUT_OVER_TIME_WEEK = createAsyncAction(createAction('FETCH_BREAKOUT_OVER_TIME_WEEK'));

const fetchBreakoutOverTime = (
  dispatch,
  isCanceled,
  { companyId, dateAfter, dateBefore, workingHours, scaleOption, query, week, archive },
) => {
  dispatch(week ? FETCH_BREAKOUT_OVER_TIME_WEEK.pending() : FETCH_BREAKOUT_OVER_TIME.pending());

  const url = ENDPOINTS.liveLook.breakoutOverTime
    .replace(':companyId', companyId)
    .concat(`?date_after=${dateAfter}`)
    .concat(`&date_before=${dateBefore}`)
    .concat(workingHours ? `&work_hours=True` : '')
    .concat(workingHours ? `&exclude_weekend=True` : '')
    .concat(scaleOption ? `&date_scale=${scaleOption.toLowerCase()}` : '')
    .concat(query ? `&${query}` : '')
    .concat(archive ? `&archive_id=${archive}` : '');

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(week ? FETCH_BREAKOUT_OVER_TIME_WEEK.success(data) : FETCH_BREAKOUT_OVER_TIME.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(week ? FETCH_BREAKOUT_OVER_TIME_WEEK.failure() : FETCH_BREAKOUT_OVER_TIME.failure());
    });
};

export default fetchBreakoutOverTime;
