import { createReducer } from '@reduxjs/toolkit';
import { sub, isBefore } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

import {
  FETCH_COMMON_DATA,
  SET_START_DATE,
  SET_END_DATE,
  RESET_STATE,
  TOGGLE_WORKING_HOURS,
  SET_ARCHIVE,
} from 'modules/liveLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';
import { requestStatuses } from 'modules/shared/constants';

const initState = {
  requestStatuses: requestStatuses.default,
  buildings: null,
  floors: null,
  initDate: null,
  startDate: null,
  endDate: null,
  hasWorkingHours: null,
  workingHours: true,
  titles: null,
  archive: null,
  arhiveDateEnd: null,
};

export default createReducer(initState, {
  [FETCH_COMMON_DATA.pending]: (state) => ({
    ...state,
    requestStatus: requestStatuses.pending,
  }),
  [FETCH_COMMON_DATA.success]: (state, { payload }) => {
    // eslint-disable-next-line camelcase
    const { buildings, floors, date_begin, date_end, work_hours, titles, archive } = payload;

    const initDate = new Date(date_begin);
    const initDateLondon = utcToZonedTime(initDate.toISOString(), 'Europe/London');
    const initDateLondonFormatted = format(initDateLondon, 'yyyy-MM-dd', { timeZone: 'Europe/London' });

    const initDateEnd = new Date(date_end);
    const initDateEndLondon = utcToZonedTime(initDateEnd.toISOString(), 'Europe/London');
    const initDateEndLondonFormatted = format(initDateEndLondon, 'yyyy-MM-dd', { timeZone: 'Europe/London' });

    const endDate = new Date();
    const endDateLondon = utcToZonedTime(endDate.toISOString(), 'Europe/London');
    const endDateLondonFormatted = format(endDateLondon, 'yyyy-MM-dd', { timeZone: 'Europe/London' });

    const startDate = sub(endDate, { days: 29 });
    const startDateLondon = utcToZonedTime(startDate.toISOString(), 'Europe/London');
    const startDateLondonFormatted = format(startDateLondon, 'yyyy-MM-dd', { timeZone: 'Europe/London' });

    return {
      ...state,
      requestStatus: requestStatuses.success,
      buildings,
      floors,
      initDate: initDateLondonFormatted,
      startDate: isBefore(initDateLondon, startDateLondon) ? startDateLondonFormatted : initDateLondonFormatted,
      endDate: endDateLondonFormatted,
      arhiveDateEnd: initDateEndLondonFormatted,
      hasWorkingHours: work_hours,
      workingHours: work_hours,
      titles,
      archive,
    };
  },
  [FETCH_COMMON_DATA.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
  }),

  [SET_START_DATE]: (state, { payload: startDate }) => ({
    ...state,
    startDate,
  }),
  [SET_END_DATE]: (state, { payload: endDate }) => ({
    ...state,
    endDate,
  }),
  [SET_ARCHIVE]: (state, { payload: archive }) => ({
    ...state,
    archive,
  }),
  [TOGGLE_WORKING_HOURS]: (state, { payload }) => ({
    ...state,
    workingHours: payload,
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
