import React from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

import {
  PageHeader,
  ReportText,
  ReportDataCards,
  ReportFilter,
  FilterHandler,
  SpaceUsageByTypeGraph,
  SpaceObservedSizesGraph,
} from 'modules/spaceLook/components';

import { reportPageTypes } from 'modules/spaceLook/constants';
import {
  setStackedGraphColumnsData,
  setupBuildingFloorData,
  setupStackedGraphRowsPeople,
  splitOneGraph,
  splitSizesGraphLabels,
} from 'utils';

import { useHasScroll } from 'modules/shared/hooks';
import config from './config';

const MeetingsSpaceCompAndSizes = ({
  databoxes,
  observedSizesData,
  peakAndAvgUtilData,
  comparisonTextData,
  pdfMode,
  isLoading,
}) => {
  // PEAK AND AVG USAGE BY TYPE GRAPH
  const scroll = useHasScroll();
  const usageByTypeData = setStackedGraphColumnsData(
    setupBuildingFloorData(peakAndAvgUtilData, null, 'location_id__type'),
  );
  const splitUsageByTypeData = pdfMode ? splitOneGraph(usageByTypeData, 5) : [usageByTypeData];
  const usageByTypeDatasets = splitUsageByTypeData.map((graph) => ({
    graphType: config.graphTypes.usageByType,
    data: {
      labels: graph.labels,
      datasets: [
        {
          label: 'Avg in Use, %',
          backgroundColor: '#00aad2',
          data: graph.occ_percent_avg,
        },
        {
          label: 'Max in Use, %',
          backgroundColor: '#0D6F85',
          data: graph.occ_percent_max,
        },
      ],
    },
  }));

  // OBSERVED SIZES GRAPH
  const labels = pdfMode
    ? splitSizesGraphLabels(observedSizesData, 5)
    : splitSizesGraphLabels(observedSizesData, Object.keys(observedSizesData).length);
  const observedSizes = setupStackedGraphRowsPeople(observedSizesData);
  const splitObservedSizesData = pdfMode ? splitOneGraph(observedSizes, 5) : [observedSizes];
  const observedSizesDatasets = splitObservedSizesData.map((graph, idx) => ({
    graphType: config.graphTypes.observedSizes,
    data: {
      labels: labels[idx],
      datasets: Object.keys(graph).map((rowName) => ({
        label: rowName,
        data: graph[rowName],
        stack: 'standing costs',
        backgroundColor: config.observedSizesColors[rowName],
      })),
    },
  }));

  // SINGLE GRAPH PDF PAGES
  const combinedGraphDatasets = [...usageByTypeDatasets, ...observedSizesDatasets];
  const pdfPages = combinedGraphDatasets.map(({ graphType, data }, idx) => (
    <div ref={scroll.ref} key={idx} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode })}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title={config.titles.page} text={config.titles.pageSubtitle} />
        </Grid>

        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.meetings} />
        </Grid>

        <Grid item xs={12}>
          <Choose>
            <When condition={graphType === config.graphTypes.usageByType}>
              <SpaceUsageByTypeGraph data={data} title={config.titles.usageByTypeGraph} />
            </When>
            <When condition={graphType === config.graphTypes.observedSizes}>
              <SpaceObservedSizesGraph data={data} title={config.titles.observedSizesGraph} />
            </When>
          </Choose>
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={[
              'meetsAndBreakAvgOccupancy',
              'meetsAndBreakOneToFourWithSolo',
              'meetsAndBreakOccupancy',
              'singleSpaceUsage',
            ]}
            repeat={{
              singleSpaceUsage: peakAndAvgUtilData.length,
            }}
            databoxes={databoxes}
            textData={comparisonTextData}
            locationsData={peakAndAvgUtilData}
            pageType={reportPageTypes.meetings}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));

  return (
    <Choose>
      <When condition={pdfMode}>{pdfPages}</When>
      <Otherwise>
        <div className={cn({ blured: isLoading })}>
          <Grid container spacing={4}>
            <Grid item xs={10}>
              <PageHeader title={config.titles.page} text={config.titles.pageSubtitle} />
            </Grid>

            <Grid item xs={2} align="right">
              <FilterHandler pageType={reportPageTypes.meetings}>
                <ReportFilter pageType={reportPageTypes.meetings} />
              </FilterHandler>
            </Grid>

            <Grid item xs={12}>
              <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.meetings} cropped />
            </Grid>

            <Grid item xs={12} xl={6}>
              <SpaceUsageByTypeGraph
                data={usageByTypeDatasets[0].data}
                title={config.titles.usageByTypeGraph}
                isCropped
              />
            </Grid>

            <Grid item xs={12} xl={6}>
              <SpaceObservedSizesGraph data={observedSizesDatasets[0].data} title={config.titles.observedSizesGraph} />
            </Grid>

            <Grid item xs={12}>
              <ReportText
                getParagraphs={[
                  'meetsAndBreakAvgOccupancy',
                  'meetsAndBreakOneToFourWithSolo',
                  'meetsAndBreakOccupancy',
                  'singleSpaceUsage',
                ]}
                repeat={{
                  singleSpaceUsage: peakAndAvgUtilData.length,
                }}
                databoxes={databoxes}
                textData={comparisonTextData}
                locationsData={peakAndAvgUtilData}
                pageType={reportPageTypes.meetings}
              />
            </Grid>
          </Grid>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default MeetingsSpaceCompAndSizes;
