const findPeakDays = (graphContent, peakValue, key = 'percent_occupancy') => {
  if (peakValue === 0) return '';

  return graphContent
    .filter((dayTime) => {
      return dayTime[key] >= peakValue;
    })
    .reduce((result, current, idx) => {
      const coma = idx ? ', ' : '';
      return `${result}${coma}${current.date} at ${current.hour}`;
    }, '');
};

export default findPeakDays;
