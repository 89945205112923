import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HIDE_SECOND_MODAL } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';

import * as modalComponentsSettings from 'components/Modals/UserSettingsModal';
import * as modalComponentsCompanySettings from 'components/Modals/CompanySettingsModal';
import Error from '../Error';

const modalMap = {
  [modalCodes.EDIT_USER]: modalComponentsSettings.EditUserModal,
  [modalCodes.EDIT_COMPANY]: modalComponentsCompanySettings.EditCompanyModal,
  [modalCodes.CONFIRM_DELETION]: modalComponentsSettings.ConfirmDeletionModal,
  [modalCodes.ERROR]: Error,
  [modalCodes.RESET_PASSWORD]: modalComponentsSettings.ResetPasswordModal,
};

function SecondModalPlace() {
  const dispatch = useDispatch();
  const onClose = useCallback(() => dispatch(HIDE_SECOND_MODAL()), [dispatch]);

  const { secondModalCode, secondModalProps } = useSelector(({ secondModal }) => ({ ...secondModal }));

  if (!secondModalCode) return null;

  const ModalComponent = modalMap[secondModalCode];

  return <ModalComponent onClose={onClose} {...secondModalProps} />;
}

export default SecondModalPlace;
