import { isEmpty } from 'lodash';
import { FILTERS } from 'modules/liveLook/constants';

const dayNumbers = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5 };

const filtersToQuery = (filterList) => {
  if (!Array.isArray(filterList) || isEmpty(filterList)) return '';

  const query = filterList
    .filter(({ values }) => values.length)
    .map(({ field, values }) => ({
      field: Object.keys(FILTERS).find((key) => FILTERS[key] === field),
      values,
    }))
    .map(({ field, values }) => {
      if (field === 'weekday') {
        return values.map((value) => `${field}=${dayNumbers[value]}`).join('&');
      }
      return values.map((value) => `${field}=${encodeURIComponent(value)}`).join('&');
    })
    .join('&');

  return query ? `${query}` : '';
};

export default filtersToQuery;
