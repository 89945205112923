const toQuery = (filterList) => {
  if (!Array.isArray(filterList) || (Array.isArray(filterList) && !filterList.length)) return '';

  const query = filterList
    .filter(({ values }) => values.length)
    .map(({ field, values }) => values.map((value) => `${field}=${encodeURIComponent(value)}`).join('&'))
    .join('&');

  return query ? `?${query}` : '';
};

export default toQuery;
