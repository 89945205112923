import React from 'react';
import Collapsible from 'react-collapsible';

import './InputsToggler.scss';

import { Checkbox, AccordionSidebarListHeader } from '../..';

import building from '../../../assets/images/icons/building.svg';

const options = {
  transitionTime: 200,
  open: false,
  easing: 'ease-out',
};

const Toggler = ({ items, onClick, currentItem, icon, title }) => {
  const renderedItems = items.map(({ name, id }) => {
    const isChecked = id === currentItem;
    return <Checkbox key={id} label={name} onClick={() => onClick(id)} isChecked={isChecked} isStrict />;
  });

  return (
    <div className="InputsToggler">
      <div className="inputs-toggler">
        <Collapsible {...options} trigger={<AccordionSidebarListHeader iconUrl={icon} label={title} />}>
          <div className="inputs-toggler-wrapper">
            <div className="inputs-toggler-container">{renderedItems}</div>
          </div>
        </Collapsible>
      </div>
    </div>
  );
};

Toggler.defaultProps = {
  title: 'Change company',
  // eslint-disable-next-line react/default-props-match-prop-types
  icon: building,
};

export default Toggler;
