import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { getPdfMode } from 'modules/spaceLook/selectors';

import config from './config';
import theme from './agilityDataCards.module.scss';

const AgilityDataCards = ({ databoxes }) => {
  const pdfMode = useSelector(getPdfMode);

  return (
    <Grid
      container
      className={cn(`${theme.agilityDataCardsContainer}`, { [`${theme.pdfMode}`]: pdfMode })}
      spacing={pdfMode ? 2 : 4}
    >
      {config.map((card) => (
        <Grid item key={card.label}>
          <div className={theme.agilityDataCard} style={{ backgroundColor: `var(--${card.color})` }}>
            <img src={card.icon} alt={`${card.label}`} />
            <p className={theme.agilityDataCardLabel}>{card.label}</p>
            <p className={theme.agilityDataCardValue}>{`${databoxes[card.key]}%`}</p>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default AgilityDataCards;
