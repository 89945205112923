import React from 'react';
import { Grid } from '@material-ui/core';

import { ASC } from 'modules/shared/constants';

import { OrderItem } from 'modules/assetLook/components';

const OrderList = ({ orders, sortParams: { orderBy, direction } }) => (
  <Grid container spacing={2}>
    {orders
      .map((i) => i)
      .sort((a, b) =>
        direction === ASC ? new Date(a[orderBy]) - new Date(b[orderBy]) : new Date(b[orderBy]) - new Date(a[orderBy]),
      )
      .map((order) => (
        <Grid item xs={12} key={order.id}>
          <OrderItem {...order} />
        </Grid>
      ))}
  </Grid>
);

export default OrderList;
