const getReportInfo = ({ report: { services } }) => ({
  reportInfoRequestStatus: services.reportInfoRequestStatus,
  surveyName: services.surveyName,
  companyName: services.companyName,
  startDate: services.startDate,
  endDate: services.endDate,
  reportLogo: services.reportLogo,
  titleImage: services.titleImage,
  titleText: services.titleText,
  titleTextColor: services.titleTextColor,
  subTitleImage: services.subTitleImage,
  subTitleTextColor: services.subTitleTextColor,
});

export default getReportInfo;
