/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Children } from 'react';
import cn from 'classnames';

import './TabsNav.scss';

const TabsNav = ({ children, onClick, activeIdx, isOtpRequired }) => {
  const renderedChildren =
    children &&
    Children.map(children, (child, idx) => {
      const classes = cn('tab-nav-item', { 'active-tab': idx === activeIdx });
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div className={classes} onClick={() => onClick(idx)}>
          {child}
        </div>
      );
    });

  return (
    <section className={`TabsNav ${!isOtpRequired && 'TabsPosition'}`}>
      {renderedChildren || <p className="TabsNav-message">Error: TabsNav items must be here</p>}
    </section>
  );
};

export default TabsNav;
