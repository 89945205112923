import React from 'react';
import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { CustomButtonContained } from 'modules/shared/components';

const Dialog = ({ onClose, title, body, onCancel, onApply, applyLabel = 'Ok', maxWidth = 'xs' }) => (
  <MUIDialog
    open
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth={maxWidth}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
    </DialogContent>
    <DialogActions style={{ padding: '16px 24px' }}>
      <Button autoFocus color="primary" onClick={() => (onCancel ? onCancel(onClose) : onClose())}>
        Cancel
      </Button>
      <CustomButtonContained label={applyLabel} onClick={() => onApply(onClose)} />
    </DialogActions>
  </MUIDialog>
);

export default Dialog;
