import React from 'react';

import { useNodeSize } from 'modules/shared/hooks';

function AutoSizer({ children }) {
  const { ref, height, width } = useNodeSize();

  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {children({ height, width })}
    </div>
  );
}

export default AutoSizer;
