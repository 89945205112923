const toBenchmarkingGraphData = (data) => {
  const graphData = {
    labels: [],
    datasets: [
      {
        label: 'Avg Occupancy',
        backgroundColor: '#B4CC04',
        data: [],
      },
      {
        label: 'Max Occupancy',
        backgroundColor: '#C4043F',
        data: [],
      },
      {
        label: 'Avg Utilisation',
        backgroundColor: '#51C3CA',
        data: [],
      },
      {
        label: 'Max Utilisation',
        backgroundColor: '#004352',
        data: [],
      },
    ],
  };

  data.forEach((item) => {
    const name = item.name.length > 16 ? `${item.name.slice(0, 13)}...` : item.name;
    graphData.labels.push(name);
    graphData.datasets[0].data.push(item.survey_result_avg_oc);
    graphData.datasets[1].data.push(item.survey_result_max_oc);
    graphData.datasets[2].data.push(item.survey_result_avg_util);
    graphData.datasets[3].data.push(item.survey_result_max_util);
  });

  return graphData;
};

export default toBenchmarkingGraphData;
