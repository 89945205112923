import redirectToLogin from './redirectToLogin';

const withoutSecondFactorAuthLogOut = (error, history) => {
  // eslint-disable-next-line no-console
  console.log(error, 'error');
  if (!error.response) return;
  if (error.response.data.detail === 'OTP authorization required.') {
    localStorage.clear();
    redirectToLogin(history);
  }
};

export default withoutSecondFactorAuthLogOut;
