/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { Grid, Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  firstLine: {
    color: theme.palette.primary.dark,
    textAlign: 'right',
  },
  button: {
    padding: 0,
    borderColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  secondLine: {
    color: 'rgba(0,0,0,0.6)',
  },
}));

function AddToPDF({ counter, onClick }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} align="right">
        <Button
          variant="contained"
          color="secondary"
          onClick={onClick}
          style={{ width: '150px', marginBottom: '10px' }}
        >
          + Add to PDF
        </Button>
      </Grid>
      <Grid item xs={12} align="right">
        <Typography component="span" classes={{ root: classes.secondLine }}>
          {counter || counter === 0 ? (
            `${counter} ${counter === 1 ? 'page' : 'pages'} added`
          ) : (
            <Skeleton width="80px" />
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AddToPDF;
