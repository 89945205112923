import { isEmpty } from 'lodash';

const getModifierFields = (data, type) => {
  if (isEmpty(data)) {
    return [];
  }
  if (type === '1' || type === '8') {
    return data?.map(({ name }, index) => ({ label: name, id: index.toString() }));
  }
  if (type === '2' || type === '3' || type === '6' || type === '9') {
    return data?.fullLabels?.map((name, index) => ({ label: name, id: index.toString() }));
  }
  if (type === '4' || type === '5' || type === '11') {
    return data?.datasets?.map(({ label }, index) => ({ label, id: index.toString() }));
  }
  if (type === '7') {
    return Object.keys(data.rows).map((key, index) => ({ label: key, id: index.toString() }));
  }
  if (type === '15') {
    return data.map(({ field }, index) => ({ label: field, id: index.toString() }));
  }
  if (type === '18') {
    return data.map((field, index) => ({ label: field.date, id: index.toString() }));
  }
  if (type === '13') {
    return data.map((field, index) => ({ label: field.DateTime, id: index.toString() }));
  }
  return [];
};

export default getModifierFields;
