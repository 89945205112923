import React from 'react';
import { List } from '@material-ui/core';

import { AllProductsCategory, CategoryItem } from 'modules/assetLook/components/Sidebar/components/index';

const CategoryList = ({ categories, ...props }) => (
  <List>
    <AllProductsCategory {...props} />

    {categories.map((category) => (
      <CategoryItem key={category.category_id} {...category} {...props} />
    ))}
  </List>
);

export default CategoryList;
