import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { useStandardPdf, useCustomPdfList } from 'modules/workLook/hooks';
import { getReports } from 'modules/workLook/selectors';

import { DownloadsList } from 'modules/shared/components';

const History = () => {
  const { reportId } = useParams();
  const { reportData } = useSelector(getReports);

  const standardPdf = useStandardPdf(reportId);
  const [customPdfList, setCustomPdfList] = useCustomPdfList(reportId);

  const _onItemDelete = (pdfReportId) => {
    const deleteUrl = ENDPOINTS.workLook.customPdf.replace(':reportId', 'id');
    const getUrl = ENDPOINTS.workLook.customPdf.replace(':reportId', reportId);

    API.delete(`${deleteUrl}${pdfReportId}/`)
      .then(() => API.get(getUrl))
      .then(({ data }) => setCustomPdfList(data))
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DownloadsList
          title="Standard Report"
          emptyListPlaceholder="No standard report yet"
          items={!standardPdf ? null : standardPdf.status ? [{ ...standardPdf, name: reportData[reportId].name }] : []}
          skeletonItems={1}
        />
      </Grid>

      <Grid item xs={12}>
        <DownloadsList
          title="Custom Reports"
          items={customPdfList}
          emptyListPlaceholder="No custom reports yet"
          skeletonItems={5}
          _onItemDelete={_onItemDelete}
          deleteTitle="Delete custom report?"
          deleteText="This operation can't be undone"
        />
      </Grid>
    </Grid>
  );
};

export default History;
