const DesksUsageBandingLess20 = ({ graphData, usageBandingType }) => {
  const totalDesks = Object.values(graphData).reduce((a, b) => a + b, 0);
  const less20Quantity = graphData[10] + graphData[20];
  const less20Percent = Math.round((less20Quantity / totalDesks) * 100);

  const occupiedOrUtilised = usageBandingType === 'OCCUPIED' ? 'occupied' : 'utilised';

  return `A further ${Math.floor(less20Quantity)} (${Math.floor(less20Percent)}%) desks
     were ${occupiedOrUtilised} ≤20% of the time during the survey.`;
};

export default DesksUsageBandingLess20;
