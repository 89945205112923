import { requestStatuses } from 'modules/shared/constants';
import { GRAPH_SWITCHER } from 'modules/liveLook/constants';
import {
  SET_GRAPH_SWITCHER,
  FETCH_MEETS_DATABOXES,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
  FETCH_MEETS_PEAK_AND_AVG_UTIL_LIVE,
  FETCH_MEETS_USAGE_BY_SIZE,
} from 'modules/liveLook/actions';

export const initialState = {
  requestStatus: requestStatuses.default,
  meetSpaceBySizeRequestStatus: requestStatuses.default,
  databoxRequestStatus: requestStatuses.default,
  textReportRequestStatus: requestStatuses.default,
  data: null,
  meetSpaceBySizeData: null,
  meetsDataboxes: [],
  returnPercentageSwitcher: GRAPH_SWITCHER.percentages,
  editableTextReport: '',
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_MEETS_PEAK_AND_AVG_UTIL_LIVE.pending().type: {
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    }
    case FETCH_MEETS_PEAK_AND_AVG_UTIL_LIVE.success().type: {
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload,
      };
    }
    case FETCH_MEETS_PEAK_AND_AVG_UTIL_LIVE.failure().type: {
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    }
    case FETCH_MEETS_USAGE_BY_SIZE.pending().type:
      return {
        ...state,
        meetSpaceBySizeRequestStatus: requestStatuses.pending,
      };
    case FETCH_MEETS_USAGE_BY_SIZE.success().type:
      return {
        ...state,
        meetSpaceBySizeRequestStatus: requestStatuses.success,
        meetSpaceBySizeData: payload,
      };
    case FETCH_MEETS_USAGE_BY_SIZE.failure().type:
      return {
        ...state,
        meetSpaceBySizeRequestStatus: requestStatuses.failure,
      };
    case FETCH_MEETS_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_MEETS_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        meetsDataboxes: payload,
      };
    case FETCH_MEETS_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SET_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcher: payload,
      };
    default:
      throw new Error();
  }
}

export default reducer;
