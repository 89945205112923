import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from 'components';

import { SET_FILTER_OPTION } from 'modules/spaceLook/actions';
import { getPropertyNamesMap } from 'modules/shared/selectors';
import config from 'components/Filters/ReportFilter/config';

import theme from './FilterList.module.scss';

const FilterList = ({ title, initOptions, availableOptions, filterCategory, selectedFilters }) => {
  const { propertyNamesMap } = useSelector(getPropertyNamesMap);
  const dispatch = useDispatch();

  return (
    <div className={theme.filterSection}>
      <h3 className={theme.header}>{title}</h3>
      <div className={theme.list}>
        {initOptions.map((filterOption) => {
          let customLabel;

          if (filterCategory === 'day_of_week') {
            customLabel = config.day_of_week.weekDays[filterOption];
          }

          if (filterCategory === 'building') {
            // eslint-disable-next-line camelcase
            const propertyInList = propertyNamesMap.find(({ property_id }) => property_id === filterOption);
            customLabel = (propertyInList && propertyInList.property_name) || filterOption;
          }

          return (
            <Checkbox
              key={filterOption}
              className={theme.item}
              label={customLabel || filterOption}
              isDisabled={!availableOptions.includes(filterOption)}
              isChecked={selectedFilters[filterCategory].includes(filterOption)}
              onClick={() =>
                dispatch(
                  SET_FILTER_OPTION({
                    value: filterOption,
                    filterCategory,
                  }),
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilterList;
