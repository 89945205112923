/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';

import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: 0,
    cursor: 'pointer',
    borderBottom: ({ disableBorder }) => (disableBorder ? '' : '1px solid #E5E5E5'),
  },
  listItemIcon: {
    minWidth: '40px',
  },
  listItemText: {
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: (props) =>
      props.isUnpublished
        ? theme.palette.secondary.main
        : props.isActive
        ? theme.palette.primary.main
        : 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      color: (props) => (props.isUnpublished ? theme.palette.secondary.main : theme.palette.primary.main),
    },
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

const SidebarListItem = ({ Icon, label, isActive, isUnpublished, isAccordionOpen, _onItemClick, disableBorder }) => {
  const classes = useStyles({ isActive, isUnpublished, disableBorder });

  return (
    <ListItem classes={{ root: classes.listItem }} onClick={_onItemClick}>
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={label} classes={{ primary: classes.listItemText }} />
      <If condition={typeof isAccordionOpen === 'boolean'}>
        {isAccordionOpen ? (
          <ArrowDropUpIcon classes={{ root: classes.arrow }} />
        ) : (
          <ArrowDropDownIcon classes={{ root: classes.arrow }} />
        )}
      </If>
    </ListItem>
  );
};

export default SidebarListItem;
