/* eslint-disable react/no-children-prop */
import React from 'react';
import { cloneDeep, isEmpty } from 'lodash';

import { Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';

import { FilterButton } from 'modules/shared/components';
import SearchField from './SearchField';

const FilterList = ({ disabled, initFilters, activeFilters, onFilterButtonClick, onResetClick, searchPlaceholder }) => {
  const deep = cloneDeep(initFilters);
  return (
    <Grid container spacing={2}>
      <If condition={searchPlaceholder}>
        <Grid item>
          <SearchField disabled={disabled} placeholder={searchPlaceholder} />
        </Grid>
      </If>
      {deep.map(({ field }, i) => {
        const activeFilter = activeFilters.find((filter) => filter.field === field);
        const isActive = activeFilter && !isEmpty(activeFilter.values);

        return (
          <Grid item key={i}>
            <FilterButton
              disabled={disabled}
              isActive={isActive}
              // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
              startIcon={isActive ? <DoneIcon size="small" /> : null}
              onClick={() => onFilterButtonClick(field)}
              children={field}
            />
          </Grid>
        );
      })}
      <Grid item>
        <FilterButton
          disabled={disabled}
          isActive
          startIcon={<CachedIcon size="small" />}
          onClick={onResetClick}
          children="Reset"
        />
      </Grid>
    </Grid>
  );
};

export default FilterList;
