import { GRAPHSCALES } from './constants';

const getTotalAmount = ({ scale, totalAmountPerDay }) => {
  if (scale === GRAPHSCALES.DAY) {
    return totalAmountPerDay;
  }
  if (scale === GRAPHSCALES.HALF_DAY) {
    return totalAmountPerDay;
  }
  return totalAmountPerDay;
};

export default getTotalAmount;
