import React from 'react';

import { StackedPercentNav, GroupedBarLegend } from 'components';
import { constructOptions } from 'utils/summaryGraphs';
import { graphTypes, graphDataTypes } from 'components/Pages/SummaryPage/constants';
import { sortData } from 'components/Pages/SummaryPage/utils/sortData';

import ConstructGraph from '../ConstructGraph';

import { options, optionsDivisionBar } from './options';
import config from '../config';

const SummaryPageGraphs = ({
  graphType,
  graphDataType,
  sortOrder,
  data: {
    graphProperty,
    graphPropertyPeaks,
    graphDivision,
    graphDivisionPeaks,
    graphPropertyPercent,
    graphPropertyPercentPeaks,
    graphDivisionPercent,
    graphDivisionPercentPeaks,
    propertyGraphLegendData,
  },
}) => {
  // eslint-disable-next-line prefer-destructuring
  options.maxLabelValue = graphPropertyPeaks[0];

  return (
    <Choose>
      <When condition={graphType === graphTypes.property && graphDataType === graphDataTypes.deskCount}>
        <div className="one-graph-wrapper">
          <div>
            <ConstructGraph
              data={sortData(graphProperty, sortOrder)}
              options={constructOptions(graphPropertyPeaks, options)}
            />
          </div>
          <GroupedBarLegend items={propertyGraphLegendData} />
        </div>
      </When>

      <When condition={graphType === graphTypes.property && graphDataType === graphDataTypes.deskUtilization}>
        <div className="one-graph-wrapper">
          <div className="with-nav-bar">
            <ConstructGraph
              data={sortData(graphPropertyPercent, sortOrder)}
              options={constructOptions(graphPropertyPercentPeaks, options, false)}
            />
            <StackedPercentNav />
          </div>
          <GroupedBarLegend items={config.graphsLegend.utilization} />
        </div>
      </When>

      <When condition={graphType === graphTypes.division && graphDataType === graphDataTypes.deskCount}>
        <div className="one-graph-wrapper">
          <div className="with-border-bar">
            <ConstructGraph
              data={sortData(graphDivision, sortOrder)}
              options={constructOptions(graphDivisionPeaks, optionsDivisionBar)}
            />
            <p className="count-division-legend">Allocated staff</p>
          </div>
        </div>
      </When>

      <When condition={graphType === graphTypes.division && graphDataType === graphDataTypes.deskUtilization}>
        <div className="one-graph-wrapper">
          <div className="with-nav-bar">
            <ConstructGraph
              data={sortData(graphDivisionPercent, sortOrder)}
              options={constructOptions(graphDivisionPercentPeaks, options, false)}
            />
            <StackedPercentNav />
          </div>
          <GroupedBarLegend items={config.graphsLegend.utilization} />
        </div>
      </When>
    </Choose>
  );
};

export default SummaryPageGraphs;
