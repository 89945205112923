/* eslint-disable no-restricted-properties */
// The "nice number" algorithm to get a "nice" value for the scale.
// It is used in the chart-js library.
// See https://stackoverflow.com/questions/8506881/nice-label-algorithm-for-charts-with-minimum-ticks for details.

const getNiceNumbers = (minPoint, maxPoint, maxTicks = 7, isTimeGraph) => {
  /**
   * Returns a "nice" number approximately equal to range
   * Rounds the number if round = true
   * Takes the ceiling if round = false
   *
   *  @param {int} localRange - the hourlyData range
   *  @param {boolean} round - whether to round the result
   */
  function niceNum(localRange, round) {
    const exponent = Math.floor(Math.log10(localRange));
    const fraction = localRange / Math.pow(10, exponent);
    let niceFraction; /** nice, rounded fraction */

    if (round) {
      if (fraction < 1.5) niceFraction = 1;
      else if (fraction < 3) niceFraction = 2;
      else if (fraction < 7) niceFraction = 5;
      else niceFraction = 10;
    } else if (fraction <= 1) niceFraction = 1;
    else if (fraction <= 2) niceFraction = 2;
    else if (fraction <= 5) niceFraction = 5;
    else niceFraction = 10;

    return niceFraction * Math.pow(10, exponent);
  }

  const range = niceNum(maxPoint - minPoint, false);
  const tickSpacing = isTimeGraph ? niceNum(range / maxTicks, false) : niceNum(range / (maxTicks - 1), true);
  const niceMin = Math.floor(minPoint / tickSpacing) * tickSpacing;
  const niceMax = isTimeGraph ? maxPoint : Math.ceil(maxPoint / tickSpacing) * tickSpacing;

  return {
    tickSpacing,
    niceMin,
    niceMax,
  };
};

export default getNiceNumbers;
