import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { SORT_ORDER } from 'modules/workLook/constants';
import { getReports } from 'modules/workLook/selectors';
import { BASKET_TOGGLE_CHECK_SINGLE } from 'modules/workLook/actions';

import TableOfContents from './TableOfContents';

const PDF_REPORT_TITLE_PAGE_NUMBER = 1;

const TableOfContentsContainer = () => {
  const dispatch = useDispatch();
  const { reportId } = useParams();
  const { reportData } = useSelector(getReports);

  const basket = cloneDeep(reportData[reportId].basket);
  let pageCounter = PDF_REPORT_TITLE_PAGE_NUMBER;

  const withCounterBasket = Object.values(basket)
    .sort(SORT_ORDER.byOrder)
    .map((section) => {
      pageCounter += 1;

      return {
        ...section,
        pages: section.pages.map((page) => {
          pageCounter += 1;

          return { ...page, pageCounter };
        }),
      };
    });

  const _onPageClick = (sectionId, pageInternalId) =>
    dispatch(
      BASKET_TOGGLE_CHECK_SINGLE({
        reportId,
        sectionId,
        internalId: pageInternalId,
      }),
    );

  return <TableOfContents pageCounter={pageCounter} basket={withCounterBasket} _onPageClick={_onPageClick} />;
};

export default TableOfContentsContainer;
