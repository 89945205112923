import { byBuildingAndFloorTypes } from 'modules/spaceLook/constants';

const toBuildingAndFloorGraphData = ({ data, type }) => {
  const res = {
    labels: [],
    datasets: [
      {
        label: 'Avg Occupancy',
        backgroundColor: '#34BBDB',
        data: [],
      },
      {
        label: 'Max Occupancy',
        backgroundColor: '#0D6F85',
        data: [],
      },
      {
        label: 'Avg Utilization',
        backgroundColor: '#F6A000',
        data: [],
      },
      {
        label: 'Max Utilization',
        backgroundColor: '#B4CC04',
        data: [],
      },
    ],
  };

  data.forEach((item) => {
    const namePrefix = type === byBuildingAndFloorTypes.floor ? 'Floor' : '';

    res.labels.push(`${namePrefix} ${item.name}`);
    res.datasets[0].data.push(item.percent_avg_occupancy);
    res.datasets[1].data.push(item.percent_max_occupancy);
    res.datasets[2].data.push(item.percent_avg_utilization);
    res.datasets[3].data.push(item.percent_max_utilization);
  });

  return res;
};

export default toBuildingAndFloorGraphData;
