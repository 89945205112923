import React from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox } from 'components';

import { SET_BENCHMARKING_FILTER_OPTION } from 'modules/spaceLook/actions';

import theme from './BenchmarkingFilterList.module.scss';

const BenchmarkingFilterList = ({ title, options, selectedFilters }) => {
  const dispatch = useDispatch();

  return (
    <div className={theme.filterSection}>
      <h3 className={theme.header}>{title}</h3>
      <div className={theme.list}>
        {options.map((filterOption) => (
          <Checkbox
            key={filterOption.id}
            className={theme.item}
            label={filterOption.name}
            isChecked={selectedFilters.includes(filterOption.id)}
            onClick={() => dispatch(SET_BENCHMARKING_FILTER_OPTION(filterOption.id))}
          />
        ))}
      </div>
    </div>
  );
};

export default BenchmarkingFilterList;
