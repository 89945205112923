import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import { getPdfMode } from 'modules/spaceLook/selectors';

import theme from './GraphHeader.module.scss';

const GraphHeader = ({ label }) => {
  const pdfMode = useSelector(getPdfMode);

  return (
    <Typography variant={pdfMode ? 'subtitle2' : 'h5'} className={cn(theme.header, { [`${theme.pdf}`]: pdfMode })}>
      <Box fontWeight="fontWeightMedium" component="span">
        {label}
      </Box>
    </Typography>
  );
};

GraphHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default GraphHeader;
