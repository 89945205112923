import React from 'react';

import { FixedDataTable } from 'modules/shared/components';
import { CellSkeleton } from 'modules/assetLook/components';

const TableSkeleton = ({ width, height }) => (
  <FixedDataTable
    width={width}
    height={height}
    rows={[1, 2, 3]}
    columns={[1, 2, 3, 4, 5, 6, 7]}
    ColumnHeader={CellSkeleton}
    ColumnCell={CellSkeleton}
    columnWidth={() => 200}
  />
);

export default TableSkeleton;
