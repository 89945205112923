import React from 'react';
import PropTypes from 'prop-types';

import theme from './GraphYScale.module.scss';

const PDF_MIN_WIDTH = '55px';

const GraphYScale = ({ isSwitcherOn, values, marginBottom, minWidth, pdf }) => (
  <div className={theme.container} style={{ minWidth: pdf ? PDF_MIN_WIDTH : minWidth }}>
    <div className={theme.scaleWrapper}>
      {values.map((value) => (
        <div className={pdf ? theme.scalePdfItem : theme.scaleItem} key={value}>
          {isSwitcherOn ? value : `${value}%`}
        </div>
      ))}
    </div>
    <div style={{ height: `${marginBottom}` }} />
  </div>
);

GraphYScale.defaultProps = {
  minWidth: '80px',
  isSwitcherOn: false,
};

GraphYScale.propTypes = {
  isSwitcherOn: PropTypes.bool,
  minWidth: PropTypes.string,
  marginBottom: PropTypes.string.isRequired,
};

export default GraphYScale;
