import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/spaceLook/components';
import { ReportDataCards } from 'modules/liveLook/components';

import { pageTypes, pdfCases, sectionsOrder, sectionItemOrder } from 'modules/liveLook/constants';
import { TextEditor } from 'modules/shared/components';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';

const databoxesToEditorData = (databoxesSet) => {
  let order = 0;
  const output = [];

  const pushEditorData = (data, prefix) => {
    const keys = Object.keys(data);
    const values = Object.values(data);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = values[i];

      output.push({
        name: prefix + key,
        action: 'min',
        color: '#FFC0CB',
        sub_title: null,
        type_of_group: null,
        group_by_name: null,
        order: ++order,
        is_display_legend: true,
        data: value,
        output_type: 'single_value',
      });
    }
  };

  if (typeof databoxesSet?.occupancyDataboxes === 'object') {
    pushEditorData(databoxesSet.occupancyDataboxes, 'occ_');
  }
  if (typeof databoxesSet?.deskDataboxes === 'object') {
    pushEditorData(databoxesSet.deskDataboxes, 'desk_');
  }
  if (typeof databoxesSet?.breakDataboxes === 'object') {
    pushEditorData(databoxesSet.breakDataboxes, 'break_');
  }
  if (typeof databoxesSet?.meetsDataboxes === 'object') {
    pushEditorData(databoxesSet.meetsDataboxes, 'meet_');
  }

  return output;
};

const SummaryOverallOccupancy = ({ databoxes, databoxesSet, editableTextReport, saveGraphsText }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Overall Occupancy" text="Executive Summary" />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3} align="right">
        <AddToPDFCard
          type="Summary Overall Occupancy"
          sectionType="Executive Summary"
          category={pdfCases.overallOcuppancy}
          sectionOrder={sectionsOrder.first}
          typeOrder={sectionItemOrder.seventh}
          // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
          chartName={liveLookPagesNames.summaryKeyFindings}
          data={{
            databoxes,
            editableTextReport,
            databoxesSet,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ReportDataCards databoxes={databoxes} pageType={pageTypes.summary} />
      </Grid>

      <Grid item xs={12}>
        <TextEditor
          data={databoxesToEditorData(databoxesSet)}
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
        />
      </Grid>
    </Grid>
  );
};

export default SummaryOverallOccupancy;
