const config = {
  legend: [
    {
      iconType: 'round',
      label: '1 Person',
      color: '#a2559d',
    },
    {
      iconType: 'round',
      label: '2-4 People',
      color: '#00aad2',
    },
    {
      iconType: 'round',
      label: '5-8 People',
      color: '#f6a000',
    },
    {
      iconType: 'round',
      label: '>9 People',
      color: '#b4cc04',
    },
  ],
};

export default config;
