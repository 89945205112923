/* eslint-disable camelcase */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Grid, Box, Button, makeStyles, ThemeProvider } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ENDPOINTS } from 'modules/shared/constants';
import { getUser } from 'modules/auth/selectors';
import { API, isAdmin, isClient, isPartner, isViewOnly } from 'modules/shared/utils';
import { PageHeader } from 'modules/shared/components';
import { FilterList, PageSelector, Counter, AddToPDFCard } from 'modules/workLook/components';
import { useCreateGraphClick } from 'modules/workLook/hooks';
import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { SET_PAGE_DATA, SET_REPORT_ID, SET_SECTION_ID } from 'modules/workLook/actions';

import { editTheme } from 'modules/modalsSystem/components/modalsWL/options';
import { SECTION_PAGE_TYPES } from 'modules/workLook/constants';
import { GraphsPagePattern, SummaryPagePattern } from './components';

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 16px',
    textTransform: 'capitalize',
    borderRadius: '20px',
    marginRight: '10px',
  },
}));

const SectionPage = ({ title, pages, activePage, initFilters, activeFilters, icon, isPublished }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(getUser);
  const { sectionId, typeId, reportId } = useParams();
  const url = ENDPOINTS.workLook.crudPage.replace(':pageId', activePage.id);
  const deleteDisable = Object.values(pages).length === 1;
  const isAdminRole = isAdmin(user?.role) || isPartner(user?.role);
  const isClientRole = isClient(user?.role);

  const { people_look_editing_rights } = user;
  const hasViewRights = isViewOnly(people_look_editing_rights);
  const clientRights = isClientRole && !hasViewRights && !isPublished;

  const classes = useStyles();
  const { onCreateGraphClick } = useCreateGraphClick(false, activePage.id);
  const onEditPageClick = () => {
    API.get(url).then(({ data }) => {
      dispatch(SET_REPORT_ID(reportId));
      dispatch(SET_SECTION_ID(sectionId));
      dispatch(SET_PAGE_DATA({ data, action: 'edit', typeId, pageId: activePage.id, couldDelete: deleteDisable }));
      dispatch(showModal(modalCodes.WL_CREATE_PAGE_MODAL));
    });
  };

  return (
    <Grid container spacing={4} direction="column" wrap="nowrap" style={{ height: 'calc(100% + 32px)' }}>
      <Grid item>
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PageHeader
                  hasViewRights={clientRights}
                  isPublished={isPublished}
                  title={title}
                  section={sectionId}
                  isAdmin={isAdminRole}
                  typeId={typeId}
                  report={reportId}
                />
              </Grid>
              <Grid item xs={12}>
                <PageSelector isPublished={isPublished} pages={pages} onEditPageClick={onEditPageClick} />
              </Grid>
              <If condition={initFilters.length}>
                <Grid item xs={12}>
                  <FilterList isPeopleLook initFilters={initFilters} activeFilters={activeFilters} />
                </Grid>
              </If>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Counter icon={icon} />
              </Grid>
              <Grid item xs={12}>
                <If condition={activePage.type !== SECTION_PAGE_TYPES.dashboard}>
                  <AddToPDFCard />
                </If>
              </Grid>
              <If condition={(isAdminRole && !isPublished) || clientRights}>
                <Grid item xs={12} align="right">
                  <Box mt={2}>
                    <ThemeProvider theme={editTheme}>
                      <Button
                        variant="contained"
                        color="primary"
                        classes={{ root: classes.root }}
                        onClick={() => onCreateGraphClick()}
                        children="Create Graph"
                      />
                    </ThemeProvider>
                  </Box>
                </Grid>
              </If>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ flex: '1 1 1px' }}>
        <Choose>
          <When condition={activePage.type === SECTION_PAGE_TYPES.summary}>
            <SummaryPagePattern page={activePage} />
          </When>
          <When condition={[SECTION_PAGE_TYPES.graphs, SECTION_PAGE_TYPES.dashboard].includes(activePage.type)}>
            <GraphsPagePattern
              isPublished={isPublished}
              page={activePage}
              isDashboard={activePage.type === SECTION_PAGE_TYPES.dashboard}
            />
          </When>
        </Choose>
      </Grid>
    </Grid>
  );
};

export default SectionPage;
