import axios from 'axios';

import { isLogged } from 'utils';
import { ENDPOINTS } from 'modules/shared/constants';

const uploadAppliedFilters = (id, parameters) => {
  const token = isLogged();
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  axios.put(ENDPOINTS.appliedFilters, { url: id, parameters }, config);
};

export default uploadAppliedFilters;
