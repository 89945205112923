/* eslint-disable camelcase */
import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { Grid, Paper } from '@material-ui/core';

import { Card, TextPlaceholder } from 'modules/shared/components';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import theme from './DataCards.module.scss';

const DataCardsGrid = ({
  isLoading,
  data,
  graphId,
  title,
  sub_title,
  isPdf,
  typeOfGroup,
  isModal,
  showTitle,
  type,
  isPublished,
}) => {
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && !data}>
        <Paper className={theme.container} elevation={2}>
          <GraphHeader
            isPublished={isPublished}
            showTitle={showTitle}
            title={title}
            subTitle={sub_title}
            graphId={graphId}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <GraphHeader
          graphId={graphId}
          isPdf={isPdf}
          onClick={onEditClick}
          onModifierClick={onModifierClick}
          isPublished={isPublished}
        />
        <Grid container spacing={2} style={{ justifyContent: 'center' }}>
          {data &&
            // eslint-disable-next-line no-shadow
            data.map(({ color, name, sub_title, data }, i) => (
              <If condition={i < 6}>
                <Grid item key={i}>
                  <Card title={name} subTitle={sub_title} data={data} subData=" " color={color} />
                </Grid>
              </If>
            ))}
        </Grid>
      </Otherwise>
    </Choose>
  );
};

export default DataCardsGrid;
