import { createTheme } from '@material-ui/core';

export const btnsTheme = createTheme({
  palette: {
    secondary: {
      main: '#ff0000',
      contrastText: '#ff0000',
    },
    primary: {
      main: '#005aba',
      contrastText: '#005aba',
    },
  },
});

export const editTheme = createTheme({
  palette: {
    primary: {
      main: '#0081B4',
      contrastText: '#fff',
    },
  },
});
