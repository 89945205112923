const SUM = 'sum';
const AVG = 'avg';
const MIN = 'min';
const MAX = 'max';
const COUNT = 'count';
const SUM_OF_EACH = 'all_sum';
const AVG_OF_EACH = 'all_avg';
const MIN_OF_EACH = 'all_min';
const MAX_OF_EACH = 'all_max';
const COUNT_UNIQUE = 'd_count';
const COUNT_UNIQUE_PERCENT = 'd_pers';
const PRESENT_DATA = 'present_data';

const SERIES_ACTIONS = {
  sum: SUM,
  avg: AVG,
  min: MIN,
  max: MAX,
  count: COUNT,
  sumOfEach: SUM_OF_EACH,
  avgOfEach: AVG_OF_EACH,
  minOfEach: MIN_OF_EACH,
  maxOfEach: MAX_OF_EACH,
  countUnique: COUNT_UNIQUE,
  countUniquePercent: COUNT_UNIQUE_PERCENT,
  presentData: PRESENT_DATA,
};

const SERIES_ACTION_GROUP = {
  singleValue: [SUM, AVG, MIN, MAX, COUNT],
  multipleValue: [SUM_OF_EACH, AVG_OF_EACH, MIN_OF_EACH, MAX_OF_EACH],
  counter: [COUNT_UNIQUE, COUNT_UNIQUE_PERCENT],
  display: [PRESENT_DATA],
};

export { SERIES_ACTIONS, SERIES_ACTION_GROUP };
