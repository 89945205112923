import { isEmpty } from 'lodash';

export const errorsText = {
  float: '* Should be a float number',
  text: '* Should be a text',
  int: '* Should be an integer',
};

export const dateTypes = {
  int: 'text',
  float: 'text',
  text: 'text',
  datetime: 'datetime-local',
  date: 'date',
  time: 'time',
  file: 'file',
};

export const fieldValidations = {
  int: (setErrors, name, value, fieldType) => {
    if (!Number(value) || value.includes('.')) {
      setErrors((state) => ({ ...state, [name]: errorsText[fieldType] }));
    }
  },
  float: (setErrors, name, value, fieldType) => {
    if (!Number(value)) {
      setErrors((state) => ({ ...state, [name]: errorsText[fieldType] }));
    }
  },
  text: () => {},
  datetime: () => {},
  date: () => {},
  time: () => {},
  file: () => {},
};

export const setHeaders = (data) => {
  if (isEmpty(data)) return [];
  return Object.keys(data[0]);
};

export const getFilteredHeaders = (array) => array.reduce((acc, name) => ({ ...acc, [name]: '' }), {});
export const getFieldsErrors = (array) => array.reduce((acc, name) => ({ ...acc, [name]: '' }), {});

export const getValue = (type, value) => {
  return type === 'datetime' ? value.replace('Z', '') : value;
};

export const isDateTime = (type) => {
  return type === 'datetime';
};

export const cutString = (string) => {
  return string?.slice(0, 40);
};
