// Replace "property_id" in data with "property_name" from map.
// If there is no value in map use original "property_id"
// Data structure remains the same

const updatePropertyIdByMap = (data, map) =>
  data.map((dataItem) => {
    const valueInMap = map.find((mapItem) => mapItem.property_id === dataItem.property_id);

    return {
      ...dataItem,
      property_name: (valueInMap && valueInMap.property_name) || dataItem.property_id,
    };
  });

export default updatePropertyIdByMap;
