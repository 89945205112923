/* eslint-disable */
import React from 'react';

const excludedKeys = ['latitude', 'longitude', 'name'];

const setupMapData = (buildingsData, mocked) => {
  const dataForMap = buildingsData.reduce((allObjects, buildingsObject, idx) => {
    if (!buildingsObject.location_data) return allObjects;
    const updatedObject = Object.keys(buildingsObject.location_data).reduce((result, current) => {
      if (!result.Building) result.Building = buildingsData[idx].property_name;
      const key = mocked[current] ? mocked[current] : current;
      result[key] = buildingsObject.location_data[current];
      return result;
    }, {});
    updatedObject.Capacity = buildingsObject.nominal_capacity;
    updatedObject['Spare capacity'] = buildingsObject.calc_data.current_spare_capacity;
    updatedObject.property_id = buildingsObject.property_id;
    allObjects.push(updatedObject);
    return allObjects;
  }, []);

  return dataForMap.map((onePoint) => {
    const coords = [onePoint.latitude, onePoint.longitude];
    const building = onePoint.Building;
    const info = Object.keys(onePoint).reduce((result, current) => {
      if (excludedKeys.includes(current)) return result;
      const value = onePoint[current] !== null ? onePoint[current] : 'N/A';
      const listItem = (
        <li key={current} className="buildings-map-tooltip-content-item">
          <span className="name">{current}:</span>
          <span className="value">{value}</span>
        </li>
      );
      result.push(listItem);
      return result;
    }, []);

    return {
      coords,
      building,
      info,
      property_id: onePoint.property_id,
    };
  });
};

export default setupMapData;
