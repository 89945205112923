/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography, Button, TextField, ThemeProvider, Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { URLS } from 'pages/constants';
import { getEditGraphId } from 'modules/workLook/selectors';
import { API } from 'modules/shared/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import isLogged from 'utils/isLogged';
import axios from 'axios';
import { ENDPOINTS } from 'modules/shared/constants';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import theme from './CreateNewSectionContainer.module.scss';
import { btnsTheme } from '../../options';

const CreateNewSectionModalContainer = React.forwardRef(({ onClose }) => {
  const { reportId, sectionData, sectionAction, sectionId, typeId } = useSelector(getEditGraphId);
  const [displayAction, setDisplayAction] = useState(false);
  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });
  const { description, order, report, sub_title, title, title_alias } = sectionData || {};
  const isEditMode = sectionAction === 'edit';

  const initialCreateValues = {
    report: reportId,
    title: '',
    title_alias: '',
    sub_title: '',
    order: '',
    description: '',
    initial_page_name: '',
  };

  const initialEditValues = {
    report,
    title,
    title_alias: !title_alias ? '' : title_alias,
    sub_title: !sub_title ? '' : sub_title,
    order: !order ? '' : order,
    description: !description ? '' : description,
  };

  const validationCreateSchema = Yup.object().shape({
    title: Yup.string().required('*Title required'),
    order: Yup.string().required('*Order required'),
    initial_page_name: Yup.string().required('*Initial page name required'),
  });

  const validationEditSchema = Yup.object().shape({
    title: Yup.string().required('*Title required'),
    order: Yup.string().required('*Order required'),
  });

  const formik = useFormik({
    initialValues: isEditMode ? initialEditValues : initialCreateValues,
    validationSchema: isEditMode ? validationEditSchema : validationCreateSchema,
    onSubmit: (values) => {
      const result = JSON.stringify(values, (k, val) => (val === '' ? null : val));
      const url = ENDPOINTS.workLook.createSection;
      const urlEditSection = ENDPOINTS.workLook.crudSection.replace(':sectionId', sectionId);
      const config = {
        method: isEditMode ? 'put' : 'post',
        url: isEditMode ? urlEditSection : url,
        headers: {
          Authorization: `Token ${isLogged()}`,
          'Content-Type': 'application/json',
        },
        data: result,
      };
      axios(config)
        .then(() => {
          onClose();
          window.location.reload(false);
        })
        .catch((error) => {
          setErrors({ isError: true, errorMsg: error?.response?.data?.non_field_errors });
        });
    },
  });

  const onDeleteSection = () => {
    const urlDelete = ENDPOINTS.workLook.crudSection.replace(':sectionId', sectionId);

    API.delete(urlDelete).then(() => {
      window.location.replace(URLS.workLook.title.replace(':typeId', typeId).replace(':reportId', reportId));
    });
  };

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h5" className={theme.title}>
                {isEditMode ? 'Edit Section' : 'Create Section'}
              </Typography>
            </Grid>
            <If condition={isEditMode && !displayAction}>
              <Grid item>
                <DeleteIcon onClick={() => setDisplayAction(true)} color="error" fontSize="medium" />
              </Grid>
            </If>
          </Grid>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={theme.formWrapper}>
          <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
            {errors.isError &&
              errors.errorMsg.map((msg) => (
                <Typography variant="body2" className={theme.error}>
                  {msg}
                </Typography>
              ))}
            <div>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Title"
                placeholder="Enter section title"
                value={formik.values.title}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.title}
              </Typography>
            </div>
            <TextField
              id="title_alias"
              name="title_alias"
              label="Title Alias"
              placeholder="Enter section title alias"
              value={formik.values.title_alias}
              onChange={formik.handleChange}
              variant="outlined"
            />
            <TextField
              id="sub_title"
              name="sub_title"
              label="Subtitle"
              placeholder="Enter section subtitle"
              value={formik.values.sub_title}
              onChange={formik.handleChange}
              variant="outlined"
            />
            <TextField
              fullWidth
              id="description"
              name="description"
              label="Description"
              placeholder="Enter description"
              value={formik.values.description}
              onChange={formik.handleChange}
              variant="outlined"
              multiline
              maxRows={3}
              minRows={2}
            />
            <div>
              <TextField
                fullWidth
                className={theme.input}
                type="number"
                id="order"
                name="order"
                label="Order"
                placeholder="Enter an order"
                value={formik.values.order}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.order}
              </Typography>
            </div>
            <If condition={!isEditMode}>
              <TextField
                fullWidth
                id="initial_page_name"
                name="initial_page_name"
                label="Initial Page Name"
                placeholder="Enter initial page name"
                value={formik.values.initial_page_name}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.initial_page_name}
              </Typography>
            </If>
            <If condition={isEditMode && displayAction}>
              <div className={theme.btnsWrapper}>
                <ThemeProvider theme={btnsTheme}>
                  <Button color="inherit" variant="outlined" onClick={() => setDisplayAction(false)}>
                    Cancel
                  </Button>
                  <Button color="secondary" variant="outlined" onClick={() => onDeleteSection()}>
                    Delete
                  </Button>
                </ThemeProvider>
              </div>
            </If>
            <If condition={!displayAction}>
              <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                Submit
              </Button>
            </If>
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateNewSectionModalContainer;
