const FILTERS = {
  building_id: 'Buildings',
  floor: 'Floors',
  subtype: 'Subtypes',
  division: 'Division',
  neighbourhood: 'Neighbourhood',
  weekday: 'Weekday',
};

export default FILTERS;
