import * as Yup from 'yup';

import { VALIDATION_RULES } from 'modules/auth/constants';

const config = {
  initialValues: {
    email: '',
    password: '',
  },

  validate: ({ email }) => {
    const errors = {};

    const isValidMail = VALIDATION_RULES.email.test(String(email).toLowerCase());

    if (!email.length) {
      errors.email = '*Required';
    } else if (!isValidMail) {
      errors.email = '*Incorrect email';
    }

    return errors;
  },

  validationSchema: Yup.object().shape({
    password: Yup.string().min(8, 'Must be at least 8 characters').required('*Required'),
  }),
};

export default config;
