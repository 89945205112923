const getHeatmapData = ({ report }) => ({
  dataRequestStatuses: {
    databoxes: report.desks.requestStatuses.databoxes,
    heatmap: report.heatmap.requestStatus,
  },
  data: {
    databoxes: report.desks.data.databoxes,
    heatmapData: report.heatmap.heatmapData,
  },
  shouldRefetchData: {
    databoxes: report.desks.shouldRefetchData.databoxes,
  },
});

export default getHeatmapData;
