/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { dateToQuery, filtersToQuery, toDeskUsageOverTimeGraphData } from 'modules/liveLook/utils';
import {
  fetchDeskOverTime,
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
  SET_GRAPH_SWITCHER,
  fetchDeskDataboxes,
  fetchGraphsText,
  saveGraphsText,
  fetchDeskVariables,
} from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import { GRAPH_SCALES } from 'modules/liveLook/constants';
import setScaleOption, { daysDifference, isInPercentages } from '../utils/common';
import { setDataGroupedByDays } from '../utils/setDataForOverTime';
import { setScaleValues, toLondonTimeZone } from '../utils';
import DesksOverTime from './DesksOverTime';
import reducer, { initialState } from './_reducer';

function DesksOverTimeContainer() {
  const [companyId] = useAuthSelector(['currentCompany']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    scale,
    value,
    deskDataboxes,
    databoxRequestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
    deskVariableRequestStatus,
    textVariables,
  } = state;

  const requestStatuses = [databoxRequestStatus, requestStatus, textReportRequestStatus, deskVariableRequestStatus];

  const [startDate, endDate, workingHours, activeDeskFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'deskFilters.activeDeskFilters',
    'common.archive',
  ]);

  const daysDiffernce = useMemo(() => daysDifference(endDate, startDate), [endDate, startDate]);
  const scaleOption = useMemo(() => setScaleOption(scale, daysDiffernce), [scale, daysDiffernce]);
  const query = filtersToQuery(activeDeskFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);
  const getScaleLabel = isEmpty(scaleOption) ? scale : scaleOption === 'hour' ? GRAPH_SCALES.hour : scaleOption;
  useEffect(() => {
    let isCanceled = false;

    fetchDeskOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      scaleOption,
      query,
      archive,
    });

    fetchDeskDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      scaleOption,
      query,
      archive,
    });

    fetchDeskVariables(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'desk_time',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, dateAfter, dateBefore, workingHours, scaleOption, query]);

  const onScaleChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_SCALE(value)), []);
  const onValueChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_VALUE(value)), []);
  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  const databoxesSet = {
    deskDataboxes,
  };

  const dataGroupedByDays = setDataGroupedByDays(data, scale, toLondonTimeZone);

  const peakOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        deskDataboxes,
        deskDataboxes?.max_desk_occupancy,
        deskDataboxes?.percentage_max_desk_occupancy,
      ),
    [returnPercentageSwitcher, deskDataboxes],
  );

  const avgOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        deskDataboxes,
        deskDataboxes?.avg_desk_occupancy,
        deskDataboxes?.percentage_avg_desk_occupancy,
      ),
    [returnPercentageSwitcher, deskDataboxes],
  );

  const { niceMax, scaleValues } = setScaleValues(peakOccupancy);

  const linesData = {
    peakOccupancy,
    avgOccupancy,
  };

  const chunk = 20; // items per chunk
  const dateArrays = dataGroupedByDays;

  const result = dateArrays
    ? dateArrays.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunk);

        if (!resultArray[chunkIndex]) {
          // eslint-disable-next-line no-param-reassign
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, [])
    : [];

  const graphDataToBuild = result.map((graph) => {
    return toDeskUsageOverTimeGraphData(
      {
        sourceData: graph,
        valueType: value,
        scale: getScaleLabel,
        returnPercentageSwitcher,
        niceMax,
      },
      false,
    );
  });

  const graphData = toDeskUsageOverTimeGraphData(
    {
      sourceData: dataGroupedByDays,
      valueType: value,
      scale: getScaleLabel,
      returnPercentageSwitcher,
      niceMax,
    },
    false,
  );

  return (
    <Choose>
      <When condition={requestStatuses.some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={requestStatuses.every((item) => isSuccess(item))}>
        <DesksOverTime
          {...state}
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'desk_time', text });
          }}
          scale={getScaleLabel}
          daysDiffernce={daysDiffernce}
          editableTextReport={editableTextReport}
          onScaleChange={onScaleChange}
          onValueChange={onValueChange}
          onSwitcherChange={onSwitcherChange}
          scaleValues={scaleValues}
          graphData={graphData}
          graphDataToBuild={graphDataToBuild}
          databoxes={deskDataboxes}
          linesData={linesData}
          databoxesSet={databoxesSet}
          data={data}
          niceMax={niceMax}
          returnPercentageSwitcher={returnPercentageSwitcher}
          textVariables={textVariables}
          customHeader
          archive={archive}
        />
      </When>
      <When condition={requestStatuses.some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default DesksOverTimeContainer;
