import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortPdfReports } from 'modules/liveLook/utils';
import { getReports } from 'modules/liveLook/selectors';
import { BASKET_TOGGLE_CHECK_SINGLE } from 'modules/liveLook/actions';
import TableOfContents from './TableOfContents';

const TableOfContentsContainer = () => {
  const dispatch = useDispatch();
  // const {reportId} = useParams();
  const { reportData } = useSelector(getReports);
  const sortedReportData = sortPdfReports(reportData);

  const data = Object.values(
    sortedReportData.reduce((acc, item, index) => {
      if (!acc[item.sectionType]) {
        acc[item.sectionType] = {
          sectionType: item.sectionType,
          pages: [],
        };
      }

      acc[item.sectionType].pages.push({
        ...item,
        pageNumber: index + 1,
      });
      return acc;
    }, {}),
  );

  const _onPageClick = (reportId) => () => dispatch(BASKET_TOGGLE_CHECK_SINGLE({ reportId }));

  return <TableOfContents pageCounter={data?.length || 1} data={data} _onPageClick={_onPageClick} />;
};

export default TableOfContentsContainer;
