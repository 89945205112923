/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal } from '@material-ui/core';

import { APPLY_FILTERS } from 'modules/assetLook/actions';
import { useAssetLookSelector } from 'modules/assetLook/hooks';

// eslint-disable-next-line import/no-cycle
import { FilterModalBase } from 'modules/modalsSystem/components';
import { toRequestBody } from 'modules/assetLook/utils';
import { isEmpty, includes, filter } from 'lodash';

const FilterModal = ({ filterField, onClose, initialFilters, activeFinalFilters, ...props }) => {
  const dispatch = useDispatch();
  const [initFilters, activeFilters, modifiedFilters, activeTargetFilters] = useAssetLookSelector([
    'filters.initFilters',
    'filters.activeFilters',
    'filters.modifiedFilters',
    'filters.activeTargetFilters',
  ]);

  const getActiveFilters = toRequestBody(activeFilters, activeTargetFilters);
  const isFiltersEmpty = isEmpty(getActiveFilters);
  const getInitialFilters = isFiltersEmpty ? initialFilters : modifiedFilters;
  const initValues = (getInitialFilters || initFilters).find(({ field }) => field === filterField).values;
  const activeValues = (activeFinalFilters || activeFilters).find(({ field }) => field === filterField).values;
  const result = Object.keys(initValues).map((key) => ({ key, value: initValues[key] }));

  const [selectedValues, setSelectedValues] = useState(activeValues);
  const isAllSelected = result.every(({ key }) => {
    return includes(selectedValues, key);
  });

  const onSelectAllClick = () => {
    return setSelectedValues(isAllSelected ? [] : Object.keys(initValues));
  };

  const onValueClick = (value) => {
    setSelectedValues(
      includes(selectedValues, value) ? filter(selectedValues, (item) => item !== value) : [...selectedValues, value],
    );
  };

  const onApplyClick = () => {
    const data = {
      field: filterField,
      values: selectedValues,
    };

    props.onApply ? props.onApply(data) : dispatch(APPLY_FILTERS(data));
    onClose();
  };

  return (
    <Modal open onClose={onClose}>
      <FilterModalBase
        isAllSelected={isAllSelected}
        onSelectAllClick={onSelectAllClick}
        title={filterField.charAt(0).toUpperCase() + filterField.slice(1)}
        onClose={onClose}
        initValues={result.map((item) => item).sort((a, b) => a.key.localeCompare(b.key))}
        isValueChecked={(value) => includes(selectedValues, value)}
        onValueClick={onValueClick}
        onApplyClick={onApplyClick}
        isAssetLook
      />
    </Modal>
  );
};

FilterModal.propTypes = {
  filterField: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FilterModal;
