import { format, utcToZonedTime } from 'date-fns-tz';

const toTimeZone = (res) => {
  return res.flatMap(({ date, ...other }) => {
    const itemDate = new Date(date);

    const itemDateLondon = utcToZonedTime(itemDate.toISOString());
    const itemLondonDay = format(itemDateLondon, 'dd MMM yyyy');
    const itemLondonTime = format(itemDateLondon, 'HH:mm');

    return [{ day: itemLondonDay, time: itemLondonTime, ...other }];
  });
};

export default toTimeZone;
