import React from 'react';
import { DesksCard } from 'components';

import './CardLines.scss';

const CardLines = ({ databoxes }) => (
  <section className="cardLines">
    {databoxes.map(({ title, cards }, i) => (
      <div key={i} className="cardLine">
        <h3 className="cardLineTitle">{title}</h3>
        {cards.map((card, idx) => (
          <DesksCard key={idx} {...card} />
        ))}
      </div>
    ))}
  </section>
);

export default CardLines;
