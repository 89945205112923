import React from 'react';
import { isEmpty } from 'lodash';

import Skeleton from '@material-ui/lab/Skeleton';

import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { isDefault, isLoading, isSuccess } from 'modules/shared/utils';

import { useDispatch } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import { ScheduleSend } from '@mui/icons-material';
import { showModal } from '../../../../../modalsSystem/actions';
import modalCodes from '../../../../../modalsSystem/constants/modalCodes';

const useStyles = makeStyles(() => ({
  label: {
    color: 'white',
  },
  root: {
    margin: '0 16px 0 0',
  },
}));

const ArrivalOrderUpload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [categoriesRequestStatus, categories] = useAssetLookSelector([
    'common.categoriesRequestStatus',
    'common.categories',
  ]);

  return (
    <Choose>
      <When condition={isDefault(categoriesRequestStatus) || isLoading(categoriesRequestStatus)}>
        <Skeleton width={274} height={32} />
      </When>
      <When condition={isSuccess(categoriesRequestStatus) && !isEmpty(categories.by_building)}>
        <Button
          classes={{ label: classes.label, root: classes.root }}
          variant="contained"
          size="medium"
          // eslint-disable-next-line react/no-children-prop
          children="Arrival Order Upload"
          color="primary"
          onClick={() => dispatch(showModal(modalCodes.AL_ARRIVAL_ORDER_UPLOAD))}
          startIcon={<ScheduleSend />}
        />
      </When>
      <Otherwise>{null}</Otherwise>
    </Choose>
  );
};

export default ArrivalOrderUpload;
