/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import { GROUP_TYPES, SERIES_ACTION_GROUP, SERIES_ACTIONS } from 'modules/workLook/constants';

const getPctValue = (value, sum) => {
  return ((value * 100) / sum).toFixed(1);
};

const handleGroupNoneDataStructure = (graphData, title) => {
  const convertedData = [];
  const getSumm = (action) => {
    if (SERIES_ACTION_GROUP.singleValue.includes(action))
      return graphData?.map(({ data }) => data).reduce((prev, next) => prev + next);

    if (SERIES_ACTION_GROUP.multipleValue.includes(action) || SERIES_ACTION_GROUP.counter.includes(action))
      return graphData
        ?.map(({ data }) => data?.map(({ value }) => value).reduce((prev, next) => prev + next))
        .reduce((prev, next) => prev + next);

    return graphData?.map(({ data }) => data).reduce((prev, next) => prev + next);
  };

  graphData?.forEach(({ name, data, action }) => {
    if (SERIES_ACTION_GROUP.singleValue.includes(action)) {
      convertedData.push(
        {
          name: `${name} - ${title} (int)`,
          data,
        },
        {
          name: `${name} - ${title} (%)`,
          data: getPctValue(data, getSumm(action)),
        },
      );
    }
    if (SERIES_ACTION_GROUP.multipleValue.includes(action)) {
      data?.map(({ field, value }) => {
        return convertedData.push(
          {
            name: `${name} ${field} - ${title} (int)`,
            data: value,
          },
          {
            name: `${name} ${field} - ${title} (%)`,
            data: getPctValue(value, getSumm(action)),
          },
        );
      });
    }
    if (SERIES_ACTION_GROUP.counter.includes(action)) {
      data?.map(({ field, value, _percentage }) => {
        return convertedData.push(
          {
            name: `${name} ${field} - ${title} (int)`,
            // data: action === SERIES_ACTIONS.COUNT_UNIQUE ? value : _percentage,
            data: value,
          },
          {
            name: `${name} ${field} - ${title} (%)`,
            data: action === SERIES_ACTIONS.countUnique ? getPctValue(value, getSumm(action)) : _percentage,
          },
        );
      });
    }
  });

  return convertedData;
};

const handleGroupByFieldDataStructure = (graphData, title) => {
  const convertedData = [];

  graphData?.forEach(({ name, data, action }) => {
    if (SERIES_ACTION_GROUP.singleValue.includes(action)) {
      data?.map(({ field, value }) => {
        return convertedData.push({
          name: `${name} ${field} - ${title}`,
          data: value,
        });
      });
    }
    if (SERIES_ACTION_GROUP.multipleValue.includes(action)) {
      data?.map(({ fields, group_by_field }) => {
        return fields?.map(({ field, value }) => {
          return convertedData.push({
            name: `${name} ${group_by_field} ${field} - ${title}`,
            data: value,
          });
        });
      });
    }
    if (SERIES_ACTION_GROUP.counter.includes(action)) {
      data?.map(({ fields, group_by_field }) => {
        return fields?.map(({ field, value, _percentage }) => {
          return convertedData.push({
            name: `${name} ${group_by_field} ${field} - ${title}`,
            data: action === SERIES_ACTIONS.COUNT_UNIQUE ? value : _percentage,
          });
        });
      });
    }
  });

  return convertedData;
};

const handleGroupByNameDataStructure = (graphData, title) => {
  const convertedData = [];

  graphData?.forEach(({ group_name, series_data }) => {
    series_data?.map(({ name, data }) => {
      return convertedData.push({
        name: `${group_name} ${name} - ${title}`,
        data,
      });
    });
  });
  return convertedData;
};

const toEditableTextData = (data, groupType, title) => {
  if (!data || !data.length) return [];

  switch (groupType) {
    case null:
      return handleGroupNoneDataStructure(data, title);
    case GROUP_TYPES.byField:
      return handleGroupByFieldDataStructure(data, title);
    case GROUP_TYPES.byName:
      return handleGroupByNameDataStructure(data, title);
    default:
      return null;
  }
};

export default toEditableTextData;
