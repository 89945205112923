import { pickBy, identity } from 'lodash';

const makeSearch = (data) => {
  const sanitizedData = pickBy(data, identity);

  const searchQuery = new URLSearchParams(sanitizedData);

  return searchQuery.toString() ? `?${searchQuery.toString()}` : '';
};

export default makeSearch;
