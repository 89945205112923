import React from 'react';
import { Box, Typography } from '@material-ui/core';

import PageRow from './PageRow';

import theme from './TableOfContents.module.scss';

const TableOfContents = ({ data, _onPageClick }) =>
  data.map((item, index) => (
    <div key={index} className={theme.sectionWrapper}>
      <Typography variant="h5" className={theme.sectionName}>
        <Box fontWeight="fontWeightMedium" component="span">
          {item.sectionType}
        </Box>
      </Typography>
      {item.pages.map((page) => (
        <PageRow _onPageClick={_onPageClick} pageCounter={page.pageNumber} {...page} />
      ))}
    </div>
  ));

export default TableOfContents;
