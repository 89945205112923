import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isDefault } from 'modules/shared/utils';
import { requestStatuses } from 'modules/shared/constants';
import { getAppliedFilters, getDesksAgilityData, getSelectedFilters, getPdfMode } from 'modules/spaceLook/selectors';
import { fetchDesksAgilityDataboxes, fetchDesksAgilityGraphData } from 'modules/spaceLook/actions';
import { LoadingCondition } from 'modules/spaceLook/components';

import { isEmpty } from 'lodash';
import DesksAgility from './DesksAgility';

const DesksAgilityContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fetchStart, setFetchStart] = useState(false);
  const pdfMode = useSelector(getPdfMode);
  const { dataRequestStatuses, data, shouldRefetchData } = useSelector(getDesksAgilityData);
  const { selectedFiltersUrlParams } = useSelector((store) => getSelectedFilters(store, 'desks'));
  const { appliedFiltersRequestStatus } = useSelector(getAppliedFilters);

  const isNoData = Object.values(data).some((dataItem) => isEmpty(dataItem));
  const isLoading = Object.values(dataRequestStatuses).some((status) => status === requestStatuses.pending);
  const isFiltersHistoryLoading = [requestStatuses.default, requestStatuses.pending].includes(
    appliedFiltersRequestStatus,
  );

  useEffect(() => {
    if (isFiltersHistoryLoading) return;

    if (isDefault(dataRequestStatuses.agilityDataboxes) || shouldRefetchData.agilityDataboxes) {
      fetchDesksAgilityDataboxes(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.agilityGraphData) || shouldRefetchData.agilityGraphData) {
      fetchDesksAgilityGraphData(dispatch, id, selectedFiltersUrlParams);
    }
    setFetchStart(true);
  }, [
    appliedFiltersRequestStatus,
    dataRequestStatuses.agilityDataboxes,
    dataRequestStatuses.agilityGraphData,
    dispatch,
    id,
    isFiltersHistoryLoading,
    selectedFiltersUrlParams,
    shouldRefetchData.agilityDataboxes,
    shouldRefetchData.agilityGraphData,
  ]);

  return (
    <LoadingCondition isLoading={isLoading} isNoData={isNoData} fetchStart={fetchStart}>
      <DesksAgility isLoading={isLoading} pdfMode={pdfMode} {...data} />
    </LoadingCondition>
  );
};

export default DesksAgilityContainer;
