import { isEmpty } from 'lodash';
import { DATA_VALUES, GRAPHSCALES } from './constants';

const getLabel = (timeSlot, scale) => {
  switch (scale) {
    case GRAPHSCALES.HOUR: {
      return timeSlot.time;
    }

    case GRAPHSCALES.HALF_DAY: {
      return timeSlot.time;
    }

    case GRAPHSCALES.DAY: {
      return timeSlot.day;
    }

    default: {
      return timeSlot.time;
    }
  }
};
const colors = [
  '#FF577F',
  '#F6A000',
  '#FFD384',
  '#FF74B1',
  '#FFA1CF',
  '#FFD6EC',
  '#256D85',
  '#47B5FF',
  '#FFCACA',
  '#C7F2A4',
  '#C689C6',
  '#FFABE1',
  '#FFD1D1',
];

const getTimeInMinutes = (time) => {
  const hours = parseInt(time.slice(0, 2), 10);
  const minutes = parseInt(time.slice(3), 10);
  return hours * 60 + minutes;
};

// eslint-disable-next-line no-unused-vars
const toGraphTimeData = ({
  sourceData,
  scale,
  returnPercentageSwitcher,
  niceMax,
  keys,
  totalAmountPerDay,
  peakValues,
  valueMethod,
}) => {
  if (isEmpty(sourceData)) return false;
  const dayLabels = [];
  const getValue = returnPercentageSwitcher ? DATA_VALUES.VALUE : DATA_VALUES.PERCENTAGES;
  const getValueMethod = valueMethod === 'average';
  const getKeys = isEmpty(peakValues) || getValueMethod ? keys : peakValues;
  const graphData = {
    labels: [],
    datasets: [],
  };
  getKeys.map((key) =>
    graphData.datasets.push({
      label: key,
      data: [],
      stack: 'standing costs',
    }),
  );
  graphData.datasets.push({
    label: 'Unoccupied',
    data: [],
    stack: 'standing costs',
    backgroundColor: '#f1f1f1',
  });

  for (let i = 0; i < getKeys.length; i++) {
    if (sourceData[0][1][0][getKeys[i]]?._color !== undefined) {
      graphData.datasets[i].backgroundColor = sourceData[0][1][0][getKeys[i]]?._color;
    } else {
      graphData.datasets[i].backgroundColor = colors[i];
    }
  }

  if (scale === GRAPHSCALES.HOUR) {
    sourceData.forEach(([dayLabel, dayData]) => {
      dayLabels.push({
        label: dayLabel,
        length: dayData.length,
      });

      dayData.forEach((timeSlot) => {
        const values = [];

        for (let i = 0; i < getKeys.length; i++) {
          values.push(timeSlot[keys[i]]?.[getValue] === undefined ? 0 : timeSlot[getKeys[i]]?.[getValue]);
        }
        const occupied = values.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);

        const unoccupied = niceMax - occupied.toFixed(0);

        const label = getLabel(timeSlot, scale);

        graphData.labels.push(label);

        for (let i = 0; i < getKeys.length; i++) {
          graphData.datasets[i].data.push(values[i]);
        }
        graphData.datasets[graphData.datasets.length - 1].data.push(parseFloat(unoccupied));
      });
    });
  }

  if (scale === GRAPHSCALES.HALF_DAY) {
    sourceData.forEach(([dayLabel, dayData]) => {
      dayLabels.push({
        label: dayLabel,
        length: 2,
      });

      const timeAM = dayData.filter((timeSlot) => getTimeInMinutes(timeSlot.time) < 720);

      const timePM = dayData.filter((timeSlot) => getTimeInMinutes(timeSlot.time) >= 720);

      const sumValuesAM = Array(dayData.length).fill(0);
      const sumValuesPM = Array(dayData.length).fill(0);

      const summary = Array(getKeys.length).fill(0);
      const summaryPM = Array(getKeys.length).fill(0);
      timeAM.forEach((timeSlot, index) => {
        for (let i = 0; i < getKeys.length; i++) {
          summary[i] +=
            timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE] === undefined ? 0 : timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE];
          sumValuesAM[index] +=
            timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE] === undefined ? 0 : timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE];
        }
      });
      timePM.forEach((timeSlot, index) => {
        for (let i = 0; i < getKeys.length; i++) {
          summaryPM[i] +=
            timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE] === undefined ? 0 : timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE];
          sumValuesPM[index] +=
            timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE] === undefined ? 0 : timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE];
        }
      });
      const getMaxValueIndexAM = sumValuesAM.indexOf(Math.max(...sumValuesAM) === 0 ? null : Math.max(...sumValuesAM));
      const getMaxValueIndexPM = sumValuesPM.indexOf(Math.max(...sumValuesPM) === 0 ? null : Math.max(...sumValuesPM));

      for (let i = 0; i < getKeys.length; i++) {
        graphData.datasets[i].data.push(
          returnPercentageSwitcher
            ? getValueMethod
              ? (summary[i] / timeAM.length).toFixed(1)
              : getMaxValueIndexAM === -1
              ? 0
              : dayData[getMaxValueIndexAM]?.[getKeys[i]]?.[DATA_VALUES.VALUE]
            : getValueMethod
            ? (((summary[i] / timeAM.length) * 100) / totalAmountPerDay).toFixed(1)
            : getMaxValueIndexAM === -1
            ? 0
            : (dayData[getMaxValueIndexAM]?.[getKeys[i]]?.[DATA_VALUES.VALUE] / totalAmountPerDay) * 100,
        );
        graphData.datasets[i].data.push(
          returnPercentageSwitcher
            ? getValueMethod
              ? (summaryPM[i] / timePM.length).toFixed(1)
              : getMaxValueIndexPM === -1
              ? 0
              : dayData[timeAM.length + getMaxValueIndexPM]?.[getKeys[i]]?.[DATA_VALUES.VALUE]
            : getValueMethod
            ? (((summaryPM[i] / timePM.length) * 100) / totalAmountPerDay).toFixed(1)
            : getMaxValueIndexPM === -1
            ? 0
            : (dayData[timeAM.length + getMaxValueIndexPM]?.[getKeys[i]]?.[DATA_VALUES.VALUE] / totalAmountPerDay) *
              100,
        );
      }

      graphData.labels.push('AM');
      graphData.labels.push('PM');
    });
  }

  if (scale === GRAPHSCALES.DAY) {
    sourceData.forEach(([dayLabel, dayData]) => {
      dayLabels.push({
        label: dayLabel,
        length: 1,
      });

      const summary = Array(getKeys.length).fill(0);
      const sumValues = Array(dayData.length).fill(0);

      dayData.forEach((timeSlot, index) => {
        for (let i = 0; i < getKeys.length; i++) {
          summary[i] +=
            timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE] === undefined ? 0 : timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE];
          sumValues[index] +=
            timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE] === undefined ? 0 : timeSlot[getKeys[i]]?.[DATA_VALUES.VALUE];
        }
      });

      const getMaxValueIndex = sumValues.indexOf(Math.max(...sumValues));
      for (let i = 0; i < getKeys.length; i++) {
        graphData.datasets[i].data.push(
          returnPercentageSwitcher
            ? getValueMethod
              ? (summary[i] / dayData.length).toFixed(1)
              : dayData[getMaxValueIndex]?.[getKeys[i]]?.[DATA_VALUES.VALUE]
            : getValueMethod
            ? (((summary[i] / dayData.length) * 100) / totalAmountPerDay).toFixed(1)
            : (dayData[getMaxValueIndex]?.[getKeys[i]]?.[DATA_VALUES.VALUE] / totalAmountPerDay) * 100,
        );
      }
      graphData.labels.push(dayLabel);
    });
  }

  return { data: graphData, dayLabels };
};

export default toGraphTimeData;
