import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';
import { PageHeader } from 'modules/shared/components';
import { ReportDataCards, StackedBarGraph, VerticalBarGraph } from 'modules/liveLook/components';
import { isEmpty } from 'lodash';

import { pageTypes } from 'modules/liveLook/constants';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';
import spaceUsageBySizeConfig from './spaceUsageBySizeConfig';
import renderFilters from '../../utils/activeFiltersPdf';
import config from './config';
import graphStyles from '../../styles/verticalGraph.module.scss';
import styles from '../../LLPrintablePdfPage.module.scss';

const SummaryKeyFindingsBreak = ({
  data,
  databoxes,
  scaleValues,
  editableTextReport,
  databoxesSet,
  spaceUsageBySizeData,
  returnPercentageSwitcher,
  activeFilters,
}) => {
  const editorRef = useRef();
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));

  return (
    <div className={cn(styles.pdfPage)}>
      <Grid style={{ paddingTop: '20px', paddingLeft: '30px', paddingRight: '10px' }} container spacing={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader pdfMode title="Executive Summary - Key Findings - Breakout" />
          </Grid>
        </Grid>
        {!shouldRenderFilters && renderFilters(activeFilters)}
        <Grid xs={6}>
          <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.meetings} />
          <Grid ref={editorRef} style={{ paddingTop: 10 }}>
            <PDFTextEditorContainer
              data={databoxesToEditorData(databoxesSet)}
              onSave={() => {}}
              text={editableTextReport}
              isKeyFindings
              hasFilters={shouldRenderFilters}
            />
          </Grid>
        </Grid>

        <Grid item xs={6} style={{ marginTop: -16 }}>
          <div className={cn(graphStyles.verticalGraph)}>
            <VerticalBarGraph
              title="Peak and Average Usage by Space Type"
              data={data}
              config={config}
              onSwitcherChange={() => {}}
              scaleValues={scaleValues}
              label="Integers"
              switcher={returnPercentageSwitcher}
              isSummaryPdf
              pdfCustomHeader
              returnPercentageSwitcher={returnPercentageSwitcher}
              shortSize
              isLiveLook
            />
          </div>
          <div className={cn(graphStyles.verticalGraph)}>
            <StackedBarGraph
              shortSize
              pdfCustomHeader
              title="Group Sizes by Space Type"
              data={spaceUsageBySizeData}
              config={spaceUsageBySizeConfig}
              databoxes={databoxes}
              isLiveLook
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SummaryKeyFindingsBreak;
