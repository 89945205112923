import { getBuildingOrFloorData } from 'modules/spaceLook/utils';
import { reportPageTypes } from 'modules/spaceLook/constants';

const BuildingAndFloorHighestUsage = ({ graphData, pageType }) => {
  const { highestValue, buildingOrFloor, nameKey } = getBuildingOrFloorData(graphData);
  const roomOrSpace = pageType === reportPageTypes.meetings ? 'room' : 'space';

  return `${buildingOrFloor.charAt(0).toUpperCase()}${buildingOrFloor.slice(1)} ${highestValue[nameKey]}
     had the highest ${roomOrSpace} usage with a peak of ${highestValue.percent_max}%
     and an average ${roomOrSpace} usage of ${highestValue.percent_avg}%.`;
};

export default BuildingAndFloorHighestUsage;
