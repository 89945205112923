import URLS from 'pages/constants/urls';

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';

import desk from 'assets/images/icons/desks.svg';
import meeting from 'assets/images/icons/humans.svg';
import coffee from 'assets/images/icons/coffee.svg';
import summary from 'assets/images/icons/graph.svg';
import report from 'assets/images/icons/report.svg';
import dashboard from 'assets/images/icons/dashboard.svg';

export const DASHBOARD = {
  label: 'Dashboard',
  Icon: dashboard,
  path: URLS.liveLook.dashboard,
  items: [{ text: 'Dashboard', path: URLS.liveLook.dashboard }],
};

export const ARCHIVE = {
  label: 'Archives',
  Icon: dashboard,
  path: URLS.liveLook.archive,
  items: [{ text: 'Archives', path: URLS.liveLook.archive }],
};

const config = [
  {
    label: 'Executive Summary',
    Icon: summary,
    items: [
      { text: 'Key Findings', path: URLS.liveLook.executiveSummaryKeyFindings },
      {
        text: 'Key Findings - Occupancy',
        path: URLS.liveLook.executiveSummaryKeyFindingsOccupancy,
      },
      {
        text: 'Key Findings - Desks',
        path: URLS.liveLook.executiveSummaryKeyFindingsDesks,
      },
      {
        text: 'Key Findings - Meeting',
        path: URLS.liveLook.executiveSummaryKeyFindingsMeet,
      },
      {
        text: 'Key Findings - Breakout',
        path: URLS.liveLook.executiveSummaryKeyFindingsBreak,
      },
      { text: 'Floor Plan', path: URLS.liveLook.executiveSummaryFloorPlan },
      // {text: 'Overall Occupancy', path: URLS.liveLook.executiveSummaryOverallOccupancy},
    ],
  },
  {
    label: 'Occupancy',
    Icon: MeetingRoomIcon,
    items: [
      { text: 'Occupancy Over Time', path: URLS.liveLook.doorsOverTime },
      { text: 'Daily Comparisons', path: URLS.liveLook.doorsDaily },
      { text: 'By Building and Floor', path: URLS.liveLook.doorsByBuilding },
      {
        text: 'Combined Occupancy',
        path: URLS.liveLook.combinedTeamZoneOccupancy,
      },
    ],
  },
  {
    label: 'Desks',
    Icon: desk,
    items: [
      { text: 'Usage Over Time', path: URLS.liveLook.desksOverTime },
      { text: 'Space Comparison', path: URLS.liveLook.desksSpaceComparison },
      { text: 'By Building and Floor', path: URLS.liveLook.desksByBuilding },
      { text: 'By Division', path: URLS.liveLook.desksByDivision },
      { text: 'Amber State', path: URLS.liveLook.amberState },
    ],
  },
  {
    label: 'Meeting Spaces',
    Icon: meeting,
    items: [
      { text: 'Usage Over Time', path: URLS.liveLook.meetsOverTime },
      { text: 'Daily Comparisons', path: null },
      { text: 'Space Comparison', path: URLS.liveLook.meetsSpaceComparison },
      {
        text: 'Space Usage by Size',
        path: URLS.liveLook.meetsSpaceUsageBySizes,
      },
    ],
  },
  {
    label: 'Breakout',
    Icon: coffee,
    items: [
      { text: 'Usage Over Time', path: URLS.liveLook.breakoutOverTime },
      { text: 'Daily Comparisons', path: null },
      { text: 'Space Comparison', path: URLS.liveLook.breakoutSpaceComparison },
      {
        text: 'Space Usage by Size',
        path: URLS.liveLook.breakoutSpaceUsageBySizes,
      },
    ],
  },
  {
    label: 'PDF Reports',
    Icon: report,
    items: [{ text: 'PDF', path: URLS.liveLook.pdfReports }],
  },
];

export default config;
