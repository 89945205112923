import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getReports } from 'modules/workLook/selectors';

import { TextPlaceholder } from 'modules/shared/components';

import TitlePage from './TitlePage';

const TitlePageContainer = () => {
  const { reportId } = useParams();
  const { reportData } = useSelector(getReports);
  const activeReport = reportData[reportId];

  return (
    <Choose>
      <When condition={!activeReport}>
        <TextPlaceholder notFound />
      </When>
      <Otherwise>
        <TitlePage {...activeReport} />
      </Otherwise>
    </Choose>
  );
};

export default TitlePageContainer;
