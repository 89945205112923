import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_DESK_BY_BUILDING = createAsyncAction(createAction('FETCH_DESK_BY_BUILDING'));

const fetchDeskByBuilding = (
  dispatch,
  isCanceled,
  { companyId, buildingId, dateAfter, dateBefore, workingHours, query, archive },
) => {
  dispatch(FETCH_DESK_BY_BUILDING.pending());

  const url = ENDPOINTS.liveLook.deskByBuilding
    .replace(':companyId', companyId)
    .replace(':buildingId/', buildingId ? `${buildingId}/` : '')
    .concat(`?date_after=${dateAfter}`)
    .concat(`&date_before=${dateBefore}`)
    .concat(workingHours ? `&work_hours=True` : '')
    .concat(workingHours ? `&exclude_weekend=True` : '')
    .concat(query ? `&${query}` : '')
    .concat(archive ? `&archive_id=${archive}` : '');

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_DESK_BY_BUILDING.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_DESK_BY_BUILDING.failure());
    });
};

export default fetchDeskByBuilding;
