import React from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import Skeleton from '@material-ui/lab/Skeleton';
import ReorderIcon from '@material-ui/icons/Reorder';

import { URLS } from 'pages/constants';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { isDefault, isLoading, isSuccess } from 'modules/shared/utils';

import { SidebarListItem } from 'modules/shared/components';

const Orders = () => {
  const [categoriesRequestStatus, categories] = useAssetLookSelector([
    'common.categoriesRequestStatus',
    'common.categories',
  ]);

  return (
    <Choose>
      <When condition={isDefault(categoriesRequestStatus) || isLoading(categoriesRequestStatus)}>
        <Skeleton width={274} height={32} />
      </When>
      <When condition={isSuccess(categoriesRequestStatus) && !isEmpty(categories.by_building)}>
        <Link to={URLS.assetLook.ordersBasket}>
          <SidebarListItem Icon={ReorderIcon} label="Orders" disableBorder />
        </Link>
      </When>
      <Otherwise>{null}</Otherwise>
    </Choose>
  );
};

export default Orders;
