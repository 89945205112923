import { createReducer } from '@reduxjs/toolkit';

import { requestStatuses } from 'modules/shared/constants';

import { SET_CURRENT_COMPANY } from 'modules/auth/actions';
import { RESET_STATE, FETCH_CATEGORIES, FETCH_LOCATIONS, FETCH_TAGS } from 'modules/assetLook/actions';

const initState = {
  categoriesRequestStatus: requestStatuses.default,
  categories: {},
  locationsRequestStatus: requestStatuses.default,
  locations: [],
  tagsRequestStatus: requestStatuses.default,
  tags: [],
};

export default createReducer(initState, {
  [FETCH_CATEGORIES.pending]: () => ({
    ...initState,
    categoriesRequestStatus: requestStatuses.pending,
  }),
  [FETCH_CATEGORIES.success]: (state, { payload }) => ({
    ...state,
    categoriesRequestStatus: requestStatuses.success,
    categories: payload,
  }),
  [FETCH_CATEGORIES.failure]: (state) => ({
    ...state,
    categoriesRequestStatus: requestStatuses.failure,
  }),

  [FETCH_LOCATIONS.pending]: (state) => ({
    ...state,
    locationsRequestStatus: requestStatuses.pending,
  }),
  [FETCH_LOCATIONS.success]: (state, { payload }) => ({
    ...state,
    locationsRequestStatus: requestStatuses.success,
    locations: payload,
  }),
  [FETCH_LOCATIONS.failure]: (state) => ({
    ...state,
    locationsRequestStatus: requestStatuses.failure,
  }),

  [FETCH_TAGS.pending]: (state) => ({
    ...state,
    tagsRequestStatus: requestStatuses.pending,
  }),
  [FETCH_TAGS.success]: (state, { payload }) => ({
    ...state,
    tagsRequestStatus: requestStatuses.success,
    tags: payload,
  }),
  [FETCH_TAGS.failure]: (state) => ({
    ...state,
    tagsRequestStatus: requestStatuses.failure,
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
