import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { URLS } from 'pages/constants';
import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { RESET_BASKET } from 'modules/assetLook/actions';
import { useAssetLookSelector } from 'modules/assetLook/hooks';

import RequestOrder from './RequestOrder';

function RequestOrderContainer({ onClose }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [errorCode, setErrorCode] = useState(null);

  const [basketAssets, basketBuilding] = useAssetLookSelector(['assets.basketAssets', 'assets.basketBuilding']);

  const validationSchema = yup.object({
    location: yup.number('Select the location').required('Location is required'),
    date: yup.string('Pick the date').required('Date is required'),
    comment: yup.string('Provide a comment'),
  });

  const formik = useFormik({
    initialValues: {
      location: '',
      date: new Date().toISOString().substring(0, 10),
      comment: '',
    },
    validationSchema,
    onSubmit: ({ location, date, comment }, formikHelpers) => {
      setErrorCode(null);

      const data = {
        assets: basketAssets,
        building: Number(basketBuilding),
        request_date: date,
      };

      if (location) data.target_location = location;
      if (comment) data.comment = comment;

      API.post(ENDPOINTS.assetLook.orders, data)
        .then(() => {
          onClose();
          formikHelpers.setSubmitting(false);
          dispatch(RESET_BASKET());
          history.push(URLS.assetLook.ordersInProgress);
        })
        .catch(({ response }) => {
          formikHelpers.setSubmitting(false);
          setErrorCode(response.status);
        });
    },
  });

  return <RequestOrder formik={formik} onClose={onClose} errorCode={errorCode} />;
}

export default RequestOrderContainer;
