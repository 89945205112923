import axios from 'axios';

import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_BREAKOUT_PEAK_AND_AVG_UTIL = createAsyncAction(createAction('FETCH_BREAKOUT_PEAK_AND_AVG_UTIL'));

const fetchBreakoutPeakAndAvgUtil = (dispatch, id, params) => {
  dispatch(FETCH_BREAKOUT_PEAK_AND_AVG_UTIL.pending());

  const token = isLogged();
  const url = ENDPOINTS.breakoutPeakAndAverageUtil.replace(':id', id);
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  axios
    .get(`${url}&${params}`, config)
    .then(({ data }) => dispatch(FETCH_BREAKOUT_PEAK_AND_AVG_UTIL.success(data)))
    .catch((err) => dispatch(FETCH_BREAKOUT_PEAK_AND_AVG_UTIL.failure({ reportError: err.response })));
};

export default fetchBreakoutPeakAndAvgUtil;
