import { useState, useCallback } from 'react';

const useEllipsisCalc = () => {
  const [isEllipsis, setIsEllipsis] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setIsEllipsis(node.offsetWidth < node.scrollWidth);
    }
  }, []);
  return [isEllipsis, ref];
};

export default useEllipsisCalc;
