/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';

import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, filtersToQuery, toUsageOverTimeGraphData } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import {
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
  SET_GRAPH_SWITCHER,
  fetchOccupancyDataboxes,
  fetchDoorsOverTime,
  fetchGraphsText,
  saveGraphsText,
} from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import { GRAPH_SCALES } from 'modules/liveLook/constants';
import setScaleOption, { daysDifference, isInPercentages } from '../utils/common';
import DoorsOverTime from './DoorsOverTime';
import { setDataGroupedByDays } from '../utils/setDataForOverTime';
import { setScaleValues, toLondonTimeOccupancy } from '../utils';
import reducer, { initialState } from './_reducer';

function DoorsOverTimeContainer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    data,
    capacity,
    nominalCapacity,
    scale,
    value,
    occupancyDataboxes,
    databoxRequestStatus,
    requestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
  } = state;

  const [companyId] = useAuthSelector(['currentCompany']);
  const [startDate, endDate, workingHours, activeDoorSensorFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'doorSensorFilters.activeDoorSensorFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeDoorSensorFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);
  const dayChoosen = dateAfter.slice(8, 10);

  const daysDiffernce = useMemo(() => daysDifference(endDate, startDate), [endDate, startDate]);
  const scaleOption = useMemo(() => setScaleOption(scale, daysDiffernce), [scale, daysDiffernce]);
  const getScaleLabel = isEmpty(scaleOption) ? scale : scaleOption === 'hour' ? GRAPH_SCALES.hour : scaleOption;

  useEffect(() => {
    let isCanceled = false;

    fetchDoorsOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      scaleOption,
      workingHours,
      query,
      archive,
    });

    fetchOccupancyDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'oc_time',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, dateAfter, scaleOption, dateBefore, workingHours, query]);

  const onScaleChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_SCALE(value)), []);
  const onValueChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_VALUE(value)), []);
  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  const databoxesSet = {
    occupancyDataboxes,
  };

  const dataGroupedByDays = setDataGroupedByDays(data, scale, toLondonTimeOccupancy, dayChoosen);

  const peakOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        occupancyDataboxes,
        occupancyDataboxes?.max_occupancy,
        occupancyDataboxes?.max_occupancy_percentage,
      ),
    [returnPercentageSwitcher, occupancyDataboxes],
  );

  const avgOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        occupancyDataboxes,
        occupancyDataboxes?.avg_occupancy,
        occupancyDataboxes?.avg_occupancy_percentage,
      ),
    [returnPercentageSwitcher, occupancyDataboxes],
  );

  const graphCapacity = returnPercentageSwitcher ? capacity : 100;
  const graphNominalCapacity = returnPercentageSwitcher ? nominalCapacity : 100;
  const peakIntegerValue = Math.max(
    occupancyDataboxes?.capacity,
    occupancyDataboxes?.nominal_capacity,
    occupancyDataboxes?.max_occupancy,
  );

  const { niceMax, scaleValues } = setScaleValues(returnPercentageSwitcher ? peakIntegerValue : peakOccupancy);

  // TODO make as a function
  const chunk = 20; // items per chunk
  const dateArrays = dataGroupedByDays;

  const result = dateArrays
    ? dateArrays.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunk);

        if (!resultArray[chunkIndex]) {
          // eslint-disable-next-line no-param-reassign
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, [])
    : [];

  const graphDataToBuild = result.map((graph) => {
    return toUsageOverTimeGraphData({
      sourceData: graph,
      valueType: value,
      scaleMaxValue: niceMax,
      scale: getScaleLabel,
      returnPercentageSwitcher,
    });
  });

  const graphData = toUsageOverTimeGraphData({
    sourceData: dataGroupedByDays,
    valueType: value,
    scaleMaxValue: niceMax,
    scale: getScaleLabel,
    returnPercentageSwitcher,
  });

  const linesData = {
    capacity: graphCapacity,
    nominalCapacity: graphNominalCapacity,
    peakOccupancy,
    avgOccupancy,
  };

  return (
    <Choose>
      <When
        condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].some(
          (item) => isDefault(item) || isLoading(item),
        )}
      >
        <Loader />
      </When>
      <When condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].every((item) => isSuccess(item))}>
        <DoorsOverTime
          {...state}
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'oc_time', text });
          }}
          daysDiffernce={daysDiffernce}
          editableTextReport={editableTextReport}
          activeFilters={activeDoorSensorFilters}
          scale={getScaleLabel}
          databoxesSet={databoxesSet}
          onScaleChange={onScaleChange}
          onValueChange={onValueChange}
          onSwitcherChange={onSwitcherChange}
          graphData={graphData}
          graphDataToBuild={graphDataToBuild}
          linesData={linesData}
          data={data}
          niceMax={niceMax}
          scaleValues={scaleValues}
          databoxes={occupancyDataboxes}
          returnPercentageSwitcher={returnPercentageSwitcher}
          customHeader
          archive={archive}
        />
      </When>
      <When condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default DoorsOverTimeContainer;
