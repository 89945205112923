import { URLS } from 'pages/constants';

const CURRENT = 'current';
const HISTORY = 'history';

const config = {
  current: {
    name: 'Current',
    pageType: CURRENT,
    path: URLS.liveLook.pdfReports.replace(':pageType', CURRENT),
  },
  history: {
    name: 'History',
    pageType: HISTORY,
    path: URLS.liveLook.pdfReports.replace(':pageType', HISTORY),
  },
};

export default config;
