import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Typography } from '@material-ui/core';

import { getReportInfo } from 'modules/spaceLook/selectors';

import buildings from 'assets/images/buildings.png';
import buildingsBig from 'assets/images/buildings1-5x.png';

import theme from './ReportPdfTitle.module.scss';

const ReportPdfTitle = () => {
  const { surveyName, companyName, titleImage, titleText, titleTextColor } = useSelector(getReportInfo);

  const imageSrc = titleImage || (window.devicePixelRatio === 2 ? buildings : buildingsBig);

  return (
    <div
      className={cn(theme.container, 'pdfPage')}
      style={{ backgroundImage: `url(${imageSrc})`, color: titleTextColor }}
    >
      <Typography variant="h3" className={theme.text}>
        {companyName}
      </Typography>
      <Typography variant="h3" className={theme.text} paragraph>
        {surveyName}
      </Typography>
      <If condition={titleText}>
        <Typography variant="h5" className={theme.text}>
          {titleText}
        </Typography>
      </If>
    </div>
  );
};

export default ReportPdfTitle;
