import { isEmpty } from 'lodash';
import FILTERS_AL from './filters';

const filtersToQuery = (filterList) => {
  if (!Array.isArray(filterList) || isEmpty(filterList)) return '';
  const query = filterList
    .filter(({ values }) => values.length)
    .map(({ field, values }) => ({
      field: Object.keys(FILTERS_AL).find((key) => FILTERS_AL[key] === field),
      values,
    }))
    .map(({ field, values }) => {
      return values.map((value) => `${field}=${encodeURIComponent(value)}`).join('&');
    })
    .join('&');

  return query ? `${query}` : '';
};

export default filtersToQuery;
