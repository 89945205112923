/* eslint-disable no-shadow */
import React from 'react';
import { chunk } from 'lodash';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import {
  PageHeader,
  ReportText,
  ReportDataCards,
  ReportFilter,
  FilterHandler,
  OccupationUtilizationGraph,
} from 'modules/spaceLook/components';

import { reportPageTypes } from 'modules/spaceLook/constants';
import { toOccUtilGraphData } from 'modules/spaceLook/utils';
import { useHasScroll } from 'modules/shared/hooks';

const DesksByType = ({ isLoading, pdfMode, databoxes, desksTypeGraphData }) => {
  const graphDatasets = pdfMode
    ? chunk(desksTypeGraphData, 5).map((chunk) => toOccUtilGraphData(chunk, 'category1'))
    : [toOccUtilGraphData(desksTypeGraphData, 'category1')];
  const scroll = useHasScroll();

  return graphDatasets.map((data, i) => (
    <div ref={scroll.ref} key={i} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Desks by Type" text="Desk Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.desks}>
              <ReportFilter pageType={reportPageTypes.desks} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.desks} cropped={!pdfMode} />
        </Grid>

        <Grid item xs={12}>
          <OccupationUtilizationGraph data={data} />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={['singleSpaceUsage']}
            repeat={{
              singleSpaceUsage: desksTypeGraphData.length,
            }}
            pageType={reportPageTypes.desks}
            graphData={desksTypeGraphData}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default DesksByType;
