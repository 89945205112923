import React, { useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader } from 'components';

import { requestStatuses } from 'modules/shared/constants';
import {
  getDeskTypesQuantity,
  getHeatmapData,
  getReportInfo,
  getReportPdfRequestStatuses,
} from 'modules/spaceLook/selectors';
import {
  loadReportInfo,
  fetchAppliedFilters,
  fetchBenchmarkingAppliedFilters,
  fetchHeatmapData,
  SET_PDF_MODE,
} from 'modules/spaceLook/actions';

import SLPdfPage from './SLPdfPage';

const ReportPdfPageContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const dataRequestStatuses = useSelector(getReportPdfRequestStatuses);
  const { reportInfoRequestStatus, reportLogo } = useSelector(getReportInfo);
  const {
    data: { heatmapData },
  } = useSelector(getHeatmapData);
  const deskTypesQuantity = useSelector(getDeskTypesQuantity);

  const isLoading = dataRequestStatuses.some((status) => status !== requestStatuses.success);

  useLayoutEffect(() => {
    dispatch(SET_PDF_MODE());

    loadReportInfo(dispatch, id);
    fetchAppliedFilters(dispatch, id);
    fetchBenchmarkingAppliedFilters(dispatch, id);
    fetchHeatmapData(dispatch, id);
  }, [dispatch, id]);

  useEffect(() => {
    if (isLoading) return;

    const pages = document.getElementsByClassName('hasPageCounter');

    Array.prototype.forEach.call(pages, (page) => {
      const img = document.createElement('img');
      img.classList.add('reportLogo');
      img.src = reportLogo;
      page.appendChild(img);
    });
  }, [isLoading, reportLogo]);

  if (reportInfoRequestStatus !== requestStatuses.success) return null;

  return (
    <>
      <If condition={isLoading}>
        <Loader id="pdf-loader" fixed />
      </If>
      <SLPdfPage heatmapData={heatmapData} deskTypesQuantity={deskTypesQuantity} />
    </>
  );
};

export default ReportPdfPageContainer;
