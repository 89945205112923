/* eslint-disable no-return-assign */
import { uniq } from 'lodash';

const keysToCombined = ['day_of_week', 'building', 'floor', 'space_type', 'division', 'department'];

const toCombinedInitFilters = (desksInit, meetsInit, breakInit) => {
  const init = {
    from_date: '',
    to_date: '',
    day_of_week: [],
    building: [],
    floor: [],
    space_type: [],
    division: [],
    department: [],
  };

  // eslint-disable-next-line consistent-return
  Object.keys(desksInit).forEach((key) => {
    if (key === 'from_date' || key === 'to_date') return (init[key] = desksInit[key]);
    if (keysToCombined.includes(key)) init[key].push(desksInit[key]);
  });

  Object.keys(meetsInit).forEach((key) => {
    if (key === 'from_date' || key === 'to_date') return;
    if (keysToCombined.includes(key)) init[key].push(meetsInit[key]);
  });

  Object.keys(breakInit).forEach((key) => {
    if (key === 'from_date' || key === 'to_date') return;
    if (keysToCombined.includes(key)) init[key].push(breakInit[key]);
  });

  keysToCombined.forEach((key) => (init[key] = uniq(init[key].flat())));

  return init;
};

export default toCombinedInitFilters;
