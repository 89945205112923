import { graphDataTypes } from 'components/Pages/SummaryPage/constants';

const config = {
  graphsHeader: {
    [graphDataTypes.deskCount]: 'Desk count by Division',
    [graphDataTypes.deskUtilization]: 'Peak Desk Utilisation by Division',
  },
};

export default config;
