/* eslint-disable camelcase */
// eslint-disable-next-line import/no-cycle
import { getRandomColor } from 'modules/workLook/utils';
import { GRAPH_COLORS, GROUP_TYPES, SERIES_ACTION_GROUP } from 'modules/workLook/constants';

const setGraphDataLegendsColors = (graphData, name, data, color, isModal, itemCounter) => {
  const label = name.length > 16 && !isModal ? `${name.slice(0, 13)}...` : name;
  const backgroundColor = color || GRAPH_COLORS[itemCounter];

  graphData.labels.push(label);
  graphData.fullLabels.push(name);
  graphData.datasets[0].data.push(data);
  graphData.datasets[0].backgroundColor.push(backgroundColor || getRandomColor());
};

const handleGroupNoneDataStructure = (data, isModal) => {
  const graphData = {
    labels: [],
    fullLabels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  };

  let itemCounter = 0;

  // eslint-disable-next-line no-shadow
  data.forEach(({ action, data, name, color }) => {
    if (!data || data === 0) return null;
    if (SERIES_ACTION_GROUP.singleValue.includes(action)) {
      const barColor = color === '#ffffff' ? false : color;
      setGraphDataLegendsColors(graphData, name, data, barColor, isModal, itemCounter);
      itemCounter += 1;
    }

    if (SERIES_ACTION_GROUP.multipleValue.includes(action)) {
      data.forEach(({ field, value, _color }) => {
        setGraphDataLegendsColors(graphData, field, value, _color, isModal, itemCounter);
        itemCounter += 1;
      });
    }

    if (SERIES_ACTION_GROUP.counter.includes(action)) {
      data.forEach(({ field, value, _percentage, _color }) => {
        setGraphDataLegendsColors(graphData, field, _percentage || value, _color, isModal, itemCounter);
        itemCounter += 1;
      });
    }
  });

  return graphData.datasets[0].data.length ? graphData : null;
};

const handleGroupByFieldDataStructure = (data, isModal) => {
  const graphData = {
    labels: [],
    fullLabels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  };

  const firstSeries = data[0];

  if (SERIES_ACTION_GROUP.singleValue.includes(firstSeries.action)) {
    firstSeries.data.forEach(({ field, value, _color }, i) => {
      setGraphDataLegendsColors(graphData, field, value, _color, isModal, i);
    });
  }

  return graphData.datasets[0].data.length ? graphData : null;
};

const handleGroupByNameDataStructure = (data, isModal) => {
  const graphData = {
    labels: [],
    fullLabels: [],
    datasets: [],
  };

  data.forEach(({ group_name, series_data }) => {
    const label = group_name && group_name.length > 16 && !isModal ? `${group_name.slice(0, 13)}...` : group_name;
    graphData.labels.push(label);
    graphData.fullLabels.push(group_name);

    series_data.forEach((series, i) => {
      if (SERIES_ACTION_GROUP.singleValue.includes(series.action)) {
        const datasetIndex = graphData.datasets.findIndex((dataset) => dataset.label === series.name);
        const barColor = series.color === '#ffffff' ? false : series.color;
        const backgroundColor = barColor || GRAPH_COLORS[i];

        if (datasetIndex >= 0) {
          graphData.datasets[datasetIndex].data.push(series.data);
        } else {
          graphData.datasets.push({
            label: series.name,
            backgroundColor: backgroundColor || getRandomColor(),
            data: [series.data],
          });
        }
      }
    });
  });

  return graphData.datasets.length ? graphData : null;
};

const toBarChartData = (data, groupType, isModal) => {
  if (!data || !data.length) return null;

  switch (groupType) {
    case null:
      return handleGroupNoneDataStructure(data, isModal);
    case GROUP_TYPES.byField:
      return handleGroupByFieldDataStructure(data, isModal);
    case GROUP_TYPES.byName:
      return handleGroupByNameDataStructure(data, isModal);
    default:
      return null;
  }
};

export default toBarChartData;
