import { combineReducers } from 'redux';

import templates from './templatesReducer';
import reports from './reportsReducer';
import mapTypes from './mapsReducer';
import graphData from './graphReducer';
import dataCards from './dataCardReducer';
import detailData from './detailModalReducer';
import widgetsData from './widgetsDataReducer';
import controlCardData from './controlCardReducer';
import timeGraph from './timeGraphReducer';
import textSummary from './textSummaryReducer';
import blankReport from './createReportReducer';

export default combineReducers({
  templates,
  reports,
  mapTypes,
  graphData,
  dataCards,
  detailData,
  widgetsData,
  controlCardData,
  timeGraph,
  textSummary,
  blankReport,
});
