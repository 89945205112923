import React from 'react';

import { URLS } from 'pages/constants';
import { makeSearch } from 'modules/assetLook/utils';
import { SidebarListSubItem } from 'modules/shared/components';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID } from 'modules/assetLook/constants';

const AllProductsCategory = ({ building_id: buildingId, category_id: categoryId }) => {
  const { buildingId: activeBuildingId, categoryId: activeCategoryId } = useLocationSearchParams([
    BUILDING_ID,
    CATEGORY_ID,
  ]);

  return (
    <SidebarListSubItem
      link={{
        pathname: URLS.assetLook.productList,
        search: makeSearch({ buildingId, categoryId }),
        state: { isBuilding: true },
      }}
      label="All Products"
      isActive={buildingId ? Number(activeBuildingId) === buildingId && !activeCategoryId : !activeCategoryId}
    />
  );
};

export default AllProductsCategory;
