/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import React, { useContext, useRef, useState } from 'react';
import { ALLOWED_FILE_TYPES } from 'modules/shared/constants';
import TextEditorContext from 'modules/shared/components/TextEditor/TextEditorContext';
// eslint-disable-next-line import/no-cycle
import { CustomSnackbar } from 'modules/shared/components';
import ImageButton from './ImageButton';

const ImageButtonContainer = () => {
  const { editorState, setEditorState, addImage, isImageBtnShow } = useContext(TextEditorContext);
  const [showErr, setShowErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const inputRef = useRef(null);
  const _onIconClick = () => {
    if (!isImageBtnShow) return;
    inputRef.current.click();
  };

  const _onFileSelect = () => {
    const file = inputRef.current.files[0];
    if (!file) return;

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setErrMessage('Wrong file format. Allowed formats: jpeg, png, gif.');
      setShowErr(true);
      return (inputRef.current.value = null);
    }

    const size = (file.size / (1024 * 1024)).toFixed(2);
    if (size > 5) {
      setErrMessage('The file is too large. Allowed maximum size is 5Mb.');
      setShowErr(true);
      return (inputRef.current.value = null);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const newState = addImage(editorState, reader.result);
      setEditorState(newState);
    };
    reader.onerror = () => {
      setErrMessage('Something went wrong. Please try again later or inform your manager.');
      setShowErr(true);
    };

    inputRef.current.value = null;
  };

  const _onErrorSnackbarClose = () => setShowErr(false);

  return (
    <>
      <ImageButton ref={inputRef} _onIconClick={_onIconClick} _onFileSelect={_onFileSelect} />

      <CustomSnackbar open={showErr} onClose={_onErrorSnackbarClose} severity="error" label={errMessage} />
    </>
  );
};

export default ImageButtonContainer;
