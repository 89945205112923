/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { Grid, Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  firstLine: {
    color: theme.palette.primary.dark,
    textAlign: 'right',
  },
  button: {
    padding: 0,
    borderColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  secondLine: {
    color: 'rgba(0,0,0,0.6)',
  },
  root: {
    padding: '4px 16px',
    textTransform: 'capitalize',
  },
}));

function AddToPDF({ counter, onClick, disabled }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} align="right">
        <Button variant="text" size="small" onClick={onClick} classes={{ root: classes.button }} disabled={disabled}>
          <Typography variant="h5" component="span" classes={{ root: classes.firstLine }}>
            + ADD TO PDF
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} align="right">
        <Typography component="span" classes={{ root: classes.secondLine }}>
          {counter || counter === 0 ? (
            `${counter} ${counter === 1 ? 'page' : 'pages'} added`
          ) : (
            <Skeleton width="80px" />
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AddToPDF;
