import React from 'react';
import { makeStyles, MenuItem, Select, Typography } from '@material-ui/core';

import { userRoles } from 'modules/auth/constants';

import theme from './SidebarHeader.module.scss';

const useStyles = makeStyles(() => ({
  select: {
    fontSize: '1.25rem',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
    '&:focus': { backgroundColor: 'unset' },
  },
}));

const SidebarHeader = ({ userRole, companyLogo, activeCompanyId, companyList, onCompanySelect }) => {
  const classes = useStyles();

  return (
    <div className={theme.container}>
      <div
        className={theme.logo}
        style={{
          backgroundImage: `url(${companyLogo})`,
        }}
      />
      <Choose>
        <When condition={userRole === userRoles.client}>
          <Typography variant="h6">{companyList.find((company) => company.id === activeCompanyId).name}</Typography>
        </When>
        <Otherwise>
          <Select
            value={activeCompanyId}
            onChange={onCompanySelect}
            disableUnderline
            MenuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              getContentAnchorEl: null,
            }}
            classes={{ select: classes.select, root: classes.root }}
            style={{ width: '92.5%' }}
          >
            {companyList.map(({ id, name }) => (
              <MenuItem key={`${name}-${id}`} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Otherwise>
      </Choose>
    </div>
  );
};

export default SidebarHeader;
