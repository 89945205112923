import React from 'react';
import { get } from 'lodash';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import { URLS } from 'pages/constants';
import { RESET_STATE } from 'modules/workLook/actions';

function WorkLookItem({ id, name, theme }) {
  const dispatch = useDispatch();

  const matchWorkLook = useRouteMatch(URLS.workLook.base);

  return (
    <Link
      to={{
        pathname: URLS.workLook.base.replace(':typeId', id),
        state: { isInit: true },
      }}
      className={cn(theme.navItem, get(matchWorkLook, 'params.typeId', null) === String(id) && theme.active)}
      onClick={() => dispatch(RESET_STATE())}
    >
      {name}
    </Link>
  );
}

export default WorkLookItem;
