import { sortObjects } from '..';

const transformDivisionData = (data) => {
  if (!data.length) {
    return [];
  }

  const divisionMaxDesksValue = data.sort(sortObjects('desk_count'))[data.length - 1].desk_count;

  return data.map((division) => {
    const deskCount = division.desk_count;
    return {
      property_name: division.division,
      divisions: [
        {
          division: 'Desks',
          desk_count: deskCount,
        },
        {
          division: 'Free Space',
          desk_count: divisionMaxDesksValue - deskCount,
        },
      ],
    };
  });
};

export default transformDivisionData;
