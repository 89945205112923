/* eslint-disable no-param-reassign */
import { omit } from 'lodash';

const getTypeObject = (obj, pref) => {
  return Object.keys(obj).reduce((typeResult, typeCurrent) => {
    typeResult[`${pref}_${typeCurrent}`] = obj[typeCurrent];
    return typeResult;
  }, {});
};

const setupBuildingFloorData = (occupancy, utilization, removedKey) => {
  const additionalFloorTitle = removedKey === 'location_id__floor' ? ' Floor' : '';
  return occupancy.reduce((result, current, idx) => {
    const occObj = getTypeObject(current, 'occ');
    const utilObj = utilization ? getTypeObject(utilization[idx], 'util') : {};

    const obj = {
      ...omit(occObj, [`occ_${removedKey}`]),
      ...omit(utilObj, [`util_${removedKey}`]),
      title: `${additionalFloorTitle} ${current[removedKey]}`,
    };

    result[current[removedKey]] = obj;
    return result;
  }, {});
};

export default setupBuildingFloorData;
