import React from 'react';
import { pick } from 'lodash';
import { Grid, Typography } from '@material-ui/core';

import { StackedPercentNav } from 'components';
import DetailsGraphSwitcher from 'components/Pages/DetailsPage/components/DetailsGraphSwitcher';
import { graphDataTypes } from 'components/Pages/SummaryPage/constants';
import ConstructGraph from 'components/Pages/SummaryPage/components/Graphs/ConstructGraph';
import { sortData } from 'components/Pages/SummaryPage/utils/sortData';

import {
  setupStackedHorizontalBar,
  constructOptions,
  transformDataDivisionPercent,
  transformDivisionData,
} from 'utils';

import NABlock from 'components/Graphs/NABlock';

import { deskCountOptions, deskUtilizationOptions } from './options';
import config from './config';

const Graphs = ({ graphDataType, sortOrder, graphsData, dispatch }) => {
  const graphDivisionPercentDataTransformed = transformDataDivisionPercent(graphsData, 'peak_util_percent');
  const barsDivisionPercentData = setupStackedHorizontalBar(graphDivisionPercentDataTransformed);
  const graphDivisionPercent = barsDivisionPercentData.graphs;
  const graphDivisionPercentPeaks = barsDivisionPercentData.peaks;

  const graphDivisionData = graphsData.map((item) => pick(item, ['division', 'desk_count']));
  const divisionData = setupStackedHorizontalBar(transformDivisionData(graphDivisionData), true);
  const graphDivision = divisionData.graphs;
  const graphDivisionPeaks = divisionData.peaks;

  return (
    <section className="graphs-block">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} lg={6}>
              <Typography variant="h6">{config.graphsHeader[graphDataType]}</Typography>
            </Grid>
            {!!graphsData.length && (
              <Grid item xs={12} lg={6} align="right">
                <DetailsGraphSwitcher dispatch={dispatch} graphDataType={graphDataType} sortOrder={sortOrder} />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div className="one-graph-wrapper">
            <If condition={graphsData.length}>
              <Choose>
                <When condition={graphDataType === graphDataTypes.deskCount}>
                  <ConstructGraph
                    data={sortData(graphDivision, sortOrder)}
                    options={constructOptions(graphDivisionPeaks, deskCountOptions)}
                  />
                  <p className="count-division-legend">Allocated staff</p>
                </When>

                <When condition={graphDataType === graphDataTypes.deskUtilization}>
                  <ConstructGraph
                    data={sortData(graphDivisionPercent, sortOrder)}
                    options={constructOptions(graphDivisionPercentPeaks, deskUtilizationOptions, false)}
                  />
                  <StackedPercentNav />
                </When>
              </Choose>
              <Else />
              <NABlock height={100} />
            </If>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Graphs;
