/* eslint-disable func-names */
import { Chart } from 'react-chartjs-2';

const options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  events: [],
  dataset: [{ barPercentage: 1, categoryThickness: 120 }],
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontSize: 12,
          fontStyle: 'bold',
          color: '#4D4D4D',
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 100,
          display: true,
          fontSize: 12,
          fontStyle: 'bold',
          color: '#4D4D4D',
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  animation: {
    onComplete() {
      const chartInstance = this.chart;
      const { ctx } = chartInstance;
      ctx.textAlign = 'center';
      ctx.font = '9px Roboto';
      ctx.fillStyle = '#fff';

      Chart.helpers.each(
        this.data.datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);
          Chart.helpers.each(
            meta.data.forEach(function (bar, index) {
              const data = dataset.data[index];
              ctx.fillStyle = '#000';
              ctx.font = '500 12px Roboto';
              ctx.fillText(`${data}`, bar._model.x, bar._model.y - 10);
            }),
            this,
          );
        }),
        this,
      );
    },
  },
};

export default options;
