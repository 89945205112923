import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 16px',
    borderRadius: '16px',
    textTransform: 'capitalize',
    backgroundColor: ({ isActive }) => (isActive ? theme.palette.primary.light : theme.palette.grey[200]),

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

function FilterButton({ variant = 'contained', isActive, disabled, startIcon, onClick, children }) {
  const classes = useStyles({ isActive });

  return (
    <Button
      disabled={disabled}
      variant={variant}
      classes={{ root: classes.root }}
      startIcon={startIcon}
      onClick={onClick}
      // eslint-disable-next-line react/no-children-prop
      children={children}
    />
  );
}

export default FilterButton;
