import axios from 'axios';
import isLogged from 'utils/isLogged';

const API = {
  get: (url, config) => {
    const conf = {
      ...config,
      headers: {
        ...(config && config.headers),
        Authorization: `Token ${isLogged()}`,
      },
    };

    return axios.get(url, conf);
  },

  post: (url, data, config) => {
    const conf = {
      ...config,
      headers: {
        ...(config && config.headers),
        Authorization: `Token ${isLogged()}`,
      },
    };

    return axios.post(url, data, conf);
  },

  put: (url, data, config) => {
    const conf = {
      ...config,
      headers: {
        ...(config && config.headers),
        Authorization: `Token ${isLogged()}`,
      },
    };

    return axios.put(url, data, conf);
  },

  delete: (url, config) => {
    const conf = {
      ...config,
      headers: {
        ...(config && config.headers),
        Authorization: `Token ${isLogged()}`,
      },
    };

    return axios.delete(url, conf);
  },

  patch: (url, data, config) => {
    const conf = {
      ...config,
      headers: {
        ...(config && config.headers),
        Authorization: `Token ${isLogged()}`,
      },
    };

    return axios.patch(url, data, conf);
  },
};

export default API;
