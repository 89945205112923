import React from 'react';
import { isEmpty } from 'lodash';

import { useDispatch } from 'react-redux';

import { makeDefaultActiveFilters, isDefault, isLoading, isSuccess } from 'modules/shared/utils';
import { showModal } from 'modules/modalsSystem/actions/showModal';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';

import { FilterList } from 'modules/assetLook/components';
import { FilterListSkeleton } from 'modules/shared/components';
import { useInitFilters } from 'pages/_privatePages/assetLook/PdfReportsNew/utils';

const FilterListPdf = ({ formik }) => {
  const dispatchRedux = useDispatch();

  const { requestStatus, initialFilters } = useInitFilters(formik);

  const onFilterButtonClick = (filterField) =>
    dispatchRedux(
      showModal(modalCodes.AL_FILTER_MODAL, {
        filterField,
        initialFilters,
        activeFinalFilters: formik.values.activeFilters,
        onApply: ({ field, values }) =>
          formik.setFieldValue(
            'activeFilters',
            formik.values.activeFilters.map((filter) => (filter.field === field ? { field, values } : { ...filter })),
          ),
      }),
    );

  const onResetClick = () => formik.setFieldValue('activeFilters', makeDefaultActiveFilters(initialFilters));

  return (
    <Choose>
      <When condition={isDefault(requestStatus) || isLoading(requestStatus)}>
        <FilterListSkeleton />
      </When>
      <When condition={isSuccess(requestStatus) && !isEmpty(initialFilters)}>
        <FilterList
          initFilters={initialFilters}
          activeFilters={formik.values.activeFilters}
          onFilterButtonClick={onFilterButtonClick}
          onResetClick={onResetClick}
        />
      </When>
      <Otherwise>{null}</Otherwise>
    </Choose>
  );
};

export default FilterListPdf;
