import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { Bar } from 'react-chartjs-2';
import cn from 'classnames';

import GraphYScale from 'modules/liveLook/components/GraphYScale';
import { GRAPH_SCALES } from 'modules/liveLook/constants';
import comibnedOptions from './compinedOptions';
import options from './options';
import theme from './GraphBody.module.scss';

import GraphLines from '../GraphLines';
import { WRAPPER_WIDTH, BAR_WIDTH } from './graphBody.constants';
import { getMarginForScale, getWidth } from '../../usageOverTime.utils';

function GraphBody({
  scaleValues = ['100%', '80%', '60%', '40%', '20%', '0%'],
  graphData: { data, dayLabels },
  config,
  linesData,
  scale,
  isSwitcherOn,
  isDashboardPageWeek,
  isDashboardPage,
  isCombinedDashboard,
  pdfCustomHeader,
  isCombinedOccupancy,
}) {
  const getDashboard = isDashboardPage || isDashboardPageWeek;
  const dashboardDayWidth = WRAPPER_WIDTH / data.labels.length;
  const barWidth = getDashboard ? dashboardDayWidth : BAR_WIDTH;

  const [firstBar] = data.datasets;

  const optionsDeep =
    isCombinedOccupancy || isCombinedDashboard ? cloneDeep(comibnedOptions(isSwitcherOn)) : cloneDeep(options);
  const getHourGraph = useMemo(
    () => ![GRAPH_SCALES.day, GRAPH_SCALES.week, GRAPH_SCALES.month].includes(scale),
    [scale],
  );
  if (typeof scaleValues[0] === 'number') {
    // eslint-disable-next-line prefer-destructuring
    optionsDeep.scales.yAxes[0].ticks.max = scaleValues[0];
  }

  const yScaleMarginBottom = useMemo(
    () => getMarginForScale(scale, getDashboard, pdfCustomHeader),
    [scale, getDashboard, pdfCustomHeader],
  );

  const getStyleInnerGraph = {
    width: getWidth(firstBar.data.length, data.labels.length, barWidth, scale, getDashboard),
  };

  return (
    <div
      className={cn(theme.container, {
        [`${theme.pdfMode}`]: pdfCustomHeader,
      })}
    >
      <div className={theme.graphWrapper}>
        <GraphYScale
          isSwitcherOn={isSwitcherOn}
          marginBottom={`${yScaleMarginBottom}px`}
          values={scaleValues}
          pdf={pdfCustomHeader}
        />
        <If condition={config.find((item) => item.lineLabel)}>
          <GraphLines
            config={config.filter((item) => item.lineLabel)}
            linesData={linesData}
            marginBottom={yScaleMarginBottom}
            scaleMaxValue={optionsDeep.scales.yAxes[0].ticks.max}
            isSwitcherOn={isSwitcherOn}
          />
        </If>

        <div className={theme.graphInner}>
          <div
            style={{
              ...getStyleInnerGraph,
              height: pdfCustomHeader && getHourGraph ? '104%' : '100%',
            }}
          >
            <div className={getHourGraph ? theme.hourGraph : theme.dayGraph}>
              <Bar key={dayLabels?.length} data={data} options={optionsDeep} />
            </div>

            <If condition={getHourGraph}>
              <div className={theme.labelWrapper}>
                {dayLabels?.map(({ label, length }) => (
                  <div
                    key={label}
                    title={label}
                    className={theme.dayLabel}
                    style={{
                      flexBasis: getWidth(firstBar.data.length, length, barWidth, scale, getDashboard),
                    }}
                  >
                    {label}
                  </div>
                ))}
              </div>
            </If>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraphBody;
