import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TOGGLE_FILTER } from 'modules/spaceLook/actions';
import { reportPageTypes } from 'modules/spaceLook/constants';
import { getSelectedFilters } from 'modules/spaceLook/selectors';
import { countSelectedFiltersQuantity } from 'modules/spaceLook/utils';

import FilterHandler from './FilterHandler';

const FilterHandlerContainer = ({ pageType, ...props }) => {
  const dispatch = useDispatch();
  const showFilter = useSelector((state) => state.report.services.showFilter);

  let dataKey;
  // eslint-disable-next-line default-case
  switch (pageType) {
    case reportPageTypes.combined:
      dataKey = 'combined';
      break;
    case reportPageTypes.desks:
      dataKey = 'desks';
      break;
    case reportPageTypes.meetings:
      dataKey = 'meetings';
      break;
    case reportPageTypes.breakout:
      dataKey = 'breakout';
      break;
  }

  const { selectedFilters } = useSelector((store) => getSelectedFilters(store, dataKey));

  const toggleFilter = () => dispatch(TOGGLE_FILTER());

  return (
    <FilterHandler
      showFilter={showFilter}
      toggleFilter={toggleFilter}
      selectedFiltersQuantity={countSelectedFiltersQuantity(selectedFilters)}
      {...props}
    />
  );
};

export default FilterHandlerContainer;
