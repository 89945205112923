import React from 'react';
import { getValue, cutString } from './config';

export const formatDateTime = (fieldType, value) => {
  if (['datetime', 'date', 'time'].includes(fieldType)) {
    const [year, month, day, ...rest] = value?.replace(' ', '-').replace('Z', '').replace('T', '-').split('-');
    return `${[month, day, year].join('-')} ${rest}`;
  }
  if (['file'].includes(fieldType)) {
    return <a href={getValue(fieldType, value)}>{cutString(getValue(fieldType, value))}</a>;
  }
  return value;
};
