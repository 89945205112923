import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import isLogged from 'utils/isLogged';
import URLS from 'pages/constants/urls';
import { useAuthSelector } from 'modules/auth/hooks';
import { useSharedSelector } from 'modules/shared/hooks';
import { fetchUser, SET_CURRENT_COMPANY } from 'modules/auth/actions';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { fetchWorkLookTypes, setPropertyNamesMap } from 'modules/shared/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';

const PrivatePage = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  if (!isLogged()) {
    localStorage.clear();
    history.push(URLS.auth.login);
  }

  const [userRequestStatus, currentCompany] = useAuthSelector(['userRequestStatus', 'currentCompany']);

  const sharedStatuses = useSharedSelector(['propertyNamesRequestStatus', 'portalSectionRequestStatus']);

  useEffect(() => {
    if (!currentCompany) {
      try {
        const lastCompany = localStorage.getItem('lastCompany');

        // eslint-disable-next-line no-unused-expressions
        lastCompany && dispatch(SET_CURRENT_COMPANY(JSON.parse(lastCompany)));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    if (isDefault(userRequestStatus)) fetchUser(dispatch);
    setPropertyNamesMap(dispatch);
    fetchWorkLookTypes(dispatch, currentCompany);
  }, [currentCompany, dispatch, userRequestStatus]);

  const statuses = [userRequestStatus, ...sharedStatuses];

  if (isFailure(userRequestStatus)) {
    localStorage.clear();
    history.push(URLS.auth.login);
  }

  return (
    <Choose>
      <When condition={statuses.some((status) => isFailure(status))}>
        <TextPlaceholder error />
      </When>
      <When condition={statuses.some((status) => isDefault(status) || isLoading(status))}>
        <Loader />
      </When>
      <When condition={statuses.every((status) => isSuccess(status))}>{children}</When>
    </Choose>
  );
};

export default PrivatePage;
