import { omit } from 'lodash';

const getMeetAndBreakInitFilters = ({ report: { filters } }) => ({
  requestStatus: filters.options.requestStatus,
  initFilters: filters.options.init.combined
    ? { ...omit(filters.options.init.combined, ['division', 'department']) }
    : null,
});

export default getMeetAndBreakInitFilters;
