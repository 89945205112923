import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { REQUESTS } from '_constants';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';
import { redirectToLogin, isLogged } from 'utils';

const { base, version, reAuth } = REQUESTS;

const withUser = (Wrapped) => (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const { user } = props;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const token = isLogged();

    if (user) return setCurrentUser(user);

    if (token) {
      const request = {
        method: 'get',
        url: `${base}${version}${reAuth}`,
        headers: { Authorization: `Token ${token}` },
      };

      axios(request).then(({ data }) => setCurrentUser(data));
    }
  }, [user]);

  // eslint-disable-next-line react/destructuring-assignment
  redirectToLogin(props.history);

  if (!currentUser) return null;
  return <Wrapped {...props} user={currentUser} />;
};

const mapStateToProps = ({ auth: { user, currentCompany } }) => ({
  user,
  currentCompany,
});

const mapDispatchToProps = {
  setCurrentCompany: SET_CURRENT_COMPANY,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withUser);
