const labels = [
  '0%',
  '1%-10%',
  '11%-20%',
  '21%-30%',
  '31%-40%',
  '41%-50%',
  '51%-60%',
  '61%-70%',
  '71%-80%',
  '81%-90%',
  '91%-100%',
];

// TODO: Remove after "DesksTwentyPercent" refactoring
const setupBandingData = (graphValues) => {
  return labels.reduce((result, current, idx) => {
    // eslint-disable-next-line no-param-reassign
    result[current] = {
      quantity: graphValues[idx],
      title: current,
    };
    return result;
  }, {});
};

export default setupBandingData;
