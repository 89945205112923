import { pick } from 'lodash';

const keysToPick = [
  'databoxes',
  'pieData',
  'textData',
  'dailyHourlyGraphData',
  'weeklyHourlyGraphData',
  'usageBandingOccGraphData',
];

const getKeyFindingsDesksData = ({ report: { desks } }) => ({
  dataRequestStatuses: pick(desks.requestStatuses, keysToPick),
  data: pick(desks.data, keysToPick),
  shouldRefetchData: pick(desks.shouldRefetchData, keysToPick),
});

export default getKeyFindingsDesksData;
