/* eslint-disable react/no-children-prop */
import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import { CustomButtonContained } from 'modules/shared/components';

import { ERROR_TEXT } from 'modules/shared/constants';

import LocationSelect from './LocationSelect';
import StatusSelect from './StatusSelect';
import TagsMultiSelect from './TagsMultiSelect';

const RequestComplete = ({ classes, formik, onClose, errorCode }) => (
  <Dialog
    open
    onClose={onClose}
    PaperProps={{
      classes: {
        root: classes.container,
      },
    }}
  >
    <DialogContent classes={{ root: classes.content }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocationSelect formik={formik} />
        </Grid>
        <Grid item xs={12}>
          <StatusSelect formik={formik} />
        </Grid>
        <Grid item xs={12}>
          <TagsMultiSelect formik={formik} />
        </Grid>
        <If condition={errorCode}>
          <Grid item xs={12}>
            <Typography variant="body1" classes={{ root: classes.errorMessage }}>
              {errorCode === 400 ? 'The status of this order has been already changed' : ERROR_TEXT}
            </Typography>
          </Grid>
        </If>
      </Grid>
    </DialogContent>
    <DialogActions classes={{ root: classes.actions }}>
      <Button children="Cancel" variant="contained" onClick={onClose} />
      <CustomButtonContained
        label="Apply"
        onClick={() => formik.submitForm()}
        startIcon={formik.isSubmitting && <CircularProgress size={14} classes={{ root: classes.loader }} />}
      />
    </DialogActions>
  </Dialog>
);

export default withStyles((theme) => ({
  container: {
    maxWidth: '350px',
  },
  content: {
    padding: '24px',
  },
  actions: {
    padding: '0 24px 24px',
  },
  loader: {
    color: 'white',
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}))(RequestComplete);
