const countSelectedFiltersQuantity = (selectedFilters) => {
  let counter = 0;

  Object.keys(selectedFilters).forEach((key) => {
    if (Array.isArray(selectedFilters[key])) {
      counter += selectedFilters[key].length;
    } else {
      counter += !selectedFilters[key] ? 0 : 1;
    }
  });

  return counter;
};

export default countSelectedFiltersQuantity;
