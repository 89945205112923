const options = {
  layout: {
    padding: {
      left: -8,
    },
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  events: [],
  dataset: [{ categoryPercentage: 0.9, barPercentage: 1 }],
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#4D4D4D',
          fontSize: 12,
          minRotation: 90,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: { min: 0, max: 100, display: false },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export default options;
