import React from 'react';
import cn from 'classnames';

import './HistoryReportListNode.scss';

const HistoryReportListNode = ({ title, label, percent }) => {
  // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
  const renderedPercent = percent ? <span className="percent">{percent}</span> : null;

  const classes = cn('HistoryReportListNode', { 'with-percent': percent });

  const desks = percent ? 'desks' : '';

  return (
    <div className={classes}>
      <div className="history-report-list-node">
        <div className="header">
          <h4 className="title">{title}</h4>
        </div>
        <div className="content">
          {renderedPercent}
          <span className="label">
            {label} {desks}
          </span>
        </div>
      </div>
    </div>
  );
};

HistoryReportListNode.defaultProps = {
  title: 'Size',
  label: 111,
};

export default HistoryReportListNode;
