/* eslint-disable camelcase */
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Paper } from '@material-ui/core';

import { Card, TextPlaceholder } from 'modules/shared/components';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';

import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';
import theme from './DataCards.module.scss';

const DataCards = ({
  isLoading,
  data,
  graphId,
  title,
  sub_title,
  isPdf,
  isModal,
  type,
  typeOfGroup,
  showTitle,
  isPublished,
}) => {
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && !data}>
        <Paper className={theme.emptyContainer} elevation={2}>
          <div className={theme.header}>
            <GraphHeader
              isPublished={isPublished}
              showTitle={showTitle}
              isPdf={isPdf}
              title={title}
              subTitle={sub_title}
              graphId={graphId}
              onClick={onEditClick}
              onModifierClick={onModifierClick}
            />
          </div>
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <GraphHeader
          graphId={graphId}
          isPdf={isPdf}
          onClick={onEditClick}
          onModifierClick={onModifierClick}
          isPublished={isPublished}
        />
        <div className={theme.container}>
          {data &&
            // eslint-disable-next-line no-shadow
            data.map(({ color, name, sub_title, data }, i) => (
              <If condition={i < 6}>
                <div className={theme.item} key={i}>
                  <Card title={name} subTitle={sub_title} data={data} subData=" " color={color} />
                </div>
              </If>
            ))}
        </div>
      </Otherwise>
    </Choose>
  );
};

export default DataCards;
