/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';
import { toDataCardsData } from 'modules/workLook/utils';

import DataCards from './DataCards';
import DataCardsGrid from './DataCardsGrid';

// eslint-disable-next-line camelcase
const DataCardsContainer = ({
  grid,
  id,
  activeFilters,
  type_of_group,
  isPdf,
  is_display_title,
  isPublished,
  ...props
}) => {
  const DataCardsComponent = grid ? DataCardsGrid : DataCards;

  const { isLoading, data } = useGraphData(id, activeFilters);

  return (
    <DataCardsComponent
      isPublished={isPublished}
      isPdf={isPdf}
      typeOfGroup={type_of_group}
      graphId={id}
      isLoading={isLoading}
      showTitle={is_display_title}
      data={toDataCardsData(data, type_of_group)}
      {...props}
    />
  );
};

export default DataCardsContainer;
