import { useSelector } from 'react-redux';
import { get } from 'lodash';

const useAssetLookSelector = (paths) => {
  return useSelector(({ shared }) => {
    const res = [];

    paths.forEach((path) => res.push(get(shared, path)));

    return res;
  });
};

export default useAssetLookSelector;
