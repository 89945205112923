import React from 'react';
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import { GRAPH_VALUES } from 'modules/liveLook/constants';
import { GRAPHSCALES } from './graphHeader.constants';

const GraphHeader = ({
  daysDiffernce,
  title,
  scale,
  onScaleChange,
  value,
  onValueChange,
  isSwitcherOn,
  onSwitcherChange,
  customHeader,
  pdfCustomHeader,
  shouldRenderScale,
  graphData,
}) => {
  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item style={{ marginRight: 'auto' }}>
        {
          // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
          pdfCustomHeader ? (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold" component="span">
                  {title}
                </Box>
                <Box fontWeight="normal" fontSize={12} marginLeft={5} component="span">{` Scale: ${scale},`}</Box>
                <Box fontWeight="normal" fontSize={12} component="span">{` Value method: ${value}`}</Box>
              </Typography>
            </div>
          ) : (
            <Typography variant={pdfCustomHeader ? 'subtitle2' : 'h5'}>
              <Box
                fontWeight="fontWeightBold"
                component="span"
                // eslint-disable-next-line react/no-children-prop
                children={title}
              />
            </Typography>
          )
        }
      </Grid>

      {customHeader && graphData && (
        <Grid item>
          <FormGroup>
            <Grid component="label" container alignItems="center" spacing={2}>
              <Grid item>Percentages</Grid>
              <Grid item>
                <FormControlLabel
                  control={<Switch checked={isSwitcherOn} onChange={onSwitcherChange} />}
                  label="Integers"
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Grid>
      )}
      {!shouldRenderScale && graphData && !pdfCustomHeader && (
        <>
          <Grid item>
            <FormControl style={{ minWidth: '150px' }}>
              <InputLabel id="scale">Scale</InputLabel>
              <Select
                labelId="scale"
                id="scale"
                value={scale}
                onChange={onScaleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {GRAPHSCALES.map(({ period, maxDays }) => (
                  <MenuItem disabled={daysDiffernce >= maxDays} value={period} key={period}>
                    {period}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: '150px' }}>
              <InputLabel id="value">Value method</InputLabel>
              <Select
                labelId="value"
                id="value"
                value={value}
                onChange={onValueChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={GRAPH_VALUES.max}>{GRAPH_VALUES.max}</MenuItem>
                <MenuItem value={GRAPH_VALUES.avg}>{GRAPH_VALUES.avg}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default GraphHeader;
