/* eslint-disable no-shadow */
import React from 'react';
import cn from 'classnames';

import { Grid, withStyles } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';

const PdfFilters = ({ activeFilters, classes }) => (
  <Grid container spacing={2} wrap="nowrap">
    {activeFilters.map((filter, i) => (
      <Grid item key={i}>
        <Grid container spacing={1} direction="column">
          <Grid item classes={{ root: classes.listTitle }}>
            <FilterListIcon fontSize="small" classes={{ root: classes.icon }} />
            <span className={classes.ellipsis}>{filter.field}</span>
          </Grid>
          <Grid
            item
            component="ul"
            style={{
              columnCount: Math.ceil(filter.values.length / 2),
            }}
            className={classes.list}
          >
            {filter.values.map((value, i) => (
              <li className={classes.listItem} key={i}>
                <DoneIcon fontSize="small" classes={{ root: classes.icon }} />
                <span className={cn(classes.listItemText, classes.ellipsis)}>{value}</span>
              </li>
            ))}
          </Grid>
        </Grid>
      </Grid>
    ))}
  </Grid>
);

export default withStyles((theme) => ({
  listTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    fontWeight: '700',
    fontSize: '14px',
  },
  list: {
    columnGap: theme.spacing(1),
  },
  listItem: {
    maxWidth: '150px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  listItemText: {
    fontSize: '12px',
    lineHeight: '20px',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))(PdfFilters);
