import React from 'react';
import {
  Grid,
  Typography,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from '@material-ui/core';
import { isAdmin, isClient, isPartner, isViewOnly } from 'modules/shared/utils';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { URL } from 'modules/workLook/components/ExternalLink/constants';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getUser } from 'modules/auth/selectors';
import { GRAPHSCALES } from './constants';

// eslint-disable-next-line no-unused-vars
const GraphHeader = ({
  title,
  scale,
  onScaleChange,
  returnPercentageSwitcher,
  onSwitcherChange,
  valueMethod,
  onValueChange,
  peakValues,
  graphId,
  isPublished,
  userRole,
  isPdf,
  displayHeaderOptions,
  onClick,
  onModifierClick,
  showTitle,
}) => {
  const { user } = useSelector(getUser);
  // eslint-disable-next-line camelcase
  const { people_look_editing_rights } = user;
  const hasViewRights = isViewOnly(people_look_editing_rights);
  const isAdminRole = isAdmin(userRole) || isPartner(userRole);
  const isExectAdmin = isAdmin(userRole);
  const isClientRole = isClient(userRole);
  const getTargetUrl = URL.replace(':graphId', graphId);
  const clientRights = isClientRole && !hasViewRights && !isPdf && !isPublished;

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid
        container
        alignItems="flex-end"
        style={{ paddingLeft: '10px', paddingRight: '10px' }}
        justifyContent="space-between"
      >
        <If condition={showTitle}>
          <Grid item>
            <Typography variant="h5">
              <Box fontWeight="fontWeightBold" component="span">
                {title}
              </Box>
            </Typography>
          </Grid>
        </If>
        <Grid item>
          <If condition={clientRights || (isAdminRole && !isPdf && !isPublished)}>
            <div className="iconsContainer">
              <div onClick={onModifierClick}>
                <SettingsSuggestIcon color="action" fontSize="medium" />
              </div>
              <div onClick={onClick}>
                <BorderColorIcon color="action" fontSize="small" />
              </div>
              <a href={getTargetUrl} target="_blank" rel="noopener noreferrer">
                <If condition={isExectAdmin}>
                  <OpenInNewIcon color="action" fontSize="small" />
                </If>
              </a>
            </div>
          </If>
        </Grid>
      </Grid>
      <If condition={displayHeaderOptions}>
        <Grid container alignItems="center" style={{ marginBottom: '15px' }}>
          <Grid style={{ marginLeft: 15 }}>
            <FormGroup>
              <Grid component="label" container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography style={{ fontSize: 12 }}>Percentages</Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Switch size="small" checked={returnPercentageSwitcher} onChange={onSwitcherChange} />}
                    label={<Typography style={{ fontSize: 12 }}>Integers</Typography>}
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
          <Grid item>
            <FormControl size="small" style={{ minWidth: '100px' }}>
              <InputLabel id="scale">
                <Typography style={{ fontSize: 12 }}>Scale</Typography>
              </InputLabel>
              <Select
                labelId="scale"
                id="scale"
                value={scale}
                style={{ height: 20 }}
                onChange={onScaleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {GRAPHSCALES.map(({ period }) => (
                  <MenuItem value={period} key={period}>
                    {period}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl size="small" style={{ minWidth: '100px', marginLeft: 10 }}>
              <InputLabel id="value">
                <Typography style={{ fontSize: 12 }}>Value method</Typography>
              </InputLabel>
              <Select
                labelId="value"
                id="value"
                style={{ height: 20 }}
                value={valueMethod}
                onChange={onValueChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="average" key="average">
                  Average
                </MenuItem>
                <MenuItem disabled={isEmpty(peakValues)} value="peak" key="peak">
                  Maximum
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </If>
    </Grid>
  );
};

export default GraphHeader;
