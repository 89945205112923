/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Typography, Button, TextField, Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ENDPOINTS } from 'modules/shared/constants';
import { isEmpty } from 'lodash';
import { isLogged } from 'utils';
import axios from 'axios';
import theme from '../forms.module.scss';

const PeakValueForm = ({
  modifierType,
  seriesList,
  modifierId,
  modifierAction,
  onClose,
  graphId,
  isModifierLoading,
  modifierData,
  onCancelModifier,
  legends,
}) => {
  const { name, graph, is_active, modifier_settings, series, order, action } = modifierData || {};
  const isEditMode = modifierType === 'edit';
  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Name required'),
    order: Yup.string().required('*Order required'),
    series: Yup.array().min(1, '*Series required'),
  });

  const [selectedValues, setSelectedValues] = useState(isEditMode ? modifier_settings.values : []);
  const [valuesHaveError, setValuesHaveError] = useState(false);

  const initialCreateValues = {
    graph: graphId,
    name: '',
    order: '',
    action: modifierAction,
    is_active: true,
    series: [],
  };

  const editInitialValues = {
    graph,
    name,
    order,
    action,
    is_active,
    series,
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(value);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode ? editInitialValues : initialCreateValues,
    validationSchema,
    onSubmit: (values) => {
      if (isEmpty(selectedValues)) {
        setValuesHaveError(true);
      } else {
        const getValues = Object.assign(values, {
          modifier_settings: { values: selectedValues },
        });
        const result = JSON.stringify(getValues, (k, val) => (val === '' ? null : val));
        const urlEditModifier = ENDPOINTS.workLook.crudModifier.replace(':modifierId', modifierId);
        const url = ENDPOINTS.workLook.modifiersList.replace(':graphId', graphId);
        const config = {
          method: isEditMode ? 'put' : 'post',
          url: isEditMode ? urlEditModifier : url,
          headers: {
            Authorization: `Token ${isLogged()}`,
            'Content-Type': 'application/json',
          },
          data: result,
        };
        axios(config)
          .then(() => {
            onClose();
            window.location.reload(false);
          })
          .catch((error) => {
            console.log('error?.response', error?.response);
            setErrors({ isError: true, errorMsg: error?.response?.data?.non_field_errors });
          });
        setValuesHaveError(false);
      }
    },
  });

  return (
    <Choose>
      <When condition={isModifierLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>
      <Otherwise>
        <div className={theme.container}>
          <div className={theme.header}>
            <Typography variant="h5" className={theme.title}>
              {isEditMode ? 'Edit Peak Value Modifier' : 'Create Peak Value Modifier'}
            </Typography>
          </div>
          <div className={theme.formWrapper}>
            <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
              <div>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  placeholder="Enter series name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.name}
                </Typography>
              </div>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="is_active"
                      name="is_active"
                      color="primary"
                      checked={formik.values.is_active}
                      value={formik.values.is_active}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Is Active Modifier"
                />
              </Box>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="series">Series</InputLabel>
                  <Select
                    labelId="series"
                    id="series"
                    name="series"
                    label="Series"
                    multiple
                    value={formik.values.series}
                    onChange={formik.handleChange}
                  >
                    {seriesList.map(({ id, name: seriesName }) => (
                      <MenuItem key={id} value={id}>
                        {seriesName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="body2" className={theme.error}>
                  {formik.errors?.series}
                </Typography>
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="selectedFields" style={{ background: '#fff', paddingRight: '5px' }}>
                    Choose peak values
                  </InputLabel>
                  <Select
                    className="MuiSelect-select"
                    labelId="selectedValues"
                    id="selectedValues"
                    name="selectedValues"
                    label="Select Values"
                    multiple
                    value={selectedValues}
                    onChange={handleChange}
                  >
                    {legends.map((label, id) => (
                      <MenuItem key={id} value={label}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <If condition={valuesHaveError}>
                  <Typography variant="body2" className={theme.error}>
                    *Required
                  </Typography>
                </If>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  className={theme.input}
                  type="number"
                  id="order"
                  name="order"
                  label="Order"
                  placeholder="Enter an order"
                  value={formik.values.order}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors?.order}
                </Typography>
              </Box>
              <div className={theme.btnContainer}>
                <Button onClick={() => onCancelModifier()} className={theme.cancelBtn} variant="outlined">
                  Cancel
                </Button>
                <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default PeakValueForm;
