export const ORDER_STATUSES = {
  PD: 'pd',
  AP: 'ap',
  RJ: 'rj',
  CP: 'cp',
  CD: 'cd',
};

export const ORDER_STATUS_LABELS = {
  [ORDER_STATUSES.PD]: 'Pending',
  [ORDER_STATUSES.AP]: 'Approved',
  [ORDER_STATUSES.RJ]: 'Rejected',
  [ORDER_STATUSES.CP]: 'Completed',
  [ORDER_STATUSES.CD]: 'Canceled',
};
