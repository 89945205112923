import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Paper } from '@material-ui/core';

import { URLS } from 'pages/constants';
import { fetchProducts } from 'modules/assetLook/actions';
import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { toRequestBody, makeSearch, filtersToQueryAL } from 'modules/assetLook/utils';
import { BUILDING_ID, CATEGORY_ID, PRODUCT_FIELDS } from 'modules/assetLook/constants';

import { FixedDataTable, TextPlaceholder } from 'modules/shared/components';
// eslint-disable-next-line import/no-cycle
import {
  AutoSizer,
  CellSkeleton,
  ProductListCellHeader as CellHeader,
  ProductListCellBody as CellBody,
} from 'modules/assetLook/components';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';

const columnWidthMap = {
  0: 250,
  2: 85,
};

function trim(el) {
  const result = el
    .trim()
    .replace(/[ ]{2,}/gi, ' ')
    .replace(/\n +/, '\n');
  return result;
}

function ProductListTable() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { buildingId, categoryId } = useLocationSearchParams([BUILDING_ID, CATEGORY_ID]);

  const { id: companyId } = useSelector(getActiveCompany);
  const [productsRequestStatus, products, activeFilters, search, tags] = useAssetLookSelector([
    'products.requestStatus',
    'products.products',
    'filters.activeFilters',
    'filters.search',
    'common.tags',
  ]);

  const filters = toRequestBody(activeFilters, tags);
  const queryFilters = filtersToQueryAL(activeFilters);

  useEffect(() => {
    let isCanceled = false;

    fetchProducts(dispatch, isCanceled, {
      companyId,
      buildingId,
      categoryId,
      filters,
      queryFilters,
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
    // eslint-disable-next-line
  }, [buildingId, categoryId, JSON.stringify(filters || {})]);

  const columnWidth = (columnKey, i) => columnWidthMap[i] || 180;

  const data =
    !isEmpty(products.data) &&
    products.data
      .map((item) => item)
      .filter(
        ({ _name }) =>
          _name.toLowerCase().includes(trim(search.toLowerCase())) ||
          trim(search.toLowerCase()).includes(_name.toLowerCase()),
      );

  const onRowClick = (e, i) =>
    history.push(
      URLS.assetLook.productSummary +
        makeSearch({
          buildingId,
          categoryId,
          productId: data[i][PRODUCT_FIELDS.id],
        }),
      { isBuilding: true },
    );

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Paper elevation={4} style={{ height: '100%', maxHeight: '100%' }}>
          <Choose>
            <When condition={isDefault(productsRequestStatus) || isLoading(productsRequestStatus)}>
              <FixedDataTable
                width={width}
                height={height}
                rows={[1, 2, 3, 4, 5]}
                rowHeight={120}
                columns={[PRODUCT_FIELDS.thumbnail, 2, 3, 4, 5]}
                columnWidth={columnWidth}
                ColumnHeader={CellSkeleton}
                ColumnCell={CellSkeleton}
              />
            </When>

            <When condition={isFailure(productsRequestStatus)}>
              <TextPlaceholder error />
            </When>

            <When condition={isSuccess(productsRequestStatus) && isEmpty(data)}>
              <TextPlaceholder noData />
            </When>

            <Otherwise>
              <FixedDataTable
                width={width}
                height={height}
                rows={data}
                rowHeight={120}
                onRowClick={onRowClick}
                columns={[
                  PRODUCT_FIELDS.thumbnail,
                  PRODUCT_FIELDS.name,
                  PRODUCT_FIELDS.assets,
                  ...Object.keys(data[0].list_data),
                ]}
                columnWidth={columnWidth}
                ColumnHeader={CellHeader}
                ColumnCell={CellBody}
                columnFixed={(key, i) => i < 2}
                columnFlexGrow={(key, i) => (i < 3 ? 0 : 1)}
              />
            </Otherwise>
          </Choose>
        </Paper>
      )}
    </AutoSizer>
  );
}

export default ProductListTable;
