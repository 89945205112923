import React from 'react';
import { Grid } from '@material-ui/core';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';

import { PageHeader } from 'modules/shared/components';
import { ReportDataCards, StackedBarGraph } from 'modules/liveLook/components';
import { isEmpty } from 'lodash';

import { GRAPH_TYPES, pageTypes } from 'modules/liveLook/constants';
import styles from '../../LLPrintablePdfPage.module.scss';
import renderFilters from '../../utils/activeFiltersPdf';
import config from './config';

const BreakoutSpaceUsageBySize = ({ data, databoxes, editableTextReport, activeFilters }) => {
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));

  return (
    <Grid style={{ paddingTop: '20px', paddingLeft: '30px' }} container spacing={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader pdfMode title="Breakout" />
        </Grid>
      </Grid>

      {!shouldRenderFilters && renderFilters(activeFilters)}
      <div className={styles.blockContainer}>
        <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.meetings} />
      </div>

      <Grid style={{ paddingTop: 7 }} xs={12}>
        <StackedBarGraph
          pdfCustomHeader
          title="Group Sizes by Space Type"
          data={data}
          config={config}
          databoxes={databoxes}
          isLiveLook
        />
      </Grid>
      <PDFTextEditorContainer
        hasFilters={shouldRenderFilters}
        graphType={GRAPH_TYPES.STACKED}
        data={[]}
        onSave={() => {}}
        text={editableTextReport}
      />
    </Grid>
  );
};

export default BreakoutSpaceUsageBySize;
