import { cloneDeep } from 'lodash';

const getAmountOfSections = (copiedData) => {
  return copiedData.reduce((acc, { sectionOrder }) => {
    if (!acc.includes(sectionOrder)) {
      return acc.concat(sectionOrder);
    }
    return acc;
  }, []);
};

const filterAndSortOneSection = (copiedData, orderNumber) => {
  const some = copiedData
    .filter(({ sectionOrder }) => orderNumber === sectionOrder)
    .sort((a, b) => a.typeOrder - b.typeOrder);
  const newArray = some.map((item, id) => {
    if (id === 0) return { ...item, shouldRenderSection: true };
    return { ...item, shouldRenderSection: false };
  });
  // console.log('some', newArray); { ...item, shouldRenderSection: true }
  return newArray;
};

const sortReportData = (amountOfSections, copiedData) => {
  return amountOfSections
    .reduce((acc, orderNumber) => {
      return acc.concat(filterAndSortOneSection(copiedData, orderNumber));
    }, [])
    .flat(1)
    .sort((a, b) => a.sectionOrder - b.sectionOrder);
};

const sortPdfReports = (reportData) => {
  const copiedData = cloneDeep(reportData);
  const amountOfSections = getAmountOfSections(copiedData);

  return sortReportData(amountOfSections, copiedData);
};

export default sortPdfReports;
