import { pick } from 'lodash';

const keysToPick = ['databoxes', 'byBuildingAndFloorData'];

const getDesksByBuildingAndFloorData = ({ report: { desks } }) => ({
  dataRequestStatuses: pick(desks.requestStatuses, keysToPick),
  data: pick(desks.data, keysToPick),
  shouldRefetchData: pick(desks.shouldRefetchData, keysToPick),
});

export default getDesksByBuildingAndFloorData;
