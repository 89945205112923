import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const PDFGraphHeader = ({ scale, returnPercentageSwitcher, valueMethod }) => {
  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item>
        <Typography variant="subtitle2">{returnPercentageSwitcher ? 'Integers' : 'Percentages'}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">{scale}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">{valueMethod === 'peak' ? 'Maximum' : 'Average'}</Typography>
      </Grid>
    </Grid>
  );
};

export default PDFGraphHeader;
