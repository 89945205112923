/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { CustomButtonContained } from 'modules/shared/components';

import { ERROR_TEXT } from 'modules/shared/constants';

import LocationSelect from './LocationSelect';
import DatePicker from './DatePicker';

const RequestOrder = ({ classes, formik, onClose, errorCode }) => (
  <Dialog
    open
    onClose={onClose}
    PaperProps={{
      classes: {
        root: classes.container,
      },
    }}
  >
    <DialogContent classes={{ root: classes.content }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocationSelect formik={formik} />
        </Grid>
        <Grid item xs={12}>
          <DatePicker formik={formik} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            classes={{ root: classes.comment }}
            multiline
            rows={4}
            id="comment"
            label="Comment"
            placeholder="Reason for request, etc."
            value={formik.values.comment}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <If condition={errorCode}>
          <Grid item xs={12}>
            <Typography variant="body1" classes={{ root: classes.errorMessage }}>
              {errorCode === 400 ? 'Some assets changed their status or were moved' : ERROR_TEXT}
            </Typography>
          </Grid>
        </If>
      </Grid>
    </DialogContent>
    <DialogActions classes={{ root: classes.actions }}>
      <Button children="Cancel" variant="contained" onClick={onClose} />
      <CustomButtonContained
        label="Apply"
        onClick={() => formik.submitForm()}
        startIcon={formik.isSubmitting && <CircularProgress size={14} classes={{ root: classes.loader }} />}
      />
    </DialogActions>
  </Dialog>
);

export default withStyles((theme) => ({
  container: {
    width: '350px',
  },
  content: {
    padding: '24px',
  },
  comment: {
    width: '100%',
  },
  actions: {
    padding: '0 24px 24px',
  },
  loader: {
    color: 'white',
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}))(RequestOrder);
