import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { URLS } from 'pages/constants';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID } from 'modules/assetLook/constants';

// eslint-disable-next-line import/no-cycle
import AllBuildings from './AllBuildings';

const AllBuildingsContainer = ({ categories }) => {
  const history = useHistory();
  const { state } = useLocation();

  const { buildingId } = useLocationSearchParams([BUILDING_ID]);

  const isActiveBuilding = get(state, 'isBuilding', false) && !buildingId;

  const [expand, setExpand] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isActiveBuilding ? setExpand(true) : setExpand(false);
  }, [buildingId, isActiveBuilding]);

  const _onItemClick = () => {
    setExpand(!expand);

    if (!isActiveBuilding) {
      const url = isEmpty(categories)
        ? URLS.assetLook.productList
        : URLS.assetLook.productList.concat(`?${CATEGORY_ID}=${categories[0].category_id}`);

      history.push(url, { isBuilding: true });
    }
  };

  return (
    <AllBuildings categories={categories} isAccordionOpen={expand && isActiveBuilding} _onItemClick={_onItemClick} />
  );
};

export default AllBuildingsContainer;
