import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00aad2',
      light: '#E0F6FB',
      dark: '#004352',
    },
    secondary: {
      main: '#9a50b7',
      light: '#EEE2F3',
      dark: '#542965',
    },
    error: {
      main: '#F70553',
      light: '#FCE5EC',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1918,
    },
  },
  overrides: {
    MuiTypography: {
      h3: {
        fontSize: '2.7rem',
      },
      subtitle1: {
        fontSize: '1.4rem',
      },
      subtitle2: {
        fontSize: '1rem',
      },
      body2: {
        fontSize: '0.8rem',
      },
    },
  },
});

export default theme;
