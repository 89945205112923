import { combineReducers } from 'redux';

// eslint-disable-next-line import/no-named-default
import { default as services } from './servicesReducer';
import summaryReducer from './summaryReducer';
import combinedReducer from './combinedReducer';
import desksReducer from './desksReducer';
import benchmarkingReducer from './benchmarkingReducer';
import meetingsReducer from './meetingsReducer';
import breakoutReducer from './breakoutReducer';
import heatmapReducer from './heatmapReducer';
import filters from './filters';
import benchmarkingFiltersReducer from './benchmarkingFiltersReducer';

export default combineReducers({
  services,
  summary: summaryReducer,
  combined: combinedReducer,
  desks: desksReducer,
  benchmarking: benchmarkingReducer,
  meetings: meetingsReducer,
  breakout: breakoutReducer,
  heatmap: heatmapReducer,
  filters,
  benchmarkingFilters: benchmarkingFiltersReducer,
});
