export const SERIES_ACTIONS = [
  {
    value: 'sum',
    name: 'Sum',
  },
  {
    value: 'avg',
    name: 'Average',
  },
  {
    value: 'min',
    name: 'Min',
  },
  {
    value: 'max',
    name: 'Max',
  },
  {
    value: 'count',
    name: 'Count',
  },
  {
    value: 'all_sum',
    name: 'Sum of each field',
  },
  {
    value: 'all_avg',
    name: 'Average value of each field',
  },
  {
    value: 'all_min',
    name: 'Min value per each field',
  },
  {
    value: 'all_max',
    name: 'Max value per each field',
  },
  {
    value: 'd_count',
    name: 'Count of each unique value',
  },
  {
    value: 'd_pers',
    name: 'Percentage of each unique value',
  },
  {
    value: 'map',
    name: 'Present data on map',
  },
  {
    value: 'map_count',
    name: 'Present with count of unique data on a map',
  },
  {
    value: 'autotext',
    name: 'Autotext',
  },
  {
    value: 'control_series',
    name: 'Control series',
  },
  {
    value: 'time_series',
    name: 'Time series',
  },
  {
    value: 'present_data',
    name: 'Present data',
  },
  {
    value: 'display_picture',
    name: 'Display picture',
  },
  {
    value: 'four_pictures',
    name: 'Present data with 4 pictures',
  },
];
