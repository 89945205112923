/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
const round = (value) => Math.round(value * 100) / 100;

// TODO: Change algorithm to reduce the number of predefined keys and consider all unknown as 9+
// || move calculation to BE
const setupStackedGraphRowsPeople = (data) => {
  const allRooms = Object.keys(data);

  const allKeys = [
    '1 person',
    '1 people',
    '2 people',
    '3 people',
    '4 people',
    '2-4 people',
    '5 people',
    '6 people',
    '7 people',
    '8 people',
    '5-8 people',
    '9 people',
    '10 people',
    '12 people',
    '11 plus people',
    '15 plus',
    '20 plus',
    '25 plus',
    '30 plus',
    '40 plus',
    '50 plus',
    '60 plus',
    '70 plus',
    '80 plus',
    '90 plus',
    '100 plus',
    'No one',
  ];

  const rooms = allRooms.reduce((allRoomsObjects, roomName) => {
    const roomObjProto = data[roomName];
    const roomValues = allKeys.reduce((roomObj, key) => {
      const valueProto = roomObjProto[key];
      const value = valueProto === undefined ? 0 : valueProto;
      roomObj[key] = value;
      return roomObj;
    }, {});
    allRoomsObjects[roomName] = roomValues;
    return allRoomsObjects;
  }, {});

  const transformedRooms = Object.keys(rooms).reduce((result, currentRoomName) => {
    const currentRoom = rooms[currentRoomName];

    const one = round(currentRoom['1 person'] + currentRoom['1 people']);

    const from2To4 = round(
      currentRoom['2-4 people'] + currentRoom['2 people'] + currentRoom['3 people'] + currentRoom['4 people'],
    );

    const from5To8 = round(
      currentRoom['5-8 people'] +
        currentRoom['5 people'] +
        currentRoom['6 people'] +
        currentRoom['7 people'] +
        currentRoom['8 people'],
    );

    const from9 = round(
      currentRoom['9 people'] +
        currentRoom['10 people'] +
        currentRoom['11 plus people'] +
        currentRoom['12 people'] +
        currentRoom['15 plus'] +
        currentRoom['20 plus'] +
        currentRoom['25 plus'] +
        currentRoom['25 plus'] +
        currentRoom['30 plus'] +
        currentRoom['40 plus'] +
        currentRoom['50 plus'] +
        currentRoom['60 plus'] +
        currentRoom['70 plus'] +
        currentRoom['80 plus'] +
        currentRoom['90 plus'] +
        currentRoom['100 plus'],
    );

    result[currentRoomName] = {
      'No one': currentRoom['No one'],
      '1 Person': one,
      '2-4 People': from2To4,
      '5-8 People': from5To8,
      '>9 People': from9,
    };
    return result;
  }, {});

  const transformedAllKeys = Object.keys(transformedRooms).reduce((allKeys, roomName) => {
    Object.keys(transformedRooms[roomName]).forEach((key) => {
      if (!allKeys.includes(key)) allKeys.push(key);
    });
    return allKeys;
  }, []);

  const transformedGraphData = transformedAllKeys.reduce((data, key) => {
    const values = Object.keys(transformedRooms).map((roomName) => {
      return transformedRooms[roomName][key];
    });
    data[key] = values;
    return data;
  }, {});

  return transformedGraphData;
};

export default setupStackedGraphRowsPeople;
