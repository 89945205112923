import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProducts } from 'modules/assetLook/actions';
import { requestStatuses } from 'modules/shared/constants';
import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID, PRODUCT_ID, PRODUCT_FIELDS } from 'modules/assetLook/constants';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';

function FetchProductsHandler({ children }) {
  const dispatch = useDispatch();

  const { id: companyId } = useSelector(getActiveCompany);
  const { buildingId, categoryId, productId } = useLocationSearchParams([BUILDING_ID, CATEGORY_ID, PRODUCT_ID]);

  const [productsRequestStatus, products] = useAssetLookSelector(['products.requestStatus', 'products.products']);

  useEffect(() => {
    let isCanceled = false;

    // eslint-disable-next-line no-unused-expressions
    isEmpty(products) &&
      fetchProducts(dispatch, isCanceled, {
        companyId,
        buildingId,
        categoryId,
      });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [buildingId, categoryId, companyId, dispatch, productId, products]);

  return (
    <Choose>
      <When condition={[requestStatuses.default, requestStatuses.pending].includes(productsRequestStatus)}>
        <Loader />
      </When>
      <When condition={productsRequestStatus === requestStatuses.failure}>
        <TextPlaceholder error />
      </When>
      <When condition={productsRequestStatus === requestStatuses.success}>
        <Choose>
          <When
            condition={
              !isEmpty(products.data) &&
              products.data.find((product) => product[PRODUCT_FIELDS.id] === Number(productId))
            }
          >
            {children}
          </When>
          <Otherwise>
            <TextPlaceholder notFound />
          </Otherwise>
        </Choose>
      </When>
    </Choose>
  );
}

export default FetchProductsHandler;
