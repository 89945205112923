import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAssets } from 'modules/assetLook/actions';
import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { BUILDING_ID, CATEGORY_ID, PRODUCT_ID, ASSET_ID, ASSET_FIELDS } from 'modules/assetLook/constants';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';

function FetchAssetsHandler({ children }) {
  const dispatch = useDispatch();

  const { id: companyId } = useSelector(getActiveCompany);
  const { buildingId, categoryId, productId, assetId } = useLocationSearchParams([
    BUILDING_ID,
    CATEGORY_ID,
    PRODUCT_ID,
    ASSET_ID,
  ]);

  const [assetsRequestStatus, assets] = useAssetLookSelector(['assets.requestStatus', 'assets.assets']);

  useEffect(() => {
    let isCanceled = false;

    // eslint-disable-next-line no-unused-expressions
    isEmpty(assets) &&
      fetchAssets(dispatch, isCanceled, {
        companyId,
        buildingId,
        categoryId,
        productId,
      });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, buildingId, categoryId, productId, assets, dispatch]);

  return (
    <Choose>
      <When condition={isDefault(assetsRequestStatus) || isLoading(assetsRequestStatus)}>
        <Loader />
      </When>
      <When condition={isFailure(assetsRequestStatus)}>
        <TextPlaceholder error />
      </When>
      <When condition={isSuccess(assetsRequestStatus)}>
        <Choose>
          <When
            condition={!isEmpty(assets.data) && assets.data.find((asset) => asset[ASSET_FIELDS.id] === Number(assetId))}
          >
            {children}
          </When>
          <Otherwise>
            <TextPlaceholder notFound />
          </Otherwise>
        </Choose>
      </When>
    </Choose>
  );
}

export default FetchAssetsHandler;
