import React from 'react';
import { isEmpty } from 'lodash';

import { Paper, Box, Grid, Typography, withStyles } from '@material-ui/core';

import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { BASKET_TABLE_COLUMNS, BASKET_COLUMNS_WIDTH_MAP } from 'modules/assetLook/constants';

import { FixedDataTable, TextPlaceholder, CustomButtonContained } from 'modules/shared/components';
import {
  AutoSizer,
  CellSkeleton,
  BasketCellHeader as CellHeader,
  BasketCellBody as CellBody,
} from 'modules/assetLook/components/index';

const BasketSummaryTable = ({
  classes,
  buildingName,
  onRequestClick,
  requestStatus,
  basketSummary: data,
  errorCode,
}) => (
  <Paper classes={{ root: classes.container }}>
    <Grid container classes={{ root: classes.header }}>
      <Grid item classes={{ root: classes.buildingContainer }}>
        <Typography classes={{ root: classes.buildingName }}>
          <Box fontWeight="fontWeightMedium" component="span">
            {`Building: `}
          </Box>
          <Box component="span">{buildingName}</Box>
        </Typography>
      </Grid>
      <Grid item>
        <CustomButtonContained label="Request" disabled={isEmpty(data)} onClick={onRequestClick} />
      </Grid>
    </Grid>
    <div className={classes.tableWrapper}>
      <AutoSizer>
        {({ width, height }) => (
          <Choose>
            <When condition={isDefault(requestStatus) || isLoading(requestStatus)}>
              <FixedDataTable
                width={width}
                height={height}
                rows={[1, 2, 3, 4]}
                columns={[1, 2, 3, 4, 5, 6]}
                ColumnHeader={CellSkeleton}
                ColumnCell={CellSkeleton}
                columnWidth={(columnKey, i) => BASKET_COLUMNS_WIDTH_MAP[i] || 150}
                columnFlexGrow={(columnKey, i) => (i !== 2 ? 1 : 0)}
              />
            </When>
            <When condition={isSuccess(requestStatus)}>
              <FixedDataTable
                width={width}
                height={height}
                rows={data}
                columns={BASKET_TABLE_COLUMNS}
                ColumnHeader={CellHeader}
                ColumnCell={CellBody}
                columnFixed={() => false}
                columnWidth={(columnKey, i) => BASKET_COLUMNS_WIDTH_MAP[i] || 150}
                columnFlexGrow={(columnKey) => (columnKey !== '_total' ? 1 : 0)}
              />
            </When>
            <When condition={isFailure(requestStatus)}>
              <Choose>
                <When condition={errorCode === 400}>
                  <TextPlaceholder
                    column
                    text="Some assets changed their status or were moved. Please, reload the page to reset the basket."
                  />
                </When>
                <Otherwise>
                  <TextPlaceholder error />
                </Otherwise>
              </Choose>
            </When>
          </Choose>
        )}
      </AutoSizer>
    </div>
  </Paper>
);

export default withStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    padding: '16px',
  },
  buildingContainer: {
    marginRight: 'auto',
  },
  buildingName: {
    lineHeight: '36px',
  },
  tableWrapper: {
    flex: '1 1 1px',
  },
}))(BasketSummaryTable);
