/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import createImagePlugin from '@draft-js-plugins/image';
import createFocusPlugin from '@draft-js-plugins/focus';
import createMentionPlugin from 'draft-js-mention-plugin';
import { composeDecorators } from '@draft-js-plugins/editor';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createCounterPlugin from '@draft-js-plugins/counter';
import { ContentState, convertFromRaw, EditorState, RichUtils, getDefaultKeyBinding } from 'draft-js';

import { isJson } from 'modules/shared/utils';
import { isAdminOrPartner } from 'modules/auth/utils';
import { requestStatuses } from 'modules/shared/constants';
import { useTextEditorActions, useTextMentions } from 'modules/shared/hooks';
import { getUser } from 'modules/auth/selectors';
import { ModalWindow } from 'components';

import { CustomSnackbar } from 'modules/shared/components';
import { ImageComponent } from 'modules/shared/components/TextEditor/components';
import * as TextFieldStorageManager from 'modules/shared/utils/TextFieldStorageManager';

import suggestionComponentStyles from 'modules/shared/components/TextEditor/components/SuggestionComponent/SuggestionComponent.module.scss';

import ConfirmModal from './ConfirmModal';
import TextEditor from './TextEditor';
import TextEditorContext from './TextEditorContext';
import counterStyles from './counterStyles.module.scss';

const theme = {
  counter: counterStyles.counter,
  counterOverLimit: counterStyles.counterOverLimit,
};

/**
 * @fileOverview WYSIWYG text editor. Supports key commands and shows status snackbars on save
 * @param {string} text Input text to show by default
 * @param {string} chartName chart name
 * @param {function} onSave Must return Promise
 * @param {array} data Graph series list that will be transformed into the mentions
 */

const TextEditorContainer = (props) => {
  const isPermitted = true;
  const { chartName, data, text, onSave, isSaveLocallyHide, isImageBtnShow, isTextLimitHide, graphType, isPeopleLook } =
    props;
  const wasSavedLocally = TextFieldStorageManager.getSaveLocalItem(chartName);
  const chooseText = wasSavedLocally ? TextFieldStorageManager.getSavedLocallyItem(chartName) : text;
  const { user } = useSelector(getUser);
  const isSaveToDBPermitted = isAdminOrPartner(user?.role);

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      isJson(chooseText) ? convertFromRaw(JSON.parse(chooseText)) : ContentState.createFromText(chooseText),
    ),
  );

  useEffect(() => {
    const textValue = TextFieldStorageManager.getSavedLocallyItem(chartName) || text;

    const nextState = EditorState.createWithContent(
      isJson(textValue) ? convertFromRaw(JSON.parse(textValue)) : ContentState.createFromText(textValue),
    );
    setEditorState(nextState);
  }, [chartName, text]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRevert = useCallback(() => {
    const nextState = EditorState.createWithContent(
      isJson(text) ? convertFromRaw(JSON.parse(text)) : ContentState.createFromText(text),
    );

    setEditorState(nextState);
  });

  // "key" is used to force the Editor re-render
  // in order to refresh the "draft-js-mention-plugin`s" default decorators
  const [key, setKey] = useState(0);

  const {
    editorRef,
    isEditable,
    saveRequestStatus,
    _onRevert,
    _onSaveClick,
    _onSaveLocally,
    _onConfirmSaveClick,
    _onCancelClick,
    _onTextAreaClick,
    showSuccess,
    showError,
    _onSuccessSnackbarClose,
    _onErrorSnackbarClose,
    showConfirmModal,
    toggleConfirmModal,
  } = useTextEditorActions(
    editorState,
    setEditorState,
    onSave,
    onRevert,
    isPermitted,
    key,
    setKey,
    chartName,
    isSaveLocallyHide,
  );

  const { suggestions, _onSearchChange } = useTextMentions(
    editorState,
    setEditorState,
    data,
    key,
    setKey,
    isEditable,
    chartName,
  );

  // Create plugins
  const { plugins, MentionSuggestions, addImage, AlignmentTool, CustomCounter } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: suggestionComponentStyles,
    });
    const counterPlugin = createCounterPlugin({ theme });
    const resizeablePlugin = createResizeablePlugin();
    const focusPlugin = createFocusPlugin();
    const alignmentPlugin = createAlignmentPlugin();

    const decorator = composeDecorators(resizeablePlugin.decorator, alignmentPlugin.decorator, focusPlugin.decorator);

    const imagePlugin = createImagePlugin({
      decorator,
      imageComponent: ImageComponent,
    });

    return {
      plugins: [mentionPlugin, imagePlugin, resizeablePlugin, alignmentPlugin, focusPlugin, counterPlugin],
      MentionSuggestions: mentionPlugin.MentionSuggestions,
      addImage: imagePlugin.addImage,
      AlignmentTool: alignmentPlugin.AlignmentTool,
      CustomCounter: counterPlugin.CustomCounter,
    };
  }, []);
  // console.log('suggestions', suggestions);

  // eslint-disable-next-line no-shadow
  const _handleKeyCommand = useCallback((command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  }, []);

  // Handle the nested list logic: https://draftjs.org/docs/advanced-topics-nested-lists
  const _mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4);
      if (newEditorState !== editorState) {
        return setEditorState(newEditorState);
      }
    }
    return getDefaultKeyBinding(e);
  };

  return (
    <TextEditorContext.Provider
      value={{ editorState, setEditorState, addImage, isSaveLocallyHide, isImageBtnShow, isTextLimitHide }}
    >
      <ModalWindow
        withToggleButton={false}
        withCloseButton={false}
        onOpen={toggleConfirmModal}
        onClose={toggleConfirmModal}
        isOpen={showConfirmModal}
        isCloseOnOutClick
        modalStyles={{ width: 'fit-content', padding: '60px 70px 50px' }}
      >
        <ConfirmModal onSave={_onConfirmSaveClick} onToggle={toggleConfirmModal} />
      </ModalWindow>

      <TextEditor
        refreshKey={key}
        ref={editorRef}
        isEditable={isEditable}
        isPermitted={isPermitted}
        isPending={saveRequestStatus === requestStatuses.pending}
        isSaveToDBPermitted={isSaveToDBPermitted}
        _mapKeyToEditorCommand={_mapKeyToEditorCommand}
        _handleKeyCommand={_handleKeyCommand}
        _onTextAreaClick={_onTextAreaClick}
        _onSaveClick={_onSaveClick}
        _onRevert={_onRevert}
        _onSaveLocally={_onSaveLocally}
        _onCancelClick={_onCancelClick}
        suggestions={suggestions}
        _onSearchChange={_onSearchChange}
        plugins={plugins}
        MentionSuggestions={MentionSuggestions}
        AlignmentTool={AlignmentTool}
        CustomCounter={CustomCounter}
        graphType={graphType}
        isPeopleLook={isPeopleLook}
      />

      <CustomSnackbar
        open={showSuccess}
        onClose={_onSuccessSnackbarClose}
        severity="success"
        label="Successfully saved"
      />
      <CustomSnackbar open={showError} onClose={_onErrorSnackbarClose} severity="error" label="Failed to save" />
    </TextEditorContext.Provider>
  );
};

export default TextEditorContainer;
