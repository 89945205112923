/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { GRAPH_SCALES } from 'modules/liveLook/constants';
import { format, utcToZonedTime } from 'date-fns-tz';

function getDateOfISOWeek(week, year) {
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 2);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

const itemDateData = ({ scale, date__year, date__month, date__day, date__week, date, dayChoosen }) => {
  switch (scale) {
    case GRAPH_SCALES.day: {
      if (!date__year || !date__month || !date__day) {
        if (date) return new Date(date);
        return;
      }

      const dateRes = new Date(Date.UTC(date__year, date__month - 1, date__day));
      return dateRes;
    }

    case GRAPH_SCALES.week: {
      if (!date__year || !date__week) {
        if (date) return new Date(date);
        return;
      }

      return getDateOfISOWeek(date__week, date__year);
    }

    case GRAPH_SCALES.month: {
      if (!date__year || !date__month) {
        if (date) return new Date(date);
        return;
      }

      return new Date(Date.UTC(date__year, date__month - 1, dayChoosen));
    }

    default: {
      if (date) return new Date(date);
    }
  }
};

const toLondonTimeOccupancy = (res, scale, dayChoosen) => {
  return res.flatMap(({ date, date__year, date__month, date__week, date__day, ...other }) => {
    const itemDate = itemDateData({
      scale,
      date__year,
      date__month,
      date__day,
      date__week,
      date,
      dayChoosen,
    });

    if (!itemDate) return;

    const itemDateLondon = utcToZonedTime(itemDate.toISOString(), 'Europe/London');
    const itemLondonDay = format(itemDateLondon, 'dd MMM yyyy', { timeZone: 'Europe/London' });
    const itemLondonTime = format(itemDateLondon, 'HH:mm', { timeZone: 'Europe/London' });

    return [{ time: itemLondonTime, ...other, day: itemLondonDay }];
  });
};

export default toLondonTimeOccupancy;
