/* eslint-disable camelcase */
import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { List } from '@material-ui/core';

import { getReports } from 'modules/workLook/selectors';
import { isDefault, isLoading, isSuccess } from 'modules/shared/utils';

import { SidebarSkeleton } from 'modules/shared/components';
import ReportItem from './ReportItem';

const ReportList = () => {
  const { reportData, requestStatus } = useSelector(getReports);

  return (
    <List>
      <Choose>
        <When condition={isDefault(requestStatus) || isLoading(requestStatus)}>
          <SidebarSkeleton />
        </When>

        <When condition={isSuccess(requestStatus) && !isEmpty(reportData)}>
          {Object.values(reportData)
            .sort((a, b) => (a.date_created < b.date_created ? 1 : -1))
            .map(({ id, name, sections, is_published }) => {
              return <ReportItem key={id} name={name} is_published={is_published} sections={sections} reportId={id} />;
            })}
        </When>
      </Choose>
    </List>
  );
};

export default ReportList;
