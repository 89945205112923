import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import L from 'leaflet';
import { MapContainer, Marker, TileLayer, Tooltip, useMap } from 'react-leaflet';

import { getPdfMode } from 'modules/spaceLook/selectors';

import './BuildingsMap.scss';

const iconPerson = new L.Icon({
  // eslint-disable-next-line global-require
  iconUrl: require('assets/images/transparent.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: 'buildings-map-marker',
});

const infoItems = ['Building', 'City', 'Capacity', 'Spare capacity'];

function Markers({ data }) {
  const map = useMap();
  return (
    data.length > 0 &&
    data.map(({ coords, building, info }) => {
      const infoList = info.filter((item) => {
        return infoItems.includes(item.key);
      });

      return (
        <Marker
          key={`${coords.join()}-${building}`}
          icon={iconPerson}
          data={coords}
          eventHandlers={{
            click: (e) => {
              map.setView(e.target.options.data);
            },
          }}
          position={coords}
        >
          <Tooltip opacity={1} direction="top" className="buildings-map-tooltip" offset={{ x: -1, y: -11 }}>
            <ul className="buildings-map-tooltip-content">{infoList}</ul>
          </Tooltip>
        </Marker>
      );
    })
  );
}

const BuildingsMap = ({ buildingsData }) => {
  const [viewport, setViewport] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const pdfMode = useSelector(getPdfMode);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const hasLisboaCity = buildingsData.find(({ building }) => {
    if (building === 'Torres De Lisboa') {
      return true;
    }
    return false;
  });

  useEffect(
    () => {
      setViewport({
        center: buildingsData[0].coords,
        zoom: hasLisboaCity ? 4 : 5,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buildingsData],
  );

  if (!isLoaded) return null;

  return (
    <div className={cn('BuildingsMap', { pdfMode })}>
      <div className="buildings-map">
        <MapContainer zoomControl={false} center={viewport.center} zoom={viewport.zoom}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Markers data={buildingsData} />
        </MapContainer>
      </div>
    </div>
  );
};

export default memo(BuildingsMap);
