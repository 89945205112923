/* eslint-disable camelcase */
import React from 'react';
import { useGraphData } from 'modules/workLook/hooks';
import { isEmpty } from 'lodash';
// eslint-disable-next-line import/no-cycle
import ImageGraph from './ImageGraph';
import EmptyGraph from '../EmptyGraph';

const ImageGraphContainer = ({
  id,
  type_of_group,
  activeFilters,
  type,
  isPdf,
  sub_title,
  isPublished,
  is_display_title,
  ...props
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters);

  if (isEmpty(data))
    return (
      <EmptyGraph
        isPublished={isPublished}
        title={props.title}
        graphId={id}
        display={false}
        typeOfGroup={type_of_group}
        type={type}
        showTitle={is_display_title}
        subtitle={sub_title}
        {...props}
      />
    );

  const getImage = data[0]?.data?.image;

  return (
    <ImageGraph
      graphId={id}
      isPublished={isPublished}
      subtitle={sub_title}
      typeOfGroup={type_of_group}
      data={getImage}
      title={data?.title}
      type={type}
      isLoading={isLoading}
      {...props}
      isPdf={isPdf}
      showTitle={is_display_title}
    />
  );
};

export default ImageGraphContainer;
