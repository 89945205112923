/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';
import { isEmpty } from 'lodash';

// eslint-disable-next-line import/no-cycle
import PicturesGraph from './PicturesGraph';
// eslint-disable-next-line import/no-cycle
import EmptyGraph from '../EmptyGraph';

const PicturesGraphContainer = ({
  id,
  type_of_group,
  activeFilters,
  type,
  isPdf,
  isPublished,
  sub_title,
  is_display_title,
  ...props
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters);

  if (isEmpty(data))
    return (
      <EmptyGraph
        display={false}
        isPublished={isPublished}
        title={props.title}
        graphId={id}
        type={type}
        typeOfGroup={type_of_group}
        showTitle={is_display_title}
        subtitle={sub_title}
        {...props}
      />
    );

  return (
    <PicturesGraph
      graphId={id}
      isPublished={isPublished}
      subtitle={sub_title}
      type={type}
      typeOfGroup={type_of_group}
      data={data[0]?.data?.pictures_data}
      pictures={data[0]?.data?.pictures_url}
      title={data?.title}
      isLoading={isLoading}
      {...props}
      isPdf={isPdf}
      showTitle={is_display_title}
    />
  );
};

export default PicturesGraphContainer;
