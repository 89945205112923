import React from 'react';

import { URLS } from 'pages/constants';
import { makeSearch } from 'modules/assetLook/utils';
import { ItemDetailsCard } from 'modules/assetLook/components';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID, PRODUCT_ID, PRODUCT_FIELDS } from 'modules/assetLook/constants';

function ProductDetailsCard({ pageType, disabledNav }) {
  const { buildingId, categoryId, productId } = useLocationSearchParams([BUILDING_ID, CATEGORY_ID, PRODUCT_ID]);

  const [products] = useAssetLookSelector(['products.products']);

  const currentProductIndex = products.data.findIndex((product) => product[PRODUCT_FIELDS.id] === Number(productId));
  const previousProductIndex = currentProductIndex === 0 ? products.data.length - 1 : currentProductIndex - 1;
  const nextProductIndex = currentProductIndex === products.data.length - 1 ? 0 : currentProductIndex + 1;

  const isSingleItem = products.data.length === 1;

  const backUrl = !(isSingleItem || disabledNav) && {
    pathname: URLS.assetLook[pageType],
    search: makeSearch({
      buildingId,
      categoryId,
      productId: products.data[previousProductIndex][PRODUCT_FIELDS.id],
    }),
    state: { isBuilding: true },
  };

  const forwardUrl = !(isSingleItem || disabledNav) && {
    pathname: URLS.assetLook[pageType],
    search: makeSearch({
      buildingId,
      categoryId,
      productId: products.data[nextProductIndex][PRODUCT_FIELDS.id],
    }),
    state: { isBuilding: true },
  };

  return (
    <ItemDetailsCard
      image={products.data[currentProductIndex][PRODUCT_FIELDS.thumbnail]}
      image_medium={products.data[currentProductIndex][PRODUCT_FIELDS.image]}
      title={products.data[currentProductIndex][PRODUCT_FIELDS.name]}
      contentData={products.data[currentProductIndex].details_data}
      backUrl={backUrl}
      forwardUrl={forwardUrl}
    />
  );
}

export default ProductDetailsCard;
