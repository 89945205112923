/* eslint-disable react/no-children-prop */
import React from 'react';
import { Typography, ButtonGroup, Button } from '@material-ui/core';

import filter from 'assets/images/icons/filter-small.svg';

import theme from './FiltersHeader.module.scss';

const FiltersHeader = ({ isApplyDisabled, isResetDisabled, handleFiltersApply, handleFiltersReset }) => (
  <div className={theme.container}>
    <img src={filter} className={theme.icon} alt="icon" />
    <Typography variant="h6">Filter</Typography>
    <ButtonGroup variant="text" className={theme.btnGroup}>
      <Button size="medium" disabled={isApplyDisabled} color="primary" onClick={handleFiltersApply} children="Apply" />
      <Button size="medium" disabled={isResetDisabled} color="primary" onClick={handleFiltersReset} children="Reset" />
    </ButtonGroup>
  </div>
);

export default FiltersHeader;
