import React, { useRef, useCallback } from 'react';
import { Button, CircularProgress, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { ModalBase } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import { onDrop, onInput } from './utils';

import theme from './UploadCsvModal.module.scss';

// eslint-disable-next-line no-shadow
const useStyles = makeStyles((theme) => ({
  dropzonesContainer: { display: 'flex', flexWrap: 'wrap' },
  dropzoneContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '350px' },
  helperText: {
    marginTop: 0,
  },
  dropzoneHeader: { fontWeight: 'bold' },
  dropzone: {
    width: 'calc(100% - 15px)',
    height: '147px',
    paddingTop: '20px',
    margin: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px dashed',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  errorContainer: { height: '65px', overflow: 'auto' },
  errorMessage: {
    color: theme.palette.error.main,
  },
  dropzoneIcon: {
    margin: '0 auto',
    width: '61px',
    height: '40px',
    color: theme.palette.grey[400],
  },
  dropzoneText: {
    fontFamily: theme.typography.fontFamily,
    flexBasis: '100%',
    textAlign: 'center',
  },
}));

const UploadCsvModal = ({ open, formik, isSubmitting, _handleClose, data }) => {
  const classes = useStyles();
  const inputFields = useRef(data);
  const checkOnEmptyData = isEmpty(data);
  const onButtonClick = useCallback((index) => {
    if (!inputFields.current[index]) {
      return;
    }
    inputFields.current[index].click();
  }, []);

  return (
    <ModalBase
      open={open}
      onClose={_handleClose}
      isEmptyData={checkOnEmptyData}
      DialogProps={{
        disableBackdropClick: isSubmitting,
        disableEscapeKeyDown: isSubmitting,
      }}
      onSubmit={formik.handleSubmit}
      title="Upload File"
      firstActionProps={{
        disabled: isSubmitting,
      }}
      secondActionLabel="Save"
      secondActionProps={{
        disabled: isSubmitting,
        startIcon: isSubmitting && <CircularProgress size={14} style={{ color: 'white' }} />,
      }}
    >
      <div className={classes.dropzonesContainer}>
        {data.map(({ id, name }, index) => {
          return (
            <div key={id} className={classes.dropzoneContainer}>
              <div className={theme.helperTextContainer}>
                <FormHelperText component="div" className={classes.helperText} error={!!formik.errors[id]}>
                  <Choose>
                    <When condition={!!formik.errors[id]}>
                      <If condition={Array.isArray(formik.errors[id])}>
                        <div className={classes.errorContainer}>
                          {formik.errors[id].map((errorMessage) => (
                            <p className={classes.errorMessage} key={errorMessage}>
                              {errorMessage}
                            </p>
                          ))}
                        </div>
                        <Else />
                        {formik.errors[id]}
                      </If>
                    </When>
                    <When condition={formik.values.files.some((el) => Number(el.id) === id)}>
                      {formik.values.files.find((el) => Number(el.id) === id).file.name}
                    </When>
                  </Choose>
                </FormHelperText>
              </div>
              <h1 className={classes.dropzoneHeader}>{name}</h1>
              <div
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={(e) => onDrop(e, formik, id)}
                className={classes.dropzone}
                style={!formik.errors[id] ? { borderColor: 'gray' } : { borderColor: 'red' }}
              >
                <CloudUploadOutlinedIcon className={classes.dropzoneIcon} />
                <div className={classes.dropzoneText}>
                  Drag file here or
                  <input
                    type="file"
                    name="uploadedfile"
                    ref={(el) => {
                      inputFields.current[index] = el;
                    }}
                    accept="text/csv"
                    multiple={false}
                    style={{ display: 'none' }}
                    onInput={(e) => onInput(e, formik, id)}
                  />
                  <Button color="primary" component="span" onClick={() => onButtonClick(index)}>
                    BROWSE
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
        <If condition={checkOnEmptyData}>
          <Typography variant="body1" style={{ color: '#F79327' }}>
            You should add base table to continue this step
          </Typography>
        </If>
      </div>
    </ModalBase>
  );
};

export default UploadCsvModal;
