/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useCallback } from 'react';
import qs from 'qs';
import axios from 'axios';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withRouter } from 'react-router-dom';

import { redirectToLogin } from 'utils';
import { REQUESTS, STRONG_PASSWORD } from '_constants';

import { FormButton, ModalWindow } from 'components';
// eslint-disable-next-line import/no-cycle
import { FormikInput, ErrorNotification, FormHeader, ResetPasswordMailNotification } from 'modules/auth/components';

import FormSchema from './FormSchema';

import './NewPasswordForm.scss';

const { base, version, resetConfirm } = REQUESTS;

const UNRELIABLE_PASSWORD_MESSAGE = 'Must contain at least 3 lowercase letters, 2 uppercase letters and 2 numbers';

const NewPasswordForm = ({ title, errorCtaText, successInfoText, successCtaText, history }) => {
  const [pending, setPending] = useState(false);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [modal, setModal] = useState(false);

  const modalRedirect = useCallback(() => redirectToLogin(history), [history]);
  const modalClose = useCallback(() => setModal(false), []);

  const modalContent = success ? (
    <ResetPasswordMailNotification infoText={successInfoText} ctaText={successCtaText} />
  ) : error ? (
    <ErrorNotification ctaText={errorCtaText} />
  ) : null;

  const modalButtonText = success ? 'Go to login' : error ? 'Got it' : null;

  const modalCallBack = success ? modalRedirect : error ? modalClose : null;

  return (
    <div className="NewPasswodForm">
      <FormHeader title={title} />
      <ModalWindow onClose={modalCallBack} isOpen={modal} buttonText={modalButtonText} withToggleButton={false}>
        {modalContent}
      </ModalWindow>
      <Formik
        initialValues={{
          password1: '',
          password2: '',
        }}
        validate={({ password1, password2 }) => {
          const errors = {};

          if (!STRONG_PASSWORD.test(password1)) errors.password1Weak = UNRELIABLE_PASSWORD_MESSAGE;
          if (!STRONG_PASSWORD.test(password2)) errors.password2Weak = UNRELIABLE_PASSWORD_MESSAGE;
          if (password1 !== password2) errors.equality = 'Passwords must be the same';

          return errors;
        }}
        validationSchema={FormSchema}
        onSubmit={({ password1, password2 }) => {
          setPending(true);
          const queryParams = qs.parse(window.location.search.slice(1));
          const data = {
            new_password1: password1,
            new_password2: password2,
            ...queryParams,
          };
          axios({
            method: 'post',
            url: `${base}${version}${resetConfirm}`,
            data,
          })
            .then(() => {
              localStorage.clear();
              setPending(false);
              setSuccess(true);
              setError(false);
              setModal(true);
            })
            .catch(() => {
              setPending(false);
              setError(true);
              setSuccess(false);
              setModal(true);
            });
        }}
      >
        {({ errors, touched }) => {
          const { equality, password1, password2, password1Weak, password2Weak } = errors;
          const { password1: password1Touched, password2: password2Touched } = touched;

          const password1Error = password1Touched && (password1 || password1Weak);
          const password2Error = password2Touched && (password2 || password2Weak);
          const isNotEqualError =
            !password1Error && !password2Error && password1Touched && password2Touched && equality;

          const isHasError = password1Error || password2Error || isNotEqualError;

          const buttonErrorClass = isHasError || pending ? 'disabled' : '';

          return (
            <Form className={cn('new-passwod-form', buttonErrorClass)}>
              <FormikInput name="password1" placeholder="New Password" type="password" />
              {password1Error && <div className="field-error">{password1Error}</div>}
              {isNotEqualError && <div className="field-error">{isNotEqualError}</div>}
              <FormikInput name="password2" placeholder="Confirm Password" type="password" />
              {password2Error && <div className="field-error">{password2Error}</div>}
              {isNotEqualError && <div className="field-error">{isNotEqualError}</div>}
              <FormButton text="Submit" />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

NewPasswordForm.defaultProps = {
  title: 'Please, create new password',
  errorCtaText: 'Please, try again',
  successInfoText: (
    <>
      Your password has been
      <br />
      successfully changed
    </>
  ),
  successCtaText: 'Try to access the system',
};

NewPasswordForm.propTypes = {
  title: PropTypes.string,
  errorCtaText: PropTypes.string,
  successInfoText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  successCtaText: PropTypes.string,
};

export default withRouter(NewPasswordForm);
