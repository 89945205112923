import axios from 'axios';

const getData = (url, token) => {
  return axios({
    method: 'get',
    url,
    headers: { Authorization: `Token ${token}` },
  }).then((response) => response.data);
};

export default getData;
