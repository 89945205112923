import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { TextPlaceholder } from 'modules/shared/components';

import PdfReportsPage from './PdfReportsPage';
import { URLS } from '../../../constants';

const PdfReportsPageContainer = () => {
  const history = useHistory();
  const { pageType } = useParams();

  const defaultPath = URLS.liveLook.pdfReports.replace(':pageType', 'current');

  useEffect(() => {
    if (pageType === ':pageType') {
      history.push(defaultPath);
    }
  }, [defaultPath, history, pageType]);

  return (
    <Choose>
      <When condition={!true}>
        <TextPlaceholder noData />
      </When>
      <Otherwise>
        <PdfReportsPage pageType={pageType} />
      </Otherwise>
    </Choose>
  );
};

export default PdfReportsPageContainer;
