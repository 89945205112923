import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getPdfMode } from 'modules/spaceLook/selectors';
import DataCard from 'modules/shared/components/DataCard';

import config from './config';
import theme from './ReportDataCards.module.scss';

const ReportDataCards = ({ databoxes, pageType, pdf, cropped }) => {
  const pdfMode = useSelector(getPdfMode);

  return (
    <Grid
      className={cn(`${theme.dataCardsContainer}`, { pdf })}
      container
      spacing={pdfMode ? 1 : 2}
      style={cropped ? { maxWidth: '485px' } : null}
    >
      {config.map(({ title, firstValue, secondValue, color }) => (
        <If condition={title[pageType]}>
          <DataCard
            key={title[pageType]}
            pdfMode={pdfMode}
            title={title[pageType]}
            firstValue={databoxes[firstValue.key]}
            firstLabel={firstValue.isPercent ? '%' : ` ${firstValue.label[pageType]}`}
            secondValue={databoxes[secondValue.key]}
            secondLabel={secondValue.isPercent ? '%' : ` ${secondValue.label[pageType]}`}
            noValue={!Number.isFinite(databoxes[firstValue.key]) || !Number.isFinite(databoxes[secondValue.key])}
            color={color}
          />
        </If>
      ))}
    </Grid>
  );
};

ReportDataCards.propTypes = {
  databoxes: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
};

export default ReportDataCards;
