import {
  DataCards,
  DonutChart,
  BarChart,
  RadarChart,
  TextSummary,
  StackedBarSingle,
  StackedBarMultiple,
  DataTable,
  GraphMap,
  LargeDataCard,
  AutotextCard,
  WordCloud,
  EditableTable,
  ControlDataCard,
  TimeGraph,
  PicturesGraph,
  ImageGraph,
  SlidingGraph,
} from 'modules/workLook/components';

const config = {
  positions: {
    1: 'topLeft',
    2: 'topRight',
    3: 'bottomLeft',
    4: 'bottomRight',
    5: 'top',
    6: 'bottom',
    7: 'left',
    8: 'right',
    9: 'wholePage',
    10: 'leftDashboard',
    11: 'rightDashboard',
  },
  posValidation: {
    1: [1, 5, 7, 9],
    2: [2, 5, 8, 9],
    3: [3, 6, 7, 9],
    4: [4, 6, 8, 9],
    5: [5, 1, 2, 7, 8, 9],
    6: [6, 3, 4, 7, 8, 9],
    7: [7, 1, 3, 5, 6, 9],
    8: [8, 2, 4, 5, 6, 9],
    9: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  graphList: {
    1: DataCards,
    2: DonutChart,
    3: BarChart,
    4: StackedBarSingle,
    5: StackedBarMultiple,
    6: RadarChart,
    7: DataTable,
    8: TextSummary,
    9: BarChart,
    11: StackedBarMultiple,
    12: GraphMap,
    13: LargeDataCard,
    14: AutotextCard,
    15: WordCloud,
    16: EditableTable,
    17: ControlDataCard,
    18: TimeGraph,
    19: PicturesGraph,
    20: ImageGraph,
    21: SlidingGraph,
  },
};

export default config;

export const widgetStylesConfig = {
  positions: {
    3: 'topLeft',
    4: 'topRight',
    5: 'bottomLeft',
    6: 'bottomRight',
    7: 'top',
    8: 'bottom',
    9: 'left',
    10: 'right',
  },
  posValidation: {
    3: [3, 7, 9],
    4: [4, 10, 7],
    5: [5, 8, 9],
    6: [6, 8, 10],
    7: [7, 3, 4, 9, 10],
    8: [8, 5, 6, 9, 10],
    9: [9, 3, 5, 7, 8],
    10: [10, 7, 8, 4, 6],
  },
};
