import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';

const formatTime = (time) => DateTime.fromISO(time).toFormat('dd LLL yyyy');

const toDeskByWeekGraphData = (data, returnPercentageSwitcher) => {
  if (isEmpty(data)) return false;
  const initialData = {
    labels: [],
    avg: [],
    max: [],
  };
  const results = data.reduce((acc, item) => {
    const getItem = (isAvg) => {
      const fieldName = `${returnPercentageSwitcher ? '' : 'percentage_'}${isAvg ? 'avg_occupancy' : 'occupancy'}`;
      return parseFloat(item[fieldName].toFixed(1));
    };
    return {
      labels: acc.labels.concat(formatTime(item.date)),
      avg: acc.avg.concat(getItem(true)),
      max: acc.max.concat(getItem(false)),
    };
  }, initialData);
  return {
    labels: results.labels,
    datasets: [
      {
        label: 'Avg Occupancy',
        backgroundColor: '#34BBDB',
        data: results.avg,
      },
      {
        label: 'Max Occupancy',
        backgroundColor: '#0D6F85',
        data: results.max,
      },
    ],
  };
};
export default toDeskByWeekGraphData;
