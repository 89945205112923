import axios from 'axios';

import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_MEETINGS_OBSERVED_SIZES_DATA = createAsyncAction(createAction('FETCH_MEETINGS_OBSERVED_SIZES_DATA'));

const fetchMeetingsObservedSizesData = (dispatch, id, params) => {
  dispatch(FETCH_MEETINGS_OBSERVED_SIZES_DATA.pending());

  const token = isLogged();
  const url = ENDPOINTS.meetingsObservedSizes.replace(':id', id);
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  axios
    .get(`${url}&${params}`, config)
    .then(({ data }) => dispatch(FETCH_MEETINGS_OBSERVED_SIZES_DATA.success(data)))
    .catch((err) => dispatch(FETCH_MEETINGS_OBSERVED_SIZES_DATA.failure({ reportError: err.response })));
};

export default fetchMeetingsObservedSizesData;
