import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import './AutotextCard.scss';
import { Typography, makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';

const useStyles = makeStyles((theme) => ({
  buildingName: {
    color: theme.palette.primary.dark,
    textAlign: 'center',
    marginBottom: 0,
    fontSize: '20px',
  },
  address: {
    textAlign: 'center',
    marginBottom: 0,
    color: '#9e9e9e',
  },
  info: {
    textAlign: 'left',
    marginBottom: 0,
    color: '#000',
    fontSize: '14px',
  },
}));

const AutotextCard = ({
  data,
  subtitle,
  title,
  isLoading,
  typeOfGroup,
  graphId,
  isPdf,
  position,
  isPublished,
  isModal,
  type,
}) => {
  const classes = useStyles();
  const checkPosition = ['7', '8', '9'].includes(position);
  const checkDashboardPosition = ['10', '11'].includes(position);
  const getGraphHeight = isPdf
    ? checkPosition
      ? '660px'
      : '340px'
    : checkPosition
    ? '720px'
    : checkDashboardPosition
    ? '500px'
    : '360px';

  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  const getData = typeof data?.[0] === 'object' ? [] : data;

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && isEmpty(data)}>
        <Paper className="container" elevation={2}>
          <GraphHeader
            display={false}
            graphId={graphId}
            title={title}
            subTitle={subtitle}
            isPdf={isPdf}
            isPublished={isPublished}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <div className="cardContainer" style={{ maxHeight: getGraphHeight }}>
          <div className="infoContainer">
            <div className="infoWrapper">
              <div className="buildingName">
                <GraphHeader
                  display={false}
                  graphId={graphId}
                  isPdf={isPdf}
                  onClick={onEditClick}
                  onModifierClick={onModifierClick}
                  isPublished={isPublished}
                />
                <Typography variant="h4" component="span" classes={{ root: classes.buildingName }}>
                  {title}
                </Typography>
              </div>
              <div className="address">
                <Typography variant="body1" component="span" classes={{ root: classes.address }}>
                  {subtitle}
                </Typography>
              </div>
              <div className="info">
                {getData?.map((info, id) => (
                  <Typography key={id} variant="body1" component="span" classes={{ root: classes.additionalInfo }}>
                    {info}
                  </Typography>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default AutotextCard;
