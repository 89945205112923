/* eslint-disable jsx-control-statements/jsx-use-if-tag */
/* eslint-disable react/no-children-prop */
/* eslint-disable camelcase */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'modules/auth/selectors';
import { API, isAdmin, isClient, isCreateEditReportTempalte, isPartner, isViewOnly } from 'modules/shared/utils';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PublishIcon from '@material-ui/icons/Publish';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { showModal } from 'modules/modalsSystem/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { ENDPOINTS } from 'modules/shared/constants';
import { SET_CSV_DATA, SET_REPORT_ID, SET_SECTION_DATA } from 'modules/workLook/actions';
// eslint-disable-next-line import/no-cycle
import { UploadCsvModal } from 'modules/workLook/components';
import { ConfirmationDialog } from 'modules/shared/components';
import StandardReportBtn from './StandardReportBtn';
import theme from './TitleActionButtons.module.scss';

const TitleActionButtons = ({
  isPermitted,
  csvModalOpen,
  _handleCsvModalOpen,
  _handleCsvModalClose,
  _onAddAllClick,
  deleteModalOpen,
  isDeleteSubmitting,
  _handleDeleteModalOpen,
  _handleDeleteModalClose,
  _handleDeleteSubmit,
  is_published,
  isPublishedSubmitting,
  _onPublishClick,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(getUser);
  const { people_look_editing_rights } = user;

  const isAdminRole = isAdmin(user?.role) || isPartner(user?.role);
  const isClientRole = isClient(user?.role);
  const { reportId } = useParams();

  const hasEditRightsTemplate =
    isAdminRole || (!isAdminRole && !is_published && isCreateEditReportTempalte(people_look_editing_rights));

  const hasViewRights = isViewOnly(people_look_editing_rights);

  const clientRights = isClientRole && !hasViewRights;

  const onCreateSectionClick = () => {
    dispatch(SET_REPORT_ID(reportId));
    dispatch(SET_SECTION_DATA({ action: 'create' }));
    dispatch(showModal(modalCodes.WL_CREATE_SECTION_MODAL));
  };

  const onCreateTemplateClick = () => {
    dispatch(SET_REPORT_ID(reportId));
    dispatch(showModal(modalCodes.WL_CREATE_TEMPLATE_MODAL));
  };

  const onEditReportsFields = () => {
    const url = ENDPOINTS.workLook.editFields.replace(':reportId', reportId);
    API.get(url).then(({ data }) => {
      dispatch(SET_REPORT_ID(reportId));
      dispatch(SET_CSV_DATA(data));
      dispatch(showModal(modalCodes.WL_EDIT_REPORTS_FIELDS));
    });
  };

  return (
    <>
      <Grid container className={theme.container} spacing={1}>
        <Grid item>
          <Button startIcon={<LibraryAddIcon />} children="ADD ALL" onClick={_onAddAllClick} />
        </Grid>
        <Grid item>
          <StandardReportBtn />
        </Grid>
        <If condition={isPermitted}>
          <Grid item>
            <Button
              children={is_published ? 'EDIT' : 'PUBLISH'}
              startIcon={
                isPublishedSubmitting ? (
                  <CircularProgress size={14} style={{ color: 'inherit' }} />
                ) : is_published ? (
                  <EditIcon />
                ) : (
                  <VisibilityIcon />
                )
              }
              onClick={_onPublishClick}
            />
          </Grid>
        </If>
        <If condition={!is_published}>
          <If condition={isAdminRole || clientRights}>
            <Grid item>
              <Button startIcon={<PublishIcon />} children="UPLOAD CSV" onClick={_handleCsvModalOpen} />
            </Grid>
          </If>
          <If condition={(isAdminRole && !is_published) || clientRights}>
            <If condition={hasEditRightsTemplate}>
              <Grid item>
                <Button
                  startIcon={<ContentCopyIcon />}
                  children="CREATE TEMPLATE"
                  onClick={() => onCreateTemplateClick()}
                />
              </Grid>
            </If>
            <If condition={clientRights || isAdminRole}>
              <Grid item>
                <Button
                  startIcon={<AppRegistrationIcon />}
                  children="EDIT REPORT FIELDS"
                  onClick={() => onEditReportsFields()}
                />
              </Grid>
            </If>
          </If>
          <If condition={isAdminRole || clientRights}>
            <Grid item>
              <Button startIcon={<DeleteIcon />} children="DELETE" onClick={_handleDeleteModalOpen} />
            </Grid>
          </If>
        </If>
      </Grid>

      <If condition={csvModalOpen && !is_published}>
        <UploadCsvModal open={csvModalOpen} _handleClose={_handleCsvModalClose} />
      </If>

      <If condition={deleteModalOpen && !is_published}>
        <ConfirmationDialog
          open={deleteModalOpen}
          _onClose={_handleDeleteModalClose}
          DialogProps={{
            disableBackdropClick: isDeleteSubmitting,
            disableEscapeKeyDown: isDeleteSubmitting,
          }}
          title="Delete current report?"
          text="This operation can't be undone"
          FirstActionProps={{
            disabled: isDeleteSubmitting,
          }}
          _onSecondActionClick={_handleDeleteSubmit}
          SecondActionProps={{
            startIcon: isDeleteSubmitting && <CircularProgress size={14} style={{ color: 'white' }} />,
            disabled: isDeleteSubmitting,
          }}
        />
      </If>
    </>
  );
};

export default TitleActionButtons;
