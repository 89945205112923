/* eslint-disable consistent-return */
/* eslint-disable react/no-children-prop */
/* eslint-disable array-callback-return */
import React from 'react';
import { isEmpty } from 'lodash';

import { Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';
import FilterListIcon from '@material-ui/icons/FilterList';

import { FilterButton } from 'modules/shared/components';
// eslint-disable-next-line import/no-cycle
import { ActiveFilterList } from 'modules/liveLook/components';

const FilterList = ({ disabled, initFilters, activeFilters, onFilterButtonClick, onResetClick }) => {
  return (
    <Grid container spacing={2}>
      {initFilters?.map(({ field }, i) => {
        const activeFilter = activeFilters.find((filter) => filter.field === field);
        const isActive = activeFilter && !isEmpty(activeFilter.values);

        return (
          <Grid item key={i}>
            <FilterButton
              disabled={disabled}
              isActive={isActive}
              // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
              startIcon={isActive ? <DoneIcon size="small" /> : null}
              onClick={() => onFilterButtonClick(field)}
              children={field}
            />
          </Grid>
        );
      })}
      <Grid item>
        <FilterButton
          disabled={disabled}
          isActive
          startIcon={<CachedIcon size="small" />}
          onClick={onResetClick}
          children="Reset"
        />
      </Grid>
      {activeFilters?.map((field, i) => {
        const filterItem = field.values.length > 0 ? `${field.field}: ${field.values.join(', ')}` : null;
        if (filterItem) {
          return (
            <Grid item key={i}>
              <ActiveFilterList
                disabled={disabled}
                isActive={false}
                startIcon={<FilterListIcon size="small" />}
                children={filterItem}
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

export default FilterList;
