/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Typography, Button } from '@material-ui/core';
import { getUser, getCompanies } from 'modules/auth/selectors';
import { showSecondModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@material-ui/icons/Close';
import theme from './companySettingsModalContainer.module.scss';

const CompanySettingsModalContainer = ({ onClose }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(getUser);
  const { companies } = useSelector(getCompanies);

  const { role } = user;

  const onEditClick = (companyId) => {
    dispatch(showSecondModal(modalCodes.EDIT_COMPANY, { companyId }));
  };

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Typography variant="h5" className={theme.title}>
            Company Settings
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={theme.tableContainer}>
          <table className={theme.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Company Name</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {companies?.map((row) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>{row.name}</td>
                  <td>
                    <IconButton onClick={() => onEditClick(row.id)} variant="outlined" color="primary" size="small">
                      <EditIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompanySettingsModalContainer;
