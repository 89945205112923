import { API, createAsyncAction } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import createAction from 'modules/shared/actions/_createAction';

export const SET_PROPERTY_NAMES_MAP = createAsyncAction(createAction('SET_PROPERTY_NAMES_MAP'));

const setPropertyNamesMap = (dispatch) => {
  dispatch(SET_PROPERTY_NAMES_MAP.pending());

  API.get(ENDPOINTS.shared.propertyNamesMap)
    .then(({ data }) => dispatch(SET_PROPERTY_NAMES_MAP.success(data)))
    .catch(() => dispatch(SET_PROPERTY_NAMES_MAP.failure()));
};

export default setPropertyNamesMap;
