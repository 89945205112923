/* eslint-disable no-param-reassign */
const dontNeedKeys = [
  'hour',
  'date',
  'breakout_occupancy',
  'desk_occupancy',
  'meet_occupancy',
  'free_graph_space',
  'total_desk',
  'total_meet',
  'total_seats',
];
let counter = 0;

const setStackedGraphRowsData = (data, max) => {
  const categoryKeys = Object.keys(data[0]).filter((key) => !dontNeedKeys.includes(key));

  return data.reduce((result, date) => {
    const dateName = date.date;

    if (result[dateName]) {
      categoryKeys.forEach((categoryKey, idx) => {
        const value = date[categoryKey];
        counter += value;
        result[dateName][categoryKey].push(value);

        if (!idx) result[dateName].title = dateName;

        if (idx === categoryKeys.length - 1) {
          result[dateName].labels.push(date.hour);
          const rest = max - counter;

          if (result[dateName].rest) {
            result[dateName].rest.push(rest);
          } else {
            result[dateName].rest = [rest];
          }
          counter = 0;
        }
      });
    } else {
      result[dateName] = {};
      categoryKeys.forEach((categoryKey, idx) => {
        const value = date[categoryKey];
        counter += value;
        result[dateName][categoryKey] = [value];

        if (idx === categoryKeys.length - 1) {
          result[dateName].labels = [date.hour];
          const rest = max - counter;

          if (result[dateName].rest) {
            result[dateName].rest.push(rest);
          } else {
            result[dateName].rest = [rest];
          }
          counter = 0;
        }
      });
    }

    return result;
  }, {});
};

export default setStackedGraphRowsData;
