/* eslint-disable camelcase */
import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';

import { useEditGraphClick, useGraphClick, useModifierGraph } from 'modules/workLook/hooks';
import theme from './SlidingGraph.module.scss';
import GradientBar from './components/GradientBar/GradientBar';
import InnerHeader from './components/InnerHeader/InnerHeader';

const SlidingGraph = ({
  title,
  sub_title,
  isLoading,
  data,
  is_display_title: showTitle,
  isModal,
  isPublished,
  modalData,
  graphId,
  typeOfGroup,
  maxAxis,
  minAxis,
  position,
  type,
  isPdf,
  is_display_legend,
}) => {
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onClick } = useGraphClick(isModal, modalData);
  const displayLegends = !isEmpty(data?.additional_data);
  const getValue = data[0]?.data || data?.series_data[0]?.data;
  const showInfo = minAxis === null || maxAxis === null;

  const redTitle = data?.additional_data?.redTitle || '';
  const greenTitle = data?.additional_data?.greenTitle || '';

  const getStep = ((maxAxis - minAxis) / 5).toFixed(1);

  const scales = [
    minAxis,
    (1 * getStep + minAxis).toFixed(1),
    (2 * getStep + minAxis).toFixed(1),
    (3 * getStep + minAxis).toFixed(1),
    (4 * getStep + minAxis).toFixed(1),
    maxAxis,
  ];
  const smallStyles = ['1', '2', '3', '4', '5', '6'].includes(position);

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && !data}>
        <Paper elevation={2} className={theme.container}>
          <GraphHeader
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={sub_title}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper elevation={2} className={isModal ? theme.container : theme.containerModal} onClick={onClick}>
          <Grid container spacing={2} direction="column" className={theme.gridContainer}>
            <Grid item>
              <GraphHeader
                showTitle
                graphId={graphId}
                title={title}
                subTitle={sub_title}
                isPdf={isPdf}
                onClick={onEditClick}
                onModifierClick={onModifierClick}
                isPublished={isPublished}
              />
            </Grid>
            <Grid item className={theme.innerContainer}>
              <div className={theme.wrapper}>
                <If condition={displayLegends}>
                  <InnerHeader
                    showHeader={is_display_legend}
                    smallStyles={smallStyles}
                    redTitle={redTitle}
                    greenTitle={greenTitle}
                  />
                </If>
                <GradientBar
                  smallStyles={smallStyles}
                  scales={scales}
                  value={getValue}
                  minAxis={minAxis}
                  maxAxis={maxAxis}
                />
                <If condition={showInfo}>
                  <Typography variant="body2" className={theme.textInfo}>
                    For the correct value displaying on the scale, it is necessary to add the min and max axis limits
                  </Typography>
                </If>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Otherwise>
    </Choose>
  );
};

export default SlidingGraph;
