export const GRAPHS_TYPE_LIST = [
  {
    value: '1',
    name: 'Data cards',
    dataType: 'dataCards',
  },
  {
    value: '2',
    name: 'Donat chart',
    dataType: 'barChart',
  },
  {
    value: '3',
    name: 'Bar chart',
    dataType: 'barChart',
  },
  {
    value: '4',
    name: 'Stacked Bar Single',
    dataType: 'barChart',
  },
  {
    value: '5',
    name: 'Stacked Bar Multiple',
    dataType: 'barChart',
  },
  {
    value: '6',
    name: 'Radar or Polar graph',
    dataType: 'barChart',
  },
  {
    value: '7',
    name: 'Data table',
    dataType: 'dataTable',
  },
  {
    value: '8',
    name: 'Text summary',
    dataType: 'textSummary',
  },
  {
    value: '9',
    name: 'Column chart',
    dataType: 'barChart',
  },
  // {
  //   value: '10',
  //   name: 'Guage chart',
  // },
  {
    value: '11',
    name: 'Likert chart',
    dataType: 'barChart',
  },
  {
    value: '12',
    name: 'Map',
    dataType: 'mapData',
  },
  {
    value: '13',
    name: 'Large data card',
    dataType: 'largeDataCard',
  },
  {
    value: '14',
    name: 'Autotext',
    dataType: 'autotextData',
  },
  {
    value: '15',
    name: 'Word Cloud',
    dataType: 'barChart',
  },
  {
    value: '16',
    name: 'Editable table',
    dataType: 'dataTable',
  },
  {
    value: '17',
    name: 'Control data card',
    dataType: 'controlData',
  },
  {
    value: '18',
    name: 'Time graph',
    dataType: 'timeData',
  },

  {
    value: '19',
    name: '4 pictures graph',
    dataType: 'fourPicData',
  },
  {
    value: '20',
    name: 'Image graph',
    dataType: 'imageData',
  },
  {
    value: '21',
    name: 'Sliding scale graph',
    dataType: 'slidingScale',
  },
];

export const GRAPH_POSITIONS = [
  {
    value: '1',
    name: 'Top Left',
  },
  {
    value: '2',
    name: 'Top Right',
  },
  {
    value: '3',
    name: 'Bottom Left',
  },
  {
    value: '4',
    name: 'Bottom Right',
  },
  {
    value: '5',
    name: 'Cover both top positions',
  },
  {
    value: '6',
    name: 'Cover both bottom positions',
  },
  {
    value: '7',
    name: 'Cover both left positions',
  },
  {
    value: '8',
    name: 'Cover both right positions',
  },
  {
    value: '9',
    name: 'Cover all 4 positions',
  },
];

export const DASHBOARD_POSITIONS = [
  {
    value: '10',
    name: 'Left dashboard sections',
  },
  {
    value: '11',
    name: 'Right dashboard sections',
  },
];

export const ERROR_TYPES =
  'If you change this type of graph to the selected one, the series data will be automatically deleted';
