/* eslint-disable consistent-return */
import { createReducer } from '@reduxjs/toolkit';

import { FILTERS, filtersPageTypes, weekday } from 'modules/liveLook/constants';
import { makeDefaultActiveFilters } from 'modules/shared/utils';
import { APPLY_FILTERS, FETCH_COMMON_DATA, RESET_FILTERS, RESET_STATE } from 'modules/liveLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  initBreakFilters: null,
  activeBreakFilters: null,
};

export default createReducer(initState, {
  [FETCH_COMMON_DATA.success]: (state, { payload }) => {
    if (!payload) {
      // eslint-disable-next-line no-console
      console.error('FETCH_COMMON_DATA_TMP::No payload', payload);
      return;
    }

    const { buildings, filters } = payload;
    const breakFilters = filters[filtersPageTypes.breakout];

    const initBreakFilters = [weekday];

    initBreakFilters.push({
      field: FILTERS.floor,
      values: breakFilters.floor,
    });

    initBreakFilters.push({
      field: FILTERS.subtype,
      values: breakFilters.subtype,
    });

    initBreakFilters.push({
      field: FILTERS.division,
      values: breakFilters.division,
    });

    initBreakFilters.push({
      field: FILTERS.neighbourhood,
      values: breakFilters.neighbourhood,
    });

    initBreakFilters.push({
      field: FILTERS.building_id,
      // eslint-disable-next-line camelcase
      values: buildings.map(({ building__property_name, building_id }) => ({
        label: building__property_name,
        value: building_id,
      })),
    });

    return {
      ...state,
      initBreakFilters,
      activeBreakFilters: makeDefaultActiveFilters(initBreakFilters),
    };
  },
  [FETCH_COMMON_DATA.failure]: (state) => {
    return state;
  },
  [APPLY_FILTERS]: (state, { payload: { field, values } }) => ({
    ...state,
    activeBreakFilters: state.activeBreakFilters.map((filter) =>
      filter.field === field ? { field, values } : { ...filter },
    ),
  }),
  [RESET_FILTERS]: (state) => ({
    ...state,
    activeBreakFilters: makeDefaultActiveFilters(state.initBreakFilters),
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
