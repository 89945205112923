import React from 'react';
import { Box, Typography } from '@material-ui/core';

import PageRow from './PageRow';

import theme from './TableOfContents.module.scss';

const TableOfContents = ({ basket, _onPageClick }) =>
  basket.map(({ id: sectionId, pages, title }) => (
    <div key={sectionId} className={theme.sectionWrapper}>
      <Typography variant="h5" className={theme.sectionName}>
        <Box fontWeight="fontWeightMedium" component="span">
          {title}
        </Box>
      </Typography>
      {pages.map(({ internalId, ...page }) => (
        <PageRow key={internalId} _onPageClick={() => _onPageClick(sectionId, internalId)} {...page} />
      ))}
    </div>
  ));

export default TableOfContents;
