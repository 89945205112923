import React, { useEffect, useMemo, useState } from 'react';
import createImagePlugin from '@draft-js-plugins/image';
import createFocusPlugin from '@draft-js-plugins/focus';
import createMentionPlugin from 'draft-js-mention-plugin';
import { composeDecorators } from '@draft-js-plugins/editor';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import { ContentState, convertFromRaw, EditorState } from 'draft-js';

import { isJson } from 'modules/shared/utils';
import { usePDFTextMention } from 'modules/shared/hooks/pdfTextEditorActions';

import { ImageComponent } from 'modules/shared/components/TextEditor/components';
import * as TextFieldStorageManager from 'modules/shared/utils/TextFieldStorageManager';

import suggestionComponentStyles from 'modules/shared/components/TextEditor/components/SuggestionComponent/SuggestionComponent.module.scss';

import TextEditor from './TextEditor';
import TextEditorContext from './TextEditorContext';

// eslint-disable-next-line react/prop-types
const PDFTextEditorContainer = ({ chartName, data, text, isKeyFindings, isKeyFindingsDesk, hasFilters, graphType }) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(isJson(text) ? convertFromRaw(JSON.parse(text)) : ContentState.createFromText(text)),
  );

  useEffect(() => {
    const textValue = TextFieldStorageManager.getItem(chartName) || text;

    const nextState = EditorState.createWithContent(
      isJson(textValue) ? convertFromRaw(JSON.parse(textValue)) : ContentState.createFromText(textValue),
    );

    setEditorState(nextState);
  }, [chartName, text]);

  // "key" is used to force the Editor re-render
  // in order to refresh the "draft-js-mention-plugin`s" default decorators
  const [key, setKey] = useState(0);

  const { suggestions } = usePDFTextMention(editorState, setEditorState, data, key, setKey, chartName);

  // Create plugins
  const { plugins, MentionSuggestions, addImage, AlignmentTool } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: suggestionComponentStyles,
    });

    const resizeablePlugin = createResizeablePlugin();
    const focusPlugin = createFocusPlugin();
    const alignmentPlugin = createAlignmentPlugin();

    const decorator = composeDecorators(resizeablePlugin.decorator, alignmentPlugin.decorator, focusPlugin.decorator);

    const imagePlugin = createImagePlugin({
      decorator,
      imageComponent: ImageComponent,
    });

    return {
      plugins: [mentionPlugin, imagePlugin, resizeablePlugin, alignmentPlugin, focusPlugin],
      MentionSuggestions: mentionPlugin.MentionSuggestions,
      addImage: imagePlugin.addImage,
      AlignmentTool: alignmentPlugin.AlignmentTool,
    };
  }, []);

  return (
    <TextEditorContext.Provider value={{ editorState, setEditorState, addImage }}>
      <TextEditor
        isPdf
        graphType={graphType}
        isKeyFindingsDesk={isKeyFindingsDesk}
        hasFilters={hasFilters}
        isKeyFindings={isKeyFindings}
        refreshKey={key}
        isEditable={false}
        suggestions={suggestions}
        plugins={plugins}
        MentionSuggestions={MentionSuggestions}
        AlignmentTool={AlignmentTool}
      />
    </TextEditorContext.Provider>
  );
};

export default PDFTextEditorContainer;
