import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';

import { getReports } from 'modules/workLook/selectors';
import { SET_REPORT_FIELDS, SET_REPORT_PICTURES } from 'modules/workLook/actions';

import { TextPlaceholder } from 'modules/shared/components';

import SectionPage from './SectionPage';

const SectionPageContainer = () => {
  const dispatch = useDispatch();
  const { reportId, sectionId, pageId } = useParams();
  const { reportData } = useSelector(getReports);

  useEffect(() => {
    const urlFields = ENDPOINTS.workLook.reportFields.replace(':reportId', reportId);
    const urlPictures = ENDPOINTS.workLook.reportPictures;

    API.get(urlFields)
      .then(({ data }) => {
        dispatch(SET_REPORT_FIELDS(data));
      })
      .catch((error) => {
        console.log(error);
      });
    API.get(urlPictures)
      .then(({ data }) => {
        dispatch(SET_REPORT_PICTURES(data));
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeReport = reportData[reportId];
  const activeSection = activeReport && activeReport.sections[sectionId];

  return (
    <Choose>
      <When condition={!activeReport || !activeSection}>
        <TextPlaceholder notFound />
      </When>
      <Otherwise>
        <SectionPage
          isPublished={activeReport.is_published}
          icon={activeReport.icon}
          key={`${reportId}-${sectionId}-${pageId}`}
          {...activeSection}
          activePage={activeSection.pages[pageId]}
          initFilters={activeReport.initial_filters}
          activeFilters={activeSection.pages[pageId].activeFilters}
        />
      </Otherwise>
    </Choose>
  );
};

export default SectionPageContainer;
