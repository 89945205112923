/* eslint-disable camelcase */
import React, { useState, useRef } from 'react';
import { Typography, Button, TextField, Box, Checkbox, FormControlLabel, Avatar } from '@material-ui/core';
// import { Skeleton } from '@material-ui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ENDPOINTS } from 'modules/shared/constants';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { isLogged } from 'utils';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { getCompanies } from 'modules/auth/selectors';
import { useSharedSelector } from 'modules/shared/hooks';
import { showSecondModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { isAdmin, isClient, isPartner, isCompanyAdmin, isPartnerAdmin } from 'modules/shared/utils';
import theme from './forms.module.scss';
import { EDIT_RIGHTS, ROLES } from './personalInfo.constants';

const PersonalInfoForm = ({ user, onClose }) => {
  const { companies, partnerCompanies } = useSelector(getCompanies);
  const [portalSections] = useSharedSelector(['portalSection']);
  const [avatarImg, setAvatarImg] = useState('');
  const inputRefAvatarImg = useRef();
  const {
    company,
    email,
    id: userId,
    first_name,
    is_edit_assets_permission,
    is_manage_orders_permission,
    landing_start_page,
    last_name,
    partner_company,
    people_look_editing_rights,
    role,
    avatar,
  } = user;

  const activeCompany = localStorage.getItem('lastCompany');
  const dispatch = useDispatch();
  const isPartnerRole = isPartner(role);
  const isClientRole = isClient(role);
  const isPartnerAdminRole = isPartnerAdmin(role);
  const isCompanyAdminRole = isCompanyAdmin(role);
  const getCompaniesList = isPartnerRole || isClientRole || isCompanyAdminRole ? [company]?.flat() : companies;
  const getPartnerCompaniesList =
    isPartnerRole || isClientRole || isPartnerAdminRole ? [partner_company]?.flat() : partnerCompanies;
  const [errorResultName, setErrorResultName] = useState(false);
  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('*Name required'),
    last_name: Yup.string().required('*Order required'),
  });

  const editInitialValues = {
    email,
    first_name,
    last_name,
    landing_start_page: landing_start_page?.name,
    partner_company,
    company,
  };

  const openResetPassword = (event) => {
    event.preventDefault();
    dispatch(showSecondModal(modalCodes.RESET_PASSWORD, {}));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editInitialValues,
    validationSchema,
    onSubmit: (values) => {
      const getValues = Object.assign(values, {
        landing_start_page: values.landing_start_page,
        ...(avatarImg && { avatar: avatarImg }),
      });
      delete getValues.company;
      delete getValues.partner_company;
      delete getValues.email;

      // const result = JSON.stringify(getValues, (k, val) => (val === '' ? null : val));
      const url = ENDPOINTS.auth.updateUser.replace(':companyId', activeCompany).replace(':user_id', userId);
      const data = new FormData();
      Object.entries(getValues).map(([key, value]) => {
        return data.append(key, value);
      });
      const config = {
        method: 'put',
        url,
        headers: {
          Authorization: `Token ${isLogged()}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      axios(config)
        .then(() => {
          onClose();
          window.location.reload(false);
        })
        .catch(({ response }) => {
          setErrors({ isError: true, errorMsg: response?.data });
        });
    },
  });

  return (
    // <Choose>
    //   <When condition={isModifierLoading}>
    //     <Skeleton variant="rect" height="100%" width="100%" />
    //   </When>
    //   <Otherwise>
    <div className={theme.container}>
      <div className={theme.wrapper}>
        <div className={theme.header}>
          <Typography variant="h5" className={theme.title}>
            Edit Personal Information
          </Typography>
        </div>
        <div className={theme.formWrapper}>
          <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
            {/* <Box sx={{ mt: 2, mb: 2 }}>
              <If condition={errors.isError}>
                {errors.errorMsg.map((msg) => (
                  <Typography variant="body2" className={theme.error}>
                    {msg}
                  </Typography>
                ))}
              </If>
            </Box> */}
            <Box>
              <div className={theme.avatar}>
                <Typography>Avatar</Typography>
                <Avatar
                  src={avatarImg ? URL.createObjectURL(avatarImg) : avatar}
                  sx={{ width: 100, height: 100, mb: 1 }}
                />
                <Button component="label" color="primary" variant="outlined">
                  Change avatar
                  <input
                    type="file"
                    id="avatar"
                    hidden
                    name="avatar"
                    accept="image/*"
                    multiple={false}
                    onChange={() => {
                      setAvatarImg(inputRefAvatarImg.current.files[0]);
                    }}
                    ref={inputRefAvatarImg}
                  />
                </Button>
              </div>
            </Box>
            <Box>
              <TextField
                fullWidth
                id="first_name"
                name="first_name"
                label="First name"
                placeholder="Enter user first name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.first_name || errors.errorMsg?.first_name}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                id="last_name"
                name="last_name"
                label="Last name"
                placeholder="Enter user last name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.last_name || errors.errorMsg?.last_name}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                disabled
                placeholder="Enter user email"
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Box>
            <If condition={isClientRole || isCompanyAdminRole}>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="company">Company</InputLabel>
                  <Select
                    labelId="company"
                    id="company"
                    name="company"
                    label="Company"
                    disabled
                    value={formik.values.company?.id}
                    onChange={formik.handleChange}
                  >
                    {getCompaniesList.map(({ id, name: companyName }) => (
                      <MenuItem key={id} value={id}>
                        {companyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </If>
            <If condition={isPartnerRole || isPartnerAdminRole}>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="partner_company">Partner company</InputLabel>
                  <Select
                    disabled
                    labelId="partner_company"
                    id="partner_company"
                    name="partner_company"
                    label="Partner company"
                    value={formik.values.partner_company?.id}
                    onChange={formik.handleChange}
                  >
                    {getPartnerCompaniesList.map(({ id, name: partnerCompanyName }) => (
                      <MenuItem key={id} value={id}>
                        {partnerCompanyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </If>
            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="landing_start_page">Landing start page</InputLabel>
                <Select
                  labelId="landing_start_page"
                  id="landing_start_page"
                  name="landing_start_page"
                  label="Landing start page"
                  value={formik.values?.landing_start_page}
                  onChange={formik.handleChange}
                >
                  {portalSections.map(({ id, name: sectionName }, index) => (
                    <MenuItem key={index} value={sectionName}>
                      {sectionName}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="body2" className={theme.error}>
                  {errors.errorMsg?.landing_start_page}
                </Typography>
              </FormControl>
            </Box>
            {/* <Box>
              <FormControl fullWidth>
                <InputLabel id="role">Role</InputLabel>
                <Select
                  labelId="role"
                  id="role"
                  name="role"
                  label="Role"
                  className={theme.menuItem}
                  value={formik.values.role}
                  onChange={formik.handleChange}
                >
                  {ROLES.map((roleName, id) => (
                    <MenuItem key={id} value={roleName} className={theme.menuItem}>
                      {roleName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="body2" className={theme.error}>
                {formik.errors?.role}
              </Typography>
            </Box> */}
            {/* <Box sx={{ mt: 2, mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="people_look_editing_rights">People look editing rights</InputLabel>
                <Select
                  labelId="people_look_editing_rights"
                  id="people_look_editing_rights"
                  name="people_look_editing_rights"
                  label="People look editing rights"
                  value={formik.values.people_look_editing_rights}
                  onChange={formik.handleChange}
                >
                  {EDIT_RIGHTS.map(({ key, value }, id) => (
                    <MenuItem key={id} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="body2" className={theme.error}>
                {formik.errors?.people_look_editing_rights}
              </Typography>
            </Box> */}
            {/* <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="is_edit_assets_permission"
                    name="is_edit_assets_permission"
                    color="primary"
                    checked={formik.values.is_edit_assets_permission}
                    value={formik.values.is_edit_assets_permission}
                    onChange={formik.handleChange}
                  />
                }
                label="Is edit assets permission"
              />
            </Box> */}
            {/* <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="is_manage_orders_permission"
                    name="is_manage_orders_permission"
                    color="primary"
                    checked={formik.values.is_manage_orders_permission}
                    value={formik.values.is_manage_orders_permission}
                    onChange={formik.handleChange}
                  />
                }
                label="Is manage orders permission"
              />
            </Box> */}
            <div className={theme.btnContainer}>
              <Button color="primary" variant="text" onClick={openResetPassword}>
                Reset Password
              </Button>
              <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
    //   </Otherwise>
    // </Choose>
  );
};

export default PersonalInfoForm;
