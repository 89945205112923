/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';
import { toStackedBarSingleChartData, toEditableTextData } from 'modules/workLook/utils';

// eslint-disable-next-line import/no-cycle
import StackedBarSingle from './StackedBarSingle';

const StackedBarSingleContainer = ({
  id,
  type_of_group,
  activeFilters,
  type,
  isModal,
  graphData,
  isPublished,
  isPdf,
  ...props
}) => {
  const { isLoading, data } = useGraphData(
    id,
    activeFilters,
    graphData,
    false,
    false,
    type,
    type_of_group,
    props.title,
  );

  return (
    <StackedBarSingle
      isPublished={isPublished}
      isPdf={isPdf}
      graphId={id}
      type={type}
      isLoading={isLoading}
      typeOfGroup={type_of_group}
      data={toStackedBarSingleChartData(data, type_of_group, isModal)}
      isModal={isModal}
      modalData={!isModal && { ...props, id, type_of_group, activeFilters, type, graphData: data }}
      {...props}
    />
  );
};

export default StackedBarSingleContainer;
