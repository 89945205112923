import React from 'react';
import { Link } from 'react-router-dom';

import URLS from 'pages/constants/urls';

import gearsIcon from 'assets/images/icons/gears.svg';

import './SwitchButton.scss';

const SwitchButton = () => {
  return (
    <Link to={URLS.auth.login} className="SwitchButton" style={{ backgroundColor: '#00AAD2' }}>
      <img src={gearsIcon} className="icon" alt="icon" />
      <span className="switch-button">WorkLOOK</span>
    </Link>
  );
};

export default SwitchButton;
