export const COMPANY_SECTOR_NAMES = [
  { id: 0, name: 'Food, Agriculture, Forestry and Fishing' },
  { id: 1, name: 'Manufacturing & Production' },
  { id: 2, name: 'Construction' },
  { id: 3, name: 'Wholesale and retail' },
  { id: 4, name: 'Transport, postal and storage' },
  { id: 5, name: 'Information and communication' },
  { id: 6, name: 'Finance and Insurance' },
  { id: 7, name: 'Property and Accomodation' },
  { id: 8, name: 'Professional, Scientific and Technical services' },
  { id: 9, name: 'Aerospace and Defence' },
  { id: 10, name: 'Education' },
  { id: 11, name: 'Healthcare' },
  { id: 12, name: 'Media, Arts and Recreation' },
  { id: 13, name: 'Local Government' },
  { id: 14, name: 'Central Government & NDPBs' },
  { id: 15, name: 'Other' },
];

export const COMPANY_SHORT_SECTOR_NAMES = [
  { id: 0, name: 'Food & Agriculture' },
  { id: 1, name: 'Manufacturing' },
  { id: 2, name: 'Construction' },
  { id: 3, name: 'Retail' },
  { id: 4, name: 'Transport' },
  { id: 5, name: 'Information' },
  { id: 6, name: 'Financial' },
  { id: 7, name: 'Property' },
  { id: 8, name: 'Professional services' },
  { id: 9, name: 'Aerospace' },
  { id: 10, name: 'Education' },
  { id: 11, name: 'Healthcare' },
  { id: 12, name: 'Media' },
  { id: 13, name: 'Local Government' },
  { id: 14, name: 'Central Government' },
  { id: 15, name: 'Other' },
];
