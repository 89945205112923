/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Box, Button, ThemeProvider, Typography } from '@material-ui/core';
import { ENDPOINTS } from 'modules/shared/constants';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { btnsTheme } from 'modules/modalsSystem/components/modalsWL/options';
import { API, isAdmin } from 'modules/shared/utils';
import { MODIFIER_URL } from 'modules/workLook/components/ExternalLink/constants';
import { useSelector } from 'react-redux';
import { getUser } from 'modules/auth/selectors';
import theme from './ModifierItem.module.scss';

const ModifierItem = ({ modifierId, index, name, onEditModifier }) => {
  const { user } = useSelector(getUser);
  const isAdminRole = isAdmin(user?.role);
  const getTargetUrl = MODIFIER_URL.replace(':modifierId', modifierId);
  const [displayOptions, setDisplayOptions] = useState(false);
  const onDeleteModifier = (id) => {
    const modifierUrl = ENDPOINTS.workLook.crudModifier.replace(':modifierId', id);
    API.delete(modifierUrl).then(() => {
      window.location.reload(false);
    });
  };

  return (
    <div key={index}>
      <div className={theme.listContainer}>
        <div>
          <div className={theme.headerItem}>
            <Typography variant="subtitle2" className={theme.header}>
              {`${index + 1}. ${name}`}
            </Typography>
            <If condition={isAdminRole}>
              <a href={getTargetUrl} target="_blank" rel="noopener noreferrer">
                <OpenInNewIcon color="action" fontSize="small" />
              </a>
            </If>
          </div>
          <div className={theme.link}>
            <Button onClick={() => onEditModifier(modifierId)} size="small" color="primary" variant="outlined">
              Edit Modifier
            </Button>
            <div onClick={() => setDisplayOptions(true)}>
              <DeleteIcon color="error" fontSize="small" />
            </div>
          </div>
        </div>
      </div>
      {displayOptions && (
        <div className={theme.btnWrapper}>
          <ThemeProvider theme={btnsTheme}>
            <Box sx={{ mr: 1 }}>
              <Button
                onClick={() => setDisplayOptions(false)}
                className={theme.dltBtn}
                size="small"
                color="inherit"
                variant="outlined"
              >
                Cancel
              </Button>
            </Box>
            <Button onClick={() => onDeleteModifier(modifierId)} size="small" color="secondary" variant="outlined">
              Delete
            </Button>
          </ThemeProvider>
        </div>
      )}
    </div>
  );
};

export default ModifierItem;
