/* eslint-disable react/require-default-props */
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    padding: '75px 50px 40px',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    background: ({ closingImage }) => (closingImage ? `center / cover no-repeat url(${closingImage})` : ''),
    backgroundColor: ({ backgroundColor }) => get(theme, `palette.${backgroundColor}`, 'unset'),
  },
}));

const ReportLastPage = ({ closingImage, backgroundColor = 'primary.dark' }) => {
  const classes = useStyles({ backgroundColor, closingImage });

  return <div className={classes.container} />;
};

ReportLastPage.propTypes = {
  closingImage: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default ReportLastPage;
