const toBenchmarkingTableData = (data) =>
  data.map((item) => ({
    industry: item.name.length > 16 ? `${item.name.slice(0, 13)}...` : item.name,
    totalDesks: item.survey_total_desks,
    maxUtilisation: item.survey_result_max_util,
    avgUtilisation: item.survey_result_avg_util,
    maxOccupancy: item.survey_result_max_oc,
    avgOccupancy: item.survey_result_avg_oc,
  }));

export default toBenchmarkingTableData;
