/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { ChromePicker } from 'react-color';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import { ENDPOINTS } from 'modules/shared/constants';
import isLogged from 'utils/isLogged';
import Autocomplete from '@mui/material/Autocomplete';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import theme from './CreateSeriesForm.module.scss';
import { SERIES_ACTIONS } from './CreateSeries.constants';

const CreateSeriesForm = ({
  seriesData,
  isSeriesLoading,
  seriesAction,
  reportFields,
  onCancelSeries,
  seriesId,
  graphId,
  reportPictures,
}) => {
  const { name, name_alias, action, group_by_name, color, sub_title, fields, order, is_display_legend, pictures } =
    seriesData || {};
  const isEditMode = seriesAction === 'edit';
  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Name required'),
    action: Yup.string().required('*Action required'),
    fields: Yup.array().min(1, '*Fields required'),
    order: Yup.string().required('*Order required'),
  });

  const createInitialValues = {
    graph: graphId,
    name: '',
    name_alias: '',
    action: '',
    group_by_name: '',
    color: '#ffffff',
    sub_title: '',
    fields: [],
    order: '',
    is_display_legend: true,
    pictures: [],
  };

  const editInitialValues = {
    graph: graphId,
    name: !name ? '' : name,
    name_alias: !name_alias ? '' : name_alias,
    action: !action ? '' : action,
    group_by_name: !group_by_name ? '' : group_by_name,
    color: !color ? '' : color,
    sub_title: !sub_title ? '' : sub_title,
    fields: !fields ? [] : fields,
    order: !order ? '' : order,
    is_display_legend: !is_display_legend ? true : is_display_legend,
    pictures: !pictures ? [] : pictures,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode ? editInitialValues : createInitialValues,
    validationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line no-param-reassign
      values.fields.forEach((part, index, theArray) => {
        // eslint-disable-next-line no-param-reassign
        theArray[index] = reportFields?.find((field) => field.name === part)?.id;
      });
      values.pictures.forEach((part, index, theArray) => {
        // eslint-disable-next-line no-param-reassign
        theArray[index] = reportPictures?.find((field) => field.name === part)?.id;
      });
      const result = JSON.stringify(values, (k, val) => (val === '' ? null : val));
      const url = ENDPOINTS.workLook.createSeries.replace(':graphId', graphId);
      const updateSeriesUrl = ENDPOINTS.workLook.deleteSeries.replace(':seriesId', seriesId);
      const config = {
        method: isEditMode ? 'put' : 'post',
        url: isEditMode ? updateSeriesUrl : url,
        headers: {
          Authorization: `Token ${isLogged()}`,
          'Content-Type': 'application/json',
        },
        data: result,
      };
      axios(config)
        .then(() => {
          window.location.reload(false);
        })
        .catch((error) => {
          setErrors({
            isError: true,
            errorMsg:
              error?.response?.data?.non_field_errors ||
              error?.response?.data?.type ||
              error?.response?.data?.fields ||
              error?.response?.data?.pictures ||
              error?.response?.data?.action,
          });
          formik.values.fields.forEach((part, index, theArray) => {
            // eslint-disable-next-line no-param-reassign
            theArray[index] = reportFields.find((field) => field.id === part)?.name;
          });
          formik.values.pictures.forEach((part, index, theArray) => {
            // eslint-disable-next-line no-param-reassign
            theArray[index] = reportPictures.find((field) => field.id === part)?.name;
          });
        });
    },
  });

  return (
    <Choose>
      <When condition={isSeriesLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>
      <Otherwise>
        <div className={theme.container}>
          <div className={theme.header}>
            <Typography variant="h5" className={theme.title}>
              {isEditMode ? 'Edit Series' : 'Create Series'}
            </Typography>
          </div>
          <div className={theme.formWrapper}>
            <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
              <div>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  placeholder="Enter series name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.name}
                </Typography>
              </div>
              <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  id="name_alias"
                  name="name_alias"
                  label="Name Alias"
                  placeholder="Enter series name alias"
                  value={formik.values.name_alias}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
              </Box>
              <FormControl fullWidth>
                <InputLabel id="action">Action</InputLabel>
                <Select
                  id="action"
                  name="action"
                  value={formik.values.action}
                  label="Action"
                  onChange={formik.handleChange}
                >
                  {SERIES_ACTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.action}
                </Typography>
              </FormControl>
              <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  id="group_by_name"
                  name="group_by_name"
                  label="Group By Name"
                  placeholder="Group By Name"
                  value={formik.values.group_by_name}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
              </Box>
              <Typography variant="body2" style={{ fontSize: '16px' }}>
                Color
              </Typography>
              <ChromePicker
                fullWidth
                id="color"
                name="color"
                color={formik.values.color}
                onChange={(value) => {
                  formik.setFieldValue('color', value.hex);
                }}
              />
              <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  id="sub_title"
                  name="sub_title"
                  label="Subtitle"
                  placeholder="Subtitle"
                  value={formik.values.sub_title}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="is_display_legend"
                      name="is_display_legend"
                      color="primary"
                      checked={formik.values.is_display_legend}
                      value={formik.values.is_display_legend}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Display legends"
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={2}
                  id="fields"
                  name="fields"
                  value={formik.values.fields}
                  options={reportFields?.map((option) => option?.name)}
                  onChange={(e, newValue) => {
                    formik.setFieldValue('fields', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} label="Fields" placeholder="Select fields" variant="outlined" />
                  )}
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors?.fields}
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={2}
                  id="pictures"
                  name="pictures"
                  value={formik.values.pictures}
                  // change on array of pictures
                  options={reportPictures?.map((option) => option?.name)}
                  onChange={(e, newValue) => {
                    formik.setFieldValue('pictures', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label="Pictures"
                      placeholder="Select pictures"
                      variant="outlined"
                    />
                  )}
                />
              </Box>
              <div>
                <TextField
                  fullWidth
                  className={theme.input}
                  type="number"
                  id="order"
                  name="order"
                  label="Order"
                  placeholder="Enter an order"
                  value={formik.values.order}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.order}
                </Typography>
              </div>
              {errors.isError &&
                errors.errorMsg?.map((msg) => (
                  <Typography variant="body2" className={theme.error}>
                    {msg}
                  </Typography>
                ))}
              <div className={theme.btnContainer}>
                <Button onClick={() => onCancelSeries()} className={theme.cancelBtn} variant="outlined">
                  Cancel
                </Button>
                <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default CreateSeriesForm;
