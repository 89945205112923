import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import cn from 'classnames';

import options from './options';
import optionsPdf from './optionsPdf';
import theme from './GraphBody.module.scss';

const GraphBody = ({ data, pdfMode }) => {
  // eslint-disable-next-line no-shadow
  const isAllDataZeros = useMemo(() => data.datasets.flatMap(({ data }) => data).every((value) => value === 0), [data]);

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      <div className={theme.graphWrapper}>
        <div className={theme.graphInner}>
          <div
            // style={{width: `${data.labels.length * BAR_WIDTH}px`}}
            className={theme.graph}
          >
            <Bar
              width={192}
              height={420}
              data={data}
              options={pdfMode ? optionsPdf(isAllDataZeros) : options(isAllDataZeros)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphBody;
