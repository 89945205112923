/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-control-statements/jsx-use-if-tag */
/* eslint-disable camelcase */
import React from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import { Button, Collapse, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';

import URLS from 'pages/constants/urls';
import { SORT_ORDER } from 'modules/workLook/constants';
import config from 'modules/workLook/components/PdfReportsPageSelector/config';

import reportIcon from 'assets/images/icons/report.svg';

import { useDispatch, useSelector } from 'react-redux';
import { SET_REPORT_ID, SET_SECTION_DATA } from 'modules/workLook/actions';
import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { isAdmin, isClient, isPartner, isViewOnly } from 'modules/shared/utils';
import { getUser } from 'modules/auth/selectors';
import theme from './ReportItem.module.scss';

const ReportItem = ({
  name,
  sections,
  is_published,
  typeId,
  reportId,
  sectionId,
  isActiveReport,
  isAccordionOpen,
  reportTitleMatch,
  pdfReportsMatch,
  _onReportItemClick,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(getUser);
  const { people_look_editing_rights, role } = user;

  const isAdminRole = isAdmin(role) || isPartner(role);
  const isClientRole = isClient(role);
  const hasViewRights = isViewOnly(people_look_editing_rights);

  const clientRights = isClientRole && !hasViewRights;
  const onCreateSectionClick = () => {
    dispatch(SET_REPORT_ID(reportId));
    dispatch(SET_SECTION_DATA({ action: 'create' }));
    dispatch(showModal(modalCodes.WL_CREATE_SECTION_MODAL));
  };
  return (
    <>
      <ListItem onClick={_onReportItemClick} className={theme.listItem}>
        <ListItemIcon className={theme.icon}>
          <img src={reportIcon} alt="Report navigation icon." />
        </ListItemIcon>
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            className: cn(theme.text, {
              [`${theme.unpublished}`]: !is_published,
            }),
          }}
        />
        {isAccordionOpen ? <ArrowDropUpIcon className={theme.arrow} /> : <ArrowDropDownIcon className={theme.arrow} />}
      </ListItem>

      <Collapse in={isAccordionOpen} timeout="auto" unmountOnExit>
        <List>
          <Link to={URLS.workLook.title.replace(':typeId', typeId).replace(':reportId', reportId)}>
            <ListItem className={theme.listSubItem}>
              <ListItemText
                primary="Title"
                inset
                className={theme.textContainer}
                primaryTypographyProps={{
                  className: cn(theme.text, {
                    [`${theme.active}`]: isActiveReport && reportTitleMatch,
                    [`${theme.unpublished}`]: !is_published,
                  }),
                }}
              />
            </ListItem>
          </Link>

          {Object.values(sections)
            .sort(SORT_ORDER.byOrder)
            .map(({ id, title, pages }) => {
              // if (isEmpty(pages)) return null;

              const path = URLS.workLook.section
                .replace(':typeId', typeId)
                .replace(':reportId', reportId)
                .replace(':sectionId', id)
                .replace(':pageId', Object.values(pages).sort(SORT_ORDER.byOrder)[0]?.id);

              return (
                <Link to={path} key={id}>
                  <ListItem className={theme.listSubItem}>
                    <ListItemText
                      primary={title}
                      inset
                      className={theme.textContainer}
                      primaryTypographyProps={{
                        className: cn(theme.text, {
                          [`${theme.active}`]: id === sectionId,
                          [`${theme.unpublished}`]: !is_published,
                        }),
                      }}
                    />
                  </ListItem>
                </Link>
              );
            })}
          <If condition={(clientRights && !is_published) || (isAdminRole && !is_published)}>
            <ListItem className={theme.listSubItemSection}>
              <ListItemIcon style={{ minWidth: '0' }}>
                <FolderCopyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary="Create Section"
                inset
                style={{ paddingLeft: 20 }}
                onClick={() => onCreateSectionClick()}
                primaryTypographyProps={{
                  className: cn(theme.text, {
                    [`${theme.active}`]: pdfReportsMatch,
                    [`${theme.unpublished}`]: !is_published,
                  }),
                }}
              />
            </ListItem>
          </If>
          <Link to={config.current.path.replace(':typeId', typeId).replace(':reportId', reportId)}>
            <ListItem className={theme.listSubItem}>
              <ListItemText
                primary="PDF Reports"
                inset
                className={theme.textContainer}
                primaryTypographyProps={{
                  className: cn(theme.text, {
                    [`${theme.active}`]: pdfReportsMatch,
                    [`${theme.unpublished}`]: !is_published,
                  }),
                }}
              />
            </ListItem>
          </Link>
        </List>
      </Collapse>
    </>
  );
};

export default ReportItem;
