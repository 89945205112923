import React from 'react';
import { ReportNetworkErrorHandler } from 'modules/spaceLook/components';
import { Loader } from 'components';

const LoadingCondition = ({ isLoading, isNoData, children, fetchStart }) => (
  <If condition={!fetchStart}>
    <Loader />
    <Else />
    <If condition={isLoading}>
      <Loader />
      <Else />
      <If condition={isNoData}>
        <ReportNetworkErrorHandler isNoData={isNoData} />
        <Else />
        {children}
      </If>
    </If>
  </If>
);

export default LoadingCondition;
