/* eslint-disable camelcase */
const DesksBusiestPeakTimeAvg = ({
  databoxes: {
    textblock_data: { max_peaktime_avg_occup_day, max_peaktime_avg_occup_percent },
  },
}) =>
  `The busiest “peak time average” occupancy (average occupancy between 10-12 and 2-4) occurred on
   ${max_peaktime_avg_occup_day} which was measured as ${max_peaktime_avg_occup_percent}%.`;

export default DesksBusiestPeakTimeAvg;
