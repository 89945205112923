const piePalette = [
  '#FEAF00',
  '#037EDD',
  '#D3F30A',
  '#FF7300',
  '#97D8FE',
  '#FDEC00',
  '#FE0000',
  '#60B7D3',
  '#46492E',
  '#E01F82',
  '#7DDDDC',
  '#878783',
  '#C957D2',
  '#26D7AD',
  '#9C46CF',
  '#2DCB76',
  '#8F6BF0',
  '#1BA92F',
  '#8399EC',
  '#52D726',
];

export default piePalette;
