/* eslint-disable camelcase */
/* eslint-disable no-shadow */
// eslint-disable-next-line import/no-cycle
import { getRandomColor } from 'modules/workLook/utils';
import { GRAPH_COLORS, SERIES_ACTION_GROUP, GROUP_TYPES } from 'modules/workLook/constants';

const handleGroupNoneDataStructure = (data, isModal) => {
  const graphData = {
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: GRAPH_COLORS[0],
      },
    ],
  };

  data.forEach(({ action, data, name }) => {
    if (SERIES_ACTION_GROUP.singleValue.includes(action)) {
      const label = name.length > 16 && !isModal ? `${name.slice(0, 13)}...` : name;

      graphData.labels.push(label);
      graphData.datasets[0].data.push(data);
    }

    if (SERIES_ACTION_GROUP.multipleValue.includes(action)) {
      data.forEach(({ field, value }) => {
        const label = field.length > 16 && !isModal ? `${field.slice(0, 13)}...` : field;

        graphData.labels.push(label);
        graphData.datasets[0].data.push(value);
      });
    }

    if (SERIES_ACTION_GROUP.counter.includes(action)) {
      data.forEach(({ field, value, _percentage }) => {
        const label = field.length > 16 && !isModal ? `${field.slice(0, 13)}...` : field;

        graphData.labels.push(label);
        graphData.datasets[0].data.push(_percentage || value);
      });
    }
  });

  return graphData.datasets[0].data.length ? graphData : null;
};

const handleGroupByFieldDataStructure = (data, isModal) => {
  const graphData = {
    labels: [],
    datasets: [],
  };

  const firstSeries = data[0];

  if (SERIES_ACTION_GROUP.multipleValue.includes(firstSeries.action)) {
    firstSeries.data.forEach(({ group_by_field, fields }, i) => {
      const legendLabel = group_by_field.length > 16 && isModal ? `${group_by_field.slice(0, 13)}...` : group_by_field;

      graphData.labels = fields.map(({ field }) => (field.length > 16 && isModal ? `${field.slice(0, 13)}...` : field));
      graphData.datasets.push({
        label: legendLabel,
        borderColor: GRAPH_COLORS[i] || getRandomColor(),
        data: fields.map(({ value }) => value),
      });
    });
  }

  return graphData.datasets.length ? graphData : null;
};

const handleGroupByNameDataStructure = (data, isModal) => {
  const graphData = {
    labels: [],
    datasets: [],
  };

  data.forEach(({ group_name, series_data }) => {
    const label = group_name && group_name.length > 16 && !isModal ? `${group_name.slice(0, 13)}...` : group_name;
    graphData.labels.push(label);

    series_data.forEach((series, i) => {
      if (SERIES_ACTION_GROUP.singleValue.includes(series.action)) {
        const datasetIndex = graphData.datasets.findIndex((dataset) => dataset.label === series.name);

        if (datasetIndex >= 0) {
          graphData.datasets[datasetIndex].data.push(series.data);
        } else {
          graphData.datasets.push({
            label: series.name,
            borderColor: GRAPH_COLORS[i] || getRandomColor(),
            data: [series.data],
          });
        }
      }
    });
  });

  return graphData.datasets.length ? graphData : null;
};

const toRadarChartData = (data, groupType, isModal) => {
  if (!data || !data.length) return null;

  switch (groupType) {
    case null:
      return handleGroupNoneDataStructure(data, isModal);
    case GROUP_TYPES.byField:
      return handleGroupByFieldDataStructure(data, isModal);
    case GROUP_TYPES.byName:
      return handleGroupByNameDataStructure(data, isModal);
    default:
      return null;
  }
};

export default toRadarChartData;
