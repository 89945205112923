/* eslint-disable camelcase */
const setupPropertiesDetails = (propertiesData) => {
  return propertiesData.map((property) => {
    const {
      property_id,
      property_name,
      floors,
      total_occupancy_cost,
      area,
      photo,
      type,
      location: { address, region },
      desk_data_by_division,
      data_blocks: {
        desks,
        capacity,
        current_allocation,
        spare_capacity,
        space_per_desk,
        planned_space_per_head,
        actual_space_per_head,
        benchmark_per_head,
        peak_occup,
        peak_occup_percent,
        avg_occup,
        avg_occup_percent,
        peak_util,
        peak_util_percent,
        avg_util,
        avg_util_percent,
        cost_per_sqm,
        cost_per_desk,
        planned_cost_per_head,
        actual_cost_per_head,
      },
    } = property;
    return {
      id: property_id,
      name: property_name,
      address,
      region,
      floors,
      type,
      totalOccupancyCost: total_occupancy_cost,
      area,
      photo,
      graphsData: desk_data_by_division,
      databoxes: [
        {
          title: 'Capacity Data',
          cards: [
            {
              label: 'Desks',
              quantity: desks,
              valueLabel: 'seats',
              color: 'crimson',
              withValueLabel: true,
            },
            {
              label: 'Capacity',
              quantity: capacity,
              valueLabel: 'people',
              color: 'crimson',
              withValueLabel: true,
            },
            {
              label: 'Current Allocation',
              quantity: current_allocation,
              valueLabel: 'staff(FTE)',
              color: 'crimson',
              withValueLabel: true,
            },
            {
              label: 'Spare Capacity',
              quantity: spare_capacity,
              valueLabel: 'staff(FTE)',
              color: 'crimson',
              withValueLabel: true,
            },
          ],
        },
        {
          title: 'Space Metrics',
          cards: [
            {
              label: 'Space per Desk',
              quantity: space_per_desk,
              valueLabel: 'm2',
              color: 'dark-gold',
              withValueLabel: true,
            },
            {
              label: 'Planned Space per Head',
              quantity: planned_space_per_head,
              valueLabel: 'm2',
              color: 'dark-gold',
              withValueLabel: true,
            },
            {
              label: 'Space per Head',
              quantity: actual_space_per_head,
              valueLabel: 'm2',
              color: 'dark-gold',
              withValueLabel: true,
            },
            {
              label: 'Benchmark per Head',
              quantity: benchmark_per_head,
              valueLabel: 'm2',
              color: 'dark-gold',
              withValueLabel: true,
            },
          ],
        },
        {
          title: 'Utilisation Data',
          cards: [
            {
              label: 'Peak Occupancy',
              quantity: peak_occup,
              percent: peak_occup_percent,
              valueLabel: 'seats',
              color: 'turquoise',
            },
            {
              label: 'Avg Occupancy',
              quantity: avg_occup,
              percent: avg_occup_percent,
              valueLabel: 'seats',
              color: 'turquoise',
            },
            {
              label: 'Peak Utilisation',
              quantity: peak_util,
              percent: peak_util_percent,
              valueLabel: 'seats',
              color: 'turquoise',
            },
            {
              label: 'Average Utilisation',
              quantity: avg_util,
              percent: avg_util_percent,
              valueLabel: 'seats',
              color: 'turquoise',
            },
          ],
        },
        {
          title: 'Cost Data',
          cards: [
            {
              label: 'Cost per Sq m',
              quantity: cost_per_sqm,
              valueLabel: '£ per m2',
              color: 'light-green',
              withValueLabel: true,
            },
            {
              label: 'Cost per Desk',
              quantity: cost_per_desk,
              valueLabel: '£ per seat',
              color: 'light-green',
              withValueLabel: true,
            },
            {
              label: 'Planned Cost per Head',
              quantity: planned_cost_per_head,
              valueLabel: '£ per FTE',
              color: 'light-green',
              withValueLabel: true,
            },
            {
              label: 'Actual Cost per Head',
              quantity: actual_cost_per_head,
              valueLabel: '£ per FTE',
              color: 'light-green',
              withValueLabel: true,
            },
          ],
        },
      ],
    };
  });
};

export default setupPropertiesDetails;
