import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Loader, PageHeader } from 'components';
import { QrConfirmForm, StaticTokenForm, Tabs, TabsContent, TabsNav } from 'modules/auth/components';

import { isLogged } from 'utils';
import { REQUESTS } from '_constants';
import URLS from 'pages/constants/urls';
import { getUser } from 'modules/auth/selectors';

import './TwoFAPage.scss';

const { base, version, otpCreate } = REQUESTS;

const TwoFAPage = () => {
  const history = useHistory();
  const { user, isOtpRequired } = useSelector(getUser);

  if (!isLogged() || isEmpty(user)) {
    localStorage.clear();
    history.push(URLS.auth.login);
  }

  const [qrString, setQrString] = useState('');
  const [mounting, setMounting] = useState(true);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isOtpRequired) return setMounting(false);

    axios({
      method: 'post',
      url: `${base}${version}${otpCreate}`,
      headers: { Authorization: `Token ${isLogged()}` },
    }).then((response) => {
      setQrString(response.data.url);
      setMounting(false);
    });
  }, [isOtpRequired]);

  if (mounting) return <Loader />;

  return (
    <div className="QrPage">
      <div className="qr-page">
        <PageHeader title="Two-Factor Authentication" />
        <Tabs>
          <TabsNav isOtpRequired={isOtpRequired}>
            <div className="name-item">Google Authenticator</div>
            {isOtpRequired && <div className="name-item">Lost your device?</div>}
          </TabsNav>
          <TabsContent>
            <div key="QrConfirmForm" className="form-wrapper">
              <QrConfirmForm qrUrl={qrString} />
            </div>
            <div key="StaticTokenForm" className="form-wrapper">
              <Choose>
                <When condition={isOtpRequired}>
                  <StaticTokenForm />
                </When>
                <Otherwise>
                  <div className="static-token-form-banner">Static token form here when you logged</div>
                </Otherwise>
              </Choose>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default TwoFAPage;
