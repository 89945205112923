import { pick } from 'lodash';

const keysToPick = ['databoxes', 'textData', 'historicData'];

const getBenchmarkingData = ({ report: { benchmarking } }) => ({
  dataRequestStatuses: pick(benchmarking.requestStatuses, keysToPick),
  data: pick(benchmarking.data, keysToPick),
  shouldRefetchData: pick(benchmarking.shouldRefetchData, keysToPick),
});

export default getBenchmarkingData;
