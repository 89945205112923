import React from 'react';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';

import { graphDataTypes, graphSortOptions } from 'components/Pages/SummaryPage/constants';
import switchGraphDataType from 'actions/switchGraphDataType';
import { changeSortOrder } from 'actions';

import theme from './DetailsGraphSwitcher.module.scss';

const DetailsGraphSwitcher = ({ graphDataType, sortOrder, dispatch }) => (
  <div className={theme.container}>
    <FormControl>
      <InputLabel>Sort by</InputLabel>
      <Select value={sortOrder} onChange={(e) => dispatch(changeSortOrder(e.target.value))} disableUnderline>
        <MenuItem value={graphSortOptions.az}>Name (a-z)</MenuItem>
        <MenuItem value={graphSortOptions.za}>Name (z-a)</MenuItem>
        <MenuItem value={graphSortOptions.asc}>Value (asc)</MenuItem>
        <MenuItem value={graphSortOptions.desc}>Value (desc)</MenuItem>
      </Select>
    </FormControl>

    <FormControl>
      <InputLabel>Data type</InputLabel>
      <Select value={graphDataType} onChange={(e) => dispatch(switchGraphDataType(e.target.value))} disableUnderline>
        <MenuItem value={graphDataTypes.deskCount}>Desk Count</MenuItem>
        <MenuItem value={graphDataTypes.deskUtilization}>Desk Utilization</MenuItem>
      </Select>
    </FormControl>
  </div>
);

export default DetailsGraphSwitcher;
