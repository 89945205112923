/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import './WithIconLink.scss';

import heartIcon from '../../../assets/images/icons/heart.svg';

const WithIconLink = ({ link, label, icon, isActive, isDisabled, isOrdinaryLink, isBig }) => {
  const classes = cn('WithIconLink', {
    'is-active': isActive,
    'is-disabled': isDisabled,
    'is-big': isBig,
  });

  const renderedLink = link ? (
    isOrdinaryLink ? (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a href={link} target="_blank" className="with-icon-link" rel="noopener noreferrer" />
    ) : (
      <Link className="with-icon-link" to={link} />
    )
  ) : null;

  return (
    <div className={classes}>
      <div className="with-icon-title">
        {renderedLink}
        <img className="icon" src={icon} alt="icon" />
        <span className="label">{label}</span>
      </div>
    </div>
  );
};

WithIconLink.defaultProps = {
  icon: heartIcon,
  isActive: false,
  isDisabled: false,
  isOrdinaryLink: false,
  isBig: false,
};

export default WithIconLink;
