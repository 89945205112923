/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import { roundToFloat } from 'utils';
// eslint-disable-next-line import/no-cycle
import { getRandomColor } from 'modules/workLook/utils';
import { GRAPH_COLORS, GROUP_TYPES, SERIES_ACTION_GROUP } from 'modules/workLook/constants';

const setGraphDataLegendsColors = (fieldName, dataValue, color, isModal, sum, itemCounter) => {
  const legendLabel = fieldName.length > 16 && !isModal ? `${fieldName.slice(0, 13)}...` : fieldName;
  const percent = roundToFloat((dataValue / sum) * 100);
  const backgroundColor = color || GRAPH_COLORS[itemCounter];

  return { legendLabel, percent, backgroundColor };
};

const handleGroupNoneDataStructure = (data, isModal) => {
  const graphData = {
    labels: ['Item'],
    datasets: [],
  };
  let itemCounter = 0;
  let sum = 0;

  data.forEach(({ action, data }) => {
    if (SERIES_ACTION_GROUP.singleValue.includes(action)) return (sum += data);

    if (SERIES_ACTION_GROUP.multipleValue.includes(action)) {
      return (sum += data.reduce((acc, cur) => acc + cur.value, 0));
    }

    if (SERIES_ACTION_GROUP.counter.includes(action)) {
      return (sum += data.reduce((acc, cur) => acc + cur.value, 0));
    }
  });

  data.forEach(({ action, name, data, color }) => {
    if (SERIES_ACTION_GROUP.singleValue.includes(action)) {
      const barColor = color === '#ffffff' ? false : color;
      const { legendLabel, percent, backgroundColor } = setGraphDataLegendsColors(
        name,
        data,
        barColor,
        isModal,
        sum,
        itemCounter,
      );

      graphData.datasets.push({
        label: legendLabel,
        backgroundColor: backgroundColor || getRandomColor(),
        data: [percent],
      });
      itemCounter += 1;
    }

    if (SERIES_ACTION_GROUP.multipleValue.includes(action)) {
      data.forEach(({ field, value, _color }) => {
        const { legendLabel, percent, backgroundColor } = setGraphDataLegendsColors(
          field,
          value,
          _color,
          isModal,
          sum,
          itemCounter,
        );

        graphData.datasets.push({
          label: legendLabel,
          backgroundColor: backgroundColor || getRandomColor(),
          data: [percent],
        });
        itemCounter += 1;
      });
    }

    if (SERIES_ACTION_GROUP.counter.includes(action)) {
      data.forEach(({ field, value, _color }) => {
        const { legendLabel, percent, backgroundColor } = setGraphDataLegendsColors(
          field,
          value,
          _color,
          isModal,
          sum,
          itemCounter,
        );

        graphData.datasets.push({
          label: legendLabel,
          backgroundColor: backgroundColor || getRandomColor(),
          data: [percent],
        });
        itemCounter += 1;
      });
    }
  });

  return graphData.datasets.length ? graphData : null;
};

const handleGroupByFieldDataStructure = (data, isModal) => {
  const firstSeries = data[0];

  if (!SERIES_ACTION_GROUP.singleValue.includes(firstSeries.action)) return null;

  const graphData = {
    labels: ['Item'],
    datasets: [],
  };
  let itemCounter = 0;

  const sum = firstSeries.data.reduce((acc, cur) => acc + cur.value, 0);

  firstSeries.data.forEach(({ field, value, _color }) => {
    const { legendLabel, percent, backgroundColor } = setGraphDataLegendsColors(
      field,
      value,
      _color,
      isModal,
      sum,
      itemCounter,
    );

    graphData.datasets.push({
      label: legendLabel,
      backgroundColor: backgroundColor || getRandomColor(),
      data: [percent],
    });
    itemCounter += 1;
  });

  return graphData.datasets.length ? graphData : null;
};

const toStackedBarSingleChartData = (data, groupType, isModal) => {
  if (!data || !data.length) return null;

  switch (groupType) {
    case null:
      return handleGroupNoneDataStructure(data, isModal);
    case GROUP_TYPES.byField:
      return handleGroupByFieldDataStructure(data, isModal);
    default:
      return null;
  }
};

export default toStackedBarSingleChartData;
