import { pick } from 'lodash';

const keysToPick = ['databoxes', 'weeklyPeakAndAvgGraphData'];

const getDesksDailyComparisonData = ({ report: { desks } }) => ({
  dataRequestStatuses: pick(desks.requestStatuses, keysToPick),
  data: pick(desks.data, keysToPick),
  shouldRefetchData: pick(desks.shouldRefetchData, keysToPick),
});

export default getDesksDailyComparisonData;
