/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
const transformTableObject = (obj) => {
  const percentKeys = ['currentSharingRatio', 'peakOccupancy', 'peakUtilisation'];

  const readyObj = {};
  for (const key in obj) {
    const value = obj[key];
    if (value === null) {
      readyObj[key] = undefined;
      // eslint-disable-next-line no-continue
      continue;
    }
    const readyValue = percentKeys.includes(key) ? `${value}%` : value;
    readyObj[key] = readyValue;
  }
  return readyObj;
};

export default transformTableObject;
