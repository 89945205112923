import { cloneDeep, flow, groupBy, isEmpty } from 'lodash';

export const setDataGroupedByDays = (data, scale, flatMapFunc, dayChoosen = null) => {
  if (isEmpty(data)) return false;
  return flow([
    (res) => res.map((item) => cloneDeep(item)),
    (res) => flatMapFunc(res, scale, dayChoosen),
    (res) => res.filter((r) => Boolean(r)),
    (res) => {
      return res;
    },
    (res) => groupBy(res, (item) => item.day),
    (res) => Object.entries(res),
  ])(data);
};
