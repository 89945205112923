/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';
import { Typography, Button, TextField, Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { API } from 'modules/shared/utils';
import * as Yup from 'yup';
import { ChromePicker } from 'react-color';
import isLogged from 'utils/isLogged';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SET_BLANK_REPORT_DATA, SET_REPORT_FILES } from 'modules/workLook/actions';
import { ENDPOINTS } from 'modules/shared/constants';
import { useFormik } from 'formik';
import theme from './CreateReportForm.module.scss';
import { ICONS } from './createForm.constants';

const CreateReportForm = ({ onNext, companyId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState('');
  const [sectionImg, setSectionImg] = useState('');
  const [closingImg, setClosingImg] = useState('');
  const inputRefImg = useRef();
  const inputRefSecImg = useRef();
  const inputRefCloImg = useRef();

  const initialCreateValues = {
    name: '',
    is_active: true,
    is_published: false,
    name_alias: '',
    is_personality_profile: false,
    is_filter_personality_profile: false,
    sub_header: '',
    working_hours_from: '',
    working_hours_to: '',
    title_text_color: '#070A52',
    section_text_color: '#D21312',
    icon: '',
  };

  const validationCreateSchema = Yup.object().shape({
    name: Yup.string().required('*Name required'),
    working_hours_from: Yup.string().required('*Working hours from required'),
    working_hours_to: Yup.string().required('*Working hours to required'),
    icon: Yup.string().required('*Icon required'),
  });

  const formik = useFormik({
    initialValues: initialCreateValues,
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const result = Object.assign(values, {
        image,
        section_image: sectionImg,
        closing_image: closingImg,
      });
      const data = new FormData();
      Object.entries(result).map(([key, value]) => {
        return data.append(key, value);
      });
      const url = ENDPOINTS.workLook.createBlankReport.replace(':companyId', companyId);
      const config = {
        method: 'post',
        url,
        headers: {
          Authorization: `Token ${isLogged()}`,
          'Content-Type': 'multipart/form-data',
        },
        data,
      };
      axios(config)
        .then(({ data }) => {
          dispatch(SET_BLANK_REPORT_DATA(data));
          const tableUrl = ENDPOINTS.workLook.reportTableAlias.replace(':reportId', data.id);
          API.get(tableUrl).then(({ data: files }) => {
            dispatch(SET_REPORT_FILES(files));
          });
          onNext();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log('error', error);
          setIsLoading(false);
        });
    },
  });

  return (
    <div className={theme.form}>
      <div className={theme.container}>
        <div className={theme.wrapper}>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6" className={theme.title}>
              Report Form
            </Typography>
          </Box>
          <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 2, mb: 2 }}>
              <TextField
                disabled={isLoading}
                fullWidth
                id="name"
                name="name"
                label="Name"
                placeholder="Enter report name"
                value={formik.values.name}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.name}
              </Typography>
            </Box>
            <TextField
              fullWidth
              disabled={isLoading}
              id="name_alias"
              name="name_alias"
              label="Name alias"
              placeholder="Enter report name alias"
              value={formik.values.name_alias}
              onChange={formik.handleChange}
              variant="outlined"
            />
            <Box sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                disabled={isLoading}
                id="sub_header"
                name="sub_header"
                label="Sub header"
                placeholder="Enter report sub header"
                value={formik.values.sub_header}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Box>
            <Typography variant="body2" style={{ fontSize: '16px' }}>
              Title text color
            </Typography>
            <ChromePicker
              fullWidth
              id="title_text_color"
              name="title_text_color"
              color={formik.values.title_text_color}
              onChange={(value) => {
                formik.setFieldValue('title_text_color', value.hex);
              }}
            />
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography variant="body2" style={{ fontSize: '16px' }}>
                Section text color
              </Typography>
              <ChromePicker
                fullWidth
                id="section_text_color"
                name="section_text_color"
                color={formik.values.section_text_color}
                onChange={(value) => {
                  formik.setFieldValue('section_text_color', value.hex);
                }}
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                disabled={isLoading}
                className={theme.input}
                type="number"
                InputProps={{
                  inputProps: {
                    max: 24,
                    min: 0,
                  },
                }}
                id="working_hours_from"
                name="working_hours_from"
                label="Working hours from"
                placeholder="Enter a working hours from"
                value={formik.values.working_hours_from}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.working_hours_from}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                disabled={isLoading}
                className={theme.input}
                type="number"
                InputProps={{
                  inputProps: {
                    max: 24,
                    min: 0,
                  },
                }}
                id="working_hours_to"
                name="working_hours_to"
                label="Working hours to"
                placeholder="Enter a working hours to"
                value={formik.values.working_hours_to}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.working_hours_to}
              </Typography>
            </Box>
            <FormControl fullWidth>
              <InputLabel id="icon">Icon</InputLabel>
              <Select
                disabled={isLoading}
                id="icon"
                name="icon"
                value={formik.values.icon}
                label="Icon"
                onChange={formik.handleChange}
              >
                {ICONS.map((icon) => (
                  <MenuItem key={icon.value} value={icon.value}>
                    {icon.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" className={theme.error}>
                {formik.errors.icon}
              </Typography>
            </FormControl>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Button fullWidth component="label" color="primary" variant="outlined">
                Image
                <input
                  type="file"
                  id="image"
                  hidden
                  disabled={isLoading}
                  name="image"
                  accept="image/*"
                  multiple={false}
                  onChange={() => setImage(inputRefImg.current.files[0])}
                  ref={inputRefImg}
                />
              </Button>
              <Typography variant="body2">{image?.name}</Typography>
            </Box>
            <Box>
              <Button fullWidth component="label" color="primary" variant="outlined">
                Section Image
                <input
                  type="file"
                  id="section_image"
                  hidden
                  disabled={isLoading}
                  name="section_image"
                  accept="image/*"
                  multiple={false}
                  onChange={() => setSectionImg(inputRefSecImg.current.files[0])}
                  ref={inputRefSecImg}
                />
              </Button>
              <Typography variant="body2">{sectionImg?.name}</Typography>
            </Box>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Button fullWidth component="label" color="primary" variant="outlined">
                Closing Image
                <input
                  type="file"
                  id="closing_image"
                  hidden
                  disabled={isLoading}
                  name="closing_image"
                  accept="image/*"
                  multiple={false}
                  onChange={() => setClosingImg(inputRefCloImg.current.files[0])}
                  ref={inputRefCloImg}
                />
              </Button>
              <Typography variant="body2">{closingImg?.name}</Typography>
            </Box>
          </form>
        </div>
      </div>
      <div className={theme.btnWrapper}>
        <Button
          className={theme.sbmtBtn}
          disabled={isLoading}
          onClick={formik.handleSubmit}
          color="primary"
          variant="outlined"
          type="submit"
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <If condition={isLoading}>
              <CircularProgress style={{ marginRight: '4px' }} size={16} />
            </If>
            Submit
          </div>
        </Button>
      </div>
    </div>
  );
};

export default CreateReportForm;
