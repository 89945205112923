/* eslint-disable react/prop-types */
import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/shared/components';
import { ReportDataCards, FilterList, UsageOverTimeGraph, LiveCounter } from 'modules/liveLook/components';

import IFrameModal from 'modules/liveLook/components/iFrame';
import { pageTypes, GRAPH_RANGE } from 'modules/liveLook/constants';
import configMeet from '../MeetsOverTime/config';
import configCombined from '../CombinedTeamZoneOccupancy/config';
import configOcc from '../DoorsOverTime/config';
import configBreak from '../BreakoutOverTime/config';
import DashboardSwitcher from './components/dashboardSwitcher';

const GRAPH_WEEK_HEADER = {
  [GRAPH_RANGE.LAST_WEEK]: 'Last Week',
  [GRAPH_RANGE.FOUR_WEEKS]: 'Last 4 Weeks',
  [GRAPH_RANGE.FOUR_MONTHS]: 'Last 4 Months',
};

const SummaryDashboard = ({
  onRangeChange,
  range,
  allDataboxes,
  scaleWeek,
  // Meetings
  graphDataMeet,
  linesDataMeet,
  scaleValuesMeet,
  // Meetings Week
  scale,
  graphDataMeetWeek,
  linesDataMeetWeek,
  scaleValuesMeetWeek,
  // Combined occ
  dataCombined,
  scaleValuesCombined,
  linesDataCombined,
  // Combined occ week
  dataCombinedWeek,
  scaleValuesCombinedWeek,
  linesDataCombinedWeek,
  // Break
  linesDataBreak,
  graphDataBreak,
  scaleValuesBreak,
  // Break week
  linesDataBreakWeek,
  graphDataBreakWeek,
  scaleValuesBreakWeek,
  // Occupancy
  linesDataOcc,
  graphDataOcc,
  onSwitcherChangeOcc,
  returnPercentageSwitcherOcc,
  scaleValuesOcc,
  // Occupancy Week
  linesDataOccWeek,
  graphDataOccWeek,
  scaleValuesOccWeek,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Dashboard" />
          </Grid>
          <Grid item xs={12}>
            <FilterList doorSensorData pageType="OCCUPANCY" subtypes={false} isDashboard />
          </Grid>
          <Grid item xs={12}>
            <ReportDataCards databoxes={allDataboxes} pageType={pageTypes.keyFindings} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
          <Grid item>
            <LiveCounter counter={3} />
          </Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
          <Grid item>
            <DashboardSwitcher
              isSwitcherOn={returnPercentageSwitcherOcc}
              onSwitcherChange={onSwitcherChangeOcc}
              range={range}
              onRangeChange={onRangeChange}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Occupancy */}
      <Grid container spacing={5} item xs={12}>
        <Grid item xs={6}>
          <UsageOverTimeGraph
            isDashboardPage
            title="Building Occupancy During the Current Day"
            isSwitcherOn={returnPercentageSwitcherOcc}
            linesData={linesDataOcc}
            graphData={graphDataOcc}
            config={configOcc}
            scale={scale}
            onSwitcherChange={onSwitcherChangeOcc}
            scaleValues={scaleValuesOcc}
            shouldRenderScale
          />
        </Grid>
        <Grid item xs={6}>
          <UsageOverTimeGraph
            isDashboardPageWeek
            title={`Building Occupancy ${GRAPH_WEEK_HEADER[range]}`}
            isSwitcherOn={returnPercentageSwitcherOcc}
            linesData={linesDataOccWeek}
            graphData={graphDataOccWeek}
            config={configOcc}
            onSwitcherChange={onSwitcherChangeOcc}
            scaleValues={scaleValuesOccWeek}
            scale={scaleWeek}
            shouldRenderScale
            isDashboard
            range={range}
            onRangeChange={onRangeChange}
          />
        </Grid>
      </Grid>
      {/* Combined */}
      <Grid container spacing={5} item xs={12}>
        <Grid item xs={6}>
          <UsageOverTimeGraph
            isDashboardPage
            scale={scale}
            title="Combined Occupancy During the Current Day"
            isSwitcherOn={returnPercentageSwitcherOcc}
            linesData={linesDataCombined}
            graphData={dataCombined}
            config={configCombined}
            onSwitcherChange={onSwitcherChangeOcc}
            scaleValues={scaleValuesCombined}
            shouldRenderScale
            isCombinedDashboard
          />
        </Grid>
        <Grid item xs={6}>
          <UsageOverTimeGraph
            isDashboardPageWeek
            title={`Combined Occupancy ${GRAPH_WEEK_HEADER[range]}`}
            isSwitcherOn={returnPercentageSwitcherOcc}
            linesData={linesDataCombinedWeek}
            graphData={dataCombinedWeek}
            config={configCombined}
            scale={scaleWeek}
            onSwitcherChange={onSwitcherChangeOcc}
            scaleValues={scaleValuesCombinedWeek}
            shouldRenderScale
            label="Integers"
            isCombinedDashboard
          />
        </Grid>
      </Grid>
      {/* Meeting */}
      <Grid container spacing={5} item xs={12}>
        <Grid item xs={6}>
          <UsageOverTimeGraph
            isDashboardPage
            title="Meeting Room Usage During the Current Day"
            isSwitcherOn={returnPercentageSwitcherOcc}
            linesData={linesDataMeet}
            graphData={graphDataMeet}
            config={configMeet}
            scale={scale}
            onSwitcherChange={onSwitcherChangeOcc}
            scaleValues={scaleValuesMeet}
            shouldRenderScale
            label="Integers"
          />
        </Grid>
        <Grid item xs={6}>
          <UsageOverTimeGraph
            isDashboardPageWeek
            title={`Meeting Room Usage ${GRAPH_WEEK_HEADER[range]}`}
            isSwitcherOn={returnPercentageSwitcherOcc}
            linesData={linesDataMeetWeek}
            graphData={graphDataMeetWeek}
            config={configMeet}
            scale={scaleWeek}
            onSwitcherChange={onSwitcherChangeOcc}
            scaleValues={scaleValuesMeetWeek}
            shouldRenderScale
            label="Integers"
          />
        </Grid>
      </Grid>
      {/* Breakout */}
      <Grid container spacing={5} item xs={12}>
        <Grid item xs={6}>
          <UsageOverTimeGraph
            isDashboardPage
            title="Breakout Usage During the Current Day"
            isSwitcherOn={returnPercentageSwitcherOcc}
            linesData={linesDataBreak}
            graphData={graphDataBreak}
            config={configBreak}
            scale={scale}
            onSwitcherChange={onSwitcherChangeOcc}
            scaleValues={scaleValuesBreak}
            shouldRenderScale
            label="Integers"
          />
        </Grid>
        <Grid item xs={6}>
          <UsageOverTimeGraph
            isDashboardPageWeek
            title={`Breakout Usage ${GRAPH_WEEK_HEADER[range]}`}
            isSwitcherOn={returnPercentageSwitcherOcc}
            linesData={linesDataBreakWeek}
            graphData={graphDataBreakWeek}
            config={configBreak}
            scale={scaleWeek}
            onSwitcherChange={onSwitcherChangeOcc}
            scaleValues={scaleValuesBreakWeek}
            shouldRenderScale
            label="Integers"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryDashboard;
