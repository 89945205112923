/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  typography: {
    color: 'rgba(0,0,0,0.6)',
    verticalAlign: 'bottom',
  },
  icon: {
    marginLeft: '5px',
    color: theme.palette.primary.dark,
  },
}));

function Counter({ Icon, total, counter }) {
  const classes = useStyles();

  const hasCounter = counter || counter === 0;

  return (
    <Grid container>
      <Grid item xs={12} align="right">
        <Typography variant="h4" component="span" classes={{ root: classes.typography }}>
          {hasCounter ? counter : <Skeleton width="80px" />}
        </Typography>
        {hasCounter && <Icon classes={{ root: classes.icon }} />}
      </Grid>
      <Grid item xs={12} align="right">
        <Typography component="span" classes={{ root: classes.typography }}>
          {total || total === 0 ? `Out of ${total}` : <Skeleton width="80px" />}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Counter;
