import React from 'react';
import ReactWordcloud from 'react-wordcloud';
import { Paper } from '@material-ui/core';
import { useEditGraphClick, useGraphClick, useModifierGraph } from 'modules/workLook/hooks';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import useGetWords from './useGetWords';
import theme from './WordCloud.module.scss';

const WordCloud = ({
  data,
  isLoading,
  showTitle,
  isModal,
  modalData,
  position,
  isPdf,
  title,
  typeOfGroup,
  isPublished,
  subtitle,
  type,
  graphId,
}) => {
  const callbacks = {
    getWordTooltip: (word) => `[${word.value > 50 ? 'good' : 'bad'}]`,
  };
  const { listOfWords } = useGetWords(data, isLoading);

  const checkPosition = ['7', '8', '9'].includes(position);
  const checkDashboardPosition = ['10', '11'].includes(position);
  const getGraphHeight = isPdf
    ? checkPosition
      ? '660px'
      : '340px'
    : checkPosition
    ? '720px'
    : checkDashboardPosition
    ? '500px'
    : '360px';

  const { onClick } = useGraphClick(isModal, modalData);
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontSizes: isModal ? [35, 60] : [10, 35],
  };

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && !data?.length}>
        <Paper className={theme.container} elevation={2} style={{ maxHeight: getGraphHeight }}>
          <GraphHeader
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={subtitle}
            isPdf={isPdf}
            isPublished={isPublished}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper
          elevation={2}
          className={isModal ? theme.cardContainer : theme.cardContainerModal}
          style={{ maxHeight: getGraphHeight }}
          onClick={onClick}
        >
          <GraphHeader
            showTitle={showTitle}
            graphId={graphId}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <ReactWordcloud callbacks={callbacks} options={options} words={listOfWords} />
        </Paper>
      </Otherwise>
    </Choose>
  );
};

export default WordCloud;
