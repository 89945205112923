import React from 'react';
import PropTypes from 'prop-types';

import './StaticTokensList.scss';

import StaticTokensListItem from 'modules/auth/components/QrConfirmForm/StaticTokensListItem';

const StaticTokensList = ({ tokensList }) => {
  const renderedTokens = tokensList.map((token) => <StaticTokensListItem key={token} token={token} />);

  return (
    <div className="StaticTokensList">
      <ul className="static-tokens-list">{renderedTokens}</ul>
    </div>
  );
};

StaticTokensList.propTypes = {
  tokensList: PropTypes.arrayOf(PropTypes.string),
};

StaticTokensList.defaultProps = {
  tokensList: [],
};

export default StaticTokensList;
