/* eslint-disable prefer-spread */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useReducer, useCallback } from 'react';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, filtersToQuery, toDivisionDeskGraphData } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import {
  SET_BUILDING,
  fetchDeskByDivision,
  fetchDeskDataboxes,
  SET_GRAPH_SWITCHER,
  fetchGraphsText,
  saveGraphsText,
  fetchDeskVariables,
} from 'modules/liveLook/actions';
import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';

import DesksByDivision from './DesksByDivision';
import { setScaleValues } from '../utils';
import reducer, { initialState } from './_reducer';

function DesksByDivisionContainer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    building,
    deskDataboxes,
    databoxRequestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
    deskVariableRequestStatus,
    textVariables,
  } = state;

  const requestStatuses = [databoxRequestStatus, requestStatus, textReportRequestStatus, deskVariableRequestStatus];

  const [companyId] = useAuthSelector(['currentCompany']);
  const [startDate, endDate, workingHours, activeDeskFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'deskFilters.activeDeskFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeDeskFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;

    fetchDeskByDivision(dispatch, isCanceled, {
      companyId,
      buildingId: state.building,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchDeskDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchDeskVariables(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'desk_div',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, state.building, dateAfter, dateBefore, workingHours, query]);

  const databoxesSet = {
    deskDataboxes,
  };

  // eslint-disable-next-line no-shadow
  const mappedData = (data) => {
    const arrData = [];
    for (const key in data) {
      arrData.push(Object.assign(data[key], { name: key }));
    }
    return arrData;
  };

  const peakOccupancy = Math.max.apply(
    Math,
    mappedData(data).map((key) => key[returnPercentageSwitcher ? 'max_occupancy' : 'percentage_max_occupancy']),
  );

  const { scaleValues } = setScaleValues(peakOccupancy);

  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  const onBuildingChange = useCallback((e) => dispatch(SET_BUILDING(e.target.value)), []);

  return (
    <Choose>
      <When condition={requestStatuses.some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={requestStatuses.every((item) => isSuccess(item))}>
        <DesksByDivision
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'desk_div', text });
          }}
          editableTextReport={editableTextReport}
          data={toDivisionDeskGraphData(data, returnPercentageSwitcher)}
          building={building}
          onBuildingChange={onBuildingChange}
          databoxes={deskDataboxes}
          onSwitcherChange={onSwitcherChange}
          returnPercentageSwitcher={returnPercentageSwitcher}
          scaleValues={scaleValues}
          databoxesSet={databoxesSet}
          textVariables={textVariables}
          archive={archive}
        />
      </When>
      <When condition={requestStatuses.some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default DesksByDivisionContainer;
