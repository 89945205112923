/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'modules/shared/utils';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, TableCell } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { ENDPOINTS } from 'modules/shared/constants';
import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import './editableTable.scss';
// Icons
import EditIcon from '@material-ui/icons/EditOutlined';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';
import theme from 'assets/theme';
import CustomTableCell from './CustomTableCell';
import EmptyTableCell from './EmptyTableCell';
import { setHeaders, getFilteredHeaders, getFieldsErrors } from './config';
import GraphHeader from '../GraphHeader';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxHeight: ({ isPdf, getFullHeight }) =>
      isPdf ? (getFullHeight ? '100%' : '330px') : getFullHeight ? '100%' : '380px',
    height: ({ isPdf, getFullHeight }) =>
      isPdf ? (getFullHeight ? '680px' : '330px') : getFullHeight ? '680px' : '380px',
    overflowX: 'auto',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '5px',
    alignItems: 'center',
  },
  inputfile: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    Zindex: '-1',
  },
  label: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

function EditableTable({
  data,
  fieldTypes,
  graphId,
  isLoading,
  isPdf,
  isPublished,
  position,
  typeOfGroup,
  editable = true,
  title,
  sub_title,
  isModal,
  showTitle,
  type,
}) {
  const url = ENDPOINTS.workLook.editTable.replace(':graphId', graphId);
  const { reportId } = useParams();
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  const [rows, setRows] = useState(data);
  const [isSorted, setIsSorted] = useState(false);
  useEffect(() => {
    setRows(data);
  }, [data]);
  const [previous, setPrevious] = useState({});
  const [onEditValues, setOnEditValues] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const [isRowActive, setActiveRow] = useState(false);
  const getFullHeight = ['7', '8', '9', '10', '11'].includes(position);
  const classes = useStyles({ isPdf, getFullHeight });
  const headers = setHeaders(data);
  const filteredHeaders = headers.filter((value) => {
    return value !== 'id';
  });
  const filteredHeadersNoFiles = headers.filter((value) => {
    return value !== 'id' && !'file'.includes(fieldTypes?.[value]);
  });

  const getFilteredHeadersState = useMemo(() => getFilteredHeaders(filteredHeaders), [filteredHeaders]);
  const getFilteredHeadersStateNoFiles = useMemo(
    () => getFilteredHeaders(filteredHeadersNoFiles),
    [filteredHeadersNoFiles],
  );
  const getFieldsErrorsState = useMemo(() => getFieldsErrors(filteredHeaders), [filteredHeaders]);
  const [errors, setErrors] = useState(getFieldsErrorsState);
  const [emptyRow, setEmptyRow] = useState(getFilteredHeadersStateNoFiles);
  const [addRow, setAddRow] = useState(false);
  const [attributeDisable, setAttributeDisable] = useState();

  const hasAllKeys = useMemo(
    () => filteredHeadersNoFiles.every((item) => Object.hasOwnProperty.call(emptyRow, item)),
    [filteredHeadersNoFiles, emptyRow],
  );
  const isNoErrors = useMemo(() => Object.values(errors).every((error) => isEmpty(error)), [errors]);
  const isAllFieldsExist = useMemo(
    () => Object.values(emptyRow).every((field) => !isEmpty(field)) && hasAllKeys && isNoErrors,
    [emptyRow, hasAllKeys, isNoErrors],
  );
  const isAllFieldsExistInRow = useMemo(
    () => Object.values(editedValues).every((field) => !isEmpty(field)),
    [editedValues],
  );

  const onSubmit = (id) => {
    setActiveRow((prev) => !prev);
    setRows(() => {
      return rows.map((row) => {
        const copyRow = { ...row };
        delete copyRow.isEditMode;
        if (copyRow.id === id) {
          const res = Object.entries(copyRow).reduce((acc, [key, value]) => {
            return { [key]: value, ...acc };
          }, {});
          const patchData = {
            data: JSON.stringify(res),
            report_data_id: id,
          };
          API.patch(url, patchData)
            .then(() => {
              return { ...copyRow, isEditMode: !copyRow.isEditMode };
            })
            .catch((err) => err);
        }
        return copyRow;
      });
    });
  };

  const onEditMode = (id) => {
    setActiveRow((prev) => !prev);
    setOnEditValues(rows.find((el) => el.id === id));
    setRows(() => {
      return rows.map((row) => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChangeEmptyRow = (e, row) => {
    const { value, name } = e.target;

    setEmptyRow({ ...row, [name]: value });
  };

  const onAddRowToTable = () => {
    setActiveRow((prev) => !prev);
    const res = Object.entries(emptyRow).reduce((acc, [key, value]) => {
      return { [key]: value, ...acc };
    }, {});
    API.post(url, { data: JSON.stringify(res) })
      .then(() => {
        window.location.reload(false);
        setRows([...rows, emptyRow]);
      })
      .catch((err) => err);

    setEmptyRow(getFilteredHeadersState);
    setAddRow((prev) => !prev);
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const { name, value } = e.target;
    const { id } = row;

    setEditedValues({ ...editedValues, [name]: value });
    const newRows = rows.map((newRow) => {
      if (newRow.id === id) {
        return { ...newRow, [name]: value };
      }
      return newRow;
    });
    setRows(newRows);
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...onEditValues, isEditMode: false };
      }
      return row;
    });

    setRows(newRows);

    setActiveRow((prev) => !prev);
    setErrors(getFieldsErrorsState);
  };

  const onAddRowRevert = () => {
    setEmptyRow(getFilteredHeadersState);
    setAddRow((prev) => !prev);
    setActiveRow((prev) => !prev);
    setErrors(getFieldsErrorsState);
  };

  const addRowBtnHandler = () => {
    setAddRow((prev) => !prev);
    setActiveRow((prev) => !prev);
  };
  const toSortData = (attribute, type) => {
    setAttributeDisable({ attribute, type });
    setIsSorted(!isSorted);
    setRows(() => {
      return rows.sort((a, b) => {
        if (a[attribute] < b[attribute]) {
          return type === 'asc' ? -1 : 1;
        }
        if (a[attribute] > b[attribute]) {
          return type === 'asc' ? 1 : -1;
        }
        return 0;
      });
    });
  };

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && isEmpty(data)}>
        <Paper className="emptyContainer" elevation={2}>
          <GraphHeader
            display={false}
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={sub_title}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper className={classes.root}>
          <If condition={!isPdf}>
            <div style={{ padding: 10 }}>
              <GraphHeader
                display={false}
                showTitle={showTitle}
                graphId={graphId}
                title={title}
                subTitle={sub_title}
                isPdf={isPdf}
                onClick={onEditClick}
                onModifierClick={onModifierClick}
                isPublished={isPublished}
              />
            </div>
          </If>
          <Table>
            <TableHead>
              <TableRow>
                {editable && <TableCell align="left" />}
                {filteredHeaders.map((attribute) => (
                  <TableCell className="attributeContainer" key={attribute} align="left">
                    <div
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <div>{attribute.replace('_', ' ')}</div>
                      <div className="sortedIconsWrapper">
                        <ArrowDropUpTwoToneIcon
                          color={
                            attributeDisable?.attribute === attribute && attributeDisable?.type === 'asc'
                              ? 'disabled'
                              : '#000'
                          }
                          style={{ marginBottom: -5 }}
                          onClick={() => toSortData(attribute, 'asc')}
                          fontSize="small"
                        />
                        <ArrowDropDownTwoToneIcon
                          color={
                            attributeDisable?.attribute === attribute && attributeDisable?.type === 'desc'
                              ? 'disabled'
                              : '#000'
                          }
                          onClick={() => toSortData(attribute, 'desc')}
                          fontSize="small"
                        />
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  {editable && (
                    <TableCell>
                      <If condition={row.isEditMode}>
                        <>
                          <If condition={isAllFieldsExistInRow}>
                            <IconButton disabled={!isNoErrors} aria-label="done" onClick={() => onSubmit(row.id)}>
                              <DoneIcon />
                            </IconButton>
                            <Else />
                            <Typography variant="body2" component="span">
                              Fill all inputs
                            </Typography>
                          </If>
                          <IconButton aria-label="revert" onClick={() => onRevert(row.id)}>
                            <RevertIcon />
                          </IconButton>
                        </>
                        <Else />
                        <IconButton disabled={isRowActive} aria-label="delete" onClick={() => onEditMode(row.id)}>
                          <EditIcon />
                        </IconButton>
                      </If>
                    </TableCell>
                  )}
                  {filteredHeaders.map((header) => (
                    <CustomTableCell
                      key={header}
                      {...{
                        row,
                        name: `${header}`,
                        fieldType: fieldTypes?.[header],
                        onChange,
                        errors,
                        setErrors,
                        classes,
                        editable,
                        reportId,
                      }}
                    />
                  ))}
                </TableRow>
              ))}
              {editable && (
                <TableRow>
                  <Choose>
                    <When condition={!addRow}>
                      <TableCell colSpan={headers.length} style={{ backgroundColor: 'transparent' }}>
                        <Button
                          color="primary"
                          disabled={isRowActive}
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            paddingLeft: '20px',
                          }}
                          onClick={() => addRowBtnHandler()}
                        >
                          + ADD A NEW ROW
                        </Button>
                      </TableCell>
                    </When>

                    <Otherwise>
                      <TableCell className={classes.selectTableCell}>
                        <If condition={isAllFieldsExist}>
                          <>
                            <IconButton aria-label="delete" onClick={() => onAddRowToTable()}>
                              <DoneIcon />
                            </IconButton>
                            <IconButton aria-label="revert" onClick={() => onAddRowRevert()}>
                              <RevertIcon />
                            </IconButton>
                          </>
                          <Else />
                          <>
                            <Typography variant="body2" component="span">
                              Fill all inputs
                            </Typography>
                            <IconButton aria-label="revert" onClick={() => onAddRowRevert()}>
                              <RevertIcon />
                            </IconButton>
                          </>
                        </If>
                      </TableCell>
                      {filteredHeaders.map((header) => (
                        <EmptyTableCell
                          key={header}
                          {...{
                            emptyRow,
                            name: `${header}`,
                            fieldType: fieldTypes?.[header.replace('_', ' ')],
                            onChangeEmptyRow,
                            errors,
                            setErrors,
                            classes,
                          }}
                        />
                      ))}
                    </Otherwise>
                  </Choose>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Otherwise>
    </Choose>
  );
}

export default EditableTable;
