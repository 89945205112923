/* eslint-disable camelcase */
import React, { useState } from 'react';
import cn from 'classnames';

import { Button, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// eslint-disable-next-line import/no-cycle
import { CustomTooltip } from 'modules/shared/components';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import { useEllipsisCalc } from 'modules/spaceLook/hooks';
import { getShortenLabel } from 'modules/shared/utils';

const getDateAndTime = (timestamp) => timestamp.split('.')[0].split('T');

const useStyles = makeStyles((theme) => ({
  item: {
    width: '100%',
    flexBasis: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
    position: 'relative',
    opacity: (isDisabled) => (isDisabled ? '0.5' : ''),
    cursor: (isDisabled) => (isDisabled ? 'default' : ''),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowX: 'hidden',

    '&:hover $label': {
      color: theme.palette.primary.main,
    },
    '&:hover $deleteBtn': {
      visibility: 'visible',
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  disabled: {
    '&:hover $label': {
      color: theme.palette.grey[700],
    },
  },
  reportIcon: {
    position: 'absolute',
    left: '0',
    top: '0',
    color: theme.palette.grey[600],
  },
  label: {
    lineHeight: '24px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  status: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  failedStatus: {
    color: theme.palette.error.main,
  },
  date: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  deleteBtn: {
    visibility: 'hidden',
    position: 'absolute',
    top: '0',
    right: '3px',
    color: theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  closeIcon: {
    width: '0.8em',
    height: '0.8em',
  },
  actions: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pinned: {
    marginLeft: 'auto',
    marginRight: 0,
  },
}));

const DownloadsListItem = ({
  file,
  date_created,
  startDate,
  endDate,
  user,
  name,
  status,
  id,
  customTooltip,
  pinned,
  isPrivate,
  _onDeleteClick,
  isLiveLook,
  _onPinReportClick,
  ...otherProps
}) => {
  const label = name || user;
  const isDisabled = status === 'In progress';

  const [isEllipsis, ref] = useEllipsisCalc();
  const classes = useStyles(isDisabled);
  // eslint-disable-next-line no-unused-vars
  const [isCopied, setIsCopied] = useState(false);
  return (
    <div className={cn(classes.item, { [classes.disabled]: isDisabled })}>
      <a href={file} target="_blank" rel="noopener noreferrer">
        <div>
          <DescriptionIcon classes={{ root: classes.reportIcon }} />
          <CustomTooltip arrow title={customTooltip ? customTooltip(otherProps) : isEllipsis ? label : ''}>
            <p className={classes.label} ref={ref}>
              {getShortenLabel(label, 20)}
            </p>
          </CustomTooltip>
          <Choose>
            <When condition={isDisabled}>
              <p className={classes.status}>In progress...</p>
            </When>
            <When condition={status === 'Error'}>
              <p className={cn(classes.failedStatus, classes.status)}>Failed</p>
            </When>
          </Choose>

          <p className={classes.date}>
            <Choose>
              <When condition={startDate && endDate}>
                From {startDate} to {endDate}
              </When>
              <When condition={date_created}>
                Created: {getDateAndTime(date_created)[0]} at {getDateAndTime(date_created)[1]}
              </When>
            </Choose>
          </p>
        </div>
      </a>
      <div className={classes.actions}>
        {isPrivate && (
          <div>
            <CopyToClipboard text={file} onCopy={() => setIsCopied(true)}>
              <Button size="small">Copy</Button>
            </CopyToClipboard>
          </div>
        )}
        {isLiveLook && (
          <IconButton
            title={pinned ? 'Unpin' : 'Pin to top'}
            size="small"
            classes={{ root: classes.pinned }}
            onClick={() => _onPinReportClick(id, pinned)}
          >
            {
              // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
              pinned ? <StarIcon color="primary" /> : <StarOutlineIcon />
            }
          </IconButton>
        )}
      </div>
      <If condition={_onDeleteClick && !isDisabled}>
        <IconButton size="small" classes={{ root: classes.deleteBtn }} onClick={(e) => _onDeleteClick(e, id)}>
          <CloseIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
      </If>
    </div>
  );
};

export default DownloadsListItem;
