/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import URLS from 'pages/constants/urls';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID } from 'modules/assetLook/constants';

// eslint-disable-next-line import/no-cycle
import BuildingItem from './BuildingItem';

const BuildingItemContainer = ({ categories, building_id, ...props }) => {
  const history = useHistory();
  const { state } = useLocation();

  const { buildingId } = useLocationSearchParams([BUILDING_ID]);

  const isActiveBuilding = get(state, 'isBuilding', false) && building_id === Number(buildingId);

  const [expand, setExpand] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isActiveBuilding ? setExpand(true) : setExpand(false);
  }, [buildingId, isActiveBuilding]);

  const _onItemClick = () => {
    setExpand(!expand);

    if (!isActiveBuilding) {
      const url = isEmpty(categories)
        ? URLS.assetLook.productList.concat(`?${BUILDING_ID}=${building_id}`)
        : URLS.assetLook.productList
            .concat(`?${BUILDING_ID}=${building_id}`)
            .concat(`&${CATEGORY_ID}=${categories[0].category_id}`);

      history.push(url, { isBuilding: true });
    }
  };

  return (
    <BuildingItem
      building_id={building_id}
      categories={categories}
      isAccordionOpen={expand && isActiveBuilding}
      _onItemClick={_onItemClick}
      {...props}
    />
  );
};

export default BuildingItemContainer;
