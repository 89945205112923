import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { isSuccess } from 'modules/shared/utils';
import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID, PRODUCT_ID } from 'modules/assetLook/constants';
import { APPLY_SUMMARY_FIELDS, fetchSummaryFields } from 'modules/assetLook/actions';

import FieldsSelect from './FieldsSelect';

function FieldsSelectContainer() {
  const dispatch = useDispatch();

  const { id: companyId } = useSelector(getActiveCompany);
  const { buildingId, categoryId, productId } = useLocationSearchParams([BUILDING_ID, CATEGORY_ID, PRODUCT_ID]);

  const [summaryFieldsRequestStatus, summaryFields, summaryFieldsActive] = useAssetLookSelector([
    'summary.fieldsRequestStatus',
    'summary.fields',
    'summary.fieldsActive',
  ]);

  useEffect(() => {
    let isCanceled = false;

    // eslint-disable-next-line no-unused-expressions
    isEmpty(summaryFields) && fetchSummaryFields(dispatch, companyId, isCanceled);

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, buildingId, categoryId, productId, summaryFields, dispatch]);

  const [value, setValue] = useState(summaryFieldsActive);

  useEffect(() => {
    setValue(summaryFieldsActive);
  }, [summaryFieldsActive, summaryFieldsRequestStatus]);

  const onSelectChange = (e) => !isEmpty(e.target.value) && setValue(e.target.value);

  const onSelectClose = () => dispatch(APPLY_SUMMARY_FIELDS(value));

  return (
    <FieldsSelect
      summaryFields={summaryFields}
      disabled={!isSuccess(summaryFieldsRequestStatus) || isEmpty(summaryFields)}
      value={value}
      onSelectChange={onSelectChange}
      onSelectClose={onSelectClose}
    />
  );
}

export default FieldsSelectContainer;
