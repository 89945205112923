/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import axios from 'axios';

import isLogged from 'utils/isLogged';
import { useDispatch } from 'react-redux';
import { toQuery } from 'modules/workLook/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { SET_WIDGETS_DATA } from '../actions';

const useControlCardData = (id, activeFilters, graphData, isModal, isDataTable) => {
  const dispatch = useDispatch();
  if (graphData) return { isLoading: false, data: graphData };
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const query =
    isModal && isDataTable ? toQuery(activeFilters).concat('?disable_limiting=True') : toQuery(activeFilters);

  useEffect(() => {
    let canceled = false;

    setIsLoading(true);

    const url = ENDPOINTS.workLook.graph.replace(':graphId', id);
    const config = {
      headers: { Authorization: `Token ${isLogged()}` },
    };

    axios
      .get(`${url}${query}`, config)
      .then(({ data }) => {
        if (canceled) return;
        setData(data);
        dispatch(SET_WIDGETS_DATA({ widgetsData: data[0].data.widget_data }));
        setIsLoading(false);
      })
      .catch((err) => {
        if (canceled) return;
        console.error(err);
      });
    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, query]);

  return { isLoading, data };
};

export default useControlCardData;
