/* eslint-disable camelcase */
import React from 'react';

import { Fab } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-cycle
import { CreateReportModal } from 'modules/workLook/components';

import { useSelector } from 'react-redux';
import { isAdmin, isPartner, isViewOnly } from 'modules/shared/utils';
import { getUser } from 'modules/auth/selectors';
import theme from './CreateReportButton.module.scss';

// eslint-disable-next-line no-shadow
const useFabStyles = makeStyles((theme) => ({
  fab: {
    minHeight: '48px',
    marginRight: 'auto',
    marginBottom: '16px',
    backgroundColor: 'white',
    boxShadow: '0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149)',
    '&:hover': {
      backgroundColor: '#fafafb',
      boxShadow: '0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)',
    },
    '& .MuiTouchRipple-root span': {
      color: theme.palette.primary.main,
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    fontSize: '32px',
  },
}));

const CreateReportButton = ({ isLoading, open, _handleOpen, _handleClose }) => {
  const classes = useFabStyles();
  const { user } = useSelector(getUser);
  const { people_look_editing_rights, role } = user;
  const isAdminRole = isAdmin(role) || isPartner(role);

  const hasViewRights = isViewOnly(people_look_editing_rights);

  return (
    <>
      <Choose>
        <When condition={isLoading}>
          <Skeleton className={theme.fabSkeleton} variant="rect" width={183} height={48} />
        </When>
        <Otherwise>
          <If condition={isAdminRole || !hasViewRights}>
            <Fab variant="extended" className={classes.fab} onClick={_handleOpen}>
              <AddIcon className={classes.extendedIcon} />
              Create report
            </Fab>
          </If>
        </Otherwise>
      </Choose>

      <If condition={open}>
        <CreateReportModal open={open} _handleClose={_handleClose} />
      </If>
    </>
  );
};

export default CreateReportButton;
