import React from 'react';
import UserTableHeader from './components/UserTableHeader';
import UserTableRow from './components/UserTableRow/UserTableRow';

const HEADERS_NAME = ['Email', 'First name', 'Last name', 'Role', 'Group'];

const UserTable = ({ companyUsers }) => {
  return (
    <div>
      <UserTableHeader headers={HEADERS_NAME} />
      <UserTableRow users={companyUsers} />
    </div>
  );
};

export default UserTable;
