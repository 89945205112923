/* eslint-disable react/no-children-prop */
import React from 'react';

import { Card, CardContent, CardHeader, CardActions, Grid, Typography, withStyles, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// eslint-disable-next-line import/no-cycle
import { OrderInfoSkeleton } from './components';

const OrderItemSkeleton = ({ classes, hasActions }) => (
  <Card elevation={2}>
    <CardHeader
      title={
        <Grid container justify="space-between" spacing={0}>
          <Grid item>
            <Typography variant="h6">
              <Skeleton width={100} />
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="h6">
              <Skeleton width={150} />
            </Typography>
          </Grid>
        </Grid>
      }
    />
    <CardContent classes={{ root: classes.cardContent }}>
      <OrderInfoSkeleton />
      <Skeleton height={56} width="100%" />
      <Skeleton height={56} width="100%" />
    </CardContent>
    <If condition={hasActions}>
      <CardActions classes={{ root: classes.cardActions }}>
        <Skeleton>
          <Button size="small" children="Reject" />
        </Skeleton>
        <Skeleton>
          <Button size="small" children="Approve" />
        </Skeleton>
      </CardActions>
    </If>
  </Card>
);

export default withStyles({
  subheader: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  cardContent: {
    paddingTop: '0',
  },
  cardActions: {
    justifyContent: 'flex-end',
    padding: '0 12px 12px',
  },
})(OrderItemSkeleton);
