const getBlankReport = ({ workLook }) => ({
  blankReportData: workLook.blankReport.blankReportData,
  reportFiles: workLook.blankReport.reportFiles,
  csvData: workLook.blankReport.csvData,
  typeId: workLook.blankReport.typeId,
  additionalFile: workLook.blankReport.additionalFile,
  mergedFields: workLook.blankReport.mergedFields,
});

export default getBlankReport;
