const config = {
  graph: [
    {
      title: 'Monday',
      key: 'Monday',
    },
    {
      title: 'Tuesday',
      key: 'Tuesday',
    },
    {
      title: 'Wednesday',
      key: 'Wednesday',
    },
    {
      title: 'Thursday',
      key: 'Thursday',
    },
    {
      title: 'Friday',
      key: 'Friday',
    },
    {
      title: 'Saturday',
      key: 'Saturday',
    },
    {
      title: 'Sunday',
      key: 'Sunday',
    },
  ],
};

export default config;
