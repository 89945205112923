/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Typography, Box, Checkbox } from '@material-ui/core';

import { CustomTooltip } from 'modules/shared/components';

import theme from './PageRow.module.scss';

const PageRow = ({ pageCounter, name, activeFilters, checked, _onPageClick }) => {
  const filteredFilters = activeFilters.filter(({ values }) => values.length);

  return (
    <div className={theme.pageRow} onClick={_onPageClick}>
      <Typography variant="body1" className={theme.text}>
        <Box fontWeight="fontWeightMedium" component="span">
          {`${name} `}
        </Box>
        <If condition={filteredFilters.length}>
          {filteredFilters.map(({ field, values }, i) => (
            <CustomTooltip key={i} title={values.join(', ')} arrow interactive>
              <Box component="span">{`${field}(${values.length})${i === filteredFilters.length - 1 ? '' : ', '}`}</Box>
            </CustomTooltip>
          ))}
        </If>
      </Typography>
      <div className={theme.pageCounter}>{pageCounter}</div>
      <div className={theme.checkbox}>
        <Checkbox checked={checked} color="primary" disableRipple style={{ padding: '0' }} />
      </div>
    </div>
  );
};

export default PageRow;
