import React from 'react';
// eslint-disable-next-line import/no-named-default
import { default as ReactDatePicker } from 'react-date-picker';
import cn from 'classnames';

import calendarIcon from 'assets/images/icons/calendar.svg';
import theme from './DatePicker.module.scss';

const DatePicker = ({ title, minDate, maxDate, value, handleDateChange, highlight }) => {
  const calendarImg = <img className="icon" src={calendarIcon} alt="icon" />;

  return (
    <div className={cn(theme.container, { [`${theme.highlight}`]: highlight })}>
      <h4 className={theme.title}>{title}</h4>
      <ReactDatePicker
        format="dd/MM/yy"
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={(newDate) => {
          if (newDate.toDateString() === value.toDateString()) return;
          handleDateChange(newDate);
        }}
        clearIcon={null}
        locale="en"
        calendarClassName="filter-calendar"
        calendarIcon={calendarImg}
      />
    </div>
  );
};

export default DatePicker;
