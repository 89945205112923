import React, { useContext } from 'react';
import { RichUtils } from 'draft-js';

import TextEditorContext from 'modules/shared/components/TextEditor/TextEditorContext';

import ActionButtons from './ActionButtons';

const ActionButtonsContainer = () => {
  const { editorState, setEditorState } = useContext(TextEditorContext);

  const _onBoldClick = (evt) => {
    evt.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const _onItalicClick = (evt) => {
    evt.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  };

  const _onUnderlineClick = (evt) => {
    evt.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const _onOrderedListClick = (evt) => {
    evt.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, 'ordered-list-item'));
  };

  const _onUnorderedListClick = (evt) => {
    evt.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'));
  };

  return (
    <ActionButtons
      _onBoldClick={_onBoldClick}
      _onItalicClick={_onItalicClick}
      _onUnderlineClick={_onUnderlineClick}
      _onOrderedListClick={_onOrderedListClick}
      _onUnorderedListClick={_onUnorderedListClick}
    />
  );
};

export default ActionButtonsContainer;
