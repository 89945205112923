/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import { get } from 'lodash';
import cn from 'classnames';

import { SORT_ORDER, SECTION_PAGE_TYPES } from 'modules/workLook/constants';

import { GraphsPagePattern, SummaryPagePattern } from 'pages/_privatePages/workLook/SectionPage/components';

import { ReportContentPage, ReportSubTitlePage, ReportTitlePage, ReportLastPage } from 'pages/_privatePages/shared';

import BSLogoWhite from 'assets/images/bsl-logo-mini-white.png';
import BSLogoColor from 'assets/images/bsl-logo-mini-color.png';

import theme from './PrintablePdfPage.module.scss';

const PrintablePdfPage = ({
  company,
  name: reportName,
  is_published,
  date_published,
  image,
  title_text_color,
  sections,
  partner_company,
  closing_image,
  section_image,
}) => {
  return (
    <div className={theme.container}>
      <div className={theme.servicePage}>
        <ReportTitlePage
          title={company.name}
          subTitle={reportName}
          middleText={`Publication Date: ${is_published ? date_published : 'Unpublished'}`}
          image={image}
          textColor={title_text_color}
          backgroundColor={!is_published ? 'secondary.dark' : undefined}
        />
      </div>
      <For each="section" of={Object.values(sections).sort(SORT_ORDER.byOrder)}>
        <Fragment key={section.id}>
          <div className={theme.servicePage}>
            <ReportSubTitlePage
              sectionImage={section_image}
              closingImage={closing_image}
              firstLogo={partner_company ? partner_company.report_logo : BSLogoWhite}
              secondLogo={company.report_logo}
              title={section.title}
              backgroundColor={!is_published ? 'secondary.dark' : undefined}
            />
          </div>
          {section.pages.map((page, i) => (
            <div key={i} className={cn(theme.pdfPage, theme.hasPageCounter)}>
              <ReportContentPage
                title={page.name}
                activeFilters={get(page, 'activeFilters', []).filter(({ values }) => values.length)}
                firstLogo={partner_company ? partner_company.report_logo_color : BSLogoColor}
                secondLogo={company.report_logo_color}
                isPdf
              >
                {() => (
                  <Choose>
                    <When condition={page.type === SECTION_PAGE_TYPES.summary}>
                      <SummaryPagePattern page={page} isPdf />
                    </When>
                    <When condition={page.type === SECTION_PAGE_TYPES.graphs}>
                      <GraphsPagePattern page={page} isPdf isPeopleLook />
                    </When>
                  </Choose>
                )}
              </ReportContentPage>
            </div>
          ))}
        </Fragment>
      </For>
      <div className={theme.servicePage}>
        <ReportLastPage closingImage={closing_image} />
      </div>
    </div>
  );
};

export default PrintablePdfPage;
