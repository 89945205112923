import React from 'react';
import { Grid } from '@material-ui/core';

import { PAGE_TYPES } from 'modules/assetLook/constants';

import {
  Counter,
  PageHeader,
  SummaryTable,
  PageSelectorBar,
  FilterListCategory,
  ProductDetailsCard,
  FetchProductsHandler,
} from 'modules/assetLook/components';

const ProductSummaryPage = () => (
  <FetchProductsHandler>
    <Grid
      container
      spacing={4}
      direction="column"
      wrap="nowrap"
      style={{
        height: 'calc(100% + 32px)',
      }}
    >
      <Grid item>
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PageHeader />
              </Grid>
              <Grid item xs={12}>
                <PageSelectorBar />
              </Grid>
              <Grid item xs={12}>
                <FilterListCategory />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Counter pageType={PAGE_TYPES.productSummary} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ flex: '1 1 1px' }}>
        <div style={{ height: '35%', maxHeight: '35%', paddingBottom: '8px' }}>
          <ProductDetailsCard pageType={PAGE_TYPES.productSummary} />
        </div>
        <div style={{ height: '65%', maxHeight: '65%', paddingTop: '8px' }}>
          <SummaryTable />
        </div>
      </Grid>
    </Grid>
  </FetchProductsHandler>
);

export default ProductSummaryPage;
