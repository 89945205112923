/* eslint-disable consistent-return */
import { createReducer } from '@reduxjs/toolkit';

import { FILTERS, filtersPageTypes, weekday } from 'modules/liveLook/constants';
import { makeDefaultActiveFilters } from 'modules/shared/utils';
import { APPLY_FILTERS, FETCH_COMMON_DATA, RESET_FILTERS, RESET_STATE } from 'modules/liveLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  initDeskFilters: null,
  activeDeskFilters: null,
};

export default createReducer(initState, {
  [FETCH_COMMON_DATA.success]: (state, { payload }) => {
    if (!payload) {
      // eslint-disable-next-line no-console
      console.error('FETCH_COMMON_DATA::No payload', payload);
      return;
    }

    const { buildings, filters } = payload;
    const deskFilters = filters[filtersPageTypes.desks];

    const initDeskFilters = [weekday];

    initDeskFilters.push({
      field: FILTERS.floor,
      values: deskFilters.floor,
    });

    initDeskFilters.push({
      field: FILTERS.subtype,
      values: deskFilters.subtype,
    });

    initDeskFilters.push({
      field: FILTERS.division,
      values: deskFilters.division,
    });

    initDeskFilters.push({
      field: FILTERS.neighbourhood,
      values: deskFilters.neighbourhood,
    });

    initDeskFilters.push({
      field: FILTERS.building_id,
      // eslint-disable-next-line camelcase
      values: buildings.map(({ building__property_name, building_id }) => ({
        label: building__property_name,
        value: building_id,
      })),
    });

    return {
      ...state,
      initDeskFilters,
      activeDeskFilters: makeDefaultActiveFilters(initDeskFilters),
    };
  },
  [FETCH_COMMON_DATA.failure]: (state) => {
    return state;
  },
  [APPLY_FILTERS]: (state, { payload: { field, values } }) => ({
    ...state,
    activeDeskFilters: state.activeDeskFilters.map((filter) =>
      filter.field === field ? { field, values } : { ...filter },
    ),
  }),
  [RESET_FILTERS]: (state) => ({
    ...state,
    activeDeskFilters: makeDefaultActiveFilters(state.initDeskFilters),
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
