/* eslint-disable no-console */

import { fetchCompanies, fetchCompanyUsers, fetchPartnerCompanies } from 'modules/auth/actions';
import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { useDispatch } from 'react-redux';

const useUserSettingsModal = (isModal, activeCompany) => {
  if (isModal) {
    return { onClick: null };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const onClick = async () => {
    fetchCompanyUsers(dispatch, activeCompany);
    fetchCompanies(dispatch);
    fetchPartnerCompanies(dispatch);
    dispatch(showModal(modalCodes.USER_SETTINGS));
  };

  return { onUserSettingsModalClick: onClick };
};

export default useUserSettingsModal;
