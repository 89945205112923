import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { omit } from 'lodash';
import { Typography, Box } from '@material-ui/core';

import { dashToSlash } from 'modules/spaceLook/utils';
import { reportPageTypes } from 'modules/spaceLook/constants';
import { getAppliedFilters, getReportInfo } from 'modules/spaceLook/selectors';

import buildings from 'assets/images/buildings.png';
import buildingsBig from 'assets/images/buildings1-5x.png';

import config from 'components/Filters/ReportFilter/config';
import theme from './ReportPdfSubTitle.module.scss';

const ReportPdfSubTitle = ({ type }) => {
  let subTitleName;
  let dataKey;
  // eslint-disable-next-line default-case
  switch (type) {
    case reportPageTypes.desks:
      subTitleName = 'Desk Utilisation';
      dataKey = 'desks';
      break;
    case reportPageTypes.meetings:
      subTitleName = 'Meeting Room Utilisation';
      dataKey = 'meetings';
      break;
    case reportPageTypes.breakout:
      subTitleName = 'Breakout Space Utilisation';
      dataKey = 'breakout';
      break;
  }

  const { subTitleImage, subTitleTextColor, startDate, endDate } = useSelector(getReportInfo);
  const { appliedFilters } = useSelector((store) => getAppliedFilters(store, dataKey));

  const hasFiltersApplied = Object.values(appliedFilters).some((filterValue) =>
    Array.isArray(filterValue) ? filterValue.length : filterValue,
  );

  const imageSrc = subTitleImage || (window.devicePixelRatio === 2 ? buildings : buildingsBig);

  return (
    <div
      className={cn(theme.container, 'pdfPage')}
      style={{ backgroundImage: `url(${imageSrc})`, color: subTitleTextColor }}
    >
      <div className={theme.filterContainer}>
        <If condition={hasFiltersApplied}>
          <Typography variant="h5" paragraph className={theme.filterText}>
            <Box fontWeight="fontWeightMedium" component="span">
              Applied filters
            </Box>
          </Typography>
          <table className={theme.filterTable}>
            <tbody>
              <If condition={appliedFilters.from_date || appliedFilters.to_date}>
                <tr className={theme.filterRow}>
                  <td className={theme.filterCategory}>
                    <Typography variant="h6" className={theme.filterText}>
                      <Box fontWeight="fontWeightMedium" component="span">
                        Date range:{' '}
                      </Box>
                    </Typography>
                  </td>
                  <td className={theme.filterOptions}>
                    <Typography variant="h6" className={theme.filterText}>
                      <Box component="span">
                        {`${dashToSlash(appliedFilters.from_date || startDate)}
                           - 
                           ${dashToSlash(appliedFilters.to_date || endDate)}`}
                      </Box>
                    </Typography>
                  </td>
                </tr>
              </If>
              {Object.keys(omit(appliedFilters, ['from_date', 'to_date'])).map((filterKey) => {
                if (!Array.isArray(appliedFilters[filterKey]) || appliedFilters[filterKey].length < 1) return;

                const options =
                  filterKey === 'day_of_week'
                    ? appliedFilters[filterKey]
                        .map((item) => item)
                        .sort((a, b) => a - b)
                        .map((item) => config[filterKey].weekDays[item])
                        .join(', ')
                        .trim()
                    : appliedFilters[filterKey].join(', ').trim();

                // eslint-disable-next-line consistent-return
                return (
                  <tr key={filterKey} className={theme.filterRow}>
                    <td className={theme.filterCategory}>
                      <Typography variant="h6" className={theme.filterText}>
                        <Box fontWeight="fontWeightMedium" component="span">{`${config[filterKey].title}: `}</Box>
                      </Typography>
                    </td>
                    <td className={theme.filterOptions}>
                      <Typography variant="h6" className={theme.filterText}>
                        <Box component="span">{options}</Box>
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </If>
      </div>

      <div className={theme.subTitleContainer}>
        <Typography variant="h3" className={theme.subTitle}>
          {subTitleName}
        </Typography>
      </div>
    </div>
  );
};

export default ReportPdfSubTitle;
