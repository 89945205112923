/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { RESET_FILTERS } from 'modules/liveLook/actions';
import { useDispatch } from 'react-redux';
import theme from './ReportNavItem.module.scss';

const ReportNavItem = ({
  Icon,
  label,
  dashboard,
  archive,
  isOpen,
  items,
  pathname: currentPath,
  onClick,
  hasArchive,
}) => {
  const dispatch = useDispatch();
  const isDashboardActive = currentPath === '/live-look/executive-summary/dashboard';
  const isArchiveActive = currentPath === '/live-look/archives';

  const resetFiltersHandler = () => {
    dispatch(RESET_FILTERS());
  };
  const disableDashboard = hasArchive && dashboard;

  return (
    <>
      <ListItem disabled={disableDashboard} onClick={disableDashboard ? '' : onClick} className={theme.listItem}>
        <ListItemIcon className={theme.icon}>
          {typeof Icon === 'string' ? <img src={Icon} alt="" /> : <Icon />}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            className: cn(theme.text, {
              [`${theme.active}`]: (isDashboardActive && dashboard) || (isArchiveActive && archive),
              [`${theme.disable}`]: disableDashboard,
            }),
          }}
        />
        {!dashboard && !archive && (
          <>{isOpen ? <ArrowDropUpIcon className={theme.arrow} /> : <ArrowDropDownIcon className={theme.arrow} />}</>
        )}
      </ListItem>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List>
          {items.map(({ text, path }, i) => {
            const LinkComponent = path ? Link : 'a';

            return (
              <LinkComponent onClick={() => resetFiltersHandler()} to={path} key={i}>
                <ListItem className={theme.listSubItem} disabled={!path}>
                  <ListItemText
                    primary={text}
                    inset
                    className={theme.textContainer}
                    primaryTypographyProps={{
                      className: cn(theme.text, { [`${theme.active}`]: path === currentPath }),
                    }}
                  />
                </ListItem>
              </LinkComponent>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default ReportNavItem;
