import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { isDefault, isLoading } from 'modules/shared/utils';
import { getReports } from 'modules/workLook/selectors';

import CreateReportButton from './CreateReportButton';

const CreateReportButtonContainer = () => {
  const { requestStatus } = useSelector(getReports);

  const [open, setOpen] = useState(false);
  const _handleOpen = () => setOpen(true);
  const _handleClose = () => setOpen(false);

  return (
    <CreateReportButton
      isLoading={isDefault(requestStatus) || isLoading(requestStatus)}
      open={open}
      _handleOpen={_handleOpen}
      _handleClose={_handleClose}
    />
  );
};

export default CreateReportButtonContainer;
