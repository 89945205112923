import React, { useEffect } from 'react';
import cn from 'classnames';
import axios from 'axios';

import { isLogged } from 'utils';
import { REQUESTS } from '_constants';
import { getData } from 'services';
import { Loader, MainButton } from 'components';

import { requestStatuses } from 'modules/shared/constants';
import useFilterReducer from 'components/Pages/SummaryPage/hooks/useFilterReducer';
import { setSelectedFilters, setAvailableFilters, SET_AVAILABLE_FILTERS } from 'components/Pages/SummaryPage/actions';

import FiltersHeader from './FiltersHeader';
import FilterList from './FilterList';

import './Filters.scss';

const {
  base,
  version,
  summary: { filter },
} = REQUESTS;

const Filters = ({ activeCompany, applyFilters }) => {
  const token = isLogged();
  const [{ requestStatus, availableFilters, selectedFilters, filtersQuery }, dispatch] = useFilterReducer();

  useEffect(() => {
    dispatch({ type: SET_AVAILABLE_FILTERS.pending });

    const filterUrl = `${base}${version}/company/${activeCompany}${filter}${filtersQuery}`;

    axios
      .all([getData(filterUrl, token)])
      .then((data) => dispatch(setAvailableFilters(...data, '', [])))
      .catch(() => dispatch({ type: SET_AVAILABLE_FILTERS.failure }));
  }, [activeCompany, dispatch, filtersQuery, token]);

  if (availableFilters.length < 1) return <Loader />;

  return (
    <div className="summaryPageFilters">
      <FiltersHeader />

      <div className={cn('filterListsContainer', { 'low-blured': requestStatus !== requestStatuses.success })}>
        <If condition={requestStatus !== requestStatuses.success}>
          <Loader />
        </If>
        {Object.keys(availableFilters).map((listCategory) => (
          <FilterList
            onCheckboxClick={(label) => dispatch(setSelectedFilters(listCategory, label))}
            key={listCategory}
            listCategory={listCategory}
            filters={availableFilters[listCategory]}
            selectedFilters={selectedFilters[listCategory]}
          />
        ))}
      </div>

      <div className="footer">
        <MainButton func={() => applyFilters(filtersQuery)} text="Apply" />
      </div>
    </div>
  );
};

export default Filters;
