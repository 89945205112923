import React from 'react';
import PropTypes from 'prop-types';

import { Copyright } from 'modules/shared/components';
import defaultLogo from 'assets/images/icons/logo-with-text.svg';
import { ClientName, StaticHeaderBanner, InputsToggler, WithIconLink } from '../..';

import { REDIRECT_URLS } from '../../../_constants';

import summary from '../../../assets/images/icons/summary-look.svg';
import details from '../../../assets/images/icons/details-look.svg';

import './StaticSidebar.scss';

const StaticSidebar = ({
  clientName,
  items,
  onItemClick,
  currentItem,
  togglerTitle,
  logoUrl,
  summaryButton,
  detailsButton,
  hideSummary,
  hideDetails,
}) => {
  const { pathname } = window.location;
  const checkIsActiveLink = (pagePath) => pathname === pagePath;

  const inputsToggler = items && (
    <InputsToggler items={items} currentItem={currentItem} onClick={onItemClick} title={togglerTitle} />
  );

  return (
    <nav className="StaticSidebar">
      <div className="static-sidebar">
        <StaticHeaderBanner logoUrl={logoUrl || defaultLogo} />
        <ClientName name={clientName} />
        {inputsToggler}
        <If condition={!hideSummary}>
          <WithIconLink {...summaryButton} isActive={checkIsActiveLink(summaryButton.link)} />
        </If>
        <If condition={!hideDetails}>
          <WithIconLink {...detailsButton} isActive={checkIsActiveLink(detailsButton.link)} />
        </If>
        <Copyright />
      </div>
    </nav>
  );
};

StaticSidebar.defaultProps = {
  clientName: 'ClientName',
  copyrightContent: ['Powered by Baker Stuart Worklook', '©BakerStuart Limited 2019'],
  togglerTitle: 'Change company',
  summaryButton: {
    label: 'Property summary',
    icon: summary,
    link: REDIRECT_URLS.summary,
  },
  detailsButton: {
    label: 'Property details',
    icon: details,
    link: REDIRECT_URLS.details,
  },
};

StaticSidebar.propTypes = {
  clientName: PropTypes.string,
  copyrightContent: PropTypes.arrayOf(PropTypes.string),
  togglerTitle: PropTypes.string,
  summaryButton: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.string,
  }),
  detailsButton: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default StaticSidebar;
