import { pick } from 'lodash';

const keysToPick = ['agilityDataboxes', 'agilityGraphData'];

const getDesksAgilityData = ({ report: { desks } }) => ({
  dataRequestStatuses: pick(desks.requestStatuses, keysToPick),
  data: pick(desks.data, keysToPick),
  shouldRefetchData: pick(desks.shouldRefetchData, keysToPick),
});

export default getDesksAgilityData;
