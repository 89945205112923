import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 16px',
    textTransform: 'capitalize',
    backgroundColor: ({ isActive }) => (isActive ? theme.palette.primary.light : theme.palette.grey[200]),
  },
}));

function ActiveFilterList({ variant = 'contained', isActive, disabled, startIcon, children, isPdf }) {
  const classes = useStyles({ isActive });

  return (
    <Button
      disabled={disabled}
      variant={variant}
      classes={{ root: classes.root }}
      startIcon={startIcon}
      // eslint-disable-next-line react/no-children-prop
      children={children}
      style={
        isPdf && {
          padding: 2,
          height: 23,
          fontSize: 11,
          fontWeight: 'normal',
        }
      }
    />
  );
}

export default ActiveFilterList;
