import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

import { NABlock } from 'components';

const constructGraph = ({ data, options }) => (
  <Choose>
    <When condition={!data.length}>
      <NABlock height={100} />
    </When>

    <Otherwise>
      {data.map((graphData, idx) => (
        <div key={idx} className="single-bar">
          <div className="single-bar-label">{graphData.labels[0]}</div>
          <Choose>
            <When condition={graphData.datasets.length}>
              <div className="single-graph">
                <HorizontalBar height={30} data={graphData} options={options} />
              </div>
            </When>
            <Otherwise>
              <NABlock />
            </Otherwise>
          </Choose>
        </div>
      ))}
    </Otherwise>
  </Choose>
);

export default constructGraph;
