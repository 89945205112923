/* eslint-disable camelcase */
import React from 'react';
import { Radar } from 'react-chartjs-2';

import { Grid, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { TextPlaceholder } from 'modules/shared/components';

import { useEditGraphClick, useGraphClick, useModifierGraph } from 'modules/workLook/hooks';
import options from './options';
import theme from './RadarChart.module.scss';

const RadarChart = ({
  title,
  sub_title,
  isLoading,
  data,
  groupType,
  is_display_legend: showLegend,
  is_display_title: showTitle,
  axis_min_limit: min,
  axis_max_limit: max,
  isModal,
  isPublished,
  modalData,
  graphId,
  isPdf,
  type,
}) => {
  const { onClick } = useGraphClick(isModal, modalData);
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, groupType);

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && !data}>
        <Paper elevation={2} className={theme.container}>
          <GraphHeader
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={sub_title}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper elevation={2} className={isModal ? theme.container : theme.containerModal} onClick={onClick}>
          <Grid container spacing={2} direction="column" className={theme.gridContainer}>
            <Grid item>
              <GraphHeader
                showTitle={showTitle}
                graphId={graphId}
                title={title}
                subTitle={sub_title}
                isPdf={isPdf}
                onClick={onEditClick}
                onModifierClick={onModifierClick}
                isPublished={isPublished}
              />
            </Grid>
            <Grid item className={theme.innerContainer}>
              <Radar
                data={data}
                options={{
                  ...options,
                  legend: {
                    ...options.legend,
                    display: groupType && showLegend,
                  },
                  scale: {
                    ticks: {
                      min: Number.isFinite(min) ? min : undefined,
                      max: Number.isFinite(max) ? max : undefined,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Otherwise>
    </Choose>
  );
};

export default RadarChart;
