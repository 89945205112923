import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';
import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

export const FETCH_COMBINED_AVAILABLE_FILTERS = createAsyncAction(createAction('FETCH_COMBINED_AVAILABLE_FILTERS'));

const fetchCombinedAvailableFilters = (dispatch, id, params) => {
  dispatch(FETCH_COMBINED_AVAILABLE_FILTERS.pending());

  const token = isLogged();
  const url = ENDPOINTS.combinedInitFilters.replace(':id', id);
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  axios
    .get(`${url}?${params}`, config)
    .then(({ data }) => dispatch(FETCH_COMBINED_AVAILABLE_FILTERS.success(data)))
    .catch(() => dispatch(FETCH_COMBINED_AVAILABLE_FILTERS.failure()));
};

export default fetchCombinedAvailableFilters;
