/* eslint-disable no-shadow */
/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
/* eslint-disable camelcase */
import React from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import { DESC } from 'modules/shared/constants';
import { sortItems } from 'modules/assetLook/utils';
import { usePdfContext } from 'modules/assetLook/hooks';
import { PAGE_TYPES, PRODUCT_FIELDS } from 'modules/assetLook/constants';

import { ProductListCellBody as CellBody, ProductListCellHeader as CellHeader } from 'modules/assetLook/components';
import { FixedDataTable } from 'modules/shared/components';
import { ReportContentPage } from 'pages/_privatePages/shared';

import BSLogoColor from 'assets/images/bsl-logo-mini-color.png';

import config from '../config';

const columnWidthMap = {
  0: 250,
  2: 85,
};

function ProductList({ products }) {
  const {
    data: { pdf_data, company, partner_company },
    theme,
  } = usePdfContext();

  const key = pdf_data.activeFilters.length ? 'hasFilter' : 'noFilter';

  const sortedProducts = sortItems(products, PRODUCT_FIELDS.name, DESC);

  const chunkedData = [
    sortedProducts.slice(0, config.rowsPerPage[PAGE_TYPES.productList][key]),
    ..._.chunk(
      sortedProducts.slice(config.rowsPerPage[PAGE_TYPES.productList][key]),
      config.rowsPerPage[PAGE_TYPES.productList].noFilter,
    ),
  ];

  return chunkedData.map((data, i) => (
    <div key={i} className={cn(theme.pdfPage, theme.hasPageCounter)}>
      <ReportContentPage
        title={PAGE_TYPES.productList}
        activeFilters={i < 1 ? pdf_data.activeFilters : []}
        firstLogo={isEmpty(partner_company) ? BSLogoColor : partner_company.report_logo_color}
        secondLogo={company.report_logo_color}
      >
        {({ height, width }) => (
          <FixedDataTable
            width={width}
            height={height}
            rows={data}
            rowHeight={120}
            columns={[
              PRODUCT_FIELDS.thumbnail,
              PRODUCT_FIELDS.name,
              PRODUCT_FIELDS.assets,
              ...Object.keys(data[0].list_data),
            ]}
            columnWidth={(columnKey, i) => columnWidthMap[i] || 180}
            ColumnHeader={CellHeader}
            ColumnCell={CellBody}
            columnFixed={(key, i) => i < 2}
            columnFlexGrow={(key, i) => (i < 3 ? 0 : 1)}
            showScrollbarX={false}
            showScrollbarY={false}
          />
        )}
      </ReportContentPage>
    </div>
  ));
}

export default ProductList;
