import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { fetchAssets } from 'modules/assetLook/actions';
import { toRequestBody } from 'modules/assetLook/utils';
import { getActiveCompany } from 'modules/shared/selectors';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { ASSET_FIELDS, BUILDING_ID, PRODUCT_ID } from 'modules/assetLook/constants';

import AssetMngmnt from './AssetMngmnt';

const KEEP = 'Keep';

const AssetMngmntContainer = ({ onClose }) => {
  const dispatch = useDispatch();

  const [errorCode, setErrorCode] = useState(null);

  const [isCanceled, setIsCanceled] = useState(false);
  useEffect(() => () => setIsCanceled(true), []);

  const { buildingId, productId } = useLocationSearchParams([BUILDING_ID, PRODUCT_ID]);

  const { id: companyId } = useSelector(getActiveCompany);
  const [assets, activeFilters, tags] = useAssetLookSelector(['assets.assets', 'filters.activeFilters', 'common.tags']);
  const filters = toRequestBody(activeFilters, tags);

  const validationSchema = yup.object({
    location: yup.number('Select the location').required('Location is required'),
    status: yup.string('Select the status').required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      location: 0,
      status: KEEP,
      tagsFlag: true,
      tags: [],
    },
    validationSchema,
    // eslint-disable-next-line no-shadow
    onSubmit: ({ location, status, tagsFlag, tags }, formikHelpers) => {
      setErrorCode(null);

      const url = ENDPOINTS.assetLook.edit.replace(':companyId', companyId);
      const data = {
        asset_ids: assets.data.filter((asset) => asset[ASSET_FIELDS.checkbox]).map((asset) => asset[ASSET_FIELDS.id]),
      };

      if (location) data.location_id = location;
      if (status !== KEEP) data.status = status;
      if (!tagsFlag) data.tags = tags;

      API.patch(url, data)
        .then(() => {
          formikHelpers.setSubmitting(false);
          onClose();
          fetchAssets(dispatch, isCanceled, {
            companyId,
            buildingId,
            productId,
            filters,
          });
        })
        .catch(({ response }) => {
          formikHelpers.setSubmitting(false);
          setErrorCode(response.status);
        });
    },
  });

  return <AssetMngmnt formik={formik} onClose={onClose} errorCode={errorCode} />;
};

export default AssetMngmntContainer;
