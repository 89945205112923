const setOneBarGraphYMaxValue = (values) => {
  const value = [...values].sort((a, b) => b - a)[0];

  if (value < 10) return 10;

  const fifteenPercents = Math.round((value / 100) * 15);
  const plusfifteenPercentsValue = value + fifteenPercents;
  const addToRound = 10 - (plusfifteenPercentsValue % 10);
  return plusfifteenPercentsValue + addToRound;
};

export default setOneBarGraphYMaxValue;
