/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format, utcToZonedTime } from 'date-fns-tz';

import URLS from 'pages/constants/urls';
import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';

import './ArchiveItem.styles.scss';
import {
  SET_START_DATE,
  SET_END_DATE,
  FETCH_COMMON_DATA,
  FETCH_COMMON_DATA_DOOR_SENSORS,
  SET_ARCHIVE,
} from 'modules/liveLook/actions';

const transformDate = (date) => (date ? new Date(date).toDateString().slice(4) : 'no date');

const ArchiveItem = ({ startDate, endDate, id, companyId }) => {
  const dispatch = useDispatch();
  const start = transformDate(startDate);
  const end = transformDate(endDate);
  const history = useHistory();
  const url = ENDPOINTS.liveLook.commonData.replace(':companyId', companyId).concat(`?archive_id=${id}`);
  const urlSensor = ENDPOINTS.liveLook.doorSensorsInitData.replace(':companyId', companyId).concat(`?archive_id=${id}`);

  const startArchive = new Date(startDate);
  const startArchiveLondon = utcToZonedTime(startArchive.toISOString(), 'Europe/London');
  const startArchiveLondonFormatted = format(startArchiveLondon, 'yyyy-MM-dd', { timeZone: 'Europe/London' });

  const endArchive = new Date(endDate);
  const endArchiveLondon = utcToZonedTime(endArchive.toISOString(), 'Europe/London');
  const endArchiveLondonFormatted = format(endArchiveLondon, 'yyyy-MM-dd', { timeZone: 'Europe/London' });

  const onArchiveClick = () => {
    API.get(url)
      .then(({ data }) => {
        dispatch(FETCH_COMMON_DATA.success(data));
      })
      .then(() => API.get(urlSensor))
      .then(({ data }) => {
        dispatch(FETCH_COMMON_DATA_DOOR_SENSORS.success(data));
      })
      .then(() => {
        dispatch(SET_ARCHIVE(id));
        dispatch(SET_START_DATE(startArchiveLondonFormatted));
        dispatch(SET_END_DATE(endArchiveLondonFormatted));
      })
      .then(() => history.push(URLS.liveLook.executiveSummaryKeyFindings))
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };

  return (
    <div onClick={onArchiveClick} className="archiveItem">
      <section className="HistoryReportListItem">
        <div className="HistoryReportListTitle">
          <div className="history-report-list-title">
            <div className="date">
              <span className="date-start">Start date: {start}</span>
              <span className="date-end">End date: {end}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArchiveItem;
