import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { API } from 'modules/shared/utils';
import { useAuthSelector } from 'modules/auth/hooks';
import { toRequestBody } from 'modules/assetLook/utils';
import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { ENDPOINTS, ERROR_TEXT } from 'modules/shared/constants';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { showDialog, showModal } from 'modules/modalsSystem/actions';
import { ADD_TO_BASKET, fetchAssets } from 'modules/assetLook/actions';
import { ASSET_FIELDS, BUILDING_ID, PRODUCT_ID } from 'modules/assetLook/constants';

import AssetManagementBar from './AssetManagementBar';

function AssetManagementBarContainer() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { buildingId, productId } = useLocationSearchParams([BUILDING_ID, PRODUCT_ID]);

  const [user] = useAuthSelector(['user']);
  const { id: companyId } = useSelector(getActiveCompany);
  const [assets, activeFilters, tags, basketBuilding] = useAssetLookSelector([
    'assets.assets',
    'filters.activeFilters',
    'common.tags',
    'assets.basketBuilding',
  ]);

  const filters = toRequestBody(activeFilters, tags);

  const [isCanceled, setIsCanceled] = useState(false);
  useEffect(() => () => setIsCanceled(true), []);

  const onDeleteApply = (onClose) => {
    const url = ENDPOINTS.assetLook.edit.replace(':companyId', companyId);
    const data = {
      asset_ids: assets.data.filter((asset) => asset[ASSET_FIELDS.checkbox]).map((asset) => asset[ASSET_FIELDS.id]),
      status: 'rm',
    };

    API.patch(url, data)
      .then(() => {
        onClose();
        fetchAssets(dispatch, isCanceled, {
          companyId,
          buildingId,
          productId,
          filters,
        });
      })
      .catch(({ response }) => {
        onClose();
        enqueueSnackbar(response.status === 400 ? 'Some assets changed their status or were moved' : ERROR_TEXT, {
          variant: 'error',
        });
      });
  };

  return (
    <AssetManagementBar
      buildingId={buildingId}
      isPermitted={get(user, 'is_edit_assets_permission', false)}
      disabled={isEmpty(assets) || !assets.data.some((asset) => asset[ASSET_FIELDS.checkbox])}
      onEditClick={() => {
        const isUnavailable = assets.data.some(
          (asset) => asset[ASSET_FIELDS.checkbox] && ['rv', 'od'].includes(asset[ASSET_FIELDS.status]),
        );

        if (isUnavailable) {
          dispatch(
            showDialog({
              title: 'Edit',
              body: 'Assets with “Ordered“ or “Reserved“ status can be managed via the orders menu only.',
              onApply: (onClose) => onClose(),
            }),
          );
        } else {
          dispatch(showModal(modalCodes.AL_ASSET_MNGMNT));
        }
      }}
      onDeleteClick={() => {
        const isUnavailable = assets.data.some(
          (asset) => asset[ASSET_FIELDS.checkbox] && ['rv', 'od'].includes(asset[ASSET_FIELDS.status]),
        );

        if (isUnavailable) {
          dispatch(
            showDialog({
              title: 'Delete',
              body: 'Assets with “Ordered“ or “Reserved“ status can be managed via the orders menu only.',
              onApply: (onClose) => onClose(),
            }),
          );
        } else {
          dispatch(
            showDialog({
              title: 'Delete selected assets?',
              body: "This operation can't be undone.",
              applyLabel: 'Confirm',
              onApply: onDeleteApply,
            }),
          );
        }
      }}
      onAddToBasketClick={() => {
        const isUnavailable = assets.data.some(
          (asset) => asset[ASSET_FIELDS.checkbox] && ['rv', 'od'].includes(asset[ASSET_FIELDS.status]),
        );

        if (isUnavailable) {
          dispatch(
            showDialog({
              title: 'Add to basket',
              body: "Assets with “Ordered“ or “Reserved“ status can't be added to the basket.",
              onApply: (onClose) => onClose(),
            }),
          );
        } else if (basketBuilding && basketBuilding !== buildingId) {
          dispatch(
            showDialog({
              title: 'Start a new basket?',
              body: "You can't add assets from different buildings. This will reset your current basket.",
              onApply: (onClose) => {
                dispatch(ADD_TO_BASKET(buildingId));
                onClose();
                enqueueSnackbar('Added to the basket', { variant: 'success' });
              },
            }),
          );
        } else {
          dispatch(ADD_TO_BASKET(buildingId));
          enqueueSnackbar('Added to the basket', { variant: 'success' });
        }
      }}
    />
  );
}

export default AssetManagementBarContainer;
