import React, { useContext } from 'react';

import { Grid, IconButton, Divider } from '@material-ui/core';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import TextEditorContext from 'modules/shared/components/TextEditor/TextEditorContext';

import ImageButton from './ImageButton';

const ActionButtons = ({
  _onBoldClick,
  _onItalicClick,
  _onUnderlineClick,
  _onOrderedListClick,
  _onUnorderedListClick,
}) => {
  const { isImageBtnShow } = useContext(TextEditorContext);

  return (
    <Grid container style={{ margin: '0 0 8px -8px' }}>
      <IconButton size="small" onClick={_onBoldClick}>
        <FormatBoldIcon />
      </IconButton>
      <IconButton size="small" onClick={_onItalicClick}>
        <FormatItalicIcon />
      </IconButton>
      <IconButton size="small" onClick={_onUnderlineClick}>
        <FormatUnderlinedIcon />
      </IconButton>

      <Divider orientation="vertical" flexItem style={{ margin: '0 8px' }} />

      <IconButton size="small" onClick={_onOrderedListClick}>
        <FormatListNumberedIcon />
      </IconButton>
      <IconButton size="small" onClick={_onUnorderedListClick}>
        <FormatListBulletedIcon />
      </IconButton>

      <Divider orientation="vertical" flexItem style={{ margin: '0 8px' }} />

      {isImageBtnShow && <ImageButton />}
    </Grid>
  );
};

export default ActionButtons;
