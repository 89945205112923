const reportPageTypes = {
  desks: 'DESKS',
  benchmarking: 'BENCHMARKING',
  meetings: 'MEETINGS',
  breakout: 'BREAKOUT',
  combined: 'COMBINED',
  download: 'DOWNLOAD',
  summary: 'SUMMARY',
};

export default reportPageTypes;
