import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ADD_TO_BASKET } from 'modules/workLook/actions';
import {
  getControlCard,
  getIntSwitcher,
  getMapType,
  getReports,
  getWidgetsData,
  getTimeGraphScale,
  getTimeGraphValue,
} from 'modules/workLook/selectors';

import { AddToPDF as SharedAddToPDF } from 'modules/shared/components';
import getDataCard from 'modules/workLook/selectors/getDataCard';

const AddToPDFCard = () => {
  const dispatch = useDispatch();
  const { reportId, sectionId, pageId } = useParams();
  const { reportData } = useSelector(getReports);
  const { mapType } = useSelector(getMapType);
  const { intSwitcher } = useSelector(getIntSwitcher);
  const { timeScale } = useSelector(getTimeGraphScale);
  const { valueMethod } = useSelector(getTimeGraphValue);
  const { dataCard } = useSelector(getDataCard);
  const { widgetsData } = useSelector(getWidgetsData);
  const { controlCardData } = useSelector(getControlCard);

  const pagesInBasket =
    reportData[reportId].basket &&
    Object.values(reportData[reportId].basket).reduce(
      (quantity, section) => quantity + Object.keys(section.pages).length,
      0,
    );

  const onClick = useCallback(() => {
    dispatch(
      ADD_TO_BASKET({
        reportId,
        sectionId,
        pageId,
        mapType,
        dataCard,
        widgetsData,
        controlCardData,
        intSwitcher,
        timeScale,
        valueMethod,
      }),
    );
  }, [
    dispatch,
    reportId,
    sectionId,
    pageId,
    mapType,
    dataCard,
    widgetsData,
    controlCardData,
    intSwitcher,
    timeScale,
    valueMethod,
  ]);

  return <SharedAddToPDF counter={pagesInBasket || 0} onClick={onClick} pageId={pageId} />;
};

export default AddToPDFCard;
