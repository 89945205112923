import React from 'react';

import { PAGE_TYPES } from 'modules/assetLook/constants';
import { useAssetLookSelector } from 'modules/assetLook/hooks';

import { Counter as SharedCounter } from 'modules/shared/components';

import EventSeatIcon from '@material-ui/icons/EventSeat';
import ProductIcon from './ProductIcon';

const Counter = ({ pageType }) => {
  let dataKey;
  let Icon;

  // eslint-disable-next-line default-case
  switch (pageType) {
    case PAGE_TYPES.productList:
      dataKey = 'products.products';
      Icon = ProductIcon;
      break;
    case PAGE_TYPES.productSummary:
      dataKey = 'summary.summary';
      Icon = EventSeatIcon;
      break;
    case PAGE_TYPES.assetList:
      dataKey = 'assets.assets';
      Icon = EventSeatIcon;
      break;
    case PAGE_TYPES.assetDetails:
      dataKey = 'assets.assets';
      Icon = EventSeatIcon;
      break;
  }

  const [data] = useAssetLookSelector([`${dataKey}`]);

  return <SharedCounter Icon={Icon} total={data.count} counter={data.filtered_count} />;
};

export default Counter;
