import { combineReducers } from 'redux';

import common from './common';
import filters from './filters';
import doorSensorFilters from './doorSensorFilters';
import deskFilters from './deskFilters';
import combinedFilters from './combinedFilters';
import meetFilters from './meetFilters';
import breakFilters from './breakFilters';
import reports from './reportsReducer';

export default combineReducers({
  common,
  filters,
  doorSensorFilters,
  deskFilters,
  meetFilters,
  breakFilters,
  combinedFilters,
  reports,
});
