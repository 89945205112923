/* eslint-disable react/no-children-prop */
import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

const NoDataAvailable = ({ theme }) => {
  return (
    <Grid className={theme} item xs={12}>
      <Typography variant="h5">
        <Box fontWeight="fontWeightBold" component="span" children="No Data Available" />
      </Typography>
    </Grid>
  );
};

export default NoDataAvailable;
