import { pick } from 'lodash';

const keysToPick = ['databoxes', 'peakAndAvgUtilData'];

const getBreakoutCompData = ({ report: { breakout } }) => ({
  dataRequestStatuses: pick(breakout.requestStatuses, keysToPick),
  data: pick(breakout.data, keysToPick),
  shouldRefetchData: pick(breakout.shouldRefetchData, keysToPick),
});

export default getBreakoutCompData;
