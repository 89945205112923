import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader, TextEditor } from 'modules/shared/components';
import { ReportDataCards, FilterList, VerticalBarGraph, LiveCounter } from 'modules/liveLook/components';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';

import IFrameModal from 'modules/liveLook/components/iFrame';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import config from './config';

const MeetsSpaceComparison = ({
  data,
  databoxes,
  onSwitcherChange,
  scaleValues,
  editableTextReport,
  saveGraphsText,
  databoxesSet,
  returnPercentageSwitcher,
  archive,
}) => (
  <Grid container spacing={4}>
    <Grid item xs={8}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title="Meeting Spaces" />
        </Grid>
        <Grid item xs={12}>
          <FilterList subtypes pageType="MEETINGS" />
        </Grid>
        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={pageTypes.meetings} />
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={4}>
      <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
        <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
        <Grid item>
          <IFrameModal />
        </Grid>
      </Grid>
      <Grid>
        <AddToPDFCard
          type="Space Comparison"
          sectionType="Meeting"
          category={pdfCases.meetingSpaceComparison}
          sectionOrder={sectionsOrder.fourth}
          typeOrder={sectionItemOrder.third}
          chartName={liveLookPagesNames.meetsSpaceComparison}
          activeFilters={filtersDataContainer('MEETINGS', false).initActiveFilters}
          data={{
            scaleValues,
            databoxes,
            editableTextReport,
            data,
            returnPercentageSwitcher,
          }}
        />
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <VerticalBarGraph
        title="Peak and Average by Type"
        data={data}
        returnPercentageSwitcher={returnPercentageSwitcher}
        config={config}
        onSwitcherChange={onSwitcherChange}
        scaleValues={scaleValues}
        customHeader
        label="Integers"
      />
    </Grid>

    <Grid item xs={12}>
      <TextEditor
        chartName={liveLookPagesNames.meetsSpaceComparison}
        data={databoxesToEditorData(databoxesSet)}
        // eslint-disable-next-line no-shadow
        onSave={async (data) => {
          saveGraphsText(JSON.stringify(data));
        }}
        text={editableTextReport}
        graphType={GRAPH_TYPES.VERTICAL}
      />
    </Grid>
  </Grid>
);

export default MeetsSpaceComparison;
