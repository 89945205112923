/* eslint-disable consistent-return */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { getWidgetsData } from 'modules/workLook/selectors';
import { Loader } from 'components';
import {
  RESET_TEXT_SUMMARY_BAR_CHART,
  RESET_TEXT_SUMMARY_BAR_MULTIPLE,
  RESET_TEXT_SUMMARY_BAR_SINGLE,
  RESET_TIME_GRAPH,
  SET_ACTIVE_PAGE_TYPE,
} from 'modules/workLook/actions';
import widgetConfig from 'modules/modalsSystem/components/modalsWL/GraphModalDetail/GraphModalDetailContainer/config';
import getGraphsInfo from './utils';
import config, { widgetStylesConfig } from './config';
import theme from './GraphsPagePattern.module.scss';

const GraphsPagePattern = ({
  page: {
    graphs,
    activeFilters,
    mapType = '',
    dataCard = 0,
    widgetsData: pdfWidgetsData,
    controlCardData,
    intSwitcher,
    timeScale,
    valueMethod,
    type,
  },
  isPdf,
  isPublished,
  isPeopleLook,
  isDashboard,
}) => {
  const { widgetsData, record, isLoading } = useSelector(getWidgetsData);
  const { isControlGraph, graphsList } = useMemo(() => getGraphsInfo(graphs), [graphs]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SET_ACTIVE_PAGE_TYPE(type));
    dispatch(RESET_TEXT_SUMMARY_BAR_SINGLE([]));
    dispatch(RESET_TEXT_SUMMARY_BAR_CHART([]));
    dispatch(RESET_TEXT_SUMMARY_BAR_MULTIPLE([]));
    dispatch(RESET_TIME_GRAPH());
  }, [dispatch, type]);
  const occupiedAreas = [];
  const widgetOccupiedAreas = isControlGraph ? [Number(graphsList[0].position) + 2] : [];
  const maxGraphsPerPage = isDashboard ? 20 : 4;
  const widgetsList = isPdf ? pdfWidgetsData : widgetsData;
  const getTimeGraphs = graphsList.filter((graph) => graph.type === '18');
  const getFirstTimeGrId = getTimeGraphs[0]?.id;

  return (
    <div className={cn(theme.container, { [`${theme.isPdf}`]: isPdf }, { [`${theme.isDashboard}`]: isDashboard })}>
      {graphsList.map((graph, idx) => {
        if (config.posValidation[graph.position]?.some((deniedPos) => occupiedAreas.includes(deniedPos))) return;
        occupiedAreas.push(Number(graph.position));

        return (
          <If condition={idx < maxGraphsPerPage}>
            <div key={graph.id} className={theme[`${config.positions[graph.position]}`]}>
              {config.graphList[graph.type] &&
                config.graphList[graph.type]({
                  ...graph,
                  activeFilters,
                  isPdf,
                  isPeopleLook,
                  mapType,
                  dataCard,
                  controlCardData,
                  intSwitcher,
                  timeScale,
                  valueMethod,
                  getFirstTimeGrId,
                  isPublished,
                })}
            </div>
          </If>
        );
      })}
      <Choose>
        <When condition={isLoading}>
          <Loader />
        </When>

        <When condition={isControlGraph && graphsList[0].position !== '9'}>
          {widgetsList?.map((widget) => {
            if (
              widgetStylesConfig.posValidation[widget.position]?.some((deniedPos) =>
                widgetOccupiedAreas.includes(deniedPos),
              )
            )
              return;
            widgetOccupiedAreas.push(Number(widget.position));

            return (
              <div
                key={widget.id}
                className={[theme[`${widgetStylesConfig.positions[widget.position]}`], theme.section].join(' ')}
              >
                {widgetConfig.widgetList[widget.type]({
                  ...widget,
                  record,
                  isModal: false,
                  isLoading,
                })}
              </div>
            );
          })}
        </When>
      </Choose>
    </div>
  );
};

export default GraphsPagePattern;
