/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Typography, Box, Checkbox } from '@material-ui/core';

import theme from './PageRow.module.scss';

const PageRow = ({ id, type, checked, pageCounter, _onPageClick }) => {
  return (
    <div className={theme.pageRow} onClick={_onPageClick(id)}>
      <Typography variant="body1" className={theme.text}>
        <Box fontWeight="fontWeightMedium" component="span">
          {`${type} `}
        </Box>
      </Typography>
      <div className={theme.pageCounter}>{pageCounter}</div>
      <div className={theme.checkbox}>
        <Checkbox checked={checked} color="primary" disableRipple style={{ padding: '0' }} />
      </div>
    </div>
  );
};

export default PageRow;
