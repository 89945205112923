import React from 'react';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { getDetailData } from 'modules/workLook/selectors';
import theme from './graphModalDetail.module.scss';
import config from './config';

const GraphModalDetailContainer = React.forwardRef(({ onClose }, ref) => {
  const { widgetModal, recordId } = useSelector(getDetailData);

  const sorted = [...widgetModal].sort((a, b) => {
    return parseFloat(a.order) - parseFloat(b.order);
  });

  return (
    <div className={theme.container}>
      <IconButton ref={ref} aria-label="close" className={theme.closeBtn} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <div className={theme.graph}>
        {sorted?.map((widget) => {
          return (
            <div key={widget.id} className={[theme[`${config.positions[widget.position]}`], theme.section].join(' ')}>
              {config.widgetListModal[widget.type] &&
                config.widgetListModal[widget.type]({
                  ...widget,
                  recordId,
                  isModal: true,
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default GraphModalDetailContainer;
