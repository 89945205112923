/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useLiveLookSelector } from 'modules/liveLook/hooks';

const ByBuildingGraphHeader = ({
  title,
  building,
  onBuildingChange,
  onSwitcherChange,
  customHeader,
  switcher,
  pdf,
  activeBuilding,
  pdfCustomHeader,
}) => {
  const [buildings] = useLiveLookSelector(['common.buildings']);
  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item style={{ marginRight: 'auto', paddingBottom: pdf ? 10 : 15 }}>
        {
          // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
          pdf ? (
            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold" component="span" children={title} />
                <Box marginLeft={5} fontSize={12} fontWeight="normal" component="span">
                  {`Building: ${activeBuilding}`}
                </Box>
              </Typography>
            </div>
          ) : (
            <Typography variant={pdfCustomHeader ? 'subtitle2' : 'h5'}>
              <Box fontWeight="fontWeightBold" component="span" children={title} />
            </Typography>
          )
        }
      </Grid>

      {customHeader && !pdf && (
        <FormGroup>
          <Grid component="label" container alignItems="center" spacing={2}>
            <Grid item>Percentages</Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={switcher} onChange={onSwitcherChange} />}
                label="Integers"
                checked={switcher}
              />
            </Grid>
          </Grid>
        </FormGroup>
      )}

      {!pdf && (
        <Grid item>
          <FormControl style={{ minWidth: '150px' }}>
            <InputLabel id="scale">Building</InputLabel>
            <Select
              labelId="building"
              id="building"
              value={building}
              onChange={onBuildingChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {/* TODO chahge this into one array */}
              <MenuItem value={0}>All buildings</MenuItem>
              {buildings?.map((i) => (
                <MenuItem key={i.building_id} value={i.building_id}>
                  {i.building__property_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default ByBuildingGraphHeader;
