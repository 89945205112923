const liveLookPagesNames = {
  summaryKeyFindings: 'SummaryKeyFindings',
  summaryKeyFindingsOccupancy: 'SummaryKeyFindingsOccupancy',
  summaryKeyFindingsDesks: 'SummaryKeyFindingsDesks',
  summaryKeyFindingsMeet: 'SummaryKeyFindingsMeet',
  summaryKeyFindingsBreak: 'SummaryKeyFindingsBreak',
  summaryFloorPlan: 'SummaryFloorPlan',
  doorsOverTime: 'DoorsOverTime',
  doorsDaily: 'DoorsDaily',
  doorsByBuilding: 'DoorsByBuilding',
  desksOverTime: 'DesksOverTime',
  desksSpaceComparison: 'DesksSpaceComparison',
  desksByDivision: 'DesksByDivision',
  desksAmberState: 'DesksAmberState',
  desksByBuilding: 'DesksByBuilding',
  combinedTeamZoneOccupancy: 'CombinedTeamZoneOccupancy',
  breakoutSpaceUsageBySize: 'BreakoutSpaceUsageBySize',
  breakoutSpaceComparison: 'BreakoutSpaceComparison',
  breakoutOverTime: 'BreakoutOverTime',
  meetsOverTime: 'MeetsOverTime',
  meetsSpaceComparison: 'MeetsSpaceComparison',
  meetsSpaceUsageBySize: 'MeetsSpaceUsageBySize',
};

export default liveLookPagesNames;
