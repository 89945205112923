import React from 'react';
import { useDispatch } from 'react-redux';

import { HIDE_MODAL } from 'modules/modalsSystem/actions';
import { Modal } from '@material-ui/core';
import EditGraphModalContainer from './EditGraphModalContainer/EditGraphModalContainer';

const EditGraphModal = () => {
  const dispatch = useDispatch();

  const onClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(HIDE_MODAL());
    }
  };

  return (
    <Modal open onClose={onClose}>
      <EditGraphModalContainer onClose={onClose} />
    </Modal>
  );
};

export default EditGraphModal;
