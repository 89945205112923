import { isEmpty } from 'lodash';

export const setItem = (chartName, value) => {
  localStorage.setItem(`${chartName}.TextField`, value);
};

export const getItem = (chartName) => {
  return localStorage.getItem(`${chartName}.TextField`);
};

export const setSaveLocalItem = (chartName, value) => {
  localStorage.setItem(`${chartName}.Saved`, value);
};

export const getSaveLocalItem = (chartName) => {
  return localStorage.getItem(`${chartName}.Saved`);
};

export const removeSave = (chartName) => {
  localStorage.removeItem(`${chartName}.Saved`);
};

export const setSavedLocallyItem = (chartName, value) => {
  localStorage.setItem(`${chartName}.SavedLocally`, value);
};

export const getSavedLocallyItem = (chartName) => {
  return localStorage.getItem(`${chartName}.SavedLocally`);
};
export const removeSavedLocallyItem = (chartName) => {
  localStorage.removeItem(`${chartName}.SavedLocally`);
};
export const removeItem = (chartName) => {
  localStorage.removeItem(`${chartName}.TextField`);
};

export const setTextFieldsList = (chartName) => {
  const keysList = localStorage.getItem('TextFieldsList');

  if (keysList) {
    const parsedKeysList = JSON.parse(keysList);
    localStorage.setItem('TextFieldsList', JSON.stringify(isIncludes(parsedKeysList, `${chartName}.TextField`)));
  } else {
    localStorage.setItem('TextFieldsList', JSON.stringify([`${chartName}.TextField`]));
  }
};

const isIncludes = (parsedKeysList, keyName) => {
  if (parsedKeysList.includes(keyName)) {
    return parsedKeysList;
  }
  return parsedKeysList.concat(keyName);
};

// eslint-disable-next-line consistent-return
export const removeTextField = (chartName) => {
  const parsedKeysList = JSON.parse(localStorage.getItem('TextFieldsList'));
  if (isEmpty(parsedKeysList)) return null;

  if (parsedKeysList.includes(chartName) && parsedKeysList.length === 1) {
    return localStorage.removeItem('TextFieldsList');
  }
  localStorage.setItem('TextFieldsList', JSON.stringify(parsedKeysList.filter((textField) => textField !== chartName)));
};

export const removeAllItems = () => {
  const parsedKeysList = JSON.parse(localStorage.getItem('TextFieldsList'));
  if (parsedKeysList) {
    for (let index = 0; index < parsedKeysList.length; index += 1) {
      localStorage.removeItem(parsedKeysList[index]);
    }
  }
  localStorage.removeItem('TextFieldsList');
};
