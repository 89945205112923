import React from 'react';

import { SidebarHeader, Copyright } from 'modules/shared/components';

import BuildingList from './components/BuildingList';
import PdfReports from './components/PdfReports';
import Orders from './components/Orders';
import ArrivalOrderUpload from './components/ArrivalOrderUpload';

import theme from './Sidebar.module.scss';

const Sidebar = () => {
  return (
    <div className={theme.container}>
      <SidebarHeader />

      <Orders />
      <PdfReports />
      <BuildingList />
      <ArrivalOrderUpload />
      <Copyright style={{ marginTop: 'auto', marginBottom: '16px' }} />
    </div>
  );
};

export default Sidebar;
