import React from 'react';
import { Grid } from '@material-ui/core';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';

import { PageHeader } from 'modules/shared/components';
import { ReportDataCards, VerticalBarGraph } from 'modules/liveLook/components';
import { GRAPH_TYPES, pageTypes } from 'modules/liveLook/constants';
import { isEmpty } from 'lodash';
import styles from '../../LLPrintablePdfPage.module.scss';
import renderFilters from '../../utils/activeFiltersPdf';
import config from './config';

const BreakoutSpaceComparison = ({
  data,
  databoxes,
  scaleValues,
  editableTextReport,
  returnPercentageSwitcher,
  activeFilters,
}) => {
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));
  return (
    <Grid style={{ paddingTop: '20px', paddingLeft: '30px' }} container spacing={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader pdfMode title="Breakout" />
        </Grid>
      </Grid>

      {!shouldRenderFilters && renderFilters(activeFilters)}
      <div className={styles.blockContainer}>
        <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.meetings} />
      </div>
      <VerticalBarGraph
        title="Peak and Average Usage by Space Type"
        data={data}
        config={config}
        onSwitcherChange={() => {}}
        scaleValues={scaleValues}
        label="Integers"
        switcher={returnPercentageSwitcher}
        returnPercentageSwitcher={returnPercentageSwitcher}
        pdfCustomHeader
        isLiveLook
      />
      <PDFTextEditorContainer
        hasFilters={shouldRenderFilters}
        graphType={GRAPH_TYPES.VERTICAL}
        data={[]}
        onSave={() => {}}
        text={editableTextReport}
      />
    </Grid>
  );
};

export default BreakoutSpaceComparison;
