/* eslint-disable no-unused-vars */
import { useMap } from 'react-leaflet';

const useMapStartZoom = (data) => {
  const map = useMap();

  const coordinatesArray = data?.map(({ coordinates }) => coordinates);

  const latitudesArray = coordinatesArray.map(([longitude, latitude]) => latitude);
  const longitudesArray = coordinatesArray.map(([longitude, latitude]) => longitude);

  const ne = [Math.max(...longitudesArray), Math.max(...latitudesArray)];
  const sw = [Math.min(...longitudesArray), Math.min(...latitudesArray)];

  map.fitBounds([sw, ne]);
};

export default useMapStartZoom;
