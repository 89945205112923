const config = {
  titles: {
    page: 'Meeting Space Comparison & Sizes',
    pageSubtitle: 'Meeting Space Utilisation',
    usageByTypeGraph: 'Peak and Average by Type',
    observedSizesGraph: 'Observed Sizes by Type',
  },
  observedSizesColors: {
    'No one': '#0D6F85',
    '1 Person': '#A2559D',
    '2-4 People': '#00AAD2',
    '5-8 People': '#F6A000',
    '>9 People': '#B4CC04',
    '12 people': '#0D6F85',
  },
  graphTypes: {
    usageByType: 'USAGE_BY_TYPE',
    observedSizes: 'OBSERVED_SIZES',
  },
};

export default config;
