import combinedPageConfig from 'pages/_privatePages/spaceLook/CombinedOccupancy/config';

const setValueParams = (key, label, isPercent = false) => ({ key, label, isPercent });

const config = [
  {
    title: 'Desks Peak',
    firstValue: setValueParams('max_desk_occupancy', 'seats'),
    secondValue: setValueParams('percent_max_desk_occupancy', null, true),
    color: combinedPageConfig.palette.desks,
  },
  {
    title: 'Meeting Peak',
    firstValue: setValueParams('max_meet_seats_occupancy', 'seats'),
    secondValue: setValueParams('percent_max_meet_seats_occupancy', null, true),
    color: combinedPageConfig.palette.meets,
  },
  {
    title: 'Breakout Peak',
    firstValue: setValueParams('max_breakout_seats_occupancy', 'seats'),
    secondValue: setValueParams('percent_max_breakout_seats_occupancy', null, true),
    color: combinedPageConfig.palette.break,
  },

  {
    title: 'Desks Average',
    firstValue: setValueParams('avg_desk_occupancy', 'seats'),
    secondValue: setValueParams('percent_avg_desk_occupancy', null, true),
    color: combinedPageConfig.palette.desks,
  },
  {
    title: 'Meeting Average',
    firstValue: setValueParams('avg_meet_seats_occupancy', 'seats'),
    secondValue: setValueParams('percent_avg_meet_seats_occupancy', null, true),
    color: combinedPageConfig.palette.meets,
  },
  {
    title: 'Breakout Average',
    firstValue: setValueParams('avg_breakout_seats_occupancy', 'seats'),
    secondValue: setValueParams('percent_avg_breakout_seats_occupancy', null, true),
    color: combinedPageConfig.palette.break,
  },
];

export default config;
