/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import URLS from 'pages/constants/urls';

import { Button, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { RESET_REPORT_ERROR } from 'modules/shared/actions';
import { RESET_FILTERS } from 'modules/spaceLook/actions';

import theme from './ReportNetworkErrorHandler.module.scss';

const ReportNetworkErrorHandler = ({ children, isNoData }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const error = useSelector(({ error }) => error.reportError);
  const errorStatus = error && error.status;

  useEffect(() => {
    if (error) dispatch(RESET_REPORT_ERROR());
    // eslint-disable-next-line
  }, [location.pathname]);

  // Old endpoints instead of "Forbidden"(403) throw "Not found"(404), when user has no access rights
  if ([403, 404].includes(errorStatus)) {
    history.push(URLS.spaceLook.reportHistory);
  }

  return (
    <Choose>
      <When condition={errorStatus === 400 || isNoData || errorStatus === 500}>
        <div className={theme.errorContainer}>
          <ErrorOutlineIcon fontSize="inherit" className={theme.errorIcon} />
          <Typography variant="h5" className={theme.errorText}>
            There is no data or data is invalid. Please, try to reset the filters or contact a manager.
          </Typography>
          <Button
            size="large"
            color="primary"
            onClick={() => {
              dispatch(RESET_FILTERS());
              dispatch(RESET_REPORT_ERROR());
            }}
            // eslint-disable-next-line react/no-children-prop
            children="Reset"
          />
        </div>
      </When>

      <When condition={errorStatus}>
        <div className={theme.errorContainer}>
          <ErrorOutlineIcon fontSize="inherit" className={theme.errorIcon} />
          <Typography variant="h5" className={theme.errorText}>
            {/* Something went wrong, please try to reload the page or contact a manager. */}
            There is no data or data is invalid. Please, try to reset the filters or contact a manager.
          </Typography>
        </div>
      </When>

      <Otherwise>{children}</Otherwise>
    </Choose>
  );
};

export default ReportNetworkErrorHandler;
