import React from 'react';
import cn from 'classnames';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { getPdfMode } from 'modules/spaceLook/selectors';
import { GraphYScale, GraphLegend } from 'modules/spaceLook/components';

import { useHasScroll } from 'modules/shared/hooks';

import config from './config';
import options from './options';
import theme from './BenchmarkingGraph.module.scss';

const BenchmarkingGraph = ({ data }) => {
  const pdfMode = useSelector(getPdfMode);
  const dataLength = data.labels.length;

  const { ref, hasHorScroll } = useHasScroll();

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      <div className={theme.graphWrapper}>
        <GraphYScale marginBottom={hasHorScroll ? '43px' : '30px'} />

        <div className={theme.graphInner} ref={ref}>
          <div className={theme.graph} style={{ width: `${dataLength * 192}px` }}>
            <Bar key={dataLength} data={data} options={options} />
          </div>
        </div>
      </div>

      <GraphLegend config={config.legend} pdfMode={pdfMode} sm={pdfMode ? 3 : undefined} xl={3} />
    </div>
  );
};

export default BenchmarkingGraph;
