import React, { useState } from 'react';

import { Copyright } from 'modules/shared/components';
import { LoginForm, RestoreForm, SwitchButton } from 'modules/auth/components';

import buildings from 'assets/images/buildings.png';
import buildingsBig from 'assets/images/buildings1-5x.png';

import theme from './LoginPage.module.scss';

const LOGIN = 'LOGIN';
const RESTORE = 'RESTORE';

const LoginPage = () => {
  const [activeForm, setActiveForm] = useState(LOGIN);

  return (
    <div className={theme.container}>
      <div className={theme.formContainer}>
        <SwitchButton />
        <div className={theme.form}>
          <Choose>
            <When condition={activeForm === LOGIN}>
              <LoginForm _onRestoreClick={() => setActiveForm(RESTORE)} />
            </When>
            <When condition={activeForm === RESTORE}>
              <RestoreForm _onBackBtnClick={() => setActiveForm(LOGIN)} />
            </When>
          </Choose>
        </div>
        <Copyright />
      </div>
      <div
        className={theme.imageContainer}
        style={{
          backgroundImage: `url(${window.devicePixelRatio === 2 ? buildings : buildingsBig})`,
        }}
      />
    </div>
  );
};

export default LoginPage;
