/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
// Icons
import EditIcon from '@material-ui/icons/EditOutlined';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import { setHeaders } from 'modules/workLook/components/EditableTable/config';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import { SET_MERGED_FIELDS } from 'modules/workLook/actions';
import SearchIcon from '@mui/icons-material/Search';
import { getBlankReport } from 'modules/workLook/selectors';
import { ENDPOINTS } from 'modules/shared/constants';
import { isLogged } from 'utils';
import { isEmpty } from 'lodash';
import style from './EditCsvData.module.scss';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'calc(100% - 203px)',
    marginTop: '15px',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
  error: {
    marginTop: '10px',
    color: 'red',
  },
  search: {
    width: '40%',
    marginTop: '20px',
  },
}));

const TYPES = ['int', 'float', 'time', 'datetime', 'date', 'text', 'file'];

const CustomTableCell = ({ row, name, onChange, errors }) => {
  const classes = useStyles();
  const { isEditMode } = row;

  return (
    <TableCell align="left" className={classes.tableCell}>
      <If condition={!isEditMode && name === 'is_filter'}>
        <Checkbox disabled checked={row[name]} color="primary" style={{ padding: '0' }} />
      </If>
      <If condition={isEditMode && name === 'is_filter'}>
        <Checkbox
          checked={row[name]}
          value={row[name]}
          name={name}
          onChange={(e) => onChange(e, row)}
          color="primary"
          style={{ padding: '0' }}
        />
      </If>
      <If condition={isEditMode && name === 'type'}>
        <Select name={name} value={row[name]} onChange={(e) => onChange(e, row)}>
          {TYPES.map((type, id) => (
            <MenuItem key={id} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </If>
      <If condition={isEditMode && (name === 'name' || name === 'csv_name')}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Input
            disabled={name === 'csv_name'}
            value={row[name]}
            name={name}
            onChange={(e) => onChange(e, row)}
            className={classes.input}
          />
          <If condition={errors.state && name === errors.name}>
            <Typography style={{ alignSelf: 'start' }} variant="body2" color="error" component="span">
              {errors.msg}
            </Typography>
          </If>
        </div>
      </If>
      <If condition={!isEditMode}>{row[name]}</If>
    </TableCell>
  );
};

const EditCsvData = ({ isEditFields = false, onNext, companyId, report }) => {
  const dispatch = useDispatch();
  const { csvData, blankReportData, reportFiles, additionalFile } = useSelector(getBlankReport);
  const hasAdditionalFile = additionalFile?.displayNewStep;
  const [isLoading, setIsLoading] = useState(false);
  const FILES = [
    { name: 'Base table', id: reportFiles[0]?.id },
    ...(additionalFile?.additionalFile
      ? additionalFile?.additionalFile
      : [{ name: 'Additional table', id: reportFiles[1]?.id }]),
    // { name: 'Additional table', id: additionalFile.additionalFile },
  ];
  const [activeFile, setActiveFile] = useState(FILES[0]);
  const [errors, setErrors] = useState({ state: false, msg: '', name: '' });
  const [editable, setEditable] = useState(true);
  const headers = setHeaders(csvData);
  const newData = csvData.map((item) => ({ ...item, id: isEditFields ? item.id : item.csv_name, isEditMode: false }));
  const [rows, setRows] = useState(newData);
  const [search, setSearch] = useState('');
  const [onEditValues, setOnEditValues] = useState({});
  const [errorResponse, setErrorResponse] = useState({ state: false, msg: '' });

  const getSearchedFields = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const getFilteredRows = rows.filter((row) => {
    return row.table_alias === activeFile.id;
  });

  const getRows = getFilteredRows.filter((row) => {
    if (search === '') {
      return row;
    }
    return row.csv_name.toLowerCase().includes(search.toLowerCase());
  });

  const [isRowActive, setActiveRow] = useState(false);
  const [previous, setPrevious] = useState({});
  const classes = useStyles();
  const filteredHeaders = headers.filter((value) => {
    return value !== 'id' && value !== 'report' && value !== 'table_alias';
  });

  const onToggleEditMode = (id) => {
    setActiveRow((prev) => !prev);
    setOnEditValues(rows.find((el) => el.id === id));

    setRows((state) => {
      return rows.map((row) => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };
  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const { value, name } = e.target;
    const { id } = row;
    if (name === 'name' && !isEmpty(value)) {
      setErrors({ state: false, msg: ``, name: '' });
    }
    if (name === 'name' && isEmpty(value)) setErrors({ state: true, msg: `* ${name} required`, name });
    const newRows = rows.map((row) => {
      if (row.id === id) {
        if (name === 'is_filter') {
          return { ...row, [name]: e.target.checked };
        }
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };
  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...onEditValues, isEditMode: false };
      }
      return row;
    });
    setRows(newRows);
    setActiveRow((prev) => !prev);
  };

  const onSubmitBtn = () => {
    setIsLoading(true);
    setEditable(false);
    rows.forEach((item) => {
      delete item.isEditMode;
      if (!isEditFields) delete item.id;
    });
    const editUrl = ENDPOINTS.workLook.editFields.replace(':reportId', report);
    const url = ENDPOINTS.workLook.csvFields.replace(':companyId', companyId).replace(':reportId', blankReportData.id);
    const result = JSON.stringify(rows);
    const config = {
      method: isEditFields ? 'put' : 'post',
      url: isEditFields ? editUrl : url,
      headers: {
        Authorization: `Token ${isLogged()}`,
        'Content-Type': 'application/json',
      },
      data: result,
    };
    axios(config)
      .then(({ data }) => {
        if (isEditFields) {
          window.location.reload(false);
        } else {
          onNext();
          dispatch(SET_MERGED_FIELDS(data));
          setIsLoading(false);
        }
      })
      .catch(({ response }) => {
        const returnData = rows.map((item) => ({
          ...item,
          id: isEditFields ? item.id : item.csv_name,
          isEditMode: false,
        }));

        setRows(returnData);
        setErrorResponse({ state: true, msg: response.data.name });
        setEditable(true);
        setIsLoading(false);
      });
  };
  const HEADER_NAME = {
    name: 'Name',
    csv_name: 'Header in CSV',
    type: 'Type',
    is_filter: 'Use as filter',
  };

  return (
    <>
      <FormControl fullWidth variant="outlined" className={classes.search}>
        <InputLabel htmlFor="search">Search by Header in CSV</InputLabel>
        <OutlinedInput
          id="search"
          onChange={getSearchedFields}
          labelWidth={60}
          value={search}
          label="Search by Header in CSV"
          placeholder="Enter Header Name in CSV"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment style={{ cursor: 'pointer' }} onClick={() => setSearch('')} position="end">
              <ClearIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      <If condition={hasAdditionalFile}>
        <div className={style.container}>
          {FILES.map(({ name, id }) => (
            <div
              className={style.fileWrapper}
              onClick={() => setActiveFile({ name, id })}
              style={{ backgroundColor: activeFile.name === name && '#b8b6b6' }}
              key={id}
            >
              <Typography>{name}</Typography>
            </div>
          ))}
        </div>
      </If>
      <If condition={errorResponse.state}>
        <Typography variant="body2" className={classes.error}>
          {errorResponse.msg}
        </Typography>
      </If>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              {filteredHeaders.map((attribute) => (
                <TableCell key={attribute} align="left">
                  {HEADER_NAME[attribute]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell className={classes.selectTableCell}>
                  <If condition={row.isEditMode}>
                    <>
                      <IconButton disabled={errors.state} aria-label="done" onClick={() => onToggleEditMode(row.id)}>
                        <DoneIcon />
                      </IconButton>
                      <IconButton disabled={errors.state} aria-label="revert" onClick={() => onRevert(row.id)}>
                        <RevertIcon />
                      </IconButton>
                    </>
                  </If>
                  <If condition={editable}>
                    <If condition={!row.isEditMode}>
                      <IconButton disabled={isRowActive} aria-label="delete" onClick={() => onToggleEditMode(row.id)}>
                        <EditIcon />
                      </IconButton>
                    </If>
                  </If>
                </TableCell>
                {filteredHeaders.map((header, index) => (
                  <CustomTableCell key={index} errors={errors} {...{ row, name: `${header}`, onChange }} />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <div className={style.btnWrapper}>
        <Button
          disabled={errors.state || isRowActive || isLoading}
          onClick={() => onSubmitBtn()}
          color="primary"
          variant="outlined"
          type="submit"
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <If condition={isLoading}>
              <CircularProgress style={{ marginRight: '4px' }} size={16} />
            </If>
            Submit
          </div>
        </Button>
      </div>
    </>
  );
};

export default EditCsvData;
