import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_COMMON_DATA_TMP = createAsyncAction(createAction('FETCH_COMMON_DATA_TMP'));

const fetchCommonDataTmp = (dispatch, companyId, isCanceled) => {
  dispatch(FETCH_COMMON_DATA_TMP.pending());

  const url = ENDPOINTS.liveLook.commonDataTmp.replace(':companyId', companyId);

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_COMMON_DATA_TMP.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_COMMON_DATA_TMP.failure());
    });
};

export default fetchCommonDataTmp;
