import { requestStatuses } from 'modules/shared/constants';
import { GRAPH_SCALES, GRAPH_VALUES, GRAPH_SWITCHER } from 'modules/liveLook/constants';

import {
  FETCH_MEETS_OVER_TIME,
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
  FETCH_MEETS_DATABOXES,
  SET_GRAPH_SWITCHER,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
} from 'modules/liveLook/actions';

export const initialState = {
  requestStatus: requestStatuses.default,
  databoxRequestStatus: requestStatuses.default,
  textReportRequestStatus: requestStatuses.default,
  data: [],
  scale: GRAPH_SCALES.halfAnHour,
  value: GRAPH_VALUES.max,
  returnPercentageSwitcher: GRAPH_SWITCHER.percentages,
  meetsDataboxes: [],
  editableTextReport: '',
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_MEETS_OVER_TIME.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_MEETS_OVER_TIME.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload,
      };
    case FETCH_MEETS_OVER_TIME.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    case FETCH_MEETS_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_MEETS_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        meetsDataboxes: payload,
      };
    case FETCH_MEETS_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SET_GRAPH_SCALE().type:
      return {
        ...state,
        scale: payload,
      };
    case SET_GRAPH_VALUE().type:
      return {
        ...state,
        value: payload,
      };
    case SET_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcher: payload,
      };
    default:
      throw new Error();
  }
}

export default reducer;
