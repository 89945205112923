/* eslint-disable camelcase */
import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_BREAK_DATABOXES = createAsyncAction(createAction('FETCH_BREAK_DATABOXES'));
export const FETCH_BREAK_DATABOXES_WEEK = createAsyncAction(createAction('FETCH_BREAK_DATABOXES_WEEK'));

const fetchBreakDataboxes = (
  dispatch,
  isCanceled,
  { companyId, dateAfter, dateBefore, workingHours, scaleOption, query, fixed_date_range, week, archive },
) => {
  dispatch(week ? FETCH_BREAK_DATABOXES_WEEK.pending() : FETCH_BREAK_DATABOXES.pending());

  const url = ENDPOINTS.liveLook.breakDataboxes
    .replace(':companyId', companyId)
    .concat(workingHours ? `?work_hours=True` : '')
    .concat(workingHours ? `&exclude_weekend=True` : '')
    .concat(dateAfter ? (workingHours ? `&date_after=${dateAfter}` : `?date_after=${dateAfter}`) : '')
    .concat(dateBefore ? `&date_before=${dateBefore}` : '')
    .concat(scaleOption ? `&date_scale=${scaleOption.toLowerCase()}` : '')
    .concat(
      fixed_date_range
        ? workingHours
          ? `&fixed_date_range=${fixed_date_range}`
          : `?fixed_date_range=${fixed_date_range}`
        : '',
    )
    .concat(query ? `&${query}` : '')
    .concat(archive ? `&archive_id=${archive}` : '');

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(week ? FETCH_BREAK_DATABOXES_WEEK.success(data) : FETCH_BREAK_DATABOXES.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(week ? FETCH_BREAK_DATABOXES_WEEK.failure() : FETCH_BREAK_DATABOXES.failure());
    });
};

export default fetchBreakDataboxes;
