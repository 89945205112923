/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography, Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { getEditGraphId } from 'modules/workLook/selectors';
import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';
import CloseIcon from '@material-ui/icons/Close';
import { getUser } from 'modules/auth/selectors';
import { useFormik } from 'formik';
import theme from './CreateTemplateModalContainer.module.scss';

const CreateTemplateContainerModal = React.forwardRef(({ onClose }) => {
  const { reportId } = useSelector(getEditGraphId);
  const { user } = useSelector(getUser);
  const initialCreateValues = {
    user: user.id,
    name: '',
  };

  const validationCreateSchema = Yup.object().shape({
    name: Yup.string().required('*Name required'),
  });

  const formik = useFormik({
    initialValues: initialCreateValues,
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append('name', values.name);
      data.append('user', values.user);
      const url = ENDPOINTS.workLook.createTemplate.replace(':reportId', reportId);

      API.post(url, data).then(() => {
        onClose();
        window.location.reload(false);
      });
    },
  });

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Typography variant="h5" className={theme.title}>
            Create Template
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={theme.formWrapper}>
          <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
            <div>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                inputProps={{ maxLength: 100 }}
                placeholder="Enter Template name"
                value={formik.values.name}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.name}
              </Typography>
            </div>
            <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateTemplateContainerModal;
