/* eslint-disable no-useless-concat */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import SearchIcon from '@material-ui/icons/Search';
import { TextField, withStyles } from '@material-ui/core';

import { SET_SEARCH } from 'modules/assetLook/actions';
import { useAssetLookSelector } from 'modules/assetLook/hooks';

function SearchField({ classes, disabled, placeholder }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [search] = useAssetLookSelector(['filters.search']);

  const onChange = useCallback((e) => dispatch(SET_SEARCH(e.target.value)), [dispatch]);

  useEffect(() => () => dispatch(SET_SEARCH('')), [dispatch]);

  const inputEl = useRef(null);

  return (
    <div className={cn(classes.container, { active: Boolean(search) })}>
      <SearchIcon
        classes={{ root: classes.icon }}
        onClick={() => {
          inputEl.current.focus();
          setIsOpen(!isOpen);
        }}
      />
      <TextField
        inputRef={inputEl}
        type="search"
        disabled={disabled}
        placeholder={placeholder}
        value={search}
        onChange={onChange}
        InputProps={{
          disableUnderline: true,
        }}
        className={cn(classes.field, { [classes.fieldActive]: isOpen })}
      />
    </div>
  );
}

export default withStyles((theme) => ({
  container: {
    height: '32px',
    maxHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    borderRadius: '16px',
    backgroundColor: theme.palette.grey[200],
    boxShadow: theme.shadows[2],
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' +
      'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' +
      'border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: theme.shadows[4],
    },
    '&.active': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  icon: {
    cursor: 'pointer',
  },
  field: {
    width: '0px',
    paddingLeft: '0px',
    transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' + 'padding-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  fieldActive: {
    width: '175px',
    paddingLeft: '8px',
    transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' + 'padding-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}))(SearchField);
