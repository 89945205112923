/* eslint-disable no-shadow */
import React from 'react';
import { chunk, cloneDeep } from 'lodash';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { reportPageTypes } from 'modules/spaceLook/constants';
import { toBuildingAndFloorGraphData } from 'modules/spaceLook/utils';

import {
  PageHeader,
  FilterHandler,
  DesksUsageByBuildingAndFloorGraph,
  ReportDataCards,
  ReportFilter,
  ReportText,
} from 'modules/spaceLook/components';
import { useHasScroll } from 'modules/shared/hooks';

const DesksByBuildingAndFloor = ({ isLoading, pdfMode, databoxes, byBuildingAndFloorData: { data, type } }) => {
  const graphDatasets = pdfMode
    ? chunk(data, 5).map((chunk) => toBuildingAndFloorGraphData({ data: chunk, type }))
    : [toBuildingAndFloorGraphData({ data, type })];
  const scroll = useHasScroll();

  return graphDatasets.map((dataset, idx) => (
    <div ref={scroll.ref} key={idx} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Desks by Building and Floor" text="Desk Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.desks}>
              <ReportFilter pageType={reportPageTypes.desks} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.desks} cropped={!pdfMode} />
        </Grid>

        <Grid item xs={12}>
          <DesksUsageByBuildingAndFloorGraph data={dataset} />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={[
              'desksPropertyOrFloorHighestOcc',
              'desksPropertyOrFloorHighestUtil',
              'desksPropertyOrFloorLowestOcc',
            ]}
            buildingAndFloorData={{
              data: cloneDeep(data),
              type,
            }}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default DesksByBuildingAndFloor;
