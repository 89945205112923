/* eslint-disable camelcase */
import { getBuildingOrFloorData } from 'modules/spaceLook/utils';

const BuildingAndFloorEfficiency = ({
  graphData,
  databoxes: {
    textblock_data: { peak_max_seat_usage_building_or_floor, peak_max_seat_usage_percent, peak_avg_seat_usage_percent },
  },
}) => {
  const { highestValue, nameKey } = getBuildingOrFloorData(graphData);

  // eslint-disable-next-line no-restricted-globals
  const buildingOrFloor = isNaN(peak_max_seat_usage_building_or_floor)
    ? `building ${peak_max_seat_usage_building_or_floor}`
    : `floor ${peak_max_seat_usage_building_or_floor}`;

  return `In terms of seat efficiency ${buildingOrFloor}
     ${peak_max_seat_usage_building_or_floor === highestValue[nameKey] ? 'also' : ''} had the highest seat usage
     with a peak of ${peak_max_seat_usage_percent}% and an average seat usage of ${peak_avg_seat_usage_percent}%.`;
};

export default BuildingAndFloorEfficiency;
