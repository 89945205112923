import location from 'assets/images/icons/surveyDetails/location.svg';
import calendar from 'assets/images/icons/surveyDetails/calendar.svg';
import eye from 'assets/images/icons/surveyDetails/eye.svg';
import search from 'assets/images/icons/surveyDetails/search.svg';

const config = [
  {
    icon: location,
    color: '#f6a000',
  },
  {
    icon: calendar,
    color: '#a2559d',
  },
  {
    icon: eye,
    color: '#00aad2',
  },
  {
    icon: search,
    color: '#b4cc04',
  },
];

export default config;
