/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import theme from './HeatmapItem.module.scss';

const HeatmapItem = ({ label, icon, path, items, activeReportId, activeHeatmapId }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <ListItem onClick={() => setOpen(!open)} className={theme.listItem}>
        <ListItemIcon className={theme.icon}>
          <img src={icon} alt="Thermometer icon." />
        </ListItemIcon>
        <ListItemText primary={label} primaryTypographyProps={{ className: theme.text }} />
        {open ? <ArrowDropUpIcon className={theme.arrow} /> : <ArrowDropDownIcon className={theme.arrow} />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {items.map(({ id: heatmapId, name }) => (
            <Link to={path.replace(':id', activeReportId).replace(':heatmapId', heatmapId)} key={heatmapId}>
              <ListItem className={theme.listSubItem}>
                <ListItemText
                  primary={name}
                  inset
                  className={theme.textContainer}
                  primaryTypographyProps={{
                    className: cn(theme.text, { [`${theme.active}`]: heatmapId === activeHeatmapId }),
                  }}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default HeatmapItem;
