import React from 'react';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { reportPageTypes } from 'modules/spaceLook/constants';
import { getPropertyNamesMap } from 'modules/shared/selectors';
import { propertyIdToPropertyName, updatePropertyIdToName } from 'modules/spaceLook/utils';
import { setStackedGraphColumnsData, setupBuildingFloorData, splitOneGraph } from 'utils';

import {
  PageHeader,
  FilterHandler,
  BuildingAndFloorUsageGraph,
  ReportText,
  ReportDataCards,
  ReportFilter,
} from 'modules/spaceLook/components';
import { useHasScroll } from 'modules/shared/hooks';

const BreakoutByBuildingAndFloor = ({
  isLoading,
  pdfMode,
  databoxes,
  buildingUtilizationData,
  floorUtilizationData,
}) => {
  const { propertyNamesMap } = useSelector(getPropertyNamesMap);
  const scroll = useHasScroll();
  const graphData =
    buildingUtilizationData.length > 1
      ? propertyIdToPropertyName(buildingUtilizationData, propertyNamesMap)
      : floorUtilizationData;
  const key = buildingUtilizationData.length > 1 ? 'location_id__building' : 'location_id__floor';
  const graphColumns = setStackedGraphColumnsData(setupBuildingFloorData(graphData, null, key));
  const graphs = pdfMode ? splitOneGraph(graphColumns, 5) : [graphColumns];

  const datas = graphs.map((graph) => {
    return {
      labels: graph.labels,
      datasets: [
        {
          label: 'Avg in Use, %',
          backgroundColor: '#00aad2',
          data: graph.occ_percent_avg,
        },
        {
          label: 'Max in Use, %',
          backgroundColor: '#0D6F85',
          data: graph.occ_percent_max,
        },
      ],
    };
  });

  return datas.map((data, idx) => (
    <div ref={scroll.ref} key={idx} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Breakout Space Usage By Building and Floor" text="Breakout Space Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.breakout}>
              <ReportFilter pageType={reportPageTypes.breakout} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.breakout} cropped={!pdfMode} />
        </Grid>

        <Grid item xs={12}>
          <BuildingAndFloorUsageGraph data={data} />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={[
              'buildingAndFloorHighestUsage',
              'buildingAndFloorEfficiency',
              'buildingAndFloorLowestUsage',
            ]}
            pageType={reportPageTypes.breakout}
            graphData={cloneDeep(graphData)}
            databoxes={updatePropertyIdToName(databoxes, propertyNamesMap)}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default BreakoutByBuildingAndFloor;
