import React from 'react';
import { Marker, Circle, LayerGroup, Popup } from 'react-leaflet';
import { useMapStartZoom } from 'modules/shared/utils';
import renderPopupData from '../utils';

const greenOptions = { color: 'green', fillColor: 'green' };

function MarkerComponent({ data }) {
  useMapStartZoom(data);
  return (
    <>
      {data?.map(({ id, coordinates, ...rest }, index) => {
        const checkStatic = id === 'static';
        return (
          <div key={index}>
            <If condition={checkStatic}>
              <LayerGroup>
                <Circle center={coordinates} pathOptions={greenOptions} radius={300} />
                <Marker key={id} position={coordinates}>
                  <Popup>{renderPopupData(rest)}</Popup>
                </Marker>
              </LayerGroup>
            </If>
            <If condition={!checkStatic}>
              <Marker key={id} position={coordinates}>
                <Popup>{renderPopupData(rest)}</Popup>
              </Marker>
            </If>
          </div>
        );
      })}
    </>
  );
}

export default MarkerComponent;
