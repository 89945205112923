/* eslint-disable no-shadow */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { RESET_FILTERS } from 'modules/liveLook/actions';
import { showModal } from 'modules/modalsSystem/actions';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';

import { pageTypes } from 'modules/liveLook/constants';

// eslint-disable-next-line import/no-cycle
import FilterList from './FilterList';

function FilterListContainer({ disabled, subtypes, doorSensorData, pageType, isDashboard }) {
  const dispatch = useDispatch();

  const [
    initFilters,
    activeFilters,
    doorSensorInitFilters,
    activeDoorSensorFilters,
    initDeskFilters,
    activeDeskFilters,
    initMeetFilters,
    activeMeetFilters,
    initBreakFilters,
    activeBreakFilters,
    initCombinedFilters,
    activeCombinedFilters,
  ] = useLiveLookSelector([
    'filters.initFilters',
    'filters.activeFilters',
    'doorSensorFilters.doorSensorInitFilters',
    'doorSensorFilters.activeDoorSensorFilters',
    'deskFilters.initDeskFilters',
    'deskFilters.activeDeskFilters',
    'meetFilters.initMeetFilters',
    'meetFilters.activeMeetFilters',
    'breakFilters.initBreakFilters',
    'breakFilters.activeBreakFilters',
    'combinedFilters.initCombinedFilters',
    'combinedFilters.activeCombinedFilters',
  ]);

  const onFilterButtonClick = useCallback(
    (filterField) => dispatch(showModal(modalCodes.LL_FILTER_MODAL, { filterField, pageType })),
    [dispatch, pageType],
  );

  const onResetClick = useCallback(() => dispatch(RESET_FILTERS()), [dispatch]);

  const setInitFilters = (pageType, subtypes) => {
    if (!doorSensorData && pageType === pageTypes.desks) {
      return subtypes ? initDeskFilters : initDeskFilters.filter((filter) => filter.field !== 'Subtypes');
    }

    if (doorSensorData && pageType === pageTypes.occupancy) {
      const initFilters = subtypes
        ? doorSensorInitFilters
        : doorSensorInitFilters.filter((filter) => filter.field !== 'Subtypes');

      return isDashboard ? initFilters.filter((filter) => filter.field !== 'Weekday') : initFilters;
    }

    if (!doorSensorData && pageType === pageTypes.meetings) {
      return subtypes ? initMeetFilters : initMeetFilters.filter((filter) => filter.field !== 'Subtypes');
    }

    if (!doorSensorData && pageType === pageTypes.breakout) {
      return subtypes ? initBreakFilters : initBreakFilters.filter((filter) => filter.field !== 'Subtypes');
    }

    if (!doorSensorData && pageType === pageTypes.combinedOccupancy) {
      return subtypes ? initCombinedFilters : initCombinedFilters.filter((filter) => filter.field !== 'Subtypes');
    }

    return subtypes ? initFilters : initFilters.filter((filter) => filter.field !== 'Subtypes');
  };

  const setInitActiveFilters = (pageType) => {
    if (pageType === pageTypes.desks) {
      return activeDeskFilters;
    }

    if (pageType === pageTypes.occupancy) {
      return activeDoorSensorFilters;
    }

    if (pageType === pageTypes.meetings) {
      return activeMeetFilters;
    }

    if (pageType === pageTypes.breakout) {
      return activeBreakFilters;
    }

    if (pageType === pageTypes.combinedOccupancy) {
      return activeCombinedFilters;
    }

    return activeFilters;
  };

  return (
    <FilterList
      disabled={disabled}
      initFilters={setInitFilters(pageType, subtypes)}
      activeFilters={setInitActiveFilters(pageType)}
      onFilterButtonClick={onFilterButtonClick}
      onResetClick={onResetClick}
    />
  );
}

export default FilterListContainer;
