/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-this-in-sfc */
import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import Editor from '@draft-js-plugins/editor';

import { Button, CircularProgress } from '@material-ui/core';

import { CustomButtonContained } from 'modules/shared/components';
import { SuggestionComponent } from 'modules/shared/components/TextEditor/components';
import { TEXT_CHARACTERS, TEXT_ROWS, GRAPH_TYPES } from 'modules/liveLook/constants';

import { useHasScroll } from 'modules/shared/hooks';
import { lowerFontSize } from 'modules/shared/utils';
import ActionButtons from './ActionButtons';

import decorators from './decorators';
import TextEditorContext from './TextEditorContext';

import 'draft-js/dist/Draft.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import theme from './TextEditor.module.scss';

const TextEditor = React.forwardRef(
  (
    {
      refreshKey,
      isEditable,
      isPermitted,
      isPending,
      isSaveToDBPermitted,

      _mapKeyToEditorCommand,
      _handleKeyCommand,
      _onTextAreaClick,
      _onRevert,
      _onSaveClick,
      _onSaveLocally,
      _onCancelClick,

      suggestions,
      _onSearchChange,
      plugins,
      MentionSuggestions,
      AlignmentTool,
      CustomCounter,
      isPdf,
      isKeyFindings,
      isKeyFindingsDesk,
      hasFilters,
      graphType,
      isPeopleLook,
    },
    ref,
  ) => {
    const { editorState, setEditorState, isSaveLocallyHide, isTextLimitHide } = useContext(TextEditorContext);
    const isOverTime = [GRAPH_TYPES.OVERTIME].includes(graphType);
    const isVertical = [GRAPH_TYPES.VERTICAL].includes(graphType);
    const isStacked = [GRAPH_TYPES.STACKED].includes(graphType);
    const scroll = useHasScroll();

    useMemo(() => lowerFontSize(scroll.hasVertScroll, scroll.ref?.current, isPdf), [scroll, isPdf]);

    const getContainerStyling = {
      [`${theme.isEditable}`]: isEditable,
      [`${theme.disabled}`]: isPending,
      [`${theme.isKeyFindings}`]: isKeyFindings && hasFilters,
      [`${theme.isKeyFindingsDesk}`]: isKeyFindingsDesk && hasFilters,
      [`${theme.isKeyFindingsDeskFilters}`]: isKeyFindingsDesk && !hasFilters,
      [`${theme.hasFilters}`]: isKeyFindings && !hasFilters,
      [`${theme.overTime}`]: isPdf && isOverTime && hasFilters,
      [`${theme.overTimeFilters}`]: isPdf && isOverTime && !hasFilters,
      [`${theme.vertical}`]: isPdf && isVertical && hasFilters,
      [`${theme.verticalFilters}`]: isPdf && isVertical && !hasFilters,
      [`${theme.stacked}`]: isPdf && isStacked && hasFilters,
      [`${theme.stackedFilters}`]: isPdf && isStacked && !hasFilters,
    };

    const customCountStringFunction = () => {
      const plainText = editorState.getCurrentContent().getPlainText('');
      const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed

      const cleanString = plainText.replace(regex, '').trim(); // replace above characters w/ nothing

      return cleanString.length;
    };

    const getLineCount = () => {
      const blockArray = editorState.getCurrentContent().getBlocksAsArray();
      return blockArray ? blockArray.length : null;
    };
    const shouldDisableBtn =
      customCountStringFunction() > TEXT_CHARACTERS[graphType] || getLineCount() > TEXT_ROWS[graphType];

    return (
      <>
        <div className={cn(theme.container)}>
          <If condition={isEditable}>
            <ActionButtons />
          </If>

          <div
            onClick={_onTextAreaClick}
            className={cn(theme.textArea, {
              [`${theme.isEditable}`]: isEditable,
              [`${theme.isPermitted}`]: isPermitted,
              [`${theme.isPdf}`]: isPdf,
            })}
            style={{ paddingLeft: isPdf && '0px', fontSize: !isPeopleLook && '1rem' }}
          >
            <div ref={scroll.ref} className={isPdf && cn(theme.pdfContainer, getContainerStyling)}>
              <Editor
                key={refreshKey}
                ref={ref}
                editorState={editorState}
                onChange={setEditorState}
                keyBindingFn={_mapKeyToEditorCommand}
                handleKeyCommand={_handleKeyCommand}
                readOnly={!isEditable}
                plugins={plugins}
                decorators={isEditable ? [decorators.toName] : []}
              />
            </div>
            <If condition={isEditable}>
              <AlignmentTool />
              <MentionSuggestions
                onSearchChange={_onSearchChange}
                suggestions={suggestions}
                entryComponent={SuggestionComponent}
              />
            </If>
          </div>
        </div>

        <If condition={isEditable}>
          {!isTextLimitHide && (
            <div className={theme.countWrapper}>
              <span className={theme.textError}>
                <CustomCounter
                  limit={TEXT_ROWS[graphType]}
                  editorState={editorState}
                  countFunction={getLineCount}
                  // eslint-disable-next-line prettier/prettier
                />
                /{TEXT_ROWS[graphType]} rows
              </span>
              <span className={theme.textError}>
                <CustomCounter
                  limit={TEXT_CHARACTERS[graphType]}
                  countFunction={customCountStringFunction}
                  // eslint-disable-next-line prettier/prettier
                />
                /{TEXT_CHARACTERS[graphType]} characters
              </span>
            </div>
          )}
          <div className={theme.rapper}>
            <div className={theme.buttonsWrapper}>
              <Button
                style={{ marginRight: '10px' }}
                color="primary"
                size="small"
                onClick={_onCancelClick}
                children="Cancel"
              />
              <Button
                style={{ marginRight: '10px' }}
                color="primary"
                size="small"
                onClick={_onRevert}
                children="Revert"
              />
              {!isSaveLocallyHide && (
                <CustomButtonContained
                  disabled={shouldDisableBtn}
                  label="Save locally"
                  size="small"
                  onClick={_onSaveLocally}
                  style={{ marginRight: '10px', backgroundColor: shouldDisableBtn ? '#e0e0e0' : '#0d6f85' }}
                  startIcon={isPending && <CircularProgress size={14} style={{ color: 'white' }} />}
                />
              )}
              {isSaveToDBPermitted && (
                <CustomButtonContained
                  disabled={!isTextLimitHide ? shouldDisableBtn : false}
                  label="Save to DB"
                  size="small"
                  onClick={_onSaveClick}
                  startIcon={isPending && <CircularProgress size={14} style={{ color: 'white' }} />}
                />
              )}
            </div>
          </div>
        </If>
      </>
    );
  },
);

export default TextEditor;
