/* eslint-disable camelcase */
import React from 'react';
import { Typography } from '@material-ui/core';

import { BASKET_TABLE_COLUMNS, BASKET_COLUMNS_WIDTH_MAP } from 'modules/assetLook/constants';

import { FixedDataTable } from 'modules/shared/components';
// eslint-disable-next-line import/no-cycle
import { AutoSizer, BasketCellHeader as CellHeader, BasketCellBody as CellBody } from 'modules/assetLook/components';

import { Accordion, AccordionDetails, AccordionSummary } from '../CustomAccordion';

const HEADER_HEIGHT = 36;
const ROW_HEIGHT = 34;
const BORDER_HEIGHT = 4;

function AccordionAssets({ expanded, onChange, summary, assets_summary }) {
  const tableHeight = HEADER_HEIGHT + BORDER_HEIGHT + assets_summary.length * ROW_HEIGHT;

  return (
    <Accordion square expanded={expanded} onChange={onChange}>
      <AccordionSummary aria-controls={`${summary}-content`} id={`${summary}-header`}>
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AutoSizer>
          {({ width }) => (
            <FixedDataTable
              width={width}
              height={tableHeight < 500 ? tableHeight : 500}
              rows={assets_summary}
              columns={BASKET_TABLE_COLUMNS}
              ColumnHeader={CellHeader}
              ColumnCell={CellBody}
              columnFixed={() => false}
              columnWidth={(columnKey, i) => BASKET_COLUMNS_WIDTH_MAP[i] || 150}
              columnFlexGrow={(columnKey) => (columnKey !== '_total' ? 1 : 0)}
            />
          )}
        </AutoSizer>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionAssets;
