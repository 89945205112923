/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { isLogged, redirectToLogin, findActiveCompany, getUserCompanies, defineCompanyNameAndLogo } from 'utils';

import { PageHeader, HistoryReportList, StaticSidebar, Loader } from 'components';
import { NavBar, TextPlaceholder } from 'modules/shared/components';

import { REQUESTS } from '_constants';
import { withUser } from 'hocs';

import './ReportHistoryPage.scss';

const { base, version, historyReport } = REQUESTS;

const ReportHistoryPage = ({ history, user, currentCompany, setCurrentCompany }) => {
  const [reports, setReports] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const token = isLogged();

  const activeCompany = currentCompany || findActiveCompany(user);
  const companies = getUserCompanies(user);
  const [companyName, logoUrl] = defineCompanyNameAndLogo(companies, user, activeCompany);

  useEffect(() => {
    let canceled = false;

    setIsLoading(true);

    const surveyListUrl = `${base}${version}${historyReport}?company_id=${activeCompany}`;
    const config = {
      headers: { Authorization: `Token ${token}` },
    };

    axios
      .get(surveyListUrl, config)
      .then(({ data }) => {
        if (canceled) return;

        // eslint-disable-next-line no-shadow
        const reports = data.map(
          ({
            area,
            capacity,
            current_allocation,
            desks_count,
            peak_occupancy,
            peak_occupancy_desks,
            peak_utilization,
            peak_utilization_desks,
            dates,
            spare_capacity,
            spare_capacity_percent,
            property,
            pk,
          }) => {
            return {
              id: pk,
              date: {
                start: dates ? dates.begin_date : '',
                end: dates ? dates.end_date : '',
              },
              place: property,
              data: [
                {
                  title: 'Size',
                  label: `${area}m2`,
                },
                {
                  title: 'Desks',
                  label: desks_count,
                },
                {
                  title: 'Cur. Allocation',
                  label: current_allocation,
                },
                {
                  title: 'Capacity',
                  label: capacity,
                },
                {
                  title: 'Spare Capacity',
                  percent: spare_capacity_percent !== null ? `${spare_capacity_percent}%` : 'no %',
                  label: spare_capacity,
                },
                {
                  title: 'Peak Utilisation',
                  percent: peak_utilization !== null ? `${peak_utilization}%` : 'no %',
                  label: peak_utilization_desks,
                },
                {
                  title: 'Peak Occupancy',
                  percent: peak_occupancy !== null ? `${peak_occupancy}%` : 'no %',
                  label: peak_occupancy_desks,
                },
              ],
            };
          },
        );
        setReports(reports);
        setIsLoading(false);
      })
      .catch(() => {
        setReports([]);
        setIsLoading(false);
      });

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
  }, [activeCompany, history, token]);

  redirectToLogin(history);

  return (
    <div className="ReportHistoryPage">
      <div className="report-history-page">
        <div className="history-nav">
          <StaticSidebar
            items={companies}
            onItemClick={setCurrentCompany}
            currentItem={activeCompany}
            clientName={companyName}
            logoUrl={logoUrl}
            hideSummary
            hideDetails
          />
        </div>
        <div className="history-content-wrapper">
          <div className="nav-menu">
            <NavBar />
          </div>
          <div className="history-content">
            <PageHeader title="Report History" text="Click on the  links to see more about the reports" />
            <Choose>
              <When condition={isLoading}>
                <Loader />
              </When>
              <When condition={!reports.length}>
                <TextPlaceholder noData />
              </When>

              <Otherwise>
                <HistoryReportList reports={reports} />
              </Otherwise>
            </Choose>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUser(ReportHistoryPage);
