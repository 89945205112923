import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';
import { PageHeader } from 'modules/shared/components';
import { ReportDataCards, VerticalBarGraph } from 'modules/liveLook/components';
import { isEmpty } from 'lodash';

import { pageTypes } from 'modules/liveLook/constants';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';
import graphDeskConfig from './graphDeskConfig';
import renderFilters from '../../utils/activeFiltersPdf';
import styles from '../../LLPrintablePdfPage.module.scss';
import graphStyles from '../../styles/verticalGraph.module.scss';

const SummaryKeyFindingsDesks = ({
  databoxes,
  databoxesSet,
  editableTextReport,
  deskGraphData,
  deskGraphScaleValues,
  deskPeakAndAvgGraphData,
  deskPeakAndAvgScaleValues,
  returnPercentageSwitcherDeskPeakAndAvg,
  returnPercentageSwitcher,
  activeFilters,
  textVariables,
}) => {
  const editorRef = useRef();
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));

  return (
    <div className={cn(styles.pdfPage)}>
      <Grid style={{ paddingTop: '20px', paddingLeft: '30px', paddingRight: '10px' }} container spacing={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader pdfMode title="Executive Summary - Key Findings - Desks" />
          </Grid>
        </Grid>
        {!shouldRenderFilters && renderFilters(activeFilters)}
        <Grid xs={6}>
          <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.desks} />
          <Grid ref={editorRef} style={{ paddingTop: 10 }}>
            <PDFTextEditorContainer
              data={databoxesToEditorData(databoxesSet, textVariables)}
              onSave={() => {}}
              text={editableTextReport}
              isKeyFindingsDesk
              hasFilters={shouldRenderFilters}
            />
          </Grid>
        </Grid>

        <Grid item xs={6} style={{ marginTop: -16 }}>
          <div className={cn(graphStyles.verticalGraph)}>
            <VerticalBarGraph
              title="Occupancy By Week"
              data={deskGraphData}
              customHeader
              scaleValues={deskGraphScaleValues}
              onSwitcherChange={() => {}}
              config={graphDeskConfig}
              switcher={returnPercentageSwitcher}
              isSummaryPdf
              pdfCustomHeader
              returnPercentageSwitcher={returnPercentageSwitcher}
              isWeekGraph
              isLiveLook
            />
          </div>
          <div className={cn(graphStyles.verticalGraph)}>
            <VerticalBarGraph
              title="Peak and Average by Type"
              label="Integers"
              data={deskPeakAndAvgGraphData}
              config={graphDeskConfig}
              onSwitcherChange={() => {}}
              scaleValues={deskPeakAndAvgScaleValues}
              switcher={returnPercentageSwitcherDeskPeakAndAvg}
              isSummaryPdf
              pdfCustomHeader
              returnPercentageSwitcher={returnPercentageSwitcherDeskPeakAndAvg}
              isLiveLook
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SummaryKeyFindingsDesks;
