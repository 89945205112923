import React, { useState } from 'react';
import { Grid, Paper, withStyles, Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { PAGE_TYPES } from 'modules/assetLook/constants';

import {
  Counter,
  PageHeader,
  AllAssetsTable,
  TargetAssetListTable,
  PageSelectorBar,
  FilterListCategory,
  AssetManagementBar,
} from 'modules/assetLook/components';

const AllAssetPage = ({ classes }) => {
  const [isTargetChecked, setTargetChecked] = useState(false);

  return (
    <Grid container spacing={4} direction="column" wrap="nowrap" classes={{ root: classes.container }}>
      <Grid item>
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PageHeader />
              </Grid>
              <Grid item xs={12}>
                <PageSelectorBar />
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <FilterListCategory isTargetChecked={isTargetChecked} searchPlaceholder="Asset ID" />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isTargetChecked}
                        onChange={() => setTargetChecked(!isTargetChecked)}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Target location"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Counter pageType={PAGE_TYPES.assetList} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item classes={{ root: classes.flexGrow }}>
        <Paper
          elevation={4}
          classes={{
            root: classes.assetListWrapper,
          }}
        >
          <AssetManagementBar />
          <div className={classes.flexGrow}>
            {
              // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
              isTargetChecked ? (
                <TargetAssetListTable isAllAssets isTargetChecked={isTargetChecked} />
              ) : (
                <AllAssetsTable />
              )
            }
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(() => ({
  container: {
    height: 'calc(100% + 32px)',
  },
  flexGrow: {
    flex: '1 1 1px',
  },
  productDetailsWrapper: {
    height: '35%',
    maxHeight: '35%',
    marginBottom: '16px',
  },
  assetListWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
}))(AllAssetPage);
