/* eslint-disable prefer-spread */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useCallback, useEffect, useReducer } from 'react';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { dateToQuery, filtersToQuery, toDailyGraphData, toByUsageDeskGraphData } from 'modules/liveLook/utils';
import {
  fetchDoorsDailyComparisons,
  fetchDeskAndBreak,
  SET_GRAPH_SWITCHER,
  SET_SECOND_GRAPH_SWITCHER,
  fetchOccupancyDataboxes,
  fetchGraphsText,
  saveGraphsText,
  fetchCombinedOccupancyVariables,
} from 'modules/liveLook/actions';
import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import SummaryKeyFindingsOccupancy from './SummaryKeyFindingsOccupancy';
import { setScaleValues } from '../utils';
import reducer, { initialState } from './_reducer';
import setScaleOption from '../utils/common';

function SummaryKeyFindingsOccupancyContainer() {
  const [companyId] = useAuthSelector(['currentCompany']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    scale,
    occupancyDataboxes,
    databoxRequestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
    deskAndBreakRequestStatus,
    returnPercentageSwitcherDeskAndBreak,
    deskAndBreakData,
    textVariables,
    combinedOccVariablesRequestStatus,
  } = state;

  const scaleOption = setScaleOption(scale);

  const requestStatuses = [
    databoxRequestStatus,
    deskAndBreakRequestStatus,
    requestStatus,
    textReportRequestStatus,
    combinedOccVariablesRequestStatus,
  ];

  const [startDate, endDate, workingHours, activeDeskFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'deskFilters.activeDeskFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeDeskFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;

    fetchDoorsDailyComparisons(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchDeskAndBreak(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchOccupancyDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
      query,
      archive,
    });

    fetchCombinedOccupancyVariables(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'sum_overall',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, dateAfter, dateBefore, workingHours, scaleOption, query]);

  const databoxesSet = {
    occupancyDataboxes,
  };

  // eslint-disable-next-line no-shadow
  const mappedData = (data) => {
    const arrData = [];
    for (const key in data) {
      arrData.push(Object.assign(data[key], { name: key }));
    }
    return arrData;
  };

  const peakOccupancy = Math.max.apply(
    Math,
    mappedData(data).map((key) => key[returnPercentageSwitcher ? 'max' : 'percentage_max']),
  );

  const dailyComparisons = setScaleValues(peakOccupancy);

  const deskAndBreakGraphPeakOccupancy = Math.max.apply(
    Math,
    mappedData(deskAndBreakData).map((key) => key[returnPercentageSwitcherDeskAndBreak ? 'usage' : 'percentage_usage']),
  );

  const deskAndBreakout = setScaleValues(deskAndBreakGraphPeakOccupancy);

  const onSwitcherChange = useCallback(
    () => dispatch(SET_SECOND_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  const deskAndBreakOnSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcherDeskAndBreak)),
    [returnPercentageSwitcherDeskAndBreak],
  );

  return (
    <Choose>
      <When condition={requestStatuses.some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={requestStatuses.every((item) => isSuccess(item))}>
        <SummaryKeyFindingsOccupancy
          {...state}
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'sum_overall', text });
          }}
          editableTextReport={editableTextReport}
          scaleValues={dailyComparisons.scaleValues}
          onSwitcherChange={onSwitcherChange}
          data={toDailyGraphData(data, returnPercentageSwitcher)}
          databoxes={occupancyDataboxes}
          databoxesSet={databoxesSet}
          deskAndBreakGraphData={toByUsageDeskGraphData(deskAndBreakData, returnPercentageSwitcherDeskAndBreak)}
          deskAndBreakScaleValues={deskAndBreakout.scaleValues}
          deskAndBreakOnSwitcherChange={deskAndBreakOnSwitcherChange}
          returnPercentageSwitcher={returnPercentageSwitcher}
          returnPercentageSwitcherDeskAndBreak={returnPercentageSwitcherDeskAndBreak}
          textVariables={textVariables}
          customHeader
          archive={archive}
        />
      </When>
      <When condition={requestStatuses.some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default SummaryKeyFindingsOccupancyContainer;
