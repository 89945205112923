import { useMemo } from 'react';

import { toSeatsUsageOverTimeGraphData } from 'modules/liveLook/utils';
import { setDataGroupedByDays } from '../utils/setDataForOverTime';
import { setScaleValues, toLondonTimeZone } from '../utils';
import { isInPercentages } from '../utils/common';

const useGetBreakData = ({ data, scale, returnPercentageSwitcher, breakDataboxes, value }) => {
  const dataGroupedByDaysBreak = setDataGroupedByDays(data, scale, toLondonTimeZone);

  const peakOccupancyBreak = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        breakDataboxes,
        breakDataboxes?.max_rooms_usage,
        breakDataboxes?.percentage_max_rooms_usage,
      ),
    [returnPercentageSwitcher, breakDataboxes],
  );

  const peakSeatOccupancyBreak = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        breakDataboxes,
        breakDataboxes?.max_seats_usage,
        breakDataboxes?.percentage_max_seats_usage,
      ),
    [returnPercentageSwitcher, breakDataboxes],
  );

  const avgOccupancyBreak = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        breakDataboxes,
        breakDataboxes?.avg_rooms_usage,
        breakDataboxes?.percentage_avg_rooms_usage,
      ),
    [returnPercentageSwitcher, breakDataboxes],
  );

  const peakValueBreak = Math.max(peakOccupancyBreak, peakSeatOccupancyBreak);
  const { niceMax, scaleValues } = setScaleValues(peakValueBreak);

  const linesDataBreak = {
    peakOccupancy: peakOccupancyBreak,
    avgOccupancy: avgOccupancyBreak,
  };

  const graphDataBreak = toSeatsUsageOverTimeGraphData(
    {
      sourceData: dataGroupedByDaysBreak,
      valueType: value,
      scale,
      returnPercentageSwitcher,
      niceMax,
    },
    true,
  );
  return { scale: scaleValues, linesDataBreak, graphDataBreak };
};

export default useGetBreakData;
