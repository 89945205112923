/* eslint-disable no-shadow */
/* eslint-disable prefer-spread */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useReducer } from 'react';

import { isEmpty } from 'lodash';

import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, filtersToQuery, toSpaceComparisonGraphData } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import {
  SET_GRAPH_SWITCHER,
  fetchMeetsDataboxes,
  fetchGraphsText,
  saveGraphsText,
  fetchMeetsPeakAndAvgUtilLive,
  fetchMeetsUsageBySize,
} from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';

import SummaryKeyFindingsMeet from './SummaryKeyFindingsMeet';
import { setScaleValues } from '../utils';
import reducer, { initialState } from './_reducer';
import { setGraphInfo } from '../../../../utils';

function SummaryKeyFindingsMeetContainer() {
  const [companyId] = useAuthSelector(['currentCompany']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    meetsDataboxes,
    databoxRequestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
    meetSpaceBySizeRequestStatus,
    meetSpaceBySizeData,
  } = state;

  const [startDate, endDate, workingHours, activeFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'filters.activeFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;

    fetchMeetsPeakAndAvgUtilLive(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchMeetsUsageBySize(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchMeetsDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'meet_key_data',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, dateAfter, dateBefore, workingHours, query]);

  const databoxesSet = {
    meetsDataboxes,
  };

  const mappedData = (data) => {
    const arrData = [];
    for (const key in data) {
      arrData.push(Object.assign(data[key], { name: key }));
    }
    return arrData;
  };

  const peakSpaceOccupancy = Math.max.apply(
    Math,
    mappedData(data).map(
      (key) => key[returnPercentageSwitcher ? 'max_space_occupancy' : 'percentage_max_space_occupancy'],
    ),
  );
  const peakSeatOccupancy = Math.max.apply(
    Math,
    mappedData(data).map(
      (key) => key[returnPercentageSwitcher ? 'max_seat_occupancy' : 'percentage_max_seat_occupancy'],
    ),
  );
  const peakOccupancy = Math.max(peakSpaceOccupancy, peakSeatOccupancy);

  const { scaleValues } = setScaleValues(peakOccupancy);

  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  if (isEmpty(meetSpaceBySizeData)) return null;

  const graphData = setGraphInfo(meetSpaceBySizeData);

  return (
    <Choose>
      <When
        condition={[databoxRequestStatus, meetSpaceBySizeRequestStatus, requestStatus, textReportRequestStatus].some(
          (item) => isDefault(item) || isLoading(item),
        )}
      >
        <Loader />
      </When>
      <When
        condition={[databoxRequestStatus, meetSpaceBySizeRequestStatus, requestStatus, textReportRequestStatus].every(
          (item) => isSuccess(item),
        )}
      >
        <SummaryKeyFindingsMeet
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'meet_key_data', text });
          }}
          editableTextReport={editableTextReport}
          data={toSpaceComparisonGraphData(mappedData(data), returnPercentageSwitcher)}
          databoxes={meetsDataboxes}
          meetSpaceBySizeData={graphData}
          onSwitcherChange={onSwitcherChange}
          scaleValues={scaleValues}
          databoxesSet={databoxesSet}
          returnPercentageSwitcher={returnPercentageSwitcher}
          archive={archive}
        />
      </When>
      <When
        condition={[databoxRequestStatus, meetSpaceBySizeRequestStatus, requestStatus, textReportRequestStatus].some(
          (item) => isFailure(item),
        )}
      >
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default SummaryKeyFindingsMeetContainer;
