import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'modules/shared/utils';
import { requestStatuses } from 'modules/shared/constants';

export const initialState = {
  requestStatus: requestStatuses.default,
  initialFilters: [],
};

export const FETCH_INIT_FILTERS = createAsyncAction(createAction('FETCH_INIT_FILTERS'));

export function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_INIT_FILTERS.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_INIT_FILTERS.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        initialFilters: payload,
      };
    case FETCH_INIT_FILTERS.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    default:
      throw new Error();
  }
}
