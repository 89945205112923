import React from 'react';
import PropTypes from 'prop-types';

import './GroupedBarLegendItem.scss';

const GroupedBarLegendItem = ({ label, color }) => {
  const backgroundColor = color.startsWith('#') ? color : `var(--${color})`;

  return (
    <div className="GroupedBarLegendItem">
      <div className="grouped-bar-legend-item">
        <span style={{ backgroundColor }} className="color-marker" />
        <h4 className="label">{label}</h4>
      </div>
    </div>
  );
};

GroupedBarLegendItem.defaultProps = {
  label: 'Label',
  color: 'dark-grey',
};

GroupedBarLegendItem.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
};

export default GroupedBarLegendItem;
