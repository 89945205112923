import { requestStatuses } from 'modules/shared/constants';
import { GRAPH_SCALES, GRAPH_VALUES, GRAPH_SWITCHER } from 'modules/liveLook/constants';
import {
  SET_BUILDING,
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
  SET_GRAPH_SWITCHER,
  SET_SECOND_GRAPH_SWITCHER,
  FETCH_OCCUPANCY_DATABOXES,
  FETCH_DESK_DATABOXES,
  FETCH_BREAK_DATABOXES,
  FETCH_MEETS_DATABOXES,
  FETCH_DOORS_OVER_TIME,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
  FETCH_DESK_PEAK_OCCUPANCY_DAY_USAGE,
  FETCH_DESK_VARIABLES,
  FETCH_COMBINED_OCCUPANCY_VARIABLES,
} from 'modules/liveLook/actions';

export const initialState = {
  requestStatus: requestStatuses.default,
  deskGraphRequestStatus: requestStatuses.default,
  databoxRequestStatus: requestStatuses.default,
  breakDataboxRequestStatus: requestStatuses.default,
  meetDataboxRequestStatus: requestStatuses.default,
  textReportRequestStatus: requestStatuses.default,
  deskVariableRequestStatus: requestStatuses.default,
  combinedOccVariablesRequestStatus: requestStatuses.default,
  building: 0,
  data: [],
  deskGraphData: [],
  capacity: null,
  nominalCapacity: null,
  scale: GRAPH_SCALES.week,
  value: GRAPH_VALUES.max,
  returnPercentageSwitcher: GRAPH_SWITCHER.integers,
  deskGraphSwitcher: GRAPH_SWITCHER.integers,
  occupancyDataboxes: [],
  deskDataboxes: [],
  breakDataboxes: [],
  meetsDataboxes: [],
  editableTextReport: '',
  textVariables: {
    deskVariables: {},
    combinedOccupancyVariables: {},
  },
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_DOORS_OVER_TIME.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_DOORS_OVER_TIME.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload.data,
        capacity: payload.capacity,
        nominalCapacity: payload.nominal_capacity,
      };
    case FETCH_DOORS_OVER_TIME.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    case FETCH_OCCUPANCY_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_OCCUPANCY_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        occupancyDataboxes: payload,
      };
    case FETCH_OCCUPANCY_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_DESK_DATABOXES.pending().type:
      return {
        ...state,
        deskDataboxRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_DATABOXES.success().type:
      return {
        ...state,
        deskDataboxRequestStatus: requestStatuses.success,
        deskDataboxes: payload,
      };
    case FETCH_DESK_DATABOXES.failure().type:
      return {
        ...state,
        deskDataboxRequestStatus: requestStatuses.failure,
      };
    case FETCH_BREAK_DATABOXES.pending().type:
      return {
        ...state,
        breakDataboxRequestStatus: requestStatuses.pending,
      };
    case FETCH_BREAK_DATABOXES.success().type:
      return {
        ...state,
        breakDataboxRequestStatus: requestStatuses.success,
        breakDataboxes: payload,
      };
    case FETCH_BREAK_DATABOXES.failure().type:
      return {
        ...state,
        breakDataboxRequestStatus: requestStatuses.failure,
      };
    case FETCH_MEETS_DATABOXES.pending().type:
      return {
        ...state,
        meetDataboxRequestStatus: requestStatuses.pending,
      };
    case FETCH_MEETS_DATABOXES.success().type:
      return {
        ...state,
        meetDataboxRequestStatus: requestStatuses.success,
        meetsDataboxes: payload,
      };
    case FETCH_MEETS_DATABOXES.failure().type:
      return {
        ...state,
        meetDataboxRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case FETCH_DESK_PEAK_OCCUPANCY_DAY_USAGE.pending().type:
      return {
        ...state,
        deskGraphRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_PEAK_OCCUPANCY_DAY_USAGE.success().type:
      return {
        ...state,
        deskGraphRequestStatus: requestStatuses.success,
        deskGraphData: payload,
      };
    case FETCH_DESK_PEAK_OCCUPANCY_DAY_USAGE.failure().type:
      return {
        ...state,
        deskGraphRequestStatus: requestStatuses.failure,
      };
    case FETCH_DESK_VARIABLES.pending().type:
      return {
        ...state,
        deskVariableRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_VARIABLES.success().type:
      return {
        ...state,
        deskVariableRequestStatus: requestStatuses.success,
        textVariables: { ...state.textVariables, ...{ deskVariables: payload } },
      };
    case FETCH_DESK_VARIABLES.failure().type:
      return {
        ...state,
        deskVariableRequestStatus: requestStatuses.failure,
      };
    case FETCH_COMBINED_OCCUPANCY_VARIABLES.pending().type:
      return {
        ...state,
        combinedOccVariablesRequestStatus: requestStatuses.pending,
      };
    case FETCH_COMBINED_OCCUPANCY_VARIABLES.success().type:
      return {
        ...state,
        combinedOccVariablesRequestStatus: requestStatuses.success,
        textVariables: { ...state.textVariables, ...{ combinedOccupancyVariables: payload } },
      };
    case FETCH_COMBINED_OCCUPANCY_VARIABLES.failure().type:
      return {
        ...state,
        combinedOccVariablesRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SET_BUILDING().type:
      return {
        ...state,
        building: payload,
      };
    case SET_GRAPH_SCALE().type:
      return {
        ...state,
        scale: payload,
      };
    case SET_GRAPH_VALUE().type:
      return {
        ...state,
        value: payload,
      };
    case SET_SECOND_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcher: payload,
      };
    case SET_GRAPH_SWITCHER().type:
      return {
        ...state,
        deskGraphSwitcher: payload,
      };
    default:
      throw new Error();
  }
}

export default reducer;
