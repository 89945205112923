import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getUserCompanies } from 'utils';
import URLS from 'pages/constants/urls';
import { getUser } from 'modules/auth/selectors';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';
import { getActiveCompany } from 'modules/shared/selectors';

import defaultLogo from 'assets/images/icons/logo-with-text.svg';

import SidebarHeader from './SidebarHeader';

const SidebarHeaderContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const spaceLookRouteMatch = useRouteMatch(URLS.spaceLook.baseReport);

  const { logo, id } = useSelector(getActiveCompany);
  const { user } = useSelector(getUser);

  const companyList = getUserCompanies(user);

  const onCompanySelect = ({ target: { value } }) => {
    if (spaceLookRouteMatch) history.push(URLS.spaceLook.reportHistory);
    dispatch(SET_CURRENT_COMPANY(value));
  };

  return (
    <SidebarHeader
      userRole={user.role}
      companyLogo={logo || defaultLogo}
      activeCompanyId={id}
      companyList={companyList}
      onCompanySelect={onCompanySelect}
    />
  );
};

export default SidebarHeaderContainer;
