/* eslint-disable camelcase */
import { useSelector } from 'react-redux';

import { getPropertyNamesMap } from 'modules/shared/selectors';

const CombinedBuildingOccupancy = ({ textData: { buildings }, repeatN }) => {
  const { propertyNamesMap } = useSelector(getPropertyNamesMap);

  const propertyName = Object.keys(buildings)[repeatN];
  const propertyInList = propertyNamesMap.find(({ property_id }) => property_id === propertyName);
  const property = (propertyInList && propertyInList.property_name) || propertyName;

  const { nominal_capacity, percent_peak_combined_occupancy } = buildings[propertyName];

  return `The overall capacity for the building ${property} was ${nominal_capacity},
     the peak recorded combined occupancy was ${percent_peak_combined_occupancy}% of capacity.`;
};

export default CombinedBuildingOccupancy;
