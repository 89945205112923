/* eslint-disable camelcase */
import { reportPageTypes } from 'modules/spaceLook/constants';

const MeetsAndBreakOccupancy = ({
  databoxes: {
    total_meet_rooms,
    percent_max_room_occupancy,
    max_room_occupancy,
    percent_avg_room_occupancy,
    avg_room_occupancy,
  },
  pageType,
}) => {
  const isMeets = pageType === reportPageTypes.meetings;

  return `On a ${isMeets ? 'room-by-room' : 'area-by-area'} basis (i.e. if a
     ${isMeets ? '10-seat room has only 1 person' : '4-seat table or sofa has only 1 person'}  in it,
     it is still classed as occupied) the ${total_meet_rooms} ${isMeets ? 'meeting room(s)' : 'breakout space(s)'} 
     surveyed had a maximum observed use of ${percent_max_room_occupancy}% (${max_room_occupancy} rooms occupied)
     with an average use of ${percent_avg_room_occupancy}% (${avg_room_occupancy} rooms occupied).`;
};

export default MeetsAndBreakOccupancy;
