import { createReducer } from '@reduxjs/toolkit';

import {
  SET_GRAPH_DATA,
  SET_GRAPH_EDIT_DATA,
  SET_GRAPH_ID,
  SET_GRAPH_SERIES_LIST,
  SET_REPORT_FIELDS,
  SET_MODAL_TYPE,
  SET_ACTIVE_PAGE_TYPE,
  SET_SECTION_ID,
  SET_REPORT_ID,
  SET_SECTION_DATA,
  SET_PAGE_DATA,
  SET_REPORT_PICTURES,
  SET_MODIFIERS_DATA,
  SET_MODIFIERS_FIELDS,
  SET_MODIFIERS_GROUP_FIELDS,
  SET_GRAPH_GROUP_TYPE,
  SET_MODIFIERS_LEGENDS,
  SET_GRAPH_TYPE,
} from 'modules/workLook/actions';

const initState = {
  graph: {},
  graphId: {},
  reportFields: [],
  seriesList: [],
  typeOfModal: '',
  graphEditData: {},
  activePageType: '1',
  sectionId: 1,
  reportId: 1,
  sectionData: {},
  sectionAction: 'create',
  typeId: 1,
  reportPictures: [],
  couldDelete: false,
  modifiersList: [],
  modifierFields: [],
  modifierGroupFields: [],
  groupType: null,
  legends: [],
  graphType: null,
};

export default createReducer(initState, {
  [SET_MODAL_TYPE]: (state, { payload }) => {
    return {
      ...state,
      typeOfModal: payload.type,
      page: payload.pageId,
    };
  },
  [SET_ACTIVE_PAGE_TYPE]: (state, { payload }) => {
    return {
      ...state,
      activePageType: payload,
    };
  },
  [SET_GRAPH_DATA]: (state, { payload }) => {
    return {
      ...state,
      graph: payload.graph,
    };
  },
  [SET_GRAPH_ID]: (state, { payload }) => {
    return {
      ...state,
      graphId: payload,
    };
  },
  [SET_GRAPH_EDIT_DATA]: (state, { payload }) => {
    return {
      ...state,
      graphEditData: payload,
    };
  },
  [SET_REPORT_FIELDS]: (state, { payload }) => {
    return {
      ...state,
      reportFields: payload,
    };
  },
  [SET_GRAPH_SERIES_LIST]: (state, { payload }) => {
    return {
      ...state,
      seriesList: payload,
    };
  },
  [SET_SECTION_ID]: (state, { payload }) => {
    return {
      ...state,
      sectionId: payload,
    };
  },
  [SET_REPORT_ID]: (state, { payload }) => {
    return {
      ...state,
      reportId: payload,
    };
  },
  [SET_SECTION_DATA]: (state, { payload }) => {
    return {
      ...state,
      sectionAction: payload.action,
      sectionData: payload.data,
      typeId: payload.typeId,
    };
  },
  [SET_PAGE_DATA]: (state, { payload }) => {
    return {
      ...state,
      pageAction: payload.action,
      pageData: payload.data,
      pageId: payload.pageId,
      pageTypeId: payload.typeId,
      couldDelete: payload.couldDelete,
    };
  },
  [SET_REPORT_PICTURES]: (state, { payload }) => {
    return {
      ...state,
      reportPictures: payload,
    };
  },
  [SET_MODIFIERS_DATA]: (state, { payload }) => {
    return {
      ...state,
      modifiersList: payload,
    };
  },
  [SET_MODIFIERS_FIELDS]: (state, { payload }) => {
    return {
      ...state,
      modifierFields: payload,
    };
  },
  [SET_MODIFIERS_GROUP_FIELDS]: (state, { payload }) => {
    return {
      ...state,
      modifierGroupFields: payload,
    };
  },
  [SET_GRAPH_GROUP_TYPE]: (state, { payload }) => {
    return {
      ...state,
      groupType: payload,
    };
  },
  [SET_MODIFIERS_LEGENDS]: (state, { payload }) => {
    return {
      ...state,
      legends: payload,
    };
  },
  [SET_GRAPH_TYPE]: (state, { payload }) => {
    return {
      ...state,
      graphType: payload,
    };
  },
});
