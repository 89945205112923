import { Typography } from '@material-ui/core';
import React from 'react';

import theme from './userTableHeader.module.scss';

const UserTableHeader = ({ headers }) => {
  return (
    <div className={theme.container}>
      <div className={theme.wrapper}>
        {headers.map((header, index) => {
          return (
            <div key={index} className={theme.header}>
              <Typography key={index}>{header}</Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserTableHeader;
