import {
  setupMapData,
  setupStackedHorizontalBar,
  tableDataTransform,
  transformDivisionData,
  transformPropertyDivisionPercent,
  transormDataPropertyPercent,
  updatePropertyIdByMap,
} from 'utils';
import { summaryPageMocked as MOCKED_DATA } from 'services/mocked';

export const SET_SUMMARY_PAGE_DATA = {
  pending: 'SET_SUMMARY_PAGE_DATA_PENDING',
  success: 'SET_SUMMARY_PAGE_DATA_SUCCESS',
  failure: 'SET_SUMMARY_PAGE_DATA_FAILURE',
};

export const setSummaryPageData = (
  databoxes,
  buildingsData,
  graphPropertyData,
  graphPropertyPercentData,
  graphDivisionPercentData,
  graphDivisionData,
  propertyNamesMap,
) => {
  const updatedBuildingsData = updatePropertyIdByMap(buildingsData, propertyNamesMap);
  const updatedGraphPropertyData = updatePropertyIdByMap(graphPropertyData, propertyNamesMap);
  const updatedGraphPropertyPercentData = updatePropertyIdByMap(graphPropertyPercentData, propertyNamesMap);

  const divisionData = setupStackedHorizontalBar(transformDivisionData(graphDivisionData), true);

  const graphPropertyPercentDataTransformed = transormDataPropertyPercent(updatedGraphPropertyPercentData);
  const barsPropertyPercentData = setupStackedHorizontalBar(graphPropertyPercentDataTransformed);

  const graphDivisionPercentDataTransformed = transformPropertyDivisionPercent(graphDivisionPercentData);
  const barsDivisionPercentData = setupStackedHorizontalBar(graphDivisionPercentDataTransformed);

  const barsPropertyData = setupStackedHorizontalBar(updatedGraphPropertyData, false, true);

  const payload = {
    tableRows: tableDataTransform(updatedBuildingsData),
    databoxes,
    buildingsConfig: setupMapData(updatedBuildingsData, MOCKED_DATA.mapLabels),
    graphDivision: divisionData.graphs,
    graphDivisionPeaks: divisionData.peaks,
    graphPropertyPercent: barsPropertyPercentData.graphs,
    graphPropertyPercentPeaks: barsPropertyPercentData.peaks,
    graphDivisionPercent: barsDivisionPercentData.graphs,
    graphDivisionPercentPeaks: barsDivisionPercentData.peaks,
    graphProperty: barsPropertyData.graphs,
    graphPropertyPeaks: barsPropertyData.peaks,
    propertyGraphLegendData: Object.keys(barsPropertyData.legend).map((legendItemKey) => ({
      label: legendItemKey,
      color: barsPropertyData.legend[legendItemKey],
    })),
  };

  return {
    type: SET_SUMMARY_PAGE_DATA.success,
    payload,
  };
};
