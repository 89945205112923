/* eslint-disable camelcase */

const BenchUtilization = ({
  databoxes: { percent_max_utilization },
  textData: { percent_peak_utilization_non_agile, percent_peak_utilization_agile },
}) =>
  `If you look at overall utilisation instead (including signs of life), the measured peak was
   ${percent_max_utilization}% compared to our pre agile benchmark utilisation of
   ${percent_peak_utilization_non_agile}% and our post agile benchmark of ${percent_peak_utilization_agile}%.`;

export default BenchUtilization;
