import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { URLS } from 'pages/constants';

import { PageHeader } from 'modules/shared/components';
import { PdfPageSelectorBar } from 'modules/assetLook/components';

import PdfReportsNew from '../PdfReportsNew';
import PdfReportsHistory from '../PdfReportsHistory';

const PdfReportsPage = () => (
  <Grid container spacing={4}>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title="PDF Reports" />
        </Grid>
        <Grid item xs={12}>
          <PdfPageSelectorBar />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Switch>
        <Route exact path={URLS.assetLook.pdfReportsNew} component={PdfReportsNew} />
        <Route exact path={URLS.assetLook.pdfReportsHistory} component={PdfReportsHistory} />

        <Redirect to={URLS.assetLook.pdfReportsNew} />
      </Switch>
    </Grid>
  </Grid>
);

export default PdfReportsPage;
