import { isEmpty } from 'lodash';

const DesksLeastBusyOccupancy = ({ graphData }) => {
  if (isEmpty(graphData)) return null;
  const leastBusy = graphData.reduce((min, current) => {
    if (min.percent_max_occupancy > current.percent_max_occupancy) return current;

    return min;
  });

  return `The least busy occupancy occurred on ${leastBusy.day_of_week}
     which was measured as ${leastBusy.percent_max_occupancy}%.`;
};

export default DesksLeastBusyOccupancy;
