import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { URLS } from 'pages/constants';
import { PageSelectorItem } from 'modules/shared/components';

const OrdersPageSelectorBar = () => {
  const matchBasket = useRouteMatch(URLS.assetLook.ordersBasket);
  const matchInProgress = useRouteMatch(URLS.assetLook.ordersInProgress);
  const matchHistory = useRouteMatch(URLS.assetLook.ordersHistory);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <PageSelectorItem link={URLS.assetLook.ordersBasket} label="Basket" active={Boolean(matchBasket)} />
      </Grid>
      <Grid item>
        <PageSelectorItem
          link={URLS.assetLook.ordersInProgress}
          label="In progress"
          active={Boolean(matchInProgress)}
        />
      </Grid>
      <Grid item>
        <PageSelectorItem link={URLS.assetLook.ordersHistory} label="History" active={Boolean(matchHistory)} />
      </Grid>
    </Grid>
  );
};

export default OrdersPageSelectorBar;
