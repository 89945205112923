import React from 'react';
import cn from 'classnames';
import { cloneDeep } from 'lodash';
import { Grid } from '@material-ui/core';

import { toPieChartData } from 'modules/spaceLook/utils';
import { reportPageTypes } from 'modules/spaceLook/constants';
import { setStackedGraphRowsData, splitGraphs } from 'utils';
import { useHasScroll } from 'modules/shared/hooks';
import {
  PageHeader,
  UsagePie,
  ReportText,
  ReportDataCards,
  ReportFilter,
  HourlyGraph,
  FilterHandler,
} from 'modules/spaceLook/components';

import config from './config';
import theme from './DesksUsageOverTime.module.scss';

const DesksUsageOverTime = ({ isLoading, pdfMode, databoxes, dailyHourlyGraphData, pieData }) => {
  const graphRows = setStackedGraphRowsData(dailyHourlyGraphData, 100);
  const graphs = pdfMode ? splitGraphs(graphRows) : [graphRows];
  const scroll = useHasScroll();
  const graphsData = graphs.map((graph) => {
    return Object.keys(graph).map((rowName) => {
      const dateObj = graphRows[rowName];
      return {
        title: rowName,
        labels: dateObj.labels,
        datasets: [
          {
            label: 'Occupancy',
            data: dateObj.percent_occupancy,
            stack: 'standing costs',
            backgroundColor: '#58BFC7',
          },
          {
            label: 'Average Signs of Life',
            data: dateObj.percent_signs_of_life,
            stack: 'standing costs',
            backgroundColor: '#F6A000',
          },
          {
            label: 'Rest',
            data: dateObj.rest,
            stack: 'standing costs',
            backgroundColor: '#F1F1F1',
          },
        ],
      };
    });
  });

  return graphsData.map((graphData, idx) => (
    <div ref={scroll.ref} key={idx} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Usage Over Time" text="Desk Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.desks}>
              <ReportFilter pageType={reportPageTypes.desks} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={6}>
          <div className={theme.cardsContainer}>
            <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.desks} cropped />
          </div>
        </Grid>

        <Grid item xs={6}>
          <UsagePie pieData={toPieChartData(pieData)} label="Observed Activities" inPercents />
        </Grid>

        <Grid item xs={12}>
          <HourlyGraph
            linesData={databoxes}
            graphData={graphData}
            linesConfig={config}
            legendConfig={config}
            pdfModeLegendItemSize={3}
          />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            databoxes={databoxes}
            pieData={pieData}
            graphData={cloneDeep(dailyHourlyGraphData)}
            getParagraphs={[
              'desksOccupancy',
              'desksCoreHoursOccupation',
              'desksUtilization',
              'desksPeakDays',
              'desksSignsOfLife',
              'mostCommonActivities',
            ]}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default DesksUsageOverTime;
