import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getUser } from 'modules/auth/selectors';

import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { GraphHeader as EmptyGraphHeader } from 'modules/workLook/components';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';

import GraphBody from './components/GraphBody';
import GraphHeader from './components/GraphHeader';
import './timeGraph.scss';
import toGraphTimeData from './utils/toTimeGraphData';
import config from './config';
import PDFGraphHeader from './components/PDFGraphHeader';

const TimeGraph = ({
  data,
  dataGroupedByDays,
  isLoading,
  onScaleChange,
  returnPercentageSwitcher,
  onSwitcherChange,
  scale,
  valueMethod,
  niceMax,
  typeOfGroup,
  scaleValues,
  isPublished,
  title,
  keys,
  peakValues,
  onValueChange,
  isPdf,
  position,
  totalAmountPerDay,
  subtitle,
  type,
  graphId,
  displayHeaderOptions,
  isModal,
  showTitle,
}) => {
  const { user } = useSelector(getUser);
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  const linesData = { peakOccupancy: 0, avgOccupancy: 0 };
  const graphData = toGraphTimeData(
    {
      sourceData: dataGroupedByDays,
      valueType: 'Maximum',
      scale,
      returnPercentageSwitcher,
      niceMax,
      keys,
      peakValues,
      valueMethod,
      totalAmountPerDay,
    },
    false,
  );
  const checkPosition = ['7', '8', '9'].includes(position);
  const checkDashboardPosition = ['10', '11'].includes(position);
  const getGraphHeight = isPdf
    ? checkPosition
      ? '660px'
      : '340px'
    : checkPosition
    ? '720px'
    : checkDashboardPosition
    ? '420px'
    : '360px';

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && isEmpty(data)}>
        <Paper className="container" elevation={2}>
          <EmptyGraphHeader
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={subtitle}
            isPdf={isPdf}
            type={type}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Grid>
          <Choose>
            <When condition={!isPdf}>
              <GraphHeader
                title={title}
                isPublished={isPublished}
                showTitle={showTitle}
                scale={scale}
                peakValues={peakValues}
                valueMethod={valueMethod}
                onValueChange={onValueChange}
                onScaleChange={onScaleChange}
                onSwitcherChange={onSwitcherChange}
                graphId={graphId}
                isPdf={isPdf}
                displayHeaderOptions={displayHeaderOptions}
                userRole={user?.role}
                onClick={onEditClick}
                onModifierClick={onModifierClick}
              />
            </When>
            <When condition={isPdf}>
              <PDFGraphHeader
                scale={scale}
                valueMethod={valueMethod}
                returnPercentageSwitcher={returnPercentageSwitcher}
              />
            </When>
          </Choose>
          <Paper elevation={2} className="timeGraphContainer" style={{ maxHeight: getGraphHeight }}>
            <GraphBody
              graphData={graphData}
              linesData={linesData}
              config={config}
              scale={scale}
              scaleValues={scaleValues}
              isSwitcherOn={returnPercentageSwitcher}
              isPdf={isPdf}
              height={getGraphHeight}
            />
          </Paper>
        </Grid>
      </Otherwise>
    </Choose>
  );
};

export default TimeGraph;
