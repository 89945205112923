import React from 'react';
import PropTypes from 'prop-types';

import './FiltersInBlockButton.scss';

import filters from '../../../assets/images/icons/black-filter.svg';

const FiltersInBlockButton = ({ icon, text, onClick }) => {
  return (
    <div className="FiltersInBlockButton">
      <img className="icon" src={icon} alt="icon" />
      <button onClick={onClick} type="button" className="filters-in-block-button">
        {text}
      </button>
    </div>
  );
};

FiltersInBlockButton.defaultProps = {
  text: 'Filter by region, building name, directorare',
  icon: filters,
};

FiltersInBlockButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
};

export default FiltersInBlockButton;
