import React from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';
import { PageHeader, TextEditor } from 'modules/shared/components';
import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';
import { ReportDataCards, LiveCounter, FilterList, UsageOverTimeGraph } from 'modules/liveLook/components';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import IFrameModal from 'modules/liveLook/components/iFrame';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import config from './config';

const CombinedTeamZoneOccupancy = ({
  daysDiffernce,
  scale,
  onScaleChange,
  value,
  onValueChange,
  niceMax,
  linesData,
  databoxesSet,
  data,
  databoxes,
  editableTextReport,
  saveGraphsText,
  graphData,
  pdfMode,
  onSwitcherChange,
  returnPercentageSwitcher,
  customHeader,
  scaleValues,
  textVariables,
  archive,
}) => {
  return (
    <div className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader title="Occupancy" text="Desk Utilisation" />
            </Grid>
            <Grid item xs={12}>
              <FilterList pageType="COMBINED OCCUPANCY" subtypes />
            </Grid>
            <Grid item xs={12}>
              <ReportDataCards databoxes={databoxes} pageType={pageTypes.combinedOccupancy} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
            <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
            <Grid item>
              <IFrameModal />
            </Grid>
          </Grid>
          <Grid>
            <AddToPDFCard
              type="Combined Occupancy"
              sectionType="Occupancy"
              category={pdfCases.combinedOccupancy}
              sectionOrder={sectionsOrder.second}
              typeOrder={sectionItemOrder.fourth}
              chartName={liveLookPagesNames.combinedTeamZoneOccupancy}
              activeFilters={filtersDataContainer('COMBINED OCCUPANCY', false).initActiveFilters}
              data={{
                value,
                graphData,
                linesData,
                scaleValues,
                databoxes,
                scale,
                data,
                customHeader,
                databoxesSet,
                returnPercentageSwitcher,
                editableTextReport,
                niceMax,
                pdfMode,
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <UsageOverTimeGraph
            daysDiffernce={daysDiffernce}
            isSwitcherOn={returnPercentageSwitcher}
            title="Combined Team Zone Occupancy"
            label="Integers"
            scale={scale}
            onScaleChange={onScaleChange}
            onSwitcherChange={onSwitcherChange}
            value={value}
            onValueChange={onValueChange}
            graphData={graphData}
            config={config}
            linesData={linesData}
            scaleValues={scaleValues}
            customHeader
            isCombinedOccupancy
          />
        </Grid>

        <Grid item xs={12}>
          <TextEditor
            chartName={liveLookPagesNames.combinedTeamZoneOccupancy}
            data={databoxesToEditorData(databoxesSet, textVariables)}
            // eslint-disable-next-line no-shadow
            onSave={async (data) => {
              saveGraphsText(JSON.stringify(data));
            }}
            text={editableTextReport}
            graphType={GRAPH_TYPES.OVERTIME}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CombinedTeamZoneOccupancy;
