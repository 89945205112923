import { reportPageTypes } from 'modules/spaceLook/constants';
import { pageTypes } from 'modules/liveLook/constants';

const setValueParams = (key, label, isPercent = false) => ({ key, label, isPercent });

const config = [
  // Meetings & Breakouts
  {
    title: { [pageTypes.meetings]: 'Rooms' },
    firstValue: setValueParams('rooms', { [reportPageTypes.meetings]: 'rooms' }),
    dateValue: 'max_rooms_date',
    color: '#c4043f',
  },
  {
    title: { [pageTypes.meetings]: 'Max Rooms Used' },
    firstValue: setValueParams('percentage_max_rooms_usage', null, true),
    secondValue: setValueParams('max_rooms_usage', { [pageTypes.meetings]: 'rooms' }),
    dateValue: 'max_rooms_date',
    color: '#0d6f85',
  },
  {
    title: { [pageTypes.meetings]: 'Avg Rooms Used' },
    firstValue: setValueParams('percentage_avg_rooms_usage', null, true),
    secondValue: setValueParams('avg_rooms_usage', { [pageTypes.meetings]: 'rooms' }),
    dateValue: 'max_rooms_date',
    color: '#00aad2',
  },
  {
    title: { [pageTypes.meetings]: 'Seats' },
    firstValue: setValueParams('seats', { [pageTypes.meetings]: 'seats' }),
    dateValue: 'max_seats_date',
    color: '#f6a000',
  },
  {
    title: { [pageTypes.meetings]: 'Max Seats Used' },
    firstValue: setValueParams('percentage_max_seats_usage', null, true),
    secondValue: setValueParams('max_seats_usage', { [pageTypes.meetings]: 'seats' }),
    dateValue: 'max_seats_date',
    color: '#a2559d',
  },
  {
    title: { [pageTypes.meetings]: 'Avg Seats Used' },
    firstValue: setValueParams('percentage_avg_seats_usage', null, true),
    secondValue: setValueParams('avg_seats_usage', { [pageTypes.meetings]: 'seats' }),
    dateValue: 'max_seats_date',
    color: '#c577c0',
  },

  // Occupancy

  {
    title: { [pageTypes.occupancy]: 'Allocated Staff' },
    firstValue: setValueParams('capacity', { [pageTypes.occupancy]: 'people' }),
    // secondValue: setValueParams('capacity_percentage', {[pageTypes.occupancy]: ''}),
    dateValue: 'max_occupancy_date',
    color: '#c4043f',
  },
  {
    title: { [pageTypes.occupancy]: 'Peak Occupancy' },
    firstValue: setValueParams('max_occupancy_percentage', null, true),
    secondValue: setValueParams('max_occupancy', { [pageTypes.occupancy]: 'people' }),
    dateValue: 'max_occupancy_date',
    color: '#0d6f85',
  },
  {
    title: { [pageTypes.occupancy]: 'Last date occurred' },
    firstValue: setValueParams('max_occupancy_date', { [pageTypes.occupancy]: '' }),
    // secondValue: setValueParams('max_occupancy_percentage', null, true),
    dateValue: 'max_occupancy_date',
    color: '#00aad2',
  },
  {
    title: { [pageTypes.occupancy]: 'Avg Occupancy' },
    firstValue: setValueParams('avg_occupancy_percentage', null, true),
    secondValue: setValueParams('avg_occupancy', { [pageTypes.occupancy]: 'people' }),
    dateValue: 'max_occupancy_date',
    color: '#f6a000',
  },
  {
    title: { [pageTypes.occupancy]: 'Nominal Capacity' },
    firstValue: setValueParams('nominal_capacity', { [pageTypes.occupancy]: 'people' }),
    dateValue: 'max_occupancy_date',
    // secondValue: ,
    color: '#a2559d',
  },

  // Combined Occupancy
  {
    title: { [pageTypes.combinedOccupancy]: 'Total Seats' },
    firstValue: setValueParams('combined_seats', { [pageTypes.combinedOccupancy]: 'seats' }),
    dateValue: 'max_combined_occupancy_date',
    color: '#c4043f',
  },
  {
    title: { [pageTypes.combinedOccupancy]: 'Peak Occupancy' },
    firstValue: setValueParams('max_combined_occupancy', { [pageTypes.combinedOccupancy]: 'seats' }),
    secondValue: setValueParams('percentage_max_combined_occupancy', null, true),
    dateValue: 'max_combined_occupancy_date',
    color: '#0d6f85',
  },
  {
    title: { [pageTypes.combinedOccupancy]: 'Last date occurred' },
    firstValue: setValueParams('max_combined_occupancy_date', { [pageTypes.occupancy]: '' }),
    dateValue: 'max_combined_occupancy_date',
    color: '#00aad2',
  },
  {
    title: { [pageTypes.combinedOccupancy]: 'Avg Occupancy' },
    firstValue: setValueParams('avg_combined_occupancy', { [pageTypes.combinedOccupancy]: 'seats' }),
    secondValue: setValueParams('percentage_avg_combined_occupancy', null, true),
    dateValue: 'max_combined_occupancy_date',
    color: '#f6a000',
  },

  // Desks

  {
    title: { [pageTypes.desks]: 'Desks' },
    firstValue: setValueParams('desks', { [reportPageTypes.desks]: 'desks' }),
    dateValue: 'max_desk_occupancy_date',
    color: '#c4043f',
  },
  {
    title: { [pageTypes.desks]: 'Max Desks Used' },
    firstValue: setValueParams('percentage_max_desk_occupancy', null, true),
    secondValue: setValueParams('max_desk_occupancy', { [pageTypes.desks]: 'desks' }),
    dateValue: 'max_desk_occupancy_date',
    color: '#0d6f85',
  },
  {
    title: { [pageTypes.desks]: 'Avg Desks Used' },
    firstValue: setValueParams('percentage_avg_desk_occupancy', null, true),
    secondValue: setValueParams('avg_desk_occupancy', { [pageTypes.desks]: 'desks' }),
    dateValue: 'max_desk_occupancy_date',
    color: '#00aad2',
  },
  {
    title: { [pageTypes.desks]: 'Last date occurred' },
    firstValue: setValueParams('max_desk_occupancy_date', { [pageTypes.desks]: '' }),
    dateValue: 'max_desk_occupancy_date',
    color: '#f6a000',
  },

  // SUMMARY FLOOR PLAN
  {
    title: { [pageTypes.summary]: 'Peak Occupancy' },
    firstValue: setValueParams('max_occupancy', { [pageTypes.summary]: 'people' }),
    secondValue: setValueParams('max_occupancy_percentage', null, true),
    dateValue: 'max_occupancy_date',
    color: '#c4043f',
  },
  {
    title: { [pageTypes.summary]: 'Peak In Use' },
    firstValue: setValueParams('max_desk_occupancy', { [pageTypes.summary]: 'desks' }),
    secondValue: setValueParams('percentage_max_desk_occupancy', null, true),
    dateValue: 'max_desk_occupancy_date',
    color: '#0d6f85',
  },
  {
    title: { [pageTypes.summary]: 'Peak In Use Rooms' },
    firstValue: setValueParams('max_rooms_usage', { [pageTypes.summary]: 'rooms' }),
    secondValue: setValueParams('percentage_max_rooms_usage', null, true),
    dateValue: 'max_rooms_date',
    color: '#00aad2',
  },
  {
    title: { [pageTypes.summary]: 'Peak In Use Spaces' },
    firstValue: setValueParams('max_rooms_usage_breakout', { [pageTypes.summary]: 'spaces' }),
    secondValue: setValueParams('percentage_max_rooms_usage_breakout', null, true),
    dateValue: 'max_rooms_date_breakout',
    color: '#f6a000',
  },

  // KEYFINDINGS
  {
    title: { [pageTypes.keyFindings]: 'Peak Occupancy' },
    firstValue: setValueParams('max_occupancy', { [pageTypes.keyFindings]: 'people' }),
    secondValue: setValueParams('max_occupancy_percentage', null, true),
    dateValue: 'max_occupancy_date',
    color: '#c4043f',
  },
  {
    title: { [pageTypes.keyFindings]: 'Peak In Use Desks' },
    firstValue: setValueParams('max_desk_occupancy', { [pageTypes.keyFindings]: 'desks' }),
    secondValue: setValueParams('percentage_max_desk_occupancy', null, true),
    dateValue: 'max_desk_occupancy_date',
    color: '#0d6f85',
  },
  {
    title: { [pageTypes.keyFindings]: 'Peak In Use Amber' },
    firstValue: setValueParams('max_desk_usage', { [pageTypes.keyFindings]: 'desks' }),
    secondValue: setValueParams('percentage_max_desk_usage', null, true),
    dateValue: 'max_desk_usage_date',
    color: '#00aad2',
  },
  {
    title: { [pageTypes.keyFindings]: 'Peak In Use Meet' },
    firstValue: setValueParams('max_rooms_usage', { [pageTypes.keyFindings]: 'rooms' }),
    secondValue: setValueParams('percentage_max_rooms_usage', null, true),
    dateValue: 'max_rooms_date',
    color: '#f6a000',
  },
  {
    title: { [pageTypes.keyFindings]: 'Peak In Use Breakout' },
    firstValue: setValueParams('max_rooms_usage_breakout', { [pageTypes.keyFindings]: 'spaces' }),
    secondValue: setValueParams('percentage_max_rooms_usage_breakout', null, true),
    dateValue: 'max_rooms_date_breakout',
    color: '#a2559d',
  },
  {
    title: { [pageTypes.keyFindings]: 'Nominal Capacity' },
    firstValue: setValueParams('nominal_capacity', { [pageTypes.keyFindings]: 'people' }),
    dateValue: 'max_occupancy_date',
    color: '#c577c0',
  },
  // {
  //   title: {[pageTypes.desks]: 'Seats'},
  //   firstValue: setValueParams('seats', {[pageTypes.meetings]: 'seats'}),
  //   color: '#f6a000',
  // },
  // {
  //   title: {[pageTypes.desks]: 'Max Seats Used'},
  //   firstValue: setValueParams('percentage_max_seats_usage', null, true),
  //   secondValue: setValueParams('max_seats_usage', {[pageTypes.meetings]: 'seats'}),
  //   color: '#a2559d',
  // },
  // {
  //   title: {[pageTypes.desks]: 'Avg Seats Used'},
  //   firstValue: setValueParams('percentage_avg_seats_usage', null, true),
  //   secondValue: setValueParams('avg_seats_usage', {[pageTypes.meetings]: 'seats'}),
  //   color: '#c577c0',
  // },
];

export default config;
