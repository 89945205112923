import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/shared/components';
import { ReportDataCards, VerticalBarGraph } from 'modules/liveLook/components';
import { isEmpty } from 'lodash';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';

import { GRAPH_TYPES, pageTypes } from 'modules/liveLook/constants';
import styles from '../../LLPrintablePdfPage.module.scss';
import renderFilters from '../../utils/activeFiltersPdf';
import config from './config';

const MeetsSpaceComparison = ({
  data,
  databoxes,
  scaleValues,
  editableTextReport,
  returnPercentageSwitcher,
  activeFilters,
}) => {
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));

  return (
    <Grid style={{ paddingTop: '20px', paddingLeft: '30px' }} container spacing={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader pdfMode title="Meeting Spaces" />
        </Grid>
      </Grid>
      {!shouldRenderFilters && renderFilters(activeFilters)}
      <div className={styles.blockContainer}>
        <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.meetings} />
      </div>
      <Grid style={{ paddingTop: '7px' }} xs={12}>
        <VerticalBarGraph
          title="Peak and Average by Type"
          data={data}
          config={config}
          onSwitcherChange={() => {}}
          scaleValues={scaleValues}
          label="Integers"
          switcher={returnPercentageSwitcher}
          pdfCustomHeader
          returnPercentageSwitcher={returnPercentageSwitcher}
          isLiveLook
        />
      </Grid>
      <PDFTextEditorContainer
        hasFilters={shouldRenderFilters}
        graphType={GRAPH_TYPES.VERTICAL}
        data={[]}
        onSave={() => {}}
        text={editableTextReport}
      />
    </Grid>
  );
};

export default MeetsSpaceComparison;
