import isLogged from './isLogged';
import redirect from './redirect';
import { REDIRECT_URLS } from '../_constants';

const { login } = REDIRECT_URLS;

const redirectToLogin = (history) => {
  if (!isLogged()) {
    localStorage.clear();
    redirect(login, history);
  }
};

export default redirectToLogin;
