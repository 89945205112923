const LANDING_START_PAGE = {
  asset: 'assetLook',
  live: 'liveLook',
  work: 'workLook',
  people: 'workLook',
  space: 'spaceLook',
  location: 'locationLook',
};

export default LANDING_START_PAGE;
