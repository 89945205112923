import React from 'react';
import { Field } from 'formik';

import './FormikInput.scss';

const FormikInput = ({ name, placeholder, type }) => {
  return (
    <div className="FormikInput">
      <Field className="formik-input" name={name} placeholder={placeholder} type={type} />
    </div>
  );
};

export default FormikInput;
