import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_COMBINED_TEAM_ZONE_OCCUPANCY = createAsyncAction(createAction('FETCH_COMBINED_TEAM_ZONE_OCCUPANCY'));
export const FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK = createAsyncAction(
  createAction('FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK'),
);

const fetchCombinedTeamZoneOccupancy = async (
  dispatch,
  isCanceled,
  { companyId, buildingId, dateAfter, dateBefore, scaleOption, workingHours, query, week, archive },
) => {
  try {
    // dispatch(week ? FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK.pending() : FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.pending());

    const url = ENDPOINTS.liveLook.combinedTeamZoneOccupancy
      .replace(':companyId', companyId)
      .replace(':buildingId/', buildingId ? `${buildingId}/` : '')
      .concat(`?date_after=${dateAfter}`)
      .concat(`&date_before=${dateBefore}`)
      .concat(workingHours ? `&work_hours=True` : '')
      .concat(workingHours ? `&exclude_weekend=True` : '')
      .concat(scaleOption ? `&date_scale=${scaleOption.toLowerCase()}` : '')
      .concat(query ? `&${query}` : '')
      .concat(archive ? `&archive_id=${archive}` : '');

    const graphData = await API.get(url);
    if (isCanceled) return;
    dispatch(
      week
        ? FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK.success(graphData.data)
        : FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.success(graphData.data),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    // if (isCanceled) return;
    // dispatch(week ? FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK.failure() : FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.failure());
  }
  // API.get(url)
  //   .then(({ data }) => {
  //     console.log('data', { data });

  //     if (isCanceled) return;
  //     dispatch(
  //       week ? FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK.success(data) : FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.success(data),
  //     );
  //   })
  //   .catch(() => {
  //     if (isCanceled) return;
  //     dispatch(week ? FETCH_COMBINED_TEAM_ZONE_OCCUPANCY_WEEK.failure() : FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.failure());
  //   });
};

export default fetchCombinedTeamZoneOccupancy;
