import React from 'react';
import PropTypes from 'prop-types';

import './AccordionSidebarListHeader.scss';

import arrow from '../../../assets/images/icons/to-top-grey-arrow.svg';
import defaultIcon from '../../../assets/images/icons/heart.svg';

const AccordionSidebarListHeader = ({ label, iconUrl }) => {
  return (
    <div className="AccordionSidebarListHeader">
      <img className="icon" src={iconUrl} alt="icon" />
      <h4 className="accordion-sidebar-list-header">{label}</h4>
      <img className="arrow-icon" src={arrow} alt="arrow-icon" />
    </div>
  );
};

AccordionSidebarListHeader.defaultProps = {
  label: 'Executive Summary',
  iconUrl: defaultIcon,
};

AccordionSidebarListHeader.propTypes = {
  label: PropTypes.string,
  iconUrl: PropTypes.string,
};

export default AccordionSidebarListHeader;
