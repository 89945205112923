/* eslint-disable prefer-template */
import { isEmpty } from 'lodash';

const escapeRegExpMatch = (s) => {
  // eslint-disable-next-line no-useless-escape
  return s?.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

const isExactMatch = (str, match) => {
  return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
};

const getTopList = (data, legends, count) => {
  return legends?.map((legend) => data?.filter((object) => isExactMatch(object?.name, legend)).slice(0, count));
};

const getTopDifference = (data, count) => {
  return data?.filter((object) => isExactMatch(object?.name, 'result_field')).slice(0, count);
};

const getBottomList = (data, legends, count) => {
  return legends?.map((legend) =>
    data
      ?.filter((object) => isExactMatch(object?.name, legend))
      .sort((a, b) => a?.data - b?.data)
      .slice(0, count),
  );
};

const getBottomDifference = (data, count) => {
  return data
    ?.filter((object) => isExactMatch(object?.name, 'result_field'))
    .sort((a, b) => a?.data - b?.data)
    .slice(0, count);
};

const getDataString = (listData, legend, titleName) => {
  if (isEmpty(listData)) return '';
  return listData
    .map((item) => `- ${item.name.replace(new RegExp('\\b' + legend + '\\b'), '')}: ${item.data}\r\n`)
    .toString()
    .replace(/,/g, '');
};

const getVariablesText = (dataList, legends, title, type) => {
  return dataList?.map((array, index) => {
    if (array[0]?.name.includes(legends?.[index]))
      return {
        name: `${title} - ${legends?.[index]} – ${type}`,
        data: getDataString(array, legends?.[index], title),
      };
    return {
      name: `Top 5`,
      data: getDataString(array),
    };
  });
};

const getTopFive = (data, legends, title) => {
  if (isEmpty(data)) return [];
  const deletedTitle = data.map(({ name, data }) => {
    return { name: name.replace(new RegExp(' - \\b' + title + '\\b'), ''), data };
  });

  const sortedData = [...deletedTitle]?.sort((a, b) => b?.data - a?.data);

  // Get Top List of fields
  const topLists = getTopList(sortedData, legends, 5);
  const topOneLists = getTopList(sortedData, legends, 1);

  // Get Top Difference
  const topDifference = getTopDifference(sortedData, 5);
  const topOneDifference = getTopDifference(sortedData, 1);

  // Get Bootom List of fields
  const bottomLists = getBottomList(sortedData, legends, 5);
  const bottomOneLists = getBottomList(sortedData, legends, 1);

  // Get Bottom Difference
  const bottomDifference = getBottomDifference(sortedData, 5);
  const bottomOneDifference = getBottomDifference(sortedData, 1);

  // Generate text with top/bottom/difference variables
  const getTopFives = getVariablesText(topLists, legends, title, 'Top 5');
  const getTopOne = getVariablesText(topOneLists, legends, title, 'Top 1');

  const getBottomFives = getVariablesText(bottomLists, legends, title, 'Bottom 5');
  const getBottomOne = getVariablesText(bottomOneLists, legends, title, 'Bottom 1');

  const getDifferenceFives = [
    {
      name: `${title} Difference – Top 5`,
      data: getDataString(topDifference, 'result_field', title),
    },
    {
      name: `${title} Difference – Bottom 5`,
      data: getDataString(bottomDifference, 'result_field', title),
    },
    {
      name: `${title} Difference – Top 1`,
      data: getDataString(topOneDifference, 'result_field', title),
    },
    {
      name: `${title} Difference – Bottom 1`,
      data: getDataString(bottomOneDifference, 'result_field', title),
    },
  ];

  const topFive = [...getTopFives, ...getBottomFives, ...getTopOne, ...getBottomOne];
  const newArray = topFive.concat(getDifferenceFives);
  const allGraphVariables = data.concat(newArray);

  return allGraphVariables;
};

export default getTopFive;
