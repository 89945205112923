/* eslint-disable camelcase */
import { reportPageTypes } from 'modules/spaceLook/constants';

const MeetsAndBreakAvgOccupancy = ({
  textData: { meet_seat_avg_when_in_use, meet_capacity_avg, avg_meet_size_in_use, avg_room_size },
  pageType,
}) => {
  const isMeets = pageType === reportPageTypes.meetings;

  return `${isMeets ? 'The average meeting size recorded' : 'The group average size using the breakout spaces'} was
     ${isMeets ? meet_seat_avg_when_in_use : avg_meet_size_in_use} people compared to the average
     ${isMeets ? 'meeting room size' : 'space capacity'} of ${isMeets ? meet_capacity_avg : avg_room_size}.`;
};

export default MeetsAndBreakAvgOccupancy;
