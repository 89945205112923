import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader, TextEditor } from 'modules/shared/components';
import { VerticalBarGraph, ReportDataCards, FilterList, LiveCounter } from 'modules/liveLook/components';
import WarningDialog from 'modules/shared/components/WarningDialog';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import IFrameModal from 'modules/liveLook/components/iFrame';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import config from './config';
import { MAX_SELECTED_WEEKS } from '../constants/liveLook.constants';

const DoorsDaily = ({
  data,
  scaleValues,
  databoxes,
  onSwitcherChange,
  editableTextReport,
  saveGraphsText,
  databoxesSet,
  secondSwitcher,
  onWeekSwitcherChange,
  returnPercentageSwitcher,
  returnWeekSwitcher,
  archive,
}) => {
  const { labels } = data;
  const [warningDialog, setWarningDialog] = useState(false);

  const openWarningDialog = () => {
    setWarningDialog(true);
  };

  const showWarning = labels?.length > MAX_SELECTED_WEEKS && returnWeekSwitcher;

  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Occupancy" />
          </Grid>
          <Grid item xs={12}>
            <FilterList doorSensorData pageType="OCCUPANCY" subtypes={false} />
          </Grid>
          <Grid item xs={12}>
            <ReportDataCards databoxes={databoxes} pageType={pageTypes.occupancy} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
          <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>
        <Grid>
          <AddToPDFCard
            openWarningDialog={openWarningDialog}
            showWarning={showWarning}
            type="Daily Comparisons"
            sectionType="Occupancy"
            category={pdfCases.occupancyDaily}
            sectionOrder={sectionsOrder.second}
            typeOrder={sectionItemOrder.second}
            chartName={liveLookPagesNames.doorsDaily}
            activeFilters={filtersDataContainer('OCCUPANCY', false).initActiveFilters}
            data={{
              data,
              scaleValues,
              databoxes,
              editableTextReport,
              returnPercentageSwitcher,
              secondSwitcher,
              returnWeekSwitcher,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <VerticalBarGraph
          customHeader
          returnPercentageSwitcher={returnPercentageSwitcher}
          secondSwitcher={secondSwitcher}
          onWeekSwitcherChange={onWeekSwitcherChange}
          onSwitcherChange={onSwitcherChange}
          title={returnWeekSwitcher ? 'Weekly Comparisons' : 'Daily Comparisons'}
          data={data}
          scaleValues={scaleValues}
          config={config}
          isWeekGraph={returnWeekSwitcher}
          isDailyComparisons
        />
      </Grid>

      <Grid item xs={12}>
        <TextEditor
          chartName={liveLookPagesNames.doorsDaily}
          data={databoxesToEditorData(databoxesSet)}
          // eslint-disable-next-line no-shadow
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
          graphType={GRAPH_TYPES.VERTICAL}
        />
      </Grid>
      <If condition={openWarningDialog}>
        <WarningDialog
          open={warningDialog}
          _onClose={() => setWarningDialog(false)}
          title="Warning"
          text="The maximum number of weeks for the PDF reports is 9"
        />
      </If>
    </Grid>
  );
};

export default DoorsDaily;
