const updatePropertyIdToName = (data, propertyNamesMap) => {
  const {
    textblock_data: { peak_avg_seat_usage_building_or_floor: peakAvg, peak_max_seat_usage_building_or_floor: peakMax },
  } = data;

  const peakAvgPropertyInList = propertyNamesMap.find((property) => property.property_id === peakAvg);
  const peakMaxPropertyInList = propertyNamesMap.find((property) => property.property_id === peakMax);

  return {
    ...data,
    textblock_data: {
      ...data.textblock_data,
      peak_avg_seat_usage_building_or_floor: (peakAvgPropertyInList && peakAvgPropertyInList.property_name) || peakAvg,
      peak_max_seat_usage_building_or_floor: (peakMaxPropertyInList && peakMaxPropertyInList.property_name) || peakMax,
    },
  };
};

export default updatePropertyIdToName;
