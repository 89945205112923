import React from 'react';

import DomainIcon from '@material-ui/icons/Domain';

import { ReportTitlePage, ReportSubTitlePage } from 'pages/_privatePages/shared';

import ContentPage from './components/ContentPage';

import theme from './PrintablePdfPage.module.scss';

const PrintablePdfPage = ({ basket, companyName, reportName, image, textColor, parentCompanyLogo, companyLogo }) => (
  <div className={theme.container}>
    <div className={theme.servicePage}>
      <ReportTitlePage title={companyName} subTitle={reportName} image={image} textColor={textColor} />
    </div>
    <For each="building" of={basket.buildings}>
      <For each="category" of={building.categories} index="i">
        <React.Fragment key={i}>
          <div className={theme.servicePage}>
            <ReportSubTitlePage
              firstLogo={parentCompanyLogo}
              secondLogo={companyLogo}
              title={category.category__name}
              bottomText={building.building__name}
              BottomTextIcon={DomainIcon}
            />
          </div>
          <ContentPage buildingId={building.building_id} categoryId={category.category_id} />
        </React.Fragment>
      </For>
    </For>
  </div>
);

export default PrintablePdfPage;
