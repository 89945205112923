import { createReducer } from '@reduxjs/toolkit';
import { filter } from 'lodash';

import { requestStatuses } from 'modules/shared/constants';

import {
  FETCH_BENCHMARKING_APPLIED_FILTERS,
  FETCH_BENCHMARKING_OPTIONS,
  SET_BENCHMARKING_FILTER_OPTION,
  APPLY_BENCHMARKING_FILTERS,
  RESET_BENCHMARKING_FILTERS,
  RESET_REPORT_DATA,
} from 'modules/spaceLook/actions';
import { toUrlParams } from '../utils';

const initState = {
  optionsRequestStatus: requestStatuses.default,
  appliedRequestStatus: requestStatuses.default,
  options: null,
  selected: [],
  applied: [],
  urlParams: '',
  shouldUpload: false,
};

const benchmarkingFiltersReducer = createReducer(initState, {
  /** APPLIED */
  [FETCH_BENCHMARKING_APPLIED_FILTERS.pending]: (state) => ({
    ...state,
    appliedRequestStatus: requestStatuses.pending,
  }),
  [FETCH_BENCHMARKING_APPLIED_FILTERS.success]: (state, { payload }) => {
    if (!payload) {
      return {
        ...initState,
        appliedRequestStatus: requestStatuses.success,
      };
    }

    const filterHistory = JSON.parse(payload);

    return {
      ...state,
      appliedRequestStatus: requestStatuses.success,
      selected: filterHistory,
      applied: filterHistory,
      urlParams: toUrlParams({ id: filterHistory }),
    };
  },
  [FETCH_BENCHMARKING_APPLIED_FILTERS.failure]: (state) => ({
    ...state,
    appliedRequestStatus: requestStatuses.failure,
  }),

  /** OPTIONS */
  [FETCH_BENCHMARKING_OPTIONS.pending]: (state) => ({
    ...state,
    optionsRequestStatus: requestStatuses.pending,
  }),
  [FETCH_BENCHMARKING_OPTIONS.success]: (state, { payload }) => {
    const hasApplied = state.applied.length > 0;

    if (hasApplied)
      return {
        ...state,
        options: payload,
      };

    const defaultSelected = payload.self_historic.map(({ id }) => id);

    return {
      ...state,
      optionsRequestStatus: requestStatuses.success,
      options: payload,
      selected: defaultSelected,
      applied: defaultSelected,
      urlParams: toUrlParams({ id: defaultSelected }),
    };
  },
  [FETCH_BENCHMARKING_OPTIONS.failure]: (state) => ({
    ...state,
    optionsRequestStatus: requestStatuses.failure,
  }),

  /** SELECTED */
  [SET_BENCHMARKING_FILTER_OPTION]: (state, { payload }) => {
    const includes = state.selected.includes(payload);
    if (includes && state.selected.length < 2) return { ...state };

    return {
      ...state,
      selected: includes ? filter(state.selected, (item) => item !== payload) : [...state.selected, payload],
    };
  },
  [APPLY_BENCHMARKING_FILTERS]: (state) => ({
    ...state,
    applied: state.selected,
    urlParams: toUrlParams({ id: state.selected }),
    shouldUpload: true,
  }),
  [RESET_BENCHMARKING_FILTERS]: (state) => {
    const defaultSelected = state.options.self_historic.map(({ id }) => id);

    return {
      ...state,
      selected: defaultSelected,
      applied: defaultSelected,
      urlParams: toUrlParams({ id: defaultSelected }),
      shouldUpload: true,
    };
  },

  [RESET_REPORT_DATA]: () => ({ ...initState }),
});

export default benchmarkingFiltersReducer;
