import { pick } from 'lodash';

const keysToPick = ['databoxes', 'dailyHourlyGraphData', 'textData'];

const getKeyFindingsMeetingsData = ({ report: { combined } }) => ({
  dataRequestStatuses: pick(combined.requestStatuses, keysToPick),
  data: pick(combined.data, keysToPick),
  shouldRefetchData: pick(combined.shouldRefetchData, keysToPick),
});

export default getKeyFindingsMeetingsData;
