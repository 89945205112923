const SPACE_LOOK_BASE = '/space-look';
const ASSET_LOOK_BASE = '/asset-look';
const WORK_LOOK_BASE = '/work-look';
const LIVE_LOOK_BASE = '/live-look';

const URLS = {
  auth: {
    empty: '/',
    login: '/login',
    restorePassword: '/restore-password',
    twoFACode: '/2FA/code',
    twoFASetup: '/2FA/setup',
  },

  // LOCATION LOOK
  locationLook: {
    locationSummary: '/summary',
    locationDetails: '/details',
  },

  spaceLook: {
    reportHistory: `${SPACE_LOOK_BASE}/report-history`,
    baseReport: `${SPACE_LOOK_BASE}/report/:id`,
    surveyDetails: `${SPACE_LOOK_BASE}/report/:id/survey-details`,
    keyFindingsDesks: `${SPACE_LOOK_BASE}/report/:id/key-findings-desks`,
    keyFindingsMeetings: `${SPACE_LOOK_BASE}/report/:id/key-findings-meetings`,
    keyFindingsBreakout: `${SPACE_LOOK_BASE}/report/:id/key-findings-breakout`,
    combinedOccupancy: `${SPACE_LOOK_BASE}/report/:id/combined-occupancy`,
    desksUsageOverTime: `${SPACE_LOOK_BASE}/report/:id/desks-usage-over-time`,
    desksUsagePattern: `${SPACE_LOOK_BASE}/report/:id/desks-usage-pattern`,
    desksDailyComparison: `${SPACE_LOOK_BASE}/report/:id/desks-dailyComparison`,
    desksBenchmarking: `${SPACE_LOOK_BASE}/report/:id/desks-benchmarking`,
    desksByBuildingAndFloor: `${SPACE_LOOK_BASE}/report/:id/desks-by-building-and-floor`,
    desksByDivAndDep: `${SPACE_LOOK_BASE}/report/:id/desks-by-division-and-department`,
    desksByType: `${SPACE_LOOK_BASE}/report/:id/desks-by-type`,
    desksUsageBandingOcc: `${SPACE_LOOK_BASE}/report/:id/desks-usage-banding-occupied`,
    desksUsageBandingUtil: `${SPACE_LOOK_BASE}/report/:id/desks-usage-banding-utilised`,
    desksAgility: `${SPACE_LOOK_BASE}/report/:id/desks-agility`,
    meetingsUsageOverTime: `${SPACE_LOOK_BASE}/report/:id/meetings-usage-over-time`,
    meetingsByBuildingAndFloor: `${SPACE_LOOK_BASE}/report/:id/meetings-by-building-and-floor`,
    meetingsSpaceComparisonAndSizes: `${SPACE_LOOK_BASE}/report/:id/meetings-space-comparison-and-sizes`,
    breakoutUsageOverTime: `${SPACE_LOOK_BASE}/report/:id/breakout-usage-over-time`,
    breakoutByBuildingAndFloor: `${SPACE_LOOK_BASE}/report/:id/breakout-by-building-and-floor`,
    breakoutSpaceComparison: `${SPACE_LOOK_BASE}/report/:id/breakout-space-comparison`,
    heatmap: `${SPACE_LOOK_BASE}/report/:id/heatmap/:heatmapId`,
    downloads: `${SPACE_LOOK_BASE}/report/:id/downloads`,
    pdf: `${SPACE_LOOK_BASE}/pdf/:id`,
  },
  workLook: {
    base: `${WORK_LOOK_BASE}/:typeId`,
    title: `${WORK_LOOK_BASE}/:typeId/report/:reportId`,
    section: `${WORK_LOOK_BASE}/:typeId/report/:reportId/section/:sectionId/:pageId`,
    pdfReports: `${WORK_LOOK_BASE}/:typeId/report/:reportId/pdf-reports/:pageType`,
    pdfBase: `${WORK_LOOK_BASE}/pdf/base/:reportId`,
    pdfCustom: `${WORK_LOOK_BASE}/pdf/custom/:reportId`,
  },
  assetLook: {
    base: `${ASSET_LOOK_BASE}`,
    productList: `${ASSET_LOOK_BASE}/product-list`,
    allAssets: `${ASSET_LOOK_BASE}/all-assets`,
    productSummary: `${ASSET_LOOK_BASE}/product-summary`,
    assetList: `${ASSET_LOOK_BASE}/asset-list`,
    assetDetails: `${ASSET_LOOK_BASE}/asset-details`,
    pdf: `${ASSET_LOOK_BASE}/pdf/:pdfId`,
    pdfReports: `${ASSET_LOOK_BASE}/pdf-reports`,
    pdfReportsNew: `${ASSET_LOOK_BASE}/pdf-reports/new`,
    pdfReportsHistory: `${ASSET_LOOK_BASE}/pdf-reports/history`,
    orders: `${ASSET_LOOK_BASE}/orders`,
    ordersBasket: `${ASSET_LOOK_BASE}/orders/basket`,
    ordersInProgress: `${ASSET_LOOK_BASE}/orders/in-progress`,
    ordersHistory: `${ASSET_LOOK_BASE}/orders/history`,
  },
  liveLook: {
    base: `${LIVE_LOOK_BASE}`,
    doorsOverTime: `${LIVE_LOOK_BASE}/doors/usage-over-time`,
    doorsDaily: `${LIVE_LOOK_BASE}/doors/daily-comparisons`,
    doorsByBuilding: `${LIVE_LOOK_BASE}/doors/by-building-and-floor`,
    meetsOverTime: `${LIVE_LOOK_BASE}/meetings/usage-over-time`,
    meetsSpaceComparison: `${LIVE_LOOK_BASE}/meetings/space-comparison`,
    meetsSpaceUsageBySizes: `${LIVE_LOOK_BASE}/meetings/space-usage-by-size`,
    breakoutOverTime: `${LIVE_LOOK_BASE}/breakout/usage-over-time`,
    breakoutSpaceComparison: `${LIVE_LOOK_BASE}/breakout/space-comparison`,
    breakoutSpaceUsageBySizes: `${LIVE_LOOK_BASE}/breakout/space-usage-by-size`,
    desksOverTime: `${LIVE_LOOK_BASE}/desks/usage-over-time`,
    desksSpaceComparison: `${LIVE_LOOK_BASE}/desks/space-comparison`,
    desksByBuilding: `${LIVE_LOOK_BASE}/desks/by-building/`,
    desksByDivision: `${LIVE_LOOK_BASE}/desks/by-division`,
    amberState: `${LIVE_LOOK_BASE}/desks/amber-state`,
    executiveSummaryKeyFindings: `${LIVE_LOOK_BASE}/executive-summary/key-findings`,
    executiveSummaryOverallOccupancy: `${LIVE_LOOK_BASE}/executive-summary/overall-occupancy`,
    executiveSummaryFloorPlan: `${LIVE_LOOK_BASE}/executive-summary/floor-plan`,
    executiveSummaryKeyFindingsMeet: `${LIVE_LOOK_BASE}/executive-summary/key-findings-meet`,
    executiveSummaryKeyFindingsBreak: `${LIVE_LOOK_BASE}/executive-summary/key-findings-break`,
    executiveSummaryKeyFindingsDesks: `${LIVE_LOOK_BASE}/executive-summary/key-findings-desks`,
    executiveSummaryKeyFindingsOccupancy: `${LIVE_LOOK_BASE}/executive-summary/key-findings-occupancy`,
    doorsKeyFindings: `${LIVE_LOOK_BASE}/doors/key-findings`,
    desksKeyFindings: `${LIVE_LOOK_BASE}/desks/key-findings`,
    meetsKeyFindings: `${LIVE_LOOK_BASE}/meets/key-findings`,
    breakKeyFindings: `${LIVE_LOOK_BASE}/break/key-findings`,
    dashboard: `${LIVE_LOOK_BASE}/executive-summary/dashboard`,
    archive: `${LIVE_LOOK_BASE}/archives`,
    pdf: `${LIVE_LOOK_BASE}/pdf/:pdfId`,
    pdfReports: `${LIVE_LOOK_BASE}/pdf-reports/:pageType`,
    combinedTeamZoneOccupancy: `${LIVE_LOOK_BASE}/combined-team-zone-occupancy`,
  },
};

export default URLS;
