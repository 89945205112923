import React from 'react';
import cn from 'classnames';

import { FilterButton } from 'components';

import theme from './BenchmarkingFilterHandler.module.scss';

const BenchmarkingFilterHandler = ({ children, showFilter, toggleFilter, selectedFiltersQuantity }) => (
  <>
    <FilterButton onClick={toggleFilter} isActive={showFilter} activeFiltersCounter={selectedFiltersQuantity} />

    <div className={cn(theme.container, { [`${theme.active}`]: showFilter })}>{children}</div>
  </>
);

export default BenchmarkingFilterHandler;
