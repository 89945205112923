/* eslint-disable react/no-children-prop */
import React from 'react';
import { FormControl, Select, InputLabel, MenuItem, ButtonGroup, Button } from '@material-ui/core';

import { changeSortOrder } from 'actions';
import { switchGraphType } from 'components/Pages/SummaryPage/actions';
import switchGraphDataType from 'actions/switchGraphDataType';

import { graphTypes, graphDataTypes, graphSortOptions } from 'components/Pages/SummaryPage/constants';

import theme from './SummaryGraphSwitcher.module.scss';

const SummaryGraphSwitcher = ({ graphType, graphDataType, sortOrder, dispatch }) => (
  <div className={theme.container}>
    <FormControl>
      <InputLabel>Sort by</InputLabel>
      <Select value={sortOrder} onChange={(e) => dispatch(changeSortOrder(e.target.value))} disableUnderline>
        <MenuItem value={graphSortOptions.az}>Name (a-z)</MenuItem>
        <MenuItem value={graphSortOptions.za}>Name (z-a)</MenuItem>
        <MenuItem value={graphSortOptions.asc}>Value (asc)</MenuItem>
        <MenuItem value={graphSortOptions.desc}>Value (desc)</MenuItem>
      </Select>
    </FormControl>

    <FormControl>
      <InputLabel>Data type</InputLabel>
      <Select value={graphDataType} onChange={(e) => dispatch(switchGraphDataType(e.target.value))} disableUnderline>
        <MenuItem value={graphDataTypes.deskCount}>Desk Count</MenuItem>
        <MenuItem value={graphDataTypes.deskUtilization}>Desk Utilization</MenuItem>
      </Select>
    </FormControl>

    <ButtonGroup variant="text">
      <Button
        size="small"
        color={graphType === graphTypes.property ? 'primary' : 'default'}
        onClick={() => dispatch(switchGraphType(graphTypes.property))}
        children="Property"
      />
      <Button
        size="small"
        color={graphType === graphTypes.division ? 'primary' : 'default'}
        onClick={() => dispatch(switchGraphType(graphTypes.division))}
        children="Division"
      />
    </ButtonGroup>
  </div>
);

export default SummaryGraphSwitcher;
