/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSummary } from 'modules/assetLook/actions';
import { filtersToQueryAL, toRequestBody } from 'modules/assetLook/utils';
import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, PRODUCT_ID } from 'modules/assetLook/constants';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';

import { FixedDataTable, TextPlaceholder } from 'modules/shared/components';
import { TableSkeleton } from 'modules/assetLook/components/SummaryTable/components';
import {
  ProductSummaryCellHeader as CellHeader,
  ProductSummaryCellBody as CellBody,
} from 'modules/assetLook/components';

const STATIC_COLUMNS_NUM = 7;

function DataTable({ width, height }) {
  const dispatch = useDispatch();

  const { id: companyId, categoryId } = useSelector(getActiveCompany);
  const { buildingId, productId } = useLocationSearchParams([BUILDING_ID, PRODUCT_ID]);

  const [summaryFieldsActive, summaryRequestStatus, summary, activeFilters, tags] = useAssetLookSelector([
    'summary.fieldsActive',
    'summary.summaryRequestStatus',
    'summary.summary',
    'filters.activeFilters',
    'common.tags',
  ]);

  const filters = toRequestBody(activeFilters, tags);
  const queryFilters = filtersToQueryAL(activeFilters);

  useEffect(() => {
    let isCanceled = false;

    fetchSummary(dispatch, isCanceled, {
      companyId,
      buildingId,
      productId,
      summaryFieldsActive,
      filters,
      categoryId,
      queryFilters,
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
    // eslint-disable-next-line
  }, [companyId, buildingId, productId, JSON.stringify(filters || {}), summaryFieldsActive.join()]);

  return (
    <Choose>
      <When condition={isDefault(summaryRequestStatus) || isLoading(summaryRequestStatus)}>
        <TableSkeleton width={width} height={height} />
      </When>
      <When condition={isFailure(summaryRequestStatus)}>
        <TextPlaceholder error />
      </When>
      <When condition={isSuccess(summaryRequestStatus)}>
        <Choose>
          <When condition={!isEmpty(summary.data)}>
            <FixedDataTable
              width={width}
              height={height}
              rows={summary.data}
              columns={Object.keys(summary.data[0])}
              ColumnHeader={CellHeader}
              ColumnCell={CellBody}
              columnFixed={() => false}
              columnWidth={(columnKey, i, columns) => {
                return i >= columns.length - STATIC_COLUMNS_NUM ? 100 : 150;
              }}
              columnFlexGrow={(columnKey, i, columns) => {
                return i >= columns.length - STATIC_COLUMNS_NUM ? 0 : 1;
              }}
            />
          </When>
          <Otherwise>
            <TextPlaceholder noData />
          </Otherwise>
        </Choose>
      </When>
    </Choose>
  );
}

export default DataTable;
