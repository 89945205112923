import React from 'react';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { getGraphData } from 'modules/workLook/selectors';
import config from 'pages/_privatePages/workLook/SectionPage/components/GraphsPagePattern/config';
import theme from './graphModalContainer.module.scss';

const GraphModalContainer = React.forwardRef(({ onClose }, ref) => {
  const { graph } = useSelector(getGraphData);
  const isModal = true;
  const { graphData } = graph;

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <IconButton ref={ref} aria-label="close" className={theme.closeBtn} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        {config.graphList[graph.type]({ isModal, graphData, ...graph })}
      </div>
    </div>
  );
});

export default GraphModalContainer;
