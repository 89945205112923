import { ENDPOINTS } from 'modules/shared/constants';
import { API, createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/workLook/actions/_createAction';

export const FETCH_REPORTS = createAsyncAction(createAction('FETCH_REPORTS_LIVE_LOOK'));

const fetchReports = (dispatch, { companyId }, isCanceled) => {
  dispatch(FETCH_REPORTS.pending());

  const url = ENDPOINTS.liveLook.reports.replace(':companyId', companyId);

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_REPORTS.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_REPORTS.failure());
    });
};

export default fetchReports;
