import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/assetLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_LOCATIONS = createAsyncAction(createAction('FETCH_LOCATIONS'));

const fetchLocations = (dispatch, companyId, isCanceled) => {
  dispatch(FETCH_LOCATIONS.pending());

  const url = ENDPOINTS.assetLook.locations.replace(':companyId', companyId);

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_LOCATIONS.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_LOCATIONS.failure());
    });
};

export default fetchLocations;
