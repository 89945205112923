const propertyIdToPropertyName = (data, propertyNamesMap) =>
  data.map((item) => {
    if (item.location_id__building) {
      const propertyInList = propertyNamesMap.find((property) => property.property_id === item.location_id__building);

      return {
        ...item,
        location_id__building: (propertyInList && propertyInList.property_name) || item.location_id__building,
      };
    }

    return item;
  });

export default propertyIdToPropertyName;
