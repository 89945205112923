/* eslint-disable camelcase */
import reportPageTypes from '../../../../constants/reportPageTypes';

const MeetsAndBreakOccupancySeats = ({
  databoxes: {
    total_room_seats,
    percent_max_seats_occupancy,
    max_seats_occupancy,
    percent_avg_seats_occupancy,
    avg_seats_occupancy,
  },
  pageType,
}) => {
  const isMeets = pageType === reportPageTypes.meetings;

  return `There were ${total_room_seats} seats available in the ${isMeets ? 'meeting room(s)' : 'breakout space(s)'}.
     The peak usage when viewed on a seat-by-seat basis (i.e. if a ${isMeets ? '10-seat room' : '4-seat table'}
     has 1 person in it, it is shown as ${isMeets ? '10' : '25'}% utilised) 
     was ${percent_max_seats_occupancy}% (${max_seats_occupancy} seats occupied) and the average
     was ${percent_avg_seats_occupancy}% (${avg_seats_occupancy} seats occupied).`;
};

export default MeetsAndBreakOccupancySeats;
