import { requestStatuses } from 'modules/shared/constants';
import { GRAPH_SCALES, GRAPH_VALUES, GRAPH_SWITCHER } from 'modules/liveLook/constants';
import {
  FETCH_DOORS_DAILY_COMPARISONS,
  FETCH_DESK_AND_BREAK,
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
  SET_GRAPH_SWITCHER,
  SET_SECOND_GRAPH_SWITCHER,
  FETCH_OCCUPANCY_DATABOXES,
  FETCH_COMBINED_OCCUPANCY_VARIABLES,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
} from 'modules/liveLook/actions';

export const initialState = {
  requestStatus: requestStatuses.default,
  databoxRequestStatus: requestStatuses.default,
  textReportRequestStatus: requestStatuses.default,
  deskAndBreakRequestStatus: requestStatuses.default,
  combinedOccVariablesRequestStatus: requestStatuses.default,
  data: [],
  capacity: null,
  deskAndBreakData: [],
  scale: GRAPH_SCALES.halfAnHour,
  value: GRAPH_VALUES.max,
  returnPercentageSwitcher: GRAPH_SWITCHER.integers,
  returnPercentageSwitcherDeskAndBreak: GRAPH_SWITCHER.integers,
  occupancyDataboxes: [],
  editableTextReport: '',
  textVariables: { combinedOccupancyVariables: {} },
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_DOORS_DAILY_COMPARISONS.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_DOORS_DAILY_COMPARISONS.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload.data,
        capacity: payload.capacity,
      };
    case FETCH_DOORS_DAILY_COMPARISONS.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    case FETCH_DESK_AND_BREAK.pending().type:
      return {
        ...state,
        deskAndBreakRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_AND_BREAK.success().type:
      return {
        ...state,
        deskAndBreakRequestStatus: requestStatuses.success,
        deskAndBreakData: payload,
      };
    case FETCH_DESK_AND_BREAK.failure().type:
      return {
        ...state,
        deskAndBreakRequestStatus: requestStatuses.failure,
      };
    case FETCH_OCCUPANCY_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_OCCUPANCY_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        occupancyDataboxes: payload,
      };
    case FETCH_OCCUPANCY_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_COMBINED_OCCUPANCY_VARIABLES.pending().type:
      return {
        ...state,
        combinedOccVariablesRequestStatus: requestStatuses.pending,
      };
    case FETCH_COMBINED_OCCUPANCY_VARIABLES.success().type:
      return {
        ...state,
        combinedOccVariablesRequestStatus: requestStatuses.success,
        textVariables: { ...state.textVariables, ...{ combinedOccupancyVariables: payload } },
      };
    case FETCH_COMBINED_OCCUPANCY_VARIABLES.failure().type:
      return {
        ...state,
        combinedOccVariablesRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SET_GRAPH_SCALE().type:
      return {
        ...state,
        scale: payload,
      };
    case SET_GRAPH_VALUE().type:
      return {
        ...state,
        value: payload,
      };
    case SET_SECOND_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcher: payload,
      };
    case SET_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcherDeskAndBreak: payload,
      };
    default:
      throw new Error();
  }
}

export default reducer;
