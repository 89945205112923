/* eslint-disable react/no-children-prop */
import React from 'react';
import { Grid } from '@material-ui/core';
import { ActiveFilterList } from 'modules/liveLook/components';

const renderFilters = (activeFilters) => {
  return (
    <Grid
      container
      style={{
        display: 'flex',
        paddingTop: 5,
        height: 28,
        alignItems: 'center',
        marginBottom: 8,
      }}
      direction="row"
      justifyContent="flex-start"
    >
      {activeFilters?.map((field, i) => {
        const getValuesLength = field.values.length;
        const value = getValuesLength > 2 ? `${field.values.slice(0, 2).join(', ')} ...` : field.values.join(', ');
        const filterItem = field.values.length > 0 ? `${field.field}: ${value}` : null;
        if (!filterItem) {
          return null;
        }
        return (
          <Grid key={i} style={{ paddingRight: 10, marginBottom: 8 }}>
            <ActiveFilterList style={{ padding: 0 }} isActive={false} children={filterItem} isPdf />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default renderFilters;
