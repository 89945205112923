/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Badge from '@material-ui/core/Badge';

import './FilterButton.scss';

import { ReactComponent as Filter } from 'assets/images/icons/filter-white.svg';

const FilterButton = ({ onClick, isActive, activeFiltersCounter }) => (
  <div className="FilterButton">
    <Badge badgeContent={activeFiltersCounter} className="badge">
      <button onClick={onClick} className={cn('button', { active: isActive, withBadge: activeFiltersCounter })}>
        <Filter className="icon" />
      </button>
    </Badge>
  </div>
);

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeFiltersCounter: PropTypes.number.isRequired,
};

export default FilterButton;
