const config = {
  areas: ['Desk areas', 'Meeting rooms and office meeting tables', 'All other meeting and breakout spaces'],
  usage: [
    'Occupied - A staff member was physically present',
    'Signs of Life - No one present, however there were at least two ‘Signs of Life’ (laptop, bag, etc)',
    'Utilised - Desk physically occupied or signs of life evident',
    'Vacant (usable) - The desk was in scope, but not occupied',
    'Vacant (unusable) - The desk was unusable (broken, clutter etc)',
  ],
  activities: [
    'PC, laptop or phone In Use',
    'Reading, writing or filing',
    'Informal meeting/discussion',
    'Phone calls',
    'Work break',
    'Eating',
  ],
};

export default config;
