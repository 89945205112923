import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';
import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

export const FETCH_BENCHMARKING_OPTIONS = createAsyncAction(createAction('FETCH_BENCHMARKING_OPTIONS'));

const fetchBenchmarkingOptions = (dispatch, id) => {
  dispatch(FETCH_BENCHMARKING_OPTIONS.pending());

  const token = isLogged();
  const url = ENDPOINTS.benchmarkingInitFilters.replace(':id', id);
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  axios
    .get(url, config)
    .then(({ data }) => dispatch(FETCH_BENCHMARKING_OPTIONS.success(data)))
    .catch(() => dispatch(FETCH_BENCHMARKING_OPTIONS.failure()));
};

export default fetchBenchmarkingOptions;
