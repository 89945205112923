import { createReducer } from '@reduxjs/toolkit';

import { actionsCreator } from 'modules/spaceLook/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  FETCH_BREAKOUT_DATABOXES,
  FETCH_BREAKOUT_PIE_DATA,
  FETCH_BREAKOUT_TEXT_DATA,
  FETCH_BREAKOUT_DAILY_HOURLY_GRAPH_DATA,
  FETCH_BREAKOUT_BUILDING_UTIL_GRAPH_DATA,
  FETCH_BREAKOUT_FLOOR_UTIL_GRAPH_DATA,
  FETCH_BREAKOUT_PEAK_AND_AVG_UTIL,
  RESET_REPORT_DATA,
  APPLY_FILTERS,
  RESET_FILTERS,
} from 'modules/spaceLook/actions';

const initState = {
  requestStatuses: {
    databoxes: requestStatuses.default,
    pieData: requestStatuses.default,
    textData: requestStatuses.default,
    dailyHourlyGraphData: requestStatuses.default,
    buildingUtilizationData: requestStatuses.default,
    floorUtilizationData: requestStatuses.default,
    peakAndAvgUtilData: requestStatuses.default,
  },
  data: {
    databoxes: null,
    pieData: null,
    textData: null,
    dailyHourlyGraphData: null,
    buildingUtilizationData: null,
    floorUtilizationData: null,
    peakAndAvgUtilData: null,
  },
  shouldRefetchData: {
    databoxes: false,
    pieData: false,
    textData: false,
    dailyHourlyGraphData: false,
    buildingUtilizationData: false,
    floorUtilizationData: false,
    peakAndAvgUtilData: false,
  },
};

const breakoutReducer = createReducer(initState, {
  ...actionsCreator(FETCH_BREAKOUT_DATABOXES, 'databoxes'),
  ...actionsCreator(FETCH_BREAKOUT_PIE_DATA, 'pieData'),
  ...actionsCreator(FETCH_BREAKOUT_TEXT_DATA, 'textData'),
  ...actionsCreator(FETCH_BREAKOUT_DAILY_HOURLY_GRAPH_DATA, 'dailyHourlyGraphData'),
  ...actionsCreator(FETCH_BREAKOUT_BUILDING_UTIL_GRAPH_DATA, 'buildingUtilizationData'),
  ...actionsCreator(FETCH_BREAKOUT_FLOOR_UTIL_GRAPH_DATA, 'floorUtilizationData'),
  ...actionsCreator(FETCH_BREAKOUT_PEAK_AND_AVG_UTIL, 'peakAndAvgUtilData'),

  [APPLY_FILTERS]: (state) => ({
    ...state,
    shouldRefetchData: {
      databoxes: true,
      pieData: true,
      textData: true,
      dailyHourlyGraphData: true,
      buildingUtilizationData: true,
      floorUtilizationData: true,
      peakAndAvgUtilData: true,
    },
  }),
  [RESET_FILTERS]: (state) => ({
    ...state,
    shouldRefetchData: {
      databoxes: true,
      pieData: true,
      textData: true,
      dailyHourlyGraphData: true,
      buildingUtilizationData: true,
      floorUtilizationData: true,
      peakAndAvgUtilData: true,
    },
  }),

  [RESET_REPORT_DATA]: () => ({ ...initState }),
});

export default breakoutReducer;
