import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ProductIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 22H6A2 2 0 0 1 8 20V8H2V5H16V8H10V20A2 2 0 0 1 12 22M22 2V22H20V15H15V22H13V14A2 2 0 0 1 15 12H20V2Z" />
  </SvgIcon>
);

export default ProductIcon;
