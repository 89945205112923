import React from 'react';
import cn from 'classnames';

import { FiltersHeader, Loader } from 'components';
import { FilterList, DateRangePicker } from 'components/Filters/ReportFilter/components';

import { requestStatuses } from 'modules/shared/constants';

import config from './config';
import theme from './ReportFilter.module.scss';

const ReportFilter = ({
  requestStatus,
  initFilters,
  availableFilters,
  selectedFilters,
  isApplyDisabled,
  isResetDisabled,
}) => (
  <div className={theme.filterBody}>
    <FiltersHeader isApplyDisabled={isApplyDisabled} isResetDisabled={isResetDisabled} />

    <div className={theme.content}>
      <Choose>
        <When condition={!initFilters}>
          <Loader />
        </When>
        <Otherwise>
          <If condition={requestStatus !== requestStatuses.success}>
            <Loader />
          </If>
          <div className={cn({ blured: requestStatus !== requestStatuses.success })}>
            <If condition={initFilters.from_date && initFilters.to_date}>
              <DateRangePicker
                selectedFrom={selectedFilters.from_date && new Date(selectedFilters.from_date)}
                selectedTo={selectedFilters.to_date && new Date(selectedFilters.to_date)}
                surveyDateRange={{
                  start: new Date(initFilters.from_date),
                  end: new Date(initFilters.to_date),
                }}
              />
            </If>
            {Object.keys(config).map((filterCategory) => {
              if (!initFilters[filterCategory] || initFilters[filterCategory].length < 1) return;

              // eslint-disable-next-line consistent-return
              return (
                <FilterList
                  key={filterCategory}
                  title={config[filterCategory].title}
                  filterCategory={filterCategory}
                  initOptions={initFilters[filterCategory]}
                  availableOptions={availableFilters[filterCategory]}
                  selectedFilters={selectedFilters}
                />
              );
            })}
          </div>
        </Otherwise>
      </Choose>
    </div>
  </div>
);

export default ReportFilter;
