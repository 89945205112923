const options = {
  maintainAspectRatio: false,
  scaleBeginAtZero: true,
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          display: false,
        },
      },
    ],
  },
  legend: {
    position: 'bottom',
    usePointStyle: true,
  },
  plugins: {
    // Plugin base doorsDailyConfig is located at App.js
    datalabels: {
      display: true,
      align: 'center',
      anchor: 'center',
      color: 'black',
      font: {
        family: 'Roboto, sans-serif',
        weight: '500',
      },
      formatter: (value) => (value > 10 ? `${value}%` : ''),
    },
  },
};

export default options;
