import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { getPdfMode } from 'modules/spaceLook/selectors';
import DataCard from 'modules/shared/components/DataCard';

import config from './config';

const CombinedDataCards = ({ databoxes }) => {
  const pdfMode = useSelector(getPdfMode);

  return (
    <Grid container spacing={pdfMode ? 1 : 2} style={{ maxWidth: '485px' }}>
      {config.map(({ title, firstValue, secondValue, color }) => (
        <DataCard
          key={title}
          pdfMode={pdfMode}
          title={title}
          color={color}
          firstValue={databoxes[firstValue.key]}
          firstLabel={firstValue.isPercent ? '%' : ` ${firstValue.label}`}
          secondValue={databoxes[secondValue.key]}
          secondLabel={secondValue.isPercent ? '%' : `${secondValue.label}`}
          noValue={!Number.isFinite(databoxes[firstValue.key]) || !Number.isFinite(databoxes[secondValue.key])}
        />
      ))}
    </Grid>
  );
};

export default CombinedDataCards;
