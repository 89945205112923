import React from 'react';
import cn from 'classnames';

import { ASSET_FIELDS, PAGE_TYPES, PRODUCT_FIELDS } from 'modules/assetLook/constants';

import {
  AssetListCellBody as CellBody,
  AssetListCellHeader as CellHeader,
  ItemDetailsCard,
} from 'modules/assetLook/components';
import { FixedDataTable } from 'modules/shared/components';
import { ReportContentPage } from 'pages/_privatePages/shared';

import config from '../config';

const AssetList = ({ product, data, activeFilters, parentCompanyLogo, companyLogo, theme }) => (
  <div className={cn(theme.pdfPage, theme.hasPageCounter)}>
    <ReportContentPage
      title={PAGE_TYPES.assetList}
      activeFilters={activeFilters}
      firstLogo={parentCompanyLogo}
      secondLogo={companyLogo}
    >
      {({ height, width }) => (
        <>
          <If condition={product}>
            <div
              style={{
                height: `${config.productDetailsHeight}px`,
                marginBottom: `${config.productDetailsMargin}px`,
              }}
            >
              <ItemDetailsCard
                image={product[PRODUCT_FIELDS.thumbnail]}
                image_medium={product[PRODUCT_FIELDS.image]}
                title={product[PRODUCT_FIELDS.name]}
                contentData={product.details_data}
                elevation={0}
                navDisabled
              />
            </div>
          </If>
          <FixedDataTable
            width={width}
            height={product ? height - config.productDetailsHeight - config.productDetailsMargin : height}
            rows={data}
            columns={[ASSET_FIELDS.id, ...Object.keys(data[0].list_data)]}
            columnWidth={(columnKey, i) => (i === 0 ? 150 : 200)}
            ColumnHeader={CellHeader}
            ColumnCell={CellBody}
            columnFlexGrow={(key, i) => (i === 0 ? 0 : 1)}
            showScrollbarX={false}
            showScrollbarY={false}
          />
        </>
      )}
    </ReportContentPage>
  </div>
);

export default AssetList;
