import URLS from 'pages/constants/urls';

import reportIcon from 'assets/images/icons/report.svg';
import graph from 'assets/images/icons/graph.svg';
import desk from 'assets/images/icons/desks.svg';
import meeting from 'assets/images/icons/humans.svg';
import coffee from 'assets/images/icons/coffee.svg';
import heatmapIcon from 'assets/images/icons/heatmap.svg';
import downloadIcon from 'assets/images/icons/download.svg';

const config = {
  reportHistory: {
    label: 'Reports',
    icon: reportIcon,
    path: URLS.spaceLook.reportHistory,
  },
  reportNav: [
    {
      label: 'Executive Summary',
      icon: graph,
      items: [
        { text: 'Survey Details', path: URLS.spaceLook.surveyDetails },
        { text: 'Key Findings – Desks', path: URLS.spaceLook.keyFindingsDesks },
        { text: 'Key Findings – Meetings', path: URLS.spaceLook.keyFindingsMeetings },
        { text: 'Key Findings – Breakout', path: URLS.spaceLook.keyFindingsBreakout },
        { text: 'Combined Occupancy', path: URLS.spaceLook.combinedOccupancy },
      ],
    },
    {
      label: 'Desks',
      icon: desk,
      items: [
        { text: 'Usage Over Time', path: URLS.spaceLook.desksUsageOverTime },
        { text: 'Daily Usage Pattern', path: URLS.spaceLook.desksUsagePattern },
        { text: 'Daily Comparisons', path: URLS.spaceLook.desksDailyComparison },
        { text: 'Benchmarking', path: URLS.spaceLook.desksBenchmarking },
        { text: 'By Building and Floor', path: URLS.spaceLook.desksByBuildingAndFloor },
        { text: 'By Division / Department', path: URLS.spaceLook.desksByDivAndDep },
        { text: 'By Type', path: URLS.spaceLook.desksByType },
        { text: 'Desk Usage Banding - Occupied', path: URLS.spaceLook.desksUsageBandingOcc },
        { text: 'Desk Usage Banding - Utilised', path: URLS.spaceLook.desksUsageBandingUtil },
        { text: 'Agility', path: URLS.spaceLook.desksAgility },
      ],
    },
    {
      label: 'Meetings',
      icon: meeting,
      items: [
        { text: 'Usage Over Time', path: URLS.spaceLook.meetingsUsageOverTime },
        { text: 'By Building / Floor', path: URLS.spaceLook.meetingsByBuildingAndFloor },
        { text: 'Space Comparison & Sizes', path: URLS.spaceLook.meetingsSpaceComparisonAndSizes },
      ],
    },
    {
      label: 'Breakout',
      icon: coffee,
      items: [
        { text: 'Usage Over Time', path: URLS.spaceLook.breakoutUsageOverTime },
        { text: 'By Building / Floor', path: URLS.spaceLook.breakoutByBuildingAndFloor },
        { text: 'Space Comparison', path: URLS.spaceLook.breakoutSpaceComparison },
      ],
    },
  ],
  heatmaps: {
    label: 'Heatmaps',
    icon: heatmapIcon,
    path: URLS.spaceLook.heatmap,
  },
  downloads: {
    label: 'Downloads',
    icon: downloadIcon,
    path: URLS.spaceLook.downloads,
  },
};

export default config;
