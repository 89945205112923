import React, { useEffect, useReducer } from 'react';

import { useAuthSelector } from 'modules/auth/hooks';
import { requestStatuses } from 'modules/shared/constants';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';

import {
  fetchOccupancyDataboxes,
  FETCH_OCCUPANCY_DATABOXES,
  FETCH_DESK_DATABOXES,
  fetchDeskDataboxes,
  fetchBreakDataboxes,
  FETCH_BREAK_DATABOXES,
  fetchMeetsDataboxes,
  FETCH_MEETS_DATABOXES,
  fetchGraphsText,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
  saveGraphsText,
} from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';

import SummaryOverallOccupancy from './SummaryOverallOccupancy';
import { dateToQuery } from '../../../../modules/liveLook/utils';

const initialState = {
  databoxRequestStatus: requestStatuses.default,
  deskDataboxRequestStatus: requestStatuses.default,
  breakDataboxRequestStatus: requestStatuses.default,
  meetDataboxRequestStatus: requestStatuses.default,
  textReportRequestStatus: requestStatuses.default,
  occupancyDataboxes: [],
  deskDataboxes: [],
  breakDataboxes: [],
  meetsDataboxes: [],
  editableTextReport: '',
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_OCCUPANCY_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_OCCUPANCY_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        occupancyDataboxes: payload,
      };
    case FETCH_OCCUPANCY_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_DESK_DATABOXES.pending().type:
      return {
        ...state,
        deskDataboxRequestStatus: requestStatuses.pending,
      };
    case FETCH_DESK_DATABOXES.success().type:
      return {
        ...state,
        deskDataboxRequestStatus: requestStatuses.success,
        deskDataboxes: payload,
      };
    case FETCH_DESK_DATABOXES.failure().type:
      return {
        ...state,
        deskDataboxRequestStatus: requestStatuses.failure,
      };
    case FETCH_BREAK_DATABOXES.pending().type:
      return {
        ...state,
        breakDataboxRequestStatus: requestStatuses.pending,
      };
    case FETCH_BREAK_DATABOXES.success().type:
      return {
        ...state,
        breakDataboxRequestStatus: requestStatuses.success,
        breakDataboxes: payload,
      };
    case FETCH_BREAK_DATABOXES.failure().type:
      return {
        ...state,
        breakDataboxRequestStatus: requestStatuses.failure,
      };
    case FETCH_MEETS_DATABOXES.pending().type:
      return {
        ...state,
        meetDataboxRequestStatus: requestStatuses.pending,
      };
    case FETCH_MEETS_DATABOXES.success().type:
      return {
        ...state,
        meetDataboxRequestStatus: requestStatuses.success,
        meetsDataboxes: payload,
      };
    case FETCH_MEETS_DATABOXES.failure().type:
      return {
        ...state,
        meetDataboxRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    default:
      throw new Error();
  }
}

function SummaryOverallOccupancyContainer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    occupancyDataboxes,
    deskDataboxes,
    databoxRequestStatus,
    breakDataboxRequestStatus,
    breakDataboxes,
    meetDataboxRequestStatus,
    meetsDataboxes,
    editableTextReport,
    textReportRequestStatus,
    deskDataboxRequestStatus,
  } = state;

  const [companyId] = useAuthSelector(['currentCompany']);
  const [startDate, endDate, workingHours] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
  ]);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;

    fetchOccupancyDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
    });

    fetchDeskDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
    });

    fetchBreakDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'sum_overall',
    });

    fetchMeetsDataboxes(dispatch, isCanceled, {
      companyId,
      workingHours,
      dateAfter,
      dateBefore,
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, dateAfter, dateBefore, workingHours]);

  const databoxes = {
    capacity: occupancyDataboxes.capacity,
    max_occupancy_percentage: occupancyDataboxes.max_occupancy_percentage,
    max_desk_usage: deskDataboxes.max_desk_usage,
    percentage_max_desk_usage: deskDataboxes.percentage_max_desk_usage,
    max_occupancy: occupancyDataboxes.max_occupancy,
    nominal_capacity_percentage: occupancyDataboxes.nominal_capacity_percentage,
    desks: deskDataboxes.desks,
    max_rooms_usage: meetsDataboxes.max_rooms_usage,
    percentage_max_rooms_usage: meetsDataboxes.percentage_max_rooms_usage,
    max_rooms_usage_breakout: breakDataboxes.max_rooms_usage,
    percentage_max_rooms_usage_breakout: breakDataboxes.percentage_max_rooms_usage,
  };

  const databoxesSet = {
    occupancyDataboxes,
    deskDataboxes,
    breakDataboxes,
    meetsDataboxes,
  };

  return (
    <Choose>
      <When
        condition={[
          databoxRequestStatus,
          meetDataboxRequestStatus,
          breakDataboxRequestStatus,
          deskDataboxRequestStatus,
          textReportRequestStatus,
        ].some((item) => isDefault(item) || isLoading(item))}
      >
        <Loader />
      </When>
      <When
        condition={[
          databoxRequestStatus,
          meetDataboxRequestStatus,
          breakDataboxRequestStatus,
          deskDataboxRequestStatus,
          textReportRequestStatus,
        ].every((item) => isSuccess(item))}
      >
        <SummaryOverallOccupancy
          {...state}
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'sum_overall', text });
          }}
          editableTextReport={editableTextReport}
          databoxes={databoxes}
          databoxesSet={databoxesSet}
          customHeader
        />
      </When>
      <When
        condition={[
          databoxRequestStatus,
          meetDataboxRequestStatus,
          breakDataboxRequestStatus,
          deskDataboxRequestStatus,
          textReportRequestStatus,
        ].some((item) => isFailure(item))}
      >
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default SummaryOverallOccupancyContainer;
