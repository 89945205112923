import { CompositeDecorator } from 'draft-js';

import { NameComponent, ValueComponent } from 'modules/shared/components/TextEditor/components';

const findMentionEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'mention';
  }, callback);
};

const decorators = {
  toValue: new CompositeDecorator([
    {
      strategy: findMentionEntities,
      component: ValueComponent,
    },
  ]),
  toName: {
    strategy: findMentionEntities,
    component: NameComponent,
  },
};

export default decorators;
