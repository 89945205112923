import { byBuildingAndFloorTypes } from 'modules/spaceLook/constants';

const DesksPropertyOrFloorHighestUtil = ({ buildingAndFloorData: { data, type } }) => {
  const highestOcc = data.reduce(
    (max, current) => (max.percent_max_occupancy < current.percent_max_occupancy ? current : max),
    data[0],
  );

  const highestUtil = data.reduce(
    (max, current) => (max.percent_max_utilization < current.percent_max_utilization ? current : max),
    data[0],
  );

  const buildingOrFloor = type === byBuildingAndFloorTypes.building ? 'building' : 'floor';

  return `In terms of utilisation, ${buildingOrFloor} ${highestUtil.name} 
     ${highestOcc.name === highestUtil.name ? 'also' : ''}
     had the highest utilisation (including signs of life) with a peak of ${highestUtil.percent_max_utilization}%
     and an average utilisation of ${highestUtil.percent_avg_utilization}%.`;
};

export default DesksPropertyOrFloorHighestUtil;
