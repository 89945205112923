import { createReducer } from '@reduxjs/toolkit';

import { ASSET_FIELDS } from 'modules/assetLook/constants';
import { sortItems, sortAction } from 'modules/assetLook/utils';
import { DESC, requestStatuses } from 'modules/shared/constants';

import {
  FETCH_ALL_ASSETS,
  FETCH_ASSETS,
  RESET_STATE,
  SORT_ASSETS,
  FETCH_PRODUCTS,
  FETCH_SUMMARY,
  CHECK_ASSET,
  CHECK_ALL_ASSETS,
  ADD_TO_BASKET,
  RESET_BASKET,
} from 'modules/assetLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  requestStatus: requestStatuses.default,
  assets: {},
  sortParams: {
    orderBy: ASSET_FIELDS.id,
    direction: DESC,
  },
  basketBuilding: null,
  basketAssets: [],
};

export default createReducer(initState, {
  [FETCH_ASSETS.pending]: (state) => ({
    ...initState,
    requestStatus: requestStatuses.pending,
    basketBuilding: state.basketBuilding,
    basketAssets: state.basketAssets,
  }),
  [FETCH_ASSETS.success]: (state, { payload }) => {
    const { orderBy, direction } = state.sortParams;

    const data = payload.data.map((item) => ({
      ...item,
      [ASSET_FIELDS.checkbox]: false,
    }));

    return {
      ...state,
      requestStatus: requestStatuses.success,
      assets: {
        ...payload,
        data: sortItems(data, orderBy, direction),
      },
    };
  },
  [FETCH_ASSETS.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
  }),

  [FETCH_ALL_ASSETS.pending]: (state) => ({
    ...initState,
    requestStatus: requestStatuses.pending,
    basketBuilding: state.basketBuilding,
    basketAssets: state.basketAssets,
  }),
  [FETCH_ALL_ASSETS.success]: (state, { payload }) => {
    const { orderBy, direction } = state.sortParams;

    const data = payload.data.map((item) => ({
      ...item,
      [ASSET_FIELDS.checkbox]: false,
    }));

    return {
      ...state,
      requestStatus: requestStatuses.success,
      assets: {
        ...payload,
        data: sortItems(data, orderBy, direction),
      },
    };
  },
  [FETCH_ALL_ASSETS.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
  }),

  [SORT_ASSETS]: (state, { payload }) => sortAction(state, payload, 'sortParams', 'assets'),

  [FETCH_PRODUCTS.pending]: (state) => ({
    ...initState,
    basketBuilding: state.basketBuilding,
    basketAssets: state.basketAssets,
  }),
  [FETCH_SUMMARY.pending]: (state) => ({
    ...initState,
    basketBuilding: state.basketBuilding,
    basketAssets: state.basketAssets,
  }),

  [CHECK_ASSET]: (state, { payload: assetId }) => ({
    ...state,
    assets: {
      ...state.assets,
      data: state.assets.data.map((asset) =>
        asset[ASSET_FIELDS.id] === assetId
          ? { ...asset, [ASSET_FIELDS.checkbox]: !asset[ASSET_FIELDS.checkbox] }
          : { ...asset },
      ),
    },
  }),
  [CHECK_ALL_ASSETS]: (state) => {
    const isAllChecked = state.assets.data.every((asset) => asset[ASSET_FIELDS.checkbox]);

    return {
      ...state,
      assets: {
        ...state.assets,
        data: state.assets.data.map((asset) => ({
          ...asset,
          [ASSET_FIELDS.checkbox]: !isAllChecked,
        })),
      },
    };
  },

  [ADD_TO_BASKET]: (state, { payload: buildingId }) => {
    const checkedAssets = state.assets.data
      .filter((asset) => asset[ASSET_FIELDS.checkbox])
      .map((asset) => asset[ASSET_FIELDS.id]);

    const basketAssets =
      state.basketBuilding !== buildingId
        ? checkedAssets
        : [...state.basketAssets, ...checkedAssets].filter((val, index, arr) => arr.indexOf(val) === index);

    return {
      ...state,
      assets: {
        ...state.assets,
        data: state.assets.data.map((asset) => ({
          ...asset,
          [ASSET_FIELDS.checkbox]: false,
        })),
      },
      basketAssets,
      basketBuilding: buildingId,
    };
  },
  [RESET_BASKET]: (state) => ({
    ...state,
    basketAssets: initState.basketAssets,
    basketBuilding: initState.basketBuilding,
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
