export const GRAPHS_GROUP_LIST = [
  {
    name: 'None',
    value: '',
  },
  {
    name: 'Group by field',
    value: '1',
  },
  {
    name: 'Group by name',
    value: '2',
  },
];
