import ModifierSettings from '../ModifierSettings';
import ColorForm from '../ModifierSettings/components/createModifierForms/ColorForm';
import CombineMultipleValueForm from '../ModifierSettings/components/createModifierForms/CombineMultipleValue';
import CombineSingleValueForm from '../ModifierSettings/components/createModifierForms/CombineSingleValue';
import DataModifierForm from '../ModifierSettings/components/createModifierForms/DataModifierForm';
import DifferenceForm from '../ModifierSettings/components/createModifierForms/DifferenceForm/DifferenceForm';
import MapMarker from '../ModifierSettings/components/createModifierForms/MapMarker';
import MergeForm from '../ModifierSettings/components/createModifierForms/MergeForm';
import PeakValueForm from '../ModifierSettings/components/createModifierForms/PeakValueForm';
import SortingForm from '../ModifierSettings/components/createModifierForms/SortingForm';

export const MODIFIER_TABS = {
  1: ['combine'],
  2: ['color', 'order', 'filter', 'combine', 'combine_m'],
  3: ['color', 'order', 'difference', 'filter', 'combine', 'combine_m'],
  4: ['color', 'order', 'filter', 'combine', 'combine_m'],
  5: ['color', 'order', 'filter'],
  6: ['order', 'filter', 'combine', 'combine_m'],
  7: [],
  8: ['combine'],
  9: ['color', 'order', 'filter', 'combine', 'combine_m'],
  11: ['color', 'order', 'filter'],
  12: ['map_marker'],
  13: [],
  14: [],
  15: ['filter'],
  16: [],
  17: [],
  18: ['peak_value', 'merge'],
  19: [],
  20: [],
  21: ['data'],
};

export const TAB_NAMES = {
  order: 'Sorting',
  color: 'Graph colours',
  data: 'Data modifier',
  difference: 'Difference',
  filter: 'Filter results',
  peak_value: 'Peak value',
  combine: 'Combine single values',
  merge: 'Merge modifier',
  map_marker: 'Map marker',
  combine_m: 'Combine multiple values',
};

export const MODIFIER_CONFIG = {
  filter: ModifierSettings,
  color: ModifierSettings,
  order: ModifierSettings,
  data: ModifierSettings,
  difference: ModifierSettings,
  manipulate: ModifierSettings,
  peak_value: ModifierSettings,
  combine: ModifierSettings,
  merge: ModifierSettings,
  map_marker: ModifierSettings,
  combine_m: ModifierSettings,
};

export const MODIFIERS_FORMS = {
  color: ColorForm,
  order: SortingForm,
  data: DataModifierForm,
  difference: DifferenceForm,
  peak_value: PeakValueForm,
  combine: CombineSingleValueForm,
  merge: MergeForm,
  map_marker: MapMarker,
  combine_m: CombineMultipleValueForm,
};
