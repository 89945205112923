import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/auth/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_COMPANY_USERS = createAsyncAction(createAction('FETCH_COMPANY_USERS'));

const fetchCompanyUsers = (dispatch, companyId) => {
  dispatch(FETCH_COMPANY_USERS.pending());
  const url = ENDPOINTS.auth.companyUsers.replace(':companyId', companyId);

  API.get(url)
    .then(({ data }) => dispatch(FETCH_COMPANY_USERS.success(data)))
    .catch(() => dispatch(FETCH_COMPANY_USERS.failure()));
};

export default fetchCompanyUsers;
