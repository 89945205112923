import React from 'react';

import './PageHeader.scss';

const PageHeader = ({ title, text }) => {
  // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
  const renderedText = text ? <p className="text">{text}</p> : null;

  return (
    <div className="PageHeader">
      <div className="page-header">
        <h1 className="title">{title}</h1>
        {renderedText}
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  title: 'Page Title',
};

export default PageHeader;
