import { GRAPH_SCALES } from 'modules/liveLook/constants';
import { isEmpty } from 'lodash';

const AVERAGE = 'avg';
const SCALES_FOR_QUERRY = {
  halfAnHour: '',
  hour: 'hour',
  day: 'day',
  week: 'Week',
  month: 'Month',
};

const setScaleOption = (scale, days) => {
  if (![GRAPH_SCALES.day, GRAPH_SCALES.week, GRAPH_SCALES.month, GRAPH_SCALES.hour].includes(scale) && days < 31)
    return '';
  if (![GRAPH_SCALES.day, GRAPH_SCALES.week, GRAPH_SCALES.month].includes(scale) && days < 61)
    return SCALES_FOR_QUERRY.hour;
  if ([GRAPH_SCALES.halfAnHour, GRAPH_SCALES.hour].includes(scale) && days >= 61) return GRAPH_SCALES.day;
  if ([GRAPH_SCALES.day, GRAPH_SCALES.week, GRAPH_SCALES.month].includes(scale)) return scale;
  return '';
};

export default setScaleOption;

export const setAvgValue = (isEqual, arrayWithThirtyMin, arrayWithFullHours) =>
  isEqual
    ? parseFloat(arrayWithThirtyMin.toFixed(1))
    : parseFloat(((arrayWithThirtyMin + arrayWithFullHours) / 2).toFixed(1));

const isMaxOrAvg = (propName, isEqual, arrayWithThirtyMin, arrayWithFullHours) =>
  propName.includes(AVERAGE)
    ? setAvgValue(isEqual, arrayWithThirtyMin[propName], arrayWithFullHours[propName])
    : Math.max(arrayWithThirtyMin[propName], arrayWithFullHours[propName]);

export const createObj = (arrayWithThirtyMin, arrayWithFullHours) => {
  const isEqual =
    arrayWithFullHours.day === arrayWithThirtyMin.day && arrayWithFullHours.time === arrayWithThirtyMin.time;

  return Object.keys(arrayWithThirtyMin).reduce((acc, propName) => {
    return {
      ...acc,
      day: arrayWithFullHours.day,
      time: arrayWithFullHours.time,
      [propName]: isMaxOrAvg(propName, isEqual, arrayWithThirtyMin, arrayWithFullHours),
    };
  }, {});
};

export const daysDifference = (endDate, startDate) => {
  const dateStart = new Date(startDate);
  const dateEnd = new Date(endDate);
  const differenceInTime = dateEnd.getTime() - dateStart.getTime();
  // To calculate the no. of days between two dates
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays;
};

export const isInPercentages = (returnPercentageSwitcher, databoxes, integerField, percentageField) => {
  if (isEmpty(databoxes)) return 0;
  return returnPercentageSwitcher ? integerField : percentageField;
};
