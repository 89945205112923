import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { URLS } from 'pages/constants';
import { API, isAdmin, isClient, isPartner, isViewOnly } from 'modules/shared/utils';
import { getUser } from 'modules/auth/selectors';
import { ENDPOINTS } from 'modules/shared/constants';
import { getReports } from 'modules/workLook/selectors';
import { BASKET_ADD_ALL, DELETE_REPORT, SWITCH_IS_PUBLISHED } from 'modules/workLook/actions';

// eslint-disable-next-line import/no-cycle
import TitleActionButtons from './TitleActionButtons';

const TitleActionButtonsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { typeId, reportId } = useParams();
  const { reportData } = useSelector(getReports);
  const activeReport = reportData[reportId];

  const { user } = useSelector(getUser);
  // eslint-disable-next-line camelcase
  const { people_look_editing_rights, role } = user;

  const isAdminRole = isAdmin(role) || isPartner(role);
  const isClientRole = isClient(role);
  const hasViewRights = isViewOnly(people_look_editing_rights);
  const isPermitted = isAdminRole || (isClientRole && !hasViewRights);
  const _onAddAllClick = () => dispatch(BASKET_ADD_ALL(reportId));

  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const _handleCsvModalOpen = () => setCsvModalOpen(true);
  const _handleCsvModalClose = () => setCsvModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleteSubmitting, setIsDeleteSubmitting] = useState(false);
  const _handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const _handleDeleteModalClose = () => setDeleteModalOpen(false);
  const _handleDeleteSubmit = () => {
    setIsDeleteSubmitting(true);

    const url = ENDPOINTS.workLook.reportDeactivate.replace(':reportId', reportId);

    API.delete(url)
      .then(() => {
        setIsDeleteSubmitting(false);
        history.push({
          pathname: URLS.workLook.base.replace(':typeId', typeId),
          state: { isInit: true },
        });
        dispatch(DELETE_REPORT(reportId));
      })
      .catch((err) => {
        setIsDeleteSubmitting(false);
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const [isPublishedSubmitting, setIsPublishedSubmitting] = useState(false);
  const _onPublishClick = () => {
    setIsPublishedSubmitting(true);

    const url = ENDPOINTS.workLook.reportPublish.replace(':reportId', reportId);
    const data = {
      is_published: !activeReport.is_published,
    };

    API.patch(url, data)
      // eslint-disable-next-line camelcase
      .then(({ data: { is_published, date_published } }) => {
        dispatch(SWITCH_IS_PUBLISHED({ reportId, is_published, date_published }));
        setIsPublishedSubmitting(false);
      })
      .catch((err) => {
        setIsPublishedSubmitting(false);
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  return (
    <TitleActionButtons
      {...activeReport}
      isPermitted={isPermitted}
      _onAddAllClick={_onAddAllClick}
      csvModalOpen={csvModalOpen}
      _handleCsvModalOpen={_handleCsvModalOpen}
      _handleCsvModalClose={_handleCsvModalClose}
      deleteModalOpen={deleteModalOpen}
      isDeleteSubmitting={isDeleteSubmitting}
      _handleDeleteModalOpen={_handleDeleteModalOpen}
      _handleDeleteModalClose={_handleDeleteModalClose}
      _handleDeleteSubmit={_handleDeleteSubmit}
      isPublishedSubmitting={isPublishedSubmitting}
      _onPublishClick={_onPublishClick}
    />
  );
};

export default TitleActionButtonsContainer;
