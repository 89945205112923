/* eslint-disable camelcase */
import React from 'react';

import { Collapse } from '@material-ui/core';

import { SidebarListItem } from 'modules/shared/components';
// eslint-disable-next-line import/no-cycle
import { CategoryList } from 'modules/assetLook/components/Sidebar/components/index';

import BuildingIcon from './BuildingIcon';

const BuildingItem = ({ building__name, isAccordionOpen, _onItemClick, ...props }) => (
  <>
    <SidebarListItem
      Icon={BuildingIcon}
      label={building__name}
      isAccordionOpen={isAccordionOpen}
      _onItemClick={_onItemClick}
    />

    <Collapse in={isAccordionOpen} timeout="auto" unmountOnExit>
      <CategoryList {...props} />
    </Collapse>
  </>
);

export default BuildingItem;
