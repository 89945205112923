import { createReducer } from '@reduxjs/toolkit';

import { SET_DATA_CARD } from 'modules/workLook/actions';

const initState = {
  dataCard: 0,
};

export default createReducer(initState, {
  [SET_DATA_CARD]: (state, { payload }) => ({
    ...state,
    dataCard: payload,
  }),
});
