import filterOptionsReducer from './filterOptionsReducer';
import activeFiltersReducer from './activeFiltersReducer';

// Used custom combined reducer function in order to get initFilters & availableFilters in activeFiltersReducer
export default (state = {}, action) => {
  const initFilters = state.options && state.options.init;
  const availableFilters = state.options && state.options.available;

  return {
    options: filterOptionsReducer(state.options, action),
    active: activeFiltersReducer(state.active, { ...action, initFilters, availableFilters }),
  };
};
