import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SET_ARCHIVE } from 'modules/liveLook/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 16px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.light,
    height: '35px',
    width: '80px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const ArchiveExit = ({ archive, variant = 'contained' }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const onClick = () => {
    dispatch(SET_ARCHIVE(null));
    history.go(0);
  };

  return (
    <div
      style={{
        backgroundColor: '#04a9d2',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '275px',
        borderRadius: '8px',
        padding: '5px 10px',
      }}
    >
      <Grid style={{ color: '#ffffff' }}>Archive: {archive}</Grid>
      <Button
        variant={variant}
        classes={{ root: classes.root }}
        // startIcon={startIcon}
        onClick={onClick}
      >
        Exit
      </Button>
    </div>
  );
};

export default ArchiveExit;
