const config = {
  graphLabels: [
    '0%',
    '1%-10%',
    '11%-20%',
    '21%-30%',
    '31%-40%',
    '41%-50%',
    '51%-60%',
    '61%-70%',
    '71%-80%',
    '81%-90%',
    '91%-100%',
  ],
};

export default config;
