import axios from 'axios';

import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_COMBINED_DATABOXES = createAsyncAction(createAction('FETCH_COMBINED_DATABOXES'));

const fetchCombinedDataboxes = (dispatch, id, params) => {
  dispatch(FETCH_COMBINED_DATABOXES.pending());

  const token = isLogged();
  const url = ENDPOINTS.combinedDataboxes.replace(':id', id);
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  axios
    .get(`${url}/?${params}`, config)
    .then(({ data }) => dispatch(FETCH_COMBINED_DATABOXES.success(data)))
    .catch((err) => dispatch(FETCH_COMBINED_DATABOXES.failure({ reportError: err.response })));
};

export default fetchCombinedDataboxes;
