/* eslint-disable camelcase */
import { roundToFloat } from 'utils';
import { reportPageTypes } from 'modules/spaceLook/constants';

const MeetsAndBreakLargeUse = ({
  textData: { percent_total_1_to_4_people_meet, percent_count_1_to_4_people_meet },
  pageType,
}) => {
  const isMeet = pageType === reportPageTypes.meetings;
  const value = percent_total_1_to_4_people_meet || percent_count_1_to_4_people_meet;

  return `Larger ${isMeet ? 'meetings' : 'breakout'} accounted for the remaining ${roundToFloat(100 - value)}% of use.`;
};

export default MeetsAndBreakLargeUse;
