import { pick } from 'lodash';

const keysToPick = ['databoxes', 'pieData', 'dailyHourlyGraphData'];

const getMeetingsUsageOverTimeData = ({ report: { meetings } }) => ({
  dataRequestStatuses: pick(meetings.requestStatuses, keysToPick),
  data: pick(meetings.data, keysToPick),
  shouldRefetchData: pick(meetings.shouldRefetchData, keysToPick),
});

export default getMeetingsUsageOverTimeData;
