import { isEmpty } from 'lodash';

const DesksPeakUtilization = ({ graphData }) => {
  if (isEmpty(graphData)) return null;
  const highest = graphData.reduce((max, current) => {
    if (max.percent_max_utilization < current.percent_max_utilization) return current;

    return max;
  });

  return `Peak utilisation occured on ${highest.day_of_week} which was measured as ${highest.percent_max_utilization}%.`;
};

export default DesksPeakUtilization;
