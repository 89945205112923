import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { findEmpty } from './utils';
import UploadCsvModal from './UploadCsvModal';

const UploadCsvModalContainer = ({ _handleClose, ...props }) => {
  const { reportId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filesData, setFilesData] = useState([]);
  // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef

  useEffect(() => {
    let isCancelled = false;
    const url = ENDPOINTS.workLook.reportTableAlias.replace(':reportId', reportId);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    API.get(url, config)
      .then((data) => {
        if (!isCancelled) {
          setFilesData(data.data);
        }
      })
      .catch((error) => {
        return error;
      });

    return () => {
      isCancelled = true;
    };
  }, [reportId]);

  const formik = useFormik({
    initialValues: {
      files: [],
    },
    onSubmit: (values) => {
      const filterdValues = findEmpty(values.files, filesData);
      if (filterdValues.length) {
        return filterdValues.forEach(({ id }) => {
          formik.setFieldError(id.toString(), 'Please select a file');
        });
      }

      setIsSubmitting(true);
      const url = ENDPOINTS.workLook.createReportOld.replace(':reportId', reportId);
      const data = new FormData();
      values.files.forEach(({ id, file }) => {
        data.append(id.toString(), file);
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      return API.post(url, data, config)
        .then(() => {
          setIsSubmitting(false);
          _handleClose();
        })
        .catch(({ response }) => {
          setIsSubmitting(false);

          Object.keys(response.data).forEach((id) => {
            formik.setFieldError(id.toString(), response.data[id]);
          });
        });
    },
  });

  return (
    <UploadCsvModal
      formik={formik}
      isSubmitting={isSubmitting}
      _handleClose={_handleClose}
      data={filesData}
      {...props}
    />
  );
};

export default UploadCsvModalContainer;
