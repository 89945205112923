import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_FLOORS_LIVE_OCC = createAsyncAction(createAction('FETCH_FLOORS_LIVE_OCC'));

const fetchFloorsLiveOcc = (dispatch, abortController, { companyId, query }) => {
  dispatch(FETCH_FLOORS_LIVE_OCC.pending());

  const url = ENDPOINTS.liveLook.floorsLiveOcc.replace(':companyId', companyId).concat(query ? `?${query}` : '');

  API.get(url, { signal: abortController.signal })
    .then(({ data }) => {
      dispatch(FETCH_FLOORS_LIVE_OCC.success(data));
    })
    .catch(() => {
      if (!abortController.signal.aborted) {
        dispatch(FETCH_FLOORS_LIVE_OCC.failure());
      }
    });
};

export default fetchFloorsLiveOcc;
