import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HIDE_MODAL } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';

import * as modalComponentsAL from 'modules/modalsSystem/components/modalsAL';
import * as modalComponentsSettings from 'components/Modals/UserSettingsModal';
import * as modalComponentsCompanySettings from 'components/Modals/CompanySettingsModal';
import * as modalComponentsWL from 'modules/modalsSystem/components/modalsWL';
import * as modalComponentsLL from 'modules/modalsSystem/components/modalsLL';
import Dialog from '../Dialog';
import Error from '../Error';

const modalMap = {
  [modalCodes.DIALOG]: Dialog,
  [modalCodes.ERROR]: Error,
  [modalCodes.WL_FILTER_MODAL]: modalComponentsWL.FilterModal,
  [modalCodes.WL_MAP_MODAL]: modalComponentsWL.MapModal,
  [modalCodes.WL_EDIT_GRAPH_MODAL]: modalComponentsWL.EditGraphModal,
  [modalCodes.WL_MODIFIERS_GRAPH_MODAL]: modalComponentsWL.ModifiersGraphModal,
  [modalCodes.WL_CREATE_PAGE_MODAL]: modalComponentsWL.CreateNewPageModal,
  [modalCodes.WL_EDIT_REPORTS_FIELDS]: modalComponentsWL.EditReportFieldsModal,
  [modalCodes.WL_EDIT_TEMPLATES]: modalComponentsWL.EditTemplatesModal,
  [modalCodes.WL_CREATE_SECTION_MODAL]: modalComponentsWL.CreateNewSectionModal,
  [modalCodes.WL_CREATE_TEMPLATE_MODAL]: modalComponentsWL.CreateTemplateModal,
  [modalCodes.WL_GRAPH_MODAL]: modalComponentsWL.GraphModal,
  [modalCodes.WL_MODAL_DETAILS]: modalComponentsWL.GraphModalDetail,
  [modalCodes.WL_CREATE_BLANK_REPORT]: modalComponentsWL.CreateBlankReport,
  [modalCodes.AL_FILTER_MODAL]: modalComponentsAL.FilterModal,
  [modalCodes.AL_ASSET_MNGMNT]: modalComponentsAL.AssetMngmnt,
  [modalCodes.AL_REQUEST_ORDER]: modalComponentsAL.RequestOrder,
  [modalCodes.AL_REQUEST_COMPLETE]: modalComponentsAL.RequestComplete,
  [modalCodes.AL_ARRIVAL_ORDER_UPLOAD]: modalComponentsAL.ArrivalOrderUpload,
  [modalCodes.LL_FILTER_MODAL]: modalComponentsLL.FilterModal,
  [modalCodes.USER_SETTINGS]: modalComponentsSettings.UserSettingsModal,
  [modalCodes.COMPANY_SETTIINGS]: modalComponentsCompanySettings.CompanySettingsModal,
  [modalCodes.CONFIRM_DELETION]: modalComponentsSettings.ConfirmDeletionModal,
};

function ModalPlace() {
  const dispatch = useDispatch();
  const onClose = useCallback(() => dispatch(HIDE_MODAL()), [dispatch]);

  const { modalCode, modalProps } = useSelector(({ modals }) => ({ ...modals }));

  if (!modalCode) return null;

  const ModalComponent = modalMap[modalCode];

  return <ModalComponent onClose={onClose} {...modalProps} />;
}

export default ModalPlace;
