import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_COMBINED_OCCUPANCY_VARIABLES = createAsyncAction(createAction('FETCH_COMBINED_OCCUPANCY_VARIABLES'));

const fetchCombinedOccupancyVariables = async (
  dispatch,
  isCanceled,
  { companyId, dateAfter, dateBefore, workingHours, scaleOption, query, archive },
) => {
  try {
    dispatch(FETCH_COMBINED_OCCUPANCY_VARIABLES.pending());
    const url = ENDPOINTS.liveLook.combinedOccupancyVariables
      .replace(':companyId', companyId)
      .concat(workingHours ? `?work_hours=True` : '')
      .concat(workingHours ? `&exclude_weekend=True` : '')
      .concat(dateAfter ? (workingHours ? `&date_after=${dateAfter}` : `?date_after=${dateAfter}`) : '')
      .concat(dateBefore ? `&date_before=${dateBefore}` : '')
      .concat(scaleOption ? `&date_scale=${scaleOption.toLowerCase()}` : '')
      .concat(query ? `&${query}` : '')
      .concat(archive ? `&archive_id=${archive}` : '');

    const { data } = await API.get(url);
    // .then(({ data }) => {
    if (isCanceled) return;
    dispatch(FETCH_COMBINED_OCCUPANCY_VARIABLES.success(data));
    // })
    // .catch(() => {
    //   if (isCanceled) return;
    //   dispatch(FETCH_COMBINED_OCCUPANCY_VARIABLES.failure());
    // });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default fetchCombinedOccupancyVariables;
