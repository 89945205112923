import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import { isEmpty } from 'lodash';
import { fieldValidations, dateTypes, isDateTime } from './config';

const EmptyTableCell = ({ emptyRow, name, fieldType, onChangeEmptyRow, errors, setErrors, classes }) => {
  const dateTime = isDateTime(fieldType);
  const checkForFile = fieldType.includes('file');
  const onChangeRow = (e) => {
    const { value } = e.target;
    if (!isEmpty(value)) {
      setErrors((state) => ({ ...state, [name]: '' }));
    }
    fieldValidations[fieldType](setErrors, name, value, fieldType);

    if (isEmpty(value)) setErrors((state) => ({ ...state, [name]: '* Required' }));

    onChangeEmptyRow(e, emptyRow, dateTime);
  };

  return (
    <TableCell style={{ minWidth: `${dateTime && '130px'}` }} align="left" className={classes.tableCell}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <If condition={!checkForFile}>
          <Input
            style={{ width: '100%' }}
            error={!!errors[name]}
            required
            inputProps={{ step: '1' }}
            type={dateTypes[fieldType]}
            placeholder={name.replace('_', ' ')}
            name={name}
            value={emptyRow[name]}
            onChange={(e) => onChangeRow(e)}
            className={classes.input}
          />
          {!!errors[name] && (
            <Typography variant="body2" color="error" component="span">
              {errors[name]}
            </Typography>
          )}
        </If>
      </div>
    </TableCell>
  );
};

export default EmptyTableCell;
