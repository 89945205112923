/* eslint-disable react/no-children-prop */
import React from 'react';
import { get } from 'lodash';

import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    padding: '75px 50px 40px',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    background: ({ sectionImage }) => (sectionImage ? `center / cover no-repeat url(${sectionImage})` : ''),
    backgroundColor: ({ backgroundColor }) => get(theme, `palette.${backgroundColor}`, 'unset'),
    color: ({ textColor }) => textColor || 'white',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  title: {
    margin: 'auto 0',
    color: 'inherit',
  },
  bottomTextContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  bottomText: {
    display: 'inline',
    fontStyle: 'italic',
    color: 'inherit',
  },
}));

const ReportSubTitlePage = ({
  firstLogo,
  secondLogo,
  title,
  bottomText,
  BottomTextIcon,
  textColor,
  sectionImage,
  backgroundColor = 'primary.dark',
}) => {
  const classes = useStyles({ textColor, backgroundColor, sectionImage });

  return (
    <div className={classes.container}>
      <div>
        <If condition={firstLogo}>
          <img src={firstLogo} height={45} className={classes.logo} alt="" />
        </If>
        <If condition={secondLogo}>
          <img src={secondLogo} height={45} className={classes.logo} alt="" />
        </If>
      </div>

      <Typography variant="h2" children={title} classes={{ root: classes.title }} />

      <div className={classes.bottomTextContainer}>
        <If condition={BottomTextIcon}>
          <BottomTextIcon fontSize="large" classes={{ root: classes.icon }} />
        </If>
        <Typography variant="h5" children={bottomText} classes={{ root: classes.bottomText }} />
      </div>
    </div>
  );
};

export default ReportSubTitlePage;
