import { createReducer } from '@reduxjs/toolkit';
import { requestStatuses } from 'modules/shared/constants';
import {
  FETCH_REPORTS,
  RESET_STATE,
  ADD_TO_BASKET,
  BASKET_TOGGLE_CHECK_ALL,
  BASKET_TOGGLE_CHECK_SINGLE,
  BASKET_DELETE_CHECKED,
} from 'modules/liveLook/actions';

const initState = {
  requestStatus: requestStatuses.default,
  reports: [],
  data: [],
};

export default createReducer(initState, {
  [FETCH_REPORTS.pending]: (state) => ({ ...state, requestStatus: requestStatuses.pending }),
  // eslint-disable-next-line no-unused-vars
  [FETCH_REPORTS.success]: (state, { payload }) => {
    return {
      ...state,
      requestStatus: requestStatuses.success,
      reports: [], // TODO
    };
  },
  [FETCH_REPORTS.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
    reports: initState.reports,
  }),

  [ADD_TO_BASKET]: (state, { payload }) => {
    const { id, type, sectionType, sectionOrder, category, data, typeOrder, workingHours } = payload;

    const newItem = {
      id,
      type,
      sectionType,
      sectionOrder,
      typeOrder,
      category,
      data,
      checked: false,
      workingHours,
    };

    return {
      ...state,
      data: [...state.data, newItem],
    };
  },

  [BASKET_TOGGLE_CHECK_ALL]: (state, { payload }) => {
    const newData = state.data.map((item) => {
      return {
        ...item,
        checked: payload,
      };
    });

    // TODO: resolve two returns
    return {
      ...state,
      data: newData,
    };

    // eslint-disable-next-line no-unreachable
    return {
      ...state,
      data: {
        ...state.data,
      },
    };
  },
  [BASKET_TOGGLE_CHECK_SINGLE]: (state, { payload }) => {
    const { reportId } = payload;

    const newData = state.data.map((item) => {
      if (item.id === reportId) {
        return {
          ...item,
          checked: !item.checked,
        };
      }

      return item;
    });

    return {
      ...state,
      data: newData,
    };
  },
  // eslint-disable-next-line no-unused-vars
  [BASKET_DELETE_CHECKED]: (state, { payload }) => {
    const newData = state.data.filter((item) => !item.checked);

    return {
      ...state,
      data: newData,
    };
    // TODO: resolve two returns
    // eslint-disable-next-line no-unreachable
    return {
      ...state,
      data: {
        ...state.data,
      },
    };
  },
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
