import { createReducer } from '@reduxjs/toolkit';

import {
  SET_TEXT_SUMMARY_BAR_CHART,
  SET_TEXT_SUMMARY_BAR_SINGLE,
  SET_TEXT_SUMMARY_BAR_MULTIPLE,
  SET_BAR_CHART_LEGENDS,
  RESET_TEXT_SUMMARY_BAR_CHART,
  RESET_TEXT_SUMMARY_BAR_SINGLE,
  RESET_TEXT_SUMMARY_BAR_MULTIPLE,
} from 'modules/workLook/actions';

const initState = {
  textSummaryBarChart: [],
  textSummaryBarSingle: [],
  textSummaryBarMultiple: [],
  textLegends: [],
};

export default createReducer(initState, {
  [SET_TEXT_SUMMARY_BAR_CHART]: (state, { payload }) => ({
    ...state,
    textSummaryBarChart: state.textSummaryBarChart.concat(payload),
  }),
  [RESET_TEXT_SUMMARY_BAR_CHART]: (state, { payload }) => ({
    ...state,
    textSummaryBarChart: [],
  }),
  [SET_BAR_CHART_LEGENDS]: (state, { payload }) => ({
    ...state,
    textLegends: payload,
  }),
  [SET_TEXT_SUMMARY_BAR_SINGLE]: (state, { payload }) => ({
    ...state,
    textSummaryBarSingle: state.textSummaryBarSingle.concat(payload),
  }),
  [RESET_TEXT_SUMMARY_BAR_SINGLE]: (state, { payload }) => ({
    ...state,
    textSummaryBarSingle: [],
  }),
  [SET_TEXT_SUMMARY_BAR_MULTIPLE]: (state, { payload }) => ({
    ...state,
    textSummaryBarMultiple: state.textSummaryBarMultiple.concat(payload),
  }),
  [RESET_TEXT_SUMMARY_BAR_MULTIPLE]: (state, { payload }) => ({
    ...state,
    textSummaryBarMultiple: [],
  }),
});
