/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography } from '@material-ui/core';
import { getUser } from 'modules/auth/selectors';
import CloseIcon from '@material-ui/icons/Close';
import theme from './userSettingsModalContainer.module.scss';
import { SETTINGS_TABS, TAB_NAMES, TABS_FORMS } from './userSettings.constants';
import PersonalInfoForm from '../components/PersonalInfo/PersonalInfo';
import CompanyInfoForm from '../components/CompanyInfo';
import UserListInfo from '../components/UserListInfo';

const UserSettingsModalContainer = React.forwardRef(({ onClose }, ref) => {
  const { user } = useSelector(getUser);
  const { role } = user;
  const [activeTab, setActiveTab] = useState(SETTINGS_TABS?.[role][0]);
  const props = { user, onClose };

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Typography variant="h5" className={theme.title}>
            User Settings
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={theme.tabsContainer}>
          <div className={theme.leftSection}>
            {SETTINGS_TABS[role].map((tab, id) => (
              <Typography
                key={id}
                onClick={() => onTabChange(tab)}
                className={theme.tabTitle}
                style={{ backgroundColor: activeTab === tab ? '#e9e8e8' : '' }}
                variant="h6"
              >
                {TAB_NAMES[tab]}
              </Typography>
            ))}
          </div>
          {/* <If condition={!isEmpty(activeTab)}> */}
          <div className={theme.verticalLine} />
          <div className={theme.rightSection}>
            <If condition={['personal_info', 'user_groups'].includes(activeTab)}>
              <PersonalInfoForm {...props} />
            </If>
            <If condition={['all_users'].includes(activeTab)}>
              <UserListInfo {...props} />
            </If>
            <If condition={['company_info'].includes(activeTab)}>
              <CompanyInfoForm {...props} />
            </If>
          </div>
          {/* </If> */}
        </div>
      </div>
    </div>
  );
});

export default UserSettingsModalContainer;
