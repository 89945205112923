/* eslint-disable react/no-children-prop */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
  Grid,
  Button,
  Checkbox,
  CircularProgress,
  Snackbar,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import Alert from '@material-ui/lab/Alert';
import { getReports } from 'modules/liveLook/selectors';
import { userRoles } from 'modules/auth/constants';
import { getUser } from 'modules/auth/selectors';
import { ENDPOINTS, requestStatuses } from 'modules/shared/constants';
import config from 'modules/liveLook/components/PdfReportsPageSelector/config';
import { BASKET_TOGGLE_CHECK_ALL, BASKET_DELETE_CHECKED } from 'modules/liveLook/actions';

import { TableOfContents } from 'modules/liveLook/components';
import { TextPlaceholder, CustomSnackbar } from 'modules/shared/components';
import * as TextFieldStorageManager from 'modules/shared/utils/TextFieldStorageManager';

import axios from 'axios';
import theme from './Current.module.scss';
import isLogged from '../../../../../../utils/isLogged';
import { getActiveCompany } from '../../../../../../modules/shared/selectors';

const HISTORY_URL = config.history.path;

const Current = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: companyId, name: companyName, logo: companyLogo } = useSelector(getActiveCompany);
  const {
    user: { role },
  } = useSelector(getUser);
  const { reportData } = useSelector(getReports);
  const isNotClient = [userRoles.admin, userRoles.partner].includes(role);

  const [createReportStatus, setCreateReportStatus] = useState(requestStatuses.default);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isStandard, setIsStandard] = useState(false);
  const [pdfReportName, setPdfReportName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [snackbarStatus, setSnackbarStatus] = useState(false);

  const empty = useMemo(() => !reportData.length, [reportData.length]);

  const isSomeChecked = useMemo(() => !empty && reportData.some((item) => item.checked), [empty, reportData]);
  const isAllChecked = useMemo(() => !empty && reportData.every((item) => item.checked), [empty, reportData]);

  const onPrivateChange = () => {
    setIsPrivate(!isPrivate);
  };

  const _onCheckAllClick = useCallback(
    ({ target: { checked } }) => dispatch(BASKET_TOGGLE_CHECK_ALL(checked)),
    [dispatch],
  );

  const _onDeleteClick = useCallback(() => dispatch(BASKET_DELETE_CHECKED()), [dispatch]);

  const token = isLogged();

  const _onCreatePdfClick = useCallback(() => {
    let canceled = false;
    setCreateReportStatus(requestStatuses.pending);
    TextFieldStorageManager.removeAllItems();

    const newData = _.cloneDeep(reportData);
    // const filtered = newData.filter(item => item.checked)

    const PostData = JSON.stringify({ data: newData });
    const config = {
      headers: { Authorization: `Token ${token}` },
    };

    setPdfReportName(inputValue);
    setSnackbarStatus(true);

    axios
      .post(
        ENDPOINTS.liveLook.pdfCreate.replace(':companyId', companyId),
        {
          pdf_data: PostData,
          name: inputValue,
          companyLogo,
          companyName,
          private: isPrivate,
          standard: isStandard,
        },
        config,
      )
      .then(() => {
        if (canceled) return;
        history.push(HISTORY_URL);
      })
      .catch(() => {
        setSnackbarStatus(false);
        if (canceled) return;
        setCreateReportStatus(requestStatuses.failure);
      });

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
  }, [reportData, token, inputValue, companyId, companyLogo, companyName, isPrivate, isStandard, history]);

  const _onInputChange = useCallback(({ target: { value } }) => {
    setInputValue(value);
  }, []);

  const onStandardChange = () => {
    setIsStandard(!isStandard);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={theme.header}>
        <Button
          className={theme.deleteBtn}
          disabled={empty || !isSomeChecked}
          startIcon={<DeleteIcon />}
          children="Delete"
          onClick={_onDeleteClick}
        />
        <Button
          disabled={empty || createReportStatus === requestStatuses.pending || !inputValue}
          // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
          startIcon={createReportStatus === requestStatuses.pending ? <CircularProgress size={14} /> : <GetAppIcon />}
          children="Create PDF"
          onClick={_onCreatePdfClick}
        />
        {!empty && (
          <Checkbox
            checked={isAllChecked}
            className={theme.selectAll}
            color="primary"
            disableRipple
            style={{ padding: 0 }}
            onChange={_onCheckAllClick}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              error={!inputValue}
              required
              type="input"
              placeholder="Enter PDF report name"
              onChange={_onInputChange}
              value={inputValue}
              inputProps={{ maxLength: 30 }}
              style={{ width: 300 }}
            />
            {!inputValue && (
              <Typography color="error" style={{ fontSize: '12px' }} variant="standard">
                *Required
              </Typography>
            )}
          </div>
          <If condition={isNotClient}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '10px' }}>
              <Checkbox
                checked={isStandard}
                disabled={isPrivate}
                color="primary"
                disableRipple
                style={{ padding: 0 }}
                onChange={onStandardChange}
              />
              <Grid style={{ marginLeft: 10 }} item>
                Standard
              </Grid>
            </div>
          </If>
          <FormGroup>
            <Grid component="label" container alignItems="center" spacing={2} style={{ marginLeft: '20px' }}>
              <Grid item style={{ color: isStandard ? '#9e9e9e' : '#000000' }}>
                Public
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Switch checked={isPrivate} onChange={onPrivateChange} />}
                  label="Private"
                  checked={isPrivate}
                  disabled={isStandard}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Choose>
          <When condition={!empty}>
            <TableOfContents />
          </When>
          <Otherwise>
            <TextPlaceholder text="The basket is empty" absolute />
          </Otherwise>
        </Choose>
      </Grid>
      <Snackbar
        open={createReportStatus === requestStatuses.failure}
        onClose={() => setCreateReportStatus(requestStatuses.default)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity="error" variant="filled">
          Something went wrong. Please try again later.
        </Alert>
      </Snackbar>
      <CustomSnackbar
        open={Boolean(pdfReportName)}
        severity="success"
        onClose={() => setSnackbarStatus(false)}
        autoHideDuration={4000}
        label={`Report successfully saved as: ${pdfReportName}`}
      />
    </Grid>
  );
};

export default Current;
