import React from 'react';
import { Box, Button, IconButton, ThemeProvider, Typography, Modal } from '@material-ui/core';
import { btnsTheme } from 'modules/modalsSystem/components/modalsWL/options';
import theme from './Error.module.scss';

const Error = ({ onClose, title = 'Error', text }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #ff0000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
        <div className={theme.btnWrapper}>
          <ThemeProvider theme={btnsTheme}>
            <Button color="secondary" variant="outlined" onClick={onClose}>
              Okay
            </Button>
          </ThemeProvider>
        </div>
      </Box>
    </Modal>
  );
};

export default Error;
