/* eslint-disable func-names */
import { Chart } from 'react-chartjs-2';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        ticks: {
          minRotation: 90,
        },
      },
    ],
    yAxes: [
      {
        display: false,
      },
    ],
  },
  // No tooltip
  events: [],
  // Add percent above occupied bar
  animation: {
    onComplete() {
      const chartInstance = this.chart;
      const { ctx } = chartInstance;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.verticalAlign = 'center';
      ctx.font = '9px Roboto';

      const { datasets } = this.data;

      Chart.helpers.each(
        datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);

          Chart.helpers.each(
            meta.data.forEach(function (bar, index) {
              if (dataset.label !== 'Occupied') return;
              const data = dataset.data[index];
              if (data === 0) return;
              ctx.fillStyle = '#000';
              const label = `${Math.round(data)}%`;
              const yCoef = data < 91 ? -10 : 10;
              ctx.fillText(label, bar._model.x, bar._model.y + yCoef);
            }),
            this,
          );
        }),
        this,
      );
    },
  },
};

export default options;
