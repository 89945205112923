/* eslint-disable camelcase */
import transformTableObject from './transformTableObject';

const tableDataTransform = (data) => {
  return data.map((building) => {
    const {
      property_name,
      location_data: { region, city },
      calc_data: {
        limiting_capacity,
        current_allocation,
        current_spare_capacity,
        current_sharing_ratio,
        peak_occup_percent,
        peak_util_percent,
        space_per_desk,
        planned_space_per_head,
        actual_space_per_head,
        potential_capacity,
        potential_spare_capacity,
      },
      type,
      area,
      floors,
      desks,
      nominal_capacity,
    } = building;

    const obj = {
      propertyName: property_name,
      region,
      city,
      propertyType: type,
      size: area,
      floors,
      desks,
      limitingCapacity: limiting_capacity,
      nominalCapacity: nominal_capacity,
      currentAllocation: current_allocation,
      currentSpareCapacity: current_spare_capacity,
      currentSharingRatio: current_sharing_ratio,
      peakOccupancy: peak_occup_percent,
      peakUtilisation: peak_util_percent,
      spacePerDesk: space_per_desk,
      plannedSpacePerHead: planned_space_per_head,
      actualSpacePerHead: actual_space_per_head,
      potentialCapacity: potential_capacity,
      potentialSpareCapacity: potential_spare_capacity,
    };

    return transformTableObject(obj);
  });
};

export default tableDataTransform;
