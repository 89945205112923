import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/shared/components';
import { VerticalBarGraph, ReportDataCards } from 'modules/liveLook/components';
import { isEmpty } from 'lodash';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';

import { GRAPH_TYPES, pageTypes } from 'modules/liveLook/constants';
import styles from '../../LLPrintablePdfPage.module.scss';
import renderFilters from '../../utils/activeFiltersPdf';
import config from './DoorsDaily.config';

const DoorsDaily = ({
  data,
  scaleValues,
  databoxes,
  editableTextReport,
  returnPercentageSwitcher,
  activeFilters,
  secondSwitcher,
  returnWeekSwitcher,
}) => {
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));

  return (
    <Grid style={{ paddingTop: '10px', paddingLeft: '17px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader pdfMode title="Occupancy" />
        </Grid>
      </Grid>

      {!shouldRenderFilters && renderFilters(activeFilters)}
      <div className={styles.blockContainer}>
        <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.occupancy} />
      </div>
      <Grid item xs={12}>
        <VerticalBarGraph
          secondSwitcher={secondSwitcher}
          onSwitcherChange={() => {}}
          title={returnWeekSwitcher ? 'Weekly Comparisons' : 'Daily Comparisons'}
          data={data}
          returnWeekSwitcher={returnWeekSwitcher}
          scaleValues={scaleValues}
          config={config}
          switcher={returnPercentageSwitcher}
          pdfCustomHeader
          returnPercentageSwitcher={returnPercentageSwitcher}
          isLiveLook
          isWeekGraph={returnWeekSwitcher}
        />
      </Grid>
      <PDFTextEditorContainer
        hasFilters={shouldRenderFilters}
        graphType={GRAPH_TYPES.VERTICAL}
        data={[]}
        onSave={() => {}}
        text={editableTextReport}
      />
    </Grid>
  );
};

export default DoorsDaily;
