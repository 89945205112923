const options = () => {
  return {
    layout: {
      padding: {
        left: -10,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
          ticks: {
            minRotation: 90,
          },
        },
      ],
      yAxes: [
        {
          ticks: { min: 0, max: 100, display: false },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: '#FFF',
      titleFontColor: '#000',
      titleFontSize: 11,
      bodyFontColor: '#000',
      bodyFontSize: 11,
      displayColors: false,
    },
  };
};

export default options;
