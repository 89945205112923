/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal } from '@material-ui/core';

import { APPLY_FILTERS } from 'modules/liveLook/actions';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { pageTypes } from 'modules/liveLook/constants';

// eslint-disable-next-line import/no-cycle
import { FilterModalBase } from 'modules/modalsSystem/components';

const FilterModal = (props) => {
  const { filterField, onClose } = props;
  const dispatch = useDispatch();

  const [
    initFilters,
    activeFilters,
    doorSensorInitFilters,
    activeDoorSensorFilters,
    initDeskFilters,
    activeDeskFilters,
    initMeetFilters,
    activeMeetFilters,
    initBreakFilters,
    activeBreakFilters,
    initCombinedFilters,
    activeCombinedFilters,
  ] = useLiveLookSelector([
    'filters.initFilters',
    'filters.activeFilters',
    'doorSensorFilters.doorSensorInitFilters',
    'doorSensorFilters.activeDoorSensorFilters',
    'deskFilters.initDeskFilters',
    'deskFilters.activeDeskFilters',
    'meetFilters.initMeetFilters',
    'meetFilters.activeMeetFilters',
    'breakFilters.initBreakFilters',
    'breakFilters.activeBreakFilters',
    'combinedFilters.initCombinedFilters',
    'combinedFilters.activeCombinedFilters',
  ]);

  const initiateInitFilters = (pageType) => {
    if (pageType === pageTypes.desks) {
      return initDeskFilters.find(({ field }) => field === filterField).values;
    }

    if (pageType === pageTypes.occupancy) {
      return doorSensorInitFilters.find(({ field }) => field === filterField).values;
    }

    if (pageType === pageTypes.meetings) {
      return initMeetFilters.find(({ field }) => field === filterField).values;
    }

    if (pageType === pageTypes.breakout) {
      return initBreakFilters.find(({ field }) => field === filterField).values;
    }

    if (pageType === pageTypes.combinedOccupancy) {
      return initCombinedFilters.find(({ field }) => field === filterField).values;
    }

    return initFilters.find(({ field }) => field === filterField).values;
  };

  const initiateActiveFilters = (pageType) => {
    if (pageType === pageTypes.desks) {
      return activeFilters.find(({ field }) => field === filterField).values;
    }

    if (pageType === pageTypes.occupancy) {
      return activeDoorSensorFilters.find(({ field }) => field === filterField).values;
    }

    if (pageType === pageTypes.meetings) {
      return activeMeetFilters.find(({ field }) => field === filterField).values;
    }

    if (pageType === pageTypes.breakout) {
      return activeBreakFilters.find(({ field }) => field === filterField).values;
    }

    if (pageType === pageTypes.combinedOccupancy) {
      return activeCombinedFilters.find(({ field }) => field === filterField).values;
    }

    return activeDeskFilters.find(({ field }) => field === filterField).values;
  };

  const initValues = initiateInitFilters(props.pageType);
  const activeValues = initiateActiveFilters(props.pageType);

  const [selectedValues, setSelectedValues] = useState(activeValues);
  const isAllSelected = initValues.every((item) =>
    typeof item === 'object' ? selectedValues.includes(item.value) : selectedValues.includes(item),
  );

  const onSelectAllClick = () =>
    setSelectedValues(
      isAllSelected ? [] : typeof initValues[0] === 'object' ? initValues.map((i) => i.value) : initValues,
    );

  const onValueClick = (value) =>
    setSelectedValues(
      selectedValues.includes(value) ? selectedValues.filter((item) => item !== value) : [...selectedValues, value],
    );

  const onApplyClick = () => {
    dispatch(
      APPLY_FILTERS({
        field: filterField,
        values: selectedValues,
      }),
    );
    onClose();
  };

  return (
    <Modal open onClose={onClose}>
      <FilterModalBase
        isAllSelected={isAllSelected}
        onSelectAllClick={onSelectAllClick}
        title={filterField.charAt(0).toUpperCase() + filterField.slice(1)}
        onClose={onClose}
        initValues={initValues}
        isValueChecked={(value) => selectedValues.includes(value)}
        onValueClick={onValueClick}
        onApplyClick={onApplyClick}
      />
    </Modal>
  );
};

FilterModal.propTypes = {
  filterField: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FilterModal;
