import React from 'react';
import { TableCell } from '@material-ui/core';

const COLUMNS_MAP = {
  product__name: 'Product Name',
  total: 'Total',
  condition: 'Condition',
};

function BasketCellHeader({ columnKey, height, width }) {
  const label = COLUMNS_MAP[columnKey] || columnKey;

  return (
    <TableCell
      size="small"
      component="div"
      variant="head"
      title={label}
      style={{
        height,
        width,
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {label}
    </TableCell>
  );
}

export default BasketCellHeader;
