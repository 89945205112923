/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';
import { useFormik } from 'formik';
import theme from './editCompanyContainer.module.scss';

const SECTOR_NAMES = [
  { value: 'agriculture', label: 'Food, Agriculture, Forestry and Fishing' },
  { value: 'manufacturing', label: 'Manufacturing & Production' },
  { value: 'construction', label: 'Construction' },
  { value: 'retail', label: 'Wholesale and retail' },
  { value: 'transport', label: 'Transport, postal and storage' },
  { value: 'information', label: 'Information and communication' },
  { value: 'finance', label: 'Finance and Insurance' },
  { value: 'property', label: 'Property & Accommodation' },
  { value: 'prof_services', label: 'Professional, Scientific and Technical services' },
  { value: 'aerospace', label: 'Aerospace & Defence' },
  { value: 'education', label: 'Education' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'media', label: 'Media, Arts and Recreation' },
  { value: 'local_gov', label: 'Local Government' },
  { value: 'central_gov', label: 'Central Government & NDPBs' },
  { value: 'other', label: 'Other' },
];

const SECTOR_SHORT_NAMES = [
  { value: 'agriculture', label: 'Food & Agriculture' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'construction', label: 'Construction' },
  { value: 'retail', label: 'Retail' },
  { value: 'transport', label: 'Transport' },
  { value: 'information', label: 'Information' },
  { value: 'finance', label: 'Financial' },
  { value: 'property', label: 'Property' },
  { value: 'prof_services', label: 'Professional services' },
  { value: 'aerospace', label: 'Aerospace' },
  { value: 'education', label: 'Education' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'media', label: 'Media' },
  { value: 'local_gov', label: 'Local Government' },
  { value: 'central_gov', label: 'Central Government' },
  { value: 'other', label: 'Other' },
];

const EditCompanyContainer = React.forwardRef(({ onClose, companyId }) => {
  const [closingImg, setClosingImg] = useState('');
  const [sectionImg, setSectionImg] = useState('');
  const [reportLogoImg, setReportLogoImg] = useState('');
  const [reportLogoColorImg, setReportLogoColorImg] = useState('');
  const [dateFields, setDateFields] = useState({
    from: '',
    to: '',
  });
  const inputRefCloImg = useRef();
  const inputRefSecImg = useRef();
  const inputReportLogoImg = useRef();
  const inputReportLogoColImg = useRef();
  const initialValuesCompanyData = {
    name: '',
    sector_name: '',
    sector_short_name: '',
    partner_company: '',
    portal_sections: [],
    landing_start_page: '',
    report_logo: '',
    report_logo_color: '',
    report_section_image: '',
    report_closing_image: '',
    working_hours_from: '',
    working_hours_to: '',
    ll_key_date_from: '',
    ll_key_date_to: '',
  };

  const dispatch = useDispatch();

  const formatDate = (dateProp) => {
    if (dateProp) {
      const date = new Date(dateProp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return '';
  };

  const formik = useFormik({
    initialValues: initialValuesCompanyData,
    onSubmit: (values) => {
      // setIsLoading(true);
      const result = Object.assign(
        values,
        {
          ll_key_date_from: formatDate(dateFields.from),
          ll_key_date_to: formatDate(dateFields.to),
          report_logo: reportLogoImg,
          report_logo_color: reportLogoColorImg,
          report_section_image: sectionImg,
          report_closing_image: closingImg,
        },
        {
          ...(values.working_hours_from || { working_hours_from: '' }),
          ...(values.working_hours_to || { working_hours_to: '' }),
        },
      );
      typeof reportLogoImg === 'string' && reportLogoImg ? delete result.report_logo : null;
      typeof reportLogoColorImg === 'string' && reportLogoColorImg ? delete result.report_logo_color : null;
      typeof sectionImg === 'string' && sectionImg ? delete result.report_section_image : null;
      typeof closingImg === 'string' && closingImg ? delete result.report_closing_image : null;

      const data = new FormData();
      Object.entries(result).map(([key, value]) => {
        return data.append(key, value);
      });
      const url = ENDPOINTS.auth.companyData.replace(':companyId', companyId);
      const config = {
        headers: {
          // Authorization: `Token ${isLogged()}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      API.put(url, data, config).then(() => window.location.reload(false));
    },
  });

  useEffect(() => {
    const url = ENDPOINTS.auth.companyData.replace(':companyId', companyId);
    API.get(url).then((result) => {
      formik.setValues({
        ...result.data,
        // ll_key_date_from: new Date(),
        // ll_key_date_to: new Date(),
      });
      setClosingImg(result.data.report_closing_image ? result.data.report_closing_image : '');
      setSectionImg(result.data.report_section_image ? result.data.report_section_image : '');
      setReportLogoImg(result.data.report_logo ? result.data.report_logo : '');
      setReportLogoColorImg(result.data.report_logo_color ? result.data.report_logo_color : '');
      setDateFields({ from: result.data.ll_key_date_from, to: result.data.ll_key_date_to });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.header}>
        <Typography variant="h5" className={theme.title}>
          Edit Company
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={theme.modalBody}>
        <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            placeholder="Enter company name"
            value={formik.values.name}
            onChange={formik.handleChange}
            variant="outlined"
          />
          <FormControl fullWidth>
            <InputLabel id="sector_name">Sector name</InputLabel>
            <Select
              labelId="sector_name"
              id="sector_name"
              name="sector_name"
              label="Sector name"
              value={formik.values?.sector_name}
              onChange={formik.handleChange}
            >
              {SECTOR_NAMES.map(({ value, label }, index) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="sector_short_name">Sector short name</InputLabel>
            <Select
              labelId="sector_short_name"
              id="sector_short_name"
              name="sector_short_name"
              label="Sector short name"
              value={formik.values?.sector_short_name}
              onChange={formik.handleChange}
            >
              {SECTOR_SHORT_NAMES.map(({ value, label }, index) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            id="partner_company"
            disabled
            name="partner_company"
            label="Partner company"
            value={formik.values.partner_company || '-'}
            onChange={formik.handleChange}
            variant="outlined"
          />
          <TextField
            fullWidth
            type="number"
            InputProps={{
              inputProps: {
                max: 24,
                min: 0,
              },
            }}
            id="working_hours_from"
            name="working_hours_from"
            label="Working hours from"
            placeholder="Enter a working hours from"
            value={formik.values.working_hours_from}
            onChange={formik.handleChange}
            variant="outlined"
          />
          <TextField
            fullWidth
            type="number"
            InputProps={{
              inputProps: {
                max: 24,
                min: 0,
              },
            }}
            id="working_hours_to"
            name="working_hours_to"
            label="Working hours to"
            placeholder="Enter a working hours to"
            value={formik.values.working_hours_to}
            onChange={formik.handleChange}
            variant="outlined"
          />
          <FormControl fullWidth>
            <InputLabel id="landing_start_page">Landing start page</InputLabel>
            <Select
              labelId="landing_start_page"
              id="landing_start_page"
              name="landing_start_page"
              label="Landing start page"
              value={formik.values?.landing_start_page}
              onChange={formik.handleChange}
            >
              {formik.values.portal_sections.map((sectionName, index) => (
                <MenuItem key={index} value={sectionName}>
                  {sectionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="LL key date from"
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              value={dayjs(formik?.values?.ll_key_date_from)}
              onChange={(newValue) => setDateFields((prev) => ({ ...prev, from: newValue }))}
            />
            <DatePicker
              label="LL key date to"
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              value={dayjs(formik?.values?.ll_key_date_to)}
              onChange={(newValue) => setDateFields((prev) => ({ ...prev, to: newValue }))}
            />
          </LocalizationProvider>
          <Box>
            {reportLogoImg && (
              <Box className={theme.imageContainer}>
                <img
                  src={typeof reportLogoImg === 'string' ? reportLogoImg : URL.createObjectURL(reportLogoImg)}
                  alt=""
                />
              </Box>
            )}
            <Button fullWidth component="label" color="primary" variant="outlined">
              Report Logo
              <input
                type="file"
                id="report_logo"
                hidden
                name="report_logo"
                accept="image/*"
                multiple={false}
                onChange={() => {
                  setReportLogoImg(inputReportLogoImg.current.files[0]);
                }}
                ref={inputReportLogoImg}
              />
            </Button>
            <Typography variant="body2">{reportLogoImg?.name}</Typography>
          </Box>
          <Box>
            {reportLogoColorImg && (
              <Box className={theme.imageContainer}>
                <img
                  src={
                    typeof reportLogoColorImg === 'string'
                      ? reportLogoColorImg
                      : URL.createObjectURL(reportLogoColorImg)
                  }
                  alt=""
                />
              </Box>
            )}
            <Button fullWidth component="label" color="primary" variant="outlined">
              Report Logo Color
              <input
                type="file"
                id="report_logo_color"
                hidden
                name="report_logo_color"
                accept="image/*"
                multiple={false}
                onChange={() => setReportLogoColorImg(inputReportLogoColImg.current.files[0])}
                ref={inputReportLogoColImg}
              />
            </Button>
            <Typography variant="body2">{reportLogoColorImg?.name}</Typography>
          </Box>
          <Box>
            {sectionImg && (
              <Box className={theme.imageContainer}>
                <img src={typeof sectionImg === 'string' ? sectionImg : URL.createObjectURL(sectionImg)} alt="" />
              </Box>
            )}
            <Button fullWidth component="label" color="primary" variant="outlined">
              Section Image
              <input
                type="file"
                id="section_image"
                hidden
                name="section_image"
                accept="image/*"
                multiple={false}
                onChange={() => setSectionImg(inputRefSecImg.current.files[0])}
                ref={inputRefSecImg}
              />
            </Button>
            <Typography variant="body2">{sectionImg?.name}</Typography>
          </Box>
          <Box>
            {closingImg && (
              <Box className={theme.imageContainer}>
                <img src={typeof closingImg === 'string' ? closingImg : URL.createObjectURL(closingImg)} alt="" />
              </Box>
            )}
            <Button fullWidth component="label" color="primary" variant="outlined">
              Closing Image
              <input
                type="file"
                id="closing_image"
                hidden
                name="closing_image"
                accept="image/*"
                multiple={false}
                onChange={() => setClosingImg(inputRefCloImg.current.files[0])}
                ref={inputRefCloImg}
              />
            </Button>
            <Typography variant="body2">{closingImg?.name}</Typography>
          </Box>
          {/* <DatePicker title="ll_key_date_from" value={formik.values.ll_key_date_from} /> */}
          <Button variant="outlined" color="primary" type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
});

export default EditCompanyContainer;
