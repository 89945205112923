import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import './TabsContent.scss';

const TabsContent = ({ children, activeIdx }) => {
  const renderedChild = children && children.find((child, idx) => activeIdx === idx);

  return (
    <section className="TabsContent">
      <div className="tabContent">
        <SwitchTransition>
          <CSSTransition key={activeIdx} appear mountOnEnter unmountOnExit classNames="tab-item" timeout={500}>
            {renderedChild || (
              <p className="TabsContent-message">Error: TabsNav and TabsContent components must be here</p>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </section>
  );
};

export default TabsContent;
