import React from 'react';

import './NABlock.scss';

const NABlock = ({ label, height }) => {
  const styles = height ? { height: `${height}px` } : {};

  return (
    <div style={styles} className="NABlock">
      <div className="n-a-block">
        <span className="n-a-block-label">{label}</span>
      </div>
    </div>
  );
};

NABlock.defaultProps = {
  label: 'No data avaliable',
};

export default NABlock;
