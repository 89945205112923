/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableCell, Checkbox } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { URLS } from 'pages/constants';
import { makeSearch } from 'modules/assetLook/utils';
import { ASSET_FIELDS, ASSET_STATUSES_LABEL } from 'modules/assetLook/constants';

import theme from './AssetListCellBody.module.scss';

function AssetListCellBody({
  data,
  rowIndex,
  columnKey,
  height,
  width,
  buildingId,
  categoryId,
  productId,
  basketAssets = [],
}) {
  const history = useHistory();

  let label;

  switch (columnKey) {
    case ASSET_FIELDS.id:
      label = data[rowIndex][ASSET_FIELDS.id];
      break;
    case ASSET_FIELDS.status:
      // eslint-disable-next-line no-case-declarations
      const status = data[rowIndex][ASSET_FIELDS.status];
      label = ASSET_STATUSES_LABEL[status];
      break;
    case ASSET_FIELDS.tags:
      // eslint-disable-next-line no-case-declarations
      const tags = data[rowIndex][ASSET_FIELDS.tags];
      label = tags && tags.map(({ name }) => name).join(', ');
      break;
    default:
      label = data[rowIndex].list_data[columnKey];
  }

  return (
    <TableCell
      size="small"
      component="div"
      variant="body"
      className={theme.container}
      style={{
        height,
        width,
      }}
    >
      <Choose>
        <When condition={columnKey === ASSET_FIELDS.checkbox}>
          <Checkbox
            checked={data[rowIndex][ASSET_FIELDS.checkbox]}
            size="small"
            color="primary"
            disableRipple
            className={theme.checkbox}
          />
        </When>
        <When condition={columnKey === ASSET_FIELDS.id}>
          <span
            title={label}
            className={theme.id}
            onClick={() => {
              history.push(
                URLS.assetLook.assetDetails +
                  makeSearch({
                    buildingId,
                    categoryId,
                    productId,
                    assetId: data[rowIndex][ASSET_FIELDS.id],
                  }),
                { isBuilding: true },
              );
            }}
          >
            {label}
          </span>
          <If condition={basketAssets.includes(data[rowIndex][ASSET_FIELDS.id])}>
            <ShoppingCartIcon
              color="primary"
              style={{
                fontSize: '1rem',
                marginLeft: '4px',
                verticalAlign: 'text-bottom',
              }}
            />
          </If>
        </When>
        <Otherwise>
          <span title={label}>{label}</span>
        </Otherwise>
      </Choose>
    </TableCell>
  );
}

export default AssetListCellBody;
