import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import URLS from 'pages/constants/urls';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID } from 'modules/assetLook/constants';
import { RESET_STATE, RESET_FILTERS_DATA } from 'modules/assetLook/actions';

import AssetLookLayout from 'pages/_layouts/AssetLookLayout';
import { RedirectHandler, PreFetchHandler } from 'modules/assetLook/components';

import ProductList from './ProductListPage';
import ProductSummary from './ProductSummaryPage';
import AssetList from './AssetListPage';
import AssetDetails from './AssetDetailsPage';
import PdfReports from './PdfReportsPage';
import OrdersPage from './OrdersPage';
import AllAssetsPage from './AllAsetsPage';

const DataRoute = ({ path, exact, component: Component, ...routeProps }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) => (
      <RedirectHandler>
        <Component {...props} {...routeProps} />
      </RedirectHandler>
    )}
  />
);

export default () => {
  const dispatch = useDispatch();

  const { buildingId, categoryId } = useLocationSearchParams([BUILDING_ID, CATEGORY_ID]);

  useEffect(() => {
    dispatch(RESET_FILTERS_DATA());
  }, [buildingId, categoryId, dispatch]);

  useEffect(() => () => dispatch(RESET_STATE()), [dispatch]);

  return (
    <AssetLookLayout>
      <PreFetchHandler>
        <Switch>
          <Route path={URLS.assetLook.orders} component={OrdersPage} />
          <Route path={URLS.assetLook.pdfReports} component={PdfReports} />
          <DataRoute exact path={URLS.assetLook.productList} component={ProductList} />
          <DataRoute exact path={URLS.assetLook.allAssets} component={AllAssetsPage} />
          <DataRoute exact path={URLS.assetLook.productSummary} component={ProductSummary} />
          <DataRoute exact path={URLS.assetLook.assetList} component={AssetList} />
          <DataRoute exact path={URLS.assetLook.assetDetails} component={AssetDetails} />

          <Redirect to={URLS.locationLook.locationSummary} />
        </Switch>
      </PreFetchHandler>
    </AssetLookLayout>
  );
};
