import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Typography } from '@material-ui/core';

import { getReportInfo } from 'modules/spaceLook/selectors';

import buildings from 'assets/images/buildings.png';
import buildingsBig from 'assets/images/buildings1-5x.png';

import theme from './HeatmapSubTitle.module.scss';

const HeatmapSubTitle = () => {
  const { subTitleImage, subTitleTextColor } = useSelector(getReportInfo);

  const imageSrc = subTitleImage || (window.devicePixelRatio === 2 ? buildings : buildingsBig);

  return (
    <div
      className={cn(theme.container, 'pdfPage')}
      style={{ backgroundImage: `url(${imageSrc})`, color: subTitleTextColor }}
    >
      <Typography variant="h3" className={theme.subTitle}>
        Heatmaps
      </Typography>
    </div>
  );
};

export default HeatmapSubTitle;
