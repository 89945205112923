import { useReducer } from 'react';

import { CHANGE_SORT_ORDER } from 'actions';
import { SWITCH_GRAPH_DATA_TYPE } from 'actions/switchGraphDataType';
import { TOGGLE_MAP } from 'actions/toggleMap';
import { INVOKE_MODAL } from 'actions/invokeModal';
import {
  SET_SUMMARY_PAGE_DATA,
  SWITCH_GRAPH_TYPE,
  APPLY_FILTER,
  RESET_FILTER,
} from 'components/Pages/SummaryPage/actions';
import { requestStatuses } from 'modules/shared/constants';
import { graphTypes, graphDataTypes, graphSortOptions } from 'components/Pages/SummaryPage/constants';

const initState = {
  showMap: true,
  showModal: false,
  graphType: graphTypes.property,
  graphDataType: graphDataTypes.deskCount,
  sortOrder: graphSortOptions.desc,
  requestStatus: requestStatuses.default,
  data: {},
  appliedFilter: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_MAP:
      return {
        ...state,
        showMap: !state.showMap,
      };
    case INVOKE_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
      };

    case SWITCH_GRAPH_DATA_TYPE:
      return {
        ...state,
        graphDataType: payload,
      };
    case SWITCH_GRAPH_TYPE:
      return {
        ...state,
        graphType: payload,
      };
    case CHANGE_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };

    case SET_SUMMARY_PAGE_DATA.pending:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case SET_SUMMARY_PAGE_DATA.success:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload,
      };
    case SET_SUMMARY_PAGE_DATA.failure:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };

    case APPLY_FILTER:
      return {
        ...state,
        appliedFilter: payload,
        showModal: false,
      };

    case RESET_FILTER:
      return {
        ...state,
        appliedFilter: '',
      };

    default:
      return state;
  }
};

const useSummaryPageReducer = () => useReducer(reducer, initState);

export default useSummaryPageReducer;
