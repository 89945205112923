import React from 'react';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';

const DatePicker = ({ formik }) => (
  <FormControl style={{ width: '100%' }}>
    <TextField
      id="date"
      type="date"
      label="Pickup/delivery date *"
      defaultValue={formik.values.date}
      onChange={formik.handleChange}
      inputProps={{
        min: new Date().toISOString().substring(0, 10),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        error: formik.touched.date && Boolean(formik.errors.date),
      }}
    />
    <FormHelperText
      error={formik.touched.date && Boolean(formik.errors.date)}
      // eslint-disable-next-line react/no-children-prop
      children={formik.touched.date && formik.errors.date}
    />
  </FormControl>
);

export default DatePicker;
