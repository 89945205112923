/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { usePdfContext } from 'modules/assetLook/hooks';
import { toRequestBody } from 'modules/assetLook/utils';
import { PAGE_TYPES, PRODUCT_FIELDS } from 'modules/assetLook/constants';

import BSLogoColor from 'assets/images/bsl-logo-mini-color.png';

import ProductSummary from './ProductSummary';

import config from '../config';

function ProductSummaryContainer({ buildingId, product }) {
  const {
    data: { pdf_data, company, partner_company },
    theme,
  } = usePdfContext();

  const [summary, setSummary] = useState(null);

  const filters = useMemo(
    () => toRequestBody(pdf_data.activeFilters, pdf_data.tags),
    [pdf_data.activeFilters, pdf_data.tags],
  );

  useEffect(() => {
    let isCanceled = false;

    const url = ENDPOINTS.assetLook.summary
      .replace(':companyId', company.id)
      .replace(':productId', product[PRODUCT_FIELDS.id]);

    const data = {
      group_by_fields: pdf_data.summaryFields,
      building_id: Number(buildingId),
      filters,
    };

    API.post(url, data)
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        if (isCanceled) return;
        setSummary(data.data);
      })
      .catch((err) => {
        if (isCanceled) return;
        // eslint-disable-next-line no-console
        console.error(err);
      });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [buildingId, company.id, filters, pdf_data.summaryFields, product]);

  return (
    <Choose>
      <When condition={summary}>
        {(() => {
          const key = pdf_data.activeFilters.length ? 'hasFilter' : 'noFilter';

          const chunkedData = [
            summary.slice(0, config.rowsPerPage[PAGE_TYPES.productSummary][key]),
            // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
            ..._.chunk(
              summary.slice(config.rowsPerPage[PAGE_TYPES.productSummary][key]),
              config.rowsPerPage[PAGE_TYPES.productSummary].nextPage,
            ),
          ];

          return chunkedData.map((data, i) => (
            <ProductSummary
              key={i}
              data={data}
              product={i < 1 ? product : false}
              activeFilters={i < 1 ? pdf_data.activeFilters : []}
              parentCompanyLogo={isEmpty(partner_company) ? BSLogoColor : partner_company.report_logo_color}
              companyLogo={company.report_logo_color}
              theme={theme}
            />
          ));
        })()}
      </When>
      <Otherwise>{null}</Otherwise>
    </Choose>
  );
}

export default ProductSummaryContainer;
