import React from 'react';

import { CATEGORY_ID } from 'modules/assetLook/constants';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';

import { PageHeader as SharedPageHeader } from 'modules/shared/components';

function PageHeader() {
  const [categories] = useAssetLookSelector(['common.categories']);
  const { categoryId } = useLocationSearchParams([CATEGORY_ID]);

  // eslint-disable-next-line camelcase
  const activeCategory = categories.all.find(({ category_id }) => category_id === Number(categoryId));

  return <SharedPageHeader title={categoryId ? activeCategory.category__name : 'All Products'} />;
}

export default PageHeader;
