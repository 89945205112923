/* eslint-disable prefer-spread */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useReducer } from 'react';

import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { dateToQuery, filtersToQuery, toSpaceComparisonDeskGraphData } from 'modules/liveLook/utils';
import {
  SET_GRAPH_SCALE,
  fetchDeskOverTime,
  fetchDeskPeakAndAvgUtilLive,
  SET_GRAPH_SWITCHER,
  SET_SECOND_GRAPH_SWITCHER,
  fetchDeskDataboxes,
  fetchGraphsText,
  saveGraphsText,
  fetchDeskVariables,
} from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import toDeskByWeekGraphData from 'modules/liveLook/utils/toDeskByWeekGraphData';
import SummaryKeyFindingsDesks from './SummaryKeyFindingsDesks';
import { setScaleValues } from '../utils';
import reducer, { initialState } from './_reducer';

function SummaryKeyFindingsDeskContainer() {
  const [companyId] = useAuthSelector(['currentCompany']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    scale,
    deskDataboxes,
    databoxRequestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
    deskPeakAndAvgRequestStatus,
    deskPeakAndAvgData,
    returnPercentageSwitcherDeskPeakAndAvg,
    textVariables,
    deskVariableRequestStatus,
  } = state;

  const requestStatuses = [
    databoxRequestStatus,
    deskPeakAndAvgRequestStatus,
    requestStatus,
    textReportRequestStatus,
    deskVariableRequestStatus,
  ];

  const [startDate, endDate, workingHours, activeDeskFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'deskFilters.activeDeskFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeDeskFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;

    fetchDeskOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      scaleOption: scale,
      query,
      archive,
    });

    fetchDeskPeakAndAvgUtilLive(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchDeskDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchDeskVariables(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'desk_key_data',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, dateAfter, dateBefore, workingHours, scale, query]);

  const databoxesSet = {
    deskDataboxes,
  };
  // eslint-disable-next-line no-shadow
  const mappedData = (data) => {
    const arrData = [];
    for (const key in data) {
      arrData.push(Object.assign(data[key], { name: key }));
    }
    return arrData;
  };

  const deskGraphPeakOccupancy = Math.max.apply(
    Math,
    mappedData(data).map((key) => key[returnPercentageSwitcher ? 'occupancy' : 'percentage_occupancy']),
  );

  const deskPeakAndAvgGraphPeakOccupancy = Math.max.apply(
    Math,
    mappedData(deskPeakAndAvgData).map(
      (key) => key[returnPercentageSwitcherDeskPeakAndAvg ? 'max_occupancy' : 'percentage_max_occupancy'],
    ),
  );

  const occupancyByWeek = setScaleValues(deskGraphPeakOccupancy);
  const peakAndAvgByType = setScaleValues(deskPeakAndAvgGraphPeakOccupancy);

  const deskPeakAndAvgOnSwitcherChange = useCallback(
    () => dispatch(SET_SECOND_GRAPH_SWITCHER(!returnPercentageSwitcherDeskPeakAndAvg)),
    [returnPercentageSwitcherDeskPeakAndAvg],
  );
  const deskGraphOnSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );
  const onScaleChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_SCALE(value)), []);

  return (
    <Choose>
      <When condition={requestStatuses.some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={requestStatuses.every((item) => isSuccess(item))}>
        <SummaryKeyFindingsDesks
          {...state}
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'desk_key_data', text });
          }}
          editableTextReport={editableTextReport}
          deskGraphScaleValues={occupancyByWeek.scaleValues}
          onSwitcherChange={deskGraphOnSwitcherChange}
          deskGraphData={toDeskByWeekGraphData(data.slice(0, 10), returnPercentageSwitcher)}
          databoxes={deskDataboxes}
          databoxesSet={databoxesSet}
          deskPeakAndAvgGraphData={toSpaceComparisonDeskGraphData(
            mappedData(deskPeakAndAvgData),
            returnPercentageSwitcherDeskPeakAndAvg,
          )}
          scale={scale}
          onScaleChange={onScaleChange}
          textVariables={textVariables}
          deskPeakAndAvgScaleValues={peakAndAvgByType.scaleValues}
          deskPeakAndAvgOnSwitcherChange={deskPeakAndAvgOnSwitcherChange}
          returnPercentageSwitcherDeskPeakAndAvg={returnPercentageSwitcherDeskPeakAndAvg}
          returnPercentageSwitcher={returnPercentageSwitcher}
          customHeader
          archive={archive}
        />
      </When>
      <When condition={requestStatuses.some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default SummaryKeyFindingsDeskContainer;
