import React from 'react';
import { Grid } from '@material-ui/core';

import { PAGE_TYPES } from 'modules/assetLook/constants';

import {
  Counter,
  PageHeader,
  PageSelectorBar,
  AssetDetailsCard,
  FetchAssetsHandler,
  ProductDetailsCard,
  FetchProductsHandler,
} from 'modules/assetLook/components';

const AssetDetailsPage = () => (
  <FetchProductsHandler>
    <FetchAssetsHandler>
      <Grid container spacing={4} direction="column" wrap="nowrap" style={{ height: 'calc(100% + 32px)' }}>
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={9}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PageHeader />
                </Grid>
                <Grid item xs={12}>
                  <PageSelectorBar />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <Counter pageType={PAGE_TYPES.assetDetails} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ flex: '1 1 1px' }}>
          <div style={{ height: '35%', maxHeight: '35%', paddingBottom: '8px' }}>
            <ProductDetailsCard disabledNav />
          </div>
          <div style={{ height: '65%', maxHeight: '65%', paddingTop: '8px' }}>
            <AssetDetailsCard />
          </div>
        </Grid>
      </Grid>
    </FetchAssetsHandler>
  </FetchProductsHandler>
);

export default AssetDetailsPage;
