import { URLS, LANDING_START_PAGE, LANDING_PATH } from 'pages/constants';
import config from 'modules/shared/components/NavBar/config';

const onLoginRedirect = ({ user, history, isOtpRequired }) => {
  const getLandingPage = user.landing_start_page?.name;
  const getLandingPageId = user.landing_start_page?.id;
  const landingPage = getLandingPage?.split(' ')[0].toLowerCase();

  const configNames = config.map(({ name }) => name);
  const getWorkLookPage = configNames.includes(getLandingPage);

  const shouldOpenLP = landingPage
    ? URLS[LANDING_START_PAGE[landingPage]]?.[LANDING_PATH[landingPage]]
    : URLS.locationLook.locationSummary;

  const portalUrl = getWorkLookPage ? shouldOpenLP : URLS.workLook.base.replace(':typeId', getLandingPageId);

  let redirectUrl = isOtpRequired ? URLS.auth.twoFACode : portalUrl;

  if (URLS.workLook.base.includes(redirectUrl) && !isOtpRequired) {
    redirectUrl = redirectUrl.replace(':typeId', user.company?.work_look_report_types?.[0]?.id || '1');
  }

  history.push(redirectUrl);
};

export default onLoginRedirect;
