const config = [
  {
    legendLabel: 'Occupied',
    iconType: 'round',
    color: '#58BFC7',
  },
  {
    legendLabel: 'Unoccupied ',
    iconType: 'round',
    color: '#f1f1f1',
  },
  {
    legendLabel: 'Peak occupancy',
    lineLabel: 'Peak occupancy',
    color: '#0d6f85',
    iconType: 'line',
    valueKey: 'peakOccupancy',
  },
  {
    legendLabel: 'Avg occupancy',
    lineLabel: 'Avg occupancy',
    color: '#f6a000',
    iconType: 'line',
    valueKey: 'avgOccupancy',
  },
  {
    legendLabel: 'Nominal capacity',
    lineLabel: 'Nominal capacity',
    color: '#a2559d',
    iconType: 'line',
    valueKey: 'nominalCapacity',
  },
  {
    legendLabel: 'Tech capacity',
    lineLabel: 'Technical capacity',
    color: '#00aad2',
    iconType: 'line',
    valueKey: 'capacity',
  },
];

export default config;
