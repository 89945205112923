import React from 'react';
import { TableCell } from '@material-ui/core';
import { ORDER_STATUS_LABELS } from 'modules/assetLook/constants';

function StatusHistoryCellBody({ data, rowIndex, columnKey, height, width }) {
  let label;

  switch (columnKey) {
    case 'date_created':
      label = new Date(data[rowIndex][columnKey]).toLocaleString('en-GB', {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      break;
    case 'status':
      label = ORDER_STATUS_LABELS[data[rowIndex][columnKey]];
      break;
    default:
      label = data[rowIndex][columnKey];
  }

  return (
    <TableCell
      size="small"
      component="div"
      variant="body"
      style={{
        height,
        width,
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <span title={label}>{label}</span>
    </TableCell>
  );
}

export default StatusHistoryCellBody;
