/* eslint-disable camelcase */
import React from 'react';
import { Skeleton } from '@material-ui/lab';

import { toTextSummaryData } from 'modules/workLook/utils';
import { useEditGraphClick, useGraphData, useModifierGraph, useOnTextEditorSave } from 'modules/workLook/hooks';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { TextEditor } from 'modules/shared/components';
import { useHasScroll } from 'modules/shared/hooks';
import { lowerFontSize } from 'modules/shared/utils';
import { isEmpty } from 'lodash';
import { getTextSummaryBarChart, getTextSummaryBarMultiple, getTextSummaryBarSingle } from 'modules/workLook/selectors';
import theme from './TextSummary.module.scss';

const TextSummary = ({
  title,
  sub_title,
  description,
  id,
  is_display_title: showTitle,
  activeFilters,
  type_of_group,
  summaryPage,
  isPdf,
  type,
  isPeopleLook,
  position,
  isPublished,
  isModal,
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters);
  const onTextEditorSave = useOnTextEditorSave(id);
  const graphData = toTextSummaryData(data, type_of_group);
  const { ref, hasVertScroll } = useHasScroll();
  const { onEditClick } = useEditGraphClick(isModal, id);
  const { onModifierClick } = useModifierGraph(isModal, id, data, type, type_of_group);

  const getDescription = isEmpty(description) ? '' : description;
  lowerFontSize(hasVertScroll, ref?.current, isPdf);
  const { textSummaryBarChart } = useSelector(getTextSummaryBarChart);
  const { textSummaryBarSingle } = useSelector(getTextSummaryBarSingle);
  const { textSummaryBarMultiple } = useSelector(getTextSummaryBarMultiple);

  const allData = [...graphData, ...textSummaryBarChart, ...textSummaryBarSingle, ...textSummaryBarMultiple];

  const checkPosition = ['7', '8', '9'].includes(position);
  const checkDashboardPosition = ['10', '11'].includes(position);
  const getGraphHeight = isPdf
    ? checkPosition
      ? '660px'
      : '340px'
    : checkPosition
    ? '720px'
    : checkDashboardPosition
    ? '500px'
    : '360px';

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height={summaryPage ? 400 : '100%'} width="100%" />
      </When>
      <Otherwise>
        <Choose>
          <When condition={summaryPage}>
            <TextEditor
              text={getDescription}
              onSave={onTextEditorSave}
              data={allData}
              isSaveLocallyHide
              isImageBtnShow
              isTextLimitHide
              isPeopleLook={isPeopleLook}
            />
          </When>
          <Otherwise>
            <div ref={ref} className={theme.container} style={{ maxHeight: getGraphHeight }}>
              <div className={theme.title}>
                <GraphHeader
                  showTitle={showTitle}
                  graphId={id}
                  title={title}
                  subTitle={sub_title}
                  isPdf={isPdf}
                  onClick={onEditClick}
                  onModifierClick={onModifierClick}
                  isPublished={isPublished}
                />
              </div>
              <div>
                <TextEditor
                  text={getDescription}
                  onSave={onTextEditorSave}
                  data={allData}
                  isSaveLocallyHide
                  isImageBtnShow
                  isTextLimitHide
                  isPeopleLook={isPeopleLook}
                />
              </div>
            </div>
          </Otherwise>
        </Choose>
      </Otherwise>
    </Choose>
  );
};

export default TextSummary;
