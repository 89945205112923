import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS, requestStatuses } from 'modules/shared/constants';

const useReportData = (isBase) => {
  const { reportId } = useParams();

  const [requestStatus, setRequestStatus] = useState(requestStatuses.default);
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    setRequestStatus(requestStatuses.pending);

    const token = isLogged();
    const url = isBase
      ? ENDPOINTS.workLook.baseReport.replace(':reportId', reportId)
      : ENDPOINTS.workLook.basket.replace(':reportId', reportId);
    const config = {
      headers: { Authorization: `Token ${token}` },
    };

    axios
      .get(url, config)
      .then(({ data }) => {
        setReportData(isBase ? data : JSON.parse(data.basket_data));
        setRequestStatus(requestStatuses.success);
      })
      .catch(() => {
        setRequestStatus(requestStatuses.failure);
      });
  }, [isBase, reportId]);

  return { requestStatus, reportData };
};

export default useReportData;
