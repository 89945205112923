import { useRef, useLayoutEffect, useState } from 'react';

const useHasScroll = () => {
  const element = useRef(null);
  const [hasHorScroll, setHasHorScroll] = useState(false);
  const [hasVertScroll, setHasVertScroll] = useState(false);

  useLayoutEffect(() => {
    const checkForScroll = () => {
      if (!element.current) return;

      const { current } = element;

      setHasHorScroll(current.scrollWidth > current.clientWidth);
      setHasVertScroll(current.scrollHeight > current.clientHeight);
    };

    window.addEventListener('resize', checkForScroll);
    checkForScroll();
    return () => window.removeEventListener('resize', checkForScroll);
  });

  return {
    ref: element,
    hasHorScroll,
    hasVertScroll,
  };
};

export default useHasScroll;
