import React from 'react';
import { isEmpty } from 'lodash';
import { Grid, withStyles } from '@material-ui/core';

import { useNodeSize } from 'modules/shared/hooks';

import { PageHeader, PdfFilters } from 'modules/shared/components';

const ReportContentPage = ({ classes, title, activeFilters, firstLogo, secondLogo, children, isPdf }) => {
  const { ref, height, width } = useNodeSize();

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      wrap="nowrap"
      classes={{
        root: classes.container,
      }}
      className={isPdf && classes.isPdf}
    >
      <Grid item>
        <PageHeader title={title} />
      </Grid>
      <If condition={!isEmpty(activeFilters)}>
        <Grid item classes={{ root: classes.filters }}>
          <PdfFilters activeFilters={activeFilters} />
        </Grid>
      </If>
      <Grid ref={ref} item classes={{ root: classes.content }} className={isPdf && classes.pdfContent}>
        {children({ height: height - 16, width: width - 16 })}
      </Grid>
      <Grid item classes={{ root: classes.footer }} className={classes.isPdf}>
        <div className={classes.logoWrapper}>
          <If condition={firstLogo}>
            <img src={firstLogo} height={15} className={classes.logo} alt="" />
          </If>
          <If condition={secondLogo}>
            <img src={secondLogo} height={15} className={classes.logo} alt="" />
          </If>
        </div>
        {`Produced using the WorkLOOK™ report engine. © BakerStuart Limited ${new Date().getFullYear()}.`}
      </Grid>
    </Grid>
  );
};

export default withStyles((theme) => ({
  container: {
    height: 'calc(100% + 16px)',
    padding: '25px 25px 0',
  },
  filters: {
    margin: '8px',
    maxHeight: '83.95px',
    backgroundColor: theme.palette.grey[50],
  },
  content: {
    flexGrow: '1',
    overflow: 'auto',
  },
  pdfContent: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  footer: {
    position: 'relative',
    padding: '0 !important',
    margin: '8px',
    minHeight: '40px',
    flexBasis: '40px',
    borderTop: '0.25px solid #7FA0A8',
    textAlign: 'center',
    fontSize: '10px',
    lineHeight: '38.75px',
    fontStyle: 'italic',
    color: theme.palette.grey[500],
  },
  logoWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
}))(ReportContentPage);
