import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { useDispatch } from 'react-redux';
import { SET_GRAPH_EDIT_DATA, SET_MODAL_TYPE } from '../actions';

const useCreateGraphClick = (isModal, pageId) => {
  if (isModal) {
    return { onClick: null };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(SET_MODAL_TYPE({ type: 'create', pageId }));
    dispatch(SET_GRAPH_EDIT_DATA({}));
    dispatch(showModal(modalCodes.WL_EDIT_GRAPH_MODAL));
  };

  return { onCreateGraphClick: onClick };
};

export default useCreateGraphClick;
