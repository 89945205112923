import React from 'react';
import PropTypes from 'prop-types';

import './StaticTokensListItem.scss';

const StaticTokensListItem = ({ token }) => {
  return (
    <li className="StaticTokensListItem">
      <span className="static-tokens-list-item">{token}</span>
    </li>
  );
};

StaticTokensListItem.propTypes = {
  token: PropTypes.string,
};

StaticTokensListItem.defaultProps = {
  token: 'static token must be here',
};

export default StaticTokensListItem;
