/* eslint-disable camelcase */
import React from 'react';
import { useGraphData } from 'modules/workLook/hooks';
// eslint-disable-next-line import/no-cycle
import { isEmpty } from 'lodash';
import SlidingGraph from './SlidingGraph';
import EmptyGraph from '../EmptyGraph';

const SlidingGraphContainer = ({
  id,
  type_of_group,
  activeFilters,
  type,
  isModal,
  graphData,
  isPdf,
  isPublished,
  sub_title,
  axis_max_limit,
  axis_min_limit,
  ...props
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters, graphData);
  if (isEmpty(data))
    return (
      <EmptyGraph
        type={type}
        title={props.title}
        graphId={id}
        typeOfGroup={type_of_group}
        showTitle={props.is_display_title}
        subtitle={sub_title}
        {...props}
      />
    );

  return (
    <SlidingGraph
      isPdf={isPdf}
      maxAxis={axis_max_limit}
      minAxis={axis_min_limit}
      graphId={id}
      data={data}
      typeOfGroup={type_of_group}
      isPublished={isPublished}
      isLoading={isLoading}
      groupType={type_of_group}
      type={type}
      isModal={isModal}
      modalData={
        !isModal && {
          ...props,
          id,
          type_of_group,
          axis_max_limit,
          axis_min_limit,
          activeFilters,
          type,
          graphData: data,
        }
      }
      {...props}
    />
  );
};

export default SlidingGraphContainer;
