/* eslint-disable camelcase */
import { roundToFloat } from 'utils';

const DesksOccupancyPlusAgile = ({
  databoxes: { percent_max_occupancy, percent_avg_signs_of_life_peek_hour, avg_signs_of_life },
  textData: { percent_peak_occupancy_non_agile },
}) => {
  const occupancyDifferenceNoAgile = roundToFloat(percent_peak_occupancy_non_agile - percent_max_occupancy);
  const higherOrLowerDifferenceNoAgile = occupancyDifferenceNoAgile > 0 ? 'lower' : 'higher';

  const higherOrLower =
    percent_avg_signs_of_life_peek_hour > 10 ? 'higher' : avg_signs_of_life < 10 ? 'lower' : 'equal';

  return `The peak occupancy observed was ${percent_max_occupancy}% which is ${Math.abs(occupancyDifferenceNoAgile)}%
     ${higherOrLowerDifferenceNoAgile} than our pre agile working benchmark of ${percent_peak_occupancy_non_agile}%.
     Further, we would typically observe signs of life at about 10%, in this case signs of life were ${higherOrLower},
     on average ${percent_avg_signs_of_life_peek_hour}%. High signs of life can point to a good level
     of mobility within the space however it can also be a symptom of “beachtowelling”
     which will potentially restrict the number of available desks if agile working is implemented.
     Low signs of life can be indicative of very sedentary behaviour and can hinder collaboration.`;
};

export default DesksOccupancyPlusAgile;
