/* eslint-disable react/no-children-prop */
import React from 'react';
import { Button } from '@material-ui/core';
import { ORDER_STATUSES } from 'modules/assetLook/constants';

const OrderActions = ({ orderStatus, isManager, onRejectClick, onApproveClick, onCancelClick, onCompleteClick }) => (
  <Choose>
    <When condition={isManager}>
      <Choose>
        <When condition={orderStatus === ORDER_STATUSES.PD}>
          <Button size="small" color="primary" children="Reject" onClick={onRejectClick} />
          <Button size="small" color="primary" children="Approve" onClick={onApproveClick} />
        </When>
        <When condition={orderStatus === ORDER_STATUSES.AP}>
          <Button size="small" color="primary" children="Cancel" onClick={onCancelClick} />
          <Button size="small" color="primary" children="Complete" onClick={onCompleteClick} />
        </When>
      </Choose>
    </When>
    <Otherwise>
      <Button size="small" color="primary" children="Cancel" onClick={onCancelClick} />
    </Otherwise>
  </Choose>
);

export default OrderActions;
