import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader, TextEditor } from 'modules/shared/components';
import {
  FilterList,
  VerticalBarGraph,
  ReportDataCards,
  ByBuildingGraphHeader,
  LiveCounter,
} from 'modules/liveLook/components';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';
import IFrameModal from 'modules/liveLook/components/iFrame';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import config from './config';

const DesksByBuilding = ({
  building,
  onBuildingChange,
  data,
  scaleValues,
  databoxes,
  onSwitcherChange,
  returnPercentageSwitcher,
  editableTextReport,
  saveGraphsText,
  databoxesSet,
  activeBuilding,
  textVariables,
  archive,
}) => (
  <Grid container spacing={4}>
    <Grid item xs={8}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title="Desk by building" />
        </Grid>
        <Grid item xs={12}>
          <FilterList pageType="DESKS" subtypes />
        </Grid>
        <Grid item xs={12}>
          <ReportDataCards databoxes={databoxes} pageType={pageTypes.desks} />
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={4}>
      <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
        <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
        <Grid item>
          <IFrameModal />
        </Grid>
      </Grid>
      <Grid>
        <AddToPDFCard
          type="By Building and Floor"
          sectionType="Desk"
          category={pdfCases.deskByBuilding}
          sectionOrder={sectionsOrder.third}
          typeOrder={sectionItemOrder.third}
          chartName={liveLookPagesNames.desksByBuilding}
          activeFilters={filtersDataContainer('DESKS', false).initActiveFilters}
          data={{
            scaleValues,
            databoxes,
            editableTextReport,
            building,
            data,
            returnPercentageSwitcher,
            activeBuilding,
          }}
        />
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <VerticalBarGraph
        CustomHeader={() => (
          <ByBuildingGraphHeader
            title="Comparison by Building"
            building={building}
            onBuildingChange={onBuildingChange}
            onSwitcherChange={onSwitcherChange}
            switcher={returnPercentageSwitcher}
            customHeader
          />
        )}
        data={data}
        scaleValues={scaleValues}
        config={config}
        returnPercentageSwitcher={returnPercentageSwitcher}
      />
    </Grid>

    <Grid item xs={12}>
      <TextEditor
        chartName={liveLookPagesNames.desksByBuilding}
        data={databoxesToEditorData(databoxesSet, textVariables)}
        // eslint-disable-next-line no-shadow
        onSave={async (data) => {
          saveGraphsText(JSON.stringify(data));
        }}
        text={editableTextReport}
        graphType={GRAPH_TYPES.VERTICAL}
      />
    </Grid>
  </Grid>
);

export default DesksByBuilding;
