import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Checkbox } from 'components';
import { getPropertyNamesMap } from 'modules/shared/selectors';

import config from './config';
import './FilterList.scss';

const FilterList = ({ listCategory, filters, onCheckboxClick, selectedFilters }) => {
  const { propertyNamesMap } = useSelector(getPropertyNamesMap);

  return (
    <section className="FiltersInBlockList">
      <div className="filters-in-block-list">
        <h4 className="FiltersInBlockListHeader">{config.filterTitles[listCategory]}</h4>
        <div className="items">
          {Object.keys(filters).map((filterOption) => {
            let propertyInMap;
            if (listCategory === 'property_id') {
              // eslint-disable-next-line camelcase
              propertyInMap = propertyNamesMap.find(({ property_id }) => property_id === filterOption);
            }

            return (
              <Checkbox
                key={filterOption}
                label={(propertyInMap && propertyInMap.property_name) || filterOption}
                isDisabled={filters[filterOption] !== 1}
                onClick={() => onCheckboxClick(filterOption)}
                isChecked={selectedFilters.includes(filterOption)}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

FilterList.propTypes = {
  listCategory: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

export default FilterList;
