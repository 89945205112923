import React from 'react';

import { useReportData } from 'modules/workLook/hooks';
import { requestStatuses } from 'modules/shared/constants';

import { Loader } from 'components';
import PrintablePdfPage from './PrintablePdfPage';

const PrintablePdfPageContainer = ({ isBase }) => {
  const { requestStatus, reportData } = useReportData(isBase);

  return (
    <Choose>
      <When condition={requestStatus === requestStatuses.success}>
        <PrintablePdfPage {...reportData} />
      </When>
      <Otherwise>
        <Loader />
      </Otherwise>
    </Choose>
  );
};

export default PrintablePdfPageContainer;
