import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_COMMON_DATA_DOOR_SENSORS = createAsyncAction(createAction('FETCH_COMMON_DATA_DOOR_SENSORS'));

const fetchCategoriesDoorSensors = (dispatch, companyId, isCanceled, archive) => {
  dispatch(FETCH_COMMON_DATA_DOOR_SENSORS.pending());

  const url = ENDPOINTS.liveLook.doorSensorsInitData
    .replace(':companyId', companyId)
    .concat(archive ? `?archive_id=${archive}` : '');

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_COMMON_DATA_DOOR_SENSORS.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_COMMON_DATA_DOOR_SENSORS.failure());
    });
};

export default fetchCategoriesDoorSensors;
