/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';

import { getUser } from 'modules/auth/selectors';

import { isAdminOrPartner } from 'modules/auth/utils';
import { isClient, isViewOnly } from 'modules/shared/utils';
import Sidebar from './Sidebar';

const SidebarContainer = () => {
  const { user } = useSelector(getUser);
  const { people_look_editing_rights, role } = user;

  const isAdminOrPartnerRole = isAdminOrPartner(role);
  const isClientRole = isClient(role);
  const hasViewRights = isViewOnly(people_look_editing_rights);
  const isPermitted = isAdminOrPartnerRole || (isClientRole && !hasViewRights);
  return <Sidebar isPermitted={isPermitted} />;
};

export default SidebarContainer;
