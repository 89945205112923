import React from 'react';

import { URLS } from 'pages/constants';
import { makeSearch } from 'modules/assetLook/utils';
import { ItemDetailsCard } from 'modules/assetLook/components';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID, PRODUCT_ID, ASSET_ID, ASSET_FIELDS } from 'modules/assetLook/constants';

function AssetDetailsCard() {
  const { buildingId, categoryId, productId, assetId } = useLocationSearchParams([
    BUILDING_ID,
    CATEGORY_ID,
    PRODUCT_ID,
    ASSET_ID,
  ]);

  const [assets] = useAssetLookSelector(['assets.assets']);
  const [products] = useAssetLookSelector(['products.products']);

  const currentProductIndex = assets.data.findIndex((asset) => asset[ASSET_FIELDS.id] === Number(assetId));
  const previousAssetIndex = currentProductIndex === 0 ? assets.data.length - 1 : currentProductIndex - 1;
  const nextAssetIndex = currentProductIndex === assets.data.length - 1 ? 0 : currentProductIndex + 1;

  const isSingleItem = assets.data.length === 1;
  return (
    <ItemDetailsCard
      image={assets.data[currentProductIndex][ASSET_FIELDS.thumbnail]}
      image_medium={products.data[currentProductIndex]._image}
      title={assets.data[currentProductIndex][ASSET_FIELDS.id]}
      contentData={assets.data[currentProductIndex].details_data}
      backUrl={
        !isSingleItem && {
          pathname: URLS.assetLook.assetDetails,
          search: makeSearch({
            buildingId,
            categoryId,
            productId,
            assetId: assets.data[previousAssetIndex][ASSET_FIELDS.id],
          }),
          state: { isBuilding: true },
        }
      }
      forwardUrl={
        !isSingleItem && {
          pathname: URLS.assetLook.assetDetails,
          search: makeSearch({
            buildingId,
            categoryId,
            productId,
            assetId: assets.data[nextAssetIndex][ASSET_FIELDS.id],
          }),
          state: { isBuilding: true },
        }
      }
    />
  );
}

export default AssetDetailsCard;
