import { findPeakDays, sortObjects } from 'utils';

const DesksPeakDays = ({ graphData }) => {
  const peakDay = graphData.sort(sortObjects('percent_occupancy'))[graphData.length - 1];
  const peakDays = findPeakDays(graphData, peakDay.percent_occupancy);

  return peakDays
    ? `The peak occupancy was observed on ${peakDays.split(', ').length}
         occasion(s) throughout the survey, on ${peakDays}.`
    : `The peak occupancy was observed on 0 occasions throughout the survey.`;
};

export default DesksPeakDays;
