const config = {
  self_historic: {
    title: 'Your Survey(s)',
  },
  other_historic: {
    title: 'Other Surveys, by sector',
  },
};

export default config;
