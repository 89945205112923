import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { URLS } from 'pages/constants';
import { PageSelectorItem } from 'modules/shared/components';

const PdfPageSelectorBar = () => {
  const matchPdfReportNew = useRouteMatch(URLS.assetLook.pdfReportsNew);
  const matchPdfReportHistory = useRouteMatch(URLS.assetLook.pdfReportsHistory);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <PageSelectorItem link={URLS.assetLook.pdfReportsNew} label="New" active={Boolean(matchPdfReportNew)} />
      </Grid>
      <Grid item>
        <PageSelectorItem
          link={URLS.assetLook.pdfReportsHistory}
          label="History"
          active={Boolean(matchPdfReportHistory)}
        />
      </Grid>
    </Grid>
  );
};

export default PdfPageSelectorBar;
