/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';

import WordCloud from './WordCloud';

const getData = (data) => {
  if (!data || !data.length) return [];
  return data[0].data;
};

// eslint-disable-next-line react/prop-types
const WordCloudContainer = ({
  id,
  type_of_group,
  activeFilters,
  type,
  isPdf,
  isModal,
  graphData,
  isPublished,
  is_display_title,
  title,
  sub_title,
  ...props
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters, graphData);

  return (
    <WordCloud
      graphId={id}
      data={getData(data)}
      isPublished={isPublished}
      isLoading={isLoading}
      isModal={isModal}
      modalData={!isModal && { ...props, id, type_of_group, activeFilters, type, graphData: data }}
      {...props}
      title={title}
      typeOfGroup={type_of_group}
      subtitle={sub_title}
      type={type}
      isPdf={isPdf}
      showTitle={is_display_title}
    />
  );
};

export default WordCloudContainer;
