import React from 'react';

import { useActiveReport, useStandardPdf } from 'modules/workLook/hooks';

import StandardReportBtn from './StandardReportBtn';

const StandardReportBtnContainer = () => {
  const [activeReport, reportId] = useActiveReport();
  const standardPdf = useStandardPdf(reportId, [activeReport.is_published]);

  return (
    <StandardReportBtn
      standardPdf={standardPdf}
      href={standardPdf && standardPdf.file}
      inProgress={standardPdf && standardPdf.status === 'In progress'}
    />
  );
};

export default StandardReportBtnContainer;
