import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';

const useCustomPdfList = (reportId, deps = []) => {
  const [customPdfList, setCustomPdfList] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(null);

  useEffect(() => {
    let canceled = false;
    const url = ENDPOINTS.workLook.customPdf.replace(':reportId', reportId);

    API.get(url)
      .then(({ data }) => {
        if (canceled) return;
        setCustomPdfList(data);
      })
      .catch((err) => {
        if (canceled) return;
        // eslint-disable-next-line no-console
        console.error(err);
      });

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, reloadTrigger, ...deps]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (customPdfList && !isEmpty(customPdfList) && customPdfList.some((item) => item.status === 'In progress')) {
        setReloadTrigger(uuidv4());
      }
    }, 5000);

    return () => clearInterval(interval);
  });

  return [customPdfList, setCustomPdfList];
};

export default useCustomPdfList;
