import axios from 'axios';

import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_BENCHMARKING_HISTORIC_DATA = createAsyncAction(createAction('FETCH_BENCHMARKING_HISTORIC_DATA'));

const fetchBenchmarkingHistoricData = (dispatch, id, params) => {
  dispatch(FETCH_BENCHMARKING_HISTORIC_DATA.pending());

  const token = isLogged();
  const url = ENDPOINTS.desksHistoricData.replace(':id', id);
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  axios
    .get(`${url}/?${params}`, config)
    .then(({ data }) => dispatch(FETCH_BENCHMARKING_HISTORIC_DATA.success(data)))
    .catch((err) => dispatch(FETCH_BENCHMARKING_HISTORIC_DATA.failure({ reportError: err.response })));
};

export default fetchBenchmarkingHistoricData;
