import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import theme from './DownloadsListItemSkeleton.module.scss';

const DownloadsListItemSkeleton = () => (
  <div className={theme.container}>
    <div className={theme.img}>
      <Skeleton variant="rect" width={16} height={21} />
    </div>

    <Skeleton className={theme.mainLine} variant="rect" width="100%" height={20} />

    <Skeleton variant="rect" width="50%" height={14} />
  </div>
);

export default DownloadsListItemSkeleton;
