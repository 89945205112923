import React, { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { useParams } from 'react-router-dom';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { PdfContext } from 'modules/assetLook/hooks/usePdfContext';
import { REPORT_TYPES } from 'modules/assetLook/constants';

import BSLogoWhite from 'assets/images/bsl-logo-mini-white.png';

import PrintablePdfPage from './PrintablePdfPage';

import theme from './PrintablePdfPage.module.scss';

const reportNamesMap = {
  [REPORT_TYPES.productList]: 'Product List Report',
  [REPORT_TYPES.productSummary]: 'Product Summary Report',
  [REPORT_TYPES.assetList]: 'Asset List Report',
};

function PrintablePdfPageContainer() {
  const { pdfId } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    const url = ENDPOINTS.assetLook.pdfItem.replace(':pdfId', pdfId);

    API.get(url)
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        setData({
          ...data,
          pdf_data: JSON.parse(data.pdf_data),
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  }, [pdfId]);

  return (
    <Choose>
      <When condition={!isEmpty(data)}>
        <PdfContext.Provider value={{ data, theme }}>
          <PrintablePdfPage
            basket={data.pdf_data}
            companyName={data.company.name}
            reportName={reportNamesMap[data.type]}
            image={get(data, 'additional_info.background_image', false)}
            textColor={get(data, 'additional_info.text_color', false)}
            parentCompanyLogo={
              isEmpty(data.partner_company) ? BSLogoWhite : get(data, 'partner_company.report_logo', false)
            }
            companyLogo={get(data, 'company.report_logo', false)}
          />
        </PdfContext.Provider>
      </When>
      <Otherwise>{null}</Otherwise>
    </Choose>
  );
}

export default PrintablePdfPageContainer;
