/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import { requestStatuses } from 'modules/shared/constants';

import { customSuggestionsFilter } from 'modules/workLook/utils';
import decorators from 'modules/shared/components/TextEditor/decorators';
import * as TextFieldStorageManager from 'modules/shared/utils/TextFieldStorageManager';

const removeAllMetaFields = (data) => {
  if (!data || typeof data !== 'object') return;

  delete data?._meta;

  const values = Array.isArray(data) ? data : Object.values(data);

  for (let i = 0; i < values.length; i++) {
    const item = values[i];

    if (typeof item === 'object') {
      removeAllMetaFields(item);
    }
  }
};

const useTextMentions = (editorState, setEditorState, data, key, setKey, isEditable, chartName) => {
  const [suggestions, setSuggestions] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [saveRequestStatus, setSaveRequestStatus] = useState(requestStatuses.default);
  const convertedData = data?.map(({ name, data }) => ({
    name,
    value: data,
    isActive: true,
  }));

  // Manage suggestions filtering on typing
  const _onSearchChange = ({ value }) => {
    setSuggestions(customSuggestionsFilter(value, convertedData));
  };

  // Update entities and suggestion on data update
  // Here JSON.stringify is not that expensive as operations inside
  useEffect(() => {
    setSuggestions(convertedData);
    const contentState = editorState.getCurrentContent();

    contentState
      .getEntityMap()
      .__getAll()
      .mapKeys((key) => {
        const entityData = contentState.getEntity(key).getData();

        if (!entityData.mention) return;

        const series = convertedData.find((item) => item.name === entityData.mention.name);

        contentState.replaceEntityData(key, {
          mention: {
            name: entityData.mention.name,
            value: series ? series.value : '[deleted value]',
            isActive: !!series,
          },
        });
      });
    const contentRaw = convertToRaw(editorState.getCurrentContent());
    removeAllMetaFields(contentRaw);

    const dataToSave = JSON.stringify(contentRaw);
    if (chartName !== 'SummaryFloorPlan') {
      TextFieldStorageManager.setItem(chartName, dataToSave);
      // TextFieldStorageManager.setTextFieldsList(chartName);
    }

    setSaveRequestStatus(requestStatuses.success);
    setKey(key + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(convertedData)]);

  // Replace "mention-plugin`s" default decorators with a single "toValue" decorator,
  // in order to show the entity value.
  // Here we need "numOfDecorators" check because otherwise any re-render of the page
  // cause re-setup of the default decorators
  useEffect(() => {
    const decorator = editorState.getDecorator();

    const numOfDecorators =
      decorator &&
      (decorator.decorators ? decorator.decorators._tail.array[0]._decorators.length : decorator._decorators.length);

    if (!isEditable && numOfDecorators > 1) {
      const newState = EditorState.set(editorState, { decorator: decorators.toValue });
      setEditorState(newState);
    }
  });

  return {
    suggestions,
    _onSearchChange,
  };
};

export default useTextMentions;
