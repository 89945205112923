import React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { setDayTimeGraphData } from 'utils';
import { getPdfMode } from 'modules/spaceLook/selectors';
import { DayTimeGraphYNav, GraphHeader } from 'components';

import options from './options';
import config from './config';

import theme from './DesksOccupationByDayGraph.module.scss';

const DesksOccupationByDayGraph = ({ graphData }) => {
  const pdfMode = useSelector(getPdfMode);

  const graphRows = setDayTimeGraphData(graphData, config);
  const data = {
    labels: graphRows[Object.keys(graphRows)[0]].data.hours,
    datasets: Object.keys(graphRows).map((dayName) => {
      const dayObj = graphRows[dayName];
      return {
        label: dayName,
        data: dayObj.data.values,
        borderColor: dayObj.color,
        lineTension: 0,
        fill: false,
        backgroundColor: dayObj.color,
      };
    }),
  };

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      <GraphHeader label="Average Desk Occupation by Day and Time" />
      <div className={theme.graphWrapper}>
        <DayTimeGraphYNav />
        <div className={theme.graph}>
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default DesksOccupationByDayGraph;
