import { isEmpty } from 'lodash';

const getGraphsInfo = (graphs) => {
  if (isEmpty(graphs)) return { isControlGraph: false, graphsList: [] };
  const graphsList = Object.values(graphs);

  return {
    isControlGraph: graphsList.some((graph) => graph.type === '17'),
    graphsList: graphsList.sort((a, b) => a.order - b.order),
  };
};

export default getGraphsInfo;
