const options = (maxValue, isSwitcherOn, isPdf) => {
  const obj = {
    layout: {
      padding: {
        left: -15,
      },
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    responsive: true,
    tooltips: {
      backgroundColor: '#FFF',
      titleFontColor: '#000',
      titleFontSize: 11,
      bodyFontColor: '#000',
      bodyFontSize: 11,
      displayColors: false,
      callbacks: {
        title(context) {
          const { labels } = this._data;
          const [firstElement] = context;
          return labels[firstElement.index];
        },
        label(context) {
          const [deskInUse, deskUsed, deskNeverUsed] = this._data.datasets;
          const { index } = context;
          return [
            `In use : ${deskInUse.data[index]}${isSwitcherOn ? '' : '%'}`,
            `Was used : ${deskUsed.data[index]}${isSwitcherOn ? '' : '%'}`,
            `Never used : ${deskNeverUsed.data[index]}${isSwitcherOn ? '' : '%'}`,
          ];
        },
      },
    },
    dataset: [{ barThickness: 11, categoryPercentage: 0.9, barPercentage: 1 }],
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            autoSkip: false,
            fontColor: '#4D4D4D',
            fontSize: isPdf ? 9 : 12,
            minRotation: 90,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: { min: 0, max: maxValue || 100, display: false },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  return obj;
};

export default options;
