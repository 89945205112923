/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MAP_TYPE } from 'modules/workLook/actions';
import { getMapData } from 'modules/workLook/selectors';
import theme from './mapContainer.module.scss';
import { MAP_TYPES } from './constants';
import { ColorComponent, HeatComponent, MarkerComponent, SizeComponent } from './components';

const MapContainerModal = React.forwardRef(({ onClose }, ref) => {
  const dispatch = useDispatch();
  const { mapData, maxIntensity, mapDataValue, mapType: Type } = useSelector(getMapData);
  const [mapType, setMapType] = useState(Type);

  const handleChange = (event) => {
    setMapType(event.target.value);
  };

  const RENDER_MARKER = {
    [MAP_TYPES.STANDARD]: <MarkerComponent data={mapData} />,
    [MAP_TYPES.SIZE]: <SizeComponent data={mapData} maxValue={maxIntensity} dataValue={mapDataValue} />,
    [MAP_TYPES.HEAT]: <HeatComponent data={mapData} maxIntensity={maxIntensity} dataValue={mapDataValue} />,
    [MAP_TYPES.COLOR]: <ColorComponent data={mapData} />,
  };

  const [firstBuilding] = mapData;

  const startPosition = firstBuilding.coordinates;

  const onApplyMapType = () => {
    dispatch(SET_MAP_TYPE(mapType));
    onClose();
  };

  return (
    <div elevation={2} className={theme.container}>
      <Grid className={theme.header}>
        <Grid className={theme.title}>
          <Typography variant="h6" component="span">
            Select Map Type
          </Typography>
        </Grid>

        <Grid>
          <IconButton ref={ref} aria-label="close" className={theme.closeBtn} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <div className={theme.mapContain}>
          <MapContainer center={startPosition} zoom={10} scrollWheelZoom>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {RENDER_MARKER[mapType]}
          </MapContainer>
        </div>
        <div
          style={{
            height: '95%',
            marginLeft: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '35%',
          }}
        >
          <FormControl>
            <FormLabel focused={false} id="mapType">
              Choose map type:
            </FormLabel>
            <RadioGroup aria-labelledby="mapType" name="controlled-mapType" value={mapType} onChange={handleChange}>
              <FormControlLabel value={MAP_TYPES.STANDARD} control={<Radio color="primary" />} label="Standard" />
              <FormControlLabel value={MAP_TYPES.HEAT} control={<Radio color="primary" />} label="Heat" />
              <FormControlLabel value={MAP_TYPES.SIZE} control={<Radio color="primary" />} label="Size" />
              <FormControlLabel value={MAP_TYPES.COLOR} control={<Radio color="primary" />} label="Color" />
            </RadioGroup>
          </FormControl>
          <div style={{ alignSelf: 'end' }}>
            <Button size="medium" color="primary" onClick={onApplyMapType} children="Apply" />
          </div>
        </div>
      </div>
    </div>
  );
});

export default MapContainerModal;
