import React from 'react';
import { cloneDeep } from 'lodash';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { reportPageTypes } from 'modules/spaceLook/constants';
import { useHasScroll } from 'modules/shared/hooks';
import {
  PageHeader,
  ReportText,
  FilterHandler,
  ReportDataCards,
  ReportFilter,
  DesksOccupationByDayGraph,
} from 'modules/spaceLook/components';

const DesksDailyUsagePattern = ({ isLoading, pdfMode, databoxes, weeklyHourlyGraphData }) => {
  const scroll = useHasScroll();
  return (
    <div ref={scroll.ref} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Daily Usage Pattern" text="Desk Utilisation" />
        </Grid>
        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.desks}>
              <ReportFilter pageType={reportPageTypes.desks} />
            </FilterHandler>
          </Grid>
        </If>
        <Grid item xs={12}>
          <ReportDataCards
            databoxes={databoxes}
            pageType={reportPageTypes.desks}
            marginBottom="40px"
            cropped={!pdfMode}
          />
        </Grid>
        <Grid item xs={12}>
          <DesksOccupationByDayGraph graphData={weeklyHourlyGraphData} />
        </Grid>
        <Grid item xs={12}>
          <ReportText
            getParagraphs={['desksBusiestDay', 'desksLeastBusy', 'desksHighestAndLowest']}
            graphData={cloneDeep(weeklyHourlyGraphData)}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DesksDailyUsagePattern;
