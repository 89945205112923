/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, useTheme } from '@material-ui/core';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const defaultStyles = (theme) => ({
  color: theme.palette.grey[900],
  opacity: '0.54',
  textDecoration: '',
  cursor: 'pointer',

  '&:hover': highlightStyles(theme),
});

const highlightStyles = (theme) => ({
  color: theme.palette.primary.main,
  opacity: '1',
  textDecoration: 'underline',
  cursor: 'pointer',
});

const disabledStyles = (theme) => ({
  color: theme.palette.grey[500],
  opacity: '0.54',
  textDecoration: '',
  cursor: 'default',
});

const PageSelectorItem = ({ link, hasViewRights, label, active, onEditPageClick, isPublished, isAdminRole }) => {
  const theme = useTheme();

  const LinkComponent = link ? Link : 'a';

  const style = !link ? disabledStyles(theme) : active ? highlightStyles(theme) : defaultStyles(theme);

  return (
    <Grid container alignItems="center">
      <LinkComponent to={link || undefined}>
        <Typography variant="body1" children={label} style={style} />
      </LinkComponent>
      <If condition={(isAdminRole && !isPublished && active) || (hasViewRights && active)}>
        <div onClick={onEditPageClick}>
          <BorderColorIcon sx={{ ml: 2 }} color="action" fontSize="small" />
        </div>
      </If>
    </Grid>
  );
};

export default PageSelectorItem;
