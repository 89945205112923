/* eslint-disable camelcase */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getCompanyUsers } from 'modules/auth/selectors';
import ModalInnerBody from 'components/Modals/components/ModalInnerBody';
import UserTable from './components/UserTable';

const UserListInfo = () => {
  const { companyUsers } = useSelector(getCompanyUsers);
  const emptyUserList = isEmpty(companyUsers);

  return (
    <Choose>
      <When condition={emptyUserList}>
        <ModalInnerBody>
          <Typography variant="h5">Users list is empty!</Typography>
        </ModalInnerBody>
      </When>
      <Otherwise>
        <ModalInnerBody title="Company Users">
          <UserTable companyUsers={companyUsers} />
        </ModalInnerBody>
      </Otherwise>
    </Choose>
  );
};

export default UserListInfo;
