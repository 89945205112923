import React from 'react';
import cn from 'classnames';

import './DesksCard.scss';

const DesksCard = ({ label, quantity, color, percent, valueLabel, customClass, withValueLabel }) => {
  const isPercent = percent || percent === 0;
  const isQuantity = quantity || quantity === 0;

  // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
  const renderedPercent = isPercent ? <span className="percent">{percent}%</span> : null;

  const valueLabelText = valueLabel || 'seats';
  const desksTitle = (isPercent || withValueLabel) && ` ${valueLabelText}`;

  const separateLabelAndValueClass = withValueLabel && !percent ? 'separated-label-and-value' : '';
  const classes = cn('DesksCard', percent && 'with-percent', customClass, separateLabelAndValueClass);

  const styles = {
    backgroundColor: `var(--${color})`,
  };

  const cardContent =
    withValueLabel && quantity == null ? (
      'N/A'
    ) : isQuantity || desksTitle ? (
      <>
        {renderedPercent}
        <span className="quantity">
          <span className="quantity-value">{quantity}</span>
          <span className="quantity-title">{desksTitle}</span>
        </span>
      </>
    ) : (
      'N/A'
    );

  return (
    <section style={styles} className={classes}>
      <div className="desks-card">
        <div className="header">
          <h3 className="title">{label}</h3>
        </div>
        <div className="content">{cardContent}</div>
      </div>
    </section>
  );
};

DesksCard.defaultProps = {
  label: 'Card label',
  color: 'dark-pink',
  withValueLabel: false,
};

export default DesksCard;
