/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  IconButton,
  Typography,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
  Grid,
} from '@material-ui/core';
import * as Yup from 'yup';
import isLogged from 'utils/isLogged';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';
import { URLS } from 'pages/constants';
import { getEditGraphId } from 'modules/workLook/selectors';
import theme from './CreateNewPageModalContainer.module.scss';
import { PAGE_TYPES } from './PageTypes';
import { btnsTheme } from '../../options';

const CreateNewPageModalContainer = React.forwardRef(({ onClose }, ref) => {
  const { sectionId, pageAction, pageData, pageTypeId, pageId, reportId, couldDelete } = useSelector(getEditGraphId);
  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });
  const [displayAction, setDisplayAction] = useState(false);
  const isEditMode = pageAction === 'edit';
  const { name, name_alias, type, order, page_content } = pageData || {};

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Title required'),
    type: Yup.string().required('*Type required'),
    order: Yup.string().required('*Order required'),
  });

  const createInitialValues = {
    section: sectionId,
    name: '',
    name_alias: '',
    type: '',
    order: '',
    page_content: '',
  };

  const editInitialValues = {
    section: sectionId,
    name: !name ? '' : name,
    name_alias: !name_alias ? '' : name_alias,
    type,
    order: !order ? '' : order,
    page_content: !page_content ? '' : page_content,
  };

  const formik = useFormik({
    initialValues: isEditMode ? editInitialValues : createInitialValues,
    validationSchema,
    onSubmit: (values) => {
      const result = JSON.stringify(values, (k, val) => (val === '' ? null : val));
      const url = ENDPOINTS.workLook.createPage;
      const editUrl = ENDPOINTS.workLook.crudPage.replace(':pageId', pageId);
      const config = {
        method: isEditMode ? 'put' : 'post',
        url: isEditMode ? editUrl : url,
        headers: {
          Authorization: `Token ${isLogged()}`,
          'Content-Type': 'application/json',
        },
        data: result,
      };
      axios(config)
        .then(() => {
          onClose();
          window.location.reload(false);
        })
        .catch((error) => {
          setErrors({ isError: true, errorMsg: error?.response?.data?.non_field_errors });
        });
    },
  });

  const onDeleteSection = () => {
    const urlDelete = ENDPOINTS.workLook.crudPage.replace(':pageId', pageId);

    API.delete(urlDelete).then(() => {
      window.location.replace(URLS.workLook.title.replace(':typeId', pageTypeId).replace(':reportId', reportId));
    });
  };

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Grid container spacing={2} alignItems="center">
            <Typography variant="h5" className={theme.title}>
              {isEditMode ? 'Edit Page' : 'Create Page'}
            </Typography>
            <If condition={isEditMode && !couldDelete && !displayAction}>
              <Grid item>
                <DeleteIcon onClick={() => setDisplayAction(true)} color="error" fontSize="medium" />
              </Grid>
            </If>
          </Grid>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={theme.formWrapper}>
          <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
            {errors.isError &&
              errors.errorMsg.map((msg) => (
                <Typography variant="body2" className={theme.error}>
                  {msg}
                </Typography>
              ))}
            <div>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                placeholder="Enter page name"
                value={formik.values.name}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.name}
              </Typography>
            </div>
            <TextField
              id="name_alias"
              name="name_alias"
              label="Name Alias"
              placeholder="Enter page name alias"
              value={formik.values.name_alias}
              onChange={formik.handleChange}
              variant="outlined"
            />
            <FormControl fullWidth>
              <InputLabel id="position">Type</InputLabel>
              <Select id="type" name="type" value={formik.values.type} label="Page type" onChange={formik.handleChange}>
                {PAGE_TYPES.map(({ value, name }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" className={theme.error}>
                {formik.errors.type}
              </Typography>
            </FormControl>
            <TextField
              fullWidth
              id="page_content"
              name="page_content"
              label="Page content"
              placeholder="Enter page content"
              value={formik.values.page_content}
              onChange={formik.handleChange}
              variant="outlined"
              multiline
              maxRows={3}
              minRows={2}
            />
            <div>
              <TextField
                fullWidth
                className={theme.input}
                type="number"
                id="order"
                name="order"
                label="Order"
                placeholder="Enter an order"
                value={formik.values.order}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.order}
              </Typography>
            </div>
            {isEditMode && (
              <>
                <ThemeProvider theme={btnsTheme}>
                  <If condition={couldDelete}>
                    <Typography variant="body2" className={theme.warning}>
                      You cannot delete the last page of a section
                    </Typography>
                  </If>
                  <If condition={displayAction}>
                    <div className={theme.btnsWrapper}>
                      <Button color="inherit" variant="outlined" onClick={() => setDisplayAction(false)}>
                        Cancel
                      </Button>
                      <Button color="secondary" variant="outlined" onClick={() => onDeleteSection()}>
                        Delete
                      </Button>
                    </div>
                  </If>
                </ThemeProvider>
              </>
            )}
            <If condition={!displayAction}>
              <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                Submit
              </Button>
            </If>
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateNewPageModalContainer;
