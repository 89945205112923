import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import URLS from 'pages/constants/urls';

import { RESET_STATE } from 'modules/workLook/actions';
import { FetchReportsHandler } from 'modules/workLook/components';

import WorkLookLayout from 'pages/_layouts/WorkLookLayout';

import Base from './Base';
import TitlePage from './TitlePage';
import SectionPage from './SectionPage';
import PdfReportsPage from './PdfReportsPage';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(RESET_STATE()), [dispatch]);

  return (
    <WorkLookLayout>
      <FetchReportsHandler>
        <Switch>
          <Route exact path={URLS.workLook.base} component={Base} />
          <Route exact path={URLS.workLook.title} component={TitlePage} />
          <Route exact path={URLS.workLook.section} component={SectionPage} />
          <Route exact path={URLS.workLook.pdfReports} component={PdfReportsPage} />

          <Redirect to={URLS.locationLook.locationSummary} />
        </Switch>
      </FetchReportsHandler>
    </WorkLookLayout>
  );
};
