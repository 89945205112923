/* eslint-disable no-param-reassign */
const MIN_FONT_SIZE = 4;
const DECREMENT_FONT_BY = 0.2;

const spaceLookLowerFont = (refElement, clientHeight, heightOfScroll) => {
  let scrollHeight = heightOfScroll;
  if (Number(refElement.style.fontSize.substr(0, refElement.style.fontSize.length - 2)) <= MIN_FONT_SIZE) return;
  refElement.style.fontSize = `${
    Number(refElement.style.fontSize.substr(0, refElement.style.fontSize.length - 2)) - DECREMENT_FONT_BY
  }px`;
  scrollHeight -= 50;

  if (clientHeight >= scrollHeight) return;
  if (scrollHeight > clientHeight) spaceLookLowerFont(refElement, clientHeight, scrollHeight);
};

const lowerFontSize = (hasVertScroll, refElement, isPdf, scrollRef, isSpaceLook) => {
  if (!isPdf || !refElement) return;
  if (!hasVertScroll && isPdf) return;
  if (!refElement.style.fontSize) {
    refElement.style.fontSize = `${10}px`;
  }
  if (scrollRef && isSpaceLook) {
    if (scrollRef.clientHeight >= scrollRef.scrollHeight) return;
    if (scrollRef.scrollHeight > scrollRef.clientHeight) {
      // eslint-disable-next-line consistent-return
      return spaceLookLowerFont(refElement, scrollRef.clientHeight, scrollRef.scrollHeight);
    }
  }

  if (Number(refElement.style.fontSize.substr(0, refElement.style.fontSize.length - 2)) <= MIN_FONT_SIZE) return;

  refElement.style.fontSize = `${
    Number(refElement.style.fontSize.substr(0, refElement.style.fontSize.length - 2)) - DECREMENT_FONT_BY
  }px`;

  if (!refElement.scrollHeight > refElement.clientHeight) return;
  if (
    refElement.scrollHeight > refElement.clientHeight &&
    Number(refElement.style.fontSize.substr(0, refElement.style.fontSize.length - 2)) > MIN_FONT_SIZE
  ) {
    lowerFontSize(refElement.scrollHeight > refElement.clientHeight, refElement, isPdf);
  }
};

export default lowerFontSize;
