import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';

import { getPdfMode } from 'modules/spaceLook/selectors';

const PageHeader = ({ title, text }) => {
  const pdfMode = useSelector(getPdfMode);

  return (
    <div>
      <Typography variant={pdfMode ? 'h5' : 'h4'}>
        <Box fontWeight="fontWeightMedium" component="span">
          {title}
        </Box>
      </Typography>
      <If condition={text}>
        <Typography variant={pdfMode ? 'subtitle2' : 'subtitle1'}>
          <Box fontWeight="fontWeightMedium" component="span">
            {text}
          </Box>
        </Typography>
      </If>
    </div>
  );
};

export default PageHeader;
