import React from 'react';
import { useDispatch } from 'react-redux';

import { SET_START_DATE, SET_END_DATE } from 'modules/spaceLook/actions';
import { customToIsoString } from 'modules/spaceLook/utils';
import { DatePicker } from 'components/Filters/ReportFilter/components';

import theme from './DateRangePicker.module.scss';

const DateRangePicker = ({ selectedFrom, selectedTo, surveyDateRange }) => {
  const dispatch = useDispatch();

  const fromDateValue = selectedFrom || surveyDateRange.start;
  const toDateValue = selectedTo || surveyDateRange.end;

  return (
    <div className={theme.container}>
      <DatePicker
        title="Start date"
        minDate={surveyDateRange.start}
        maxDate={toDateValue}
        value={fromDateValue}
        handleDateChange={(date) => dispatch(SET_START_DATE(customToIsoString(date)))}
        highlight={fromDateValue.toDateString() !== surveyDateRange.start.toDateString()}
      />
      <DatePicker
        title="End date"
        minDate={fromDateValue}
        maxDate={surveyDateRange.end}
        value={toDateValue}
        handleDateChange={(date) => dispatch(SET_END_DATE(customToIsoString(date)))}
        highlight={toDateValue.toDateString() !== surveyDateRange.end.toDateString()}
      />
    </div>
  );
};

export default DateRangePicker;
