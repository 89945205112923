import React from 'react';

import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// TODO: Remove when the "notistack" is fully implemented
const SnackbarError = ({ open, _handleClose, message = 'Something went wrong! Please, try again later.' }) => (
  <Snackbar
    open={open}
    onClose={_handleClose}
    autoHideDuration={6000}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <Alert severity="error" variant="filled">
      {message}
    </Alert>
  </Snackbar>
);

export default SnackbarError;
