import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS, requestStatuses } from 'modules/shared/constants';
import { useSelector } from 'react-redux';
import { getActiveCompany } from 'modules/shared/selectors';

const usePdfData = () => {
  const { pdfId } = useParams();

  const [requestStatus, setRequestStatus] = useState(requestStatuses.default);
  const [pdfData, setPdfData] = useState(null);
  const [reportName, setReportName] = useState('');

  const { logo, name } = useSelector(getActiveCompany);

  useEffect(() => {
    setRequestStatus(requestStatuses.pending);

    const token = isLogged();
    const url = ENDPOINTS.liveLook.pdfData.replace(':pdfId', pdfId);

    const config = {
      headers: { Authorization: `Token ${token}` },
    };

    axios
      .get(url, config)
      .then(({ data }) => {
        setPdfData(JSON.parse(data?.pdf_data));
        setReportName(data?.name);
        setRequestStatus(requestStatuses.success);
      })
      .catch(() => {
        setRequestStatus(requestStatuses.failure);
      });
  }, [pdfId]);

  return { requestStatus, pdfData, logo, name, reportName };
};

export default usePdfData;
