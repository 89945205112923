import { useMemo } from 'react';

import { toUsageOverTimeGraphData } from 'modules/liveLook/utils';
import { setDataGroupedByDays } from '../utils/setDataForOverTime';
import { setScaleValues, toLondonTimeOccupancy } from '../utils';
import { isInPercentages } from '../utils/common';

const useGetOccupancyData = ({
  dataOcc,
  scale,
  dayChoosen,
  occupancyDataboxesOcc,
  returnPercentageSwitcher,
  capacity,
  nominalCapacity,
  value,
}) => {
  const dataGroupedByDaysOcc = setDataGroupedByDays(dataOcc, scale, toLondonTimeOccupancy, dayChoosen);

  // const getOccDataboxes = () => {
  //   const getPeakDataboxes = occupancyDataboxesOcc;
  //   delete getPeakDataboxes.max_occupancy_date;
  //   const databoxesValues = Object.values(getPeakDataboxes);
  //   const peakDataboxes = Math.max(...databoxesValues);
  //   return peakDataboxes;
  // };

  const peakOccupancyOcc = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        occupancyDataboxesOcc,
        occupancyDataboxesOcc?.max_occupancy,
        occupancyDataboxesOcc?.max_occupancy_percentage,
      ),
    [returnPercentageSwitcher, occupancyDataboxesOcc],
  );

  const avgOccupancyOcc = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        occupancyDataboxesOcc,
        occupancyDataboxesOcc?.avg_occupancy,
        occupancyDataboxesOcc?.avg_occupancy_percentage,
      ),
    [returnPercentageSwitcher, occupancyDataboxesOcc],
  );

  const graphCapacity = returnPercentageSwitcher ? capacity : 100;
  const graphNominalCapacity = returnPercentageSwitcher ? nominalCapacity : 100;
  const peakIntegerValue = Math.max(
    occupancyDataboxesOcc?.capacity,
    occupancyDataboxesOcc?.nominal_capacity,
    occupancyDataboxesOcc?.max_occupancy,
  );

  // const peakValueOcc = returnPercentageSwitcher ? getOccDataboxes() : peakOccupancyOcc;

  const { niceMax: niceMaxOcc, scaleValues } = setScaleValues(
    returnPercentageSwitcher ? peakIntegerValue : peakOccupancyOcc,
  );

  const graphDataOcc = toUsageOverTimeGraphData({
    sourceData: dataGroupedByDaysOcc,
    valueType: value,
    scaleMaxValue: niceMaxOcc,
    scale,
    returnPercentageSwitcher,
  });

  const linesDataOcc = {
    capacity: graphCapacity,
    nominalCapacity: graphNominalCapacity,
    peakOccupancy: peakOccupancyOcc,
    avgOccupancy: avgOccupancyOcc,
  };
  return { scaleValues, linesDataOcc, graphDataOcc };
};

export default useGetOccupancyData;
