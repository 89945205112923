import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { fetchCategories, fetchLocations, fetchTags } from 'modules/assetLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';

function PreFetchHandler({ children }) {
  const dispatch = useDispatch();

  const { id: companyId } = useSelector(getActiveCompany);

  useEffect(() => {
    let isCanceled = false;

    fetchTags(dispatch, companyId, isCanceled);
    fetchLocations(dispatch, companyId, isCanceled);
    fetchCategories(dispatch, companyId, isCanceled);

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, dispatch]);

  const [categories, ...requestStatuses] = useAssetLookSelector([
    'common.categories',
    'common.categoriesRequestStatus',
    'common.tagsRequestStatus',
    'common.locationsRequestStatus',
  ]);

  return (
    <Choose>
      <When condition={requestStatuses.some((status) => isDefault(status) || isLoading(status))}>
        <Loader />
      </When>
      <When condition={requestStatuses.every((status) => isSuccess(status))}>
        <Choose>
          <When condition={!isEmpty(categories.by_building)}>{children}</When>
          <Otherwise>
            <TextPlaceholder noData />
          </Otherwise>
        </Choose>
      </When>
      <When condition={requestStatuses.some((status) => isFailure(status))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default PreFetchHandler;
