import React from 'react';
import { Grid } from '@material-ui/core';

import { PAGE_TYPES } from 'modules/assetLook/constants';

import {
  Counter,
  PageHeader,
  PageSelectorBar,
  ProductListTable,
  FilterListCategory,
} from 'modules/assetLook/components';

const ProductListPage = () => (
  <Grid container spacing={4} direction="column" wrap="nowrap" style={{ height: 'calc(100% + 32px)' }}>
    <Grid item>
      <Grid container spacing={4}>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader />
            </Grid>
            <Grid item xs={12}>
              <PageSelectorBar />
            </Grid>
            <Grid item xs={12}>
              <FilterListCategory searchPlaceholder="Product Name" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Counter pageType={PAGE_TYPES.productList} />
        </Grid>
      </Grid>
    </Grid>

    <Grid item style={{ flex: '1 1 1px' }}>
      <ProductListTable />
    </Grid>
  </Grid>
);

export default ProductListPage;
