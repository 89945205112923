/* eslint-disable func-names */
import { Chart } from 'react-chartjs-2';

export const options = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          max: 5,
          fontFamily: 'Roboto, sans-serif',
          fontSize: 11,
          width: 100,
          padding: '0 50px 0 0',
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {},
        stacked: true,
        display: false,
      },
    ],
    yAxes: [
      {
        barThickness: 30,
        gridLines: {
          display: false,
        },
        ticks: {},
        stacked: true,
        display: false,
      },
    ],
  },
  events: [],
  animation: {
    onComplete() {
      const { maxLabelValue } = this.options;
      const chartInstance = this.chart;
      const { ctx } = chartInstance;
      ctx.textAlign = 'right';
      ctx.font = '10px bold, sans-serif';

      const { datasets } = this.data;

      Chart.helpers.each(
        datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);
          Chart.helpers.each(
            meta.data.forEach(function (bar, index) {
              const data = dataset.data[index];
              const perentOfTotal = (100 * data) / maxLabelValue;
              if (perentOfTotal < 10) return;
              if (data === 0) return;
              const color = dataset.label[0] === 'Free Space' ? '#000' : '#fff';
              ctx.fillStyle = color;
              ctx.fillText(`${data}`, bar._model.x - 5, bar._model.y + 3);
            }),
            this,
          );
        }),
        this,
      );
    },
  },
};

export const optionsDivisionBar = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          max: 5,
          fontFamily: 'Roboto, sans-serif',
          fontSize: 11,
          width: 100,
          padding: '0 50px 0 0',
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {},
        stacked: true,
        display: false,
      },
    ],
    yAxes: [
      {
        barThickness: 30,
        gridLines: {
          display: false,
        },
        ticks: {},
        stacked: true,
        display: false,
      },
    ],
  },
  events: [],
  animation: {
    onComplete() {
      const chartInstance = this.chart;
      const { ctx } = chartInstance;
      ctx.textAlign = 'right';
      ctx.font = '10px bold, sans-serif';

      const { datasets } = this.data;
      const total = datasets.reduce((result, current) => {
        // eslint-disable-next-line no-param-reassign
        result += current.data[0];
        return result;
      }, 0);

      Chart.helpers.each(
        datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);
          Chart.helpers.each(
            meta.data.forEach(function (bar, index) {
              const data = dataset.data[index];
              const label = dataset.label[0];
              const perentOfTotal = (100 * data) / total;
              if (perentOfTotal < 10 || label === 'Free Space') return;
              if (data === 0) return;
              const color = dataset.label[0] === 'Free Space' ? '#000' : '#fff';
              ctx.fillStyle = color;
              ctx.fillText(`${data}`, bar._model.x - 5, bar._model.y + 3);
            }),
            this,
          );
        }),
        this,
      );
    },
  },
};
