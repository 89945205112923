import React from 'react';

import { Grid } from '@material-ui/core';

import { GRAPH_TYPES } from 'modules/workLook/constants';
import { DataCards, TextSummary } from 'modules/workLook/components';

const SummaryPagePattern = ({ page: { graphs, activeFilters }, isPdf }) => {
  const textSummary = Object.values(graphs).find((graph) => graph.type === GRAPH_TYPES.text);
  const dataCards = Object.values(graphs).find((graph) => graph.type === GRAPH_TYPES.dataCards);

  return (
    <Grid container spacing={4}>
      <Grid item xs={dataCards ? 8 : 12} xl={dataCards ? 9 : 12}>
        <If condition={textSummary}>
          <TextSummary summaryPage {...textSummary} isPdf={isPdf} isPeopleLook />
        </If>
      </Grid>
      <If condition={dataCards}>
        <Grid item xs={4} xl={3}>
          <DataCards grid activeFilters={activeFilters} {...dataCards} />
        </Grid>
      </If>
    </Grid>
  );
};

export default SummaryPagePattern;
