/* eslint-disable prefer-spread */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useReducer } from 'react';
import { isEmpty } from 'lodash';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, filtersToQuery, toSpaceComparisonGraphData } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import {
  SET_GRAPH_SWITCHER,
  fetchBreakDataboxes,
  fetchGraphsText,
  saveGraphsText,
  fetchBreakoutPeakAndAvgUtilLive,
  fetchBreakoutUsageBySize,
} from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import { setGraphInfo } from 'utils';
import SummaryKeyFindingsBreak from './SummaryKeyFindingsBreak';
import { setScaleValues } from '../utils';
import reducer, { initialState } from './_reducer';

function SummaryKeyFindingsBreakContainer() {
  const [companyId] = useAuthSelector(['currentCompany']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    breakDataboxes,
    databoxRequestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
    spaceUsageBySizerequestStatus,
    spaceUsageBySizeData,
  } = state;

  const [startDate, endDate, workingHours, activeDoorSensorFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'filters.activeFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeDoorSensorFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;

    fetchBreakoutUsageBySize(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchBreakoutPeakAndAvgUtilLive(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchBreakDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'break_key_data',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, dateAfter, dateBefore, workingHours, query]);

  const databoxesSet = {
    breakDataboxes,
  };

  // eslint-disable-next-line no-shadow
  const mappedData = (data) => {
    const arrData = [];
    for (const key in data) {
      arrData.push(Object.assign(data[key], { name: key }));
    }
    return arrData;
  };

  const peakSpaceOccupancy = Math.max.apply(
    Math,
    mappedData(data).map(
      (key) => key[returnPercentageSwitcher ? 'max_space_occupancy' : 'percentage_max_space_occupancy'],
    ),
  );
  const peakSeatOccupancy = Math.max.apply(
    Math,
    mappedData(data).map(
      (key) => key[returnPercentageSwitcher ? 'max_seat_occupancy' : 'percentage_max_seat_occupancy'],
    ),
  );
  const peakOccupancy = Math.max(peakSpaceOccupancy, peakSeatOccupancy);

  const { scaleValues } = setScaleValues(peakOccupancy);

  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  if (isEmpty(spaceUsageBySizeData)) return null;

  const graphData = setGraphInfo(spaceUsageBySizeData);

  return (
    <Choose>
      <When
        condition={[databoxRequestStatus, spaceUsageBySizerequestStatus, requestStatus, textReportRequestStatus].some(
          (item) => isDefault(item) || isLoading(item),
        )}
      >
        <Loader />
      </When>
      <When
        condition={[databoxRequestStatus, spaceUsageBySizerequestStatus, requestStatus, textReportRequestStatus].every(
          (item) => isSuccess(item),
        )}
      >
        <SummaryKeyFindingsBreak
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'break_key_data', text });
          }}
          editableTextReport={editableTextReport}
          data={toSpaceComparisonGraphData(mappedData(data), returnPercentageSwitcher)}
          spaceUsageBySizeData={graphData}
          databoxes={breakDataboxes}
          onSwitcherChange={onSwitcherChange}
          scaleValues={scaleValues}
          databoxesSet={databoxesSet}
          returnPercentageSwitcher={returnPercentageSwitcher}
          archive={archive}
        />
      </When>
      <When
        condition={[databoxRequestStatus, spaceUsageBySizerequestStatus, requestStatus, textReportRequestStatus].some(
          (item) => isFailure(item),
        )}
      >
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default SummaryKeyFindingsBreakContainer;
