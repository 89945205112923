/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { dateToQuery, filtersToQuery, toSeatsUsageOverTimeGraphData } from 'modules/liveLook/utils';
import {
  fetchBreakoutOverTime,
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
  fetchBreakDataboxes,
  SET_GRAPH_SWITCHER,
  fetchGraphsText,
  saveGraphsText,
} from 'modules/liveLook/actions';
import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import { GRAPH_SCALES } from 'modules/liveLook/constants';
import { setDataGroupedByDays } from '../utils/setDataForOverTime';
import setScaleOption, { daysDifference, isInPercentages } from '../utils/common';
import { setScaleValues, toLondonTimeZone } from '../utils';
import BreakoutOverTime from './BreakoutOverTime';
import reducer, { initialState } from './_reducer';

function BreakoutOverTimeContainer() {
  const [companyId] = useAuthSelector(['currentCompany']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    scale,
    value,
    breakDataboxes,
    databoxRequestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
  } = state;

  const [startDate, endDate, workingHours, activeFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'filters.activeFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  const daysDiffernce = useMemo(() => daysDifference(endDate, startDate), [endDate, startDate]);
  const scaleOption = useMemo(() => setScaleOption(scale, daysDiffernce), [scale, daysDiffernce]);
  const getScaleLabel = isEmpty(scaleOption) ? scale : scaleOption === 'hour' ? GRAPH_SCALES.hour : scaleOption;

  useEffect(() => {
    let isCanceled = false;

    fetchBreakoutOverTime(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      scaleOption,
      query,
      archive,
    });

    fetchBreakDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      scaleOption,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'break_time',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, dateAfter, dateBefore, workingHours, scaleOption, query]);

  const onScaleChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_SCALE(value)), []);
  const onValueChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_VALUE(value)), []);
  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  const databoxesSet = {
    breakDataboxes,
  };

  const dataGroupedByDays = setDataGroupedByDays(data, scale, toLondonTimeZone);

  const peakOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        breakDataboxes,
        breakDataboxes?.max_rooms_usage,
        breakDataboxes?.percentage_max_rooms_usage,
      ),
    [returnPercentageSwitcher, breakDataboxes],
  );

  const peakSeatOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        breakDataboxes,
        breakDataboxes?.max_seats_usage,
        breakDataboxes?.percentage_max_seats_usage,
      ),
    [returnPercentageSwitcher, breakDataboxes],
  );

  const avgOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        breakDataboxes,
        breakDataboxes?.avg_rooms_usage,
        breakDataboxes?.percentage_avg_rooms_usage,
      ),
    [returnPercentageSwitcher, breakDataboxes],
  );

  const peakValue = Math.max(peakOccupancy, peakSeatOccupancy);
  const { niceMax, scaleValues } = setScaleValues(peakValue);

  const linesData = {
    peakOccupancy,
    avgOccupancy,
  };

  const chunk = 20; // items per chunk
  const dateArrays = dataGroupedByDays;

  const result = dateArrays
    ? dateArrays.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunk);

        if (!resultArray[chunkIndex]) {
          // eslint-disable-next-line no-param-reassign
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, [])
    : [];

  const graphDataToBuild = result.map((graph) => {
    return toSeatsUsageOverTimeGraphData(
      {
        sourceData: graph,
        valueType: value,
        scale: getScaleLabel,
        returnPercentageSwitcher,
        niceMax,
      },
      true,
    );
  });

  const graphData = toSeatsUsageOverTimeGraphData(
    {
      sourceData: dataGroupedByDays,
      valueType: value,
      scale: getScaleLabel,
      returnPercentageSwitcher,
      niceMax,
    },
    true,
  );
  return (
    <Choose>
      <When
        condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].some(
          (item) => isDefault(item) || isLoading(item),
        )}
      >
        <Loader />
      </When>
      <When condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].every((item) => isSuccess(item))}>
        <BreakoutOverTime
          {...state}
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'break_time', text });
          }}
          scale={getScaleLabel}
          daysDiffernce={daysDiffernce}
          editableTextReport={editableTextReport}
          onScaleChange={onScaleChange}
          onValueChange={onValueChange}
          onSwitcherChange={onSwitcherChange}
          scaleValues={scaleValues}
          linesData={linesData}
          databoxesSet={databoxesSet}
          graphData={graphData}
          data={data}
          niceMax={niceMax}
          graphDataToBuild={graphDataToBuild}
          databoxes={breakDataboxes}
          returnPercentageSwitcher={returnPercentageSwitcher}
          archive={archive}
        />
      </When>
      <When condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default BreakoutOverTimeContainer;
