import React from 'react';

import { FiltersHeader, Loader } from 'components';
import BenchmarkingFilterList from './BenchmarkingFilterList';

import config from './config';
import theme from './BenchmarkingFilter.module.scss';

const BenchmarkingFilter = ({ options, selectedFilters, isApplyDisabled, isResetDisabled }) => (
  <div className={theme.filterBody}>
    <FiltersHeader type="benchmarking" isApplyDisabled={isApplyDisabled} isResetDisabled={isResetDisabled} />

    <div className={theme.content}>
      <Choose>
        <When condition={!options}>
          <Loader />
        </When>
        <Otherwise>
          {Object.keys(config).map((filterCategory) => {
            if (!options[filterCategory] || options[filterCategory].length < 1) return;

            // eslint-disable-next-line consistent-return
            return (
              <BenchmarkingFilterList
                key={filterCategory}
                title={config[filterCategory].title}
                options={options[filterCategory]}
                selectedFilters={selectedFilters}
              />
            );
          })}
        </Otherwise>
      </Choose>
    </div>
  </div>
);

export default BenchmarkingFilter;
