/* eslint-disable react/no-children-prop */
import React from 'react';

import { Button, withStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

const AssetManagementBar = ({
  buildingId,
  isPermitted,
  disabled,
  classes,
  onEditClick,
  onDeleteClick,
  onAddToBasketClick,
}) => (
  <div className={classes.container}>
    <If condition={isPermitted}>
      <Button
        children="Edit"
        size="small"
        variant="contained"
        disabled={disabled}
        startIcon={<EditIcon />}
        onClick={onEditClick}
        classes={{ root: classes.button }}
        disableElevation
      />
      <Button
        children="Delete"
        size="small"
        variant="contained"
        disabled={disabled}
        startIcon={<DeleteForeverIcon />}
        onClick={onDeleteClick}
        classes={{ root: classes.button }}
        disableElevation
      />
    </If>
    <If condition={buildingId}>
      <Button
        children="Add to basket"
        size="small"
        variant="contained"
        disabled={disabled}
        startIcon={<AddShoppingCartIcon />}
        onClick={onAddToBasketClick}
        disableElevation
      />
    </If>
  </div>
);

export default withStyles(() => ({
  container: {
    margin: '16px',
  },
  button: {
    marginRight: '16px',
  },
}))(AssetManagementBar);
