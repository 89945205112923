/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';
import { toDonutChartData } from 'modules/workLook/utils';
import { SERIES_ACTIONS } from 'modules/workLook/constants';

// eslint-disable-next-line import/no-cycle
import DonutChart from './DonutChart';

// eslint-disable-next-line camelcase
const DonutChartContainer = ({
  id,
  type_of_group,
  activeFilters,
  type,
  isModal,
  graphData,
  isPdf,
  isPublished,
  ...props
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters, graphData);

  return (
    <DonutChart
      isPublished={isPublished}
      isPdf={isPdf}
      type={type}
      typeOfGroup={type_of_group}
      isLoading={isLoading}
      graphId={id}
      data={toDonutChartData(data, type_of_group, isModal)}
      isModal={isModal}
      modalData={!isModal && { ...props, id, type_of_group, activeFilters, type, graphData: data }}
      isPercent={data && data.length > 0 && data[0].action === SERIES_ACTIONS.countUniquePercent}
      {...props}
    />
  );
};

export default DonutChartContainer;
