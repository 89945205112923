/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';

const useGraphWidgets = (id) => {
  const [isLoadingWidgets, setIsLoading] = useState(true);
  const [widgetsList, setWidgets] = useState(null);

  useEffect(() => {
    let canceled = false;

    setIsLoading(true);
    const widgetUrl = ENDPOINTS.workLook.widgetsList.replace(':graphId', id);
    const config = {
      headers: { Authorization: `Token ${isLogged()}` },
    };

    axios
      .get(`${widgetUrl}`, config)
      .then(({ data }) => {
        if (canceled) return;
        setWidgets(data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (canceled) return;
        console.error(err);
      });

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
  }, [id]);

  return { isLoadingWidgets, widgetsList };
};

export default useGraphWidgets;
