import { createReducer } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { requestStatuses } from 'modules/shared/constants';

import { APPLY_SUMMARY_FIELDS, FETCH_SUMMARY, FETCH_SUMMARY_FIELDS, RESET_STATE } from 'modules/assetLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  fieldsRequestStatus: requestStatuses.default,
  fields: [],
  fieldsActive: [],
  summaryRequestStatus: requestStatuses.default,
  summary: [],
};

export default createReducer(initState, {
  [FETCH_SUMMARY_FIELDS.pending]: () => ({
    ...initState,
    fieldsRequestStatus: requestStatuses.pending,
  }),
  [FETCH_SUMMARY_FIELDS.success]: (state, { payload }) => ({
    ...state,
    fieldsRequestStatus: requestStatuses.success,
    fields: payload,
    fieldsActive: isEmpty(payload) ? initState.fieldsActive : [payload[0]],
  }),
  [FETCH_SUMMARY_FIELDS.failure]: (state) => ({
    ...state,
    fieldsRequestStatus: requestStatuses.failure,
  }),

  [APPLY_SUMMARY_FIELDS]: (state, { payload }) => ({
    ...state,
    fieldsActive: payload,
  }),

  [FETCH_SUMMARY.pending]: (state) => ({
    ...state,
    summaryRequestStatus: requestStatuses.pending,
  }),
  [FETCH_SUMMARY.success]: (state, { payload }) => ({
    ...state,
    summaryRequestStatus: requestStatuses.success,
    summary: payload,
  }),
  [FETCH_SUMMARY.failure]: (state) => ({
    ...state,
    summaryRequestStatus: requestStatuses.failure,
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
