import { isEmpty, get } from 'lodash';
import { ASSET_STATUSES_LABEL, FILTERS } from 'modules/assetLook/constants';

const toRequestBody = (filterList, tags) => {
  if (!Array.isArray(filterList) || isEmpty(filterList)) return;

  // eslint-disable-next-line consistent-return
  return filterList.reduce((res, { field, values }) => {
    if (!values.length) return res;

    switch (field) {
      case FILTERS.status:
        res[field] = values.map((value) =>
          Object.keys(ASSET_STATUSES_LABEL).find((key) => ASSET_STATUSES_LABEL[key] === value),
        );
        return res;
      case FILTERS.tags:
        res[field] = values
          .map((value) => {
            if (value === 'None') return value;

            const tag = tags.find(({ name }) => name === value);
            return get(tag, 'id', null);
          })
          .filter((e) => e);
        return res;
      default:
        res[field] = values;
        return res;
    }
  }, {});
};

export default toRequestBody;
