import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/spaceLook/components';
import {
  FilterList,
  ReportDataCards,
  StackedBarGraph,
  VerticalBarGraph,
  LiveCounter,
} from 'modules/liveLook/components';

import filtersDataContainer from 'modules/liveLook/components/FilterList/filtersData.utils';
import {
  pageTypes,
  liveLookPagesNames,
  pdfCases,
  sectionsOrder,
  sectionItemOrder,
  GRAPH_TYPES,
} from 'modules/liveLook/constants';
import { TextEditor } from 'modules/shared/components';
import IFrameModal from 'modules/liveLook/components/iFrame';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import spaceUsageBySizeConfig from './spaceUsageBySizeConfig';
import config from './config';

const SummaryKeyFindingsBreak = ({
  data,
  databoxes,
  onSwitcherChange,
  scaleValues,
  editableTextReport,
  saveGraphsText,
  databoxesSet,
  spaceUsageBySizeData,
  returnPercentageSwitcher,
  archive,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Executive Summary - Key Findings - Breakout" />
          </Grid>
          <Grid item xs={12}>
            <FilterList subtypes pageType="BREAKOUT" />
          </Grid>
          <Grid item xs={12}>
            <ReportDataCards databoxes={databoxes} pageType={pageTypes.meetings} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Grid container alignItems="flex-end" direction="column" style={{ justifyContent: 'flex-end' }}>
          <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>
        <Grid>
          <AddToPDFCard
            type="Key Findings - Breakout"
            sectionType="Executive Summary"
            category={pdfCases.keyFindingsBreakout}
            sectionOrder={sectionsOrder.first}
            typeOrder={sectionItemOrder.fifth}
            chartName={liveLookPagesNames.summaryKeyFindingsBreak}
            activeFilters={filtersDataContainer('BREAKOUT', false).initActiveFilters}
            data={{
              scaleValues,
              databoxes,
              editableTextReport,
              databoxesSet,
              data,
              spaceUsageBySizeData,
              returnPercentageSwitcher,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <TextEditor
          chartName={liveLookPagesNames.summaryKeyFindingsBreak}
          data={databoxesToEditorData(databoxesSet)}
          // eslint-disable-next-line no-shadow
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
          graphType={GRAPH_TYPES.KEY_FINDINGS}
        />
      </Grid>

      <Grid item xs={6}>
        <VerticalBarGraph
          title="Peak and Average Usage by Space Type"
          data={data}
          config={config}
          onSwitcherChange={onSwitcherChange}
          scaleValues={scaleValues}
          returnPercentageSwitcher={returnPercentageSwitcher}
          customHeader
          label="Integers"
        />
        <StackedBarGraph
          title="Group Sizes by Space Type"
          data={spaceUsageBySizeData}
          config={spaceUsageBySizeConfig}
        />
      </Grid>
    </Grid>
  );
};

export default SummaryKeyFindingsBreak;
