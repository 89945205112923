import axios from 'axios';

import { createAsyncAction } from 'modules/shared/utils';
import createAction from 'modules/spaceLook/actions/_createAction';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';

export const LOAD_REPORT_INFO = createAsyncAction(createAction('LOAD_REPORT_INFO'));

const loadReportInfo = (dispatch, id) => {
  dispatch(LOAD_REPORT_INFO.pending());

  const token = isLogged();
  const url = ENDPOINTS.reportInfo.replace(':id', id);
  const config = {
    headers: { Authorization: `Token ${token}` },
  };

  axios
    .get(url, config)
    .then(({ data }) => dispatch(LOAD_REPORT_INFO.success(data)))
    .catch((err) => dispatch(LOAD_REPORT_INFO.failure({ reportError: err.response })));
};

export default loadReportInfo;
