import React from 'react';
import cn from 'classnames';

import sortArrows from 'assets/images/icons/sort-arrows.svg';

import './StatsTableHeadCell.scss';

const StatsTableHeadCell = ({ label, icon, pdfMode }) => (
  <div className={cn('StatsTableHeadCell', { pdf: pdfMode })}>
    <span className="label">{label}</span>
    <span className="icon">
      <img className="pic" src={icon} alt="icon" />
    </span>
  </div>
);

StatsTableHeadCell.defaultProps = {
  icon: sortArrows,
};

export default StatsTableHeadCell;
