/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toQuery } from 'modules/workLook/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import axios from 'axios';

import isLogged from 'utils/isLogged';
import { Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';

import { showModal } from 'modules/modalsSystem/actions/showModal';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { REPORT_FILTER_RESET, SECTION_FILTER_RESET } from 'modules/workLook/actions';

import { FilterButton } from 'modules/shared/components';

const FilterList = ({ initFilters, activeFilters, isPeopleLook }) => {
  const dispatch = useDispatch();
  const { reportId, sectionId } = useParams();
  const [modifiedFilters, setModifiedFilters] = useState();

  const query = toQuery(activeFilters);
  const _handleReset = () =>
    dispatch(sectionId ? SECTION_FILTER_RESET({ reportId, sectionId }) : REPORT_FILTER_RESET({ reportId }));

  useEffect(() => {
    let canceled = false;
    const url = ENDPOINTS.workLook.initFilters.replace(':reportId', reportId);
    // const filterUrl = ENDPOINTS.workLook.initFilters.replace(':reportId', reportId);
    const config = {
      headers: { Authorization: `Token ${isLogged()}` },
    };
    axios
      .get(`${url}${query}`, config)
      // eslint-disable-next-line no-shadow
      .then(({ data }) => {
        if (canceled) return;
        setModifiedFilters(data);
      })
      .catch((err) => {
        if (canceled) return;
        // eslint-disable-next-line no-console
        console.error(err);
      });

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
    // eslint-disable-next-line
  }, [query]);

  return (
    <Grid container spacing={2}>
      {initFilters.map(({ field }, i) => {
        const activeFilter = activeFilters.find((filter) => filter.field === field);
        const isActive = activeFilter && !isEmpty(activeFilter.values);

        return (
          <Grid item key={i}>
            <FilterButton
              isActive={isActive}
              startIcon={isActive ? <DoneIcon size="small" /> : null}
              onClick={() => {
                dispatch(
                  showModal(modalCodes.WL_FILTER_MODAL, {
                    filterField: field,
                    initFilters,
                    activeFilters,
                    reportId,
                    sectionId,
                    modifiedFilters,
                    isPeopleLook,
                  }),
                );
              }}
              children={field}
            />
          </Grid>
        );
      })}
      <Grid item>
        <FilterButton isActive startIcon={<CachedIcon size="small" />} onClick={_handleReset} children="Reset" />
      </Grid>
    </Grid>
  );
};

export default FilterList;
