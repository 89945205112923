/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';

import { isEmpty } from 'lodash';
import GraphMap from './GraphMap';
import EmptyGraph from '../EmptyGraph';

const GraphMapContainer = ({
  mapType,
  id,
  type_of_group,
  activeFilters,
  type,
  isPdf,
  title,
  sub_title,
  isPublished,
  is_display_title,
  ...props
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters);

  if (isEmpty(data))
    return (
      <EmptyGraph
        isPublished={isPublished}
        isPdf={isPdf}
        type={type}
        title={title}
        graphId={id}
        showTitle={is_display_title}
        subtitle={sub_title}
        {...props}
      />
    );

  return (
    <GraphMap
      graphId={id}
      isPublished={isPublished}
      isLoading={isLoading}
      data={data?.[0]?.data}
      {...props}
      isPdf={isPdf}
      typeOfGroup={type_of_group}
      title={title}
      subtitle={sub_title}
      type={type}
      showTitle={is_display_title}
      typeOfMap={mapType}
    />
  );
};

export default GraphMapContainer;
