import React from 'react';

const ImageComponent = ({ blockProps, style, ...otherProps }) => {
  const {
    alignment,
    resizeData: { src },
  } = blockProps;

  // Copy of the library`s implementation:
  // https://github.com/draft-js-plugins/draft-js-plugins/blob/master/packages/image/src/Image.tsx
  const {
    block,
    customStyleMap,
    customStyleFn,
    decorator,
    forceSelection,
    offsetKey,
    selection,
    tree,
    blockStyleFn,
    preventScroll,
    contentState,
    className,
    theme,
    ...elementProps
  } = otherProps;

  const customStyle = {
    maxWidth: '100%',
  };

  // eslint-disable-next-line default-case
  switch (alignment) {
    case 'left':
      customStyle.marginRight = '24px';
      break;
    case 'right':
      customStyle.marginLeft = '24px';
      break;
  }

  return (
    <img
      src={src}
      role="presentation"
      style={{
        ...style,
        ...customStyle,
      }}
      alt=""
      {...elementProps}
    />
  );
};

export default ImageComponent;
