const options = {
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
  },
  scales: {
    xAxes: [
      {
        ticks: {
          min: 0,
        },
      },
    ],
  },
};

export default options;
