import React from 'react';
import { cloneDeep, groupBy, chunk } from 'lodash';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { toPieChartData, toHourlyGraphMeetsData } from 'modules/spaceLook/utils';
import { reportPageTypes } from 'modules/spaceLook/constants';

import {
  PageHeader,
  UsagePie,
  ReportText,
  ReportDataCards,
  ReportFilter,
  FilterHandler,
  HourlyGraphMeets,
} from 'modules/spaceLook/components';
import { useHasScroll } from 'modules/shared/hooks';

const MeetingsUsageOverTime = ({ databoxes, pieData, dailyHourlyGraphData, pdfMode, isLoading }) => {
  const dataGroupedByDays = Object.entries(groupBy(dailyHourlyGraphData, (item) => item.date));
  const chunkedData = pdfMode ? chunk(dataGroupedByDays, 5) : [dataGroupedByDays];
  const scroll = useHasScroll();

  return chunkedData.map((graphData, idx) => (
    <div ref={scroll.ref} key={idx} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 1 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Usage over Time" text="Meeting Space Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.meetings}>
              <ReportFilter pageType={reportPageTypes.meetings} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={pdfMode ? 6 : 12} lg={6}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.meetings} cropped />
        </Grid>
        <Grid item xs={pdfMode ? 6 : 12} lg={6}>
          <UsagePie pieData={toPieChartData(pieData)} label="Observed Activities" inPercents />
        </Grid>

        <Grid item xs={12}>
          <HourlyGraphMeets graphData={toHourlyGraphMeetsData(graphData)} />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={[
              'meetsAndBreakOccupancy',
              'meetsAndBreakPeakUsagePlus',
              'meetsAndBreakOccupancySeats',
              'mostCommonActivities',
            ]}
            databoxes={databoxes}
            graphData={cloneDeep(dailyHourlyGraphData)}
            pieData={pieData}
            pageType={reportPageTypes.meetings}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default MeetingsUsageOverTime;
