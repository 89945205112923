import React from 'react';
import './emptyGraph.scss';
import { Paper } from '@material-ui/core';
import { TextPlaceholder } from 'modules/shared/components';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';

const EmptyGraph = ({
  isPdf = false,
  title,
  subtitle,
  graphId,
  showTitle,
  isModal,
  isPublished,
  typeOfGroup,
  type,
  display,
}) => {
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, null, type, typeOfGroup);

  return (
    <Paper className="container" elevation={2}>
      <GraphHeader
        display={display}
        isPublished={isPublished}
        onModifierClick={onModifierClick}
        onClick={onEditClick}
        showTitle={showTitle}
        graphId={graphId}
        title={title}
        subTitle={subtitle}
        isPdf={isPdf}
      />
      <TextPlaceholder />
    </Paper>
  );
};

export default EmptyGraph;
