/* eslint-disable camelcase */
import React from 'react';
import { Typography, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import theme from './groupingSettings.module.scss';
import { GRAPHS_GROUP_LIST } from './groupingSettings.contants';

const GroupingSettings = ({ formik, reportFields, errors, setTypeOf }) => {
  const getGroupType = formik.values.type_of_group;

  return (
    <div className={theme.container}>
      <Typography variant="h6" className={theme.title}>
        Grouping Settings
      </Typography>
      <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
        {errors?.isError &&
          errors?.errorMsg?.map((msg) => (
            <Typography variant="body2" className={theme.error}>
              {msg}
            </Typography>
          ))}
        <FormControl fullWidth>
          <InputLabel id="type_of_group">Type of group</InputLabel>
          <Select
            id="type_of_group"
            name="type_of_group"
            value={formik.values.type_of_group}
            label="Type of group"
            onChange={(e, { props }) => {
              setTypeOf(props.value);
              formik.setFieldValue('type_of_group', props.value);
            }}
          >
            {GRAPHS_GROUP_LIST.map(({ name, value }) => (
              <MenuItem key={name} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <If condition={getGroupType === '1'}>
          <Autocomplete
            freeSolo
            id="group_by_field"
            name="group_by_field"
            options={reportFields.map((option) => option?.name)}
            value={reportFields.find((field) => field.id === formik.values.group_by_field)?.name}
            onChange={(e, value) => {
              formik.setFieldValue('group_by_field', reportFields.find((field) => field.name === value)?.id);
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Group By Field" placeholder="Enter group field" variant="outlined" />
            )}
          />
          <Typography variant="body2" className={theme.error}>
            {formik.errors.group_by_field}
          </Typography>
        </If>
        <FormControlLabel
          control={
            <Checkbox
              id="is_display_legend"
              name="is_display_legend"
              color="primary"
              checked={formik.values.is_display_legend}
              value={formik.values.is_display_legend}
              onChange={formik.handleChange}
            />
          }
          label="Display legends"
        />
        <TextField
          type="number"
          id="axis_min_limit"
          name="axis_min_limit"
          label="Axis min limit"
          placeholder="Enter axis min limit"
          value={formik.values.axis_min_limit}
          onChange={formik.handleChange}
          variant="outlined"
        />
        <TextField
          type="number"
          id="axis_max_limit"
          name="axis_max_limit"
          label="Axis max limit"
          placeholder="Enter axis max limit"
          value={formik.values.axis_max_limit}
          onChange={formik.handleChange}
          variant="outlined"
        />
        <FormControlLabel
          control={
            <Checkbox
              id="is_disabled_limits"
              name="is_disabled_limits"
              color="primary"
              checked={formik.values.is_disabled_limits}
              value={formik.values.is_disabled_limits}
              onChange={formik.handleChange}
            />
          }
          label="Disable Limits"
        />
      </form>
      <Button
        onClick={() => formik.handleSubmit()}
        className={theme.sbmtBtn}
        color="primary"
        variant="outlined"
        type="submit"
      >
        Submit
      </Button>
    </div>
  );
};

export default GroupingSettings;
