import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import PrivatePage from './PrivatePage';

const PrivateRoute = ({ path, exact, component: Component, ...routeProps }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) => (
      <PrivatePage>
        <Component {...props} {...routeProps} />
      </PrivatePage>
    )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  path: PropTypes.string.isRequired,
};

export default PrivateRoute;
