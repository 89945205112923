import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { URLS } from 'pages/constants';
import { DESC, ASC } from 'modules/shared/constants';
import { ORDER_STATUSES } from 'modules/assetLook/constants';

import { PageHeader } from 'modules/shared/components';
import { OrdersPageSelectorBar, BasketSummaryTable, OrderList } from 'modules/assetLook/components';

const OrdersPage = () => (
  <Grid
    container
    spacing={4}
    direction="column"
    wrap="nowrap"
    style={{
      height: 'calc(100% + 32px)',
    }}
  >
    <Grid item>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title="Orders" />
        </Grid>
        <Grid item xs={12}>
          <OrdersPageSelectorBar />
        </Grid>
      </Grid>
    </Grid>
    <Switch>
      <Route
        exact
        path={URLS.assetLook.ordersBasket}
        component={(prop) => (
          <Grid item style={{ flex: '1 1 1px' }}>
            <BasketSummaryTable {...prop} />
          </Grid>
        )}
      />
      <Route
        exact
        path={URLS.assetLook.ordersInProgress}
        component={(props) => (
          <Grid item>
            <OrderList
              {...props}
              sortParams={{
                orderBy: 'date_created',
                direction: ASC,
              }}
              statuses={[ORDER_STATUSES.PD, ORDER_STATUSES.AP]}
            />
          </Grid>
        )}
      />
      <Route
        exact
        path={URLS.assetLook.ordersHistory}
        component={(props) => (
          <Grid item>
            <OrderList
              {...props}
              sortParams={{
                orderBy: 'date_updated',
                direction: DESC,
              }}
              statuses={[ORDER_STATUSES.RJ, ORDER_STATUSES.CD, ORDER_STATUSES.CP]}
            />
          </Grid>
        )}
      />
      <Redirect to={URLS.assetLook.ordersBasket} />
    </Switch>
  </Grid>
);

export default OrdersPage;
