import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';

const FieldInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  min-height: 40px;
  border-radius: 5px;
  border: 1px solid #04a9d2;
  max-width: 311px;
  /* background: ${({ isDragging }) => (isDragging ? 'rgba(255, 59, 59, 0.15)' : 'white')}; */
  background: white;
  margin-top: 15px;
`;

const FieldCard = ({ id, name, index }) => {
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <FieldInformation>
            <p>{name}</p>
          </FieldInformation>
        </div>
      )}
    </Draggable>
  );
};

export default FieldCard;
