import {
  MARGIN_BOTTOM,
  MARGIN_BOTTOM_PDF,
  MAX_BARS,
  DAILY_COMPARISON_MAX_BARS,
  GRAPH_HEIGHT,
  PDF_GRAPH_HEIGHT,
} from './components/GraphBody/graphBody.constants';

export const setMarginBottom = (isPdf, barPosition) => {
  if (!barPosition && !isPdf) return MARGIN_BOTTOM;
  if (!barPosition && isPdf) return MARGIN_BOTTOM_PDF;
  if (isPdf) return `${PDF_GRAPH_HEIGHT - barPosition}px`;
  return `${GRAPH_HEIGHT - barPosition}px`;
};

export const setWidth = (dataLabelsLength, barWidth, isPdf, isWeekGraph, isDailyComparisons) => {
  if (!isPdf && isWeekGraph && isDailyComparisons && dataLabelsLength > DAILY_COMPARISON_MAX_BARS) {
    return `${dataLabelsLength * barWidth}px`;
  }
  if (!isPdf && isWeekGraph && !isDailyComparisons && dataLabelsLength > MAX_BARS) {
    return `${dataLabelsLength * barWidth}px`;
  }
  return '100%';
};
