/* eslint-disable camelcase */
import React from 'react';

import { Typography, Grid } from '@material-ui/core';

import { ReportTitlePage } from 'pages/_privatePages/shared';
import { FilterList, TitleActionButtons } from 'modules/workLook/components';

import theme from './TitlePage.module.scss';

const TitlePage = ({
  company,
  name: reportName,
  title_text_color: textColor,
  initial_filters: initFilters,
  image,
  activeFilters,
  date_published,
  is_published,
}) => (
  <div className={theme.container}>
    <ReportTitlePage
      title={company.name}
      subTitle={reportName}
      middleText={`Publication Date: ${is_published ? date_published : 'Unpublished'}`}
      image={image}
      textColor={textColor}
      backgroundColor={!is_published ? 'secondary.dark' : undefined}
    >
      <Grid container spacing={4}>
        <If condition={initFilters.length}>
          <Grid item xs={12}>
            <Typography variant="h6" className={theme.fontColor}>
              Report filters
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} xl={7}>
                <FilterList initFilters={initFilters} activeFilters={activeFilters} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={theme.fontColor}>
                  These filters will be applied to all pages in the report. You can also set them individually per page
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </If>

        <Grid item xs={12}>
          <TitleActionButtons />
        </Grid>
      </Grid>
    </ReportTitlePage>
  </div>
);

export default TitlePage;
