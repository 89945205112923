import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { DataCard } from 'modules/shared/components';

import config from './config';
import theme from './SummaryDataCards.module.scss';

const SummaryDataCards = ({ databoxes }) => (
  <Grid className={theme.dataCardsContainer} container spacing={2}>
    {config.map(({ title, firstValue, secondValue, color }) => {
      return (
        <DataCard
          key={title}
          title={title}
          color={color}
          firstValue={databoxes[firstValue.key]}
          firstLabel={firstValue.isPercent ? '%' : ` ${firstValue.label}`}
          secondValue={secondValue && databoxes[secondValue.key]}
          secondLabel={secondValue && (secondValue.isPercent ? '%' : ` ${secondValue.label}`)}
          noValue={
            secondValue
              ? !Number.isFinite(databoxes[firstValue.key]) || !Number.isFinite(databoxes[secondValue.key])
              : !Number.isFinite(databoxes[firstValue.key])
          }
        />
      );
    })}
  </Grid>
);

SummaryDataCards.propTypes = {
  databoxes: PropTypes.object.isRequired,
};

export default SummaryDataCards;
