const LANDING_PATH = {
  asset: 'productList',
  live: 'dashboard',
  work: 'base',
  people: 'base',
  space: 'reportHistory',
  location: 'locationSummary',
};

export default LANDING_PATH;
