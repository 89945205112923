import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import cn from 'classnames';
import { PageHeader } from 'modules/shared/components';
import { ReportDataCards, VerticalBarGraph } from 'modules/liveLook/components';
import { isEmpty } from 'lodash';

import { pageTypes } from 'modules/liveLook/constants';
import databoxesToEditorData from 'modules/liveLook/utils/dataBoxesToEditorData';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';
import graphDeskConfig from './graphDeskConfig';
import renderFilters from '../../utils/activeFiltersPdf';
import styles from '../../LLPrintablePdfPage.module.scss';
import graphStyles from '../../styles/verticalGraph.module.scss';

const SummaryKeyFindingsOccupancy = ({
  databoxes,
  databoxesSet,
  editableTextReport,
  data,
  scaleValues,
  deskAndBreakGraphData,
  deskAndBreakScaleValues,
  returnPercentageSwitcher,
  returnPercentageSwitcherDeskAndBreak,
  activeFilters,
  textVariables,
}) => {
  const editorRef = useRef();
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));

  return (
    <div className={cn(styles.pdfPage)}>
      <Grid style={{ paddingTop: '20px', paddingLeft: '30px', paddingRight: '10px' }} container spacing={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader pdfMode title="Executive Summary - Key Findings - Occupancy" />
          </Grid>
        </Grid>
        {!shouldRenderFilters && renderFilters(activeFilters)}
        <Grid xs={6}>
          <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.occupancy} />

          <Grid ref={editorRef} style={{ paddingTop: 10 }}>
            <PDFTextEditorContainer
              data={databoxesToEditorData(databoxesSet, textVariables)}
              onSave={() => {}}
              text={editableTextReport}
              isKeyFindings
              hasFilters={shouldRenderFilters}
            />
          </Grid>
        </Grid>

        <Grid item xs={6} style={{ marginTop: -16 }}>
          <div className={cn(graphStyles.verticalGraph)}>
            <VerticalBarGraph
              customHeader
              onSwitcherChange={() => {}}
              title="Daily Comparisons"
              data={data}
              scaleValues={scaleValues}
              config={graphDeskConfig}
              switcher={returnPercentageSwitcher}
              returnPercentageSwitcher={returnPercentageSwitcher}
              isSummaryPdf
              pdfCustomHeader
              isLiveLook
            />
          </div>
          <div className={cn(graphStyles.verticalGraph)}>
            <VerticalBarGraph
              title="Desk and breakout seat usage by team zones"
              data={deskAndBreakGraphData}
              scaleValues={deskAndBreakScaleValues}
              deskGraphOnSwitcherChange={() => {}}
              config={graphDeskConfig}
              switcher={returnPercentageSwitcherDeskAndBreak}
              returnPercentageSwitcher={returnPercentageSwitcherDeskAndBreak}
              isSummaryPdf
              pdfCustomHeader
              isLiveLook
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SummaryKeyFindingsOccupancy;
