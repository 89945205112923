/* eslint-disable no-shadow */
import React from 'react';
import { chunk, cloneDeep } from 'lodash';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { toOccUtilGraphData } from 'modules/spaceLook/utils';
import { reportPageTypes, byDivAndDepGraphTypes } from 'modules/spaceLook/constants';

import {
  PageHeader,
  ReportText,
  FilterHandler,
  DesksUsageByDivAndDepGraph,
  ReportDataCards,
  ReportFilter,
} from 'modules/spaceLook/components';
import { useHasScroll } from 'modules/shared/hooks';

const DesksByDivAndDep = ({ isLoading, pdfMode, databoxes, divisionData, departmentData }) => {
  let pdfGraphData;
  if (pdfMode) {
    pdfGraphData = [
      ...chunk(divisionData, 5).map((chunk) => ({
        data: toOccUtilGraphData(chunk, 'name'),
        type: byDivAndDepGraphTypes.div,
      })),
      ...chunk(departmentData, 5).map((chunk) => ({
        data: toOccUtilGraphData(chunk, 'name'),
        type: byDivAndDepGraphTypes.dep,
      })),
    ];
  }
  const scroll = useHasScroll();

  return (
    <Choose>
      <When condition={pdfMode}>
        {pdfGraphData.map(({ data, type }, idx) => (
          <div ref={scroll.ref} key={idx} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode })}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <PageHeader title={`Desks by ${type}`} text="Desk Utilisation" />
              </Grid>

              <Grid item xs={12}>
                <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.desks} />
              </Grid>

              <Grid item xs={12}>
                <DesksUsageByDivAndDepGraph
                  divisionData={type === byDivAndDepGraphTypes.div ? data : null}
                  departmentData={type === byDivAndDepGraphTypes.dep ? data : null}
                />
              </Grid>

              <Grid item xs={12}>
                <ReportText
                  getParagraphs={['desksDivHighestOcc', 'desksDivHighestUtil', 'desksDivLowestOcc']}
                  divOrDepData={
                    type === byDivAndDepGraphTypes.div ? cloneDeep(divisionData) : cloneDeep(departmentData)
                  }
                  type={type}
                  scrollRef={scroll}
                />
              </Grid>
            </Grid>
          </div>
        ))}
      </When>

      <Otherwise>
        <div className={cn({ blured: isLoading })}>
          <Grid container spacing={4}>
            <Grid item xs={10}>
              <PageHeader title="Desks by Division / Department" text="Desk Utilisation" />
            </Grid>

            <Grid item xs={2} align="right">
              <FilterHandler pageType={reportPageTypes.desks}>
                <ReportFilter pageType={reportPageTypes.desks} />
              </FilterHandler>
            </Grid>

            <Grid item xs={12}>
              <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.desks} cropped />
            </Grid>

            <Grid item xs={12}>
              <DesksUsageByDivAndDepGraph
                divisionData={toOccUtilGraphData(divisionData, 'name')}
                departmentData={toOccUtilGraphData(departmentData, 'name')}
              />
            </Grid>

            <Grid item xs={12}>
              <ReportText
                getParagraphs={['desksDivHighestOcc', 'desksDivHighestUtil', 'desksDivLowestOcc']}
                divOrDepData={cloneDeep(divisionData)}
                type={byDivAndDepGraphTypes.div}
              />
            </Grid>
          </Grid>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default DesksByDivAndDep;
