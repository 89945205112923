import React from 'react';
import PropTypes from 'prop-types';

import theme from './GraphYScale.module.scss';

const GraphYScale = ({ isSwitcherOn, values, marginBottom, minWidth }) => (
  <div className={theme.container} style={{ minWidth }}>
    <div className={theme.scaleWrapper}>
      {values.map((value) => (
        <div className={theme.scaleItem} key={value}>
          {isSwitcherOn ? value?.toFixed(1) : value}
        </div>
      ))}
    </div>
    <div style={{ height: `${marginBottom}` }} />
  </div>
);

GraphYScale.defaultProps = {
  values: ['100%', '80%', '60%', '40%', '20%', '0%'],
  minWidth: '80px',
  isSwitcherOn: false,
};

GraphYScale.propTypes = {
  isSwitcherOn: PropTypes.bool,
  minWidth: PropTypes.string,
  marginBottom: PropTypes.string.isRequired,
  // eslint-disable-next-line consistent-return
  values(props, propName, componentName) {
    if (props[propName].length < 2)
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}.
       Needs to be an array of 2 or more items. Validation failed.`,
      );
  },
};

export default GraphYScale;
