/* eslint-disable no-shadow */
import React, { useEffect, useReducer, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAuthSelector } from 'modules/auth/hooks';
import { setScaleValues, toLondonTimeZone } from 'pages/_privatePages/liveLook/utils';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, filtersToQuery } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { getPdfMode } from 'modules/spaceLook/selectors';
import {
  fetchCombinedTeamZoneOccupancy,
  fetchCombinedOccupancyDataboxes,
  SET_GRAPH_SWITCHER,
  fetchGraphsText,
  saveGraphsText,
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
  fetchCombinedOccupancyVariables,
} from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import { isEmpty } from 'lodash';
import { GRAPH_SCALES } from 'modules/liveLook/constants';
import setScaleOption, { daysDifference, isInPercentages } from '../utils/common';
import CombinedTeamZoneOccupancy from './CombinedTeamZoneOccupancy';
import reducer, { initialState } from './_reducer';
import { setDataGroupedByDays } from '../utils/setDataForOverTime';
import toCombinedOccTimeGraphData from '../LLPrintablePdfPage/components/CombinedTeamZoneOccupancy/data.utils.pdf';

function CombinedTeamZoneOccupancyContainer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    scale,
    value,
    databoxRequestStatus,
    occupancyDataboxes,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
    textVariables,
    combinedOccVariablesRequestStatus,
  } = state;

  const requestStatuses = [
    databoxRequestStatus,
    requestStatus,
    textReportRequestStatus,
    combinedOccVariablesRequestStatus,
  ];

  const [companyId] = useAuthSelector(['currentCompany']);
  const [startDate, endDate, workingHours, activeDeskFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'deskFilters.activeDeskFilters',
    'common.archive',
  ]);
  const daysDiffernce = useMemo(() => daysDifference(endDate, startDate), [endDate, startDate]);
  const scaleOption = useMemo(() => setScaleOption(scale, daysDiffernce), [scale, daysDiffernce]);
  const getScaleLabel = isEmpty(scaleOption) ? scale : scaleOption === 'hour' ? GRAPH_SCALES.hour : scaleOption;

  const pdfMode = useSelector(getPdfMode);
  const filters = filtersToQuery(activeDeskFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    // let isCanceled = false;
    const timeout = setTimeout(() => {
      fetchCombinedTeamZoneOccupancy(dispatch, false, {
        companyId,
        dateAfter,
        dateBefore,
        scaleOption,
        workingHours,
        query: filters,
        archive,
      });

      fetchCombinedOccupancyDataboxes(dispatch, false, {
        companyId,
        workingHours,
        dateAfter,
        dateBefore,
        query: filters,
        archive,
      });
      fetchCombinedOccupancyVariables(dispatch, false, {
        companyId,
        dateAfter,
        dateBefore,
        workingHours,
        query: filters,
        archive,
      });

      fetchGraphsText(dispatch, false, {
        companyId,
        page: 'combined_oc',
      });
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [companyId, archive, state.building, dateAfter, dateBefore, workingHours, scaleOption, filters]);

  const databoxesSet = {
    occupancyDataboxes,
  };
  const onScaleChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_SCALE(value)), []);
  const onValueChange = useCallback(({ target: { value } }) => dispatch(SET_GRAPH_VALUE(value)), []);
  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  const peakOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        occupancyDataboxes,
        occupancyDataboxes?.max_combined_occupancy,
        occupancyDataboxes?.percentage_max_combined_occupancy,
      ),
    [returnPercentageSwitcher, occupancyDataboxes],
  );

  const avgOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        occupancyDataboxes,
        occupancyDataboxes?.avg_combined_occupancy,
        occupancyDataboxes?.percentage_avg_combined_occupancy,
      ),
    [returnPercentageSwitcher, occupancyDataboxes],
  );
  const { niceMax, scaleValues } = setScaleValues(peakOccupancy);
  const dataGroupedByDays = setDataGroupedByDays(data, scale, toLondonTimeZone);

  const linesData = {
    peakOccupancy,
    avgOccupancy,
  };

  const graphData = toCombinedOccTimeGraphData(
    {
      sourceData: dataGroupedByDays,
      valueType: value,
      scale: getScaleLabel,
      returnPercentageSwitcher,
      niceMax,
    },
    false,
  );

  return (
    <Choose>
      <When condition={requestStatuses.some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={requestStatuses.every((item) => isSuccess(item))}>
        <CombinedTeamZoneOccupancy
          scale={getScaleLabel}
          daysDiffernce={daysDiffernce}
          onScaleChange={onScaleChange}
          onValueChange={onValueChange}
          onSwitcherChange={onSwitcherChange}
          value={value}
          linesData={linesData}
          scaleValues={scaleValues}
          niceMax={niceMax}
          graphData={graphData}
          databoxesSet={databoxesSet}
          pdfMode={pdfMode}
          data={data}
          returnPercentageSwitcher={returnPercentageSwitcher}
          databoxes={occupancyDataboxes}
          editableTextReport={editableTextReport}
          textVariables={textVariables}
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'combined_oc', text });
          }}
          archive={archive}
        />
      </When>
      <When condition={requestStatuses.some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default CombinedTeamZoneOccupancyContainer;
