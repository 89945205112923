import { createReducer } from '@reduxjs/toolkit';

import { requestStatuses } from 'modules/shared/constants';
import { toCombinedInitFilters } from 'modules/spaceLook/utils';

import {
  FETCH_INIT_FILTERS,
  FETCH_COMBINED_AVAILABLE_FILTERS,
  FETCH_DESKS_AVAILABLE_FILTERS,
  FETCH_MEETINGS_AVAILABLE_FILTERS,
  FETCH_BREAKOUT_AVAILABLE_FILTERS,
  FETCH_DOWNLOADS_AVAILABLE_FILTERS,
  RESET_REPORT_DATA,
} from 'modules/spaceLook/actions';

const fetchAvailableActionCreator = (action, dataKey) => ({
  [action.pending]: (state) => ({
    ...state,
    requestStatus: requestStatuses.pending,
  }),
  [action.success]: (state, { payload }) => ({
    ...state,
    requestStatus: requestStatuses.success,
    available: {
      ...state.available,
      [dataKey]: payload,
    },
  }),
  [action.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
  }),
});

const initState = {
  requestStatuses: requestStatuses.default,
  init: {
    combined: null,
    desks: null,
    meetings: null,
    breakout: null,
  },
  available: {
    combined: null,
    desks: null,
    meetings: null,
    breakout: null,
  },
};

const filterOptionsReducer = createReducer(initState, {
  /** INIT */
  [FETCH_INIT_FILTERS.pending]: (state) => ({
    ...state,
    requestStatus: requestStatuses.pending,
  }),
  [FETCH_INIT_FILTERS.success]: (state, { payload: { desks, meetings, breakout } }) => {
    const combined = toCombinedInitFilters(desks, meetings, breakout);

    return {
      ...state,
      requestStatus: requestStatuses.success,
      init: {
        ...state.init,
        combined,
        desks,
        meetings,
        breakout,
      },
      available: {
        ...state.available,
        combined,
        desks,
        meetings,
        breakout,
      },
    };
  },
  [FETCH_INIT_FILTERS.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
  }),

  /** AVAILABLE */
  ...fetchAvailableActionCreator(FETCH_COMBINED_AVAILABLE_FILTERS, 'combined'),
  ...fetchAvailableActionCreator(FETCH_DESKS_AVAILABLE_FILTERS, 'desks'),
  ...fetchAvailableActionCreator(FETCH_MEETINGS_AVAILABLE_FILTERS, 'meetings'),
  ...fetchAvailableActionCreator(FETCH_BREAKOUT_AVAILABLE_FILTERS, 'breakout'),

  [FETCH_DOWNLOADS_AVAILABLE_FILTERS.pending]: (state) => ({
    ...state,
    requestStatus: requestStatuses.pending,
  }),
  [FETCH_DOWNLOADS_AVAILABLE_FILTERS.success]: (state, { payload }) => ({
    ...state,
    requestStatus: requestStatuses.success,
    available: {
      ...state.available,
      combined: payload.combined,
      desks: payload.desks,
    },
  }),
  [FETCH_DOWNLOADS_AVAILABLE_FILTERS.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
  }),

  [RESET_REPORT_DATA]: () => ({ ...initState }),
});

export default filterOptionsReducer;
