export const GRAPHSCALES = [
  {
    period: '1 hour',
  },
  {
    period: 'Half day',
  },
  {
    period: 'Day',
  },
];
