import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getPropertyNamesMap } from 'modules/shared/selectors';

import './HistoryReportPopup.scss';

const HistoryReportPopup = ({ buildings }) => {
  const { propertyNamesMap } = useSelector(getPropertyNamesMap);

  return (
    <div className="HistoryReportPopup">
      <ul className="history-report-popup">
        {buildings.map((building) => {
          // eslint-disable-next-line camelcase
          const propertyInList = propertyNamesMap.find(({ property_id }) => property_id === building);
          const propertyName = (propertyInList && propertyInList.property_name) || building;

          return (
            <li className="building-item" key={building}>
              {propertyName}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

HistoryReportPopup.propTypes = {
  buildings: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default HistoryReportPopup;
