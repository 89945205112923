const config = [
  {
    iconType: 'round',
    label: '1 Person',
    color: '#A2559D',
  },
  {
    iconType: 'round',
    label: '2 People',
    color: '#0D6F85',
  },
  {
    iconType: 'round',
    label: '3-4 People',
    color: '#00AAD2',
  },
  {
    iconType: 'round',
    label: '5-6 People',
    color: '#B4CC04',
  },
  {
    iconType: 'round',
    label: '7-8 People',
    color: '#F6A000',
  },
  {
    iconType: 'round',
    label: '≥9 People',
    color: '#FF5733',
  },
];

export default config;
