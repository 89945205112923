import { pick } from 'lodash';

const keysToPick = ['databoxes', 'buildingUtilizationData', 'floorUtilizationData'];

const getMeetingsByBuildingAndFloorData = ({ report: { meetings } }) => ({
  dataRequestStatuses: pick(meetings.requestStatuses, keysToPick),
  data: pick(meetings.data, keysToPick),
  shouldRefetchData: pick(meetings.shouldRefetchData, keysToPick),
});

export default getMeetingsByBuildingAndFloorData;
