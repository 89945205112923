/* eslint-disable prefer-spread */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useReducer, useCallback } from 'react';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, filtersToQuery, toByUsageDeskGraphData } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import {
  fetchDeskDataboxes,
  SET_SECOND_GRAPH_SWITCHER,
  fetchGraphsText,
  saveGraphsText,
  fetchDeskByDivisionSummary,
  fetchDeskVariables,
} from 'modules/liveLook/actions';
import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';

import DesksAmberState from './DeskAmberState';
import { setScaleValues } from '../utils';
import reducer, { initialState } from './_reducer';

function DesksAmberStateContainer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    deskDataboxes,
    databoxRequestStatus,
    editableTextReport,
    textReportRequestStatus,
    returnPercentageSwitcherDeskGraph,
    deskGraphRequestStatus,
    deskGraphData,
    deskVariableRequestStatus,
    textVariables,
  } = state;

  const requestStatuses = [
    databoxRequestStatus,
    textReportRequestStatus,
    deskGraphRequestStatus,
    deskVariableRequestStatus,
  ];

  const [companyId] = useAuthSelector(['currentCompany']);
  const [startDate, endDate, workingHours, activeDeskFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'deskFilters.activeDeskFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeDeskFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled;

    fetchDeskDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchDeskVariables(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'desk_amber',
    });

    fetchDeskByDivisionSummary(dispatch, isCanceled, {
      companyId,
      buildingId: state.building,
      workingHours,
      query,
      dateAfter,
      dateBefore,
      archive,
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, state.building, dateAfter, dateBefore, workingHours, query]);

  const databoxesSet = {
    deskDataboxes,
  };

  const mappedData = (data) => {
    const arrData = [];
    for (const key in data) {
      arrData.push(Object.assign(data[key], { name: key }));
    }
    return arrData;
  };

  const deskGraphPeakOccupancy = Math.max.apply(
    Math,
    mappedData(deskGraphData).map((key) => key[returnPercentageSwitcherDeskGraph ? 'usage' : 'percentage_usage']),
  );
  const deskGraph = setScaleValues(deskGraphPeakOccupancy);

  const deskGraphOnSwitcherChange = useCallback(
    () => dispatch(SET_SECOND_GRAPH_SWITCHER(!returnPercentageSwitcherDeskGraph)),
    [returnPercentageSwitcherDeskGraph],
  );

  return (
    <Choose>
      <When condition={requestStatuses.some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={requestStatuses.every((item) => isSuccess(item))}>
        <DesksAmberState
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'desk_amber', text });
          }}
          deskGraphData={toByUsageDeskGraphData(deskGraphData, returnPercentageSwitcherDeskGraph)}
          deskGraphScaleValues={deskGraph.scaleValues}
          deskGraphOnSwitcherChange={deskGraphOnSwitcherChange}
          returnPercentageSwitcherDeskGraph={returnPercentageSwitcherDeskGraph}
          editableTextReport={editableTextReport}
          databoxes={deskDataboxes}
          databoxesSet={databoxesSet}
          textVariables={textVariables}
          archive={archive}
        />
      </When>
      <When condition={requestStatuses.some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default DesksAmberStateContainer;
