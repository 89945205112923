import { SERIES_ACTION_GROUP } from 'modules/workLook/constants';

const toDataCardsData = (data, groupType) => {
  if (!data || !data.length) return null;

  switch (groupType) {
    case null:
      return data.filter((series) => SERIES_ACTION_GROUP.singleValue.includes(series.action));
    default:
      return null;
  }
};

export default toDataCardsData;
