/* eslint-disable camelcase */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Grid, Button, makeStyles, ThemeProvider } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'modules/auth/selectors';
import { isAdmin, isClient, isPartner, isViewOnly } from 'modules/shared/utils';
import { PageSelectorItem } from 'modules/shared/components';
import { showModal } from 'modules/modalsSystem/actions';
import URLS from 'pages/constants/urls';
import { SORT_ORDER } from 'modules/workLook/constants';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { SET_PAGE_DATA, SET_SECTION_ID } from 'modules/workLook/actions';
import { editTheme } from 'modules/modalsSystem/components/modalsWL/options';

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 16px',
    textTransform: 'capitalize',
    borderRadius: '20px',
  },
}));

const PageSelector = ({ pages, isPublished, onEditPageClick }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(getUser);
  const classes = useStyles();
  const { people_look_editing_rights } = user;
  const isAdminRole = isAdmin(user?.role) || isPartner(user?.role);
  const isClientRole = isClient(user?.role);
  const hasViewRights = isViewOnly(people_look_editing_rights);
  const clientRights = isClientRole && !hasViewRights && !isPublished;

  const { typeId, reportId, sectionId, pageId } = useParams();

  const onCreatePageClick = () => {
    dispatch(SET_SECTION_ID(sectionId));
    dispatch(SET_PAGE_DATA({ action: 'create' }));
    dispatch(showModal(modalCodes.WL_CREATE_PAGE_MODAL));
  };

  return (
    <Grid container spacing={3}>
      {Object.values(pages)
        .sort(SORT_ORDER.byOrder)
        .map(({ id, name }) => {
          const pagePath = URLS.workLook.section
            .replace(':typeId', typeId)
            .replace(':reportId', reportId)
            .replace(':sectionId', sectionId)
            .replace(':pageId', id);

          return (
            <Grid item key={id}>
              <PageSelectorItem
                hasViewRights={clientRights}
                isAdminRole={isAdminRole}
                isPublished={isPublished}
                onEditPageClick={onEditPageClick}
                link={pagePath}
                label={name}
                active={id === Number(pageId)}
              />
            </Grid>
          );
        })}
      <If condition={(!isPublished && isAdminRole) || clientRights}>
        <Grid item>
          <ThemeProvider theme={editTheme}>
            <Button
              variant="contained"
              color="primary"
              classes={{ root: classes.root }}
              onClick={() => onCreatePageClick()}
              children="Create Page"
            />
          </ThemeProvider>
        </Grid>
      </If>
    </Grid>
  );
};

export default PageSelector;
