/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { dateToString } from 'modules/spaceLook/utils';
import { getPdfMode } from 'modules/spaceLook/selectors';

import config from './config';
import theme from './DetailsDataCards.module.scss';

const DetailsDataCards = ({
  detailsData: {
    property_data,
    desks_data: { standard_desks_count, meeting_spaces_count },
    survey_stats: { timeslots_per_day_desks, total_observations },
    dates_data: { begin_date, end_date, total_days },
  },
}) => {
  const pdfMode = useSelector(getPdfMode);

  const firstCard =
    property_data.length > 1
      ? `${property_data.length} properties`
      : `${property_data[0].location_data.address}, ${property_data[0].location_data.city}`;

  const secondCard = `From ${dateToString(begin_date)} to ${dateToString(end_date)} (${total_days} day study)`;

  const thirdCard = `${standard_desks_count} standard desk + ${meeting_spaces_count} meeting spaces`;

  const fourthCard = `Surveyed ${Math.ceil(timeslots_per_day_desks)} time(s) a day
  (${total_observations} observations - desks and meeting areas)`;

  const labels = [firstCard, secondCard, thirdCard, fourthCard];

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      {config.map(({ icon, color }, i) => (
        <div key={i} style={{ backgroundColor: color }} className={theme.cardItem}>
          <img src={icon} className={theme.icon} />
          <p className={theme.label}>{labels[i]}</p>
        </div>
      ))}
    </div>
  );
};

export default DetailsDataCards;
