import React from 'react';
import { Typography } from '@material-ui/core';
import theme from './modalInnerHeader.module.scss';

const ModalInnerHeader = ({ title }) => {
  return (
    <div className={theme.header}>
      <Typography variant="h5">{title}</Typography>
    </div>
  );
};

export default ModalInnerHeader;
