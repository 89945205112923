/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useRef, useState } from 'react';
import { convertToRaw } from 'draft-js';
import { useSelector } from 'react-redux';

import { getUser } from 'modules/auth/selectors';
import { isAdmin, isPartner } from 'modules/shared/utils';
import { requestStatuses } from 'modules/shared/constants';
import * as TextFieldStorageManager from 'modules/shared/utils/TextFieldStorageManager';

const removeAllMetaFields = (data) => {
  if (!data || typeof data !== 'object') return;

  // eslint-disable-next-line no-param-reassign
  delete data?._meta;

  const values = Array.isArray(data) ? data : Object.values(data);

  for (let i = 0; i < values.length; i++) {
    const item = values[i];

    if (typeof item === 'object') {
      removeAllMetaFields(item);
    }
  }
};

const useTextEditorActions = (
  editorState,
  setEditorState,
  onSave,
  onRevert,
  isPermitted,
  key,
  setKey,
  chartName,
  isSaveLocallyHide,
) => {
  const editorRef = useRef(null);
  const { user } = useSelector(getUser);
  const isAdminRole = isAdmin(user?.role);
  const isPartnerRole = isPartner(user?.role);

  const [lastSavedState, setLastSavedState] = useState(editorState);
  const [saveRequestStatus, setSaveRequestStatus] = useState(requestStatuses.default);
  const [isEditable, setIsEditable] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConfirmModal, updateConfirmModal] = useState(false);

  const _onRevert = useCallback(async () => {
    TextFieldStorageManager.removeItem(chartName);
    TextFieldStorageManager.removeTextField(`${chartName}.TextField`);
    TextFieldStorageManager.removeSavedLocallyItem(chartName);
    TextFieldStorageManager.removeSave(chartName);

    setSaveRequestStatus(requestStatuses.success);
    setShowSuccess(true);
    setIsEditable(false);

    onRevert();
  });

  const _onSaveLocally = useCallback(async () => {
    if (isSaveLocallyHide) return;
    const contentRaw = convertToRaw(editorState.getCurrentContent());
    removeAllMetaFields(contentRaw);

    const dataToSave = JSON.stringify(contentRaw);
    TextFieldStorageManager.setSavedLocallyItem(chartName, dataToSave);
    TextFieldStorageManager.setItem(chartName, dataToSave);
    TextFieldStorageManager.setSaveLocalItem(chartName, true);
    TextFieldStorageManager.setTextFieldsList(chartName);

    setSaveRequestStatus(requestStatuses.success);
    setShowSuccess(true);
    setIsEditable(false);
  });

  const toggleConfirmModal = useCallback(() => {
    updateConfirmModal(!showConfirmModal);
  });

  const _onSaveClick = () => {
    if (isAdminRole || isPartnerRole) {
      toggleConfirmModal();
      return;
    }

    setSaveRequestStatus(requestStatuses.pending);

    const contentRaw = convertToRaw(editorState.getCurrentContent());

    onSave(contentRaw)
      .then(() => {
        setSaveRequestStatus(requestStatuses.success);
        setShowSuccess(true);
        setIsEditable(false);
      })
      .catch(() => {
        setSaveRequestStatus(requestStatuses.failure);
        setShowError(true);
        setIsEditable(false);
      });
  };

  const _onConfirmSaveClick = () => {
    toggleConfirmModal();
    setSaveRequestStatus(requestStatuses.pending);
    TextFieldStorageManager.removeItem(chartName);
    TextFieldStorageManager.removeTextField(`${chartName}.TextField`);
    TextFieldStorageManager.removeSavedLocallyItem(chartName);
    TextFieldStorageManager.removeSave(chartName);
    const contentRaw = convertToRaw(editorState.getCurrentContent());
    onSave(contentRaw)
      .then(() => {
        setSaveRequestStatus(requestStatuses.success);
        setShowSuccess(true);
        setIsEditable(false);
      })
      .catch(() => {
        setSaveRequestStatus(requestStatuses.failure);
        setShowError(true);
        setIsEditable(false);
      });
  };

  const _onCancelClick = () => {
    setEditorState(lastSavedState);
    setIsEditable(false);
  };

  const _onTextAreaClick = () => {
    if (isPermitted && !isEditable) {
      setLastSavedState(editorState);
      setIsEditable(true);
      setKey(key + 1);

      // https://github.com/draft-js-plugins/draft-js-plugins/issues/1511
      setTimeout(() => editorRef.current.focus(), 0);
    }
  };

  const _onSuccessSnackbarClose = useCallback(() => setShowSuccess(false), []);

  const _onErrorSnackbarClose = useCallback(() => setShowError(false), []);

  return {
    editorRef,
    isEditable,
    saveRequestStatus,
    _onRevert,
    _onSaveClick,
    _onSaveLocally,
    _onConfirmSaveClick,
    _onCancelClick,
    _onTextAreaClick,
    showSuccess,
    showError,
    _onSuccessSnackbarClose,
    _onErrorSnackbarClose,
    showConfirmModal,
    toggleConfirmModal,
  };
};

export default useTextEditorActions;
