import { omit } from 'lodash';

export const ASSET_STATUSES = {
  NONE: 'None',
  RV: 'rv',
  OD: 'od',
  PA: 'pa',
  RM: 'rm',
};

export const ASSET_STATUSES_LABEL = {
  [ASSET_STATUSES.NONE]: 'None',
  [ASSET_STATUSES.RV]: 'Reserved',
  [ASSET_STATUSES.OD]: 'Ordered',
  [ASSET_STATUSES.PA]: 'Publicly available',
  [ASSET_STATUSES.RM]: 'Removed',
};

export const ASSET_STATUSES_FILTER_AVAILABLE = Object.values(omit(ASSET_STATUSES_LABEL, [ASSET_STATUSES.RM]));
