import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const SAVE_GRAPHS_TEXT = createAsyncAction(createAction('SAVE_GRAPHS_TEXT'));

const saveGraphsText = (dispatch, isCanceled, { companyId, page, text }) => {
  dispatch(SAVE_GRAPHS_TEXT.pending());

  const url = ENDPOINTS.liveLook.graphsText.replace(':companyId', companyId).concat(`?page=${page}`);

  API.put(url, { text })
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(SAVE_GRAPHS_TEXT.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(SAVE_GRAPHS_TEXT.failure());
    });
};

export default saveGraphsText;
