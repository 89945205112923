/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core';
import { PAGE_TYPES, REPORT_TYPES } from 'modules/assetLook/constants';

const reportTypesMap = {
  [REPORT_TYPES.productList]: PAGE_TYPES.productList,
  [REPORT_TYPES.productSummary]: PAGE_TYPES.productSummary,
  [REPORT_TYPES.assetList]: PAGE_TYPES.assetList,
};

const useStyles = makeStyles(() => ({
  row: {
    backgroundColor: 'unset !important;',
    borderRadius: 'unset !important',
  },
  cell: {
    padding: '2px',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    color: 'inherit',
    minWidth: '100px',
  },
}));

function PdfListTooltip({ type, pdf_data }) {
  const classes = useStyles();

  const data = useMemo(() => JSON.parse(pdf_data), [pdf_data]);

  const buildings = useMemo(() => data.buildings.map((building) => building.building__name), [data.buildings]);

  const categories = useMemo(
    () =>
      data.buildings
        .map((building) => building.categories)
        .flat()
        .map((category) => category.category__name)
        .filter((v, i, a) => a.indexOf(v) === i),
    [data.buildings],
  );

  const CustomRow = ({ children }) => <tr className={classes.row}>{children}</tr>;
  const CustomCell = ({ children }) => <td className={classes.cell}>{children}</td>;

  return (
    <table>
      <tbody>
        <CustomRow>
          <CustomCell>Report type:</CustomCell>
          <CustomCell>{reportTypesMap[type]}</CustomCell>
        </CustomRow>
        <CustomRow>
          <CustomCell>Buildings:</CustomCell>
          <CustomCell>{buildings.join(', ')}</CustomCell>
        </CustomRow>
        <CustomRow>
          <CustomCell>Categories:</CustomCell>
          <CustomCell>{categories.join(', ')}</CustomCell>
        </CustomRow>
        <CustomRow>
          <CustomCell>Filters:</CustomCell>
          <CustomCell>
            <Choose>
              <When condition={!isEmpty(data.activeFilters)}>
                {data.activeFilters.map((filter) => `${filter.field} (${filter.values.join(', ')})`).join(', ')}
              </When>
              <Otherwise>None</Otherwise>
            </Choose>
          </CustomCell>
        </CustomRow>
        <If condition={type === REPORT_TYPES.productSummary}>
          <CustomRow>
            <CustomCell>Summary fields:</CustomCell>
            <CustomCell>{data.summaryFields.join(', ')}</CustomCell>
          </CustomRow>
        </If>
      </tbody>
    </table>
  );
}

export default PdfListTooltip;
