import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CHECK_ASSET, fetchAssets } from 'modules/assetLook/actions';
import { filtersToQueryAL, toRequestBody } from 'modules/assetLook/utils';
import { getActiveCompany } from 'modules/shared/selectors';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { BUILDING_ID, CATEGORY_ID, PRODUCT_ID, ASSET_FIELDS } from 'modules/assetLook/constants';

import { FixedDataTable, TextPlaceholder } from 'modules/shared/components';
// eslint-disable-next-line import/no-cycle
import {
  AutoSizer,
  CellSkeleton,
  AssetListCellHeader as CellHeader,
  AssetListCellBody as CellBody,
} from 'modules/assetLook/components';

const columnWidthMap = {
  0: 53,
  1: 150,
};

function trim(el) {
  const result = el
    .trim()
    .replace(/[ ]{2,}/gi, ' ')
    .replace(/\n +/, '\n');
  return result;
}

function AssetListTable() {
  const dispatch = useDispatch();

  const { id: companyId } = useSelector(getActiveCompany);
  const { buildingId, categoryId, productId } = useLocationSearchParams([BUILDING_ID, CATEGORY_ID, PRODUCT_ID]);

  const [assetsRequestStatus, assets, activeFilters, activeTargetFilters, search, tags, basketAssets] =
    useAssetLookSelector([
      'assets.requestStatus',
      'assets.assets',
      'filters.activeFilters',
      'filters.activeTargetFilters',
      'filters.search',
      'common.tags',
      'assets.basketAssets',
    ]);

  const allActiveFilters = [...activeTargetFilters, ...activeFilters];
  const queryFilters = filtersToQueryAL(allActiveFilters);

  const filters = toRequestBody(allActiveFilters, tags);

  useEffect(() => {
    let isCanceled = false;

    fetchAssets(dispatch, isCanceled, {
      companyId,
      buildingId,
      productId,
      filters,
      categoryId,
      queryFilters,
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
    // eslint-disable-next-line
  }, [buildingId, categoryId, productId, JSON.stringify(filters || {})]);

  const onRowClick = (e, i) => {
    const assetId = assets.data[i][ASSET_FIELDS.id];
    dispatch(CHECK_ASSET(assetId));
  };

  const columnWidth = (columnKey, i) => columnWidthMap[i] || 200;

  const data =
    !isEmpty(assets.data) &&
    assets.data
      .map((item) => item)
      .filter(
        ({ _id }) =>
          _id.toString().includes(trim(search.toLowerCase())) || trim(search.toLowerCase()).includes(_id.toString()),
      );

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Choose>
          <When condition={isDefault(assetsRequestStatus) || isLoading(assetsRequestStatus)}>
            <FixedDataTable
              width={width}
              height={height}
              rows={[1, 2, 3]}
              columns={[1, 2, 3, 4, 5]}
              columnWidth={columnWidth}
              ColumnHeader={CellSkeleton}
              ColumnCell={CellSkeleton}
            />
          </When>

          <When condition={isFailure(assetsRequestStatus)}>
            <TextPlaceholder error />
          </When>

          <When condition={isSuccess(assetsRequestStatus)}>
            <Choose>
              <When condition={!isEmpty(data)}>
                <FixedDataTable
                  width={width}
                  height={height}
                  rows={data}
                  onRowClick={onRowClick}
                  columns={[
                    ASSET_FIELDS.checkbox,
                    ASSET_FIELDS.id,
                    ...Object.keys(data[0].list_data),
                    ASSET_FIELDS.status,
                    ASSET_FIELDS.tags,
                  ]}
                  columnWidth={columnWidth}
                  ColumnHeader={CellHeader}
                  ColumnCell={(props) => (
                    <CellBody
                      {...props}
                      buildingId={buildingId}
                      categoryId={categoryId}
                      productId={productId}
                      basketAssets={basketAssets}
                    />
                  )}
                  columnFixed={(key, i) => i < 2}
                  columnFlexGrow={(key, i) => (i < 2 ? 0 : 1)}
                />
              </When>
              <Otherwise>
                <TextPlaceholder noData />
              </Otherwise>
            </Choose>
          </When>
        </Choose>
      )}
    </AutoSizer>
  );
}

export default AssetListTable;
