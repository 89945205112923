import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { isDefault } from 'modules/shared/utils';
import { toPropertyNamesMap } from 'modules/spaceLook/utils';
import { requestStatuses } from 'modules/shared/constants';
import { getPropertyNamesMap } from 'modules/shared/selectors';
import {
  getAppliedFilters,
  getDesksByBuildingAndFloorData,
  getSelectedFilters,
  getPdfMode,
} from 'modules/spaceLook/selectors';
import { fetchDesksDataboxes, fetchDesksByBuildingAndFloorData } from 'modules/spaceLook/actions';
import { LoadingCondition } from 'modules/spaceLook/components';

import DesksByBuildingAndFloor from './DesksByBuildingAndFloor';

const DesksByBuildingAndFloorContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fetchStart, setFetchStart] = useState(false);
  const pdfMode = useSelector(getPdfMode);
  const { dataRequestStatuses, data, shouldRefetchData } = useSelector(getDesksByBuildingAndFloorData);
  const { selectedFiltersUrlParams } = useSelector((store) => getSelectedFilters(store, 'desks'));
  const { appliedFiltersRequestStatus } = useSelector(getAppliedFilters);
  const { propertyNamesMap } = useSelector(getPropertyNamesMap);

  const isNoData = Object.values(data).some((dataItem) => isEmpty(dataItem));
  const isLoading = Object.values(dataRequestStatuses).some((status) => status === requestStatuses.pending);
  const isFiltersHistoryLoading = [requestStatuses.default, requestStatuses.pending].includes(
    appliedFiltersRequestStatus,
  );

  useEffect(() => {
    if (isFiltersHistoryLoading) return;

    if (isDefault(dataRequestStatuses.databoxes) || shouldRefetchData.databoxes) {
      fetchDesksDataboxes(dispatch, id, selectedFiltersUrlParams);
    }
    if (isDefault(dataRequestStatuses.byBuildingAndFloorData) || shouldRefetchData.byBuildingAndFloorData) {
      fetchDesksByBuildingAndFloorData(dispatch, id, selectedFiltersUrlParams);
    }
    setFetchStart(true);
  }, [
    appliedFiltersRequestStatus,
    shouldRefetchData.databoxes,
    shouldRefetchData.byBuildingAndFloorData,
    isFiltersHistoryLoading,
    dataRequestStatuses.databoxes,
    dataRequestStatuses.byBuildingAndFloorData,
    dispatch,
    id,
    selectedFiltersUrlParams,
  ]);

  return (
    <LoadingCondition isLoading={isLoading} isNoData={isNoData} fetchStart={fetchStart}>
      <DesksByBuildingAndFloor
        isLoading={isLoading}
        pdfMode={pdfMode}
        {...data}
        byBuildingAndFloorData={
          !isNoData && {
            data: toPropertyNamesMap(data.byBuildingAndFloorData, propertyNamesMap, isNoData),
            type: data.byBuildingAndFloorData.type,
          }
        }
      />
    </LoadingCondition>
  );
};

export default DesksByBuildingAndFloorContainer;
