import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.white,
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const CustomButtonContained = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      classes={{
        label: classes.text,
        containedPrimary: classes.containedPrimary,
      }}
      color="primary"
      variant="contained"
      // eslint-disable-next-line react/no-children-prop
      children={label}
      {...props}
    />
  );
};

export default CustomButtonContained;
