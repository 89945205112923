/* eslint-disable no-param-reassign */
import { sum } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { sortObjects } from '.';

const findPeakAndLowestDayByOccupancy = (graphData) => {
  const groupedOccValues = graphData.reduce((allDaysObj, dayObj) => {
    const day = dayObj.week_day;
    const percent = dayObj.percent_occupancy;
    // eslint-disable-next-line no-unused-expressions
    allDaysObj[day] ? allDaysObj[day].push(percent) : (allDaysObj[day] = [percent]);

    return allDaysObj;
  }, {});

  const dayAverageValues = Object.keys(groupedOccValues)
    .map((day) => {
      const dayOccArray = groupedOccValues[day];
      return {
        day,
        avgOccupancy: parseFloat((sum(dayOccArray) / dayOccArray.length).toFixed(2)),
      };
    })
    .sort(sortObjects('avgOccupancy'));

  return {
    lowestOcc: dayAverageValues[0],
    highestOcc: dayAverageValues[dayAverageValues.length - 1],
  };
};

export default findPeakAndLowestDayByOccupancy;
