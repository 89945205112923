import React from 'react';
import { TableCell } from '@material-ui/core';

const COLUMNS_MAP = {
  _total: 'Total',
  _condition_ex: 'Excellent',
  _condition_gd: 'Good',
  _condition_avg: 'Average',
  _condition_pr: 'Poor',
  _condition_vpr: 'Very poor',
  _condition_none: 'None',
};

function ProductSummaryCellHeader({ columnKey, height, width }) {
  const label = COLUMNS_MAP[columnKey] || columnKey;

  return (
    <TableCell
      size="small"
      component="div"
      variant="head"
      title={label}
      style={{
        height,
        width,
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {label}
    </TableCell>
  );
}

export default ProductSummaryCellHeader;
