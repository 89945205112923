import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { TableCell, Typography } from '@material-ui/core';

import { PRODUCT_FIELDS } from 'modules/assetLook/constants';

const CellSkeleton = ({ height, width, columnKey, rowIndex }) => (
  <TableCell size="small" component="div" style={{ height, width }}>
    <Choose>
      <When condition={columnKey === PRODUCT_FIELDS.thumbnail && rowIndex >= 0}>
        <Skeleton variant="rect" width="134px" height="100%" style={{ margin: '0 auto' }} />
      </When>
      <Otherwise>
        <Typography variant="body1">
          <Skeleton width="100%" />
        </Typography>
      </Otherwise>
    </Choose>
  </TableCell>
);

export default CellSkeleton;
