import { createAction } from '@reduxjs/toolkit';
import { requestStatuses } from 'modules/shared/constants';

const defaultAction = (action) => createAction(`${action}_${requestStatuses.default}`);
const failureAction = (action) => createAction(`${action}_${requestStatuses.failure}`);
const pendingAction = (action) => createAction(`${action}_${requestStatuses.pending}`);
const successAction = (action) => createAction(`${action}_${requestStatuses.success}`);

const createAsyncAction = (action) => ({
  default: defaultAction(action),
  failure: failureAction(action),
  pending: pendingAction(action),
  success: successAction(action),
});

export default createAsyncAction;
