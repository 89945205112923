/* eslint-disable no-shadow */
import { useLiveLookSelector } from 'modules/liveLook/hooks';

import { pageTypes } from 'modules/liveLook/constants';

function filtersDataContainer({ subtypes, doorSensorData, pageType }) {
  const [
    initFilters,
    activeFilters,
    doorSensorInitFilters,
    activeDoorSensorFilters,
    initDeskFilters,
    activeDeskFilters,
    initMeetFilters,
    activeMeetFilters,
    initBreakFilters,
    activeBreakFilters,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  ] = useLiveLookSelector([
    'filters.initFilters',
    'filters.activeFilters',
    'doorSensorFilters.doorSensorInitFilters',
    'doorSensorFilters.activeDoorSensorFilters',
    'deskFilters.initDeskFilters',
    'deskFilters.activeDeskFilters',
    'meetFilters.initMeetFilters',
    'meetFilters.activeMeetFilters',
    'breakFilters.initBreakFilters',
    'breakFilters.activeBreakFilters',
  ]);

  const setInitFilters = (pageType, subtypes) => {
    if (!doorSensorData && pageType === pageTypes.desks) {
      return subtypes ? initDeskFilters : initDeskFilters.filter((filter) => filter.field !== 'Subtypes');
    }

    if (doorSensorData && pageType === pageTypes.occupancy) {
      return subtypes ? doorSensorInitFilters : doorSensorInitFilters.filter((filter) => filter.field !== 'Subtypes');
    }

    if (!doorSensorData && pageType === pageTypes.meetings) {
      return subtypes ? initMeetFilters : initMeetFilters.filter((filter) => filter.field !== 'Subtypes');
    }

    if (!doorSensorData && pageType === pageTypes.breakout) {
      return subtypes ? initBreakFilters : initBreakFilters.filter((filter) => filter.field !== 'Subtypes');
    }

    return subtypes ? initFilters : initFilters.filter((filter) => filter.field !== 'Subtypes');
  };

  const setInitActiveFilters = (pageType) => {
    if (pageType === pageTypes.desks) {
      return activeDeskFilters;
    }

    if (pageType === pageTypes.occupancy) {
      return activeDoorSensorFilters;
    }

    if (pageType === pageTypes.meetings) {
      return activeMeetFilters;
    }

    if (pageType === pageTypes.breakout) {
      return activeBreakFilters;
    }

    return activeFilters;
  };

  return { initActiveFilters: setInitActiveFilters(pageType), initFilters: setInitFilters(pageType, subtypes) };
}

export default filtersDataContainer;
