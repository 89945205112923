import { isEmpty } from 'lodash';

const DesksPeakOccupancy = ({ graphData }) => {
  if (isEmpty(graphData)) return null;
  const highest = graphData.reduce((max, current) => {
    if (max.percent_max_occupancy < current.percent_max_occupancy) return current;

    return max;
  });

  return `Peak occupancy occured on ${highest.day_of_week} which was measured as ${highest.percent_max_occupancy}%.`;
};

export default DesksPeakOccupancy;
