import { createReducer } from '@reduxjs/toolkit';

import { actionsCreator } from 'modules/spaceLook/utils';
import { requestStatuses } from 'modules/shared/constants';
import {
  FETCH_MEETINGS_DATABOXES,
  FETCH_MEETINGS_PIE_DATA,
  FETCH_MEETINGS_COMPARISON_TEXT_DATA,
  FETCH_MEETINGS_DAILY_HOURLY_GRAPH_DATA,
  FETCH_MEETINGS_BUILDING_UTIL_GRAPH_DATA,
  FETCH_MEETINGS_FLOOR_UTIL_GRAPH_DATA,
  FETCH_MEETINGS_OBSERVED_SIZES_DATA,
  FETCH_MEETINGS_PEAK_AND_AVG_UTIL,
  RESET_REPORT_DATA,
  APPLY_FILTERS,
  RESET_FILTERS,
} from 'modules/spaceLook/actions';

const initState = {
  requestStatuses: {
    databoxes: requestStatuses.default,
    pieData: requestStatuses.default,
    comparisonTextData: requestStatuses.default,
    dailyHourlyGraphData: requestStatuses.default,
    buildingUtilizationData: requestStatuses.default,
    floorUtilizationData: requestStatuses.default,
    peakAndAvgUtilData: requestStatuses.default,
    observedSizesData: requestStatuses.default,
  },
  data: {
    databoxes: null,
    pieData: null,
    comparisonTextData: null,
    dailyHourlyGraphData: null,
    buildingUtilizationData: null,
    floorUtilizationData: null,
    peakAndAvgUtilData: null,
    observedSizesData: null,
  },
  shouldRefetchData: {
    databoxes: false,
    pieData: false,
    comparisonTextData: false,
    dailyHourlyGraphData: false,
    buildingUtilizationData: false,
    floorUtilizationData: false,
    peakAndAvgUtilData: false,
    observedSizesData: false,
  },
};

const meetingsReducer = createReducer(initState, {
  ...actionsCreator(FETCH_MEETINGS_DATABOXES, 'databoxes'),
  ...actionsCreator(FETCH_MEETINGS_PIE_DATA, 'pieData'),
  ...actionsCreator(FETCH_MEETINGS_COMPARISON_TEXT_DATA, 'comparisonTextData'),
  ...actionsCreator(FETCH_MEETINGS_DAILY_HOURLY_GRAPH_DATA, 'dailyHourlyGraphData'),
  ...actionsCreator(FETCH_MEETINGS_BUILDING_UTIL_GRAPH_DATA, 'buildingUtilizationData'),
  ...actionsCreator(FETCH_MEETINGS_FLOOR_UTIL_GRAPH_DATA, 'floorUtilizationData'),
  ...actionsCreator(FETCH_MEETINGS_PEAK_AND_AVG_UTIL, 'peakAndAvgUtilData'),
  ...actionsCreator(FETCH_MEETINGS_OBSERVED_SIZES_DATA, 'observedSizesData'),
  [APPLY_FILTERS]: (state) => ({
    ...state,
    shouldRefetchData: {
      databoxes: true,
      pieData: true,
      comparisonTextData: true,
      dailyHourlyGraphData: true,
      buildingUtilizationData: true,
      floorUtilizationData: true,
      peakAndAvgUtilData: true,
      observedSizesData: true,
    },
  }),
  [RESET_FILTERS]: (state) => ({
    ...state,
    shouldRefetchData: {
      databoxes: true,
      pieData: true,
      comparisonTextData: true,
      dailyHourlyGraphData: true,
      buildingUtilizationData: true,
      floorUtilizationData: true,
      peakAndAvgUtilData: true,
      observedSizesData: true,
    },
  }),

  [RESET_REPORT_DATA]: () => ({ ...initState }),
});

export default meetingsReducer;
