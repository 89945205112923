import { reportPageTypes } from 'modules/spaceLook/constants';

const setValueParams = (key, label, isPercent = false) => ({ key, label, isPercent });

const config = [
  // DESKS
  {
    title: { [reportPageTypes.desks]: 'Desks' },
    firstValue: setValueParams('total_desks', { [reportPageTypes.desks]: 'seats' }),
    secondValue: setValueParams('percent_total_desks', null, true),
    color: '#c4043f',
  },

  {
    title: { [reportPageTypes.desks]: 'Peak Occupancy' },
    firstValue: setValueParams('percent_max_occupancy', null, true),
    secondValue: setValueParams('max_occupancy', { [reportPageTypes.desks]: 'seats' }),
    color: '#0d6f85',
  },

  {
    title: { [reportPageTypes.desks]: 'Average Occupancy' },
    firstValue: setValueParams('percent_avg_occupancy', null, true),
    secondValue: setValueParams('avg_occupancy', { [reportPageTypes.desks]: 'seats' }),
    color: '#00aad2',
  },

  {
    title: { [reportPageTypes.desks]: 'Average Signs of Life' },
    firstValue: setValueParams('percent_avg_signs_of_life', null, true),
    secondValue: setValueParams('avg_signs_of_life', { [reportPageTypes.desks]: 'seats' }),
    color: '#f6a000',
  },

  {
    title: { [reportPageTypes.desks]: 'Peak Utilisation' },
    firstValue: setValueParams('percent_max_utilization', null, true),
    secondValue: setValueParams('max_utilization', { [reportPageTypes.desks]: 'seats' }),
    color: '#a2559d',
  },

  {
    title: { [reportPageTypes.desks]: 'Average Utilisation' },
    firstValue: setValueParams('percent_avg_utilization', null, true),
    secondValue: setValueParams('avg_utilization', { [reportPageTypes.desks]: 'seats' }),
    color: '#c577c0',
  },

  // MEETINGS & BREAKOUT
  {
    title: {
      [reportPageTypes.meetings]: 'Rooms',
      [reportPageTypes.breakout]: 'Spaces',
    },
    firstValue: setValueParams('total_meet_rooms', {
      [reportPageTypes.meetings]: 'rooms',
      [reportPageTypes.breakout]: 'spaces',
    }),
    secondValue: setValueParams('percent_total_meet_rooms', null, true),
    color: '#c4043f',
  },

  {
    title: {
      [reportPageTypes.meetings]: 'Max Rooms Used',
      [reportPageTypes.breakout]: 'Max Spaces Used',
    },
    firstValue: setValueParams('percent_max_room_occupancy', null, true),
    secondValue: setValueParams('max_room_occupancy', {
      [reportPageTypes.meetings]: 'rooms',
      [reportPageTypes.breakout]: 'spaces',
    }),
    color: '#0d6f85',
  },

  {
    title: {
      [reportPageTypes.meetings]: 'Avg Rooms Used',
      [reportPageTypes.breakout]: 'Avg Spaces Used',
    },
    firstValue: setValueParams('percent_avg_room_occupancy', null, true),
    secondValue: setValueParams('avg_room_occupancy', {
      [reportPageTypes.meetings]: 'rooms',
      [reportPageTypes.breakout]: 'spaces',
    }),
    color: '#00aad2',
  },

  {
    title: {
      [reportPageTypes.meetings]: 'Seats',
      [reportPageTypes.breakout]: 'Seats',
    },
    firstValue: setValueParams('total_room_seats', {
      [reportPageTypes.meetings]: 'seats',
      [reportPageTypes.breakout]: 'seats',
    }),
    secondValue: setValueParams('percent_total_room_seats', null, true),
    color: '#f6a000',
  },

  {
    title: {
      [reportPageTypes.meetings]: 'Max Seats Used',
      [reportPageTypes.breakout]: 'Max Seats Used',
    },
    firstValue: setValueParams('percent_max_seats_occupancy', null, true),
    secondValue: setValueParams('max_seats_occupancy', {
      [reportPageTypes.meetings]: 'seats',
      [reportPageTypes.breakout]: 'seats',
    }),
    color: '#a2559d',
  },

  {
    title: {
      [reportPageTypes.meetings]: 'Avg Seats Used',
      [reportPageTypes.breakout]: 'Avg Seats Used',
    },
    firstValue: setValueParams('percent_avg_seats_occupancy', null, true),
    secondValue: setValueParams('avg_seats_occupancy', {
      [reportPageTypes.meetings]: 'seats',
      [reportPageTypes.breakout]: 'seats',
    }),
    color: '#c577c0',
  },
];

export default config;
