import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mention: {
    color: theme.palette.primary.dark,
    display: 'inline-block',
    background: (isActive) => (isActive ? theme.palette.primary.light : theme.palette.error.light),
  },
}));

const NameComponent = ({ contentState, entityKey, children }) => {
  const {
    mention: { isActive },
  } = contentState.getEntity(entityKey).getData();

  const classes = useStyles(isActive);

  return <span className={classes.mention}>{children}</span>;
};

export default NameComponent;
