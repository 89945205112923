const options = {
  layout: {
    padding: {
      right: 100,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: 'right',
    labels: {
      padding: 16,
      fontSize: 14,
      usePointStyle: true,
    },
  },
};

export default options;
