/* eslint-disable no-console */
import { useLocation } from 'react-router-dom';

const useLocationSearchParams = (params) => {
  const query = new URLSearchParams(useLocation().search);

  if (!Array.isArray(params))
    return console.error(`Wrong type of "param" argument. Must be an array, but received ${typeof params}`);

  const res = {};

  params.forEach((param) => {
    if (typeof param === 'string') {
      res[param] = query.get(param);
    } else {
      console.error(`Wrong type of array's item. Must be a string, but received ${typeof param}`);
    }
  });

  return res;
};

export default useLocationSearchParams;
