const config = [
  {
    iconType: 'round',
    legendLabel: 'Occupied',
    color: '#58BFC7',
  },
  {
    iconType: 'round',
    legendLabel: 'Unoccupied ',
    color: '#f1f1f1',
  },
  {
    iconType: 'round',
    legendLabel: 'Occupied (seats)',
    color: '#a2559d',
  },
  {
    legendLabel: 'Max rooms used',
    lineLabel: 'Max rooms used',
    color: '#0d6f85',
    iconType: 'line',
    valueKey: 'peakOccupancy',
  },
  {
    legendLabel: 'Avg rooms used',
    lineLabel: 'Avg rooms used',
    color: '#00aad2',
    iconType: 'line',
    valueKey: 'avgOccupancy',
  },
];

export default config;
