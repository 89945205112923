/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Button, IconButton, Typography, ThemeProvider as BtnTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { getEditGraphId } from 'modules/workLook/selectors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ENDPOINTS } from 'modules/shared/constants';
import isLogged from 'utils/isLogged';
import { isEmpty } from 'lodash';
import { API } from 'modules/shared/utils';
import theme from './editGraphModalContainer.module.scss';
import GeneralSettings from '../GeneralSettings';
import GroupingSettings from '../GroupingSettings';
import SeriesSettings from '../SeriesSettings';
import { GRAPHS_TYPE_LIST } from '../GeneralSettings/generalSettings.constants';
import { btnsTheme } from '../../options';
import { selectStyles } from '../../ModifiersGraphModal/ModifierSettings/components/createModifierForms/select.styles';

const EDIT_CONFIG = {
  general: GeneralSettings,
  grouping: GroupingSettings,
  series: SeriesSettings,
};

const EditGraphModalContainer = React.forwardRef(({ onClose }, ref) => {
  const [activeTab, setActiveTab] = useState('general');
  const [seriesData, setSeriesData] = useState();
  const [deleteGraphAction, setDeleteGraphAction] = useState(false);
  const [typeOf, setTypeOf] = useState('');

  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });
  const [isSeriesLoading, setIsSeriesLoading] = useState(false);
  const [seriesFields, setseriesFields] = useState([]);
  const [seriesPictures, setSeriesPictures] = useState([]);
  const { graphEditData, graphId, reportFields, seriesList, typeOfModal, page, activePageType, reportPictures } =
    useSelector(getEditGraphId);
  const isEdit = typeOfModal === 'edit';
  const getTitle = isEdit ? 'Edit Graph' : 'Create Graph';
  const editTabs = ['general', 'grouping', 'series'];
  const createTabs = ['general', 'grouping'];

  const isDashboard = activePageType === '4';
  const getLeftSections = isEdit ? editTabs : createTabs;
  const isEmptySeries = isEmpty(seriesList);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    position: Yup.string().required('Required'),
    order: isDashboard && Yup.string().required('Required'),
    group_by_field: typeOf === '1' && Yup.string().required('Required'),
  });

  const createGraphInit = {
    page,
    type: '',
    title: '',
    title_alias: '',
    axis_max_limit: '',
    axis_min_limit: '',
    sub_title: '',
    description: '',
    position: '',
    order: '',
    type_of_group: '',
    group_by_field: '',
    is_display_legend: true,
    is_display_title: true,
    is_disabled_limits: false,
  };

  const onChangeActiveTab = (tab) => {
    setActiveTab(tab);
  };
  const {
    type_of_group,
    group_by_field,
    axis_min_limit,
    axis_max_limit,
    title,
    title_alias,
    is_display_title,
    sub_title,
    type,
    description,
    is_disabled_limits,
    is_display_legend,
    order,
    position,
  } = graphEditData || {};

  const formik = useFormik({
    initialValues: isEdit
      ? {
          title,
          title_alias: !title_alias ? '' : title_alias,
          is_display_title,
          sub_title: !sub_title ? '' : sub_title,
          type,
          description: !description ? '' : description,
          type_of_group: !type_of_group ? '' : type_of_group,
          group_by_field: !group_by_field ? '' : group_by_field,
          axis_min_limit: axis_min_limit === null ? '' : axis_min_limit,
          axis_max_limit: axis_max_limit === null ? '' : axis_max_limit,
          position: !position ? (isDashboard ? '10' : '1') : position,
          order: !order ? '' : order,
          is_display_legend,
          is_disabled_limits,
        }
      : createGraphInit,
    validationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line no-param-reassign
      if (['', '2'].includes(values.type_of_group)) values.group_by_field = null;
      const result = JSON.stringify(values, (k, val) => (val === '' ? null : val));
      const url = ENDPOINTS.workLook.editGraphData.replace(':graphId', graphId);
      const urlCreateGraph = ENDPOINTS.workLook.createGraph;

      const config = {
        method: isEdit ? 'put' : 'post',
        url: isEdit ? url : urlCreateGraph,
        headers: {
          Authorization: `Token ${isLogged()}`,
          'Content-Type': 'application/json',
        },
        data: result,
      };
      if (getError) {
        seriesList.map(({ id }) => {
          const seriesUrl = ENDPOINTS.workLook.deleteSeries.replace(':seriesId', id);
          return API.delete(seriesUrl);
        });
      }
      axios(config)
        .then(() => {
          onClose();
          window.location.reload(false);
        })
        .catch((error) => {
          setErrors({
            isError: true,
            errorMsg:
              error?.response?.data?.non_field_errors ||
              error?.response?.data?.type ||
              error?.response?.data?.fields ||
              error?.response?.data?.type_of_group ||
              error?.response?.data?.action ||
              error?.response?.data?.order,
          });
        });
    },
  });

  const getGraphDataType = GRAPHS_TYPE_LIST.find(({ value }) => value === type)?.dataType;
  const getSelectedDataType = GRAPHS_TYPE_LIST.find(({ value }) => value === formik.values.type)?.dataType;
  const getError = getGraphDataType !== getSelectedDataType && !isEmptySeries;
  const [activeSeries, setActiveSeries] = useState({
    displaySeries: false,
    seriesId: 0,
    action: '',
  });

  const onSeriesClick = (id) => {
    setIsSeriesLoading(true);
    const url = ENDPOINTS.workLook.deleteSeries.replace(':seriesId', id);
    API.get(url).then(({ data }) => {
      setIsSeriesLoading(false);
      setseriesFields(
        data.fields.forEach((part, index, theArray) => {
          // eslint-disable-next-line no-param-reassign
          theArray[index] = reportFields.find((field) => field.id === part)?.name;
        }),
      );
      setSeriesPictures(
        data.pictures.forEach((part, index, theArray) => {
          // eslint-disable-next-line no-param-reassign
          theArray[index] = reportPictures.find((field) => field.id === part)?.name;
        }),
      );
      setSeriesData(data);
      setActiveSeries({ displaySeries: true, seriesId: id, action: 'edit' });
    });
  };

  const onCreateSeriesClick = () => {
    setActiveSeries({ displaySeries: true, seriesid: null, action: 'create' });
  };

  const onCancelSeries = () => {
    setActiveSeries({ displaySeries: false, seriesId: 0, action: '' });
  };

  const onDeleteGraphClick = () => {
    const urlDelete = ENDPOINTS.workLook.editGraphData.replace(':graphId', graphId);
    API.delete(urlDelete).then(() => {
      onClose();
      window.location.reload(false);
    });
  };

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Typography variant="h5" className={theme.title}>
            {getTitle}
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={theme.tabsContainer}>
          <div className={theme.leftSection}>
            {getLeftSections.map((tab, id) => (
              <Typography
                key={id}
                onClick={() => onChangeActiveTab(tab)}
                className={theme.tabTitle}
                style={{ backgroundColor: activeTab === tab ? '#e9e8e8' : '' }}
                variant="h6"
              >
                {tab}
              </Typography>
            ))}
            {isEdit && (
              <div>
                <BtnTheme theme={btnsTheme}>
                  <Button
                    size="medium"
                    className={theme.dltButton}
                    color="secondary"
                    variant="outlined"
                    children="Delete Graph"
                    onClick={() => setDeleteGraphAction(true)}
                  />
                  {deleteGraphAction && (
                    <div className={theme.actionsBtnWrapper}>
                      <Button
                        size="medium"
                        color="inherit"
                        variant="outlined"
                        children="Cancel"
                        onClick={() => setDeleteGraphAction(false)}
                      />
                      <Button
                        size="medium"
                        variant="outlined"
                        color="secondary"
                        children="Delete"
                        onClick={() => onDeleteGraphClick()}
                      />
                    </div>
                  )}
                </BtnTheme>
              </div>
            )}
          </div>
          <div className={theme.verticalLine} />
          <div className={theme.rightSection}>
            <ThemeProvider theme={selectStyles}>
              {EDIT_CONFIG[activeTab]({
                formik,
                type,
                graphEditData,
                reportFields,
                seriesList,
                isEdit,
                setTypeOf,
                isDashboard,
                getError,
                getGraphDataType,
                isEmptySeries,
                activeSeries,
                onCancelSeries,
                onSeriesClick,
                onCreateSeriesClick,
                graphId,
                seriesData,
                isSeriesLoading,
                reportPictures,
                errors,
                ref,
              })}
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditGraphModalContainer;
