/* eslint-disable no-shadow */
/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 4;
const INPUT_WIDTH = 250;

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: ({ width }) => width,
    maxWidth: ({ width }) => width,
  },
  menuPaper: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: ({ width, menuWidth }) => menuWidth || width,
  },
  menuItem: {
    maxHeight: `${ITEM_HEIGHT}px`,
  },
}));

function MultiSelect({ width = INPUT_WIDTH, menuWidth, label, disabled, value, menuItems, onClose, onChange }) {
  const classes = useStyles({ width, menuWidth });

  const flatData = typeof menuItems[0] === 'string';

  return (
    <FormControl
      disabled={disabled}
      classes={{
        root: classes.formControl,
      }}
    >
      <If condition={label}>
        <InputLabel children={label} />
      </If>
      <Select
        multiple
        value={value}
        onClose={onClose}
        onChange={onChange}
        input={<Input />}
        renderValue={(value) =>
          flatData ? value.join(', ') : value.map((id) => menuItems.find((item) => item.id === id).label).join(', ')
        }
        MenuProps={{
          PaperProps: {
            className: classes.menuPaper,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          variant: 'menu',
          getContentAnchorEl: null,
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={flatData ? item : item.id}
            value={flatData ? item : item.id}
            dense
            classes={{ root: classes.menuItem }}
          >
            <Checkbox checked={flatData ? value.indexOf(item) > -1 : value.includes(item.id)} color="primary" />
            <ListItemText primary={flatData ? item : item.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;
