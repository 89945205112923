import { findPeakAndLowestDayByOccupancy } from 'utils';

const DesksBusiestDay = ({ graphData }) => {
  const {
    highestOcc: { day },
  } = findPeakAndLowestDayByOccupancy(graphData);

  return `The busiest day (by average occupancy) was ${day}.`;
};

export default DesksBusiestDay;
