/* eslint-disable camelcase */
import React from 'react';
import { isEmpty } from 'lodash';
import TableGraph from '../../TableGraph/TableGraph';

const GraphTableGraphContainer = ({ description, order, sub_title, title, data, isLoading }) => {
  if (isEmpty(data)) return null;
  return (
    <TableGraph
      isLoading={isLoading}
      data={data}
      order={order}
      description={description}
      subTitle={sub_title}
      title={title}
    />
  );
};

export default GraphTableGraphContainer;
