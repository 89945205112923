import { useMemo } from 'react';

import { toSeatsUsageOverTimeGraphData } from 'modules/liveLook/utils';
import { setDataGroupedByDays } from '../utils/setDataForOverTime';
import { setScaleValues, toLondonTimeZone } from '../utils';
import { isInPercentages } from '../utils/common';
// TODO compare other useGetData!!!
const useGetMeetData = ({ data, scale, returnPercentageSwitcher, meetsDataboxes, value }) => {
  const dataGroupedByDays = setDataGroupedByDays(data, scale, toLondonTimeZone);

  const peakOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        meetsDataboxes,
        meetsDataboxes?.max_rooms_usage,
        meetsDataboxes?.percentage_max_rooms_usage,
      ),
    [returnPercentageSwitcher, meetsDataboxes],
  );

  const peakSeatOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        meetsDataboxes,
        meetsDataboxes?.max_seats_usage,
        meetsDataboxes?.percentage_max_seats_usage,
      ),
    [returnPercentageSwitcher, meetsDataboxes],
  );

  const avgOccupancy = useMemo(
    () =>
      isInPercentages(
        returnPercentageSwitcher,
        meetsDataboxes,
        meetsDataboxes?.avg_rooms_usage,
        meetsDataboxes?.percentage_avg_rooms_usage,
      ),
    [returnPercentageSwitcher, meetsDataboxes],
  );

  const peakValue = Math.max(peakOccupancy, peakSeatOccupancy);

  const { niceMax, scaleValues } = setScaleValues(peakValue);

  const linesData = {
    peakOccupancy,
    avgOccupancy,
  };

  const graphData = toSeatsUsageOverTimeGraphData(
    {
      sourceData: dataGroupedByDays,
      valueType: value,
      scale,
      returnPercentageSwitcher,
      niceMax,
    },
    true,
  );

  return { scale: scaleValues, linesData, graphData };
};

export default useGetMeetData;
