/* eslint-disable camelcase */
import React from 'react';

import { useGraphData, useGraphWidgets } from 'modules/workLook/hooks';

import { isEmpty } from 'lodash';
import LargeDataCard from './LargeDataCard';
import EmptyGraph from '../EmptyGraph';

const LargeDataCardContainer = ({
  dataCard,
  isModal,
  id,
  type_of_group,
  activeFilters,
  type,
  isPdf,
  title,
  sub_title,
  isPublished,
  is_display_title,
  ...props
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters, null, type);
  const { isLoadingWidgets, widgetsList } = useGraphWidgets(id);

  if (isEmpty(data))
    return (
      <EmptyGraph
        display={false}
        type={type}
        title={title}
        graphId={id}
        isPublished={isPublished}
        isPdf={isPdf}
        showTitle={is_display_title}
        subtitle={sub_title}
        {...props}
      />
    );

  return (
    <LargeDataCard
      graphId={id}
      isPublished={isPublished}
      modalDetails={!isModal && { ...props, id, type_of_group, activeFilters, type, graphData: data }}
      isLoading={isLoading || isLoadingWidgets}
      data={data[0].data}
      {...props}
      widgets={widgetsList}
      isPdf={isPdf}
      typeOfGroup={type_of_group}
      type={type}
      title={title}
      subtitle={sub_title}
      dataCard={dataCard}
      showTitle={is_display_title}
    />
  );
};

export default LargeDataCardContainer;
