import { PAGE_TYPES } from 'modules/assetLook/constants';

const config = {
  staticColumnsNum: 7,
  productDetailsHeight: 200,
  productDetailsMargin: 16,
  /**
   * The rows per page value were precalculated and hardcoded.
   *
   * Formula: RPP = ((CA - PDh - PDm) - HRh) / Rh
   * Where:
   *   RPP - rows per page
   *   CA  - content area (general height of content on a page)
   *   PDh - product details height (specified above)
   *   PDm - product details margin (specified above)
   *   HRh - header row height (by default it's 36px)
   *   Rh  - row height (by default it's 33px)
   *
   * Please note, the filters may be absent. That impacts CA, thus there is value for "noFilters" and "hasFilter" state.
   *
   * "Next page" - each next page after the first.
   * The formula is the same, except there are no PDh and PDm, since the page has no product card.
   */
  rowsPerPage: {
    [PAGE_TYPES.productList]: {
      hasFilter: 4,
      noFilter: 5,
    },
    [PAGE_TYPES.productSummary]: {
      hasFilter: 9,
      noFilter: 12,
      nextPage: 18,
    },
    [PAGE_TYPES.assetList]: {
      hasFilter: 9,
      noFilter: 12,
      nextPage: 18,
    },
  },
};

export default config;
