import { pick } from 'lodash';

const keysToPick = ['databoxes', 'buildingUtilizationData', 'floorUtilizationData'];

const getBreakoutByBuildingAndFloorData = ({ report: { breakout } }) => ({
  dataRequestStatuses: pick(breakout.requestStatuses, keysToPick),
  data: pick(breakout.data, keysToPick),
  shouldRefetchData: pick(breakout.shouldRefetchData, keysToPick),
});

export default getBreakoutByBuildingAndFloorData;
