import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveCompany } from 'modules/shared/selectors';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { fetchCommonData, fetchCommonDataDoorSensors } from 'modules/liveLook/actions';

import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';

function PreFetchHandler({ children }) {
  const dispatch = useDispatch();

  const { id: companyId } = useSelector(getActiveCompany);
  const [requestStatus, buildings] = useLiveLookSelector(['common.requestStatus', 'common.buildings']);

  useEffect(() => {
    let isCanceled = false;

    fetchCommonData(dispatch, companyId, isCanceled);

    fetchCommonDataDoorSensors(dispatch, companyId, isCanceled);

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, dispatch]);

  return (
    <Choose>
      <When condition={isDefault(requestStatus) || isLoading(requestStatus)}>
        <Loader />
      </When>
      <When condition={isSuccess(requestStatus)}>
        <Choose>
          <When condition={!isEmpty(buildings)}>{children}</When>
          <Otherwise>
            <TextPlaceholder noData />
          </Otherwise>
        </Choose>
      </When>
      <When condition={isFailure(requestStatus)}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default PreFetchHandler;
