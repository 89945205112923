import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { useHasScroll } from 'modules/shared/hooks';
import { getPdfMode } from 'modules/spaceLook/selectors';

import { GraphHeader } from 'components';
import { GraphLegend, GraphYScale } from 'modules/spaceLook/components';

import config from './config';
import options from './options';
import theme from './HourlyGraphMeets.module.scss';

const HourlyGraphMeets = ({ graphData: { data, dayLabels } }) => {
  const pdfMode = useSelector(getPdfMode);
  const { ref, hasHorScroll } = useHasScroll();

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      <GraphHeader label="Meeting Space Utilisation Usage Over Time - Area Wise and Seat Wise" />

      <div className={theme.graphWrapper}>
        <GraphYScale marginBottom={hasHorScroll ? '96px' : '83px'} />

        <div className={theme.graphInner} ref={ref}>
          <div style={{ width: `${dayLabels.length * 192}px`, height: '100%' }}>
            <div className={theme.graph}>
              <Bar key={dayLabels.length} data={data} options={options} />
            </div>

            <div className={theme.labelWrapper}>
              <For each="label" of={dayLabels}>
                <div key={label} className={theme.dayLabel} style={{ flexBasis: `${100 / dayLabels.length}%` }}>
                  {label}
                </div>
              </For>
            </div>
          </div>
        </div>
      </div>

      <GraphLegend config={config.legend} pdfMode={pdfMode} sm={pdfMode ? 2 : undefined} />
    </div>
  );
};

export default HourlyGraphMeets;
