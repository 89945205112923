const setFilterOption = (availableFilters, selectedOptions, value, filterCategory) => {
  const filterCategories = Object.keys(selectedOptions);
  const isOptionAvailable = availableFilters[filterCategory] && availableFilters[filterCategory].includes(value);

  if (!filterCategories.includes(filterCategory) || !isOptionAvailable) return selectedOptions;

  return {
    ...selectedOptions,
    [filterCategory]: selectedOptions[filterCategory].includes(value)
      ? selectedOptions[filterCategory].filter((item) => item !== value)
      : [...selectedOptions[filterCategory], value],
  };
};

export default setFilterOption;
