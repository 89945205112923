/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { Grid, Typography, withStyles } from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

function LiveCounter({ classes, counter }) {
  const hasCounter = Number.isFinite(counter);

  return (
    <Grid container>
      <Grid item xs={12} align="right">
        <Typography variant="h4" component="span" classes={{ root: classes.typography }}>
          {hasCounter ? counter : <Skeleton width="80px" />}
        </Typography>
        {hasCounter && <SupervisorAccountIcon classes={{ root: classes.icon }} />}
      </Grid>
      <Grid item xs={12} align="right">
        <Typography component="span" classes={{ root: classes.typography }}>
          {hasCounter ? 'Live' : <Skeleton width="80px" />}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles((theme) => ({
  typography: {
    color: 'rgba(0,0,0,0.6)',
    verticalAlign: 'bottom',
  },
  icon: {
    marginLeft: '5px',
    color: theme.palette.primary.dark,
  },
}))(LiveCounter);
