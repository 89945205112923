import React from 'react';
import { Bar } from 'react-chartjs-2';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { getPdfMode } from 'modules/spaceLook/selectors';
import { GraphHeader, GraphLegend } from 'modules/spaceLook/components';

import options from './options';
import config from './config';
import theme from './SpaceObservedSizesGraph.module.scss';

const SpaceObservedSizesGraph = ({ data, title }) => {
  const pdfMode = useSelector(getPdfMode);
  const dataLength = data.labels.length;

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      <GraphHeader label={title} />

      <div className={theme.graphWrapper}>
        <div className={theme.graphInner}>
          <div style={{ width: `${dataLength * 208}px` }} className={theme.graph}>
            <Bar key={dataLength} data={data} options={options} />
          </div>
        </div>
      </div>

      <GraphLegend config={config.legend} pdfMode={pdfMode} sm={pdfMode ? 2 : undefined} md={6} xl={3} />
    </div>
  );
};

export default SpaceObservedSizesGraph;
