/* eslint-disable no-console */

import { fetchCompanies, fetchPartnerCompanies } from 'modules/auth/actions';
import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { useDispatch } from 'react-redux';

const useCompanySettingsModal = (isModal, activeCompany) => {
  if (isModal) {
    return { onClick: null };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const onClick = async () => {
    fetchCompanies(dispatch);
    fetchPartnerCompanies(dispatch);
    dispatch(showModal(modalCodes.COMPANY_SETTIINGS));
  };

  return { onCompanySettingsModalClick: onClick };
};

export default useCompanySettingsModal;
