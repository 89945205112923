import { isEmpty } from 'lodash';

const databoxesToEditorData = (databoxesSet, textVariables) => {
  let order = 0;
  const output = [];

  const pushEditorData = (data, prefix) => {
    const keys = Object.keys(data);
    const values = Object.values(data);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = values[i];

      output.push({
        name: prefix + key,
        action: 'min',
        color: '#FFC0CB',
        sub_title: null,
        type_of_group: null,
        group_by_name: null,
        order: ++order,
        is_display_legend: true,
        data: value,
        output_type: 'single_value',
      });
    }
  };

  if (typeof databoxesSet.occupancyDataboxes === 'object') {
    pushEditorData(databoxesSet.occupancyDataboxes, 'occ_');
  }
  if (typeof databoxesSet.deskDataboxes === 'object') {
    pushEditorData(databoxesSet.deskDataboxes, 'desk_');
  }
  if (typeof databoxesSet.breakDataboxes === 'object') {
    pushEditorData(databoxesSet.breakDataboxes, 'break_');
  }
  if (typeof databoxesSet.meetsDataboxes === 'object') {
    pushEditorData(databoxesSet.meetsDataboxes, 'meet_');
  }
  if (!isEmpty(textVariables?.deskVariables)) {
    pushEditorData(textVariables.deskVariables, 'desk_');
  }
  if (!isEmpty(textVariables?.combinedOccupancyVariables)) {
    pushEditorData(textVariables.combinedOccupancyVariables, 'comb_occ_');
  }

  return output;
};

export default databoxesToEditorData;
