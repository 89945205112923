/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { flow, uniqBy, flatten, isEmpty } from 'lodash';

import { URLS } from 'pages/constants';
import { requestStatuses } from 'modules/shared/constants';
import { getActiveCompany } from 'modules/shared/selectors';
import { fetchSummaryFields } from 'modules/assetLook/actions';
import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { REPORT_TYPES, ALL_BUILDINGS, ALL_PRODUCTS } from 'modules/assetLook/constants';

import onSubmit from './onSubmit';

import PdfReportsNew from './PdfReportsNew';

function PdfReportsNewContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(getActiveCompany);
  const [categories, summaryMenuItems, fieldsRequestStatus, tags] = useAssetLookSelector([
    'common.categories',
    'summary.fields',
    'summary.fieldsRequestStatus',
    'common.tags',
  ]);

  const validationSchema = yup.object({
    reportType: yup.string('Select the report type').required('Report type is required'),
  });

  const formik = useFormik({
    initialValues: {
      reportType: REPORT_TYPES.productList,
      buildings: [ALL_BUILDINGS.id],
      categories: [ALL_PRODUCTS.id],
      summaryFields: [],
      activeFilters: [],
    },
    validationSchema,
    onSubmit: (formValues) =>
      onSubmit(formValues, categories, companyId, tags)
        .then(() => history.push(URLS.assetLook.pdfReportsHistory))
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err)),
  });

  const buildingsMenuItems = flow([
    (res) => res.map((item) => ({ id: item.building_id, label: item.building__name })),
    (res) => [ALL_BUILDINGS, ...res],
  ])(categories.by_building);

  const categoriesMenuItems = flow([
    (res) => res.filter(({ building_id }) => formik.values.buildings.includes(building_id)),
    (res) => res.map(({ categories }) => categories),
    flatten,
    (res) => (formik.values.buildings.includes(ALL_BUILDINGS.id) ? [...categories.all, ...res] : res),
    (res) => uniqBy(res, 'category_id'),
    (res) => res.map((item) => ({ id: item.category_id, label: item.category__name })),
    (res) => [ALL_PRODUCTS, ...res],
  ])(categories.by_building);

  useEffect(() => {
    let isCanceled = false;
    isEmpty(summaryMenuItems) && fetchSummaryFields(dispatch, companyId, isCanceled);
    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
    // eslint-disable-next-line
  }, [companyId]);

  useEffect(() => {
    fieldsRequestStatus === requestStatuses.success &&
      !isEmpty(summaryMenuItems) &&
      formik.setFieldValue('summaryFields', [summaryMenuItems[0]]);
    // eslint-disable-next-line
  }, [fieldsRequestStatus]);

  return (
    <PdfReportsNew
      formik={formik}
      buildingsMenuItems={buildingsMenuItems}
      categoriesMenuItems={categoriesMenuItems}
      summaryMenuItems={summaryMenuItems}
    />
  );
}

export default PdfReportsNewContainer;
