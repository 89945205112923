import { showModal } from 'modules/modalsSystem/actions';
import modalCodes from 'modules/modalsSystem/constants/modalCodes';
import { useDispatch } from 'react-redux';
import { SET_GRAPH_DATA } from '../actions';

const useGraphClick = (isModal, modalData) => {
  if (isModal) {
    return { onClick: null };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const onClick = ({ detail }) => {
    if (detail !== 2) {
      return;
    }

    dispatch(showModal(modalCodes.WL_GRAPH_MODAL));
    dispatch(
      SET_GRAPH_DATA({
        graph: modalData,
      }),
    );
  };

  return { onClick };
};

export default useGraphClick;
