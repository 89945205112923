import React, { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { usePdfList } from 'modules/assetLook/hooks';

import { DownloadsList } from 'modules/shared/components';
import { PdfListTooltip } from 'modules/assetLook/components';

function PdfReportsHistory() {
  const [pdfList, setReloadTrigger] = usePdfList();

  const onItemDelete = useCallback(
    (id) => {
      const deleteUrl = ENDPOINTS.assetLook.pdfItem.replace(':pdfId', id);

      API.delete(deleteUrl)
        .then(() => setReloadTrigger(uuidv4()))
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
    },
    [setReloadTrigger],
  );

  return (
    <DownloadsList
      title="Downloads"
      items={pdfList}
      emptyListPlaceholder="No reports yet"
      skeletonItems={5}
      _onItemDelete={onItemDelete}
      deleteTitle="Delete custom report?"
      deleteText="This operation can't be undone"
      customTooltip={PdfListTooltip}
    />
  );
}

export default PdfReportsHistory;
