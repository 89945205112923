import { getMostCommonActivities } from 'utils';

const MostCommonActivities = ({ pieData }) => {
  if (!pieData || pieData.length < 1) return null;

  const [theMostCommonList, secondTheMostCommonList] = getMostCommonActivities(pieData);

  if (!theMostCommonList || theMostCommonList.length < 1) return null;

  return secondTheMostCommonList
    ? `If we look at the activities occurring, then ${theMostCommonList[0].label} was the most frequent and
         accounted for ${theMostCommonList[0].value}% of all recorded activity. However in ${
        secondTheMostCommonList[0].value
      }% 
         of cases ${secondTheMostCommonList
           .map(({ label }) => label)
           .join(', ')
           .trim()}
         ${secondTheMostCommonList.length > 1 ? 'were occuring' : 'occurred'}.`
    : `If we look at the activities occurring, then ${theMostCommonList
        .map(({ label }) => label)
        .join(', ')
        .trim()}
         ${theMostCommonList.length > 1 ? 'were' : 'was'} the most frequent and accounted for
         ${theMostCommonList[0].value}% of all recorded activity.`;
};

export default MostCommonActivities;
