import React from 'react';
import { chunk, cloneDeep, groupBy } from 'lodash';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { reportPageTypes } from 'modules/spaceLook/constants';
import { toHourlyGraphMeetsData, toPieChartData } from 'modules/spaceLook/utils';

import {
  PageHeader,
  FilterHandler,
  ReportDataCards,
  ReportFilter,
  ReportText,
  UsagePie,
  HourlyGraphMeets,
} from 'modules/spaceLook/components';
import { useHasScroll } from 'modules/shared/hooks';

const BreakoutUsageOverTime = ({ isLoading, pdfMode, databoxes, pieData, dailyHourlyGraphData }) => {
  const dataGroupedByDays = Object.entries(groupBy(dailyHourlyGraphData, (item) => item.date));
  const chunkedData = pdfMode ? chunk(dataGroupedByDays, 5) : [dataGroupedByDays];
  const scroll = useHasScroll();

  return chunkedData.map((graphData, idx) => (
    <div ref={scroll.ref} key={idx} className={cn({ pdfPage: pdfMode, hasPageCounter: pdfMode, blured: isLoading })}>
      <Grid container spacing={pdfMode ? 2 : 4}>
        <Grid item xs={10}>
          <PageHeader title="Usage Over Time" text="Breakout Space Utilisation" />
        </Grid>

        <If condition={!pdfMode}>
          <Grid item xs={2} align="right">
            <FilterHandler pageType={reportPageTypes.breakout}>
              <ReportFilter pageType={reportPageTypes.breakout} />
            </FilterHandler>
          </Grid>
        </If>

        <Grid item xs={pdfMode ? 6 : 12} lg={6}>
          <ReportDataCards databoxes={databoxes} pageType={reportPageTypes.breakout} cropped />
        </Grid>

        <Grid item xs={pdfMode ? 6 : 12} lg={6}>
          <UsagePie pieData={toPieChartData(pieData)} label="Observed Activities" inPercents />
        </Grid>

        <Grid item xs={12}>
          <HourlyGraphMeets graphData={toHourlyGraphMeetsData(graphData)} />
        </Grid>

        <Grid item xs={12}>
          <ReportText
            getParagraphs={['meetsAndBreakOccupancySeats', 'meetsAndBreakPeakUsagePlus', 'mostCommonActivities']}
            databoxes={databoxes}
            graphData={cloneDeep(dailyHourlyGraphData)}
            pieData={pieData}
            pageType={reportPageTypes.breakout}
            scrollRef={scroll}
          />
        </Grid>
      </Grid>
    </div>
  ));
};

export default BreakoutUsageOverTime;
