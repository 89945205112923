import React from 'react';
import PropTypes from 'prop-types';

import './DayTimeGraphYNavItem.scss';

const DayTimeGraphYNavItem = ({ item }) => {
  return (
    <span className="DayTimeGraphYNavItem">
      <span className="day-time-graph-y-nav-item">{item}</span>
    </span>
  );
};

DayTimeGraphYNavItem.defaultProps = {
  item: 'percent%',
};

DayTimeGraphYNavItem.propTypes = {
  item: PropTypes.string,
};

export default DayTimeGraphYNavItem;
