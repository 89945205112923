/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Button,
  IconButton,
  ThemeProvider,
  Typography,
  TextField,
  // Select,
  // MenuItem,
  // FormControl,
  // InputLabel,
  Checkbox,
  FormControlLabel,
  // Avatar,
} from '@material-ui/core';

import { Avatar } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import CloseIcon from '@material-ui/icons/Close';
import { ENDPOINTS } from 'modules/shared/constants';
import { API } from 'modules/shared/utils';
import { useFormik } from 'formik';
import theme from './editUserContainer.module.scss';

const EDITING_RIGHTS = [
  {
    label: 'Inherit from group',
    value: 'inherit_group',
  },
  {
    label: 'View Only',
    value: 'view_only',
  },
  {
    label: 'Create/edit Reports',
    value: 'create_edit_report',
  },
  {
    label: 'Create/edit Reports and Templates',
    value: 'create_edit_report_template',
  },
];

const EditUserContainer = ({ onClose, userId }) => {
  const [avatarImg, setAvatarImg] = useState('');
  const inputRefAvatarImg = useRef();
  const initialValuesCompanyData = {
    first_name: '',
    last_name: '',
    email: '',
    avatar: '',
    role: '',
    people_look_editing_rights: '',
    date_joined: '',
    is_edit_assets_permission: false,
    is_manage_orders_permission: false,
    landing_start_page: 'People',
    group: null,
    portal_sections: [],
  };

  const dispatch = useDispatch();
  const activeCompany = localStorage.getItem('lastCompany');

  const formik = useFormik({
    initialValues: initialValuesCompanyData,
    onSubmit: (values) => {
      const result = Object.assign({}, values, {
        ...(avatarImg && { avatar: avatarImg }),
        ...(values.landing_start_page || { landing_start_page: '' }),
      });
      !avatarImg && delete result.avatar;
      delete result.portal_sections;
      delete result.role;
      delete result.group;
      delete result.date_joined;
      const data = new FormData();
      Object.entries(result).map(([key, value]) => {
        return data.append(key, value);
      });
      const url = ENDPOINTS.auth.userData.replace(':companyId', activeCompany).replace(':userId', userId);
      const config = {
        headers: {
          // Authorization: `Token ${isLogged()}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      API.put(url, data, config).then(() => window.location.reload(false));
    },
  });

  useEffect(() => {
    const url = ENDPOINTS.auth.userData.replace(':companyId', activeCompany).replace(':userId', userId);
    API.get(url).then((result) => {
      formik.setValues(result.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.header}>
        <Typography variant="h5" className={theme.title}>
          Edit User
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={theme.modalBody}>
        <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
          <div className={theme.avatar}>
            <Typography>Avatar</Typography>
            <Avatar
              src={avatarImg ? URL.createObjectURL(avatarImg) : formik.values.avatar}
              sx={{ width: 100, height: 100, mb: 1 }}
            />
            <Button component="label" color="primary" variant="outlined">
              Change avatar
              <input
                type="file"
                id="avatar"
                hidden
                name="avatar"
                accept="image/*"
                multiple={false}
                onChange={() => {
                  setAvatarImg(inputRefAvatarImg.current.files[0]);
                }}
                ref={inputRefAvatarImg}
              />
            </Button>
          </div>
          <TextField
            fullWidth
            id="first_name"
            name="first_name"
            label="First name"
            placeholder="Enter first name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            variant="outlined"
          />
          <TextField
            fullWidth
            id="last_name"
            name="last_name"
            label="Last name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            variant="outlined"
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            disabled
            value={formik.values.email}
            onChange={formik.handleChange}
            variant="outlined"
          />
          {/* <TextField
            fullWidth
            id="role"
            name="role"
            label="Role"
            disabled
            value={formik.values.role}
            onChange={formik.handleChange}
            variant="outlined"
          /> */}
          {/* <TextField
            fullWidth
            id="group"
            name="group"
            label="Group"
            disabled
            value={formik.values.group || '_'}
            onChange={formik.handleChange}
            variant="outlined"
          /> */}
          <FormControl fullWidth>
            <InputLabel id="landing_start_page">Landing start page</InputLabel>
            <Select
              labelId="landing_start_page"
              id="landing_start_page"
              name="landing_start_page"
              label="Landing start page"
              value={formik.values?.landing_start_page}
              onChange={formik.handleChange}
              variant="outlined"
            >
              {formik.values.portal_sections.map((sectionName, index) => (
                <MenuItem key={index} value={sectionName}>
                  {sectionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="people_look_editing_rights">People look editing rights</InputLabel>
            <Select
              labelId="people_look_editing_rights"
              id="people_look_editing_rights"
              name="people_look_editing_rights"
              label="People look editing rights"
              value={formik.values?.people_look_editing_rights}
              onChange={formik.handleChange}
              variant="outlined"
            >
              {EDITING_RIGHTS.map((right, index) => (
                <MenuItem key={index} value={right.value}>
                  {right.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <TextField
            fullWidth
            id="date_joined"
            name="date_joined"
            label="Date Joined"
            disabled
            value={formik.values.date_joined}
            onChange={formik.handleChange}
            variant="outlined"
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.is_edit_assets_permission}
                onChange={formik.handleChange}
                variant="outlined"
              />
            }
            name="is_edit_assets_permission"
            id="is_edit_assets_permission"
            label="Is edit assets permission"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.is_manage_orders_permission}
                onChange={formik.handleChange}
                variant="outlined"
              />
            }
            name="is_manage_orders_permission"
            id="is_manage_orders_permission"
            label="Is manage orders Permission"
          />
          <Typography>Role: {formik.values.role}</Typography>
          <Typography>Group: {formik.values.group || '_'}</Typography>
          <Typography>Date Joined: {formik.values.date_joined}</Typography>
          <Button variant="outlined" color="primary" type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default EditUserContainer;
