/* eslint-disable camelcase */
import { flow, flattenDeep, uniqBy } from 'lodash';

// eslint-disable-next-line import/no-cycle
import { getRandomColor } from 'modules/workLook/utils';
import { GRAPH_COLORS, GROUP_TYPES, SERIES_ACTIONS } from 'modules/workLook/constants';

const setGraphDataLegendsColors = (name, graphData, datasetLabelsMap, fieldData, isModal) => {
  const label = name.length > 16 && !isModal ? `${name.slice(0, 13)}...` : name;
  graphData.labels.push(label);

  datasetLabelsMap.forEach((datasetLabel, i) => {
    const dataItem = fieldData.find(({ field }) => field === datasetLabel.field);
    const value = dataItem ? dataItem._percentage : 0;
    graphData.datasets[i].data.push(value);
  });
};

const handleGroupNoneDataStructure = (data, isModal) => {
  const filteredData = data.filter((series) => series.action === SERIES_ACTIONS.countUniquePercent);

  // 1. Create the dataset labels map
  const datasetLabelsMap = flow([flattenDeep, (res) => uniqBy(res, 'field')])(
    filteredData.map((series) => series.data),
  );

  // 2. Predefine the datasets based on the labels map
  const graphData = {
    labels: [],
    datasets: datasetLabelsMap.map(({ field, _color }, i) => {
      const barColor = _color || GRAPH_COLORS[i];
      return {
        label: field.length > 16 && !isModal ? `${field.slice(0, 13)}...` : field,
        backgroundColor: barColor || getRandomColor(),
        data: [],
      };
    }),
  };

  // 3. Fill the datasets with data || 0 (based on the labels map order)
  // eslint-disable-next-line no-shadow
  filteredData.forEach(({ data, name }) => {
    setGraphDataLegendsColors(name, graphData, datasetLabelsMap, data, isModal);
  });

  return graphData.datasets.length ? graphData : null;
};

const handleGroupByFieldDataStructure = (data, isModal) => {
  const firstSeries = data[0];

  if (firstSeries.action !== SERIES_ACTIONS.countUniquePercent) return null;

  // 1. Create the dataset labels map
  const datasetLabelsMap = flow([flattenDeep, (res) => uniqBy(res, 'field')])(
    firstSeries.data.map(({ fields }) => fields),
  );

  // 2. Predefine the datasets based on the labels map
  const graphData = {
    labels: [],
    datasets: datasetLabelsMap.map(({ field, _color }, i) => {
      const barColor = _color || GRAPH_COLORS[i];
      return {
        label: field.length > 16 && !isModal ? `${field.slice(0, 13)}...` : field,
        backgroundColor: barColor || getRandomColor(),
        data: [],
      };
    }),
  };

  // 3. Fill the datasets with data || 0 (based on the labels map order)
  firstSeries.data.forEach(({ group_by_field, fields }) => {
    setGraphDataLegendsColors(group_by_field, graphData, datasetLabelsMap, fields, isModal);
  });

  return graphData.datasets.length ? graphData : null;
};

const toStackedBarMultipleChartData = (data, groupType, isModal) => {
  if (!data || !data.length) return null;

  switch (groupType) {
    case null:
      return handleGroupNoneDataStructure(data, isModal);
    case GROUP_TYPES.byField:
      return handleGroupByFieldDataStructure(data, isModal);
    default:
      return null;
  }
};

export default toStackedBarMultipleChartData;
