import { isEmpty } from 'lodash';

const getModifierGroupFields = (data, type) => {
  if (isEmpty(data)) {
    return [];
  }
  if (type === '5' || type === '11') {
    return data?.labels?.map((label, index) => ({ label, id: index.toString() }));
  }

  return [];
};

export default getModifierGroupFields;
