const getEditGraphId = ({ workLook }) => ({
  graphEditData: workLook.graphData.graphEditData,
  graphId: workLook.graphData.graphId,
  reportFields: workLook.graphData.reportFields,
  seriesList: workLook.graphData.seriesList,
  typeOfModal: workLook.graphData.typeOfModal,
  page: workLook.graphData.page,
  activePageType: workLook.graphData.activePageType,
  sectionId: workLook.graphData.sectionId,
  reportId: workLook.graphData.reportId,
  sectionData: workLook.graphData.sectionData,
  sectionAction: workLook.graphData.sectionAction,
  typeId: workLook.graphData.typeId,
  pageAction: workLook.graphData.pageAction,
  pageData: workLook.graphData.pageData,
  pageTypeId: workLook.graphData.pageTypeId,
  pageId: workLook.graphData.pageId,
  reportPictures: workLook.graphData.reportPictures,
  couldDelete: workLook.graphData.couldDelete,
  modifiersList: workLook.graphData.modifiersList,
  modifierFields: workLook.graphData.modifierFields,
  modifierGroupFields: workLook.graphData.modifierGroupFields,
  groupType: workLook.graphData.groupType,
  legends: workLook.graphData.legends,
  graphType: workLook.graphData.graphType,
});

export default getEditGraphId;
