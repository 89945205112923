import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/spaceLook/components';
import { ReportDataCards, LiveCounter } from 'modules/liveLook/components';

import { pageTypes, liveLookPagesNames, pdfCases, sectionsOrder, sectionItemOrder } from 'modules/liveLook/constants';
import { TextEditor } from 'modules/shared/components';
import AddToPDFCard from 'modules/liveLook/components/AddToPDFCard';
import IFrameModal from 'modules/liveLook/components/iFrame';

const databoxesToEditorData = (databoxesSet) => {
  let order = 0;
  const output = [];

  const pushEditorData = (data, prefix) => {
    const keys = Object.keys(data);
    const values = Object.values(data);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = values[i];

      output.push({
        name: prefix + key,
        action: 'min',
        color: '#FFC0CB',
        sub_title: null,
        type_of_group: null,
        group_by_name: null,
        order: ++order,
        is_display_legend: true,
        data: value,
        output_type: 'single_value',
      });
    }
  };

  if (typeof databoxesSet?.occupancyDataboxes === 'object') {
    pushEditorData(databoxesSet.occupancyDataboxes, 'occ_');
  }
  if (typeof databoxesSet?.deskDataboxes === 'object') {
    pushEditorData(databoxesSet.deskDataboxes, 'desk_');
  }
  if (typeof databoxesSet?.breakDataboxes === 'object') {
    pushEditorData(databoxesSet.breakDataboxes, 'break_');
  }
  if (typeof databoxesSet?.meetsDataboxes === 'object') {
    pushEditorData(databoxesSet.meetsDataboxes, 'meet_');
  }

  return output;
};

const SummaryFloorPlan = ({ databoxes, databoxesSet, editableTextReport, saveGraphsText, archive }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title="Executive Summary - Floor Plan" />
          </Grid>
          <Grid item xs={12}>
            <ReportDataCards databoxes={databoxes} pageType={pageTypes.summary} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Grid
          container
          alignItems="flex-end"
          direction="column"
          // spacing={1}
          style={{ justifyContent: 'flex-end' }}
        >
          <Grid item>{!archive && <LiveCounter counter={3} />}</Grid>
          <Grid item>
            <IFrameModal />
          </Grid>
        </Grid>
        <Grid>
          <AddToPDFCard
            type="Floor Plan"
            sectionType="Executive Summary"
            category={pdfCases.keyFindingsFloor}
            sectionOrder={sectionsOrder.first}
            typeOrder={sectionItemOrder.sixth}
            chartName={liveLookPagesNames.summaryFloorPlan}
            data={{
              databoxes,
              editableTextReport,
              databoxesSet,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TextEditor
          chartName={liveLookPagesNames.summaryFloorPlan}
          data={databoxesToEditorData(databoxesSet)}
          onSave={async (data) => {
            saveGraphsText(JSON.stringify(data));
          }}
          text={editableTextReport}
          isSaveLocallyHide
          isImageBtnShow
          isTextLimitHide
        />
      </Grid>
    </Grid>
  );
};

export default SummaryFloorPlan;
