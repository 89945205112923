import { omit } from 'lodash';

const getReportPdfRequestStatuses = ({ report }) => {
  const { deskTypesQuantity } = report.services;

  let desksRequests = report.desks.requestStatuses;
  if (deskTypesQuantity < 2) {
    desksRequests = omit(desksRequests, ['desksTypeGraphData']);
  }

  return [
    ...Object.values(report.combined.requestStatuses),
    ...Object.values(desksRequests),
    ...Object.values(report.benchmarking.requestStatuses),
    ...Object.values(report.meetings.requestStatuses),
    ...Object.values(report.breakout.requestStatuses),
    report.summary.requestStatus,
    report.heatmap.requestStatus,
  ];
};

export default getReportPdfRequestStatuses;
