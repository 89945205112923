/* eslint-disable camelcase */
import React from 'react';

import { useGraphData } from 'modules/workLook/hooks';
import { toTableData } from 'modules/workLook/utils';
import { SERIES_ACTION_GROUP } from 'modules/workLook/constants';

// eslint-disable-next-line import/no-cycle
import DataTable from './DataTable';
import EditableTable from '../EditableTable/EditableTable';

const DataTableContainer = ({
  id,
  activeFilters,
  group_by_field,
  isModal,
  graphData,
  is_display_title,
  isPdf,
  type_of_group,
  isPublished,
  type,
  ...props
}) => {
  const { isLoading, data } = useGraphData(id, activeFilters, false, isModal, true);

  const getSeriesAction = SERIES_ACTION_GROUP.display.includes(data?.[0]?.action);

  if (getSeriesAction)
    return (
      <EditableTable
        editable={false}
        graphId={id}
        isLoading={isLoading}
        data={data[0].data}
        {...props}
        isPdf={isPdf}
        typeOfGroup={type_of_group}
        isPublished={isPublished}
        type={type}
        showTitle={is_display_title}
      />
    );

  return (
    <DataTable
      showTitle={is_display_title}
      isPublished={isPublished}
      isPdf={isPdf}
      typeOfGroup={type_of_group}
      graphId={id}
      type={type}
      isLoading={isLoading}
      modalData={!isModal && { ...props, id, group_by_field, activeFilters, graphData: data }}
      isModal={isModal}
      data={toTableData(data, group_by_field)}
      {...props}
    />
  );
};

export default DataTableContainer;
