import { createReducer } from '@reduxjs/toolkit';

import findActiveCompany from 'utils/findActiveCompany';
import { requestStatuses } from 'modules/shared/constants';
import { validateActiveCompany } from 'modules/auth/utils';
import { LOAD_REPORT_INFO } from 'modules/spaceLook/actions';
import {
  LOG_IN,
  SET_TWO_FACTOR_REQUIRED,
  SET_CURRENT_COMPANY,
  FETCH_USER,
  FETCH_COMPANIES,
  FETCH_COMPANY_USERS,
  FETCH_PARTNER_COMPANIES,
} from 'modules/auth/actions';

const initState = {
  userRequestStatus: requestStatuses.default,
  user: null,
  isOtpRequired: null,
  currentCompany: null,
  companies: [],
  partnerCompanies: [],
  companyUsers: [],
};

const authReducer = createReducer(initState, {
  [LOG_IN]: (state, { payload: { user, isOtpRequired } }) => ({
    ...state,
    user,
    isOtpRequired,
    currentCompany: findActiveCompany(user),
    userRequestStatus: requestStatuses.success,
  }),
  [SET_TWO_FACTOR_REQUIRED]: (state, { payload }) => ({
    ...state,
    isOtpRequired: payload,
  }),

  [FETCH_USER.pending]: (state) => ({
    ...state,
    userRequestStatus: requestStatuses.pending,
  }),
  [FETCH_USER.success]: (state, { payload }) => {
    const isCompanyValid = validateActiveCompany(payload, state.currentCompany);

    return {
      ...state,
      userRequestStatus: requestStatuses.success,
      user: payload,
      currentCompany: isCompanyValid ? state.currentCompany : findActiveCompany(payload),
    };
  },
  [FETCH_USER.failure]: (state) => ({
    ...state,
    userRequestStatus: requestStatuses.failure,
  }),

  [SET_CURRENT_COMPANY]: (state, { payload }) => ({
    ...state,
    currentCompany: payload,
  }),
  [LOAD_REPORT_INFO.success]: (state, { payload }) => ({
    ...state,
    currentCompany: payload.company,
  }),
  [FETCH_COMPANIES.success]: (state, { payload }) => {
    return {
      ...state,
      companies: payload,
    };
  },
  [FETCH_PARTNER_COMPANIES.success]: (state, { payload }) => {
    return {
      ...state,
      partnerCompanies: payload,
    };
  },
  [FETCH_COMPANY_USERS.success]: (state, { payload }) => {
    return {
      ...state,
      companyUsers: payload,
    };
  },
});

export default authReducer;
