const SORT_ORDER = {
  byOrder: (a, b) => (a.order <= b.order ? -1 : 1),
  byOrderAndId: (a, b) => (a.order <= b.order && a.id <= b.id ? -1 : 1),
  byOrderAndIdAndFilters: (a, b) => {
    return a.order <= b.order && a.id <= b.id && a.activeFilters.every(({ values }) => values.length === 0) ? -1 : 1;
  },
  byMapTypeOrder: (a, b) => a.mapOrder - b.mapOrder,
  byControlCardData: (a, b) => a.controlCardData.id - b.controlCardData.id,
};

export default SORT_ORDER;
