import { createReducer } from '@reduxjs/toolkit';

import { requestStatuses } from 'modules/shared/constants';
import { FETCH_TEMPLATES, RESET_STATE } from 'modules/workLook/actions';

const initState = {
  requestStatus: requestStatuses.default,
  data: [],
};

export default createReducer(initState, {
  [FETCH_TEMPLATES.default]: (state) => ({
    ...state,
    requestStatus: requestStatuses.default,
  }),
  [FETCH_TEMPLATES.pending]: (state) => ({
    ...state,
    requestStatus: requestStatuses.pending,
  }),
  [FETCH_TEMPLATES.success]: (state, { payload }) => ({
    ...state,
    requestStatus: requestStatuses.success,
    data: payload,
  }),
  [FETCH_TEMPLATES.failure]: (state) => ({
    ...state,
    requestStatus: requestStatuses.failure,
  }),

  [RESET_STATE]: () => ({
    ...initState,
  }),
});
