import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'components';

import URLS from 'pages/constants/urls';
import { isDefault } from 'modules/shared/utils';
import { requestStatuses } from 'modules/shared/constants';
import { fetchDesksDataboxes } from 'modules/spaceLook/actions';
import { getPdfMode, getHeatmapData, getAppliedFilters, getSelectedFilters } from 'modules/spaceLook/selectors';

import Heatmap from './Heatmap';

const HeatmapContainer = () => {
  const { id: surveyId, heatmapId } = useParams();
  const dispatch = useDispatch();

  const pdfMode = useSelector(getPdfMode);
  const { dataRequestStatuses, data, shouldRefetchData } = useSelector(getHeatmapData);
  const { selectedFiltersUrlParams } = useSelector((store) => getSelectedFilters(store, 'desks'));
  const { appliedFiltersRequestStatus } = useSelector(getAppliedFilters);

  const heatmap = pdfMode
    ? data.heatmapData
    : data.heatmapData && [data.heatmapData.find(({ id }) => id === parseInt(heatmapId))];

  const isLoading = Object.values(dataRequestStatuses).some((status) => status === requestStatuses.pending);
  const isLoaded = Object.values(dataRequestStatuses).every((status) => status === requestStatuses.success);
  const isFiltersHistoryLoading = [requestStatuses.default, requestStatuses.pending].includes(
    appliedFiltersRequestStatus,
  );

  useEffect(() => {
    if (isFiltersHistoryLoading) return;

    if (isDefault(dataRequestStatuses.databoxes) || shouldRefetchData.databoxes) {
      fetchDesksDataboxes(dispatch, surveyId, selectedFiltersUrlParams);
    }
  }, [
    appliedFiltersRequestStatus,
    dataRequestStatuses.databoxes,
    dispatch,
    isFiltersHistoryLoading,
    selectedFiltersUrlParams,
    shouldRefetchData.databoxes,
    surveyId,
  ]);

  return (
    <Choose>
      <When condition={isLoading}>
        <Loader />
      </When>
      <When condition={isLoaded}>
        <Choose>
          <When condition={heatmap && heatmap.length}>
            <Heatmap pdfMode={pdfMode} heatmap={heatmap} databoxes={data.databoxes} />
          </When>
          <Otherwise>
            <Redirect to={URLS.spaceLook.surveyDetails.replace(':id', surveyId)} />
          </Otherwise>
        </Choose>
      </When>
    </Choose>
  );
};

export default HeatmapContainer;
