/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { isEmpty } from 'lodash';
import { getEditGraphId } from 'modules/workLook/selectors';
import theme from './modifiersGraphModalContainer.module.scss';
import { MODIFIER_CONFIG, MODIFIER_TABS, TAB_NAMES } from './modifiers.constants';

const ModifiersGraphModalContainer = React.forwardRef(({ onClose }) => {
  const { modifiersList, graphId, seriesList, modifierFields, modifierGroupFields, groupType, legends, graphType } =
    useSelector(getEditGraphId);
  const [activeTab, setActiveTab] = useState(MODIFIER_TABS?.[graphType][0]);
  const [isModifierLoading, setIsModifierLoading] = useState(false);
  const [modifierData, setModifierData] = useState();
  const [activeModifier, setActiveModifier] = useState({
    displayModifier: false,
    modifierId: 0,
    type: '',
    action: '',
  });

  const getTabModifiersList = modifiersList.filter(({ action }) => action === activeTab);

  const onCancelModifier = () => {
    setActiveModifier({
      displayModifier: false,
      modifierId: 0,
      type: '',
      action: '',
    });
  };

  const onEditModifier = (id) => {
    setIsModifierLoading(true);
    onCancelModifier();
    const url = ENDPOINTS.workLook.crudModifier.replace(':modifierId', id);
    API.get(url).then(({ data }) => {
      setIsModifierLoading(false);
      setModifierData(data);
      setActiveModifier({
        displayModifier: true,
        modifierId: id,
        type: 'edit',
        action: activeTab,
      });
    });
  };

  const onTabChange = (tab) => {
    setActiveTab(tab);
    onCancelModifier();
  };

  const onCreateModifierClick = () => {
    onCancelModifier();
    setActiveModifier({
      displayModifier: true,
      modifierId: null,
      type: 'create',
      action: activeTab,
    });
  };

  return (
    <div elevation={2} className={theme.container}>
      <div className={theme.graph}>
        <div className={theme.header}>
          <Typography variant="h5" className={theme.title}>
            Graph Advanced Settings
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={theme.tabsContainer}>
          <div className={theme.leftSection}>
            {MODIFIER_TABS[graphType].map((tab, id) => (
              <Typography
                key={id}
                onClick={() => onTabChange(tab)}
                className={theme.tabTitle}
                style={{ backgroundColor: activeTab === tab ? '#e9e8e8' : '' }}
                variant="h6"
              >
                {TAB_NAMES[tab]}
              </Typography>
            ))}
          </div>
          <If condition={!isEmpty(activeTab)}>
            <div className={theme.verticalLine} />
            <div className={theme.rightSection}>
              {MODIFIER_CONFIG?.[activeTab]({
                getTabModifiersList,
                activeModifier,
                modifierData,
                onCancelModifier,
                activeTab,
                onEditModifier,
                graphId,
                groupType,
                legends,
                onCreateModifierClick,
                onClose,
                seriesList,
                isModifierLoading,
                modifierFields,
                modifierGroupFields,
              })}
            </div>
          </If>
        </div>
      </div>
    </div>
  );
});

export default ModifiersGraphModalContainer;
