import React, { useEffect, useReducer } from 'react';
import { isEmpty } from 'lodash';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, filtersToQuery } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import { fetchMeetsUsageBySize, fetchMeetsDataboxes, fetchGraphsText, saveGraphsText } from 'modules/liveLook/actions';
import { setGraphInfo } from 'utils';
import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import MeetsSpaceUsageBySize from './MeetsSpaceUsageBySize';
import reducer, { initialState } from './_reducer';

function MeetsSpaceUsageBySizeContainer() {
  const [companyId] = useAuthSelector(['currentCompany']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { requestStatus, data, meetsDataboxes, databoxRequestStatus, editableTextReport, textReportRequestStatus } =
    state;

  const [startDate, endDate, workingHours, activeFilters, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'filters.activeFilters',
    'common.archive',
  ]);

  const query = filtersToQuery(activeFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;

    fetchMeetsUsageBySize(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchMeetsDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'meet_size',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, dateAfter, dateBefore, workingHours, query]);

  const databoxesSet = {
    meetsDataboxes,
  };

  if (isEmpty(data)) return null;

  const graphData = setGraphInfo(data);

  return (
    <Choose>
      <When
        condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].some(
          (item) => isDefault(item) || isLoading(item),
        )}
      >
        <Loader />
      </When>
      <When condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].every((item) => isSuccess(item))}>
        <MeetsSpaceUsageBySize
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'meet_size', text });
          }}
          editableTextReport={editableTextReport}
          data={graphData}
          databoxes={meetsDataboxes}
          databoxesSet={databoxesSet}
          archive={archive}
        />
      </When>
      <When condition={[databoxRequestStatus, requestStatus, textReportRequestStatus].some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default MeetsSpaceUsageBySizeContainer;
