import DataWidget from '../components/DataWidget';
import UrlWidget from '../components/UrlWidget';
import TableGraph from '../components/TableGraph';
import GraphDataWidget from '../components/GraphWidgets/GraphDataWidget';
import GraphTableGraph from '../components/GraphWidgets/GraphTableWidget';
import GraphUrlWidget from '../components/GraphWidgets/GraphUrlWidget';

const config = {
  positions: {
    1: 'leftSection',
    2: 'rightSection',
  },
  widgetListModal: {
    data_widget: DataWidget,
    url_widget: UrlWidget,
    data_widget_2c: TableGraph,
  },
  widgetList: {
    data_widget: GraphDataWidget,
    url_widget: GraphUrlWidget,
    data_widget_2c: GraphTableGraph,
  },
};

export default config;
