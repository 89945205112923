import { isEmpty } from 'lodash';
import { useState, useCallback } from 'react';
import { SET_BUILDING } from 'modules/liveLook/actions';

const ALL_BUILDINGS = 'All buildings';

const useActiveBuildings = (buildings, dispatch) => {
  const [activeBuilding, setActiveBuilding] = useState(ALL_BUILDINGS);
  const onBuildingChange = useCallback(
    ({ target: { value } }) => {
      const selectedBuilding = buildings.find(
        // eslint-disable-next-line camelcase
        ({ building_id }) => building_id === value,
      )?.building__property_name;
      setActiveBuilding(isEmpty(selectedBuilding) ? ALL_BUILDINGS : selectedBuilding);
      dispatch(SET_BUILDING(value));
    },
    [buildings, dispatch],
  );
  return { activeBuilding, onChange: onBuildingChange };
};

export default useActiveBuildings;
