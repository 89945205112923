/* eslint-disable no-shadow */
import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/assetLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';
import { FETCH_MODIFIED_FILTERS } from './fetchAllAssets';

export const FETCH_SUMMARY = createAsyncAction(createAction('FETCH_SUMMARY'));

const fetchSummary = (
  dispatch,
  isCanceled,
  { companyId, buildingId, productId, summaryFieldsActive, filters, categoryId, queryFilters },
) => {
  dispatch(FETCH_SUMMARY.pending());

  const url = ENDPOINTS.assetLook.summary.replace(':companyId', companyId).replace(':productId', productId);
  const filtersUrl = ENDPOINTS.assetLook.initModifiedFilters
    .replace(':companyId', companyId)
    .concat(queryFilters ? `?${queryFilters}` : '');

  const data = {
    group_by_fields: summaryFieldsActive,
    building_id: Number(buildingId),
    filters,
  };

  API.post(filtersUrl, { building_id: Number(buildingId), category_id: Number(categoryId) })
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_MODIFIED_FILTERS.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_MODIFIED_FILTERS.failure());
    });

  API.post(url, data)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_SUMMARY.success(data));
      // eslint-disable-next-line consistent-return
      return data.data;
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_SUMMARY.failure());
    });
};

export default fetchSummary;
