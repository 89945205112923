/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  SET_TEXT_SUMMARY_BAR_CHART,
  SET_TEXT_SUMMARY_BAR_MULTIPLE,
  SET_TEXT_SUMMARY_BAR_SINGLE,
  RESET_TEXT_SUMMARY_BAR_CHART,
} from 'modules/workLook/actions';
import { useDispatch } from 'react-redux';
import isLogged from 'utils/isLogged';
import { toEditableTextData, toQuery, toBarChartData } from 'modules/workLook/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import getTopFive from '../components/BarChart/useGetTopFive';

const useGraphData = (id, activeFilters, graphData, isModal, isDataTable, type, type_of_group, title) => {
  if (graphData) return { isLoading: false, data: graphData };
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [legends, setLegends] = useState(null);

  const query =
    isModal && isDataTable ? toQuery(activeFilters).concat('?disable_limiting=True') : toQuery(activeFilters);

  useEffect(() => {
    let canceled = false;
    setIsLoading(true);
    dispatch(SET_TEXT_SUMMARY_BAR_SINGLE([]));
    dispatch(RESET_TEXT_SUMMARY_BAR_CHART([]));
    dispatch(SET_TEXT_SUMMARY_BAR_MULTIPLE([]));

    const url = ENDPOINTS.workLook.graph.replace(':graphId', id);
    const config = {
      headers: { Authorization: `Token ${isLogged()}` },
    };

    axios
      .get(`${url}${query}`, config)
      .then(({ data }) => {
        if (canceled) return;
        setData(data);
        setIsLoading(false);
        if (type === '3') {
          const { datasets } = toBarChartData(data, type_of_group);
          const legends = datasets?.map(({ label }) => label);
          setLegends(legends);
          const covertedData = toEditableTextData(data, type_of_group, title);
          const allData = getTopFive(covertedData, legends, title);
          dispatch(SET_TEXT_SUMMARY_BAR_CHART(allData));
        }
        if (type === '4') {
          const convertedData = toEditableTextData(data, type_of_group, title);
          dispatch(SET_TEXT_SUMMARY_BAR_SINGLE(convertedData));
        }
        if (type === '5') {
          const convertedData = toEditableTextData(data, type_of_group, title);
          dispatch(SET_TEXT_SUMMARY_BAR_MULTIPLE(convertedData));
        }
      })
      .catch((err) => {
        if (canceled) return;
        console.error(err);
      });
    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
  }, [id, query]);

  return { isLoading, data, legends };
};

export default useGraphData;
