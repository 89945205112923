/* eslint-disable no-case-declarations */
import { userRoles } from 'modules/auth/constants';

// eslint-disable-next-line consistent-return
const defineCompanyNameAndLogo = (companies, user, activeCompany) => {
  // eslint-disable-next-line default-case
  switch (user.role) {
    case userRoles.admin:
      const { name, logo } = companies.find((company) => company.id === activeCompany);
      return [name, logo];
    case userRoles.partner:
      return [
        companies.find((company) => company.id === activeCompany).name,
        user.partner_company && user.partner_company.logo,
      ];
    case userRoles.client:
      return [user.company && user.company.name, user.company && user.company.logo];
    case userRoles.company_admin:
      return [user.company && user.company.name, user.company && user.company.logo];
    case userRoles.partner_admin:
      return [
        companies.find((company) => company.id === activeCompany).name,
        user.partner_company && user.partner_company.logo,
      ];
  }
};

export default defineCompanyNameAndLogo;
