const SIGN_OF_LIFE = 'Sign of Life';
const VACANT = 'Vacant';
const UNOCCUPIED = 'Unoccupied (Vacant)';
const UNUSABLE = 'Unusable';
const UNUSED = 'Unused';
const UNSURVEYED = 'Unsurveyed';

const PIE_VALUES_TO_OMIT = [SIGN_OF_LIFE, VACANT, UNOCCUPIED, UNUSABLE, UNUSED, UNSURVEYED];

const PC_IN_USE = 'In Use (PC In Use)';
const LAPTOP_IN_USE = 'In Use (Laptop In Use)';

export { PIE_VALUES_TO_OMIT, PC_IN_USE, LAPTOP_IN_USE };
