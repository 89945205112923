import { useMapStartZoom } from 'modules/shared/utils';
import React from 'react';
import { Popup, LayerGroup, CircleMarker } from 'react-leaflet';
import renderPopupData from '../utils';

const colors = [
  '#ee8135',
  '#e8869a',
  '#2367b0',
  '#33a852',
  '#902b88',
  '#fbc94f',
  '#d60d0d',
  '#5e2e2e',
  '#2e355e',
  '#4d67ff',
];

function ColorComponent({ data }) {
  useMapStartZoom(data);
  return (
    <LayerGroup>
      {data.map(({ id, coordinates, value, ...rest }) => {
        return (
          <CircleMarker
            key={id}
            id={id}
            center={coordinates}
            color={colors[Math.floor(Math.random() * colors.length)]}
            fillColor="#ee8135"
            radius={10}
          >
            <Popup>{renderPopupData({ ...rest, value })}</Popup>
          </CircleMarker>
        );
      })}
    </LayerGroup>
  );
}

export default ColorComponent;
