import React from 'react';
import { isEmpty } from 'lodash';
import { Grid, Paper } from '@material-ui/core';

import { useAssetLookSelector } from 'modules/assetLook/hooks';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';

import { AutoSizer } from 'modules/assetLook/components';
import { TextPlaceholder } from 'modules/shared/components';
import { DataTable, FieldsSelect, TableSkeleton } from 'modules/assetLook/components/SummaryTable/components';

const SELECT_HEIGHT = 64;

function SummaryTable() {
  const [summaryFieldsRequestStatus, summaryFields] = useAssetLookSelector([
    'summary.fieldsRequestStatus',
    'summary.fields',
  ]);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Paper style={{ height: '100%', maxHeight: '100%' }}>
          <Grid container spacing={0} direction="column" style={{ height: '100%', flexWrap: 'nowrap' }}>
            <Grid item>
              <FieldsSelect />
            </Grid>
            <Grid item style={{ flexGrow: 1, overflow: 'hidden' }}>
              <Choose>
                <When condition={isDefault(summaryFieldsRequestStatus) || isLoading(summaryFieldsRequestStatus)}>
                  <TableSkeleton width={width} height={height - SELECT_HEIGHT} />
                </When>
                <When condition={isFailure(summaryFieldsRequestStatus)}>
                  <TextPlaceholder error />
                </When>
                <When condition={isSuccess(summaryFieldsRequestStatus)}>
                  <Choose>
                    <When condition={!isEmpty(summaryFields)}>
                      <DataTable width={width} height={height - SELECT_HEIGHT} />
                    </When>
                    <Otherwise>
                      <TextPlaceholder text="No summary fields available" />
                    </Otherwise>
                  </Choose>
                </When>
              </Choose>
            </Grid>
          </Grid>
        </Paper>
      )}
    </AutoSizer>
  );
}

export default SummaryTable;
