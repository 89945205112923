import React from 'react';

import { NavBar } from 'modules/shared/components';
import { Sidebar } from 'modules/workLook/components';

import theme from './WorkLookLayout.module.scss';

const WorkLookLayout = ({ children }) => (
  <div className={theme.root}>
    <div className={theme.header}>
      <NavBar />
    </div>

    <div className={theme.sidebar}>
      <Sidebar />
    </div>

    <div className={theme.content}>{children}</div>
  </div>
);

export default WorkLookLayout;
