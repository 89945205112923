/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataGroupedByDays } from 'pages/_privatePages/liveLook/utils/setDataForOverTime';
import { useGraphData } from 'modules/workLook/hooks';
import { SET_INTEGER_SWITCHER, SET_TIME_GRAPH_SCALE, SET_TIME_GRAPH_VALUE } from 'modules/workLook/actions';
import { setScaleValues, toTimeZone } from 'pages/_privatePages/liveLook/utils';
import { isEmpty } from 'lodash';
import { getTimeGraphScale, getTimeGraphValue, getIntSwitcher } from 'modules/workLook/selectors';
// eslint-disable-next-line import/no-cycle
import TimeGraph from './TimeGraph';
import getTotalAmountFunc from './utils/getTotalAmount';
import EmptyGraph from '../EmptyGraph';

const TimeGraphContainer = ({
  isModal,
  id,
  activeFilters,
  type,
  isPdf,
  sub_title,
  type_of_group,
  getFirstTimeGrId,
  isPublished,
  is_display_title,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isLoading, data } = useGraphData(id, activeFilters, null, type);
  const { intSwitcher } = useSelector(getIntSwitcher);
  const { timeScale } = useSelector(getTimeGraphScale);
  const { valueMethod } = useSelector(getTimeGraphValue);

  const onScaleChange = useCallback(
    ({ target: { value } }) => {
      dispatch(SET_TIME_GRAPH_SCALE(value));
    },
    [dispatch],
  );

  const onValueChange = useCallback(
    ({ target: { value } }) => {
      dispatch(SET_TIME_GRAPH_VALUE(value));
    },
    [dispatch],
  );

  const onSwitcherChange = useCallback(() => {
    dispatch(SET_INTEGER_SWITCHER(!intSwitcher));
  }, [dispatch, intSwitcher]);

  if (isEmpty(data))
    return (
      <EmptyGraph
        isPublished={isPublished}
        title={props.title}
        graphId={id}
        type={type}
        showTitle={is_display_title}
        subtitle={sub_title}
        {...props}
      />
    );

  const getExistKeys = Array.isArray(data[0]?.data);
  const sortData = data[0]?.data?.time_data?.sort((a, b) => {
    return new Date(a?.date) - new Date(b?.date);
  });

  const getIntSwitchers = isPdf ? props.intSwitcher : intSwitcher;
  const getTimeScale = isPdf ? props.timeScale : timeScale;
  const getValueMethod = isPdf ? props.valueMethod : valueMethod;

  const dataGroupedByDays = setDataGroupedByDays(sortData, getTimeScale, toTimeZone);
  const getTotalAmount = getTotalAmountFunc({
    getTimeScale,
    totalAmountPerDay: data[0]?.data?.total_amount,
    dayLength: dataGroupedByDays?.[0]?.[1]?.length,
  });

  const getDataKeys = getExistKeys ? [] : data[0]?.data?.keys?.sort();
  const getPeakValues = data[0]?.data?.peak_values?.sort();
  const { niceMax, scaleValues } = setScaleValues(getIntSwitchers ? getTotalAmount : 100, true, true, 7);
  const displayHeaderOptions = id === getFirstTimeGrId;

  return (
    <TimeGraph
      graphId={id}
      isPublished={isPublished}
      displayHeaderOptions={displayHeaderOptions}
      isLoading={isLoading}
      typeOfGroup={type_of_group}
      onSwitcherChange={onSwitcherChange}
      returnPercentageSwitcher={getIntSwitchers}
      data={sortData}
      {...props}
      totalAmountPerDay={data[0]?.data?.total_amount}
      isPdf={isPdf}
      type={type}
      scale={getTimeScale}
      scaleValues={scaleValues}
      niceMax={niceMax}
      peakValues={getPeakValues}
      keys={getDataKeys}
      onScaleChange={onScaleChange}
      valueMethod={getValueMethod}
      onValueChange={onValueChange}
      subtitle={sub_title}
      dataGroupedByDays={dataGroupedByDays}
      showTitle={is_display_title}
    />
  );
};

export default TimeGraphContainer;
