/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Button, ButtonGroup, Grid } from '@material-ui/core';

import { useHasScroll } from 'modules/shared/hooks';
import { getPdfMode } from 'modules/spaceLook/selectors';
import { byDivAndDepGraphTypes } from 'modules/spaceLook/constants';

import { GraphHeader } from 'components';
// eslint-disable-next-line import/no-cycle
import { GraphLegend, GraphYScale } from 'modules/spaceLook/components';

import options from './options';
import config from './config';

import theme from './DesksUsageByDivAndDepGraph.module.scss';

const DesksUsageByDivAndDepGraph = ({ divisionData, departmentData }) => {
  const pdfMode = useSelector(getPdfMode);
  const { ref, hasHorScroll } = useHasScroll();

  const divLength = divisionData && divisionData.labels.length;
  const depLength = departmentData && departmentData.labels.length;
  const [activeGraph, setActiveGraph] = useState(divLength ? byDivAndDepGraphTypes.div : byDivAndDepGraphTypes.dep);

  if (!divLength && !depLength) return null;

  const dataLength = activeGraph === byDivAndDepGraphTypes.div ? divLength : depLength;

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode })}>
      <Grid container>
        <Grid item xs={8}>
          <GraphHeader label={`Peak and Average by ${activeGraph}`} />
        </Grid>
        <If condition={divLength && depLength}>
          <Grid item xs={4} align="right">
            <ButtonGroup variant="text">
              <Button
                size="small"
                color={activeGraph === byDivAndDepGraphTypes.div ? 'primary' : 'default'}
                onClick={() => setActiveGraph(byDivAndDepGraphTypes.div)}
                children={byDivAndDepGraphTypes.div}
              />
              <Button
                size="small"
                color={activeGraph === byDivAndDepGraphTypes.dep ? 'primary' : 'default'}
                onClick={() => setActiveGraph(byDivAndDepGraphTypes.dep)}
                children={byDivAndDepGraphTypes.dep}
              />
            </ButtonGroup>
          </Grid>
        </If>
      </Grid>

      <div className={theme.graphWrapper}>
        <GraphYScale marginBottom={hasHorScroll ? '43px' : '30px'} />
        <div className={theme.graphInner} ref={ref}>
          <div style={{ width: `${dataLength * 192}px` }} className={theme.graph}>
            <Choose>
              <When condition={activeGraph === byDivAndDepGraphTypes.div}>
                <Bar key={`${byDivAndDepGraphTypes.div}_${dataLength}`} data={divisionData} options={options} />
              </When>
              <When condition={activeGraph === byDivAndDepGraphTypes.dep}>
                <Bar key={`${byDivAndDepGraphTypes.dep}_${dataLength}`} data={departmentData} options={options} />
              </When>
            </Choose>
          </div>
        </div>
      </div>

      <GraphLegend config={config.legend} pdfMode={pdfMode} sm={pdfMode ? 3 : undefined} xl={3} />
    </div>
  );
};

export default DesksUsageByDivAndDepGraph;
