/* eslint-disable camelcase */
import { createReducer } from '@reduxjs/toolkit';

import { FILTERS, weekday } from 'modules/liveLook/constants';
import { makeDefaultActiveFilters } from 'modules/shared/utils';
import { APPLY_FILTERS, FETCH_COMMON_DATA_DOOR_SENSORS, RESET_FILTERS, RESET_STATE } from 'modules/liveLook/actions';
import { SET_CURRENT_COMPANY } from 'modules/auth/actions';

const initState = {
  doorSensorInitFilters: null,
  activeDoorSensorFilters: null,
};

export default createReducer(initState, {
  [FETCH_COMMON_DATA_DOOR_SENSORS.success]: (state, { payload: { buildings, floors, subtypes } }) => {
    const doorSensorInitFilters = [weekday];

    doorSensorInitFilters.push({
      field: FILTERS.building_id,
      values: buildings.map(({ building__property_name, building_id }) => ({
        label: building__property_name,
        value: building_id,
      })),
    });

    doorSensorInitFilters.push({
      field: FILTERS.floor,
      values: floors,
    });

    doorSensorInitFilters.push({
      field: FILTERS.subtype,
      values: subtypes,
    });

    return {
      ...state,
      doorSensorInitFilters,
      activeDoorSensorFilters: makeDefaultActiveFilters(doorSensorInitFilters),
    };
  },
  [FETCH_COMMON_DATA_DOOR_SENSORS.failure]: (state) => {
    return state;
  },
  [APPLY_FILTERS]: (state, { payload: { field, values } }) => ({
    ...state,
    activeDoorSensorFilters: state.activeDoorSensorFilters.map((filter) =>
      filter.field === field ? { field, values } : { ...filter },
    ),
  }),
  [RESET_FILTERS]: (state) => ({
    ...state,
    activeDoorSensorFilters: makeDefaultActiveFilters(state.doorSensorInitFilters),
  }),

  [SET_CURRENT_COMPANY]: () => ({
    ...initState,
  }),
  [RESET_STATE]: () => ({
    ...initState,
  }),
});
