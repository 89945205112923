/* eslint-disable no-param-reassign */
const sortGraphObjects = (graphsArray) => {
  return graphsArray.sort((a, b) => {
    const aSum = a.datasets.reduce((sum, obj) => {
      sum += obj.data[0];
      return sum;
    }, 0);
    const bSum = b.datasets.reduce((sum, obj) => {
      sum += obj.data[0];
      return sum;
    }, 0);
    return bSum - aSum;
  });
};

export default sortGraphObjects;
