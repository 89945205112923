/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Typography, Button, TextField, Box, Checkbox, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Skeleton } from '@material-ui/lab';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { isEmpty } from 'lodash';
import { ENDPOINTS } from 'modules/shared/constants';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isLogged } from 'utils';
import axios from 'axios';
import theme from '../forms.module.scss';
import FieldCard from './FieldCard';

const SortingForm = ({
  modifierType,
  seriesList,
  modifierId,
  modifierAction,
  onClose,
  graphId,
  isModifierLoading,
  groupType,
  modifierData,
  onCancelModifier,
  modifierFields,
  modifierGroupFields,
}) => {
  const { name, graph, is_active, modifier_settings, series, order, action } = modifierData || {};
  const isEditMode = modifierType === 'edit';
  const hasGroupByField = groupType === '1';
  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });
  const getEditFields = modifier_settings?.order_fields.map((label, id) => ({ label, id: id.toString() }));
  const getEditGroupFields = modifier_settings?.order_group_fields.map((label, id) => ({ label, id: id.toString() }));
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Name required'),
    order: Yup.string().required('*Order required'),
    series: Yup.array().min(1, '*Series required'),
  });

  const [fields, setFields] = useState(
    isEditMode ? (isEmpty(getEditFields) ? modifierFields : getEditFields) : modifierFields,
  );
  const [useFields, setUseFields] = useState(isEditMode && !isEmpty(getEditFields));

  const [groupFields, setGroupFields] = useState(
    isEditMode ? (isEmpty(getEditGroupFields) ? modifierGroupFields : getEditGroupFields) : modifierGroupFields,
  );
  const [useGroupFields, setUseGroupFields] = useState(isEditMode && !isEmpty(getEditGroupFields));

  const initialCreateValues = {
    graph: graphId,
    name: '',
    order: '',
    action: modifierAction,
    is_active: true,
    series: [],
  };

  const editInitialValues = {
    graph,
    name,
    order,
    action,
    is_active,
    series,
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFields(items);
  };

  const handleOnDragEndGroup = (result) => {
    if (!result.destination) return;

    const items = Array.from(groupFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setGroupFields(items);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode ? editInitialValues : initialCreateValues,
    validationSchema,
    onSubmit: (values) => {
      const orderFields = useFields ? fields.map(({ label }) => label) : [];
      const orderGroupFields = useGroupFields ? groupFields.map(({ label }) => label) : [];

      const getValues = Object.assign(values, {
        modifier_settings: { order_fields: orderFields, order_group_fields: orderGroupFields },
      });
      const result = JSON.stringify(getValues, (k, val) => (val === '' ? null : val));
      const urlEditModifier = ENDPOINTS.workLook.crudModifier.replace(':modifierId', modifierId);
      const url = ENDPOINTS.workLook.modifiersList.replace(':graphId', graphId);
      const config = {
        method: isEditMode ? 'put' : 'post',
        url: isEditMode ? urlEditModifier : url,
        headers: {
          Authorization: `Token ${isLogged()}`,
          'Content-Type': 'application/json',
        },
        data: result,
      };
      axios(config)
        .then(() => {
          onClose();
          window.location.reload(false);
        })
        .catch((error) => {
          setErrors({ isError: true, errorMsg: error?.response?.data?.non_field_errors });
        });
    },
  });

  return (
    <Choose>
      <When condition={isModifierLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>
      <Otherwise>
        <div className={theme.container}>
          <div className={theme.header}>
            <Typography variant="h5" className={theme.title}>
              {isEditMode ? 'Edit Order Value Modifier' : 'Create Order Value Modifier'}
            </Typography>
          </div>
          <div className={theme.formWrapper}>
            <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
              {errors.isError &&
                errors.errorMsg.map((msg) => (
                  <Typography variant="body2" className={theme.error}>
                    {msg}
                  </Typography>
                ))}
              <div>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  placeholder="Enter series name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.name}
                </Typography>
              </div>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="is_active"
                      name="is_active"
                      color="primary"
                      checked={formik.values.is_active}
                      value={formik.values.is_active}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Is Active Modifier"
                />
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="series">Series</InputLabel>
                  <Select
                    labelId="series"
                    id="series"
                    name="series"
                    label="Series"
                    multiple
                    value={formik.values.series}
                    onChange={formik.handleChange}
                  >
                    {seriesList.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="body2" className={theme.error}>
                  {formik.errors?.series}
                </Typography>
              </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    style={{ marginLeft: '5px' }}
                    control={
                      <Switch
                        color="primary"
                        checked={useFields}
                        onChange={() => setUseFields(!useFields)}
                        name="useFields"
                      />
                    }
                    label="Order Fields"
                  />
                </FormGroup>
                <If condition={useFields}>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="fields">
                      {(provided) => (
                        <ul className="fields" {...provided.droppableProps} ref={provided.innerRef}>
                          {fields.map(({ id, label }, index) => {
                            return <FieldCard key={id} id={id} name={label} index={index} />;
                          })}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </If>
              </Box>
              <If condition={hasGroupByField}>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      style={{ marginLeft: '5px' }}
                      control={
                        <Switch
                          color="primary"
                          checked={useGroupFields}
                          onChange={() => setUseGroupFields(!useGroupFields)}
                          name="useGroupFields"
                        />
                      }
                      label="Order Group by fields"
                    />
                  </FormGroup>
                  <If condition={useGroupFields}>
                    <DragDropContext onDragEnd={handleOnDragEndGroup}>
                      <Droppable droppableId="groupFields">
                        {(provided) => (
                          <ul className="groupFields" {...provided.droppableProps} ref={provided.innerRef}>
                            {groupFields.map(({ id, label }, index) => {
                              return <FieldCard key={id} id={id} name={label} index={index} />;
                            })}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </If>
                </Box>
              </If>
              <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  className={theme.input}
                  type="number"
                  id="order"
                  name="order"
                  label="Order"
                  placeholder="Enter an order"
                  value={formik.values.order}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
                <Typography variant="body2" className={theme.error}>
                  {formik.errors.order}
                </Typography>
              </Box>
              <div className={theme.btnContainer}>
                <Button onClick={() => onCancelModifier()} className={theme.cancelBtn} variant="outlined">
                  Cancel
                </Button>
                <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default SortingForm;
