/* eslint-disable react/no-children-prop */
import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

import { useAssetLookSelector } from 'modules/assetLook/hooks';

const LocationSelect = ({ formik }) => {
  const [locations] = useAssetLookSelector(['common.locations']);

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel children="Location *" />
      <Select
        id="location"
        name="location"
        disabled={formik.isSubmitting}
        value={formik.values.location}
        onChange={formik.handleChange}
        error={formik.touched.location && Boolean(formik.errors.location)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {locations.map(({ id, data: { Building, Floor, Zone } }) => (
          <MenuItem key={id} value={id}>
            {`${Building} (${Floor}, ${Zone})`}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText
        error={formik.touched.location && Boolean(formik.errors.location)}
        children={formik.touched.location && formik.errors.location}
      />
    </FormControl>
  );
};

export default LocationSelect;
