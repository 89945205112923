const config = {
  legend: [
    {
      iconType: 'round',
      label: 'Average Occupancy',
      color: '#b4cc04',
    },
    {
      iconType: 'round',
      label: 'Peak Occupancy',
      color: '#c4043f',
    },
    {
      iconType: 'round',
      label: 'Average Utilisation',
      color: '#51c3ca',
    },
    {
      iconType: 'round',
      label: 'Peak Utilisation',
      color: '#004352',
    },
  ],
};

export default config;
