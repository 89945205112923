import React, { useState } from 'react';
import { cloneDeep, isUndefined } from 'lodash';
import { Bar } from 'react-chartjs-2';
import cn from 'classnames';
import { useHasScroll } from 'modules/shared/hooks';
import GraphYScale from 'modules/liveLook/components/GraphYScale';

import { NoDataAvailable } from 'modules/liveLook/utils';
import options from './options';
import optionsPdf from './optionsPdf';
import theme from './GraphBody.module.scss';
import { BAR_WIDTH } from './graphBody.constants';
import { setMarginBottom, setWidth } from '../../verticalBar.utils';

function GraphBody({
  scaleValues = ['100%', '80%', '60%', '40%', '20%', '0%'],
  data,
  pdfCustomHeader,
  isSwitcherOn,
  isWeekGraph,
  isDailyComparisons,
  isSummaryPdf,
}) {
  const { ref } = useHasScroll();
  const [barPosition, setBarPosition] = useState(0);
  const optionsDeep = cloneDeep(options(setBarPosition));
  const optionsDeepPdf = cloneDeep(optionsPdf(setBarPosition, isSummaryPdf));

  if (typeof scaleValues[0] === 'number') {
    // eslint-disable-next-line prefer-destructuring
    optionsDeep.scales.yAxes[0].ticks.max = scaleValues[0];
    // eslint-disable-next-line prefer-destructuring
    optionsDeepPdf.scales.yAxes[0].ticks.max = scaleValues[0];
  }

  return (
    <div className={cn(theme.container, { [`${theme.pdfMode}`]: pdfCustomHeader })}>
      <div className={theme.graphWrapper}>
        {
          // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
          data ? (
            <>
              <GraphYScale
                marginBottom={setMarginBottom(pdfCustomHeader, barPosition)}
                pdf={pdfCustomHeader}
                values={scaleValues}
                isSwitcherOn={isSwitcherOn}
              />

              <div className={cn(theme.graphInner, !isUndefined(pdfCustomHeader) && theme.pdfGraphInner)} ref={ref}>
                <div
                  className={theme.graph}
                  style={{
                    width: setWidth(data.labels.length, BAR_WIDTH, pdfCustomHeader, isWeekGraph, isDailyComparisons),
                  }}
                >
                  <Bar data={data} options={pdfCustomHeader ? optionsDeepPdf : optionsDeep} />
                </div>
              </div>
            </>
          ) : (
            <NoDataAvailable theme={pdfCustomHeader ? theme.pdfGraphSection : theme.graphSection} />
          )
        }
      </div>
    </div>
  );
}

export default GraphBody;
