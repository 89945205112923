/* eslint-disable func-names */
import { Chart } from 'react-chartjs-2';

const options = {
  layout: {
    padding: {
      left: -10,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        ticks: {
          minRotation: 90,
        },
      },
    ],
    yAxes: [
      {
        ticks: { min: 0, max: 100, display: false },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  // No tooltip
  events: [],
  // Add percent above occupied bar
  animation: {
    onComplete() {
      const chartInstance = this.chart;
      const { ctx } = chartInstance;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.verticalAlign = 'center';
      ctx.font = '9px Roboto';

      const { datasets } = this.data;

      Chart.helpers.each(
        datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);

          Chart.helpers.each(
            meta.data.forEach(function (bar, index) {
              if (dataset.label !== 'Occupied') return;
              const data = dataset.data[index];
              if (data === 0) return;
              ctx.fillStyle = '#000';
              const yCoef = -10;
              ctx.save();
              ctx.translate(bar._model.x, bar._model.y + yCoef);
              ctx.rotate(-0.5 * Math.PI);
              ctx.fillText(data, 0, 0);
              ctx.restore();
            }),
            this,
          );
        }),
        this,
      );
    },
  },
};

export default options;
