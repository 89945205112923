/* eslint-disable no-param-reassign */
import { GRAPH_SCALES } from 'modules/liveLook/constants';
import { MAX_BARS_IN_GRAPH, MAX_BARS_FOR_HOURS, MAX_BARS_FOR_DAYS } from '../constants/amountOfBars';

const getLabelSize = (length, scale) => {
  if (scale === GRAPH_SCALES.hour) return Math.trunc(MAX_BARS_FOR_HOURS / length);

  if (scale === GRAPH_SCALES.halfAnHour) return Math.trunc(MAX_BARS_IN_GRAPH / length);

  return Math.trunc(MAX_BARS_FOR_DAYS / length);
};

const fillArray = (dateArrays, chunk) => {
  return dateArrays.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
};

export const splitGraphData = (hoursLength, dataGroupedByDays, scale) => {
  if (!dataGroupedByDays) return false;

  const chunk = getLabelSize(hoursLength, scale); // items per chunk
  const dateArrays = dataGroupedByDays;

  return fillArray(dateArrays, chunk, scale);
};
