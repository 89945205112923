/* eslint-disable react/no-children-prop */
/* eslint-disable camelcase */
import React from 'react';
import { isEmpty } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { TextPlaceholder } from 'modules/shared/components';

import { useEditGraphClick, useGraphClick, useModifierGraph } from 'modules/workLook/hooks';
import theme from './DataTable.module.scss';

const DataTable = ({
  isLoading,
  data,
  showTitle,
  title,
  sub_title,
  isModal,
  modalData,
  typeOfGroup,
  graphId,
  isPublished,
  type,
  isPdf,
}) => {
  const { onClick } = useGraphClick(isModal, modalData);
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && isEmpty(data.rows)}>
        <Paper elevation={2} className={theme.container}>
          <div className={theme.header}>
            <GraphHeader
              display={false}
              showTitle={showTitle}
              graphId={graphId}
              title={title}
              subTitle={sub_title}
              isPdf={isPdf}
              onClick={onEditClick}
              onModifierClick={onModifierClick}
              isPublished={isPublished}
            />
          </div>
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper elevation={2} className={isModal ? theme.container : theme.containerModal} onClick={onClick}>
          <div className={theme.header}>
            <GraphHeader
              display={false}
              showTitle={showTitle}
              graphId={graphId}
              title={title}
              subTitle={sub_title}
              isPdf={isPdf}
              onClick={onEditClick}
              onModifierClick={onModifierClick}
              isPublished={isPublished}
            />
          </div>
          <TableContainer className={theme.tableContainer}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {data.headers.map((value, i) => (
                    <TableCell key={i} align="center" children={value} />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(data.rows).map(([rowName, rowValues]) => (
                  <TableRow key={rowName}>
                    <TableCell>{rowName}</TableCell>
                    {rowValues.map((value, i) => (
                      <TableCell key={i} align="right" children={value} />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Otherwise>
    </Choose>
  );
};

export default DataTable;
