import { createStore, applyMiddleware, combineReducers } from 'redux';
import { throttle } from 'lodash';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import auth from 'modules/auth/reducers';
import { LOG_OUT } from 'modules/auth/actions';

import report from 'modules/spaceLook/reducers';
import { sharedReducer as shared, errorReducer as error } from 'modules/shared/reducers';
import modals from 'modules/modalsSystem/reducers/modalsReducer';
import secondModal from 'modules/modalsSystem/reducers/secondModalReducer';
import assetLook from 'modules/assetLook/reducers';
import workLook from 'modules/workLook/reducers';
import liveLook from 'modules/liveLook/reducers';

const middleware = [];

if (process.env.NODE_ENV === `development`) {
  const logger = createLogger({
    collapsed: true,
    timestamp: false,
    colors: {
      title: (action) => {
        let color = 'inherit';
        if (action.type.includes('SUCCESS')) color = 'green';
        if (action.type.includes('FAILURE')) color = 'red';
        return color;
      },
    },
  });
  middleware.push(logger);
}

const appReducer = combineReducers({
  auth,
  shared,
  error,
  report,
  workLook,
  assetLook,
  modals,
  secondModal,
  liveLook,
});

// Dan Abramov`s approach: https://stackoverflow.com/a/35641992
const rootReducer = (state, action) => {
  if (action.type === LOG_OUT().type) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

store.subscribe(
  throttle(() => {
    const state = store.getState();

    try {
      const company = JSON.stringify(state.auth.currentCompany);
      localStorage.setItem('lastCompany', company);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }),
  1000,
);

export default store;
