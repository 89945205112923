/* eslint-disable camelcase */
const DesksOccupancyPlusSigns = ({
  databoxes: {
    total_desks,
    avg_occupancy,
    percent_avg_occupancy,
    max_occupancy,
    percent_max_occupancy,
    max_utilization,
    percent_max_utilization,
  },
}) =>
  `${total_desks} desks were surveyed and the average percentage of physically occupied desks
   was ${percent_avg_occupancy}% (${avg_occupancy} desks) with an observed peak of ${percent_max_occupancy}%
   (${max_occupancy} of the ${total_desks} desks occupied, leaving at least ${total_desks - max_occupancy} desks
   either vacant or exhibiting signs of life). Once signs of life are included 
   the peak utilisation was ${percent_max_utilization}% (${max_utilization} desks).`;

export default DesksOccupancyPlusSigns;
