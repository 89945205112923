import React from 'react';
import { Grid } from '@material-ui/core';
import { OrderItemSkeleton } from 'modules/assetLook/components';

const OrderListSkeleton = ({ hasActions }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <OrderItemSkeleton hasActions={hasActions} />
    </Grid>
  </Grid>
);

export default OrderListSkeleton;
