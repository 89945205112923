import React from 'react';

import SurveyDetails from 'pages/_privatePages/spaceLook/SurveyDetails';
import KeyFindingDesks from 'pages/_privatePages/spaceLook/KeyFindingsDesks';
import KeyFindingsMeetings from 'pages/_privatePages/spaceLook/KeyFindingsMeetings';
import KeyFindingsBreakout from 'pages/_privatePages/spaceLook/KeyFindingsBreakout';
import CombinedOccupancy from 'pages/_privatePages/spaceLook/CombinedOccupancy';
import DesksUsageOverTime from 'pages/_privatePages/spaceLook/DesksUsageOverTime';
import DesksDailyUsagePattern from 'pages/_privatePages/spaceLook/DesksDailyUsagePattern';
import DesksDailyComparison from 'pages/_privatePages/spaceLook/DesksDailyComparison';
import DesksBenchmarking from 'pages/_privatePages/spaceLook/DesksBenchmarking';
import DesksByBuildingAndFloor from 'pages/_privatePages/spaceLook/DesksByBuildingAndFloor';
import DesksByDivAndDep from 'pages/_privatePages/spaceLook/DesksByDivAndDep';
import DesksByType from 'pages/_privatePages/spaceLook/DesksByType';
import DesksUsageBandingOccupied from 'pages/_privatePages/spaceLook/DesksUsageBandingOccupied';
import DesksUsageBandingUtilized from 'pages/_privatePages/spaceLook/DesksUsageBandingUtilized';
import DesksAgility from 'pages/_privatePages/spaceLook/DesksAgility';
import MeetingsUsageOverTime from 'pages/_privatePages/spaceLook/MeetingsUsageOverTime';
import MeetingsByBuildingAndFloor from 'pages/_privatePages/spaceLook/MeetingsByBuildingAndFloor';
import MeetingsSpaceCompAndSizes from 'pages/_privatePages/spaceLook/MeetingsSpaceCompAndSizes';
import BreakoutUsageOverTime from 'pages/_privatePages/spaceLook/BreakoutUsageOverTime';
import BreakoutByBuildingAndFloor from 'pages/_privatePages/spaceLook/BreakoutByBuildingAndFloor';
import BreakoutSpaceComparison from 'pages/_privatePages/spaceLook/BreakoutSpaceComparison';
import Heatmap from 'pages/_privatePages/spaceLook/Heatmap';

import { ReportPdfTitle, ReportPdfSubTitle, HeatmapSubTitle } from 'modules/spaceLook/components';

import { reportPageTypes } from 'modules/spaceLook/constants';

import theme from './SLPdfPage.module.scss';

const SLPdfPage = ({ heatmapData, deskTypesQuantity }) => (
  <div className={theme.container}>
    <ReportPdfTitle />
    <SurveyDetails />
    <KeyFindingDesks />
    <KeyFindingsMeetings />
    <KeyFindingsBreakout />
    <CombinedOccupancy />

    <ReportPdfSubTitle type={reportPageTypes.desks} />
    <DesksUsageOverTime />
    <DesksDailyUsagePattern />
    <DesksDailyComparison />
    <DesksBenchmarking />
    <DesksByBuildingAndFloor />
    <DesksByDivAndDep />
    <If condition={deskTypesQuantity > 1}>
      <DesksByType />
    </If>
    <DesksUsageBandingOccupied />
    <DesksUsageBandingUtilized />
    <DesksAgility />

    <ReportPdfSubTitle type={reportPageTypes.meetings} />
    <MeetingsUsageOverTime />
    <MeetingsByBuildingAndFloor />
    <MeetingsSpaceCompAndSizes />

    <ReportPdfSubTitle type={reportPageTypes.breakout} />
    <BreakoutUsageOverTime />
    <BreakoutByBuildingAndFloor />
    <BreakoutSpaceComparison />

    <If condition={heatmapData && heatmapData.length}>
      <HeatmapSubTitle />
      <Heatmap />
    </If>
  </div>
);

export default SLPdfPage;
