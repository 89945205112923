import React from 'react';
import { Grid } from '@material-ui/core';

import { PageHeader } from 'modules/shared/components';
import { VerticalBarGraph, ReportDataCards, ByBuildingGraphHeader } from 'modules/liveLook/components';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';

import { GRAPH_TYPES, pageTypes } from 'modules/liveLook/constants';
import styles from '../../LLPrintablePdfPage.module.scss';
import config from './config';

const DoorsByBuilding = ({
  building,
  data,
  scaleValues,
  databoxes,
  editableTextReport,
  returnPercentageSwitcher,
  activeBuilding,
}) => {
  return (
    <Grid style={{ paddingTop: '10px', paddingLeft: '17px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader pdfMode title="Occupancy" />
        </Grid>
      </Grid>
      <div className={styles.blockContainer}>
        <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.occupancy} />
      </div>

      <VerticalBarGraph
        CustomHeader={() => (
          <ByBuildingGraphHeader
            title="Comparison by Building and Floor"
            building={building}
            activeBuilding={activeBuilding}
            onBuildingChange={() => {}}
            onSwitcherChange={() => {}}
            switcher={returnPercentageSwitcher}
            pdf
            isGraphScaleExist={!!scaleValues?.length}
            pdfCustomHeader
          />
        )}
        pdfCustomHeader
        data={data}
        scaleValues={scaleValues}
        config={config}
        returnPercentageSwitcher={returnPercentageSwitcher}
        isLiveLook
      />
      <PDFTextEditorContainer graphType={GRAPH_TYPES.VERTICAL} data={[]} onSave={() => {}} text={editableTextReport} />
    </Grid>
  );
};

export default DoorsByBuilding;
