/* eslint-disable no-shadow */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useReducer, useCallback } from 'react';
import { useAuthSelector } from 'modules/auth/hooks';
import { useLiveLookSelector } from 'modules/liveLook/hooks';
import { dateToQuery, filtersToQuery, toBuildingDeskGraphData } from 'modules/liveLook/utils';
import { isDefault, isFailure, isLoading, isSuccess } from 'modules/shared/utils';
import {
  fetchDeskByBuilding,
  fetchDeskDataboxes,
  SET_GRAPH_SWITCHER,
  fetchGraphsText,
  saveGraphsText,
  fetchDeskVariables,
} from 'modules/liveLook/actions';
import { Loader } from 'components';
import { TextPlaceholder } from 'modules/shared/components';
import DesksByBuilding from './DesksByBuilding';
import { setScaleValues } from '../utils';
import reducer, { initialState } from './_reducer';
import { useActiveBuildings } from '../hooks';

function DesksByBuildingContainer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestStatus,
    data,
    building,
    deskDataboxes,
    databoxRequestStatus,
    returnPercentageSwitcher,
    editableTextReport,
    textReportRequestStatus,
    deskVariableRequestStatus,
    textVariables,
  } = state;

  const requestStatuses = [databoxRequestStatus, requestStatus, textReportRequestStatus, deskVariableRequestStatus];

  const [companyId] = useAuthSelector(['currentCompany']);
  const [startDate, endDate, workingHours, activeDeskFilters, buildings, archive] = useLiveLookSelector([
    'common.startDate',
    'common.endDate',
    'common.workingHours',
    'deskFilters.activeDeskFilters',
    'common.buildings',
    'common.archive',
  ]);

  const query = filtersToQuery(activeDeskFilters);
  const dateAfter = dateToQuery(startDate, [0, 0, 0]);
  const dateBefore = dateToQuery(endDate, [23, 59, 59]);

  useEffect(() => {
    let isCanceled = false;

    fetchDeskByBuilding(dispatch, isCanceled, {
      companyId,
      buildingId: state.building,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchDeskDataboxes(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchDeskVariables(dispatch, isCanceled, {
      companyId,
      dateAfter,
      dateBefore,
      workingHours,
      query,
      archive,
    });

    fetchGraphsText(dispatch, isCanceled, {
      companyId,
      page: 'desk_bld_flr',
    });

    // eslint-disable-next-line no-return-assign
    return () => (isCanceled = true);
  }, [companyId, archive, state.building, dateAfter, dateBefore, workingHours, query]);

  const databoxesSet = {
    deskDataboxes,
  };

  const mappedData = (data) => {
    const arrData = [];
    for (const key in data) {
      arrData.push(Object.assign(data[key], { name: key }));
    }
    return arrData;
  };

  // eslint-disable-next-line prefer-spread
  const peakOccupancy = Math.max.apply(
    Math,
    mappedData(data).map((key) => key[returnPercentageSwitcher ? 'max_occupancy' : 'percentage_max_occupancy']),
  );
  const { scaleValues } = setScaleValues(peakOccupancy);

  const onSwitcherChange = useCallback(
    () => dispatch(SET_GRAPH_SWITCHER(!returnPercentageSwitcher)),
    [returnPercentageSwitcher],
  );

  const { activeBuilding, onChange: onBuildingChange } = useActiveBuildings(buildings, dispatch);

  return (
    <Choose>
      <When condition={requestStatuses.some((item) => isDefault(item) || isLoading(item))}>
        <Loader />
      </When>
      <When condition={requestStatuses.every((item) => isSuccess(item))}>
        <DesksByBuilding
          saveGraphsText={async (text) => {
            saveGraphsText(dispatch, false, { companyId, page: 'desk_bld_flr', text });
          }}
          editableTextReport={editableTextReport}
          data={toBuildingDeskGraphData(data, returnPercentageSwitcher)}
          building={building}
          onBuildingChange={onBuildingChange}
          onSwitcherChange={onSwitcherChange}
          databoxes={deskDataboxes}
          returnPercentageSwitcher={returnPercentageSwitcher}
          scaleValues={scaleValues}
          databoxesSet={databoxesSet}
          activeBuilding={activeBuilding}
          textVariables={textVariables}
          archive={archive}
        />
      </When>
      <When condition={requestStatuses.some((item) => isFailure(item))}>
        <TextPlaceholder error />
      </When>
    </Choose>
  );
}

export default DesksByBuildingContainer;
