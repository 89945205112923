const meetSpaceUsageBySizeGraphConfig = [
  {
    iconType: 'round',
    label: 'Average Occupancy',
    color: '#34BBDB',
  },
  {
    iconType: 'round',
    label: 'Peak Occupancy',
    color: '#0D6F85',
  },
  {
    iconType: 'round',
    label: 'Average Occupancy (seats)',
    color: '#c577c0',
  },
  {
    iconType: 'round',
    label: 'Peak Occupancy (seats)',
    color: '#a2559d',
  },
];

export default meetSpaceUsageBySizeGraphConfig;
