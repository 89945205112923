import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 0,
  },
  subTitle: {
    color: theme.palette.primary.dark,
    textAlign: 'center',
    marginBottom: 0,
  },
  description: {
    color: '#9e9e9e',
    marginBottom: 0,
    fontSize: '12px',
  },
  additionalInfo: {
    textAlign: 'center',
    marginBottom: 0,
    color: '#9e9e9e',
    fontSize: '14px',
  },
  values: {
    color: theme.palette.primary.dark,
    textAlign: 'center',
    marginBottom: 0,
  },
}));
