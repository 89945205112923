/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { cloneDeep } from 'lodash';
import { graphSortOptions } from 'components/Pages/SummaryPage/constants';

const sortAlphabetically = (data, asc) =>
  data.sort(({ labels: a }, { labels: b }) => {
    if (a[0] === b[0]) return 0;
    // if (a[0] === 'Not Assigned') return asc ? 1 : -1;
    // if (b[0] === 'Not Assigned') return asc ? -1 : 1;

    if (a[0] < b[0]) return asc ? -1 : 1;
    if (a[0] > b[0]) return asc ? 1 : -1;

    return 0;
  });

const sortValue = (data, asc) =>
  data.sort(({ datasets: a }, { datasets: b }) => {
    if (!a.length) return 1;
    if (!b.length) return -1;

    const aValue = a.reduce((sum, currentItem) => {
      if (currentItem.label[0] === 'Free Space') return sum;
      return (sum += currentItem.data[0]);
    }, 0);
    const bValue = b.reduce((sum, currentItem) => {
      if (currentItem.label[0] === 'Free Space') return sum;
      return (sum += currentItem.data[0]);
    }, 0);

    if (aValue === bValue) return 0;
    if (aValue < bValue) return asc ? -1 : 1;
    if (aValue > bValue) return asc ? 1 : -1;

    return 0;
  });

const sortData = (data, sortOrder) => {
  const deep = cloneDeep(data);
  let sorted;

  switch (sortOrder) {
    case graphSortOptions.az:
      return (sorted = sortAlphabetically(deep, true));
    case graphSortOptions.za:
      return (sorted = sortAlphabetically(deep, false));
    case graphSortOptions.asc:
      return (sorted = sortValue(deep, true));
    case graphSortOptions.desc:
      return (sorted = sortValue(deep, false));
    default:
      sorted = data;
  }

  return sorted;
};

export { sortData };
