import React from 'react';
import { Menu as MuiMenu, MenuItem, Switch, makeStyles, CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '45px !important',
  },
  signOut: {
    color: theme.palette.primary.dark,
  },
  switchBase: {
    '&:hover': {
      backgroundColor: 'unset',
    },

    '&$checked': {
      color: theme.palette.primary.dark,

      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  checked: {},
  track: {},
}));

const Menu = ({
  anchorEl,
  handleClose,
  isOtpRequired,
  isDeleting,
  _onSignOutClick,
  onUserSettings,
  onCompanySettings,
  _on2FAClick,
  anotherUser,
  isAdmin,
  token,
}) => {
  const classes = useStyles();
  const label = anotherUser && !isEmpty(token) ? 'Return to admin account' : 'Sign out';

  return (
    <MuiMenu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PopoverClasses={{ paper: classes.paper }}
      onClose={handleClose}
    >
      <MenuItem onClick={_on2FAClick}>
        2FA
        <Switch
          checked={isOtpRequired}
          classes={{
            switchBase: classes.switchBase,
            checked: classes.checked,
            track: classes.track,
          }}
          disableRipple
        />
        <If condition={isOtpRequired && isDeleting}>
          <CircularProgress size={24} style={{ position: 'absolute', left: '70px' }} />
        </If>
      </MenuItem>
      <MenuItem onClick={_onSignOutClick} classes={{ root: classes.signOut }}>
        {label}
      </MenuItem>
      <MenuItem onClick={onUserSettings} classes={{ root: classes.signOut }}>
        User Settings
      </MenuItem>
      <If condition={isAdmin}>
        <MenuItem onClick={onCompanySettings} classes={{ root: classes.signOut }}>
          Company Settings
        </MenuItem>
      </If>
    </MuiMenu>
  );
};

export default Menu;
