import React from 'react';

import noImage from 'assets/images/icons/no-image.svg';
import building from 'assets/images/buildings.png';

import config from './config';
import './PropertyInfo.scss';

const PropertyInfo = ({ activePropertyData }) => {
  const imgStyles = {
    backgroundImage: `url(${activePropertyData.photo || noImage})`,
    backgroundColor: activePropertyData.photo ? 'transparent' : '#E4E4E4',
    backgroundSize: activePropertyData.photo ? 'contain' : '250px auto',
    backgroundPosition: activePropertyData.photo ? '' : 'center',
  };

  return (
    <section className="propertyInfoContainer">
      <div className="propertyImage" style={imgStyles} />
      <ul className="propertyInfoList">
        {config.map(({ key, label, prefix, suffix }, i) => (
          <If condition={activePropertyData[key]}>
            <li className="propertyInfoListItem" key={i}>
              <span className="label">{label}: </span>
              <span className="text">{`${prefix || ''}${activePropertyData[key]}${suffix || ''}`}</span>
            </li>
          </If>
        ))}
      </ul>
    </section>
  );
};

PropertyInfo.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  activePropertyData: {
    photo: building,
  },
};

export default PropertyInfo;
