import React from 'react';
import PropTypes from 'prop-types';

import './FormButton.scss';

const FormButton = ({ text }) => {
  return (
    <div className="FormButton">
      <button type="submit" className="form-button">
        {text}
      </button>
    </div>
  );
};

FormButton.defaultProps = {
  text: 'Log In',
};

FormButton.propTypes = {
  text: PropTypes.string,
};

export default FormButton;
