import React from 'react';
import { Grid } from '@material-ui/core';
import PDFTextEditorContainer from 'modules/shared/components/TextEditor/PDFTextEditorContainer';

import { PageHeader } from 'modules/shared/components';
import { VerticalBarGraph, ReportDataCards, ByBuildingGraphHeader } from 'modules/liveLook/components';
import { isEmpty } from 'lodash';
import { GRAPH_TYPES, pageTypes } from 'modules/liveLook/constants';
import styles from '../../LLPrintablePdfPage.module.scss';
import renderFilters from '../../utils/activeFiltersPdf';
import config from './config';

const DesksByBuilding = ({
  building,
  data,
  scaleValues,
  databoxes,
  editableTextReport,
  returnPercentageSwitcher,
  activeFilters,
  activeBuilding,
}) => {
  const shouldRenderFilters = activeFilters.every((item) => isEmpty(item.values));

  return (
    <Grid style={{ paddingTop: '20px', paddingLeft: '30px' }} container spacing={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader pdfMode title="Desk by building" />
        </Grid>
      </Grid>
      {!shouldRenderFilters && renderFilters(activeFilters)}
      <div className={styles.blockContainer}>
        <ReportDataCards pdf databoxes={databoxes} pageType={pageTypes.desks} />
      </div>
      <Grid style={{ paddingTop: 7 }} xs={12}>
        <VerticalBarGraph
          CustomHeader={() => (
            <ByBuildingGraphHeader
              title="Comparison by Building"
              building={building}
              pdf
              onBuildingChange={() => {}}
              onSwitcherChange={() => {}}
              switcher={returnPercentageSwitcher}
              activeBuilding={activeBuilding}
              pdfCustomHeader
            />
          )}
          data={data}
          scaleValues={scaleValues}
          config={config}
          returnPercentageSwitcher={returnPercentageSwitcher}
          pdfCustomHeader
          isLiveLook
        />
      </Grid>
      <PDFTextEditorContainer
        hasFilters={shouldRenderFilters}
        graphType={GRAPH_TYPES.VERTICAL}
        data={[]}
        onSave={() => {}}
        text={editableTextReport}
      />
    </Grid>
  );
};

export default DesksByBuilding;
