import { byDivAndDepGraphTypes } from 'modules/spaceLook/constants';

const DesksDivHighestUtil = ({ divOrDepData, type }) => {
  if (divOrDepData.length < 1) return null;

  const highestOcc = divOrDepData.reduce(
    (max, current) => (max.percent_max_occupancy < current.percent_max_occupancy ? current : max),
    divOrDepData[0],
  );

  const highestUtil = divOrDepData.reduce(
    (max, current) => (max.percent_max_utilization < current.percent_max_utilization ? current : max),
    divOrDepData[0],
  );

  const divOrDep = type === byDivAndDepGraphTypes.div ? 'division' : 'department';

  return `In terms of utilisation, ${highestUtil.name} ${divOrDep} ${
    highestOcc.name === highestUtil.name ? 'also' : ''
  } 
     had the highest utilisation (including signs of life) with a peak of ${highestUtil.percent_max_utilization}%
     and an average utilisation of ${highestUtil.percent_avg_utilization}%.`;
};

export default DesksDivHighestUtil;
