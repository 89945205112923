/* eslint-disable camelcase */
import { roundToFloat } from 'utils';

const BenchOccPostAgile = ({ databoxes: { percent_max_occupancy }, textData: { percent_peak_occupancy_agile } }) => {
  const occDiff = roundToFloat(percent_peak_occupancy_agile - percent_max_occupancy);
  const higherOrLower = occDiff > 0 ? 'lower' : 'higher';

  return `When compared to a post agile working environment, it was ${Math.abs(occDiff)}% ${higherOrLower}
     than our post agile working benchmark of ${percent_peak_occupancy_agile}%.`;
};

export default BenchOccPostAgile;
