import { byDivAndDepGraphTypes } from 'modules/spaceLook/constants';

const DesksDivHighestOcc = ({ divOrDepData, type }) => {
  if (divOrDepData.length < 1) return null;

  const highest = divOrDepData.reduce(
    (max, current) => (max.percent_max_occupancy < current.percent_max_occupancy ? current : max),
    divOrDepData[0],
  );

  const divOrDep = type === byDivAndDepGraphTypes.div ? 'division' : 'department';

  return `${highest.name} ${divOrDep} had the highest occupancy with a peak of ${highest.percent_max_occupancy}%
     and an average occupancy of ${highest.percent_avg_occupancy}%.`;
};

export default DesksDivHighestOcc;
