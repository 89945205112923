import { createReducer } from '@reduxjs/toolkit';

import {
  SET_INTEGER_SWITCHER,
  SET_TIME_GRAPH_SCALE,
  SET_TIME_GRAPH_VALUE,
  RESET_TIME_GRAPH,
} from 'modules/workLook/actions';

const initState = {
  intSwitcher: false,
  timeScale: '1 hour',
  valueMethod: 'average',
};

export default createReducer(initState, {
  [SET_INTEGER_SWITCHER]: (state, { payload }) => ({
    ...state,
    intSwitcher: payload,
  }),
  [SET_TIME_GRAPH_SCALE]: (state, { payload }) => ({
    ...state,
    timeScale: payload,
  }),
  [SET_TIME_GRAPH_VALUE]: (state, { payload }) => ({
    ...state,
    valueMethod: payload,
  }),
  [RESET_TIME_GRAPH]: (state, { payload }) => ({
    ...state,
    intSwitcher: false,
    timeScale: '1 hour',
    valueMethod: 'average',
  }),
});
