import { requestStatuses } from 'modules/shared/constants';
import {
  SET_BUILDING,
  FETCH_COMBINED_OCCUPANCY_DATABOXES,
  FETCH_COMBINED_TEAM_ZONE_OCCUPANCY,
  FETCH_COMBINED_OCCUPANCY_VARIABLES,
  SET_GRAPH_SWITCHER,
  FETCH_GRAPHS_TEXT,
  SAVE_GRAPHS_TEXT,
  SET_GRAPH_SCALE,
  SET_GRAPH_VALUE,
} from 'modules/liveLook/actions';
import { GRAPH_SCALES, GRAPH_SWITCHER, GRAPH_VALUES } from 'modules/liveLook/constants';

export const initialState = {
  requestStatus: requestStatuses.default,
  databoxRequestStatus: requestStatuses.default,
  textReportRequestStatus: requestStatuses.default,
  combinedOccVariablesRequestStatus: requestStatuses.default,
  data: [],
  maxNum: 0,
  building: 0,
  occupancyDataboxes: [],
  returnPercentageSwitcher: GRAPH_SWITCHER.percentages,
  editableTextReport: '',
  scale: GRAPH_SCALES.halfAnHour,
  value: GRAPH_VALUES.max,
  textVariables: { combinedOccupancyVariables: {} },
};

function reducer(state, { type, payload }) {
  switch (type) {
    case FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.pending().type:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.success().type:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        data: payload,
      };
    case FETCH_COMBINED_TEAM_ZONE_OCCUPANCY.failure().type:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };
    case FETCH_COMBINED_OCCUPANCY_DATABOXES.pending().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.pending,
      };
    case FETCH_COMBINED_OCCUPANCY_DATABOXES.success().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.success,
        occupancyDataboxes: payload,
      };
    case FETCH_COMBINED_OCCUPANCY_DATABOXES.failure().type:
      return {
        ...state,
        databoxRequestStatus: requestStatuses.failure,
      };
    case FETCH_COMBINED_OCCUPANCY_VARIABLES.pending().type:
      return {
        ...state,
        combinedOccVariablesRequestStatus: requestStatuses.pending,
      };
    case FETCH_COMBINED_OCCUPANCY_VARIABLES.success().type:
      return {
        ...state,
        combinedOccVariablesRequestStatus: requestStatuses.success,
        textVariables: { ...state.textVariables, ...{ combinedOccupancyVariables: payload } },
      };
    case FETCH_COMBINED_OCCUPANCY_VARIABLES.failure().type:
      return {
        ...state,
        combinedOccVariablesRequestStatus: requestStatuses.failure,
      };
    case FETCH_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case FETCH_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case FETCH_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SAVE_GRAPHS_TEXT.pending().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.pending,
      };
    case SAVE_GRAPHS_TEXT.success().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.success,
        editableTextReport: payload?.text,
      };
    case SAVE_GRAPHS_TEXT.failure().type:
      return {
        ...state,
        textReportRequestStatus: requestStatuses.failure,
      };
    case SET_BUILDING().type:
      return {
        ...state,
        building: payload,
      };
    case SET_GRAPH_SWITCHER().type:
      return {
        ...state,
        returnPercentageSwitcher: payload,
      };
    case SET_GRAPH_SCALE().type:
      return {
        ...state,
        scale: payload,
      };
    case SET_GRAPH_VALUE().type:
      return {
        ...state,
        value: payload,
      };
    default:
      throw new Error();
  }
}

export default reducer;
