import { useRef, useLayoutEffect, useState } from 'react';

const useNodeSize = () => {
  const element = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const updateSize = () => {
      setHeight(element.current.offsetHeight);
      setWidth(element.current.offsetWidth);
    };

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  });

  return {
    ref: element,
    height,
    width,
  };
};

export default useNodeSize;
