import React from 'react';

import './ClientName.scss';

const ClientName = ({ name }) => {
  return (
    <div className="ClientName">
      <span className="client-name">{name}</span>
    </div>
  );
};

ClientName.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  name: 'clientName',
};

export default ClientName;
