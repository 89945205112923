export const GRAPHSCALES = [
  {
    period: 'Day',
    maxDays: 365,
  },
  {
    period: 'Week',
    maxDays: 365,
  },
  {
    period: 'Month',
    maxDays: 365,
  },
];
