/* eslint-disable camelcase */
import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/liveLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_COMBINED_OCCUPANCY_DATABOXES = createAsyncAction(createAction('FETCH_COMBINED_OCCUPANCY_DATABOXES'));
export const FETCH_COMBINED_OCCUPANCY_DATABOXES_WEEK = createAsyncAction(
  createAction('FETCH_COMBINED_OCCUPANCY_DATABOXES_WEEK'),
);

const fetchCombinedOccupancyDataboxes = async (
  dispatch,
  isCanceled,
  { companyId, dateAfter, dateBefore, workingHours, query, fixed_date_range, week, archive },
) => {
  try {
    // dispatch(week ? FETCH_COMBINED_OCCUPANCY_DATABOXES_WEEK.pending() : FETCH_COMBINED_OCCUPANCY_DATABOXES.pending());

    const url = ENDPOINTS.liveLook.combinedOcuupancyDataboxes
      .replace(':companyId', companyId)
      .concat(workingHours ? `?work_hours=True` : '')
      .concat(workingHours ? `&exclude_weekend=True` : '')
      .concat(dateAfter ? (workingHours ? `&date_after=${dateAfter}` : `?date_after=${dateAfter}`) : '')
      .concat(dateBefore ? `&date_before=${dateBefore}` : '')
      .concat(
        fixed_date_range
          ? workingHours
            ? `&fixed_date_range=${fixed_date_range}`
            : `?fixed_date_range=${fixed_date_range}`
          : '',
      )
      .concat(query ? `&${query}` : '')
      .concat(archive ? `&archive_id=${archive}` : '');

    const { data } = await API.get(url);
    // .then(({ data }) => {
    //   if (isCanceled) return;
    dispatch(
      week ? FETCH_COMBINED_OCCUPANCY_DATABOXES_WEEK.success(data) : FETCH_COMBINED_OCCUPANCY_DATABOXES.success(data),
    );
    // })
    // .catch(() => {
    //   if (isCanceled) return;
    //   dispatch(week ? FETCH_COMBINED_OCCUPANCY_DATABOXES_WEEK.failure() : FETCH_COMBINED_OCCUPANCY_DATABOXES.failure());
    // });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default fetchCombinedOccupancyDataboxes;
