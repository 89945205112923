import { URLS } from 'pages/constants';

import DoorsOverTime from '../DoorsOverTime';
import DoorsDaily from '../DoorsDaily';
import DoorsByBuilding from '../DoorsByBuilding';
import MeetsOverTime from '../MeetsOverTime';
import BreakoutOverTime from '../BreakoutOverTime';
import MeetsSpaceComparison from '../MeetsSpaceComparison';
import MeetsSpaceUsageBySize from '../MeetsSpaceUsageBySize';
import BreakoutSpaceComparison from '../BreakoutSpaceComparison';
import BreakoutSpaceUsageBySize from '../BreakoutSpaceUsageBySize';
import DesksSpaceComparison from '../DesksSpaceComparison';
import DesksOverTime from '../DesksOverTime';
import DesksByBuilding from '../DesksByBuilding';
import DesksByDivision from '../DesksByDivision';
import SummaryKeyFindings from '../SummaryKeyFindings';
import SummaryKeyFindingsMeet from '../SummaryKeyFindingsMeet';
import SummaryKeyFindingsBreak from '../SummaryKeyFindingsBreak';
import SummaryKeyFindingsDesks from '../SummaryKeyFindingsDesks';
import SummaryOverallOccupancy from '../SummaryOverallOccupancy';
import SummaryKeyFindingsOccupancy from '../SummaryKeyFindingsOccupancy';
import SummaryFloorPlan from '../SummaryFloorPlan';
import PdfReportsPage from '../PdfReportsPage';
import CombinedTeamZoneOccupancy from '../CombinedTeamZoneOccupancy';
import SummaryDashboardContainer from '../SummaryDashboard';
import DesksAmberState from '../DeskAmberState';
import ArchivePageContainer from '../ArchivesPage';

const routes = [
  {
    name: 'executiveSummaryDashboard',
    path: URLS.liveLook.dashboard,
    component: SummaryDashboardContainer,
  },
  {
    name: 'executiveSummaryKeyFindings',
    path: URLS.liveLook.executiveSummaryKeyFindings,
    component: SummaryKeyFindings,
  },
  {
    name: 'executiveSummaryOverallOccupancy',
    path: URLS.liveLook.executiveSummaryOverallOccupancy,
    component: SummaryOverallOccupancy,
  },
  {
    name: 'executiveSummaryFloorPlan',
    path: URLS.liveLook.executiveSummaryFloorPlan,
    component: SummaryFloorPlan,
  },
  {
    name: 'executiveSummaryKeyFindingsMeet',
    path: URLS.liveLook.executiveSummaryKeyFindingsMeet,
    component: SummaryKeyFindingsMeet,
  },
  {
    name: 'executiveSummaryKeyFindingsBreak',
    path: URLS.liveLook.executiveSummaryKeyFindingsBreak,
    component: SummaryKeyFindingsBreak,
  },
  {
    name: 'executiveSummaryKeyFindingsDesks',
    path: URLS.liveLook.executiveSummaryKeyFindingsDesks,
    component: SummaryKeyFindingsDesks,
  },
  {
    name: 'executiveSummaryKeyFindingsOccupancy',
    path: URLS.liveLook.executiveSummaryKeyFindingsOccupancy,
    component: SummaryKeyFindingsOccupancy,
  },
  {
    name: 'doorsOverTime',
    path: URLS.liveLook.doorsOverTime,
    component: DoorsOverTime,
  },
  {
    name: 'doorsDaily',
    path: URLS.liveLook.doorsDaily,
    component: DoorsDaily,
  },
  {
    name: 'doorsByBuilding',
    path: URLS.liveLook.doorsByBuilding,
    component: DoorsByBuilding,
  },
  {
    name: 'combinedTeamZoneOccupancy',
    path: URLS.liveLook.combinedTeamZoneOccupancy,
    component: CombinedTeamZoneOccupancy,
  },
  {
    name: 'desksOverTime',
    path: URLS.liveLook.desksOverTime,
    component: DesksOverTime,
  },
  {
    name: 'desksSpaceComparison',
    path: URLS.liveLook.desksSpaceComparison,
    component: DesksSpaceComparison,
  },
  {
    name: 'desksByBuilding',
    path: URLS.liveLook.desksByBuilding,
    component: DesksByBuilding,
  },
  {
    name: 'desksByDivision',
    path: URLS.liveLook.desksByDivision,
    component: DesksByDivision,
  },
  {
    name: 'desksAmberState',
    path: URLS.liveLook.amberState,
    component: DesksAmberState,
  },
  {
    name: 'meetsOverTime',
    path: URLS.liveLook.meetsOverTime,
    component: MeetsOverTime,
  },
  {
    name: 'meetsSpaceComparison',
    path: URLS.liveLook.meetsSpaceComparison,
    component: MeetsSpaceComparison,
  },
  {
    name: 'meetsSpaceUsageBySizes',
    path: URLS.liveLook.meetsSpaceUsageBySizes,
    component: MeetsSpaceUsageBySize,
  },
  {
    name: 'breakoutOverTime',
    path: URLS.liveLook.breakoutOverTime,
    component: BreakoutOverTime,
  },
  {
    name: 'breakoutSpaceComparison',
    path: URLS.liveLook.breakoutSpaceComparison,
    component: BreakoutSpaceComparison,
  },
  {
    name: 'breakoutSpaceUsageBySizes',
    path: URLS.liveLook.breakoutSpaceUsageBySizes,
    component: BreakoutSpaceUsageBySize,
  },
  {
    name: 'pdfReports',
    path: URLS.liveLook.pdfReports,
    component: PdfReportsPage,
  },
  {
    name: 'archives',
    path: URLS.liveLook.archive,
    component: ArchivePageContainer,
  },
];

export default routes;
