/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { Loader } from 'components';
import theme from './seriesSettings.module.scss';
import SeriesItem from './components/series-item';
import CreateSeriesForm from './components/createSeriesForm';

const SeriesSettings = ({
  isLoading,
  seriesList,
  activeSeries,
  onSeriesClick,
  onCreateSeriesClick,
  onCancelSeries,
  graphId,
  reportFields,
  seriesData,
  reportPictures,
  isSeriesLoading,
}) => {
  const displaySeriesForm = activeSeries.displaySeries;
  const getActiveSeries = activeSeries.seriesId;
  const getSeriesAction = activeSeries.action;

  return (
    <Choose>
      <When condition={isLoading}>
        <Loader />
      </When>
      <When condition={!isLoading}>
        <div className={theme.container}>
          <Typography variant="h6" className={theme.title}>
            List of Series
          </Typography>
          <div className={theme.seriesBody}>
            <div className={theme.seriesContainer}>
              <Button fullWidth onClick={() => onCreateSeriesClick()} color="primary" variant="outlined">
                Create Series
              </Button>
              {seriesList.map(({ id, name }, index) => (
                <SeriesItem onEditSeries={onSeriesClick} key={id} seriesId={id} name={name} index={index} />
              ))}
            </div>
            {displaySeriesForm && (
              <div className={theme.seriesFormContainer}>
                <CreateSeriesForm
                  reportPictures={reportPictures}
                  seriesData={seriesData}
                  isSeriesLoading={isSeriesLoading}
                  graphId={graphId}
                  reportFields={reportFields}
                  seriesAction={getSeriesAction}
                  onCancelSeries={onCancelSeries}
                  seriesId={getActiveSeries}
                />
              </div>
            )}
          </div>
        </div>
      </When>
    </Choose>
  );
};

export default SeriesSettings;
