import { createReducer } from '@reduxjs/toolkit';

import { requestStatuses } from 'modules/shared/constants';
import { LOAD_REPORT_INFO, RESET_REPORT_DATA, TOGGLE_FILTER, SET_PDF_MODE } from 'modules/spaceLook/actions';

import defaultLogo from 'assets/images/icons/logo-with-text.svg';

const initState = {
  reportInfoRequestStatus: requestStatuses.default,
  surveyName: null,
  startData: null,
  endDate: null,
  reportLogo: null,
  titleImage: null,
  titleText: null,
  titleTextColor: null,
  subTitleImage: null,
  subTitleTextColor: null,
  deskTypesQuantity: null,
  lastOpenedReportId: null,
  showFilter: false,
  pdfMode: false,
};

const servicesReducer = createReducer(initState, {
  [LOAD_REPORT_INFO.pending]: (state) => ({
    ...state,
    reportInfoRequestStatus: requestStatuses.pending,
  }),
  [LOAD_REPORT_INFO.success]: (state, { payload }) => ({
    ...state,
    reportInfoRequestStatus: requestStatuses.success,
    surveyName: payload.survey_name,
    companyName: payload.company_name,
    startDate: payload.dates_range.begin_date,
    endDate: payload.dates_range.end_date,
    reportLogo: payload.report_logo ? payload.report_logo : defaultLogo,
    titleImage: payload.report_image,
    titleText: payload.report_description,
    titleTextColor: payload.report_text_color,
    subTitleImage: payload.report_sub_title_image,
    subTitleTextColor: payload.report_sub_title_text_color,
    deskTypesQuantity: payload.category_1_count,
  }),
  [LOAD_REPORT_INFO.failure]: (state) => ({
    ...state,
    reportInfoRequestStatus: requestStatuses.failure,
  }),
  [TOGGLE_FILTER]: (state) => ({
    ...state,
    showFilter: !state.showFilter,
  }),
  [SET_PDF_MODE]: (state) => ({
    ...state,
    pdfMode: true,
  }),

  [RESET_REPORT_DATA]: (state, { payload }) => ({
    ...initState,
    lastOpenedReportId: payload,
  }),
});

export default servicesReducer;
