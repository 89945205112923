/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS, requestStatuses } from 'modules/shared/constants';
import { useSelector } from 'react-redux';
import { getActiveCompany } from 'modules/shared/selectors';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const useCustomPdfList = () => {
  const [requestStatus, setRequestStatus] = useState(requestStatuses.default);
  const [requestStandardStatus, setStandardRequestStatus] = useState(requestStatuses.default);
  const [requestStatusPrivate, setRequestStatusPrivate] = useState(requestStatuses.default);
  const [pdfList, setPdfList] = useState(null);
  const [privatePdfList, setPrivatePdfList] = useState(null);
  const [standardPdfList, setStandardPdfList] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(null);

  const { id: companyId } = useSelector(getActiveCompany);

  useEffect(() => {
    setRequestStatus(requestStatuses.pending);

    const token = isLogged();
    const url = ENDPOINTS.liveLook.pdfList.replace(':companyId', companyId);
    // TODO change url after BE side will be ready
    // Make this as hook
    const privateUrl = ENDPOINTS.liveLook.privatePdfList.replace(':companyId', companyId);
    const standardUrl = ENDPOINTS.liveLook.standardPdfList.replace(':companyId', companyId);

    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    axios
      .get(privateUrl, config)
      .then(({ data }) => {
        setPrivatePdfList(data);
        setRequestStatusPrivate(requestStatuses.success);
      })
      .catch((err) => {
        setRequestStatus(requestStatuses.failure);
      });

    axios
      .get(standardUrl, config)
      .then(({ data }) => {
        setStandardPdfList(data);
        setStandardRequestStatus(requestStatuses.success);
      })
      .catch((err) => {
        setStandardRequestStatus(requestStatuses.failure);
      });

    axios
      .get(url, config)
      .then(({ data }) => {
        setPdfList(data);
        setRequestStatus(requestStatuses.success);
      })
      .catch((err) => {
        setRequestStatus(requestStatuses.failure);
      });
  }, [companyId, reloadTrigger]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pdfList && !isEmpty(pdfList) && pdfList.some((item) => item.status === 'In progress')) {
        setReloadTrigger(uuidv4());
      }
      if (privatePdfList && !isEmpty(privatePdfList) && privatePdfList.some((item) => item.status === 'In progress')) {
        setReloadTrigger(uuidv4());
      }
      if (
        standardPdfList &&
        !isEmpty(standardPdfList) &&
        standardPdfList.some((item) => item.status === 'In progress')
      ) {
        setReloadTrigger(uuidv4());
      }
    }, 5000);

    return () => clearInterval(interval);
  });

  return [pdfList, privatePdfList, standardPdfList, setPdfList, setPrivatePdfList, setStandardPdfList];
};

export default useCustomPdfList;
