import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/assetLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';

export const FETCH_TAGS = createAsyncAction(createAction('FETCH_TAGS'));

const fetchCategories = (dispatch, companyId, isCanceled) => {
  dispatch(FETCH_TAGS.pending());

  const url = ENDPOINTS.assetLook.tags.replace(':companyId', companyId);

  API.get(url)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_TAGS.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_TAGS.failure());
    });
};

export default fetchCategories;
