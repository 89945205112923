/* eslint-disable camelcase */
import React from 'react';
import { isEmpty } from 'lodash';
import { useControlCardData, useGraphWidgets } from 'modules/workLook/hooks';
import StackedDataCard from './ControlDataCard';
import EmptyGraph from '../EmptyGraph';

const ControlDataCardContainer = ({
  dataCard,
  id,
  type_of_group,
  activeFilters,
  is_display_title,
  type,
  isPdf,
  title,
  isPublished,
  sub_title,
  ...props
}) => {
  const { isLoading, data } = useControlCardData(id, activeFilters);
  const { widgetsList } = useGraphWidgets(id);

  if (isEmpty(data))
    return (
      <EmptyGraph
        isPublished={isPublished}
        title={title}
        type={type}
        graphId={id}
        display={false}
        typeOfGroup={type_of_group}
        showTitle={is_display_title}
        subtitle={sub_title}
        {...props}
      />
    );

  return (
    <StackedDataCard
      graphId={id}
      isPublished={isPublished}
      data={data[0].data.control_data}
      widgets={data[0].data.widget_data}
      dataCard={dataCard}
      isLoading={isLoading}
      widgetsList={widgetsList}
      {...props}
      typeOfGroup={type_of_group}
      title={title}
      type={type}
      subtitle={sub_title}
      isPdf={isPdf}
      showTitle={is_display_title}
    />
  );
};

export default ControlDataCardContainer;
