import React from 'react';

import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { List, ListItem, ListItemText } from '../CustomList';

const OrderInfoSkeleton = () => (
  <List>
    <Grid container spacing={1}>
      {[1, 2, 3, 4].map((i) => (
        <Grid item sm={6} md={3} key={i}>
          <ListItem>
            <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
          </ListItem>
        </Grid>
      ))}
      <Grid item xs={12} md={6}>
        <ListItem>
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
        </ListItem>
      </Grid>
    </Grid>
  </List>
);

export default OrderInfoSkeleton;
