import React from 'react';
import cn from 'classnames';
import { Grid, Typography, Box } from '@material-ui/core';

import filterConfig from 'modules/liveLook/components/UsageOverTimeGraph/usageOverTime.utils';
import { isEmpty } from 'lodash';
import theme from './GraphLegend.module.scss';

const toShortForm = (label, shortSize) => {
  if (shortSize && label.includes('Average Occupancy')) return label.replace('Average Occupancy', 'Avg Occ');
  if (shortSize && label.includes('Person')) return label.replace('Person', '');
  if (shortSize && label.includes('People')) return label.replace('People', '');
  if (shortSize && label.includes('Peak Occupancy')) return label.replace('Peak Occupancy', 'Peak Occ');
  if (isEmpty(shortSize)) return label;
  return label;
};

const GraphLegend = ({ config, pdfMode, marginTop, isSwitcherOn, isLiveLook, shortSize, isDashboard }) => {
  const { newConfigArray } = filterConfig(isSwitcherOn, config);
  const getFontWeight = isLiveLook || pdfMode || isDashboard;
  return (
    <div
      className={cn(theme.container, { [`${theme.pdfMode}`]: pdfMode || isDashboard })}
      style={{
        marginTop: isLiveLook ? '-20px' : marginTop,
      }}
    >
      <Grid container spacing={2}>
        {newConfigArray.map(({ iconType, label, legendLabel, color }, i) => (
          <Grid item key={i} className={theme.legendItem}>
            <div
              className={cn(theme.icon, {
                [`${theme.round}`]: iconType === 'round',
                [`${theme.line}`]: iconType === 'line',
              })}
              style={{ backgroundColor: color }}
            />
            <Typography variant={pdfMode || isDashboard ? 'body2' : 'body1'}>
              <Box fontWeight={getFontWeight ? 'normal' : 'fontWeightMedium'} component="span">
                {toShortForm(legendLabel || label, shortSize)}
              </Box>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

GraphLegend.defaultProps = {
  xs: 12,
  sm: 6,
  md: 3,
  lg: 3,
  xl: 2,
  marginTop: '1px',
};

export default GraphLegend;
