import URLS from 'pages/constants/urls';

const config = [
  {
    label: 'Location',
    name: 'Location Look',
    pathname: URLS.locationLook.locationSummary,
    linkedPages: [URLS.locationLook.locationSummary, URLS.locationLook.locationDetails],
  },
  {
    label: 'Space',
    name: 'Space Look',
    pathname: URLS.spaceLook.reportHistory,
    linkedPages: [URLS.spaceLook.reportHistory, URLS.spaceLook.baseReport.replace(':id', '')],
  },
  {
    label: 'Live',
    name: 'Live Look',
    pathname: URLS.liveLook.dashboard,
    linkedPages: [URLS.liveLook.base],
  },
  {
    label: 'People',
    name: 'People Look',
    pathname: URLS.workLook.base,
    linkedPages: [URLS.workLook.base],
  },
  {
    label: 'Asset',
    name: 'Asset Look',
    pathname: URLS.assetLook.productList,
    linkedPages: [URLS.assetLook.base],
    state: {
      fromNavBar: true,
      isBuilding: true,
    },
  },
];

export default config;
