import React from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: '4px',
    fontSize: '0.8rem',
  },
}));

const CustomTooltip = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} {...props}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
