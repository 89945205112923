export const ICONS = [
  {
    name: 'People',
    value: 'PEOPLE',
  },
  {
    name: 'Buildings',
    value: 'BUILDINGS',
  },
  {
    name: 'Desks',
    value: 'DESKS',
  },
  {
    name: 'Rooms',
    value: 'ROOMS',
  },
  {
    name: 'Chairs',
    value: 'CHAIRS',
  },
  {
    name: 'Computer screens',
    value: 'COMPUTER_SCREENS',
  },
  {
    name: 'Telephones',
    value: 'TELEPHONES',
  },
  {
    name: 'Large TV',
    value: 'LARGE_TV',
  },
  {
    name: 'Teams',
    value: 'TEAMS',
  },
  {
    name: 'Truck',
    value: 'TRUCK',
  },
  {
    name: 'Car',
    value: 'CAR',
  },
  {
    name: 'Person walking',
    value: 'PERSON_WALKING',
  },
  {
    name: 'Bus',
    value: 'BUS',
  },
  {
    name: 'Train',
    value: 'TRAIN',
  },
  {
    name: 'Journyes',
    value: 'JOURNEYS',
  },
  {
    name: 'Trees',
    value: 'TREES',
  },
  {
    name: 'Plants',
    value: 'PLANTS',
  },
  {
    name: 'Tonnes CO2',
    value: 'TONNES_CO2',
  },
  {
    name: 'Filing cabinets',
    value: 'FILING_CABINETS',
  },
  {
    name: 'Mechanical items',
    value: 'MECHANICAL_ITEMS',
  },
  {
    name: 'Cogs',
    value: 'COGS',
  },
  {
    name: 'Meeting',
    value: 'MEETING',
  },
  {
    name: 'Door',
    value: 'DOOR',
  },
  {
    name: 'Other general items',
    value: 'OTHER_GENERAL_ITEMS',
  },
  {
    name: 'Document',
    value: 'DOCUMENT',
  },
  {
    name: 'Up arrow',
    value: 'UP_ARROW',
  },
  {
    name: 'Down arrow',
    value: 'DOWN_ARROW',
  },
  {
    name: 'Location',
    value: 'LOCATION',
  },
  {
    name: 'Dollar',
    value: 'DOLLAR',
  },
  {
    name: 'Pound',
    value: 'POUND',
  },
  {
    name: 'Euro',
    value: 'EURO',
  },
  {
    name: 'Pin',
    value: 'PIN',
  },
  {
    name: 'Project',
    value: 'PROJECT',
  },
  {
    name: 'Assets',
    value: 'ASSETS',
  },
  {
    name: 'Map',
    value: 'MAP',
  },
  {
    name: 'Process',
    value: 'PROCESS',
  },
  {
    name: 'Images',
    value: 'IMAGES',
  },
  {
    name: 'Happy',
    value: 'HAPPY',
  },
  {
    name: 'Neutral',
    value: 'NEUTRAL',
  },
  {
    name: 'Sad',
    value: 'SAD',
  },
  {
    name: 'Work',
    value: 'WORK',
  },
  {
    name: 'Home',
    value: 'HOME',
  },
];
