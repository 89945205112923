import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { URLS } from 'pages/constants';
import { RESET_STATE } from 'modules/liveLook/actions';

import LiveLookLayout from 'pages/_layouts/LiveLookLayout';
import { PreFetchHandler } from 'modules/liveLook/components';
import routes from './constants/routes';

export default () => {
  const dispatch = useDispatch();
  useEffect(() => () => dispatch(RESET_STATE()), [dispatch]);

  return (
    <LiveLookLayout>
      <PreFetchHandler>
        <Switch>
          {routes.map(({ name, path, component }) => (
            <Route key={name} path={path} component={component} />
          ))}
          <Redirect to={URLS.locationLook.locationSummary} />
        </Switch>
      </PreFetchHandler>
    </LiveLookLayout>
  );
};
