import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getHeatmapData } from 'modules/spaceLook/selectors';

import Sidebar from './Sidebar';

const SidebarContainer = () => {
  const {
    data: { heatmapData },
  } = useSelector(getHeatmapData);

  const { id, heatmapId } = useParams();
  const { pathname } = useLocation();

  return <Sidebar heatmapData={heatmapData} activeReportId={id} activeHeatmapId={heatmapId} pathname={pathname} />;
};

export default SidebarContainer;
