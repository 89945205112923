import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect, useParams, useLocation } from 'react-router-dom';

import URLS from 'pages/constants/urls';
import { isDefault } from 'modules/shared/utils';
import { getBenchmarkingAppliedFilters, getDeskTypesQuantity } from 'modules/spaceLook/selectors';
import { uploadAppliedFilters, uploadBenchmarkingAppliedFilters } from 'modules/spaceLook/utils';
import {
  loadReportInfo,
  fetchAppliedFilters,
  fetchBenchmarkingAppliedFilters,
  fetchHeatmapData,
  RESET_SHOULD_UPLOAD,
  RESET_BENCHMARKING_SHOULD_UPLOAD,
} from 'modules/spaceLook/actions';

import { ErrorBoundary } from 'modules/shared/components';
import { ReportNetworkErrorHandler } from 'modules/spaceLook/components';

import SpaceLookLayout from 'pages/_layouts/SpaceLookLayout';

import SurveyDetails from './SurveyDetails';
import KeyFindingsDesks from './KeyFindingsDesks';
import KeyFindingsMeetings from './KeyFindingsMeetings';
import KeyFindingsBreakout from './KeyFindingsBreakout';
import CombinedOccupancy from './CombinedOccupancy';
import DesksUsageOverTime from './DesksUsageOverTime';
import DesksDailyUsagePattern from './DesksDailyUsagePattern';
import DesksDailyComparison from './DesksDailyComparison';
import DesksBenchmarking from './DesksBenchmarking';
import DesksByBuildingAndFloor from './DesksByBuildingAndFloor';
import DesksByDivAndDep from './DesksByDivAndDep';
import DesksByType from './DesksByType';
import DesksUsageBandingOccupied from './DesksUsageBandingOccupied';
import DesksUsageBandingUtilized from './DesksUsageBandingUtilized';
import DesksAgility from './DesksAgility';
import MeetingsUsageOverTime from './MeetingsUsageOverTime';
import MeetingsByBuildingAndFloor from './MeetingsByBuildingAndFloor';
import MeetingsSpaceCompAndSizes from './MeetingsSpaceCompAndSizes';
import BreakoutUsageOverTime from './BreakoutUsageOverTime';
import BreakoutByBuildingAndFloor from './BreakoutByBuildingAndFloor';
import BreakoutSpaceComparison from './BreakoutSpaceComparison';
import Heatmap from './Heatmap';
import Downloads from './Downloads';

export default () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const isFirstRun = useRef(true);

  const { appliedFilters, appliedFiltersRequestStatus, shouldUpload } = useSelector(({ report: { filters } }) => ({
    appliedFilters: filters.active.applied,
    appliedFiltersRequestStatus: filters.active.requestStatus,
    shouldUpload: filters.active.shouldUpload,
  }));
  const { benchmarkingAppliedFilters, benchmarkingAppliedFiltersRequestStatus, benchmarkingShouldUpload } =
    useSelector(getBenchmarkingAppliedFilters);
  const deskTypesQuantity = useSelector(getDeskTypesQuantity);

  useEffect(() => {
    fetchHeatmapData(dispatch, id);
    loadReportInfo(dispatch, id);
  }, [dispatch, id]);

  useEffect(() => {
    if (isDefault(appliedFiltersRequestStatus)) fetchAppliedFilters(dispatch, id);
    if (isDefault(benchmarkingAppliedFiltersRequestStatus)) fetchBenchmarkingAppliedFilters(dispatch, id);
  }, [appliedFiltersRequestStatus, benchmarkingAppliedFiltersRequestStatus, dispatch, id]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (shouldUpload) {
      uploadAppliedFilters(id, JSON.stringify(appliedFilters));
      dispatch(RESET_SHOULD_UPLOAD());
    }

    if (benchmarkingShouldUpload) {
      uploadBenchmarkingAppliedFilters(id, JSON.stringify(benchmarkingAppliedFilters));
      dispatch(RESET_BENCHMARKING_SHOULD_UPLOAD());
    }
  }, [id, shouldUpload, benchmarkingShouldUpload, appliedFilters, dispatch, benchmarkingAppliedFilters]);

  return (
    <SpaceLookLayout>
      <ErrorBoundary location={location}>
        <ReportNetworkErrorHandler>
          <Switch>
            <Redirect exact from={URLS.spaceLook.baseReport} to={URLS.spaceLook.surveyDetails} />

            <Route exact path={URLS.spaceLook.surveyDetails} component={SurveyDetails} />
            <Route exact path={URLS.spaceLook.keyFindingsDesks} component={KeyFindingsDesks} />
            <Route exact path={URLS.spaceLook.keyFindingsMeetings} component={KeyFindingsMeetings} />
            <Route exact path={URLS.spaceLook.keyFindingsBreakout} component={KeyFindingsBreakout} />
            <Route exact path={URLS.spaceLook.combinedOccupancy} component={CombinedOccupancy} />
            <Route exact path={URLS.spaceLook.desksUsageOverTime} component={DesksUsageOverTime} />
            <Route exact path={URLS.spaceLook.desksUsagePattern} component={DesksDailyUsagePattern} />
            <Route exact path={URLS.spaceLook.desksDailyComparison} component={DesksDailyComparison} />
            <Route exact path={URLS.spaceLook.desksBenchmarking} component={DesksBenchmarking} />
            <Route exact path={URLS.spaceLook.desksByBuildingAndFloor} component={DesksByBuildingAndFloor} />
            <Route exact path={URLS.spaceLook.desksByDivAndDep} component={DesksByDivAndDep} />
            <If condition={deskTypesQuantity > 1}>
              <Route exact path={URLS.spaceLook.desksByType} component={DesksByType} />
            </If>
            <Route exact path={URLS.spaceLook.desksUsageBandingOcc} component={DesksUsageBandingOccupied} />
            <Route exact path={URLS.spaceLook.desksUsageBandingUtil} component={DesksUsageBandingUtilized} />
            <Route exact path={URLS.spaceLook.desksAgility} component={DesksAgility} />
            <Route exact path={URLS.spaceLook.meetingsUsageOverTime} component={MeetingsUsageOverTime} />
            <Route exact path={URLS.spaceLook.meetingsByBuildingAndFloor} component={MeetingsByBuildingAndFloor} />
            <Route exact path={URLS.spaceLook.meetingsSpaceComparisonAndSizes} component={MeetingsSpaceCompAndSizes} />
            <Route exact path={URLS.spaceLook.breakoutUsageOverTime} component={BreakoutUsageOverTime} />
            <Route exact path={URLS.spaceLook.breakoutByBuildingAndFloor} component={BreakoutByBuildingAndFloor} />
            <Route exact path={URLS.spaceLook.breakoutSpaceComparison} component={BreakoutSpaceComparison} />
            <Route exact path={URLS.spaceLook.heatmap} component={Heatmap} />

            <Route exact path={URLS.spaceLook.downloads} component={Downloads} />

            <Redirect to={URLS.spaceLook.reportHistory} />
          </Switch>
        </ReportNetworkErrorHandler>
      </ErrorBoundary>
    </SpaceLookLayout>
  );
};
