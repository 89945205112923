/* eslint-disable consistent-return */
const toUrlParams = (obj) =>
  Object.keys(obj)
    .map((key) => {
      if (!obj[key] || (Array.isArray(obj[key]) && obj[key].length < 1)) return;

      if (Array.isArray(obj[key])) return obj[key].map((item) => `${key}=${item}&`).join('');

      return `${key}=${obj[key]}&`;
    })
    .join('');

export default toUrlParams;
