import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { Bar } from 'react-chartjs-2';

import GraphYScale from 'modules/liveLook/components/GraphYScale';
import { GRAPH_SCALES } from 'modules/liveLook/constants';
// import GraphLines from 'modules/liveLook/components/UsageOverTimeGraph/components/GraphLines/GraphLines';
import comibnedOptions from './options';
import theme from './GraphBody.module.scss';

// import { getWidth } from '../../usageOverTime.utils';

function GraphBody({
  scaleValues = ['100', '80', '60', '40', '20', '0'],
  graphData: { data, dayLabels },
  config,
  // linesData,
  scale,
  isSwitcherOn,
  height,
}) {
  //   const [firstBar] = data.datasets;
  const BAR_WIDTH = 15;
  const innerWidth = data.labels.length * BAR_WIDTH;
  const optionsDeep = cloneDeep(comibnedOptions(isSwitcherOn));
  const getHourGraph = useMemo(
    () => ![GRAPH_SCALES.day, GRAPH_SCALES.week, GRAPH_SCALES.month].includes(scale),
    [scale],
  );
  if (typeof scaleValues[0] === 'number') {
    // eslint-disable-next-line prefer-destructuring
    optionsDeep.scales.yAxes[0].ticks.max = scaleValues[0];
  }

  return (
    <div className={theme.container}>
      <div className={theme.graphWrapper} style={{ height }}>
        <GraphYScale isSwitcherOn={isSwitcherOn} marginBottom="96px" values={scaleValues} />
        <If condition={config.find((item) => item.lineLabel)}>
          {/* <GraphLines
            config={config.filter((item) => item.lineLabel)}
            linesData={linesData}
            marginBottom="96px"
            scaleMaxValue={optionsDeep.scales.yAxes[0].ticks.max}
            isSwitcherOn={isSwitcherOn}
          /> */}
        </If>

        <div className={theme.graphInner}>
          <div
            style={{
              ...innerWidth,
              height: '100%',
            }}
          >
            <div className={getHourGraph ? theme.hourGraph : theme.dayGraph}>
              <Bar key={dayLabels?.length} data={data} options={optionsDeep} />
            </div>

            <If condition={getHourGraph}>
              <div className={theme.labelWrapper}>
                {dayLabels?.map(({ label }) => (
                  <div
                    key={label}
                    title={label}
                    className={theme.dayLabel}
                    style={{
                      flexBasis: innerWidth,
                    }}
                  >
                    {label}
                  </div>
                ))}
              </div>
            </If>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraphBody;
