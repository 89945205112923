/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useCallback } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  withStyles,
  Paper,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { filterInitValues } from './utils';

import theme from './FilterModalBase.module.scss';

const FilterModalBase = React.forwardRef(
  (
    {
      classes,
      isAllSelected,
      onSelectAllClick,
      title,
      onClose,
      initValues,
      isValueChecked,
      onValueClick,
      onApplyClick,
      isAssetLook,
      isPeopleLook,
    },
    ref,
  ) => {
    const [search, setSearch] = useState('');
    const [emptyHidden, setEmptyHidden] = useState(true);
    const filteredInitValues = useMemo(() => initValues.filter((item) => item.toString().trim() !== ''), [initValues]);
    const isTags = ['Tags', 'Status'].includes(title);

    const togglerHandler = useCallback(() => setEmptyHidden((prev) => !prev), []);

    const filteredValues = useMemo(
      () => filterInitValues(filteredInitValues, emptyHidden, isAssetLook),
      [filteredInitValues, emptyHidden, isAssetLook],
    );

    return (
      <Paper ref={ref} elevation={2} className={theme.container} tabIndex={-1}>
        <Grid container spacing={2}>
          <Grid item xs={10} className={theme.title}>
            <Checkbox className={theme.checkbox} checked={isAllSelected} color="primary" onChange={onSelectAllClick} />
            <Typography variant="h6" component="span">
              {title}
            </Typography>
          </Grid>

          <Grid item xs={2} align="right">
            <IconButton aria-label="close" classes={{ root: classes.closeBtn }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12} classes={{ root: classes.searchContainer }}>
            <SearchIcon classes={{ root: classes.searchIcon }} />
            <TextField
              autoFocus
              value={search}
              placeholder="Search"
              classes={{ root: classes.searchInput }}
              onChange={({ target: { value } }) => setSearch(value)}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} className={theme.content}>
              {filteredValues.map((initValue) => {
                let label;
                let value;
                let count;
                if (isAssetLook) {
                  label = initValue.key;
                  value = isPeopleLook ? initValue.key : isTags ? initValue.value : initValue.key;
                  count = initValue.value;
                } else if (typeof initValue === 'object') {
                  label = initValue.label;
                  value = initValue.value;
                } else {
                  label = initValue;
                  value = initValue;
                }

                // eslint-disable-next-line default-case
                switch (typeof label) {
                  case 'string':
                    if (label.toLowerCase().indexOf(search.toLowerCase().trim()) === -1) return;
                    break;
                  case 'number':
                    if (label.toString().indexOf(search.toLowerCase().trim()) === -1) return;
                    break;
                }

                const checked = isValueChecked(value);

                // eslint-disable-next-line consistent-return
                return (
                  <Grid key={value} className={theme.item} item xs={4}>
                    <label>
                      <Checkbox
                        className={theme.checkbox}
                        checked={checked || isAllSelected}
                        color="primary"
                        onClick={() => onValueClick(value)}
                        disableRipple
                      />
                      <Typography variant="body1" component="span" className={theme.value} title={label}>
                        {isPeopleLook ? label : isTags ? count : label}
                      </Typography>
                      {isAssetLook && (
                        <Typography
                          variant="body1"
                          component="span"
                          style={{ marginLeft: 10 }}
                          className={theme.value}
                          title={label}
                        >
                          {
                            // eslint-disable-next-line prettier/prettier
                            isPeopleLook ? `(${count})` : !isTags && `(${count})`
                          }
                        </Typography>
                      )}
                    </label>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <If condition={isAssetLook && !isTags}>
              <Grid item>
                <FormControlLabel
                  control={<Switch color="primary" checked={!emptyHidden} onChange={togglerHandler} />}
                  labelPlacement="start"
                  label="Show empties"
                />
              </Grid>
            </If>
          </Grid>
          <Grid item xs={6} className={theme.actions}>
            <Button color="primary" size="large" onClick={onApplyClick}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  },
);

// eslint-disable-next-line no-shadow
export default withStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    right: '30px',
  },
  searchContainer: {
    padding: 0,
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(1),
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    width: '100%',
  },
}))(FilterModalBase);
