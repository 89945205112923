/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable react/no-children-prop */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import isLogged from 'utils/isLogged';
import { ENDPOINTS } from 'modules/shared/constants';

import { Typography, IconButton, makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TextPlaceholder } from 'modules/shared/components';
import noImage from 'assets/images/icons/no-image.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { isEmpty } from 'lodash';
import { SET_CONTROL_CARD_DATA, SET_WIDGETS_DATA } from 'modules/workLook/actions';
import { useDispatch } from 'react-redux';
import './ControlDataCard.scss';
import { useEditGraphClick, useModifierGraph } from 'modules/workLook/hooks';
// eslint-disable-next-line import/no-cycle
import { GraphHeader } from 'modules/workLook/components';
import { formatDateTime } from '../EditableTable/formatDateTime';

const getDataCard = (key, card) => {
  if (['date', 'time'].includes(key)) return formatDateTime(key, card);
  return card;
};

const useStyles = makeStyles((theme) => ({
  buildingType: {
    color: '#9e9e9e',
    textAlign: 'center',
    marginBottom: 0,
    fontSize: '12px',
  },
  buildingName: {
    color: theme.palette.primary.dark,
    textAlign: 'center',
    marginBottom: 0,
  },
  address: {
    textAlign: 'center',
    marginBottom: 0,
    color: '#9e9e9e',
  },
  additionalInfo: {
    textAlign: 'center',
    marginBottom: 0,
    color: '#9e9e9e',
    fontSize: '14px',
  },
  image: {
    height: '100%',
    maxWidth: '100%',
    backgroundSize: 'contain',
    backgroundColor: ({ src }) => (src ? theme.palette.common.white : theme.palette.grey[200]),
  },
  actionButtons: {
    padding: '4px',
  },
}));

const ControlDataCard = ({
  data,
  isLoading,
  isPdf,
  widgetsList,
  controlCardData,
  title,
  subtitle,
  typeOfGroup,
  isPublished,
  graphId,
  isModal,
  showTitle,
  type,
}) => {
  const config = {
    headers: { Authorization: `Token ${isLogged()}` },
  };
  const { onEditClick } = useEditGraphClick(isModal, graphId);
  const { onModifierClick } = useModifierGraph(isModal, graphId, data, type, typeOfGroup);

  const dispatch = useDispatch();
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const uiCardData = data?.[index];
  const cardData = isPdf ? controlCardData : uiCardData;
  const keys = isEmpty(data) ? [] : Object.keys(data?.[index]);

  useEffect(() => {
    dispatch(SET_CONTROL_CARD_DATA(uiCardData));
  }, [uiCardData, dispatch]);

  const onMoveBack = () => {
    const widgets = [];
    setIndex(index - 1);
    widgetsList.forEach((widget) => {
      const url = ENDPOINTS.workLook.widgetData.replace(':wigetId', widget.id).replace(':recordId', data[index - 1].id);
      dispatch(SET_WIDGETS_DATA({ isLoading: true }));

      axios
        .get(`${url}`, config)
        .then(({ data }) => {
          widgets.push(data);
          dispatch(SET_WIDGETS_DATA({ widgetsData: [...widgets], isLoading: false }));
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const onMoveForward = () => {
    const widgets = [];
    setIndex(index + 1);
    widgetsList.forEach((widget) => {
      const url = ENDPOINTS.workLook.widgetData.replace(':wigetId', widget.id).replace(':recordId', data[index + 1].id);
      dispatch(SET_WIDGETS_DATA({ isLoading: true }));

      axios
        .get(`${url}`, config)
        .then(({ data }) => {
          widgets.push(data);
          dispatch(SET_WIDGETS_DATA({ widgetsData: [...widgets], isLoading: false }));
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </When>

      <When condition={!isLoading && isEmpty(data)}>
        <Paper className="container" elevation={2}>
          <GraphHeader
            display={false}
            showTitle={showTitle}
            graphId={graphId}
            title={title}
            subTitle={subtitle}
            isPdf={isPdf}
            onClick={onEditClick}
            onModifierClick={onModifierClick}
            isPublished={isPublished}
          />
          <TextPlaceholder />
        </Paper>
      </When>

      <Otherwise>
        <Paper elevation={2} className="largeCardContainer">
          <div className="imageContainer">
            <img className={classes.image} src={noImage} alt="thumbnail" />
          </div>
          <div className="infoContainer">
            <div className="buildingType2">
              <div className="btnWrapper">
                <If condition={!isPdf}>
                  <IconButton
                    onClick={() => onMoveBack()}
                    disabled={index === 0}
                    children={<ChevronLeftIcon />}
                    classes={{ root: classes.actionButtons }}
                  />
                  <IconButton
                    onClick={onMoveForward}
                    disabled={index === data.length - 1}
                    children={<ChevronRightIcon />}
                    classes={{ root: classes.actionButtons }}
                  />
                </If>
              </div>
              <GraphHeader
                showTitle={showTitle}
                display={false}
                graphId={graphId}
                isPdf={isPdf}
                onClick={onEditClick}
                onModifierClick={onModifierClick}
                isPublished={isPublished}
              />
            </div>
            <div className="infoWrapper">
              <div className="buildingName">
                <Typography variant="h4" component="span" classes={{ root: classes.buildingName }}>
                  {isEmpty(data) ? '' : getDataCard(keys[1], cardData[keys[1]])}
                </Typography>
              </div>
              <div className="address">
                <Typography variant="body1" component="span" classes={{ root: classes.address }}>
                  {isEmpty(data) ? '' : getDataCard(keys[2], cardData[keys[2]])}
                </Typography>
              </div>
              <div className="additionalInfo">
                <Typography variant="body1" component="span" classes={{ root: classes.additionalInfo }}>
                  {isEmpty(data) ? '' : getDataCard(keys[3], cardData[keys[3]])}
                </Typography>
              </div>
            </div>
          </div>
        </Paper>
      </Otherwise>
    </Choose>
  );
};

export default ControlDataCard;
