/* eslint-disable no-shadow */
import { createAsyncAction, API } from 'modules/shared/utils';
import createAction from 'modules/assetLook/actions/_createAction';

import { ENDPOINTS } from 'modules/shared/constants';
import { FETCH_MODIFIED_FILTERS } from './fetchAllAssets';

export const FETCH_PRODUCTS = createAsyncAction(createAction('FETCH_PRODUCTS'));

const fetchProducts = (dispatch, isCanceled, { companyId, buildingId, categoryId, filters, queryFilters }) => {
  dispatch(FETCH_PRODUCTS.pending());

  const url = ENDPOINTS.assetLook.products.replace(':companyId', companyId);
  const filtersUrl = ENDPOINTS.assetLook.initModifiedFilters
    .replace(':companyId', companyId)
    .concat(queryFilters ? `?${queryFilters}` : '');

  const data = {
    building_id: Number(buildingId),
    category_id: Number(categoryId),
    filters,
  };

  API.post(filtersUrl, { building_id: Number(buildingId), category_id: Number(categoryId) })
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_MODIFIED_FILTERS.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_MODIFIED_FILTERS.failure());
    });

  API.post(url, data)
    .then(({ data }) => {
      if (isCanceled) return;
      dispatch(FETCH_PRODUCTS.success(data));
    })
    .catch(() => {
      if (isCanceled) return;
      dispatch(FETCH_PRODUCTS.failure());
    });
};

export default fetchProducts;
