/* eslint-disable camelcase */
import React from 'react';

import { URLS } from 'pages/constants';
import { makeSearch } from 'modules/assetLook/utils';
import { useLocationSearchParams } from 'modules/shared/hooks';
import { BUILDING_ID, CATEGORY_ID } from 'modules/assetLook/constants';

import { SidebarListSubItem } from 'modules/shared/components';

const CategoryItem = ({ building_id: buildingId, category_id: categoryId, category__name }) => {
  const { buildingId: activeBuildingId, categoryId: activeCategoryId } = useLocationSearchParams([
    BUILDING_ID,
    CATEGORY_ID,
  ]);

  return (
    <SidebarListSubItem
      link={{
        pathname: URLS.assetLook.productList,
        search: makeSearch({ buildingId, categoryId }),
        state: { isBuilding: true },
      }}
      label={category__name}
      isActive={
        buildingId
          ? buildingId === Number(activeBuildingId) && categoryId === Number(activeCategoryId)
          : categoryId === Number(activeCategoryId)
      }
    />
  );
};

export default CategoryItem;
