import combinedPageConfig from 'pages/_privatePages/spaceLook/CombinedOccupancy/config';

const config = [
  {
    key: 'total_desks',
    label: 'Desk seats',
    color: combinedPageConfig.palette.desks,
  },
  {
    key: 'total_room_seats_meet',
    label: 'Meeting seats',
    color: combinedPageConfig.palette.meets,
  },
  {
    key: 'total_room_seats_break',
    label: 'Breakout seats',
    color: combinedPageConfig.palette.break,
  },
];

const databoxesToPieChartData = (databoxes) => {
  const pieChartData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  };

  config.forEach(({ key, label, color }) => {
    if (databoxes[key]) {
      pieChartData.labels.push(label);
      pieChartData.datasets[0].data.push(databoxes[key]);
      pieChartData.datasets[0].backgroundColor.push(color);
    }
  });

  return pieChartData;
};

export default databoxesToPieChartData;
