import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import { API } from 'modules/shared/utils';
import { ENDPOINTS } from 'modules/shared/constants';
import { getActiveCompany } from 'modules/shared/selectors';

const usePdfList = () => {
  const { id: companyId } = useSelector(getActiveCompany);

  const [pdfList, setPdfList] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(null);

  // TODO: Show snackbar on error

  useEffect(() => {
    let canceled = false;
    const url = ENDPOINTS.assetLook.pdf.replace(':companyId', companyId);

    API.get(url)
      .then(({ data }) => {
        if (canceled) return;
        setPdfList(data);
      })
      .catch((err) => {
        if (canceled) return;
        // eslint-disable-next-line no-console
        console.error(err);
      });

    // eslint-disable-next-line no-return-assign
    return () => (canceled = true);
  }, [companyId, reloadTrigger]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isEmpty(pdfList) && pdfList.some((item) => item.status === 'In progress')) {
        setReloadTrigger(uuidv4());
      }
    }, 5000);

    return () => clearInterval(interval);
  });

  return [pdfList, setReloadTrigger];
};

export default usePdfList;
