export const DATA_VALUES = {
  VALUE: 'value',
  PERCENTAGES: 'pct',
};

export const GRAPHSCALES = {
  HOUR: '1 hour',
  HALF_DAY: 'Half day',
  DAY: 'Day',
};
