const keysMap = {
  percent_nomadic: 'nomadic',
  percent_mobile: 'mobile',
  percent_flexible: 'flexible',
  percent_fixed: 'fixed',
};
const allKeys = ['percent_fixed', 'percent_flexible', 'percent_mobile', 'percent_nomadic'];

const setupStackedGraphRowsRooms = (data) => {
  const dataKeys = Object.keys(data);

  return allKeys.reduce((result, key) => {
    const arr = dataKeys.map((dataKey) => {
      const dataObj = data[dataKey];
      return dataObj[key];
    });
    // eslint-disable-next-line no-param-reassign
    result[keysMap[key]] = arr;
    return result;
  }, {});
};

export default setupStackedGraphRowsRooms;
