/* eslint-disable no-case-declarations */
import { useReducer } from 'react';
import qs from 'qs';
import { cloneDeep } from 'lodash';

import { requestStatuses } from 'modules/shared/constants';

import { SET_SELECTED_FILTERS, SET_AVAILABLE_FILTERS } from 'components/Pages/SummaryPage/actions';

const initState = {
  requestStatus: requestStatuses.default,
  availableFilters: {},
  selectedFilters: {
    division: [],
    property_id: [],
    region: [],
  },
  filtersQuery: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_FILTERS:
      const deep = cloneDeep(state.selectedFilters);

      deep[payload.category] = deep[payload.category].includes(payload.label)
        ? deep[payload.category].filter((item) => item !== payload.label)
        : [...deep[payload.category], payload.label];

      const query = `?${qs.stringify(deep, { arrayFormat: 'repeat' })}`;

      return {
        ...state,
        requestStatus: requestStatuses.pending,
        selectedFilters: deep,
        filtersQuery: query.length > 1 ? query : '',
      };

    case SET_AVAILABLE_FILTERS.pending:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case SET_AVAILABLE_FILTERS.success:
      return {
        ...state,
        requestStatus: requestStatuses.success,
        availableFilters: payload,
      };
    case SET_AVAILABLE_FILTERS.failure:
      return {
        ...state,
        requestStatus: requestStatuses.failure,
      };

    default:
      return state;
  }
};

const useFilterReducer = () => useReducer(reducer, initState);

export default useFilterReducer;
